<template>
  <div class="fmboard-wrapper">
    <v-row>
      <v-col :cols="tvview ? 12 : 10" class="fm-users mb-2" ref="fmusers">
        <v-btn v-for="user in fleetManagers" :key="user._id" @click="selectFm(user)" :id="`fm-${user._id}`" :text="selectedUser && selectedUser._id !== user._id" :disabled="selectedUser && selectedUser._id === user._id">
          {{user.name}}
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2" v-if="!tvview">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          tabindex="1"
          clearable
          height="28"
          dense
          solo
          :light="true"
          :label="$t('other.searchFMDrivers')"
          single-line
          hide-details
          @input="onSearchFmDrivers"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-progress-circular
      v-if="loading"
      indeterminate
      class="fmboard-loader"
      color="primary"
    ></v-progress-circular>
    <div class="table-container-fmboard" v-if="Object.keys(drivers).length" :class="{loading: loading}">
      <table class="fmboard-table" :class="{dark: dark, tvview: tvview}">
        <thead>
          <tr>
            <th v-for="item in headers" :key="item.value">{{item.text}}</th>
          </tr>
        </thead>
        <tbody>
          <tr
              v-for="driverKey in sortedDrivers"
              :key="drivers[driverKey]._id"
              :id="drivers[driverKey]._id"
              :data-name="drivers[driverKey].name"
              :class="{inactive: !drivers[driverKey].is_active}"
            >
            <td class="center light-col">{{drivers[driverKey].company}}</td>
            <!-- @click="$router.push(`/?truck=${driver.truck}`)" -->
            <td class="driver-name">
              <span>
                <template v-if="drivers[driverKey].name.indexOf('/') === -1">
                  {{ drivers[driverKey].name }}
                </template>
                <template v-else>
                  {{drivers[driverKey].name.split('/')[0]}} <br/>
                  {{drivers[driverKey].name.split('/')[1]}}
                </template>
              </span>
              <v-tooltip v-if="drivers[driverKey].note && drivers[driverKey].note !== ''" right max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="note-icon" v-bind="attrs" v-on="on" right> mdi-comment-text-outline </v-icon> 
                </template>
                <span style="white-space: pre-wrap;">{{ drivers[driverKey].note }}</span>
              </v-tooltip>
            </td>
            <td class="center">{{drivers[driverKey].type_trailer}}</td>
            <td class="center">{{drivers[driverKey].dispatcher_ext}}</td>
            <td class="center">{{drivers[driverKey].dispatcher_ext_third}}</td>
            <td class="center">{{drivers[driverKey].dispatcher_ext_fourth}}</td>
            <td class="center bold">{{drivers[driverKey].truck}}</td>
            <td class="center">{{drivers[driverKey].trailer}}</td>
            <td>
              <template v-if="drivers[driverKey].phone.indexOf('/') === -1">{{drivers[driverKey].phone}}</template>
              <template v-else>{{drivers[driverKey].phone.split('/')[0].trim()}}<br/>{{drivers[driverKey].phone.split('/')[1].trim()}}</template>
            </td>
            <td class="center">{{ drivers[driverKey].fc  ? 'ON' : 'OFF' }}</td>
            <td class="center">
              <v-tooltip v-if="drivers[driverKey].fleetManager && drivers[driverKey].fleetManager.name" left max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <span class="" v-bind="attrs" v-on="on" right @click="$router.push(`/contacts-board?name=${drivers[driverKey].fleetManager.name}`)">
                    {{ drivers[driverKey].fleetManager && drivers[driverKey].fleetManager.name !== '' ? drivers[driverKey].fleetManager.name.split(" ").map((n)=>n[0]).join(".") : '-' }}
                  </span>
                </template>
                <span style="white-space: pre-wrap;">{{drivers[driverKey].fleetManager.name}}</span>
              </v-tooltip>
            </td>
            <td class="center">
              <v-tooltip v-if="drivers[driverKey].payroll && drivers[driverKey].payroll.name" left max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <span class="" v-bind="attrs" v-on="on" right @click="$router.push(`/contacts-board?name=${drivers[driverKey].payroll.name}`)">
                    {{ drivers[driverKey].payroll && drivers[driverKey].payroll.name !== '' ? drivers[driverKey].payroll.name.split(" ").map((n)=>n[0]).join(".") : '-' }}
                  </span>
                </template>
                <span style="white-space: pre-wrap;">{{drivers[driverKey].payroll.name}}</span>
              </v-tooltip>
            </td>
            <td class="center">
              <v-tooltip v-if="drivers[driverKey].trailer_manager && drivers[driverKey].trailer_manager.name" left max-width="350">
                <template v-slot:activator="{ on, attrs }">
                  <span class="" v-bind="attrs" v-on="on" right @click="$router.push(`/contacts-board?name=${drivers[driverKey].trailer_manager.name}`)">
                    {{ drivers[driverKey].trailer_manager && drivers[driverKey].trailer_manager.name !== '' ? drivers[driverKey].trailer_manager.name.split(" ").map((n)=>n[0]).join(".") : '-' }}
                  </span>
                </template>
                <span style="white-space: pre-wrap;">{{drivers[driverKey].trailer_manager.name}}</span>
              </v-tooltip>
            </td>
            <Load v-if="drivers[driverKey].brokenLoad"
              :key="`${drivers[driverKey]._id}-${drivers[driverKey].brokenLoad.day_key}`"
              :day="drivers[driverKey].brokenLoad.day"
              :boardId="drivers[driverKey].board._id"
              :load="drivers[driverKey].brokenLoad"
              :showDate="true">
            </Load>
            <td class="center" v-else>/</td>
          </tr>
          <tr class="fake-row">
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else-if="!loading" class="date-from-to subtitle-1">There is no broken trucks/loads</p>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import DriverService from '../../services/DriverService'
import {mapGetters} from 'vuex'
import Load from '../Dashboard/Load.vue'

export default {
  name: 'FMBoard.vue',
  data() {
    return {
      fleetManagers: [],
      selectedUser: null,
      search: '',
      tvview: false,
      drivers: {},
      tvinterval: null,
      mainInterval: null,
      headers: [
        {text: 'Co', value: 'company'},
        {text: 'Name', value: 'name'},
        {text: 'Type', value: 'type'},
        {text: 'Floyd Ext', value: 'dispatcher_ext'},
        {text: 'Rocket Ext', value: 'dispatcher_ext_third'},
        {text: 'Jordan Ext', value: 'dispatcher_ext_fourth'},
        {text: 'Truck#', value: 'truck'},
        {text: 'Tr#', value: 'trailer'},
        {text: 'Phone', value: 'phone'},
        {text: 'Fc', value: 'fc'},
        {text: 'Fm', value: 'fleetManager'},
        {text: 'Pr', value: 'payroll'},
        {text: 'Tm', value: 'trailer_manager'},
        {text: 'Broken load', value: 'broken_load'}
      ],
    }
  },
  components: {
    Load
  },
  watch: {
    selectedUser( newVal, oldVal) {
      console.log('on change', newVal, oldVal)
      this.getDrivers()
    },
    $route() {
      const city = this.$route.query && this.$route.query.city
      clearInterval(this.mainInterval)
      this.getFleetManagers(city)
      this.mainInterval = setInterval(() => {
        this.getDrivers()
      }, 1000 * 30)
    },
  },
  computed: {
    ...mapGetters('common', ['loading', 'dark']),
  },
  methods: {
    getFleetManagers(city) {
      this.$store.dispatch('user/getUsersByRoleId', 11).then(res => {
        this.fleetManagers = res.filter(user => {
          if (!user.is_team_lead && user.name !== 'tvuser' && user.order < 9000 && user.fm_city === city) {
            return true
          }
          return false
        })
        if (this.fleetManagers[0]) {
          this.selectedUser = this.fleetManagers[0]
        }

        // INTERVAL FOR TV
        if (this.$route.query && this.$route.query.tvview) {
          let currentUserIndex = 0
          let maxUsers = this.fleetManagers.length
          this.tvinterval = setInterval( () => {
            if (currentUserIndex +1 < maxUsers) {
              currentUserIndex += 1
            } else {
              currentUserIndex = 0
            }
            this.selectedUser = this.fleetManagers[currentUserIndex]
            document.getElementById(`fm-${this.selectedUser._id}`).scrollIntoView({behavior: 'smooth'})
          }, 1000 * 60)
        }
      })
    },
    getDrivers() {
      this.$store.dispatch('common/setLoading', true)
      DriverService.getBrokenTrucks(this.selectedUser._id, this.search).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          this.drivers = res.data.drivers
          this.sortedDrivers = res.data.sorted
        }
      })
    },
    selectFm(user) {
      if (this.tvview) {
        return
      }
      this.selectedUser = user
    },
    onSearchFmDrivers: debounce( function() {
      this.getDrivers()
    }, 500),
  },
  mounted() {
    const city = this.$route.query && this.$route.query.city
    this.getFleetManagers(city)
    if (this.$route.query && this.$route.query.tvview) {
      this.tvview = true
    } else {
      this.mainInterval = setInterval(() => {
        this.getDrivers()
      }, 1000 * 30)
    }
  },
  beforeDestroy() {
    if (this.tvinterval) {
      clearInterval(this.tvinterval)
    }
    if (this.mainInterval) {
      clearInterval(this.mainInterval)
    }
  }
}
</script>

<style lang="scss">

.fmboard-wrapper {
  .fm-users {
    white-space: nowrap;
    overflow-x: scroll;
  }

  .fmboard-loader {
    position: fixed;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }

  .table-container-fmboard {
    overflow: scroll;
    width: 100%;
    max-height: calc(100vh - 145px);
    position: relative;

    &.loading {
      opacity: 0.7;
    }

    .fmboard-table {
      border-collapse: collapse;
      min-width: 400px;
      width: 100%;
      border-collapse: separate; /* Don't collapse */
      border-spacing: 0;

      &.dark {
        th {
          background-color: #43a047;
        }

        td {
          .drag-icon {
            color: #43a047;
          }

          .note-icon {
            color: #43a047;
          }
        }
      }

      &.tvview {
        .load-cell {
          font-size: 20px !important;
          color: #ffffff;

          .load-date {
            color: red;
          }
        }
      }

      th {
        padding: 10px 5px;
        border-left: 1px solid #A9A9A9;
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        background-color: #1E88E5FF;
        color: #ffffff;
        text-align: center;
        top: 0;
        z-index: 5;
        font-size: 13px;
      }

      tr {
        &.fake-row {
          td {
            height: 200px;
            border-left: none;
            border-bottom: none;
          }
        }

        td {
          padding: 7px 5px;
          font-size: 12px;
          border-left: 1px solid #A9A9A9;
          border-bottom: 1px solid #A9A9A9;
          white-space: nowrap;
          position: relative;

          &.bold {
          font-weight: 600;
          }
          &.center {
            text-align: center;
          }

          &.light-col {
            color: #3f51b5;
            text-shadow: 0px 0px 10px rgb(63 81 181 / 70%);
            font-weight: 600;
            font-size: 11px;
            text-align: center;
          }

          &.driver-name {
            font-weight: 600;
            position: relative;
            &:hover {
              color: blue;
              cursor: pointer;
            }

            .note-icon {
              position: absolute;
              right: 2px;
              top: 2px;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>