<template>
  <div class="TableFilters">
    <form @submit.prevent="handleFiltersApplied">
      <div class="TableFilters__fields d-flex align-center flex-wrap justify-end" :class="align">
        <template v-for="field in Object.keys(form)">
          <v-text-field class="flex-grow-0 TableFilters__field" v-if="shouldShow(form[field], 'text')"
            :value="values[field]" :key="field" :label="form[field].label" :type="form[field].inputType || 'text'" dense
            hide-details solo small width="160px" clearable :readonly="loading" @input="(v) => handleInput(field, v)" />
          <v-radio-group class="flex-grow-0 mt-0 mb-2" v-if="shouldShow(form[field], 'radio-group')" :key="field"
            :value="values[field]" row hide-details dense @change="(v) => handleInput(field, v)">
            <v-radio v-for="o in form[field].options" :key="`${field}_${o.value}`" :label="o.label" :value="o.value" />
          </v-radio-group>
          <v-divider class="mr-2 mb-1" v-if="isDividerVisible && field === 'filter'" :key="`${field}_divider`"
            vertical></v-divider>
          <v-select class="flex-grow-0 TableFilters__field" v-if="shouldShow(form[field], 'select')" :key="field"
            :value="values[field]" :items="form[field].options" :label="form[field].label"
            :placeholder="form[field].label" :multiple="form[field].multiple"
            :item-value="form[field].itemValue || 'value'" :item-text="form[field].itemText || 'text'" dense row solo
            hide-details small clearable @change="(v) => handleInput(field, v)" />

          <v-combobox class="flex-grow-0 TableFilters__field" v-if="shouldShow(form[field], 'combobox')" :key="field"
            :value="values[field]" :items="form[field].options" :label="form[field].label"
            :placeholder="form[field].label" :multiple="form[field].multiple"
            :item-value="form[field].itemValue || 'value'" :item-text="form[field].itemText || 'text'" dense row solo
            hide-details small clearable @change="(v) => handleInput(field, v)" />
          <autocomplete-input :key="field" :ref="`auto-${field}`" v-if="shouldShow(form[field], 'autocomplete')"
            :field="form[field]" :field-value="values[field]" @change="(v) => handleInput(field, v)"></autocomplete-input>

          <v-menu v-if="shouldShow(form[field], 'date-time')" :key="field" v-model="menus[field]"
            :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="flex-grow-0 TableFilters__field date-time" :class="form[field].range ? 'date-time-range': 'date-time'"
                :value="Array.isArray(values[field]) ? values[field].join(' ~ ') : values[field]"
                :label="form[field].label" prepend-inner-icon="mdi-calendar" dense solo hide-details readonly small
                :clearable="typeof form[field].clearable === 'undefined' || form[field].clearable " @click:clear="handleInput(field, null)" v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker
              @input="(v) => handleDateInput(field, v)"
              :range="form[field].range"
              :value="values[field]"
              :allowed-dates="getDateChecker(form[field], values[field])"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </template>

        <v-btn class="ml-4" v-if="!hideClearButton" color="red" :disabled="disableClearButton" fab x-small @click="handleClearFilters">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn v-if="!hideSearchButton" class="ml-1" type="submit" color="primary" dark>Search</v-btn>
        <v-switch v-if="extraFields" class="mt-3 ml-1" :model="moreFilters" :value="moreFilters" dense
          @change="handleToggleAdditional"></v-switch>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AutocompleteInput from './Common/AutocompleteInput.vue'
export default {
  name: 'TableFilters',
  components: {
    AutocompleteInput
  },
  props: {
    initialValues: Object,
    initialForm: Object,
    extraFields: Object,
    resetValues: Object,
    hideClearButton: {
      type: Boolean,
      default: false,
    },
    pickupSecondChanged: {
      type: Boolean
    },
    isDividerVisible: {
      type: Boolean,
      default: false
    },
    isShop: {
      type: Boolean,
      default: false
    },
    hideSearchButton: {
      type: Boolean,
      default: true
    },
    align: {
      type: String,
      default: 'justify-end'
    }
  },

  emits: ['filters-changed', 'filters-applied'],

  data() {
    const data = {
      resetData: this.resetValues ? this.resetValues : this.initialValues,
      form: this.initialForm,
      values: { ...this.initialValues },
      menus: {},
      moreFilters: false,
    }


    return data
  },

  mounted() {
    // napravi atribut da vodi racuna da li je otvoren date select
    for (const field of Object.keys(this.initialForm)) {
      if (this.initialForm[field].type === 'date-time') {
        Object.assign(this.menus, { [field]: false })
      } else if (this.initialForm[field].type === 'autocomplete') {
        if (this.$refs[`auto-${field}`] && this.$refs[`auto-${field}`][0] && this.$refs[`auto-${field}`][0].$refs['v-auto']) {
          this.$refs[`auto-${field}`][0].$refs['v-auto'].items.push(this.initialValues[field])
        }
      }
    }
  },

  computed: {
    ...mapGetters('common', ['loading']),
    disableClearButton() {
      return JSON.stringify(this.values) === JSON.stringify(this.resetData)
    }
  },

  watch: {
    initialForm(v) {
      this.form = { ...v }
    }
  },

  methods: {
    shouldShow(field, type) {
      let conditionMet = true
      if (field.conditionField && !this.values[field.conditionField])
        conditionMet = false
      return field.type === type && conditionMet
    },

    shouldCloseDatePicker(field, v) {
      if((this.form[field].type === 'date-time' && !this.form[field].range) ||
      (this.form[field].type === 'date-time' && this.form[field].range && v.length > 1)) {
        return true
      }
      return false
    },

    handleInput(field, v) {
      this.values[field] = v
      if (this.form[field].type === 'date-time') this.menus[field] = false
      // else if (
      //   this.form[field].type === 'text' &&
      //   v &&
      //   v.length > 0 &&
      //   v.length <= 2
      // ) return
      return this.isShop
        ? this.$emit('filters-applied', this.values)
        : this.$emit('filters-changed', this.values)
    },

    handleDateInput(field, v) {
      this.values[field] = v
      if (this.shouldCloseDatePicker(field, v)) {
        this.menus[field] = false
        return this.$emit('filters-changed', this.values)
      }
    },

    handleClearFilters() {
      this.values = { ...this.resetData }
      this.removeMoreFilters()
      this.$emit('filters-changed', this.values)
      this.$emit('filters-reset')
      this.$emit('filters-shop-reset', this.values)
    },
    removeMoreFilters() {
      this.moreFilters = false
      this.form = { ...this.initialForm }
    },

    handleToggleAdditional() {
      if (this.moreFilters) this.removeMoreFilters()
      else this.addMoreFilters()
    },
    addMoreFilters() {
      this.moreFilters = true
      this.form = {
        ...this.initialForm,
        ...this.extraFields,
      }
    },
    isDateAllowed(date, maxRange, value){
      if (value && value.length === 1) {
        const firstDate = new Date(value[0])
        const currentDate = new Date(date)
        // Calculate diff between dates
        const diffDays = Math.ceil((currentDate - firstDate) / (1000 * 60 * 60 * 24))
        
        return diffDays >= 0 && diffDays <= maxRange
      }
      return true
    },
    getDateChecker(field, value) {
      if (field.maxRange) {
        return (date) => {
          return this.isDateAllowed(date, field.maxRange, value)
        }
      }
      return null
    },
    handleRemoveValue(field) {
      this.values[field] = null
    }
  },
}
</script>

<style lang="scss">
.TableFilters {
  &__field {
    max-width: 130px;

    &.date-time {
      max-width: 160px;
    }
    &.date-time-range {
      min-width: 260px;
    }
  }

  &__fields {
    gap: 3px;
  }
}
</style>
