import debounce from 'lodash/debounce'
import Api from '@/services/Api'

export default {
  data() {
    return {
      items: [],
      searchInput: null,
      loading: false
    }
  },
  watch: {
    searchInput (val) {
      if (val) {
        this.debounceSearch(val)
      }
    },
  },
  methods: {
    debounceSearch: debounce( function(search) {
      search = search.split(',')[0]
      if (search && search.length) {
        this.fetchItems(search)
      } else {
        this.items = []
      }
    }, 400),
    fetchItems(query) {
      this.loading = true
      // pass as prop apiUrl = /loads/autocomplete/cities
      Api().get(this.field.apiUrl, {
        params: {
          search: query
        }
      }).then(response => {
        if (response.data.status === 'OK') {
          this.items = this.field.dataKey ? response.data[this.field.dataKey] : response.data.items
        }
      })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}