<template>
  <v-container>
    <v-layout row wrap>
      <v-flex col="6">
        <v-card height="350" class="permisions-card">
          <PermissionsInfo />
          <v-card-title class="subtitle-1 pb-1">
            Currnet permissions:
            <p class="caption">Naming convetion: {PAGENAME}_{ACTIONNAME}</p>
          </v-card-title>
          <v-container>
            <draggable
              v-model="tmpPermissions"
              :sort="false"
              :group="{ name: 'formPermissions', pull: true, put: true }"
              @add="onChange"
              @remove="onChange"
            >
              <v-chip
                small
                class="mr-2 mb-1 permission"
                color="green"
                text-color="white"
                v-for="(permision, index) in tmpPermissions"
                :key="`permision-${index}`"
                label
              >{{permision}}</v-chip>
            </draggable>
          </v-container>
        </v-card>
      </v-flex>
      <v-flex col="6">
        <v-card height="350" class="permisions-card">
          <v-card-title class="subtitle-1 pb-1">
            All remaining permissions:
            <p class="caption">Naming convetion: {PAGENAME}_{ACTIONNAME}</p>
          </v-card-title>
          <v-container class="permisions-container">
            <draggable
              v-model="allPermissions"
              :sort="false"
              :group="{ name: 'formPermissions', pull: true, put: true }"
            >
              <v-chip
                small
                class="mr-2 mb-1 permission"
                color="primary"
                text-color="white"
                v-for="(permision, index) in allPermissions"
                :key="`permision-${index}`"
                label
              >{{permision}}</v-chip>
            </draggable>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import userService from '../../services/UsersService'
import PermissionsInfo from './PermissionsInfo'

export default {
  name: 'PermissionsDrag',
  props: ['permissions', 'rolePermissions'],
  components: {
    draggable,
    PermissionsInfo
  },
  data() {
    return {
      tmpPermissions: [],
      allPermissions: []
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.tmpPermissions)
    }
  },
  mounted() {
    this.tmpPermissions = this.permissions
    userService.getAllPermissions().then(res => {
      if (res.status === 200) {
        let allPermissionsArray = Object.keys(res.data).filter(x => !this.tmpPermissions.includes(x))
        if (this.rolePermissions) {
          allPermissionsArray = allPermissionsArray.filter(x => !this.rolePermissions.includes(x))
        }
        this.allPermissions = allPermissionsArray.sort()
      }
    })
  }
}
</script>

<style lang="scss">
.permission {
  &.v-chip {
    cursor: move;
  }
}

.permisions-card {
  overflow: scroll;
}
</style>