<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="240"
    min-width="240"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>

    <v-card style="padding: 10px">
      <div v-for="units of unitsCountByType" class="UnitsInfo__row" :key="units.text">
        <div class="UnitsInfo__col">{{ units.text }}</div>
        <div class="UnitsInfo__col text-right">{{ units.count }}</div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>   
export default {
  name: 'UnitsInfo',
  props: {
    units: {
      type: Array,
      required: false,
    },
    type: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      menu: false,
      TYPES: {
        truck: {
          TRUCK_RENT: { count: 0, text: 'Rent' },
          TRUCK_LEASE_TO_BUY: { count: 0, text: 'Lease to Buy' },
          TRUCK_LEASE_TO_LEASE: { count: 0, text: 'Lease to Lease' },
        },
        trailer: {
          TRAILER_RENT: { count: 0, text: 'Rent' },
          TRAILER_LEASE_TO_LEASE: { count: 0, text: 'Lease to Lease' },
        }
      }
    }
  },
  computed: {
    unitsCountByType() {
      const unitsByType = {...this.TYPES[this.type]}
      for (const key in unitsByType) {
        const filteredUnits = this.units ? this.units.filter(u => u.type === key) : []
        unitsByType[key].count = filteredUnits.length
      }
      return unitsByType
    }
  }
}
</script>

<style lang="scss">
.UnitsInfo {
  &__row {
    width: 100%;
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }
  }

  &__col {
    width: 50%;
    padding: 2px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(:last-child) {
      border-right: 1px solid #dddddd;
    }
  }
}
</style>
