import Vue from 'vue'
import Router from 'vue-router'
import Guard from '../middleware/guard'

import Home from '@/components/Home'
import Login from '@/components/Login/Login'
import Dashboard from '@/components/Dashboard/Dashboard'
import Users from '@/components/Users/Users'
import Roles from '@/components/Roles/Roles'
import Boards from '@/components/Boards/Boards'
import Companies from '@/components/Companies/Companies'
import Contacts from '@/components/Contacts/Contacts'
import ContactsView from '@/components/Contacts/ContactsView'
// import Logs from '@/components/Logs/Logs'
import Drivers from '@/components/Drivers/Drivers'
import LoadTypes from '@/components/LoadTypes/LoadTypes'
import Messages from '@/components/Messages/Messages'
import Activate from '@/components/Users/Activate'
import ActivateApp from '@/components/Users/ActivateApp'
import ResetPassword from '@/components/Users/ResetPassword'
import Inbox from '@/components/Messages/Inbox'
import MenageFm from '@/components/Drivers/MenageFM'

import EldDashboard from '@/components/Eld/EldDashboard'
import Shifts from '@/components/Eld/Shifts'
import DotInspections from '@/components/Eld/DotInspections'
import EldDrivers from '@/components/Eld/Drivers'
import EldTrucks from '@/components/Eld/Trucks'
import EldCompanies from '@/components/Eld/Companies'
import CallTypes from '@/components/Eld/CallTypes'

import Trailers from '@/components/Trailer/Trailers'
import AvailableTrailers from '@/components/Trailer/AvailableTrailers'
import InactiveDrivers from '@/components/Trailer/InactiveDrivers'
import AvailableTrailerStats from '@/components/Trailer/AvailableTrailerStats'
import TrailerOwners from '@/components/Trailer/TrailerOwners'
import TrailerMarks from '@/components/Trailer/TrailerMarks'
import TrailerTypes from '@/components/Trailer/TrailerTypes'
import Dealerships from '@/components/Dealerships/Dealerships'
import Yards from '@/components/Yards/Yards'

import Claims from '@/components/Claims/Claims'

import NewDrivers from '@/components/Drivers/NewDrivers'

import FMBoard from '@/components/Drivers/FMBoard'

import NotFound404 from '@/components/404'
import Forbidden403 from '@/components/403'
import TruckMake from '@/components/Trucks/TruckMake'
import TruckModel from '@/components/Trucks/TruckModel'
import Trucks from '@/components/Trucks/Trucks'

import Banks from '@/components/Banks/Banks'
import Contracts from '@/components/Accounting/Contracts'
import AccountingUnits from '@/components/Accounting/AccountingUnits'
import AccountingAllUnits from '@/components/Accounting/AllUnits'
import AccountingLease from '@/components/Accounting/AccountingLease'
import ContractDetails from '@/components/Accounting/ContractDetails'
import UnitsHistory from '@/components/Accounting/UnitsHistory'
import PostTrucks from '@/components/PreBook/PostTrucks'
import PostLoads from '@/components/PreBook/PostLoads'
import PostLoadsBoardTv from '@/components/PreBook/PostLoadsBoardTv'
import PrebookStats from '@/components/PreBook/PrebookStats'
import PrebookCompanies from '@/components/PreBook/PrebookCompanies'

import Cars from '@/components/Cars/Cars'
import CarMakes from '@/components/Cars/CarMakes'
import CarModels from '@/components/Cars/CarModels'

import TrucksStatististics from '@/components/Statististics/TrucksStatististics'
import AvailableView from '@/components/AvailableTrucks/AvailableView'
import RecruitmentView from '@/components/Recruitment/RecruitmentView'

import Alerts from '@/components/Common/Alerts.vue'

import AllCompanies from '@/components/Lease/AllCompanies'
import AllUnits from '@/components/Lease/AllUnits'
import Otp from '@/components/Login/Otp.vue'
import GridNavigation from '@/components/Common/GridNavigation.vue'

import Shop from '@/components/Shop/Shop.vue'
import ShopDetails from '@/components/Shop/ShopDetails.vue'

import ManageAppUsers from '@/components/Users/ManageAppUsers'
import FNDUsers from '@/components/Users/FNDUsers'
import MessagesMarketReport from '@/components/Messages/MessagesMarketReport.vue'

import PermitBookDrivers from '@/components/PermitBooks/PermitBookDrivers.vue'
import DriverDocuments from '@/components/DriverDocuments/DriverDocuments.vue'
import TrailersStatististics from '@/components/Statististics/TrailersStatististics.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '/',
          name: 'gridNavigation',
          component: GridNavigation,
          beforeEnter: Guard.auth
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['DISPATCHER_ALL', 'DISPATCHER_READ']}
        },
        {
          path: '/eld-dashboard',
          name: 'eldDashboard',
          component: EldDashboard,
          beforeEnter: Guard.auth,
        },
        {
          path: '/contacts-board',
          name: 'contactsBoard',
          component: ContactsView,
          beforeEnter: Guard.auth
        },
        {
          path: '/inbox',
          name: 'inbox',
          component: Inbox,
          beforeEnter: Guard.auth
        },
        {
          path: '/users',
          name: 'users',
          component: Users,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN']}
        },
        {
          path: '/roles',
          name: 'roles',
          component: Roles,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN']}
        },
        {
          path: '/boards',
          name: 'boards',
          component: Boards,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'SAFETY']}
        },
        {
          path: '/companies',
          name: 'companies',
          component: Companies,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'SAFETY']}
        },
        {
          path: '/load-types',
          name: 'load-types',
          component: LoadTypes,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'DISPATCHER', 'SAFETY']}
        },
        {
          path: '/drivers',
          name: 'drivers',
          component: Drivers,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['DRIVERS_VIEW']}
        },
        {
          path: '/fm-board',
          name: 'FMBoard',
          component: FMBoard,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'DISPATCHER', 'SAFETY', 'FLEET_MANAGER', 'ASSET']}
        },
        {
          path: '/menage-fm',
          name: 'menageFm',
          component: MenageFm,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['MENAGE_FM_ALL', 'SAFETY']}
        },
        {
          path: '/manage-app-users',
          name: 'manageAppUsers',
          component: ManageAppUsers,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['MANAGE_APP_USERS', 'RECRUIT_APP_USERS']}
        },
        {
          path: '/fnd-users',
          name: 'FNDUsers',
          component: FNDUsers,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN']}
        },
        {
          path: '/drivers/deleted',
          name: 'driversDeleted',
          component: Drivers,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['DRIVERS_VIEW', 'DRIVERS_DELETED_VIEW']}
        },
        // {
        //   path: '/logs',
        //   name: 'logs',
        //   component: Logs,
        //   beforeEnter: Guard.checkRole,
        //   meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'DISPATCHER']}
        // },
        {
          path: '/contacts',
          name: 'contacts',
          component: Contacts,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN']}
        },
        {
          path: '/messages',
          name: 'messages',
          component: Messages,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN']}
        },
        {
          path: '/messages/daily-report',
          name: 'messages-daily-report',
          component: MessagesMarketReport,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'BROKERAGE', 'DISPATCHER']}
        },
        {
          path: '/messages/weekly-report',
          name: 'messages-weekly-report',
          component: MessagesMarketReport,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'BROKERAGE', 'DISPATCHER']}
        },
        {
          path: '/claims/:type',
          name: 'claims',
          component: Claims,
          beforeEnter: Guard.auth
        },
        {
          path: '/new-drivers',
          name: 'newDrivers',
          component: NewDrivers,
          beforeEnter: Guard.auth
        },
        {
          path: '/shifts',
          name: 'shifts',
          component: Shifts,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'ELD_ADMIN', 'SHIFT_LEADER']}
        },
        {
          path: '/dot-inspections',
          name: 'dotInspections',
          component: DotInspections,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'ELD_ADMIN', 'SHIFT_LEADER']}
        },
        {
          path: '/eld-drivers',
          name: 'eld-drivers',
          component: EldDrivers,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'ELD_ADMIN']}
        },
        {
          path: '/eld-trucks',
          name: 'eld-trucks',
          component: EldTrucks,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'ELD_ADMIN']}
        },
        {
          path: '/eld-companies',
          name: 'eld-companies',
          component: EldCompanies,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'ELD_ADMIN']}
        },
        {
          path: '/eld-types',
          name: 'eld-types',
          component: CallTypes,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'ELD_ADMIN']}
        },
        {
          path: '/owners/:type',
          name: 'owwners',
          component: TrailerOwners,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'TRAILER_DEPARTMENT', 'TRUCK_DEPARTMENT', 'ASSET']}
        },
        {
          path: '/dealerships',
          name: 'dealerships',
          component: Dealerships,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'TRAILER_DEPARTMENT', 'TRUCK_DEPARTMENT', 'ASSET']}
        },
        {
          path: '/yards',
          name: 'yards',
          component: Yards,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'TRAILER_DEPARTMENT', 'TRUCK_DEPARTMENT', 'ASSET']}
        },
        {
          path: '/trailers/statistics',
          name: 'TrailersStatistics',
          component: TrailersStatististics,
          beforeEnter: Guard.auth
        },
        {
          path: '/trailers',
          name: 'trailers',
          component: Trailers,
          beforeEnter: Guard.auth
        },
        {
          path: '/available-trailers',
          name: 'availableTrailers',
          component: AvailableTrailers,
          beforeEnter: Guard.auth
        },
        {
          path: '/available-trailers/stats',
          name: 'AvailableTrailerStats',
          component: AvailableTrailerStats,
          beforeEnter: Guard.auth
        },
        {
          path: '/trailers-inactive-drivers',
          name: 'InactiveDrivers',
          component: InactiveDrivers,
          beforeEnter: Guard.auth
        },
        {
          path: '/trailers/marks',
          name: 'TrailerMarks',
          component: TrailerMarks,
          beforeEnter: Guard.auth
        },
        {
          path: '/trailers/types',
          name: 'TrailerTypes',
          component: TrailerTypes,
          beforeEnter: Guard.auth
        },
        {
          path: '/trailers/:filter',
          name: 'trailersFilter',
          component: Trailers,
          beforeEnter: Guard.auth
        },
        {
          path: '/truck-make',
          name: 'truck-make',
          component: TruckMake,
          beforeEnter: Guard.auth
        },
        {
          path: '/truck-model',
          name: 'truck-model',
          component: TruckModel,
          beforeEnter: Guard.auth
        },
        {
          path: '/trucks/:filter',
          name: 'trucks',
          component: Trucks,
          beforeEnter: Guard.auth
        },
        {
          path: '/statistics',
          name: 'statistics',
          component: TrucksStatististics,
          beforeEnter: Guard.auth
        },
        {
          path: '/available-trucks',
          name: 'AvailableView',
          component: AvailableView,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['AVAILABLE_TRUCKS_ALL', 'AVAILABLE_TRUCKS_VIEW']}
        },
        {
          path: '/all-truck-alerts',
          name: 'Alerts',
          component: Alerts,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['AVAILABLE_TRUCKS_ALL', 'AVAILABLE_TRUCKS_VIEW']}
        },
        {
          path: '/recruitment',
          name: 'RecruitmentView',
          component: RecruitmentView,
          beforeEnter: Guard.auth
        },
        {
          path: '/accounting/all-units',
          name: 'AccountingAllUnits',
          component: AccountingAllUnits,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['ACCOUNTING_VIEW_ALL']}
        },
        {
          path: '/accounting/units',
          name: 'AccountingUnits',
          component: AccountingUnits,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['ACCOUNTING_VIEW_ALL']}
        },
        {
          path: '/accounting/lease',
          name: 'AccountingLease',
          component: AccountingLease,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['ACCOUNTING_VIEW_ALL']}
        },
        {
          path: '/accounting/units-history',
          name: 'UnitsHistory',
          component: UnitsHistory,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['ACCOUNTING_VIEW_ALL']}
        },
        {
          path: '/contracts/:bankId',
          name: 'Contratcts',
          component: Contracts,
          beforeEnter: Guard.checkRole,
          meta: { allowedPermissions: ['ACCOUNTING_VIEW_ALL'] }
        },
        {
          path: '/contract-details/:id',
          name: 'ContractDetails',
          component: ContractDetails,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['ACCOUNTING_VIEW_ALL']}
        },
        {
          path: '/banks',
          name: 'Banks',
          component: Banks,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['ACCOUNTING_VIEW_ALL']}
        },
        {
          path: '/cars',
          name: 'Cars',
          component: Cars,
          beforeEnter: Guard.auth
        },
        {
          path: '/car-makes',
          name: 'CarMakes',
          component: CarMakes,
          beforeEnter: Guard.auth
        },
        {
          path: '/car-models',
          name: 'CarModels',
          component: CarModels,
          beforeEnter: Guard.auth
        },
        {
          path: '/lease/companies',
          name: 'AllCompanies',
          component: AllCompanies,
          beforeEnter: Guard.auth
        },
        {
          path: '/lease/companies/:id',
          name: 'AllCompanies',
          component: AllCompanies,
          beforeEnter: Guard.auth
        },
        {
          path: '/lease/units',
          name: 'AllUnits',
          component: AllUnits,
          beforeEnter: Guard.auth
        },
        {
          path: '/lease/units/:id',
          name: 'AllUnits',
          component: AllUnits,
          beforeEnter: Guard.auth
        },
        {
          path: '/pre-book/companies',
          name: 'PrebookCompanies',
          component: PrebookCompanies,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['PREBOOK_LOAD_ADMIN'], allowedRoles: ['SUPERADMIN', 'ADMIN', 'BROKER']}
        },
        {
          path: '/pre-book/post-trucks',
          name: 'PostTrucks',
          component: PostTrucks,
          beforeEnter: Guard.auth,
          meta: {allowedPermissions: ['PREBOOK_POST_TRUCKS_VIEW']}
        },
        {
          path: '/pre-book/posted-trucks',
          name: 'PostedTrucks',
          component: PostTrucks,
          beforeEnter: Guard.auth,
          meta: {allowedPermissions: ['PREBOOK_POSTED_TRUCKS_VIEW']}
        },
        {
          path: '/pre-book/post-loads',
          name: 'PostLoads',
          component: PostLoads,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['PREBOOK_LOAD_VIEW']}
        },
        {
          path: '/pre-book/stats',
          name: 'PrebookStats',
          component: PrebookStats,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['PREBOOK_LOAD_ADMIN'], allowedRoles: ['SUPERADMIN']}
        },
        {
          path: '/pre-book/tv',
          name: 'PrebookBoardTv',
          component: PostLoadsBoardTv,
          beforeEnter: Guard.checkRole,
          meta: {allowedPermissions: ['PREBOOK_LOAD_VIEW']}
        },
        {
          path: '/shop',
          name: 'Shop',
          component: Shop,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'FLEET_MANAGER', 'DISPATCHER', 'ELD', 'ASSET', 'TRAILER_DEPARTMENT']},
        },
        {
          path: '/shop/:shopId',
          name: 'ShopDetails',
          component: ShopDetails,
          props: true,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'FLEET_MANAGER', 'DISPATCHER', 'ELD', 'ASSET', 'TRAILER_DEPARTMENT']},
        },
        {
          path: '/permit-books/:tab?',
          name: 'PermitBooks',
          component: PermitBookDrivers,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'SAFTEY', 'DISPATCHER'], allowedPermissions: ['PERMITBOOK_VIEW']},
        },
        {
          path: '/driver-documents',
          name: 'DriverDocuments',
          component: DriverDocuments,
          beforeEnter: Guard.checkRole,
          meta: {allowedRoles: ['SUPERADMIN', 'ADMIN', 'ACCOUNTING'], allowedPermissions: ['DRIVER_DOCUMENTS_VIEW']},
        }
      ],
      beforeEnter: Guard.auth
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: Guard.guest
    },
    {
      path: '/activate',
      name: 'activate',
      component: Activate
    },
    {
      path: '/validate-drivers-mail',
      name: 'activate-app',
      component: ActivateApp
    },
    {
      path: '/otp-validate/:email',
      name: 'OtpValidate',
      component: Otp
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPassword
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: Forbidden403
    },
    {
      path: '*',
      name: '404',
      component: NotFound404
    }
  ]
})
