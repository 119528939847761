import Api from '@/services/Api'

export default {
  getCallTypes (pagination) {
    return Api().get('/call-types', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1
    }})
  },
  addCallType (loadType) {
    return Api().post('/call-types', loadType)
  },
  editCallType (loadType) {
    return Api().put('/call-types', loadType)
  },
  deleteCallType (id) {
    return Api().delete(`/call-types/${id}`)
  },
  getAllCallTypes () {
    return Api().get('/call-types/all')
  }
}
