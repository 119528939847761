<template>
  <div class="TruckHistory">
    <v-progress-circular
      v-if="loading"
      indeterminate
      class="TruckHistory__loader"
      color="primary"
    ></v-progress-circular>

    <p class="TruckHistory__empty" v-else-if="!history.length">
      No field history ...
    </p>

    <v-list v-else class="TruckHistory__list">
      <template v-for="(item, index) of history">
        <v-list-item :key="`field-history-${item._id}`">
          <v-list-item-content class="TruckHistory__content">
            <div class="TruckHistory__left">
              <p class="TruckHistory__change">
                Changed from
                <strong>{{ item.oldState || emptyString }}</strong> to
                <strong>{{ item.newState }}</strong>
              </p>

              <p class="TruckHistory__time">
                {{ new Date(item.date) | formatDate('DD/MM/YYYY') }}
              </p>

              <p v-if="index < history.length - 1 && item.type === 'DIVISION_CHANGED'" class="TruckHistory__time">
                {{ calculateDateDifference(item.date, history[index + 1].date) }} on {{ item.newState }}
              </p>
            </div>

            <div class="TruckHistory__right">
              <div class="TruckHistory__actions" v-if="type === 'DIVISION_CHANGED'">
                <v-icon small color="primary" @click="handleEditHistory(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  small
                  color="error"
                  @click="handleDeleteHistory(item._id)"
                  >mdi-delete</v-icon
                >
              </div>

              <p class="TruckHistory__user">
                {{ item.userEmail }}
              </p>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="`divider-${index}`"
          v-if="index < history.length - 1"
        ></v-divider>
      </template>
    </v-list>

    <v-btn
      v-if="type === 'DIVISION_CHANGED'"
      @click="addDivisonHistory"
      elevation="2"
      small
      block
      color="success"
    >Add history</v-btn>

    <v-dialog v-model="showDialog" width="400px" persistent>
      <v-card>
        <form @submit.prevent="handleSubmitForm" data-vv-scope="history">
          <v-card-title class="lighten-4 py-4 title">
            Edit division history
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :return-value.sync="form.date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="datePicker = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.datePicker.save(form.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-select
                  v-model="form.oldState"
                  :items="companiesAll"
                  placeholder="Old state"
                />
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-select
                  v-model="form.newState"
                  :items="companiesAll"
                  placeholder="New state"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="handleCloseDialog"
              >Cancel</v-btn
            >
            <v-btn
              color="info"
              :loading="loading"
              :disabled="loading"
              type="submit"
              >Edit</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from '../../services/TruckService'
import moment from 'moment'
import {mapGetters} from 'vuex'

export default {
  name: 'TruckPlateHistory',

  props: ['truckId', 'type'],

  data() {
    return {
      loading: false,
      history: [],
      showDialog: false,
      datePicker: false,
      form: {
        oldState: null,
        newState: null,
        date: null,
        id: null,
      },
    }
  },
  computed: {
    ...mapGetters('common', ['companiesAll']),
    emptyString() {
      if (this.type === 'DIVISION_CHANGED') {
        return 'no division'
      } else {
        return 'no data'
      }
    }
  },

  async mounted() {
    this.loading = true
    await this.fetchFieldHistory()
    await this.$store.dispatch('common/getAllCompanies')
    this.loading = false
  },

  methods: {

    calculateDateDifference(date1, date2) {

      const date1Moment = moment(date1)
      const date2Moment = moment(date2)
      
      const years = date2Moment.diff(date1Moment, 'years')
      date1Moment.add(years, 'years')

      const months = date2Moment.diff(date1Moment, 'months')

      let result = ''
      if (years > 0) {
        result += `${years}y`
      }
      if (months > 0) {
        result += `${years > 0 ? ' and ' : ''}${months}m`
      }
      return result || '0m'
    },

    async fetchFieldHistory() {
      try {
        const { data } = await service.getFieldHistory(this.truckId, this.type)
        this.history = data.changeLogs
      } catch (error) {
        console.warn(error)
      }
    },

    handleCloseDialog() {
      this.form = {
        oldState: null,
        newState: null,
        date: null,
        id: null,
      }
      this.showDialog = false
    },

    async handleSubmitForm() {
      this.loading = true
      try {
        const { id, ...data } = this.form
        if (id) {
          await service.editFieldHistory(id, data)
        } else {
          data.type = this.type
          data.truckId = this.truckId
          await service.addFieldHistory(data)
        }
        this.handleCloseDialog()
        await this.fetchFieldHistory()
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },

    handleEditHistory(item) {
      this.form.oldState = item.oldState
      this.form.newState = item.newState
      this.form.date = moment(item.date).format('YYYY-MM-DD')
      this.form.id = item._id
      this.showDialog = true
    },

    addDivisonHistory() {
      this.showDialog = true
    },

    async handleDeleteHistory(id) {
      const confVal = confirm('Are you sure you want to delete this entry?')
      if (confVal) {
        this.loading = true
        try {
          await service.deleteFieldHistory(id)
          await this.fetchFieldHistory()
        } catch (error) {
          console.warn(error)
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
.TruckHistory {
  position: relative;
  min-width: 400px;
  min-height: 200px;

  &__loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }

  &__empty {
    padding: 16px;
    text-align: center;
  }

  &__list {
    max-height: 200px;
    overflow-y: scroll;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__left,
  &__right {
    flex: 1 !important;
  }

  &__right {
    text-align: right;
  }

  &__change {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 6px !important;
  }

  &__time,
  &__user {
    color: rgb(33, 150, 243);
    font-size: 11px;
    margin: 0 !important;
  }
}
</style>
