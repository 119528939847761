import Api from '@/services/Api'

export default {
  getTableOrder (userId, tableName) {
    return Api().get(`/table-order/${userId}/${tableName}`)
  },
  editTableOrder (userId, tableName, columns) {
    return Api().put(`/table-order/${userId}/${tableName}`, { columns })
  },
  createTableOrder (data) {
    return Api().post('/table-order', data)
  },
  deleteTableOrder (userId, tableName) {
    return Api().delete(`/table-order/${userId}/${tableName}`)
  }
}