import dotInspectionsService from '../../services/DotInspectionsService'

const state = {
  selectedShift: null,
  eldSearchActivated: false,
  eldSearch: {
    byName: '',
    byTruck: ''
  },
  dotInspections: [],
  locked: []
}

const getters = {
  selectedShift: (state, getters, rootState) => {
    if (rootState.auth.user === null)
      return null
    return state.selectedShift
  },
  search: (state) => {
    return state.eldSearch
  },
  eldSearchActivated: (state) => {
    return state.eldSearchActivated
  },
  dotInspections: (state) => {
    return state.dotInspections
  },
  locked: (state) => {
    return state.locked
  }
}

const actions = {
  setShiftId ({commit}, id) {
    commit('setShiftId', id)
  },
  updateSearch ({commit}, search) {
    commit('setSearch', search)
  },
  activateSearch ({commit}, active) {
    commit('setActiveSearch', active)
  },
  fetchAllDotInspections ({commit}) {
    dotInspectionsService.getAllDotInspections().then(res => {
      commit('setDotInspections', res.data)
    })
  },
  addDot ({commit}, dot) {
    commit('addDotInspection', dot)
  },
  editDot ({commit}, dot) {
    commit('editDotInspection', dot)
  },
  deliteDot ({commit}, dot) {
    commit('deliteDotInspection', dot)
  },
  setLocked ({commit}, locked) {
    commit('setLocked', locked)
  }
}

const mutations = {
  setShiftId (state, id) {
    state.selectedShift = id
  },
  setSearch (state, search) {
    state.eldSearch.byName = search.byName
    state.eldSearch.byTruck = search.byTruck
  },
  setActiveSearch( state, active) {
    state.eldSearchActivated = active
  },
  setDotInspections(state, data) {
    state.dotInspections = data
  },
  addDotInspection( state, dot) {
    state.dotInspections.push(dot)
  },
  editDotInspection( state, dot) {
    let tmpDots = state.dotInspections.slice()
    let index = tmpDots.findIndex(el => el._id === dot._id)
    tmpDots.splice(index, 1, dot)
    state.dotInspections = tmpDots
  },
  deliteDotInspection( state, dot) {
    const tmpDots = state.dotInspections.slice()
    tmpDots.splice(tmpDots.findIndex(el => el._id === dot._id), 1)
    state.dotInspections = tmpDots
  },
  setLocked (state, locked) {
    state.locked = locked
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
