<template>
  <div class="UnitsHistory">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :options="options"
      :items="items"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      disable-sort
      dense
      @update:options="handleOptionsChange"
    >
      <template v-slot:item.contractId="{ item }">
        <router-link
          class="UnitsHistory__id-col"
          :to="`/contract-details/${item.contractObjectId}`"
        >
          {{ item.contractId }}
        </router-link>
      </template>

      <template v-slot:item.unitsType="{ item }">
        {{ getFinancingType(item) }}
      </template>

      <template v-slot:item.date="{ item }">
        {{ formatDate(item.date) }}
      </template>

      <template v-slot:item.bank="{ item }">
        <router-link
          v-if="item.bank"
          class="UnitsHistory__id-col"
          :to="`/contracts/${item.bank._id}`"
        >
          {{ item.bank.name }}
        </router-link>
      </template>

      <template v-slot:item.vin_number="{ item }">
        <td style="font-weight: bold">
          {{ item.vin_number }}
        </td>
      </template>

      <template v-slot:item.type="{ item }">
        <td
          :style="{
            backgroundColor: getTypeBackground(item.type),
            textTransform: 'capitalize',
          }"
        >
          {{ item.type }}
        </td>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Units History</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <table-filters
            class="mr-2"
            :initial-values="{ ...FILTERS_INITIAL }"
            :initial-form="filtersForm"
            hide-clear-button
            @filters-changed="handleFiltersChanged"
          />

          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import service from '../../services/ContractService'
import debounce from 'lodash/debounce'
import TableFilters from '../TableFilters.vue'

const footerProps = {
  'items-per-page-options': [20, 50, 100],
}

const FILTERS_INITIAL = {
  contractId: null,
  bank: null,
  company: null,
  type: null,
  dateFrom: null,
  dateTo: null,
  vin_number: null,
}

export default {
  components: {
    TableFilters,
  },

  data() {
    return {
      footerProps,
      dialog: false,
      options: { page: 1, itemsPerPage: 50 },

      headers: [
        {
          text: 'Contract ID',
          value: 'contractId',
          width: 200,
        },
        {
          text: 'Bank',
          value: 'bank',
          width: 200,
        },
        {
          text: 'Company',
          value: 'company',
          width: 200,
        },
        {
          text: 'VIN',
          value: 'vin_number',
          width: 200,
        },
        {
          text: 'Type',
          value: 'type',
          width: 200,
        },
        {
          text: 'Financing',
          value: 'unitsType',
          width: 200,
        },
        {
          text: 'Contract Date',
          value: 'date',
          width: 150,
        },
      ],
      items: [],
      totalItems: 0,
      editId: null,
      loading: false,

      companyOptions: [],
      unitOptions: [],
      queryTerm: '',

      FILTERS_INITIAL: {
        ...FILTERS_INITIAL,
        vin_number: this.$route.query.vin_number,
      },
      filters: { ...FILTERS_INITIAL, vin_number: this.$route.query.vin_number },
      filtersForm: {
        contractId: {
          label: 'Contract ID',
          type: 'text',
        },
        vin_number: {
          label: 'VIN',
          type: 'text',
        },
        bank: {
          label: 'Bank',
          type: 'select',
          itemValue: 'name',
          itemText: 'name',
          options: [],
        },
        company: {
          type: 'combobox',
          label: 'Company',
          options: ['Floyd Inc', 'Ego Express Inc', 'Floyd / Ego Express'],
        },
        type: {
          label: 'Type',
          type: 'select',
          options: [
            {
              text: 'Truck',
              value: 'truck',
            },
            {
              text: 'Trailer',
              value: 'trailer',
            },
          ],
        },
        unitsType: {
          type: 'select',
          label: 'Financing',
          options: [
            {
              text: 'Refinanced',
              value: ['rf-trailers', 'rf-trucks', 'rf-trucks-trailers'],
            },
            {
              text: 'Financed',
              value: ['f-trailers', 'f-trucks', 'f-trucks-trailers'],
            },
            { text: 'Paid with cash', value: ['c-trailers', 'c-trucks'] },
          ],
        },
        dateFrom: {
          type: 'date-time',
          label: 'From',
        },
        dateTo: { type: 'date-time', label: 'To' },
      },
    }
  },

  async created() {
    await this.fetchItems()
    await this.setupFilters()
  },

  methods: {
    async fetchItems() {
      this.loading = true
      try {
        const { data } = await service.getUnitsHistory({
          ...this.options,
          ...this.filters,
        })
        this.totalItems = data.total
        this.items = data.docs
      } catch (error) {
        console.error('UnitsHistory fetchItems()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async setupFilters() {
      try {
        const { data } = await service.getUnitFilterOptions({ forUnits: true })
        this.filtersForm.bank.options = [...data.banks]
      } catch (error) {
        console.error('AccountingUnits setupFilters()', error)
      }
    },

    getFinancingType(item) {
      if (this.isRefinanced(item.unitsType)) return 'Refinanced'
      if (this.isForCash(item.unitsType)) return 'Paid with cash'
      if (this.isFinanced(item.unitsType)) return 'Financed'
      return '/'
    },

    isForCash(unitsType) {
      return ['c-trailers', 'c-trucks'].includes(unitsType)
    },

    isRefinanced(unitsType) {
      return ['rf-trailers', 'rf-trucks', 'rf-trucks-trailers'].includes(
        unitsType
      )
    },

    isFinanced(unitsType) {
      return ['f-trailers', 'f-trucks', 'f-trucks-trailers'].includes(
        unitsType
      )
    },

    getTypeBackground(type) {
      let color = '#ffffff'
      if (type === 'truck') return '#D8EAD3'
      if (type === 'trailer') return '#FFF2CC'
      return color
    },

    async handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      await this.fetchItems()
    },

    formatDate(date) {
      return this.$options.filters.formatDateObj(date, 'MM/DD/YYYY')
    },

    handleFiltersChanged: debounce(function (filters) {
      this.items = []
      this.filters = { ...filters }
      this.options.page = 1

      this.fetchItems()
    }, 200),
  },
}
</script>

<style lang="scss">
.UnitsHistory {
  tr:hover {
    cursor: pointer;
  }

  &__id-col {
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    color: #3f51b5;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td:not(.no-border) {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
      position: relative;

      .header-filter-btn {
        position: absolute;
        top: 2px;
        right: 2px;
      }
    }

    td {
      padding: 0 6px !important;
    }
  }
}
</style>
