<template>
  <v-autocomplete
    ref="v-auto"
    :value="fieldValue"
    :items="items"
    :item-text="field.itemText || 'name'"
    return-object
    :label="field.label"
    :search-input.sync="searchInput"
    hide-no-data
    :loading="loading"
    :placeholder="field.label"
    prepend-icon="mdi-database-search"
    dense
    row
    solo
    hide-details
    small
    clearable
    @change="(v) => $emit('change', v)"
  ></v-autocomplete>
</template>

<script>
import AutocompleteMixin from './AutocompleteMixin'

export default {
  mixins: [AutocompleteMixin],
  props: ['fieldValue', 'field'],
  emits: ['changed']
}
</script>