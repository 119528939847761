<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div @click="on.click" style="cursor: pointer">
        <span v-if="recruiter" style="width: min-content">
          {{ formatDate(recruiter.since) }} &mdash;
          {{ formatDate(recruiter.until) }}
        </span>
        <v-icon v-else small color="black lighten-2">
          mdi-clock-outline
        </v-icon>
      </div>
    </template>
    <v-card class="pa-2">
      <v-progress-circular
        v-if="loading"
        indeterminate
        class="trailer-history-loader"
        color="primary"
      />
      <ul class="RecruiterHistory__item-list pa-0" v-else-if="showHistory">
        <li
          v-for="rh in reveresed"
          :key="rh._id"
          class="RecruiterHistory__item"
        >
          <p class="pa-0 ma-0">
            <strong>Recruiter:</strong> {{ rh.name }}
            <strong>Status:</strong>&nbsp;
            <span style="text-transform: capitalize">{{ rh.status }}</span>
          </p>
          <p class="pa-0 ma-0">
            <strong>From:</strong> {{ formatDate(rh.since) }}
            <strong>To:</strong> {{ formatDate(rh.until) }}
          </p>
        </li>
      </ul>
      <p v-else class="ma-0">There is no recruitment history.</p>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment'
import service from '../../services/EmployeesService'

export default {
  name: 'RecruiterHistory',

  props: {
    recruiter: {
      type: Object,
      required: true
    },
    employeeId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      menu: false,
      history: [],
      loading: false
    }
  },

  watch: {
    menu(v) {
      if (v) {
        this.getHistory()
      }
    }
  },

  computed: {
    reveresed() {
      if (!this.history) return []
      return [...this.history].reverse()
    },

    showHistory() {
      return Array.isArray(this.history) && this.history.length > 0
    },
  },

  methods: {
    async getHistory() {
      try {
        this.loading = true
        const { data } = await service.getRecruiterHistory(this.employeeId)
        if (Array.isArray(data.docs)) this.history = data.docs
      } catch (error) {
        console.error('RecruiterHistory getHistory()', error)
      } finally {
        this.loading = false
      }
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
  },
}
</script>

<style lang="scss">
.RecruiterHistory {
  &__item-list {
    list-style: none;
    max-height: 400px;
    overflow-y: scroll;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid #c6c6c6;
    }
  }
}
</style>
