<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-alert
              :value="message !== null"
              :type="messageType"
              transition="scale-transition"
            >
              {{message}}
            </v-alert>
            <v-card class="elevation-12">
              <v-form @submit.prevent="onSubmit">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Reset Password</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-text-field
                      :disabled="messageType === 'success'"
                      v-validate="'required|email'"
                      :error="errors.has('email')"
                      :error-messages="errors.first('email')"
                      placeholder="Email"
                      ref="email"
                      v-model="email"
                      prepend-icon="mdi-email"
                      name="email"
                      label="Email"
                      type="text">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="messageType !== 'success'" color="primary" type="submit" :loading="loading" :disabled="loading">Send</v-btn>
                  <v-btn
                    v-else
                    color="primary"
                    text
                    :to="{name: 'login'}"
                  >
                    Go to Login
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import User from '../../services/UsersService'
export default {
  data: () => ({
    message: null,
    messageType: 'warning',
    email: ''
  }),
  mounted () {
    this.$refs.email.focus()
  },
  methods: {
    onSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.dispatch('common/setLoading', true)
          User.resetPassword(this.email).then(res => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data.error) {
              this.messageType = 'warning'
              this.message = this.$t(res.data.message)
            } else if (res.data.success) {
              this.messageType = 'success'
              this.message = this.$t(res.data.message)
            }
          }).catch(err => {
            if (err) {
              console.log(err)
            }
          })
        }
      })
    }
  },
  computed: {
    ...mapGetters('common', ['loading'])
  }
}
</script>
