<template>
  <div class="mention-input-holder" :class="[{dark: dark}]">
    <div class="mention-list" :class="[{loading: myLoading}]">
      <v-progress-circular
        v-if="myLoading"
        indeterminate
        :size="30"
        :width="3"
        class="mention-loader"
        color="primary"
      ></v-progress-circular>
      <div v-else-if="showList" class="mention-list-item" :id="`mention-item-${index}`" :class="[{selected: selectedIndex === index }]" @click="selectUser(user)" v-for="(user, index) in users" :key="user._id">
        <span class="username">{{user.name}}</span>
        <span class="email">{{user.email}}</span>
      </div>
    </div>
    <v-textarea
      @keydown.enter="onEnter($event)"
      @input="handleInput($event)"
      @blur="onBlur"
      @click:append="onAppendClick"
      ref="minput"
      :disabled="disabled"
      :loading="loading"
      :autofocus="autofocus"
      class="mention-input"
      :name="name"
      :label="''"
      :placeholder="placeholder"
      :rules="rules"
      :hide-details="'auto'"
      :rows="rows"
      :value="value"
      :outlined="outlined"
      :counter="counter"
      :no-resize="noResize"
      :append-icon="appendIcon"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import userService from '../../services/UsersService'

const debounce = (func, delay) => {
  let inDebounce
  return function() {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    rows: {
      type: Number,
      default: 2
    },
    maxChar: {
      type: Number,
      default: 500
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    counter: {
      type: [Boolean, Number],
      default: false
    },
    appendIcon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    noResize: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      key: '@',
      showList: false,
      lastSearchText: '',
      searchText: '',
      kIndex: null,
      myLoading: false,
      selectedIndex: 0,
      users: [],
      rules: [v => v.length <= this.maxChar || `Max ${this.maxChar} characters`]
    }
  },
  computed: {
    ...mapGetters('common', ['dark'])
  },
  methods: {
    onEnter (e) {
      if (this.value.length <= this.maxChar) {
        this.$emit('onEnter', e)
      }
    },
    onAppendClick() {
      if (this.value.length <= this.maxChar) {
        this.$emit('onAppendClick', this.value)
      }
    },
    handleInput: function (value) {
      this.$emit('input', value)
      this.checkKey(value)
    },
    closeList () {
      this.showList = false
      this.myLoading = false
      this.selectedIndex = 0
      this.searchText = null
      this.kIndex = null
    },
    onBlur () {
      setTimeout(() => {
        this.closeList()
      }, 300)
    },
    attach () {
      if (this.$refs.minput.$refs.input) {
        this.$refs.minput.$refs.input.addEventListener('keydown', this.onKeyDown)
      }
    },
    detach () {
      if (this.$refs.minput.$refs.input) {
        this.$refs.minput.$refs.input.removeEventListener('keydown', this.onKeyDown)
      }
    },
    onKeyDown (e) {
      if (this.key && this.showList) {
        let wrapperDiv = document.getElementsByClassName('mention-list')[0]
        if (e.key === 'ArrowDown' || e.keyCode === 40) {
          this.selectedIndex++
          if (this.selectedIndex >= this.users.length) {
            this.selectedIndex = 0
          }
          let selItem = document.getElementById(`mention-item-${this.selectedIndex}`)
          wrapperDiv.scrollTop = selItem.offsetTop + selItem.clientHeight - wrapperDiv.clientHeight
          this.cancelEvent(e)
        }
        if (e.key === 'ArrowUp' || e.keyCode === 38) {
          this.selectedIndex--
          if (this.selectedIndex < 0) {
            this.selectedIndex = 0
          }
          let selItem = document.getElementById(`mention-item-${this.selectedIndex}`)
          wrapperDiv.scrollTop = selItem.offsetTop + selItem.clientHeight - wrapperDiv.clientHeight
          this.cancelEvent(e)
        }
        if ((e.key === 'Enter' || e.key === 'Tab' || e.keyCode === 13 || e.keyCode === 9) && this.users.length > 0) {
          this.selectUser(this.users[this.selectedIndex])
          this.cancelEvent(e)
        }
        if (e.key === 'Escape' || e.keyCode === 27) {
          this.closeMenu()
          this.cancelEvent(e)
        }
      }
    },
    cancelEvent (e) {
      e.preventDefault()
      e.stopPropagation()
    },
    getSelectionStart () {
      return this.$refs.minput.$refs.input.selectionStart
    },
    getLastKeyBeforeCaret (value, caretIndex) {
      return value.lastIndexOf(this.key, caretIndex)
    },
    getLastSearchText (value, caretIndex, keyIndex) {
      if (keyIndex !== -1) {
        const searchText = value.substring(keyIndex + 1, caretIndex)
        
        // ako je @ spojeno sa tekstom pre vratiti null
        if (keyIndex > 0 && !/\s/.test(value.substring(keyIndex - 1, caretIndex))) {
          return null
        }

        // If there is a space we close the menu
        if (!/\s/.test(searchText)) {
          return searchText
        }
      }
      return null
    },
    getUsers: debounce((vm, searchText) => {
      userService.getUsersMention(searchText).then(res => {
        vm.myLoading = false
        vm.users = res.data.map(user => {
          user.key = user.name.split(' ').join('_').toLowerCase()
          return user
        })
      })
    }, 300),
    checkKey (value) {
      const index = this.getSelectionStart()
      if (index >= 0) {
        const keyIndex = this.getLastKeyBeforeCaret(value, index)
        this.kIndex = keyIndex
        const searchText = this.lastSearchText = this.getLastSearchText(value, index, keyIndex)

        if (!(keyIndex < 1 || /\s/.test(value[keyIndex - 1]))) {
          this.showList = false
        }

        if (searchText != null && searchText !== '') {
          this.showList = true
          this.searchText = searchText
          this.myLoading = true
          this.getUsers(this, searchText)
          
        } else {
          this.closeList()
        }
      } else {
        this.closeList()
      }
    },
    selectUser (user) {
      if (this.searchText)
        this.$emit('input', this.replaceText(this.value, this.searchText, user.key, this.kIndex + 1))
      this.closeList()
    },
    replaceText (text, searchText, newText, index) {
      return text.slice(0, index) + newText + text.slice(index + searchText.length + 1, text.length)
    }
  },
  mounted () {
    this.attach()
  },
  beforeDestroy () {
    this.detach()
  }
}
</script>

<style lang="scss">
  .mention-input-holder {
    
    .mention-list {
      max-height: 110px;
      overflow-y: scroll;
      position: relative;

      &.loading {
        text-align: center;
      }

      .mention-loader {
        margin-top: 8px;
      }

      .mention-list-item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding: 0 8px;

        &:hover {
          .username {
            color: rgba(63, 81, 181);
          }
        }

        &.selected {
          background-color: rgba(63, 81, 181, 0.2);
        }

        .username {
          color: rgba(0, 0, 0, 0.6);
          font-weight: 600;
          font-size: 13px;
        }

        .email {
          color: rgba(0, 0, 0, 0.6);
          font-size: 12px;
        }
      }
    }

    .mention-input {
      padding: 8px;
      margin-top: 6px;
    }

    &.dark {
      .mention-list {
        .mention-list-item {
          &:hover {
            color: rgba(106, 191, 105, 0.2);
          }
          &:hover {
            .username {
              color: rgba(106, 191, 105);
            }
          }
          &.selected {
            background-color: rgba(106, 191, 105, 0.2);
          }
          .username {
            color: #fff;
          }

          .email {
            color: #fff;
          }
        }
      }
    }
  }
</style>