import Vue from 'vue'
// import moment from 'moment/src/moment'
import moment from 'moment-timezone'

Vue.filter('size', function (bytes) {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
})
Vue.filter('formatDurationFromSeconds', function (seconds) {
  if (seconds) {
    let hours = Math.floor(seconds / 3600)
    let remainingMinutes = Math.floor((seconds % 3600) / 60)
    let remainingSeconds = seconds % 60

    return `${hours.toString().padStart(2, '0')}h :${remainingMinutes.toString().padStart(2, '0')}m :${remainingSeconds.toString().padStart(2, '0')}s`
  }
})



Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
})

Vue.filter('formatDateUtc', function (value) {
  if (value) {
    return moment.utc(String(value)).format('MM/DD/YYYY')
  }
})

Vue.filter('formatDatePicker', function (value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
})

Vue.filter('formatDateObj', function (date, format = 'MM/DD/YYYY') {
  if (date) {
    return moment(date).format(format)
  }
})

Vue.filter('formatDateUSA', function (date, format = 'MM/DD/YYYY') {
  if (date) {
    return moment(date).tz('America/Chicago').format(format)
  }
})

Vue.filter('truncate', function (text, length = 30) {
  if (text) {
    return typeof text === 'string' && text.length > length ? text.slice(0, length) + '...' : text
  }
})

Vue.filter('convertDate', function (value) {
  if (typeof value === 'string' && value !== '') {
    let dateTimeSplited = value.split(' ')
    let timeSplited = dateTimeSplited[1].split(':')
    let dateSplited = dateTimeSplited[0].split('-')
    let mydate = new Date(dateSplited[2], dateSplited[1] - 1, dateSplited[0])
    mydate.setHours(timeSplited[0], timeSplited[1], timeSplited[2])
    return mydate
  }
  return value
})

Vue.filter('highlight', function(word, query){
  let check = new RegExp(query, 'ig')
  return word.toString().replace(check, (matchedText) =>{
    return ('<span class="highlight">' + matchedText + '</span>')
  })
})

Vue.filter('driverName', function(name){
  const replaceThisStrings = ['(NEW) ', ' *', ' (company driver)', ' (time)']
  replaceThisStrings.forEach(item => name = name.replace(item, ''))
  return name
})

Vue.filter('money', function (value) {
  const formater = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
  return formater.format(value)
})

Vue.filter('secToHours', function(seconds) {
  let hours = Math.floor(seconds / 3600)
  let remainingSeconds = seconds % 3600
  let minutes = Math.floor(remainingSeconds / 60)
  return `${hours} h ${minutes} m`
})

Vue.filter('formatTimeUSA', function (value) {
  if (value) {
    let [hour, min] = value.split(':')
    
    let hours = parseInt(hour, 10)
    let sufiks = 'AM'

    if (hours >= 12) {
      sufiks = 'PM'
      if (hours > 12) {
        hours -= 12
      }
    } else if (hours === 0) {
      hours = 12
    }

    return `${hours.toString().padStart(2, '0')}:${min} ${sufiks}`
  }
})
