export const FOOTER_PROPS = {
  'items-per-page-options': [10, 20, 50, 100],
}

export const UNIT_STATUS_OPTIONS_LOAN = [
  { header: 'Active' },
  { text: 'Active Loan ', value: 'ACTIVE_LOAN', },
  { header: 'Inactive' },
  { text: 'Returned', value: 'RETURNED' },
]

export const UNIT_STATUS_OPTIONS = [
  { header: 'Active Unit' },
  { text: 'Active Unit', value: 'CLAIM', },
  { header: 'Inactive Unit' },
  { text: 'Returned', value: 'RETURNED' },
  { text: 'Paid In Full', value: 'PAID_IN_FULL' },
  { text: 'Total Damage', value: 'TOTAL_DAMAGE' },
  { text: 'Newer Pick Up', value: 'NEVER_PICK_UP' },
]

export const TRUCK_UNITS = ['TRUCK_RENT', 'TRUCK_LEASE_TO_LEASE', 'TRUCK_LEASE_TO_BUY']
export const TRAILER_UNITS = ['TRAILER_RENT', 'TRAILER_LEASE_TO_LEASE']

export const UNIT_OPTIONS = [
  { header: 'Truck' },
  { text: 'Rent', value: 'TRUCK_RENT', },
  { text: 'Lease to Lease', value: 'TRUCK_LEASE_TO_LEASE' },
  { text: 'Lease to Buy', value: 'TRUCK_LEASE_TO_BUY' },
  { header: 'Trailer' },
  { text: 'Rent', value: 'TRAILER_RENT' },
  { text: 'Lease to Lease', value: 'TRAILER_LEASE_TO_LEASE' },
  { header: 'Loan' },
  { text: 'Loan', value: 'LOAN' },
]

export const ACTIVE_STATUS_TYPES = ['CLAIM', 'ACTIVE_LOAN']
export const INACTIVE_STATUS_TYPES = ['RETURNED', 'PAID_IN_FULL', 'TOTAL_DAMAGE', 'NEVER_PICK_UP']

export const CONTRACT_TERM_OPTIONS = [
  '6 months',
  '1 year',
  '2 year',
  '3 year',
  '1 month',
  '2 months',
  '3 months',
  '4 months',
  '5 months',
  '7 months',
  '8 months',
  '9 months',
  '10 months',
  '11 months',
  'Week to Week',
]

export const UNIT_FORM_CONFIG_BY_TYPE = {
  'TRUCK_RENT': {
    'registration': {
      'options': [
        { text: 'Ours - Included', value: 'OURS_INCLUDED' },
      ],
      'additionalFields': false
    },
    'bobtail_insurance': {
      'options': [
        { text: 'Their', value: 'THEIR' },
        { text: 'Ours', value: 'OURS' },
        { text: 'United Ego Policy', value: 'UNITED_EGO_POLICY' },
      ],
      'additionalFields': ['requested_date']
    },
    'billing_periond': {
      'options': [
        { text: 'Weekly', value: 'WEEKLY' },
      ],
      'additionalFields': ['ammount']
    },
    docUpload: true,
  },
  'TRUCK_LEASE_TO_LEASE': {
    'registration': {
      'options': [
        { text: 'Ours', value: 'OURS' },
        { text: 'Their', value: 'Their' },
      ],
      'additionalFields': ['amount', 'start_date', 'until_date']
    },
    'bobtail_insurance': {
      'options': [
        { text: 'Their', value: 'THEIR' },
        { text: 'Ours', value: 'OURS' },
        { text: 'United Ego Policy', value: 'UNITED_EGO_POLICY' },
      ],
      'additionalFields': ['requested_date']
    },
    'billing_periond': {
      'options': [
        { text: 'Weekly', value: 'WEEKLY' },
        { text: 'Monthly', value: 'MONYHLY' },
      ],
      'additionalFields': ['ammount']
    },
    'contract_expired': true,
    docUpload: true,
  },
  'TRUCK_LEASE_TO_BUY': {
    'registration': {
      'options': [
        { text: 'Ours', value: 'OURS' },
        { text: 'Their', value: 'Their' },
      ],
      'additionalFields': ['amount', 'start_date', 'until_date']
    },
    'bobtail_insurance': {
      'options': [
        { text: 'Their', value: 'THEIR' },
        { text: 'Ours', value: 'OURS' },
        { text: 'United Ego Policy', value: 'UNITED_EGO_POLICY' },
      ],
      'additionalFields': ['ammount', 'requested_date']
    },
    'billing_periond': {
      'options': [
        { text: 'Weekly', value: 'WEEKLY' },
        { text: 'Monthly', value: 'MONYHLY' },
      ],
      'additionalFields': ['ammount'],
      docUpload: true,
    }
  },
  'TRAILER_RENT': {
    'registration': {
      'options': [
        { text: 'Ours - Included', value: 'OURS_INCLUDED' },
      ],
      'additionalFields': false
    },
    'bobtail_insurance': {
      'options': [
        { text: 'Their', value: 'THEIR' },
        { text: 'Ours', value: 'OURS' },
        { text: 'United Ego Policy', value: 'UNITED_EGO_POLICY' },
      ],
      'additionalFields': ['requested_date']
    },
    'billing_periond': {
      'options': [
        { text: 'Weekly', value: 'WEEKLY' },
        { text: 'Monthly', value: 'MONYHLY' },
      ],
      'additionalFields': ['ammount']
    },
    'trailer_usage_fee': true,
    docUpload: true,
  },
  'TRAILER_LEASE_TO_LEASE': {
    'registration': {
      'options': [
        { text: 'Ours - Included', value: 'OURS_INCLUDED' },
      ],
      'additionalFields': false
    },
    'bobtail_insurance': {
      'options': [
        { text: 'Their', value: 'THEIR' },
        { text: 'Ours', value: 'OURS' },
        { text: 'United Ego Policy', value: 'UNITED_EGO_POLICY' },
      ],
      'additionalFields': ['requested_date']
    },
    'billing_periond': {
      'options': [
        { text: 'Weekly', value: 'WEEKLY' },
        { text: 'Monthly', value: 'MONYHLY' },
      ],
      'additionalFields': ['ammount']
    },
  },
  'LOAN': {
    'registration': false,
    'bobtail_insurance': false,
    'billing_periond': {
      'options': [
        { text: 'Weekly', value: 'WEEKLY' },
        { text: 'Monthly', value: 'MONYHLY' },
      ],
      'additionalFields': ['ammount']
    },
  },
}