import Api from '@/services/Api'

export default {
  getEmployees(options) {
    return Api().get('/employees', {
      params: {
        page: options.page,
        perPage: options.itemsPerPage,
        search: options.search,
        statuses: options.statuses
      }
    })
  },
  addEmployee(body) {
    return Api().post('/employees', body)
  },
  editEmployee(id, body) {
    return Api().put(`/employees/${id}`, body)
  },
  deleteEmployee(id) {
    return Api().delete(`/employees/${id}`)
  },
  getRecruiters(options) {
    const params = {}

    if (options.name !== undefined) {
      params.name = options.name
    }

    if (options.autocomplete !== undefined) {
      params.autocomplete = options.autocomplete
    }

    return Api().get('/employees/recruiters', {
      params: params
    })
  },
  downloadSheet(params) {
    return Api().get('/employees/sheet', { params })
  },
  moveEmployees(body) {
    return Api().post('/employees/move', body)
  },
  getByRecruiter(id, options) {
    return Api().get(`/employees/by-recruiter/${id}`, {
      params: {
        page: options.page,
        perPage: options.itemsPerPage,
        search: options.search,
        statuses: options.statuses
      }
    })
  },
  getHistory(employeeId, type) {
    return Api().get(`/employees/logs/${employeeId}/${type}`)
  },
  getRecruiterHistory(employeeId) {
    return Api().get(`/employees/history/${employeeId}`)
  },
  groupedByRecruiters(options) {
    return Api().get('/employees/grouped-by-recruiters', {
      params: {
        page: options.page,
        perPage: options.itemsPerPage,
        recruiterId: options.recruiterId,
        statuses: options.statuses,
        search: options.search,
      }
    })
  }
}
