<template>
  <v-card>
    <v-card-title>Devices:</v-card-title>
    <v-simple-table class="pa-4 text-no-wrap">
      <template v-slot:default v-if="userTokens && userTokens.length">
        <thead>
          <tr>
            <th scope="col">
              BROWSER
            </th>
            <th scope="col">
              OS
            </th>
            <th scope="col">
              IP
            </th>
            <th scope="col">
              LOCATION
            </th>
            <th scope="col">
              SECRET
            </th>
            <th scope="col">
              EXP
            </th>
            <th scope="col">
              VERIFIED
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="token in userTokens"
            :key="token.secret"
          >
            <td>
              <template v-if="token.fingerprint">
                <v-icon v-if="browsersIconsMap[token.fingerprint.browserName]" color="success">{{browsersIconsMap[token.fingerprint.browserName]}}</v-icon>
                {{ `${token.fingerprint.browserName} - V: ${token.fingerprint.browserVersion}` }}
              </template>
              <template v-else>/</template>
            </td>
            <td>
              <v-icon class="mr-1">mdi-monitor</v-icon>{{token.fingerprint ? `${token.fingerprint.os} - ${token.fingerprint.osVersion}` : '/'}}
            </td>
            <td>{{token.fingerprint ? token.fingerprint.ip : '/'}}</td>
            <td>
              <template v-if="token.fingerprint && token.fingerprint.ipLocation">
                {{ `${token.fingerprint.ipLocation.country.name} (${token.fingerprint.ipLocation.latitude} / ${token.fingerprint.ipLocation.longitude})` }}
              </template>
              <temmplate v-else>/</temmplate>
            </td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ref="copyBtn"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="copySecret(token.secret)"
                    >
                    {{ token.secret }}
                    </v-btn>
                  </template>
                <span>Click to copy</span>
              </v-tooltip>
            </td>
            <td>
              {{ new Date(token.expDate) | formatDateObj }}
            </td>
            <td>{{token.verified ? 'YES' : 'NO'}}</td>
          </tr>
        </tbody>
      </template>
      <template v-slot:default v-else>
        <p class="body-1 text-center">No tokens yet</p>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'UserDevices',
  props: ['userTokens'],
  data: () => ({
    browsersIconsMap: {
      'Chrome': 'mdi-google-chrome',
      'Safari': 'mdi-apple-safari',
      'Firefox': 'mdi-firefox',
      'Opera': 'mdi-opera',
    }
  }),
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    copySecret(secret) {
      this.$copyText(secret, this.$refs.copyBtn[0].$el).then(() => {
        this.setMessage(this.$t('COPY_TO_CLIPBOARD_SUCCESS'))
      })
    }
  }
}
</script>