<template>
  <div ref="talkjs" style="width: 1000px; padding:0 0 30px 300px; height: 500px">
    <v-progress-circular
        v-if="loading"
        indeterminate
        class="dashboard-loader"
        color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
import Talk from 'talkjs'
import { mapGetters } from 'vuex'
// import AppUserService from '@/services/AppUsersService'

export default {
  name: 'Inbox',
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
  },
  mounted() {
    Talk.ready
    const me = new Talk.User(this.currentUser.id)

    const talkSession = new Talk.Session({
      appId: this.$config.TALKJS_APP_ID,
      me: me,
    })

    const inbox = talkSession.createInbox()

    inbox.mount(this.$refs.talkjs)

  },
}
</script>
