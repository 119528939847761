<template>
  <div class="nav">
    <v-row class="nav-items" justify="center" align="center">
      <v-col class="d-flex justify-center" v-for="(item, index) in getLinks" :key="index" cols="2" md="2" >
        <v-menu offset-x open-on-hover v-if="item.children">
          <template v-slot:activator="{ on, attrs }">
            <v-card class="nav-card" width="180" height="200" v-bind="attrs" v-on="on">
              <v-icon size="42px" style="margin-bottom: 16px;">{{ item.icon }}</v-icon>
              <div>{{item.lang_id ?  $t(item.lang_id) : item.text }}</div>
            </v-card>
          </template>
          <v-list>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.url"
              link
              exact
            >
              <v-list-item-title v-text="$t(child.lang_id)"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="child.icon"></v-icon>
              </v-list-item-icon>
          </v-list-item>
          </v-list>
        </v-menu>
        <v-card v-else :to="item.url" class="nav-card" width="180" height="200">
          <v-icon size="42px" style="margin-bottom: 16px;">{{ item.icon }}</v-icon>
          <div>{{item.lang_id ?  $t(item.lang_id) : item.text }}</div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import {unionBy} from 'lodash'
import {mapGetters} from 'vuex'
import { naviConfig, permissionLinksMap } from './naviConfig.js'

export default {
  name: 'GridNavigation',
  data: () => (
    {
      items: naviConfig
    }
  ),
  computed: {
    ...mapGetters('auth', [
      'getRoles', 'user', 'permissions'
    ]),
    getLinks () {
      let links = []
      if (this.getRoles) {
        for (let role of this.getRoles) {
          links = [...this.items[role]]
        }
      }

      // remove home route from grid nav
      let homeIndex = links.findIndex(link => link.url === '/')
      links.splice(homeIndex, 1)

      // Push links by permissions
      const linksFromPermisions = []
      for (let perm in this.permissions) {
        if (permissionLinksMap[perm]) {
          // check if this link exists in links
          let notFound = true
          for (let link of links) {
            if (link.children && link.children.length) {
              let index = link.children.findIndex(child => child.url === permissionLinksMap[perm].url)
              if (index !== -1) {
                notFound = false
              }
            } else if (link.url === permissionLinksMap[perm].url) {
              notFound = false
            }
          }

          if (notFound) linksFromPermisions.push(permissionLinksMap[perm])
        }
      }
      const unionArray = unionBy(links, linksFromPermisions, 'url')
      return unionArray
    },

  }
}
</script>

<style scoped>
.nav-card {
  padding: 16px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nav-card:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.nav-card div {
  font-size: 20px;
  font-weight: bold;
  margin-top: 8px;
}

.nav-card v-icon {
  color: #2196F3;
}
</style>