<template>
  <div class="message-content" :class="messageClass">

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <span class="message-sender" v-bind="attrs" v-on="on">{{getFirstLetter}}</span>
      </template>
      <span>{{userEmail}}</span>
    </v-tooltip>

    <span class="message-text" v-html="messageText"></span>
    <span class="message-time">{{created | formatDateUSA('MM/DD/YYYY HH:mm')}}</span>

    <v-menu v-if="selectedGroup === 'trucks'" bottom right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="editMessage">
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>
        <v-list-item @click="deleteMessage">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Edit Dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>Edit Message</v-card-title>
        <v-card-subtitle>Edit the message content below:</v-card-subtitle>
        <v-card-text>
          <v-textarea v-model="editedMessage" label="Message" outlined></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateMessage">Save</v-btn>
          <v-btn @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Autolinker from 'autolinker'
import ChatService from '../../services/ChatService'

export default {
  props: {
    content: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    userEmail: {
      type: String,
      required: true
    },
    created: {
      type: [Date, String],
      required: true
    },
    messageId: {
      type: String,
      required: false
    },
    selectedGroup: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      dialog: false,
      editedMessage: this.content
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    messageClass() {
      if (this.user && this.user._id === this.userId) return 'sent'
      return 'recived'
    },
    getFirstLetter() {
      return this.userEmail.charAt(0).toUpperCase()
    },
    messageText() {
      return Autolinker.link(this.content, {
        className: 'chatLink',
        truncate: {length: 50, location: 'smart'}
      })
    },
  },
  methods: {
    editMessage() {
      this.editedMessage = this.content
      this.dialog = true
    },
    updateMessage() {
      const updatedMessage = {
        messageId: this.messageId,
        content: this.editedMessage
      }
      ChatService.updateMessage(updatedMessage).then(() => {
        this.$emit('message-updated', updatedMessage)
        this.dialog = false
      }).catch(error => {
        console.log('Error updating message:', error)
      })
    },
    deleteMessage() {
      this.$emit('deleteMessage', this.userId)
    }
  }
}
</script>

<style lang="scss">
.message-content {
  margin-bottom: 16px;
  display: flex;
  position: relative;

  &.recived {
    margin-left: 16px;
    .message-text {
      background-color: rgb(234, 234, 234);
      color: rgb(34, 34, 34);
    }

    .message-time {
      left: 34px;
    }
  }

  &.sent {
    margin-right: 16px;
    justify-content: flex-end;
    .message-text {
      background-color: #4e8cff;
      color: #fff;
    }
  }
  .message-text {
    display: block;
    padding: 5px 20px;
    border-radius: 6px;
    font-size: 16px;
    position: relative;
    max-width: calc(100% - 100px);
    word-wrap: break-word;
    white-space: pre-wrap;

    .chatLink {
      text-decoration: none;
    }
  }

  .message-time {
    position: absolute;
    bottom: -15px;
    right: 2px;
    font-size: 10px;
  }

  .v-menu {
    position: absolute;
    top: -10px;
    right: 5px;
  }

  .message-sender {
    width: 25px;
    height: 25px;
    border: 1px solid black;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-right: 4px;
  }
}
</style>