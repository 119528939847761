<template>
  <div>
    <v-dialog
      v-if="messages[0]"
      :key="messages[0]._id"
      persistent
      max-width="460"
      :value="true"
    >
      <v-card>
        <v-card-title class="headline">
          {{messages[0].name}}
        </v-card-title>
        <v-card-text style="white-space: pre-wrap;" v-html="messages[0].content"></v-card-text>
        <v-card-actions>
          <span class="caption">{{messages[0].created | formatDateObj('MM/DD/YYYY HH:mm')}}</span>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeMessage(messages[0]._id)">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import messageService from '@/services/MessagesService'

export default {
  computed: {
    ...mapGetters('common', ['messages']),
    ...mapGetters('auth', ['user'])
  },
  methods: {
    closeMessage (id) {
      messageService.deleteUserFromMsg(id, this.user._id).then(() => {
        this.$store.dispatch('common/removeMessage', id)
      })
    }
  },
  sockets: {
    connect() {
      this.$socket.client.emit('joinToMessages')
    },
    message (message) {
      let index = message.userIds.indexOf(this.user._id)
      if (index !== -1) {
        this.$store.dispatch('common/addMessage', message)
      }
    }
  },
  mounted() {
    messageService.getUserMessages().then(response => {
      for(let i = 0; i < response.data.length; i++){
        this.$store.dispatch('common/addMessage', response.data[i])
      }
    })

    this.$socket.client.emit('joinToMessages')
  },
  beforeDestroy() {
    this.$socket.client.emit('leave_messages')
  }
}
</script>