const SMALL_RADIUS = 16 // Small radius
const MEDIUM_RADIUS = 20 // Medium radius
const LARGE_RADIUS = 24 // Big radius

const SMALL_THRESHOLD = 10 // Less then 10 -> small pin
const LARGE_THRESHOLD = 50 // More then 50 -> big pin

const groupedPinSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="{diameter}" height="{diameter}" viewBox="0 0 {viewBox} {viewBox}" fill="none">
<circle cx="{radius}" cy="{radius}" r="{outerRadius}" fill="#546D22"/>
<circle cx="{radius}" cy="{radius}" r="{innerRadius}" fill="#0C4130"/>
<text x="{radius}" y="{radius}" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="{fontSize}" fill="#FFE419">{shopcount}</text>
</svg>`

const svgCache = {}

export const formatGroupedPin = (weight) => {
  let radius, fontSize, viewBox, sizeKey

  if (weight < SMALL_THRESHOLD) {
    radius = SMALL_RADIUS
    fontSize = 12
    viewBox = 36
    sizeKey = 'small'
  } else if (weight >= LARGE_THRESHOLD) {
    radius = LARGE_RADIUS
    fontSize = 18
    viewBox = 60
    sizeKey = 'large'
  } else {
    radius = MEDIUM_RADIUS
    fontSize = 14
    viewBox = 48
    sizeKey = 'medium'
  }

  sizeKey = `${sizeKey}_${weight}`

  const outerRadius = radius
  const innerRadius = radius * 0.8
  const diameter = radius * 2

  if (!svgCache[sizeKey]) {
    svgCache[sizeKey] = groupedPinSvg
      .replace(/\{shopcount\}/g, weight.toString())
      .replace(/\{radius\}/g, radius.toString())
      .replace(/\{outerRadius\}/g, outerRadius.toString())
      .replace(/\{innerRadius\}/g, innerRadius.toString())
      .replace(/\{fontSize\}/g, fontSize.toString())
      .replace(/\{diameter\}/g, diameter.toString())
      .replace(/\{viewBox\}/g, viewBox.toString())
  }

  return { svgIcon: svgCache[sizeKey], diameter, innerRadius, outerRadius }
}