<template>
  <div>
    <div class="pl-2 pt-2 pb-2 pr-2">
      <v-btn class="pl-1 font-weight-medium text-body-1" color="primary" @click="goBack" text><v-icon class="mr-2"
          color="primary">mdi-arrow-left</v-icon>Back</v-btn>
    </div>
    <v-card class="mt-2 shop-details-here-map">
      <!-- <HereMapShop v-if="shop" :shops="shopPin" /> -->
      <MapSinglePin :key="`map-${shop.id}`" v-if="shop&&isShopLoaded" :shop="shop" :shops="additionalShops"/>
    </v-card>
    <div v-if="!shopLoading" class="pl-6 pt-6 pb-6">
      <v-row>
        <v-col class="col-8">
          <v-card-text class="pl-1 pt-5 font-weight-bold text-h6 dark-blue">SHOP</v-card-text>
          <v-row class="pt-4">
            <div class="pl-4 pt-0 pb-4 pr-4 font-weight-bold text-h4 shop-details-title" :class="{'shop-blacklisted-color': isBlacklisted}">{{ shop.name }}</div>
            <v-chip v-if="!isBlacklisted" class="mt-1 font-weight-bold text-body-1 pl-6 pr-6 pt-1 pb-1"
              :class="shopCategory(shop.label)">{{ shop.label === null || shop.label === '' ? 'BASIC' : shop.label }}</v-chip>
              <v-chip v-if="isBlacklisted" class="mt-1 font-weight-bold text-body-1 pl-6 pr-6 pt-3 pb-3 shop-blacklisted-chip">{{ 'BLACKLISTED' }}</v-chip>
            <v-spacer></v-spacer>
            <v-btn v-if="permissions.ADD_SHOP" text class="mt-1 mr-2 primary--text font-weight-bold text-h6" @click="editShopDialog">Edit
              Shop</v-btn>
          </v-row>
          <!-- SHOP EDIT FORM -->
          <v-dialog v-model="editShop" max-width="560px" @click:outside="editShop = false">
            <v-card>
              <AddShopForm :editShopData="shop" @shop-added-edited="reloadPage" @close-dialog="editShop = false"/>
            </v-card>
          </v-dialog>
          <v-card-text v-if="shop && shop.franchise" class="pl-1 pt-0 font-weight-bold text-h6 dark-blue">{{
            shop.franchise.name
          }}</v-card-text>
          <v-divider class="mb-8"></v-divider>

          <v-row class="pt-4 mb-1 pr-8">
            <v-card-title class="pt-0 pb-0 font-weight-medium text-h5 dark-blue">REVIEWS</v-card-title>
            <v-spacer></v-spacer>
            <v-switch
              v-model="onlyCompanyReviews"
              @change="getReviews"
              class="pl-4"
              color="primary"
              :value="false"
              :label="onlyCompanyReviews ? 'Private' : 'Public'"
              hide-details>
              ></v-switch>
            <v-icon class="ml-4 pt-5">{{onlyCompanyReviews ? 'mdi-eye-off' : 'mdi-earth'}} </v-icon>
          </v-row>
          <v-card-text class="pl-1 pt-0 pr-0 pb-8 font-weight-medium">{{ onlyCompanyReviews ? 'Visible reviews are private within your company, ensuring privacy and internal communication.' : 'Reviews are visible to all users, providing transparency across companies.' }}</v-card-text>

          <v-btn v-if="permissions.ADD_SHOP_REVIEW" color="mb-8 primary" @click="writeReviewDialog">Write Review</v-btn>
          <div v-if="!reviewsLoading">
          <ShopReview v-for="review in displayedReviews" :key="review.id" :review="review" @review-updated="getShop()" />
          <v-pagination v-if="showPagination" v-model="reviewsPagination.currentPage"
            :length="numberOfPages"></v-pagination>
        </div>
        <div v-if="reviewsLoading" class="pt-16">
          <v-col align="center" class="pt-10">
            <v-progress-circular indeterminate :size="128" :width="12" color="grey"></v-progress-circular>
          </v-col>
        </div>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="pl-6 pt-6 pr-6">
          <v-card-text class="pl-0 dark-blue font-weight-bold text-h5">Rating</v-card-text>
          <v-row class="pl-2 pt-4 pb-2">
            <v-rating background-color="grey" empty-icon="mdi-star-outline" full-icon="mdi-star"
              half-icon="mdi-star-half-full" color="#1B2C4B" length="5" size="34" :value=shop.averageRating
              half-increments dense readonly>
            </v-rating>
            <p v-if="shop && shop.averageRating" class="pl-4 pt-1 mb-0 font-weight-medium text-h6 dark-blue">{{
              shop.averageRating }}
            </p>
          </v-row>
          <v-divider class="mt-8 mb-8"></v-divider>
          <v-card-text class="pl-0 pt-0 font-weight-bold text-h6 dark-blue">Shop
            Address</v-card-text>
          <v-card-text v-if="shop.address" class="pl-2 pt-0 pb-0 font-weight-bold text-body-1">{{
            shop.address.text
          }}</v-card-text>
          <v-divider class="mt-8 mb-8"></v-divider>
          <div v-if="shop.phoneNumber">
            <v-card-text class="pl-0 pt-0 font-weight-bold text-h6 dark-blue">Shop
              Phone Number</v-card-text>
            <v-card-text class="pl-2 pt-0 pb-0 font-weight-bold text-body-1"><a :href="'tel:' + shop.phoneNumber"
                class="text-decoration-none">{{
                  shop.phoneNumber
                }}</a></v-card-text>
            <v-divider v-if="shop.workingHours" class="mt-8 mb-8"></v-divider>
          </div>
          <div v-if="shop.workingHours" class="mt-6">
            <p class="my-4 text-h6 font-weight-bold text-neutral_800 dark:text-neutral_200">
              Hours
            </p>
            <div
              class="pl-2 d-flex flex-column"
            >
              <div
                v-for="(day, index) in formatedHours"
                :key="index"
                class="d-flex align-center justify-space-between"
              >
                <p class="text-h6 font-weight-medium">
                  {{ day.day }}
                </p>
                <div v-if="!day.specialNote" class="d-flex align-center">
                  <p class="font-weight-bold text-h6">
                    {{ day.openTime }}
                  </p>
                  <p class="font-weight-bold mx-4">-</p>
                  <p class="font-weight-bold text-h6">
                    {{ day.closeTime }}
                  </p>
                </div>
                <div v-if="day.specialNote == 'CLOSED'">
                  <p class="font-weight-bold text-h6 red--text">Closed</p>
                </div>
                <div v-if="day.specialNote == 'OPEN_24_HOURS'">
                  <p class="font-weight-bold text-h6 green--text">24/7</p>
                </div>
              </div>
            </div>
          </div>
          <v-divider class="mt-8 mb-8"></v-divider>
          <v-card-text class="pl-0 pt-0 pb-4 font-weight-bold text-h6 dark-blue">Shop
            Type</v-card-text>
          <v-row v-for="(type, index) in shop.type" :key="index" class="pl-5 pt-0">
            <div class="pl-0 pt-4 pb-0 mr-3 font-weight-bold text-body-1">
              <v-icon v-if="type === 'TRUCK'">mdi-truck</v-icon>
              <v-icon v-if="type === 'TRAILER'">mdi-truck-trailer</v-icon>
              <v-icon v-if="type === 'TOWING'">mdi-tow-truck</v-icon>
              <v-icon v-if="type === 'MOBILE_SHOP'">mdi-account-wrench</v-icon>
              <v-icon v-if="type === 'SECURED_PARKING'">mdi-parking</v-icon>
              <v-icon v-if="type === 'DETAILING'">mdi-car-wash</v-icon>
              <!-- New icons -->
              <v-icon v-if="type === 'FRAME_ALIGNMENT_SUSPENSION'">mdi-car-cog</v-icon>
              <v-icon v-if="type === 'GLASS_REPAIR'">mdi-car-door</v-icon>
              <v-icon v-if="type === 'TRAILER_REFRIGERATION_REPAIR'">mdi-fridge-industrial</v-icon>
              <v-icon v-if="type === 'WELDING'">mdi-flash</v-icon>
              <v-icon v-if="type === 'LOCK_OUT_SERVICE'">mdi-lock-smart</v-icon>
              <v-icon v-if="type === 'MOBILE_FUELING'">mdi-fuel</v-icon>
              <!-- Display the type -->
              {{ type }}
            </div>
          </v-row>
          <v-divider class="mt-8 mb-8"></v-divider>
          <div v-if="shop && shop.contacts && shop.contacts.length > 0">
            <v-card-text class="pl-0 pt-0 pb-4 font-weight-bold text-h6 dark-blue">Contact</v-card-text>
            <v-row v-for="(contact, index) in shop.contacts" :key="index" class="pl-2 pb-4 mb-0 mt-0">
              <v-card-text class="pt-0 pb-0 font-weight-bold text-body-1 dark-blue">{{ contact.name
              }}</v-card-text>
              <v-card-text class="pt-0 pb-0 font-weight-medium text-body-1"><a class="mb-0 font-weight-bold
  text-decoration-none" :href="'tel:' + contact.phoneNumber">{{ contact.phoneNumber }}
                </a> <a :href="'mailto:' + contact.email" class="ml-4 text-decoration-none">{{ contact.email
                }}</a></v-card-text>
            </v-row>
            <v-divider class="mb-4 mt-4"></v-divider>
          </div>
          <div v-if="shop && (shop.website || shop.email)">
            <v-card-text class="pl-0 pt-2 pb-6 font-weight-bold text-h6 dark-blue">Web site &
              Email</v-card-text>
            <!-- ako nije https onda nece da redirektuje lepo  -->
            <!-- kada ima https skinuti ga da shop.webSite bude bez toga -->
            <v-card-text v-if="shop.website" class="pl-4 pt-0 pb-3 font-weight-medium text-body-1 primary--text"><a
                :href="shop.website" class="text-decoration-none" target="_blank">{{ shop.website
                }}</a></v-card-text>
            <v-card-text v-if="shop.email" class="pl-4 pt-0 pb-0 font-weight-medium text-body-1 primary--text"><a
                :href="'mailto:' + shop.email" class="text-decoration-none">{{ shop.email }}</a></v-card-text>
            <v-divider class="mt-8 mb-4"></v-divider>
          </div>
          <v-card-text class="pl-0 pt-2 pb-10 font-weight-bold text-h6 dark-blue">Status</v-card-text>
          <div align="center">
            <v-chip :color="isShopRegular ? '#6A892B' : '#8A1829'" x-large
              class="font-weight-medium text-h6 white--text pt-4 pl-10 pr-10 pb-4">{{ shopStatus }}</v-chip>
            <v-card-text class="pt-0" v-if="!shop.label">
              <v-btn class="mt-10 font-weight-bold primary--text text-h6" @click="shopStatusDialog" text>Change Shop Status</v-btn>
            </v-card-text>
          </div>
          <div v-if="isBlacklisted && shop.blacklistedBy" class="shop-details-note-about-status">
            <v-divider class="mt-8 mb-4"></v-divider>
            <v-card-text class="pl-0 pt-2 pb-7 font-weight-bold text-h6 dark-blue">Note About
              Status</v-card-text>
            <v-row class="pl-3 pt-4 pb-4 shop-details-note-about-status">
              <v-card-title class="pl-0 pt-0">{{ shop.blacklistedBy.name.toUpperCase() }}</v-card-title>
              <v-spacer></v-spacer>
              <v-btn v-if="shop.blacklistedBy.id === currentUser.id" icon
                @click="editNoteReviewOpen"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
            </v-row>
            <v-card-text class="pl-0 pt-0 text-body-1">{{ shop.statusText }}</v-card-text>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="shopLoading" class="d-flex" style="padding-top: 200px;">
      <v-col align="center">
        <v-progress-circular indeterminate :size="128" :width="12" color="grey"></v-progress-circular>
      </v-col>
    </div>
    <!-- Write Review Dialog -->
    <v-dialog max-width="480px" v-model="addReviewDialog" @click:outside="writeReviewDialog">
      <v-card>
        <div class="pl-6 pt-7 pr-6 pb-7">
          <v-row>
            <v-card-title class="font-weight-bold text-h6">Write Review</v-card-title>
            <v-spacer></v-spacer>
            <v-btn class="mt-3 mr-3" @click="writeReviewDialog" icon><v-icon>mdi-close</v-icon></v-btn>
          </v-row>
          <form @submit.prevent="addReview" data-vv-scope="addReviewShopDetails">
            <v-card-text class="pl-2 pb-0 font-weight-bold text-body-1 dark-blue">Rate Shop
              *</v-card-text>
            <v-rating v-model="reviewForm.rating" v-validate="{ required: true }" data-vv-name="rating"
              :error="errors.has('addReviewShopDetails.rating')" empty-icon="mdi-star-outline" full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              :background-color="errors.has('addReviewShopDetails.rating') ? 'error' : 'grey'" color="#1B2C4B"
              class="mt-4 mb-4 ml-1" length="5" size="28" :value=null half-increments dense></v-rating>
            <div v-if="reviewFormSubmited" class="pl-2 red--text">{{
              errors.first('addReviewShopDetails.rating') }}
            </div>
            <v-divider class="mt-4"></v-divider>
            <v-card-text class="pl-2 pb-0 font-weight-bold text-body-1 dark-blue">Review Visibility</v-card-text>
            <v-switch
              v-model="reviewForm.isPublic"
              class="pl-4"
              color="primary"
              :label="reviewForm.isPublic ? 'Public' : 'Private'"
              hide-details>
            </v-switch>
            <v-card-text class="pb-0 font-weight-medium dark-blue"><v-icon>{{reviewForm.isPublic ? 'mdi-earth' : 'mdi-eye-off'}} </v-icon> {{  reviewForm.isPublic ? 'If you choose to post this review, it will be publicly visible to all users' : 'Your review is private to your company, ensuring privacy and internal communication' }}</v-card-text>
            <v-divider class="mt-4"></v-divider>
            <v-card-text class="pl-2 font-weight-bold text-body-1 dark-blue">Write Review *</v-card-text>
            <v-textarea v-model="reviewForm.description" v-validate="{ required: true }" data-vv-name="description"
              :error="errors.has('addReviewShopDetails.description')"
              :error-messages="errors.first('addReviewShopDetails.description')" rows="6" row-height="15"
              label="Your Review" outlined hide-details></v-textarea>
            <v-btn class="mt-4" color="primary" type="submit" block>Post Review</v-btn>
          </form>
        </div>
      </v-card>
    </v-dialog>
    <!-- Review successfully posted dialog -->
    <v-dialog max-width="420px" v-model="reviewPosted" @click:outside="handleCloseReviewPostedDialog">
      <v-card>
        <div align="center" class="pl-8 pt-8 pr-8 pb-8">
          <v-card-text class="pt-0 font-weight-bold text-h6">Your Review</v-card-text>
          <v-divider></v-divider>
          <v-card-text class="pt-7 pb-7 font-weight-medium text-body-2">Successfully posted</v-card-text>
          <v-btn color="primary" block @click="handleCloseReviewPostedDialog">Ok</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- Change shop status dialog -->
    <v-dialog v-if="changeShopStatusDialog" max-width="460px" v-model="changeShopStatusDialog"
      @click:outiside="shopStatusDialog">
      <v-card>
        <div align="center" class="pl-8 pt-8 pr-8 pb-8">
          <v-card-text class="pt-0 font-weight-bold text-h6">Confirmation</v-card-text>
          <v-divider class="mb-6"></v-divider>
          <v-chip :color="isShopRegular ? '#8A1829' : '#6A892B'" large
            class="pl-10 pr-10 font-weight-medium text-h6 white--text">{{
              isShopRegular ? 'BLACKLISTED' : 'REGULAR'
            }}</v-chip>
          <v-card-text class="pt-6 pb-4 font-weight-medium text-body-2">This Shop will be {{ isShopRegular ? 'BLACKLISTED'
            :
            'REGULAR' }} from now on.</v-card-text>
            <div v-if="!isShopRegular">
                <v-card-text class="pt-0 pb-4 font-weight-medium text-body-2">Choose category:</v-card-text>
                <v-radio-group v-model="shopCategoryStatus" class="pl-4 pt-0 pb-4 mt-0 justify-center" row hide-details>
                  <v-radio label="None" value=""></v-radio>
                  <v-radio label="Platinum" value="PLATINUM"></v-radio>
                  <v-radio label="Gold" value="GOLD"></v-radio>
                  <v-radio label="Silver" value="SILVER"></v-radio>
                </v-radio-group>
            </div>
          <form @submit.prevent="updateShopStatus" data-vv-scope="newNoteDescriptionDialog">
            <div v-if="isShopRegular">
              <v-card-text class="pt-0 pb-6 font-weight-medium text-body-2">What experience have you had considering that
                the shop is becoming blacklisted?</v-card-text>
              <v-textarea v-model="noteDescriptionDialog" v-validate="{ required: true }" data-vv-name="description"
                :error="errors.has('newNoteDescriptionDialog.description')"
                :error-messages="errors.first('newNoteDescriptionDialog.description')" rows="6" row-height="10"
                :label="`Why you blacklisted ${shop.name}?`" outlined hide-details></v-textarea>
            </div>
            <div class="pt-4">
              <v-btn class="mr-4 primary--text white--text" outlined @click="shopStatusDialog">Don't change</v-btn>
              <v-btn color="primary" type="submit">Confirm</v-btn>
            </div>
          </form>
        </div>
      </v-card>
    </v-dialog>
    <!-- Edit review dialog -->
    <v-dialog max-width="480px" v-model="editNoteReviewActive" @click:outside="handleCloseEditReview">
      <v-card>
        <div class="pl-6 pt-7 pr-6 pb-7">
          <v-row>
            <v-card-title class="font-weight-bold text-h6">Edit Note *</v-card-title>
            <v-spacer></v-spacer>
            <v-btn class="mt-3 mr-3" @click="handleCloseEditReview" icon><v-icon>mdi-close</v-icon></v-btn>
          </v-row>
          <form @submit.prevent="editNoteReview()" data-vv-scope="editNote">
            <v-textarea v-model="noteDescription" v-validate="{ required: true }" data-vv-name="description"
              :error="errors.has('editNote.description')" :error-messages="errors.first('editNote.description')"
              class="mt-4" label="Your Review" rows="6" row-height="15" outlined hide-details></v-textarea>
            <v-btn class="mt-4" color="primary" type="submit" block>Edit Review</v-btn>
          </form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

// import HereMapShop from '@/components/Shop/HereMapShop.vue'

import AddShopForm from '@/components/Shop/AddShopForm.vue'
import ShopReview from '@/components/Shop/ShopReview.vue'
import MapSinglePin from '@/components/Shop/MapSinglePin.vue'

import MsShopService from '../../services/MsShopService'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  name: 'ShopDetails',
  components: {
    // HereMapShop,
    ShopReview,
    AddShopForm,
    MapSinglePin
  },
  data: () => ({
    isShopLoaded:false,
    shop: {},
    additionalShops:[],
    shopObject: {},
    reviewForm: {
      description: '',
      rating: null,
      isPublic: true
    },
    onlyCompanyReviews: false,
    addReviewDialog: false,
    reviewPosted: false,
    reviewFormSubmited: false,
    changeShopStatusDialog: false,
    editNoteReviewActive: false,
    noteDescription: '',
    noteDescriptionDialog: '',
    editShop: false,
    reviews: [
    ],
    reviewsPagination: {
      itemsPerPage: 7,
      currentPage: 1
    },
    currentUser: {},
    shopLoading: false,
    reviewsLoading: false,
    shopCategoryStatus: ''
  }),
  watch: {
    addReviewDialog(addReviewDialog) {
      if (addReviewDialog) this.errors.clear()
    },
    '$route.params': {
      handler: async function(newParams, oldParams) {
        this.isShopLoaded = false
        if (this.$route.name === 'ShopDetails' && newParams.shopId !== oldParams.shopId) {
          await this.getShop()
          if (this.shop) {
            this.isShopLoaded = true
            const additionalShopsResponse = await MsShopService.getShops(
              { locationLat: this.shop.latitude, locationLng: this.shop.longitude, radius: 2 }
            )
            this.additionalShops = additionalShopsResponse.data
          }
          await this.getReviews()
        }
      },
      deep: true
    }
  },
  async mounted () {
    this.currentUser = { ...this.msUser }
    this.shopLoading = true
    await this.getShop()
    if(this.shop){
      this.isShopLoaded = true
      const additionalShopsResponse = await MsShopService.getShops({locationLat:this.shop.latitude, locationLng:this.shop.longitude, radius:2})
      this.additionalShops = additionalShopsResponse.data
    }
    await this.getReviews()
  },
  computed: {
    ...mapGetters('auth', ['msUser', 'permissions']),
    shopPin() {
      return {
        id: this.shop.id,
        averageRating: this.shop.averageRating,
        label: this.shop.label,
        name: this.shop.name,
        franchise: this.shop.franchise,
        address: this.shop.address,
        latitude: Number(this.shop.latitude),
        longitude: Number(this.shop.longitude),
        status: this.shopStatus
      }
    },
    shopCategory() {
      return (category) => {
        switch (category) {
        case 'GOLD':
          return 'shop-gold-chip'
        case 'PLATINUM':
          return 'shop-platinum-chip'
        case 'SILVER':
          return 'shop-silver-chip'
        case null:
        case '':
          return 'shop-basic-chip'
        default:
          return ''
        }
      }
    },
    shopStatus() {
      return (this.shop.details && this.shop.details[0] && this.shop.details[0].status) || 'REGULAR'
    },
    isShopRegular() {
      return this.shopStatus === 'REGULAR'
    },
    isBlacklisted() {
      return this.shopStatus === 'BLACKLISTED'
    },
    checkRatingError() {
      return this.reviewForm.rating == null && this.reviewFormSubmited ? true : false
    },
    numberOfPages() {
      return Math.ceil(this.reviews.length / this.reviewsPagination.itemsPerPage)
    },
    displayedReviews() {
      const startIndex = (this.reviewsPagination.currentPage - 1) * this.reviewsPagination.itemsPerPage
      const endIndex = startIndex + this.reviewsPagination.itemsPerPage
      return this.reviews.slice(startIndex, endIndex)
    },
    showPagination() {
      return this.reviews.length > this.reviewsPagination.itemsPerPage
    },
    formatedHours () {
      if (this.shop && this.shop.workingHours) {
        return this.shop.workingHours.map((day) => {
          return {
            ...day,
            day: this.formatWorkDay(day.day),
            openTime: this.convertTo12HourFormat(day.openTime),
            closeTime: this.convertTo12HourFormat(day.closeTime)
          }
        })
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    async getShop() {
      try {
        this.shopObject = await MsShopService.getShop(this.$route.params.shopId)
        this.shop = this.shopObject.data
        this.shopLoading = false
        // console.log('shop na shopdetails', this.shop)
      } catch (error) {
        this.setMessage('Cannot get shop, contact support')
      }
    },
    async getReviews() {
      this.reviewsLoading = true
      try {
        this.reviewsObject = await MsShopService.getReview(this.$route.params.shopId, this.onlyCompanyReviews)
        this.reviews = this.reviewsObject.data.reviews
        this.reviews.sort((a, b) => {
          const dateA = new Date(a.createdDate)
          const dateB = new Date(b.createdDate)
          return dateB - dateA
        })
        this.reviewsLoading = false
      } catch (error) {
        console.error('Error getting reviews:', error)
      }
    },
    async addReview() {
      this.reviewFormSubmited = true
      try {
        const isFormValid = await this.$validator.validateAll('addReviewShopDetails')
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('submit_form')
        if (isFormValid && token) {
          await MsShopService.writeReview({
            text: this.reviewForm.description,
            rating: this.reviewForm.rating,
            isPublic: this.reviewForm.isPublic,
            shopId: this.shop.id,
            recaptchaToken: token,
            recaptchaV2: false
          })

          this.reviewForm = {
            description: '',
            rating: null,
            dateCreated: ''
          }
          this.errors.clear()
          this.addReviewDialog = false
          this.reviewPosted = true
          this.reviewFormSubmited = false
          this.getReviews()
          this.getShop()
        } else {
          console.warn('Form validation failed. Review not submitted.')
          this.setMessage('Form validation failed. Review not submitted.')
        }
      } catch (error) {
        if (error.response && error.response.data.message === 'LOW_RECAPTCHA_SCORE') {
          console.log('LOW_RECAPTCHA_SCORE', error)
          this.setMessage('Form validation failed, low recaptcha score.')
        }
        console.error('Error submitting review:', error)
      }
    },
    async updateShopStatus() {
      if (this.isShopRegular) {
        const formValid = await this.$validator.validateAll('newNoteDescriptionDialog')
        if (formValid) {
          try {
            await MsShopService.updateShopStatus({
              status: 'BLACKLISTED',
              label: null,
              statusText: this.noteDescriptionDialog,
            }, this.shop.id)
            this.noteDescriptionDialog = ''
            this.changeShopStatusDialog = false
            this.getShop()
          } catch {
            this.setMessage('Cannot change the status of shop.')
          }
        } else {
          this.setMessage('Form validation failed. Please, try again. ')
        }
      } else {
        try {
          await MsShopService.updateShopStatus({
            status: 'REGULAR',
            label: this.shopCategoryStatus,
            statusText: ''
          }, this.shop.id)
          this.shopCategoryStatus = ''
          this.changeShopStatusDialog = false
          this.getShop()
        } catch {
          this.setMessage('Cannot change status of shop.')
        }

      }
    },
    async editNoteReview() {
      const formValidation = this.$validator.validateAll('editNote')
      if (formValidation) {
        try {
          await MsShopService.updateShopStatus({
            status: 'BLACKLISTED',
            statusText: this.noteDescription,
          }, this.shop.id)
          this.noteDescription = ''
          this.editNoteReviewActive = false
          this.getShop()
        } catch {
          this.setMessage('Cannot update shop status text, contact support. ')
        }
      } else {
        this.setMessage('Form is not valid, try again. ')
      }
    },
    editNoteReviewOpen() {
      this.editNoteReviewActive = true
      this.noteDescription = this.shop.statusText
    },
    handleCloseEditReview() {
      this.editNoteReviewActive = false
    },
    writeReviewDialog() {
      this.addReviewDialog = !this.addReviewDialog
      if (this.addReviewDialog) {
        this.reviewForm = {
          description: '',
          isPublic: true,
          rating: null
        }
      }
    },
    convertTo12HourFormat(timeString) {
      if (timeString === null) {
        return null
      }
  
      const [hourStr, minuteStr] = timeString.split(':')
      const hour = parseInt(hourStr, 10)
      const minute = parseInt(minuteStr, 10)
      let hour12 = hour % 12 || 12
      const period = hour < 12 ? 'AM' : 'PM'
      const formattedHour = hour12.toString().padStart(2, '0')
      const formattedMinute = minute.toString().padStart(2, '0')

      return formattedHour + ':' + formattedMinute + ' ' + period
    },
    formatWorkDay(day) {
      const capitalizedWord = day.toLowerCase().charAt(0).toUpperCase() + day.slice(1)
      const firstThreeLetters = capitalizedWord.slice(0, 3)
      return firstThreeLetters
    },
    handleCloseReviewPostedDialog() {
      this.reviewPosted = false
    },
    shopStatusDialog() {
      this.changeShopStatusDialog = !this.changeShopStatusDialog
    },
    editShopDialog() {
      this.editShop = true
    },
    async handleCloseAddShopDialog() {
      this.editShop = false
    },
    goBack() {
      this.$router.go(-1)
    },
    reloadPage() {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/variables/variables.scss';


.shop-gold-chip {
  background-color: $gold !important;
  color: white !important;
}

.shop-platinum-chip {
  background-color: $platinum !important;
  color: white !important;
}

.shop-silver-chip {
  background-color: $silver !important;
  color: white !important;
}
.shop-blacklisted-chip{
  background-color: $blacklisted-color !important;
  color: white !important;
}

.shop-blacklisted-color{
  color: $blacklisted-color !important;
}

.shop-basic-chip{
  background-color: $basic !important;
  color: white !important;
}

.dark-blue {
  color: $dark-blue !important;
}

.shop-details-here-map {
  width: 100%;
  height: 360px;
  display: flex;
}

.shop-details-title{
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shop-details-note-about-status {
  position: relative;

  .shop-details-actions {
    position: absolute;
    top: 0;
    right: 0;
  }
}

// .shop-details-fixed-bar { ???
//   position: fixed;
//   top: 48px;
//   left: 0;
//   width: 100%;
//   z-index: 3;
//   background-color: white;
// }

.custom-switch {
  font-size: 6px;
  /* Adjust the font size as needed */
  font-weight: bold;
}
</style>
