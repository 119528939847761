<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div @click="on.click" style="cursor: pointer;">
        <v-icon title="Edit location" color="indigo" v-if="truck.on_the_road">mdi-map-marker</v-icon>
        <v-icon color="red lighten-2" v-else>mdi-close-octagon-outline</v-icon>
      </div>
    </template>
    <v-card style="padding: 8px">
      <v-container>
        <location-form :truck="truck" @location-changed="handleLocationChange" />
      </v-container> 
    </v-card>
  </v-menu>
</template>

<script>
import LocationForm from './LocationForm.vue'

export default {
  name: 'TruckLocation',

  
  components: { LocationForm },

  props: ['truck'],

  emits: ['location-changed'],

  data() {
    return {
      menu: false,
    }
  },

  methods: {
    handleLocationChange(data) {
      this.$emit('location-changed', data)
      this.menu = false
    }
  }
}
</script>
