<template>
  <div class="companies-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.companies') }}
      </v-card-title>
      <v-data-table
        height="550"
        :disable-sort="true"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalItems"
        :items="companies"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.is_active="{item}">
          {{item.is_active ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              @click="editCompany(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="deleteCompany(item)"
            >
              mdi-delete
            </v-icon>
            <v-icon v-if="permissions.PERMITBOOK_ADD" small class="mr-2" @click="addDocumentForThisCompany(item)">
              mdi-upload
            </v-icon>
            <v-icon v-if="permissions.PERMITBOOK_VIEW" small @click="showDocumentsForThisCompany(item)">
              mdi-file-document-multiple
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
          color="pink"
          dark
          absolute
          top
          right
          fab
          @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
        <v-card>
          <form @submit.prevent="onAddCompany" data-vv-scope="company">
            <v-card-title
              class="lighten-4 py-4 title"
            >
              {{form._id ? 'Edit' : 'Add'}} company
            </v-card-title>
            <v-container grid-list-sm class="pa-4">
              <v-layout row wrap>
                <v-flex xs12 align-center justify-space-between>
                    <v-text-field
                      ref="name"
                      v-model="form.name"
                      v-validate="'required'"
                      :error="errors.has('company.name')"
                      :error-messages="errors.first('company.name')"
                      name="name"
                      id="name"
                      :autofocus="true"
                      placeholder="Name"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 align-center justify-space-between>
                    <v-text-field
                      ref="name"
                      v-model="form.short_name"
                      v-validate="'required'"
                      :error="errors.has('company.short_name')"
                      :error-messages="errors.first('company.short_name')"
                      name="short_name"
                      id="short_name"
                      placeholder="Short name"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    v-model="form.is_active"
                    label="Is active"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
              <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
            </v-card-actions>
          </form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="showForm" max-width="500">
      <div>
        <PermitBookForm
          v-if="showForm"
          @permitBookAdded="showForm = false"
          :referrerForDocument="companyForDocument"
        />
      </div>
    </v-dialog>
    <v-dialog v-model="showDocumentsModal" max-width="90vw" max-height="90vh">
      <PermitBookTable
        :referrer-type="'company'"
        :referrer-id="referrerId"
        v-if="showDocumentsModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import PermitBookForm from '../PermitBooks/PermitBookForm.vue'
import PermitBookTable from '../PermitBooks/PermitBookTable.vue'

export default {
  name: 'Companies',
  components: {
    PermitBookForm,
    PermitBookTable
  },
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 10
      },
      totalItems: 0,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Short name', value: 'short_name'},
        {text: 'Is active', value: 'is_active'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        name: '',
        short_name: '',
        is_active: true
      },
      formDialog: false,
      companyForDocument: null,
      showForm: false,
      showDocumentsModal: false,
      referrerId: null
    }
  },
  computed: {
    ...mapGetters('common', ['loading', 'companies']),
    ...mapGetters('auth', ['permissions']),
  },
  watch: {
    options: {
      handler () {
        this.$store.dispatch('common/getCompanies', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    onAddCompany() {
      this.$validator.validateAll('company').then((result) => {
        if (result) {
          if (this.form._id) {
            this.$store.dispatch('common/editCompany', this.form).then(res => {
              if (res) {
                this.closeForm()
              }
            })
          } else {
            this.$store.dispatch('common/addCompany', this.form).then(res => {
              if (res) {
                this.closeForm()
              }
            })
          }
        }
      })
    },
    editCompany(company) {
      this.form = {...company}
      this.formDialog = true
    },
    deleteCompany(company) {
      let confVal = confirm(`Are you sure you want to delete company: ${company.name}?`)
      if (confVal) {
        this.$store.dispatch('common/deleteCompany', company._id).then(() => {
          this.setMessage(`${company.name} successfully deleted`)
        }).catch((err) => {
          console.log('err on delete', err)
        })
      }
    },
    closeForm () {
      this.formDialog = false
      this.form = {
        _id: null,
        name: '',
        short_name: '',
        is_active: true
      }
    },
    addDocumentForThisCompany(company) {
      this.showForm = true
      const companyObj = {
        type: 'company',
        text: company.name,
        value: {
          name: company.name,
          id: company._id
        },
      }
      
      this.companyForDocument = companyObj
    },
    showDocumentsForThisCompany(company) {
      this.referrerId = company._id
      this.showDocumentsModal = true
    }
  }
}
</script>

<style lang="scss">
</style>