<template>
  <div class="postloads-board">
    <v-row>
      <v-progress-circular
        v-if="loading"
        indeterminate
        class="prebookboard-loader"
        color="primary"
      ></v-progress-circular>
      <template v-else>
        <v-col cols="3" v-for="(load) of loads" :key="load._id">
          <v-card style="border: 1px solid #191970;">
            <v-card-text>
              <PrebookLoadCard :load="load"></PrebookLoadCard>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import PrebookService from '../../services/PrebookService'
import loadsService from '../../services/LoadsService'
import PrebookLoadCard from './PrebookLoadCard.vue'

export default {
  components: {
    PrebookLoadCard
  },
  data() {
    return {
      loads: [],
      loading: false,
      refreshTime: 3, // min
      options: {
        origin: null,
        destination: null,
        pickup: new Date,
      }
    }
  },
  methods: {
    async getLoads() {
      this.loading = true
      const params = {...this.options}
      params.pickup = [moment(params.pickup).format('YYYY-MM-DD')]
      params.dho = 700
      try {
        const response = await PrebookService.getPrebookLoads(params)
        this.loading = false
        if (response.status === 200 && response.data.length) {
          this.loads = response.data.sort((a, b) => b.suggested_rate - a.suggested_rate)
        }
      } catch (err) {
        this.loading = false
        console.log('err on get prebook loads', err)
      }
    },
    moveDays() {
      this.options.pickup = moment(this.options.pickup).add(1, 'days')
      if (this.options.pickup.diff(moment(), 'days') >= 5) {
        this.options.pickup = this.$route.query.avail ? moment(new Date(this.$route.query.avail)) : moment() // reset date
      }
    },
    async moveDaysInterval() {
      setInterval(async () => {
        this.moveDays()
        await this.getLoads()
      }, this.refreshTime * 60 * 1000)
    }
  },
  async mounted() {
    if (this.$route.query.origin) {
      const response = await loadsService.getCity(this.$route.query.origin)
      if (response.data.status === 'OK') {
        this.options.origin = response.data.cities[0]
      }
    }

    if (this.$route.query.destination) {
      const response = await loadsService.getCity(this.$route.query.destination)
      if (response.data.status === 'OK') {
        this.options.destination = response.data.cities[0]
      }
    }

    if (this.$route.query.avail) {
      this.options.pickup = new Date(this.$route.query.avail)
    }
    await this.getLoads()
    await this.moveDaysInterval()
    if (!this.loads.length) {
      this.moveDays()
      this.getLoads()
    }
  }
}
</script>

<style lang="scss">
.postloads-board {
  .prebookboard-loader {
    position: fixed;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }
}
</style>