import driver from '../../services/DriverService'
const state = {
  drivers: [],
  formDialog: false,
  driversDashboard: []
}

const getters = {
  drivers (state) {
    return state.drivers
  },
  formDialog(state){
    return state.formDialog
  },
  form(state){
    return state.form
  }
}

const actions = {
  getDrivers ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return driver.getDrivers(payload).then(res => {
      commit('setDrivers', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllDrivers ({dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return driver.getAllDrivers().then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addDriver ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return driver.addDriver(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addDriver', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editDriver ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return driver.editDriver(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editDriver', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteDriver ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return driver.deleteDriver(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteDriver', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getDriver ({dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return driver.getDriver(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  setFormDialog({commit},payload){
    commit('setFormDialog',payload)
  },
  setDriversForm({commit},payload){
    commit('setDriversForm',payload)
  },
  filterDashboard ({dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    let loadingInterval = setInterval(() => {
      dispatch('common/setLoading', true, {root: true})
    }, 300)
    return driver.filterDashboard(payload).then(res => {
      clearInterval(loadingInterval)
      dispatch('common/setLoading', false, {root: true})
      // commit('setDashboardData', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      clearInterval(loadingInterval)
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
}

const mutations = {
  setDrivers (state, drivers) {
    state.drivers = drivers
  },
  addDriver (state, driver) {
    const tmpDrivers = state.drivers.slice()
    tmpDrivers.push(driver)
    state.drivers = tmpDrivers
  },
  editDriver (state, driver) {
    let tmpDrivers = state.drivers.slice()
    let index = tmpDrivers.findIndex(el => el._id === driver._id)
    tmpDrivers.splice(index, 1, driver)
    state.drivers = tmpDrivers
  },
  deleteDriver (state, id) {
    const tmpDrivers = state.drivers.slice()
    tmpDrivers.splice(tmpDrivers.findIndex(el => el._id === id), 1)
    state.drivers = tmpDrivers
  },
  setFormDialog(state, payload){
    state.formDialog = payload
  },
  setDriversForm(state,payload){
    state.form = payload
  },
  setDashboardData(state, payload){
    state.driversDashboard = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
