<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.eldTrucks') }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('other.search_eld_truck')"
          single-line
          hide-details
          height="30"
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
          height="750"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="eldTrucks"
          class="elevation-1"
          :loading="loading"
          :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.is_active="{item}">
          {{item.is_active ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
                small
                class="mr-2"
                @click="editEldTruck(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteEldTruck(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddEldTruck" data-vv-scope="eldTruck">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            {{form._id ? 'Edit' : 'Add'}} ELD Truck
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                    ref="truck_number"
                    v-model="form.truck_number"
                    v-validate="'required'"
                    :error="errors.has('eldTruck.truck_number')"
                    :error-messages="errors.first('eldTruck.truck_number')"
                    name="truck_number"
                    id="truck_number"
                    :autofocus="true"
                    placeholder="Truck Number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="form.truck_brand"
                  :items="brands"
                  label="Truck Brand"
                  v-validate="'required'"
                  :error="errors.has('eldTruck.truck_brand')"
                  :error-messages="errors.first('eldTruck.truck_brand')"
                  dense
                  name="truck_brand"
                  id="truck_brand"
                ></v-select>
              </v-flex>
              <v-flex
                cols="12"
                lg="6"
              >
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.payment_started"
                      label="Payment started"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  :items="eldCompanies"
                  v-model="selected.eldCompany"
                  label="Eld Company"
                  item-value="_id"
                  item-text="name"
                  hide-details="auto"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                    v-model="form.is_active"
                    label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'EldTrucks',
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 15,
        search: ''
      },
      eldCompanies:[],
      selected: {
        eldCompany: null
      },
      totalItems: 0,
      headers: [
        {text: 'Truck Number', value: 'truck_number'},
        {text: 'Truck Brand', value: 'truck_brand'},
        {text: 'Payment Started', value: 'payment_started'},
        {text: 'Company', value: 'eldCompany.name'},
        {text: 'Is active', value: 'is_active'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        truck_number: '',
        truck_brand: '',
        payment_started: this.formatDate(new Date().toISOString().substr(0, 10)),
        is_active: true,
        eldCompany: null
      },
      formDialog: false,
      datePicker: false,
      date: new Date().toISOString().substr(0, 10),
      search: '',
      brands: ['Freightliner', 'International', 'MAC', 'Kenworth', 'Volvo', 'Peterbilt']
    }
  },
  mounted() {
    this.getCompanies()
  },
  watch: {
    search: function (newSearch) {
      this.searchTrucks(newSearch)
    },
    options: {
      handler () {
        this.$store.dispatch('eldTrucks/getEldTrucks', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true,
    },
    date () {
      this.form.payment_started = this.formatDate(this.date)
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('eldTrucks', ['eldTrucks']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    searchTrucks: debounce( function(search) {
      this.options.search = search
      this.$store.dispatch('eldTrucks/getEldTrucks', this.options).then(res => {
        this.totalItems = res.total
      })
    }, 300),
    getCompanies(){
      this.$store.dispatch('eldCompanies/getAllEldCompanies').then(res => {
        this.eldCompanies = res
      })
    },
    onAddEldTruck () {
      this.$validator.validateAll('eldTruck').then((result) => {
        if (result) {
          if(this.selected.eldCompany){
            this.form.eldCompany = this.selected.eldCompany
          }
          if (this.form._id) {
            if(this.selected.eldCompany){

              this.form.eldCompany = this.selected.eldCompany
            }
            this.$store.dispatch('eldTrucks/editEldTruck', this.form).then(res => {
              if (res) {
                this.formDialog = false
                this.form = {
                  _id: null,
                  truck_number: '',
                  truck_brand: '',
                  payment_started: '',
                  is_active: true
                }
              }
            })
          } else {
            this.$store.dispatch('eldTrucks/addEldTruck', this.form).then(res => {
              if (res) {
                this.formDialog = false
                this.form = {
                  _id: null,
                  truck_number: '',
                  truck_brand: '',
                  payment_started: '',
                  is_active: true
                }
              }
            })
          }
        }
      })
    },
    editEldTruck (eldTruck) {
      this.form = {...eldTruck}
      this.formDialog = true
    },
    deleteEldTruck (eldTruck) {
      let confVal = confirm(`Are you sure you want to delete eldTruck: ${eldTruck.name}?`)
      if (confVal) {
        this.$store.dispatch('eldTrucks/deleteEldTruck', eldTruck._id).then(() => {
          this.setMessage(`${eldTruck.name} successfully deleted`)
        }).catch((err) => {
          console.log('err on delete', err)
        })
      }
    },
    closeForm () {
      this.formDialog = false
      this.form = {
        _id: null,
        truck_number: '',
        truck_brand: '',
        payment_started: this.formatDate(new Date().toISOString().substr(0, 10)),
        is_active: true,
        eldCompany: null
      }
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    }
  }
}
</script>

<style lang="scss">
.boards-wrapper {
  width: 100%;
  margin-top: 20px;
}

// @media only screen and (max-width: 599px) {
//   .boards-wrapper {
//     margin-top: 65px;
//   }
// }
</style>