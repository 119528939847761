<template>
  <div class="LeaseUnits">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :options="options"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      @update:options="handleOptionsChange"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lease Units</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <table-filters :filterable-fields="tableFilters" :init-filters="filters" table-key="lease-units" @change="handleFiltersChanged"></table-filters>
          <v-spacer></v-spacer>
          <v-btn small color="primary" dark @click="handleNewItem">
            New Item
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.unit.vin="{ item }">
        <td :style="{ backgroundColor: getTypeBackground(item.type) }">
          <strong>{{ item.unit.vin }}</strong>
        </td>
      </template>

      <template v-slot:item.type="{ item }">
        <td :style="{ backgroundColor: getTypeBackground(item.type) }">
          {{ getUnitType(item.type) }}
        </td>
      </template>

      <template v-slot:item.amount="{ item }">
        {{ getAmountValue(item) | money }}
      </template>

      <template v-slot:item.unit.make="{ item }">
        <td :style="{ backgroundColor: getTypeBackground(item.type) }">
          {{ item.unit.make }}
        </td>
      </template>

      <template v-slot:item.unit.model="{ item }">
        <td :style="{ backgroundColor: getTypeBackground(item.type) }">
          {{ item.unit.model }}
        </td>
      </template>

      <template v-slot:item.unit.year="{ item }">
        <td :style="{ backgroundColor: getTypeBackground(item.type) }">
          {{ item.unit.year }}
        </td>
      </template>

      <template v-slot:item.unit.number="{ item }">
        <td :style="{ backgroundColor: getTypeBackground(item.type) }">
          {{ item.unit.number }}
        </td>
      </template>

      <template v-slot:item.status_type="{ item }">
        <td
          :style="{
            backgroundColor: getStatusTypeBackground(item.status_type),
          }"
        >
          {{ getStatusTypeText(item.status_type, item.type) }}
        </td>
      </template>

      <template v-slot:item.contract_expired="{ item }">
        <span v-if="item.contract_expired">{{
          item.contract_expired | formatDate
        }}</span>
        <span v-else>/</span>
      </template>

      <template v-slot:item.billing_date="{ item }">
        <span v-if="item.billing_date">{{
          item.billing_date | formatDate
        }}</span>
        <span v-else>/</span>
      </template>

      <template v-slot:item.pickup_date="{ item }">
        <span v-if="item.pickup_date">{{ item.pickup_date | formatDate }}</span>
        <span v-else>/</span>
      </template>

      <template v-slot:item.billing_period="{ item }">
        {{ item.billing_period ? item.billing_period.type : '/' }}
      </template>

      <template v-slot:item.price="{ item }">
        <span v-if="item.total_amount">{{ item.total_amount | money }}</span>
        <span v-else>/</span>
      </template>

      <template v-slot:item.contract_term="{ item }">
        {{ item.contract_term || '/' }}
      </template>

      <template v-slot:item.insurance_value> TODO </template>

      <template v-slot:item.registration="{ item }">
        {{ item.registration ? item.registration.type : '/' }}
      </template>

      <template v-slot:item.bobtail_insurance="{ item }">
        {{ item.bobtail_insurance ? item.bobtail_insurance.type : '/' }}
      </template>

      <template v-slot:item.down_payment> TODO </template>

      <template v-slot:item.trailer_usage_fee="{ item }">
        {{ item.trailer_usage_fee ? item.trailer_usage_fee : 0 | money }}
      </template>

      <template v-slot:item.payment_per_mile="{ item }">
        {{ item.payment_per_mile ? item.payment_per_mile : 0 | money }}
      </template>

      <template v-slot:item.comments="{ item }">
        <lease-comments :comments="item.comments" :id="item._id" type="unit" />
      </template>

      <template v-slot:item.type="{ item }">
        {{ getType(item.type) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <!-- Delete item -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add/Edit item -->
    <v-dialog v-model="dialog" max-width="1080px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  label="Company"
                  v-model="form.company"
                  :items="companies"
                  item-text="name"
                  small
                  return-object
                  hide-details
                  clearable
                />
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="form.type"
                  :items="UNIT_OPTIONS"
                  @change="handleTypeChanged"
                  label="Type"
                  hide-details
                  small
                />
              </v-col>

              <v-col
                v-if="form.company"
                cols="4"
                style="border-bottom: 2px solid rgba(0, 0, 0, 0.1)"
              >
                <v-text-field
                  :value="form.company.name"
                  label="Name"
                  disabled
                  hide-details
                  small
                />
                <v-text-field
                  :value="formattedAddress"
                  label="Address"
                  disabled
                  hide-details
                  small
                />
                <v-text-field
                  :value="form.company.mc"
                  label="MC"
                  disabled
                  hide-details
                  small
                />
                <v-text-field
                  :value="form.company.dot"
                  label="DOT"
                  disabled
                  hide-details
                  small
                />
                <v-text-field
                  :value="form.company.fein"
                  label="FEIN"
                  disabled
                  hide-details
                  small
                />
                <v-text-field
                  :value="salesManager"
                  label="Sales Manger"
                  disabled
                  hide-details
                  small
                />
                <v-text-field
                  :value="accPerson"
                  label="Acc Person"
                  disabled
                  hide-details
                  small
                />
              </v-col>

              <v-col
                v-if="form.company"
                cols="8"
                style="border-bottom: 2px solid rgba(0, 0, 0, 0.1)"
              >
                <contacts-form
                  :initialContacts="form.company.contacts"
                  readonly
                />
              </v-col>

              <template v-if="form.type">
                <v-col
                  cols="4"
                  style="
                    border-right: 2px solid rgba(0, 0, 0, 0.1);
                    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                  "
                >
                  <v-autocomplete
                    v-if="!form.unit.vin"
                    :items="units"
                    :loading="loading"
                    :search-input.sync="search"
                    item-text="vin_number"
                    label="VIN#"
                    placeholder="VIN#"
                    item-disabled
                    clearable
                    hide-no-data
                    hide-selected
                    return-object
                    hide-details
                    small
                    @change="handleUnitPicked"
                  />
                  <v-text-field
                    v-else
                    :value="form.unit.vin"
                    label="VIN#"
                    @click:clear="handleUnitCleared"
                    clearable
                    readonly
                    hide-details
                    small
                  />

                  <v-text-field
                    v-model="form.unit.make"
                    label="Make"
                    disabled
                    hide-details
                    small
                  />

                  <v-text-field
                    v-model="form.unit.model"
                    label="Model"
                    disabled
                    hide-details
                    small
                  />

                  <v-text-field
                    v-model="form.unit.year"
                    label="Year"
                    disabled
                    hide-details
                    small
                  />

                  <v-text-field
                    v-model="form.unit.number"
                    label="Number"
                    disabled
                    hide-details
                    small
                  />

                  <v-checkbox
                    v-model="form.contract_signed"
                    label="Contract Signed"
                    hide-details
                    small
                  />

                  <template v-if="showDocUpload && form.contract_signed">
                    <v-text-field
                      v-if="typeof form.contract_path === 'string'"
                      v-model="form.contract_path"
                      @click:prepend="handleOpenFile(form.contract_path)"
                      clearable
                      readonly
                      label="Contract"
                      prepend-icon="mdi-file"
                      class="LeaseUnits__file-text"
                    />
                    <v-file-input
                      v-else
                      v-model="form.contract_path"
                      label="Contract"
                    />
                  </template>

                  <v-checkbox
                    v-model="form.insurance_recived"
                    label="Insurance Recived"
                    hide-details
                    small
                  />

                  <template v-if="showDocUpload && form.insurance_recived">
                    <v-text-field
                      v-if="typeof form.insurance_path === 'string'"
                      v-model="form.insurance_path"
                      @click:prepend="handleOpenFile(form.insurance_path)"
                      clearable
                      readonly
                      label="Insurance"
                      prepend-icon="mdi-file"
                      class="LeaseUnits__file-text"
                    />
                    <v-file-input
                      v-else
                      v-model="form.insurance_path"
                      label="Insurance"
                    />
                  </template>

                  <v-text-field
                    v-if="showTrailerUsageFee"
                    v-model="form.trailer_usage_fee"
                    label="Trailer Usage Fee"
                    type="number"
                    hide-details
                    small
                    prefix="$"
                  />
                </v-col>

                <v-col
                  cols="4"
                  style="
                    border-right: 2px solid rgba(0, 0, 0, 0.1);
                    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                  "
                >
                  <v-menu
                    ref="pickUpDateMenu"
                    v-model="pickUpDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.pickup_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.pickup_date"
                        label="Pickup Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        hide-details
                        small
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.pickup_date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="pickUpDateMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.pickUpDateMenu.save(form.pickup_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="billingDateMenu"
                    v-model="billingDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.billing_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.billing_date"
                        label="Billing Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        hide-details
                        small
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.billing_date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="billingDateMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.billingDateMenu.save(form.billing_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    v-if="showContractExpired"
                    ref="contractExpiredMenu"
                    v-model="contractExpiredMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.contract_expired"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.contract_expired"
                        label="Contract Expired"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        hide-details
                        small
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.contract_expired"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="contractExpiredMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.contractExpiredMenu.save(form.contract_expired)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="4"
                  style="border-bottom: 2px solid rgba(0, 0, 0, 0.1)"
                >
                  <v-select
                    v-model="form.status_type"
                    :items="statusOptions"
                    label="Unit status"
                    hide-details
                    small
                  />

                  <v-menu
                    v-if="showInactiveDate"
                    ref="inactiveDateMenu"
                    v-model="inactiveDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.inactive_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.inactive_date"
                        label="Inactive Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        hide-details
                        small
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.inactive_date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="inactiveDateMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.inactiveDateMenu.save(form.inactive_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="6"
                  style="
                    border-right: 2px solid rgba(0, 0, 0, 0.1);
                    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                  "
                >
                  <registration-input
                    v-if="form.type"
                    :unit-type="form.type"
                    :reg-data="this.form.registration"
                    @input="onRegInput"
                  />
                  <bobtail-insurance-input
                    v-if="form.type"
                    :unit-type="form.type"
                    :bobtail-data="this.form.bobtail_insurance"
                    @input="onBobtailInput"
                  />
                  <billing-period-input
                    v-if="form.type"
                    :unit-type="form.type"
                    :billing-data="this.form.billing_period"
                    @input="onBillingInput"
                  />
                </v-col>

                <v-col
                  cols="6"
                  style="border-bottom: 2px solid rgba(0, 0, 0, 0.1)"
                >
                  <v-select
                    v-if="form.type === 'TRUCK_LEASE_TO_LEASE'"
                    v-model="form.contract_term"
                    label="Contract on"
                    :items="contractTermOptions"
                  />
                  <per-mile
                    v-if="showPerMilePayments"
                    @change="handlePerMileChanged"
                    :initial-per-mile="Number(form.payment_per_mile)"
                    :initial-payments="form.per_mile_payments"
                  />
                  <lease-payments
                    v-if="showLeasePayments"
                    @change="handleLeaseChanged"
                    :initial-total-amount="Number(form.total_amount)"
                    :initial-payments="form.lease_payments"
                  />
                </v-col>

                <v-col cols="12">
                  <comments
                    :comments="form.comments"
                    :loading="loading"
                    cancel-text="Clear"
                    :autofocus="false"
                    :show-actions="true"
                    :with-form="true"
                    @delete="handleDeleteComment"
                    @save="saveComment"
                  />
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import service from '../../services/LeaseUnitsService'
import { commentsMixin } from './CommentsMixin'
import companyService from '../../services/LeaseCompaniesService'
import ContactsForm from './ContactsForm.vue'
import PerMile from './PerMile.vue'
import LeasePayments from './LeasePayments.vue'
import Comments from '../Common/Comments.vue'
import LeaseComments from './LeaseComments.vue'
import RegistrationInput from './RegistrationInput.vue'
import BobtailInsuranceInput from './BobtailInsuranceInput.vue'
import BillingPeriodInput from './BillingPeriodInput.vue'
import TableFilters from '../Common/TableFilters.vue'

import {
  FOOTER_PROPS,
  UNIT_STATUS_OPTIONS_LOAN,
  UNIT_STATUS_OPTIONS,
  TRUCK_UNITS,
  TRAILER_UNITS,
  UNIT_OPTIONS,
  ACTIVE_STATUS_TYPES,
  INACTIVE_STATUS_TYPES,
  UNIT_FORM_CONFIG_BY_TYPE,
  CONTRACT_TERM_OPTIONS,
} from '../../utils/constants.js'

const INITIAL_FORM = {
  company: null,
  type: null,
  unit: {
    vin: null,
    make: null,
    model: null,
    year: null,
    unit: null,
  },
  pickup_date: null,
  billing_date: null,
  status_type: null,
  inactive_date: null,
  contract_signed: null,
  insurance_recived: null,
  contract_expired: null,
  trailer_usage_fee: null,
  payment_per_mile: null,
  per_mile_payments: [],
  lease_payments: [],
  total_amount: null,
  comments: [],
  registration: null,
  bobtail_insurance: null,
  contract_term: '6 months',
}

const HEADERS = [
  { text: 'Status Unit', value: 'status_type', width: 100 },
  { text: 'Company', value: 'company.name', width: 200 },
  { text: 'Truck/Trailer', value: 'type', width: 150 },
  { text: 'Make', value: 'unit.make', width: 120 },
  { text: 'Model', value: 'unit.model', width: 100 },
  { text: 'Year', value: 'unit.year', width: 70 },
  { text: 'Vin Number', value: 'unit.vin' },
  { text: 'Unit', value: 'unit.number', width: 100 },
  { text: 'Pickup Date', value: 'pickup_date', width: 100 },
  { text: 'Billing Date', value: 'billing_date', width: 100 },
  { text: 'Amount', value: 'amount', width: 100 },
  {
    text: 'Billing Period / ACH',
    value: 'billing_period',
    width: 140,
  },
  { text: 'Price', value: 'price', width: 100 },
  { text: 'Type', value: 'type', width: 150 },
  { text: 'Contract Expiration Date', value: 'contract_expired', width: 180 },
  { text: 'Contract Term', value: 'contract_term', width: 120 },
  { text: 'Insurance Value', value: 'insurance_value', width: 120 },
  { text: 'Registration', value: 'registration', width: 120 },
  { text: 'Per Mile', value: 'payment_per_mile', width: 80 },
  { text: 'Down Payment', value: 'down_payment', width: 120 },
  { text: 'Bobtail Insurance', value: 'bobtail_insurance', width: 140 },
  {
    text: 'Trailer Usage Fee',
    value: 'trailer_usage_fee',
    width: 140,
  },
  { text: 'Comments', value: 'comments', width: 120 },
  { text: 'Actions', value: 'actions', sortable: false },
]

const TABLE_FILTERS = [
  {
    name: 'status_type',
    label: 'Unit status',
    type: 'select',
    options: [
      ...UNIT_STATUS_OPTIONS,
      { header: 'Loan' },
      { text: 'Active Loan ', value: 'ACTIVE_LOAN', }
    ]
  },
  {
    name: 'company.name',
    label: 'Company',
    type: 'text'
  },
  {
    name: 'type',
    label: 'Type',
    type: 'select',
    options: [
      ...UNIT_OPTIONS
    ]
  },
  {
    name: 'unit.vin',
    label: 'Unit vin',
    type: 'text'
  },
  {
    name: 'unit.number',
    label: 'Unit number',
    type: 'number'
  },
  {
    name: 'unit.make',
    label: 'Unit make',
    type: 'text'
  },
  {
    name: 'unit.model',
    label: 'Unit model',
    type: 'text'
  },
  {
    name: 'unit.year',
    label: 'Unit year',
    type: 'number'
  },
  {
    name: 'total_amount',
    label: 'Price',
    type: 'number'
  },
  {
    name: 'pickup_date',
    label: 'Pickup date',
    type: 'date'
  },
  {
    name: 'billing_date',
    label: 'Billing date',
    type: 'date'
  },
  {
    name: 'billing_period.type',
    label: 'Billing period',
    type: 'select',
    options: ['WEEKLY', 'MONTHLY']
  },
  {
    name: 'contract_expired',
    label: 'Contract expiration',
    type: 'date'
  },
  {
    name: 'contract_term',
    label: 'Contract term',
    type: 'select',
    options: [...CONTRACT_TERM_OPTIONS]
  },
  {
    name: 'registration.type',
    label: 'Registration',
    type: 'select',
    options: ['OURS_INCLUDED', 'OURS', 'THEIR']
  },
  {
    name: 'bobtail_insurance.type',
    label: 'Bobtail insurance',
    type: 'select',
    options: ['OURS_INCLUDED', 'OURS', 'THEIR', 'UNITED_EGO_POLICY']
  },
  {
    name: 'trailer_usage_fee',
    label: 'Trailer usage fee',
    type: 'number'
  }
]

export default {
  components: {
    ContactsForm,
    PerMile,
    LeasePayments,
    Comments,
    LeaseComments,
    RegistrationInput,
    BobtailInsuranceInput,
    BillingPeriodInput,
    TableFilters,
  },

  mixins: [commentsMixin],

  data() {
    return {
      commentsService: 'units',
      UNIT_OPTIONS,

      dialog: false,
      dialogDelete: false,

      footerProps: FOOTER_PROPS,
      headers: HEADERS,

      items: [],
      totalItems: 0,
      loading: false,

      editId: null,
      form: { ...INITIAL_FORM },
      options: { page: 1, itemsPerPage: 10, sortBy: [], sortDesc: [] },
      filters: [],

      companies: [],
      units: [],
      queryTerm: '',

      billingDateMenu: false,
      pickUpDateMenu: false,
      inactiveDateMenu: false,
      contractExpiredMenu: false,

      contractTermOptions: [...CONTRACT_TERM_OPTIONS],

      tableFilters: [...TABLE_FILTERS],
    }
  },

  computed: {
    formTitle() {
      return !this.editId ? 'New Unit' : 'Edit Unit'
    },

    showPerMilePayments() {
      if (!this.form.type) return false
      return this.form.type === 'TRUCK_RENT'
    },

    showLeasePayments() {
      if (!this.form.type) return false
      return ['TRUCK_LEASE_TO_BUY', 'TRAILER_LEASE_TO_BUY', 'LOAN'].includes(
        this.form.type
      )
    },

    search: {
      get() {
        return this.queryTerm
      },
      set(val) {
        if (val !== this.queryTerm) {
          this.queryTerm = val
          this.searchUnits(val)
        }
      },
    },

    showInactiveDate() {
      if (!this.form.status_type) return false
      return [
        'RETURNED',
        'PAID_IN_FULL',
        'TOTAL_DAMAGE',
        'NEVER_PICK_UP',
      ].includes(this.form.status_type)
    },

    formattedAddress() {
      if (!this.form.company) return null
      const { address, city, zip, state } = this.form.company
      return `${address}, ${city}, ${zip}, ${state}`
    },

    salesManager() {
      if (!this.form.company || !this.form.company.sales_manager) return null
      return this.form.company.sales_manager.name
    },

    accPerson() {
      if (!this.form.company || !this.form.company.acc_person) return null
      return this.form.company.acc_person.name
    },

    showContractExpired() {
      return (
        this.form.type &&
        UNIT_FORM_CONFIG_BY_TYPE[this.form.type].contract_expired
      )
    },

    showTrailerUsageFee() {
      return (
        this.form.type &&
        UNIT_FORM_CONFIG_BY_TYPE[this.form.type].trailer_usage_fee
      )
    },

    showDocUpload() {
      return (
        this.form.type && UNIT_FORM_CONFIG_BY_TYPE[this.form.type].docUpload
      )
    },

    statusOptions() {
      if (!this.form.type) return []
      return this.form.type === 'LOAN'
        ? UNIT_STATUS_OPTIONS_LOAN
        : UNIT_STATUS_OPTIONS
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  async created() {
    if (this.$route.query.company) {
      this.filters.push({
        field: {
          name: 'company.name',
          type: 'text',
          label: 'Company'
        },
        operator: 'is',
        value: this.$route.query.company
      })
    }
    await this.fetchCompanies()
  },

  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),

    searchUnits: debounce(async function (val) {
      const type = TRUCK_UNITS.includes(this.form.type) ? 'truck' : 'trailer'
      if (val)
        try {
          this.loading = true
          const { data } = await service.searchUnits(val, type)
          if (Array.isArray(data.docs)) this.units = data.docs
        } catch (error) {
          console.error('searchUnits()', error)
          this.units = []
        } finally {
          this.loading = false
        }
    }, 200),

    handleTypeChanged(v) {
      if (v !== 'TRUCK_RENT') this.form.per_mile_payments = []
    },

    checkUrl(company) {
      if (this.$route.query.company && company !== this.$route.query.company) {
        this.$router.replace({ query: {} })
      }
    },

    handleFiltersChanged: debounce(function (filters) {
      // this.checkUrl(filters.company)
      this.items = []
      this.filters = [ ...filters ]
      this.options.page = 1

      this.fetchItems()
    }, 200),

    getStatusTypeText(v, type) {
      const statusOptions =
        type === 'LOAN' ? UNIT_STATUS_OPTIONS_LOAN : UNIT_STATUS_OPTIONS
      return statusOptions.find((s) => s.value === v).text
    },

    getStatusTypeBackground(type) {
      let color = '#ffffff'
      if (ACTIVE_STATUS_TYPES.includes(type)) return '#A4C2F4'
      if (INACTIVE_STATUS_TYPES.includes(type)) return '#FF9902'
      return color
    },

    getTypeBackground(type) {
      let color = '#ffffff'
      if (this.isTruck(type)) return '#D8EAD3'
      if (this.isTrailer(type)) return '#FFF2CC'
      return color
    },

    getType(t) {
      return UNIT_OPTIONS.find((o) => o.value === t).text
    },

    getUnitType(t) {
      let type = ''
      if (this.isTruck(t)) return 'Truck'
      if (this.isTrailer(t)) return 'Trailer'
      return type
    },

    isTruck(t) {
      return TRUCK_UNITS.includes(t)
    },

    isTrailer(t) {
      return TRAILER_UNITS.includes(t)
    },

    handleUnitPicked(u) {
      if (!u) return
      this.search = ''
      this.queryTerm = ''
      this.form.unit = {
        vin: u.vin_number,
        id: u._id,
        make: u.make && u.make.name,
        model: u.model && u.model.name,
        year: u.year,
        number: u.number,
      }
    },

    handleUnitCleared() {
      this.form.unit = {
        vin: null,
        id: null,
        make: null,
        model: null,
        year: null,
        number: null,
      }
    },

    formatDate(date) {
      return this.$options.filters.formatDatePicker(date)
    },

    async fetchItems() {
      this.loading = true
      try {
        const { data } = await service.getItems(this.options, this.filters)
        this.totalItems = data.total
        this.items = data.docs
      } catch (error) {
        console.error('fetchItems()', error)
      } finally {
        this.loading = false
      }
    },

    handlePerMileChanged({ payments, perMile }) {
      this.form.per_mile_payments = [...payments]
      this.form.payment_per_mile = perMile
    },

    handleLeaseChanged({ payments, totalAmount }) {
      this.form.lease_payments = [...payments]
      this.form.total_amount = totalAmount
    },

    async fetchCompanies() {
      try {
        // TODO: Morace u nekom trenutku autocomplete, imaju dosta kompanija ovih
        const { data } = await companyService.getAll()
        if (Array.isArray(data.docs) && data.docs.length > 0)
          this.companies = data.docs
      } catch (error) {
        console.error('fetchCompanies()', error)
      }
    },

    handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      this.options.sortBy = o.sortBy
      this.options.sortDesc = o.sortDesc
      this.fetchItems()
    },

    handleNewItem() {
      this.dialog = true
    },

    onRegInput(registrationData) {
      this.form.registration = { ...registrationData }
    },
    onBobtailInput(bobtailData) {
      this.form.bobtail_insurance = { ...bobtailData }
    },
    onBillingInput(billingData) {
      this.form.billing_period = { ...billingData }
    },

    getAmountValue(item) {
      if (!item.lease_payments || item.lease_payments.length === 0) return 0
      return item.lease_payments[item.lease_payments.length - 1].amount
    },

    editItem(item) {
      const {
        _id,
        company,
        pickup_date,
        billing_date,
        contract_expired,
        inactive_date,
        ...rest
      } = item
      this.editId = _id
      this.form = Object.assign(
        {},
        {
          company: this.companies.find((c) => c._id === company.id),
          pickup_date: pickup_date ? this.formatDate(pickup_date) : null,
          contract_expired: contract_expired
            ? this.formatDate(contract_expired)
            : null,
          billing_date: billing_date ? this.formatDate(billing_date) : null,
          inactive_date: inactive_date ? this.formatDate(inactive_date) : null,
          ...rest,
        }
      )
      this.dialog = true
    },

    deleteItem(item) {
      const { _id, ...rest } = item
      this.editId = _id
      this.form = Object.assign({}, rest)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      try {
        await service.deleteItem(this.editId)
        await this.fetchItems()
        this.closeDelete()
      } catch (error) {
        console.error('deleteItemConfirm()', error)
      } finally {
        this.loading = false
      }
    },

    resetForm() {
      this.form = Object.assign({}, INITIAL_FORM)
      this.editId = null
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.form = Object.assign({}, INITIAL_FORM)
        this.editId = null
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.form = Object.assign({}, INITIAL_FORM)
        this.editId = null
      })
    },

    prepareForm() {
      const formData = new FormData()

      for (const key in this.form) {
        if (this.form[key]) {
          switch (key) {
          case 'insurance_path':
          case 'contract_path':
            if (typeof this.form[key] == 'string')
              formData.append(key, this.form[key])
            else formData.append(key, this.form[key], this.form[key].name)
            break

          case 'company':
          case 'unit':
          case 'registration':
          case 'bobtail_insurance':
          case 'billing_period':
          case 'comments':
          case 'per_mile_payments':
          case 'lease_payments':
            formData.append(key, JSON.stringify(this.form[key]))
            break

          default:
            formData.append(key, this.form[key])
            break
          }
        }
      }

      return formData
    },

    handleOpenFile(file) {
      if (file) window.open(file, '_newtab')
    },

    async save() {
      this.loading = true
      const form = this.prepareForm()
      try {
        if (this.editId) await service.editItem(this.editId, form)
        else await service.addItem(form)
        await this.fetchItems()
        this.close()
      } catch (error) {
        console.error('save()', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.LeaseUnits {
  tr:hover {
    cursor: pointer;
  }

  .v-data-table {
    overflow-x: auto;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      position: relative;
      padding: 0 6px !important;
    }
  }

  &__file-text {
    .v-input__icon--prepend {
      cursor: pointer;
    }
  }
}
</style>
