<template>
  <div class="ContactsForm">
    <v-row v-for="(contact, index) in contacts" :key="index">
      <v-col cols="2"
        ><v-text-field
          v-model="contact.name"
          @input="handleChange"
          :disabled="readonly"
          label="Name*"
          hide-details
          small
      /></v-col>
      <v-col cols="3"
        ><v-text-field
          v-model="contact.email"
          @input="handleChange"
          :disabled="readonly"
          label="Email*"
          hide-details
          small
      /></v-col>
      <v-col cols="2"
        ><v-text-field
          v-model="contact.phone"
          @input="handleChange"
          :disabled="readonly"
          label="Phone*"
          hide-details
          small
      /></v-col>
      <v-col cols="5" class="d-flex align-end">
        <v-checkbox
          v-model="contact.invoice"
          @change="handleChange"
          :disabled="readonly"
          class="mr-2"
          label="Invoices"
          hide-details
          small
        />

        <v-checkbox
          class="flex-grow-0 mr-4"
          v-model="contact.contract"
          :disabled="readonly"
          label="Contracts"
          hide-details
          small
        />

        <v-icon v-if="!readonly" color="error" @click="handleRemoveContract(index)">mdi-delete</v-icon>
      </v-col>
    </v-row>

    <div v-if="!readonly" class="d-flex justify-center mt-5">
      <v-btn text @click="handleAddNewContact" :disabled="addMoreDisabled" color="primary">
        Add new contact
      </v-btn>
    </div>
  </div>
</template>

<script>
const INITIAL_ITEM = {
  name: null,
  email: null,
  phone: null,
  invoice: false,
  contract: false,
}

export default {
  name: 'ContactsForm',

  emits: ['change'],

  props: {
    initialContacts: {
      type: Array,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      contacts: [],
    }
  },

  created() {
    const hasItems = Array.isArray(this.initialContacts) && this.initialContacts.length > 0
    this.contacts = hasItems ? this.initialContacts : [{ ...INITIAL_ITEM }]
  },

  computed: {
    addMoreDisabled() {
      const lastContact = this.contacts[0]
      if (!lastContact) return false
      const { name, email, phone } = lastContact
      return !name || !email || !phone
    },
  },

  methods: {
    handleAddNewContact() {
      this.contacts.push({ ...INITIAL_ITEM })
      this.handleChange()
    },

    handleRemoveContract(index) {
      let confVal = confirm('Are you sure you want to delete contact?')
      if (confVal) {
        const newContacts = [...this.contacts]
        newContacts.splice(index, 1)
        this.contacts = newContacts
        this.handleChange()
      }
    },

    handleChange() {
      this.$emit('change', this.contacts)
    }
  }
}
</script>