import eldCompany from '../../services/EldCompaniesService'

const state = {
  eldCompanies: [],
  driversByCompanyMap: {}
}

const getters = {
  eldCompanies (state) {
    return state.eldCompanies
  },
  driversByCompanyMap (state) {
    return state.driversByCompanyMap
  }
}

const actions = {
  getEldCompanies ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return eldCompany.getEldCompanies(payload).then(res => {
      commit('setEldCompanies', res.data.companies.docs)
      commit('setdriversByCompanyMap', res.data.driversNumbeMap)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data.companies)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllEldCompanies ({dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return eldCompany.getAllEldCompanies().then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addEldCompany ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return eldCompany.addEldCompany(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addEldCompany', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editEldCompany ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return eldCompany.editEldCompany(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editEldCompany', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteEldCompany ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return eldCompany.deleteEldCompany(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteEldCompany', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setEldCompanies (state, eldCompanies) {
    state.eldCompanies = eldCompanies
  },
  setdriversByCompanyMap (state, data) {
    state.driversByCompanyMap = data
  },
  addEldCompany (state, contact) {
    const tmpEldCompanies = state.eldCompanies.slice()
    tmpEldCompanies.push(contact)
    state.eldCompanies = tmpEldCompanies
  },
  editEldCompany (state, eldCompany) {
    let tmpEldCompanies = state.eldCompanies.slice()
    let index = tmpEldCompanies.findIndex(el => el._id === eldCompany._id)
    tmpEldCompanies.splice(index, 1, eldCompany)
    state.eldCompanies = tmpEldCompanies
  },
  deleteEldCompany (state, id) {
    const tmpEldCompanies = state.eldCompanies.slice()
    tmpEldCompanies.splice(tmpEldCompanies.findIndex(el => el._id === id), 1)
    state.eldCompanies = tmpEldCompanies
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
