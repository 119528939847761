<template>
  <v-dialog v-model="openDialog" max-width="500px">
    <v-card>
      <v-card-title>Add Truck Alert</v-card-title>
      <v-card-text>
        <v-form ref="alertForm">
          <v-autocomplete
              v-model="form.truck"
              :items="trucks"
              :loading="loading"
              :search-input.sync="search"
              item-text="number"
              item-disabled
              label="Select a truck"
              placeholder="Search by number"
              prepend-icon="mdi-database-search"
              clearable
              hide-no-data
              hide-selected
              return-object
              v-validate="'required'"
              @change="pickedTruck"
            >
              <template v-slot:item="{ item }">
                {{ item.number }}
              </template>
          </v-autocomplete>
          <v-textarea
            label="Note"
            v-model="form.note"
            v-validate="'required'"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="submitAlert">Submit</v-btn>
        <v-btn @click="openDialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import debounce from 'lodash/debounce'
import trucksService from '../../services/TruckService'


export default {
  data() {
    return {
      openDialog: false,
      form: {
        truckNumber: '',
        note: '',
        is_active: true,
        role: 'TRUCK_DEPARTMENT',
        isSent: false,
      },
      trucks: [],
      loading: false,
    }
  },
  computed: {
    search: {
      get() {
        return this.form.truckNumber
      },
      set(val) {
        this.searchTrucks(val)
      },
    },
  },
  methods: {
    searchTrucks: debounce(async function (val) {
      try {
        if (!val || !val.length) {
          this.trucks = []
          return
        }
        this.loading = true
        const { data } = await trucksService.searchTrucks(val)
        if (Array.isArray(data.docs)) this.trucks = data.docs
      } catch (error) {
        console.error('AvailableTable searchTrucks()', error)
        this.trucks = []
        this.error = true
      } finally {
        this.loading = false
      }
    }, 200),
    open() {
      this.openDialog = true
    },
    submitAlert() {
      this.loading = true
      this.$validator.validateAll('alert').then((result) => {
        if (result) {
          trucksService.addAlert(this.form).then((res) => {
            console.log('Alert added:', res.data)
            this.openDialog = false
            this.form = {
              truckNumber: '',
              note: '',
              is_active: true,
              role: 'TRUCK_DEPARTMENT',
              isSent: false,
            }
          }).catch((error) => {
            console.error('Error adding alert:', error)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    pickedTruck(truck) {
      this.form.truckNumber = truck ? truck.number : ''
    },
  }
}
</script>

<style scoped>
</style>
