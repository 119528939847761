<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div @click="on.click" style="cursor: pointer;">
        <span v-if="trailer.lastAnnualDot">
          {{ new Date(trailer.lastAnnualDot.date_inspected).toLocaleDateString() }}
        </span>
        <v-icon v-else class="mr-2" small color="black lighten-2">
          mdi-clock-outline
        </v-icon>
      </div>
    </template>
    <v-card>
      <trailer-dot-inspection-list v-if="menu" :trailer="trailer" />
    </v-card>
  </v-menu>
</template>

<script>
import TrailerDotInspectionList from './TrailerDotInspectionList.vue'

export default {
  name: 'TrailerDotInspection',

  components: { TrailerDotInspectionList },

  props: ['trailer'],

  data() {
    return {
      menu: false,
    }
  },
}
</script>
