<template>
  <div>
    <v-card class="mx-auto" max-width="100%">
      <v-card-title>
        {{ $t('other.inbox') }}
      </v-card-title>
      <v-list v-if="messages.length" two-line>
        <v-list-item-group
          v-model="selected"
          active-class="pink--text"
        >
          <template v-for="(message, index) in messages">
            <v-list-item :key="message._id">
                <v-list-item-content>
                  <v-list-item-title v-text="message.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="message.content"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text>{{message.created | formatDateObj('MM/DD/YYYY HH:mm')}}</v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < messages.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
      <v-card-text v-else>
        Your inbox is empty
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-pagination
          @input="getMessages"
          v-model="page"
          :length="pages"
        ></v-pagination>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-if="selected !== null"
      :key="messages[selected]._id"
      persistent
      max-width="460"
      :value="true"
    >
      <v-card>
        <v-card-title class="headline">
          {{messages[selected].name}}
        </v-card-title>
        <v-card-text style="white-space: pre-wrap;" v-html="messages[selected].content"></v-card-text>
        <v-card-actions>
          <span class="caption">{{messages[selected].created | formatDateObj('MM/DD/YYYY HH:mm')}}</span>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="selected = null">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MessagesService from '../../services/MessagesService'
export default {
  data() {
    return {
      messages: [],
      selected: null,
      page: 1,
      pages: 0,
      perPage: 50
    }
  },
  methods: {
    getMessages() {
      MessagesService.getInboxMessages(this.page, this.perPage).then(res => {
        this.messages = res.data.docs
        this.pages = res.data.pages
      })
    }
  },
  mounted() {
    this.getMessages()
  }
}
</script>