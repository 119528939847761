import company from '../../services/CompaniesService'

const state = {
  // drawer: localStorage.getItem('drawer') ? JSON.parse(localStorage.getItem('drawer')) : null,
  drawer: false,
  appName: process.env.VUE_APP_APP_NAME,
  lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
  loading: false,
  message: '',
  alert: '',
  hiddenAlert:'',
  languages: {},
  settingsDialog: false,
  localSettings: localStorage.getItem('localSettings') ? JSON.parse(localStorage.getItem('localSettings')) : {
    dark: false,
    saveDrawer: true,
    userColor: '#E0F2F1FF'
  },
  messages: [],
  alerts: [],
  hiddenAlerts: [],
  companies: [],
  companiesAll: [],
  companiesShortAll: [],
  companiesFullSelect: []
}

const getters = {
  loading (state) {
    return state.loading
  },
  drawer (state) {
    return state.drawer
  },
  languages (state) {
    return state.languages
  },
  domains (state) {
    return state.domains
  },
  appName (state) {
    return state.appName
  },
  settingsDialog (state) {
    return state.settingsDialog
  },
  localSettings (state) {
    return state.localSettings
  },
  dark (state) {
    return state.localSettings.dark
  },
  saveDrawer (state) {
    return state.localSettings.saveDrawer
  },
  userColor (state) {
    return state.localSettings.userColor
  },
  messages (state) {
    return state.messages
  },
  alerts (state) {
    return state.alerts.filter(alert => {
      return !state.hiddenAlerts.some(hiddenAlert => 
        hiddenAlert.alertId === alert._id
      )
    })
  },
  hiddenAlerts (state) {
    return state.hiddenAlerts
  },
  companies (state) {
    return state.companies
  },
  companiesAll (state) {
    return state.companiesAll
  },
  companiesShortAll (state) {
    return state.companiesShortAll
  },
  companiesFullSelect (state) {
    return state.companiesFullSelect
  }
}

const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },
  setMessage (state, message) {
    state.message = message
  },
  setAlert (state, alert) {
    state.alert = alert
  },
  setDrawer (state, val) {
    state.drawer = val
    if (state.localSettings.saveDrawer) {
      localStorage.setItem('drawer', val)
    }
  },
  setLanguage (state, lang) {
    state.lang = lang
  },
  setSettingsDialog (state, dialog) {
    state.settingsDialog = dialog
  },
  setLocalSettings (state, data) {
    state.localSettings = data
    localStorage.setItem('localSettings', JSON.stringify(state.localSettings))
  },
  setTheme (state, theme) {
    state.localSettings.dark = theme
    localStorage.setItem('localSettings', JSON.stringify(state.localSettings))
  },
  setSaveDrawer (state, drawer) {
    state.localSettings.saveDrawer = drawer
    localStorage.setItem('localSettings', JSON.stringify(state.localSettings))
  },
  setUserColor (state, color) {
    state.localSettings.userColor = color
    if (typeof color === 'object') {
      state.localSettings.userColor = color.hexa
    }
    localStorage.setItem('localSettings', JSON.stringify(state.localSettings))
  },
  removeMessage (state, id) {
    let messageIndex = state.messages.findIndex(el => el._id === id)
    if (messageIndex !== -1) {
      state.messages.splice(messageIndex, 1)
    }
  },
  pushMessage(state, message) {
    state.messages.push(message)
  },
  removeAlert (state, id) {
    let alertIndex = state.alerts.findIndex(el => el._id === id)
    if (alertIndex !== -1) {
      state.alerts.splice(alertIndex, 1)
    }
  },
  pushAlert(state, alert) {
    state.alerts.push(alert)
  },
  hideAlert (state, id) {
    state.hiddenAlerts.push(id)
  },
  setCompanies (state, companies) {
    state.companies = companies
  },
  setCompaniesAll (state, companies) {
    state.companiesAll = companies
  },
  setCompaniesShortAll (state, companies) {
    state.companiesShortAll = companies
  },
  setCompaniesFull (state, companies) {
    state.companiesFullSelect = companies
  },
  addCompany (state, company) {
    const tmpCompanies = state.companies.slice()
    tmpCompanies.push(company)
    state.companies = tmpCompanies
  },
  editCompany (state, company) {
    let tmpCompanies = state.companies.slice()
    let index = tmpCompanies.findIndex(el => el._id === company._id)
    tmpCompanies.splice(index, 1, company)
    state.companies = tmpCompanies
  },
  deleteCompany (state, id) {
    const tmpCompanies = state.companies.slice()
    tmpCompanies.splice(tmpCompanies.findIndex(el => el._id === id), 1)
    state.companies = tmpCompanies
  }
}

const actions = {
  setLoading ({commit}, loading) {
    commit('setLoading', loading)
  },
  setMessage ({commit}, message) {
    commit('setMessage', message)
  },
  setAlert ({commit}, alert) {
    commit('setAlert', alert)
  },
  setDrawer ({commit}, val) {
    commit('setDrawer', val)
  },
  changeLang ({commit}, lang) {
    commit('setLanguage', lang)
    localStorage.setItem('lang', lang)
  },
  toggleSettingsDialog ({commit}, dailog) {
    commit('setSettingsDialog', dailog)
  },
  saveLocalSettings ({commit}, payload) {
    commit('setLocalSettings', payload)
  },
  saveTheme ({commit}, payload) {
    commit('setTheme', payload)
  },
  saveDawerSetting ({commit}, payload) {
    commit('setSaveDrawer', payload)
  },
  saveUserColor ({commit}, payload) {
    commit('setUserColor', payload)
  },
  removeMessage ({commit}, id) {
    commit('removeMessage', id)
  },
  addMessage ({commit}, message) {
    commit('pushMessage', message)
  },
  removeAlert ({commit}, id) {
    commit('removeAlert', id)
  },
  addAlert ({commit}, alert) {
    commit('pushAlert', alert)
  },
  hideAlert ({commit}, id) {
    commit('hideAlert', id)
  },
  getCompanies ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return company.getCompanies(payload).then(res => {
      commit('setCompanies', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllCompanies ({commit, dispatch, state}) {
    if (state.companiesAll.length) return Promise.resolve(state.companiesAll)

    dispatch('common/setLoading', true, {root: true})
    return company.getAllCompanies().then(res => {
      dispatch('common/setLoading', false, {root: true})
      const companiesFormated = res.data.map(el => el.name)
      const companiesShortFormated = res.data.map(el => el.short_name)
      commit('setCompaniesAll', companiesFormated)
      commit('setCompaniesFull', res.data)
      commit('setCompaniesShortAll', companiesShortFormated)
      return Promise.resolve(companiesFormated)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addCompany ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return company.addCompany(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addCompany', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editCompany ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return company.editCompany(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editCompany', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteCompany ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return company.deleteCompany(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteCompany', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
