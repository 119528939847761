<template>
  <div class="TableOrder">
    <v-btn color="pink" fab dark small @click="handleOpenDialog">
      <v-icon> mdi-order-bool-ascending </v-icon>
    </v-btn>

    <v-dialog
      max-width="300px"
      v-model="dialogShown"
      class="TableOrder__v-dialog"
    >
      <v-card>
        <v-card-title class="TableOrder__card-title">
          Table Order

          <v-btn color="red" fab dark small @click="handleCloseDialog">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-container v-if="data" class="TableOrder__v-container">
          <draggable
            :list="data"
            @change="handleMove"
            @start="dragging = true"
            @end="dragging = false"
          >
            <div
              v-for="(item, index) in data"
              :key="`column-${index}`"
              style="display: flex; align-items: center"
            >
              <v-icon
                :style="`cursor: ${
                  dragging ? 'grabbing' : 'grab'
                }; margin-top: 18px;`"
                >mdi-drag</v-icon
              >
              <v-switch
                small
                v-model="item.isShown"
                :label="findLabel(item.columnName)"
                @change="handleSwitchChange"
                :hide-details="true"
              ></v-switch>
            </div>
          </draggable>
        </v-container>

        <v-card-actions>
          <v-spacer />

          <v-btn
            @click="handleResetClicked"
            :disabled="loading"
            :loading="loading"
            large
            >Reset</v-btn
          >
          <v-btn
            @click="handleSubmit"
            color="primary"
            large
            :disabled="submitDisabled"
            :loading="loading"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tableOrderService from '../services/TableOrder'
import Draggable from 'vuedraggable'

export default {
  name: 'TableOrder',

  components: { Draggable },

  props: ['userId', 'tableName', 'default-order'],

  emits: ['order-changed'],

  data() {
    return {
      dialogShown: false,
      loading: false,
      data: null,
      anyChanged: false,
      dragging: false,
    }
  },

  async created() {
    await this.checkTableOrder()
  },

  computed: {
    submitDisabled() {
      return this.loading || !this.anyChanged
    },
  },

  methods: {
    handleSwitchChange() {
      this.anyChanged = true
    },

    findLabel(value) {
      const item = this.defaultOrder.find((o) => o.value === value)
      return item ? item.text : 'Something is wrong'
    },

    getInitialValues() {
      const columns = this.defaultOrder.map((c, i) => {
        if (c.value === 'is_active')
          return {
            columnName: 'is_active',
            isShown: false,
            order: i,
          }

        return {
          columnName: c.value,
          isShown: true,
          order: i,
        }
      })

      return { userId: this.userId, tableName: this.tableName, columns }
    },

    async checkTableOrder() {
      this.loading = true

      try {
        const { data } = await tableOrderService.getTableOrder(
          this.userId,
          this.tableName
        )

        if (data.tableOrder) {
          const { newColumns, changed } = await this.checkForColumnChanges(
            data.tableOrder.columns
          )
          this.data = newColumns
          if (changed) await this.handleSubmit()
          this.$emit('order-changed', newColumns)
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },

    async checkForColumnChanges(dbColumns) {
      let newColumns = [...dbColumns]
      let changed = false

      const columnsToRemove = dbColumns
        .filter((c) => !this.defaultOrder.find((h) => h.value === c.columnName))
        .map((c) => c.columnName)

      const columnsToAdd = this.defaultOrder.filter(
        (h) => !dbColumns.find((c) => h.value === c.columnName)
      )

      if (columnsToRemove.length > 0) {
        changed = true
        newColumns = newColumns.filter(
          (c) => !columnsToRemove.includes(c.columnName)
        )
      }

      if (columnsToAdd.length > 0) {
        changed = true
        for (const c of columnsToAdd)
          newColumns.push({
            columnName: c.value,
            isShown: true,
            order: newColumns.index,
          })
      }

      return { newColumns, changed }
    },

    async setInitialOrder() {
      const body = this.getInitialValues()

      this.loading = true

      try {
        const { data } = await tableOrderService.createTableOrder(body)

        if (data.tableOrder) {
          this.data = [...data.tableOrder.columns]
          this.$emit('order-changed', this.data)
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },

    async handleSubmit() {
      this.loading = true

      this.data.forEach((_, index) => {
        this.data[index].order = index
      })

      try {
        const { data } = await tableOrderService.editTableOrder(
          this.userId,
          this.tableName,
          this.data
        )

        if (data.tableOrder) {
          this.data = [...data.tableOrder.columns]
          this.$emit('order-changed', this.data)
          this.anyChanged = false
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },

    async handleOpenDialog() {
      if (!this.data) await this.setInitialOrder()
      this.dialogShown = true
    },

    handleCloseDialog() {
      this.dialogShown = false
    },

    async handleResetClicked() {
      const { columns } = this.getInitialValues()

      this.loading = true

      try {
        const { data } = await tableOrderService.editTableOrder(
          this.userId,
          this.tableName,
          columns
        )

        if (data.tableOrder) {
          this.data = [...data.tableOrder.columns]
          this.$emit('order-changed', this.data)
          this.anyChanged = false
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },

    handleMove() {
      this.anyChanged = true
    },
  },
}
</script>

<style lang="scss">
.TableOrder {
  &__loader {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__v-dialog {
    width: 300px;
  }

  &__card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__v-container {
    overflow-y: scroll;
    max-height: 400px;
  }
}
</style>