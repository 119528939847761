<template>
  <div class="CarModels">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :options="options"
      :items="items"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      dense
      @update:options="handleOptionsChange"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Car models</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px" @click:outside="close">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New car model
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.name"
                        :rules="[rules.required]"
                        prepend-icon="mdi-car"
                        label="Name*"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="form.make"
                        :items="makeOptions"
                        item-text="name"
                        label="Make"
                        prepend-icon="mdi-car-electric"
                        return-object
                      />
                    </v-col>
                    <v-col cols="8">
                      <v-text-field
                        v-model="form.note"
                        label="Note"
                        prepend-icon="mdi-calendar-text"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox v-model="form.is_active" label="Is active?" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="submitDisabled"
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
            @click:outside="closeDelete"
          >
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        <td style="text-align: center">
          <strong>
            {{ item.name }}
          </strong>
        </td>
      </template>

      <template v-slot:item.make="{ item }">
        <td style="text-align: center">
          {{ item.make && item.make.name }}
        </td>
      </template>

      <template v-slot:item.is_active="{ item }">
        <td style="text-align: center">
          {{ item.is_active ? 'Yes' : 'No' }}
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import service from '../../services/CarService'

const REQUIRED = ['name']

const footerProps = {
  'items-per-page-options': [20, 50, 100],
}

const DEFAULT_ITEM = {
  name: null,
  note: null,
  make: null,
  is_active: true,
}

export default {
  data: () => ({
    footerProps,
    dialog: false,
    dialogDelete: false,
    options: { page: 1, itemsPerPage: 10 },
    rules: { required: (value) => !!value || 'Required.' },

    headers: [
      {
        text: 'Name',
        sortable: false,
        value: 'name',
        align: 'center',
        width: 200,
      },
      {
        text: 'Make',
        sortable: false,
        value: 'make',
        align: 'center',
        width: 200,
      },
      {
        text: 'Note',
        sortable: false,
        align: 'center',
        value: 'note',
      },
      {
        text: 'Is active?',
        sortable: false,
        align: 'center',
        value: 'is_active',
        width: 90,
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: 60,
      },
    ],
    items: [],
    makeOptions: [],
    totalItems: 0,
    editId: null,
    form: { ...DEFAULT_ITEM },
  }),

  computed: {
    formTitle() {
      return this.editId ? 'Edit car model' : 'New car model'
    },

    submitDisabled() {
      for (const field of REQUIRED) if (!this.form[field]) return true
      return false
    },
  },

  async created() {
    await this.fetchItems()
    await this.getMakes()
  },

  methods: {
    async getMakes() {
      this.loading = true
      try {
        const { data } = await service.getCarMakes({ itemsPerPage: 500, is_active: 1 })
        if (Array.isArray(data.docs)) this.makeOptions = data.docs
      } catch (error) {
        console.error('CarModels getMakes()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async fetchItems() {
      this.loading = true
      try {
        const { data } = await service.getCarModels(this.options)
        this.totalItems = data.total
        this.items = data.docs
      } catch (error) {
        console.error('CarModels fetchItems()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    editItem(item) {
      const { _id, ...rest } = item
      this.editId = _id
      this.form = Object.assign({}, rest)
      this.dialog = true
    },

    deleteItem(item) {
      const { _id, ...rest } = item
      this.editId = _id
      this.form = Object.assign({}, rest)
      this.dialogDelete = true
    },

    async handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      await this.fetchItems()
    },

    async deleteItemConfirm() {
      this.loading = true
      try {
        await service.deleteCarModel(this.editId)
        await this.fetchItems()
      } catch (error) {
        console.error('CarModels deleteItemConfirm()', error)
        this.error = true
      } finally {
        this.loading = false
        this.closeDelete()
      }
    },

    resetForm() {
      this.form = Object.assign({}, DEFAULT_ITEM)
      this.editId = null
    },

    close() {
      this.resetForm()
      this.dialog = false
    },

    closeDelete() {
      this.resetForm()
      this.dialogDelete = false
    },

    async save() {
      this.loading = true
      try {
        if (this.editId) await service.editCarModel(this.editId, this.form)
        else await service.addCarModel(this.form)
        await this.fetchItems()
        this.close()
      } catch (error) {
        console.error('CarModels save()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.CarModels {
  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
    }
  }
}
</style>
