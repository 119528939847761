import eldTruck from '../../services/EldTrucksService'
const state = {
  eldTrucks: []
}

const getters = {
  eldTrucks (state) {
    return state.eldTrucks
  }
}

const actions = {
  getEldTrucks ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return eldTruck.getEldTrucks(payload).then(res => {
      commit('setEldTrucks', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllEldTrucks ({dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return eldTruck.getAllEldTrucks().then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getTrucksByCompanyId ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return eldTruck.getTrucksByCompanyId(id).then(res => {
      commit('setTrucksByCompanyId', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addEldTruck ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return eldTruck.addEldTruck(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addEldTruck', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editEldTruck ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return eldTruck.editEldTruck(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editEldTruck', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteEldTruck ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return eldTruck.deleteEldTruck(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteEldTruck', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setEldTrucks (state, eldTrucks) {
    state.eldTrucks = eldTrucks
  },
  addEldTruck (state, contact) {
    const tmpEldTrucks = state.eldTrucks.slice()
    tmpEldTrucks.push(contact)
    state.eldTrucks = tmpEldTrucks
  },
  editEldTruck (state, eldTruck) {
    let tmpEldTrucks = state.eldTrucks.slice()
    let index = tmpEldTrucks.findIndex(el => el._id === eldTruck._id)
    tmpEldTrucks.splice(index, 1, eldTruck)
    state.eldTrucks = tmpEldTrucks
  },
  deleteEldTruck (state, id) {
    const tmpEldTrucks = state.eldTrucks.slice()
    tmpEldTrucks.splice(tmpEldTrucks.findIndex(el => el._id === id), 1)
    state.eldTrucks = tmpEldTrucks
  },
  setTrucksByCompanyId(state,eldTrucks){
    state.eldTrucks = eldTrucks
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
