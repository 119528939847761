<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.contacts') }}
        <v-spacer></v-spacer>
        <v-text-field
            id="step-3"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('other.search')"
            single-line
            hide-details
            height="30"
            dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
          height="750"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="contacts"
          class="elevation-1"
          :loading="loading"
          :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.is_active="{item}">
          {{item.is_active ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
                small
                class="mr-2"
                @click="editContact(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteContact(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="handleAdd()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddContact" data-vv-scope="contact">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            {{form._id ? 'Edit' : 'Add'}} contact
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-autocomplete :items="users"
                  v-model="selected.user"
                  :value="selected.user"
                  label="Populate fileds"
                  item-value="_id"
                  item-text="name"
                  hide-details="auto"
                  @change="onUserSelect()"
                  return-object>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                    ref="name"
                    v-model="form.name"
                    v-validate="'required'"
                    :error="errors.has('contact.name')"
                    :error-messages="errors.first('contact.name')"
                    name="name"
                    id="name"
                    :autofocus="true"
                    placeholder="Name"
                ></v-text-field>
              </v-flex>
              <v-flex sm4 align-center justify-space-between>
                <v-text-field
                    ref="first_ext_field"
                    v-model="form.first_ext_field"
                    name="first_ext_field"
                    id="first_ext_field"
                    :autofocus="true"
                    placeholder="Floyd Ext"
                ></v-text-field>
              </v-flex>
              <v-flex sm4 align-center justify-space-between>
                <v-text-field
                    ref="second_ext_field"
                    v-model="form.second_ext_field"
                    name="second_ext_field"
                    id="second_ext_field"
                    :autofocus="true"
                    placeholder="Trytime ext"
                ></v-text-field>
              </v-flex>
              <v-flex sm4 align-center justify-space-between>
                <v-text-field
                    ref="dispatcher_ext_third"
                    v-model="form.dispatcher_ext_third"
                    name="dispatcher_ext_third"
                    id="dispatcher_ext_third"
                    :autofocus="true"
                    placeholder="Rocket Ext"
                ></v-text-field>
              </v-flex>
              <v-flex sm4 align-center justify-space-between>
                <v-text-field
                    ref="dispatcher_ext_fourth"
                    v-model="form.dispatcher_ext_fourth"
                    name="dispatcher_ext_fourth"
                    id="dispatcher_ext_fourth"
                    :autofocus="true"
                    placeholder="Jordan Ext"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 align-center justify-space-between>
                <v-text-field
                    ref="phone_number"
                    v-model="form.phone_number"
                    v-validate="'required'"
                    data-vv-as="phone"
                    :error="errors.has('contact.phone_number')"
                    :error-messages="errors.first('contact.phone_number')"
                    name="phone_number"
                    id="phone_number"
                    :autofocus="true"
                    placeholder="Phone Number"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 align-center justify-space-between>
                <v-text-field
                    ref="email"
                    v-model="form.email"
                    v-validate="'required|email'"
                    :error="errors.has('contact.email')"
                    :error-messages="errors.first('contact.email')"
                    name="email"
                    id="email"
                    :autofocus="true"
                    placeholder="Email"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 align-center justify-space-between>
                <v-text-field
                    ref="company"
                    v-model="form.company"
                    name="company"
                    id="company"
                    :autofocus="true"
                    placeholder="Company"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 align-center justify-space-between>
                <v-text-field
                    ref="position"
                    v-model="form.position"
                    name="position"
                    id="position"
                    :autofocus="true"
                    placeholder="Position"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 align-center justify-space-between>
                <v-autocomplete :items="users"
                  v-model="form.assistant"
                  :value="form.assistant"
                  label="Assistant"
                  item-value="_id"
                  item-text="name"
                  hide-details="auto"
                  return-object>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                    v-model="form.is_active"
                    label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeDialog">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'Contacts',
  data() {
    return {
      users: [],
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 15
      },
      search: '',
      optionsUser: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 10000000
      },
      selected: {
        user: null
      },
      totalItems: 0,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Floyd Ext', value: 'first_ext_field'},
        {text: 'Trytime ext', value: 'second_ext_field'},
        {text: 'Rocket ext', value: 'dispatcher_ext_third'},
        {text: 'Jordan ext', value: 'dispatcher_ext_fourth'},
        {text: 'Phone Number', value: 'phone_number'},
        {text: 'Email', value: 'email'},
        {text: 'Position', value: 'position'},
        // {text: 'Assistant', value: 'assistant.name'},
        {text: 'Company', value: 'company'},
        {text: 'Is active', value: 'is_active'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        name: '',
        first_ext_field: '',
        second_ext_field: '',
        dispatcher_ext_third: '',
        dispatcher_ext_fourth: '',
        phone_number: '',
        email: '',
        position: '',
        company: '',
        is_active: true,
        assistant: null
      },
      formDialog: false
    }
  },
  watch: {
    options: {
      handler () {
        this.$store.dispatch('contacts/getContacts', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true,
    },
    search: function (newSearch) {
      this.searchContacts(newSearch)
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('contacts', ['contacts']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    handleAdd(){
      this.formDialog = true
      this.$store.dispatch('user/getUsers', this.optionsUser).then(res => {
        this.users = res.docs
      })
    },
    onAddContact () {
      this.$validator.validateAll('contact').then((result) => {
        if (result) {
          if (this.form._id) {
            this.$store.dispatch('contacts/editContact', this.form).then(res => {
              if (res) {
                this.formDialog = false
                this.clearForm()
              }
            })
          } else {
            this.$store.dispatch('contacts/addContact', this.form).then(res => {
              if (res) {
                this.formDialog = false
                this.clearForm()
              }
            })
          }
        }
      })
    },
    editContact (contact) {
      this.$store.dispatch('user/getUsers', this.optionsUser).then(res => {
        this.users = res.docs
      })
      this.form = {...contact}
      this.formDialog = true
    },
    deleteContact (contact) {
      let confVal = confirm(`Are you sure you want to delete contact: ${contact.name}?`)
      if (confVal) {
        this.$store.dispatch('contacts/deleteContact', contact._id).then(() => {
          this.setMessage(`${contact.name} successfully deleted`)
        }).catch((err) => {
          console.log('err on delete', err)
        })
      }
    },
    onUserSelect(){
      const user = this.selected.user
      console.log('user', user)
      this.form.name = user.name
      this.form.email = user.email
      this.form.first_ext_field = user.ext
      this.form.second_ext_field = user.ext_second
      this.form.dispatcher_ext_third = user.ext_third
      this.form.dispatcher_ext_fourth = user.ext_fourth
      this.form.position = user.roles[0].name
    },
    clearForm() {
      this.form = {
        _id: null,
        name: '',
        first_ext_field: '',
        second_ext_field: '',
        dispatcher_ext_third: '',
        dispatcher_ext_fourth: '',
        phone_number: '',
        email: '',
        position: '',
        company: '',
        is_active: true,
        assistant: null
      }
    },
    closeDialog() {
      this.formDialog = false
      this.clearForm()
    },
    searchContacts: debounce( function(search) {
      this.options.search = search
      this.$store.dispatch('contacts/getContacts', this.options).then(res => {
        this.options.totalItems = res.total
      })
    }, 300),
  }
}
</script>

<style lang="scss">
.boards-wrapper {
  width: 100%;
  margin-top: 20px;
}

// @media only screen and (max-width: 599px) {
//   .boards-wrapper {
//     margin-top: 65px;
//   }
// }
</style>