<template>
  <div class="registration-fields d-flex" v-if="registrationConfig">
    <v-select
      class="mr-1"
      v-model="form.type"
      :items="registrationConfig.options"
      label="Registration"
      hide-details
      small
      @change="emitFormData"
    />
    <v-text-field
      v-if="
        registrationConfig.additionalFields &&
        registrationConfig.additionalFields.includes('amount')
      "
      prefix="$"
      class="mr-1"
      label="Amount"
      v-model="form.amount"
      type="number"
      @change="emitFormData"
    ></v-text-field>
    <v-menu
      v-if="
        registrationConfig.additionalFields &&
        registrationConfig.additionalFields.includes('start_date')
      "
      ref="startDate"
      v-model="startDateMenu"
      :close-on-content-click="false"
      :return-value.sync="form.start_date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="mr-1"
          v-model="form.start_date"
          label="Start date"
          readonly
          v-bind="attrs"
          v-on="on"
          clearable
          hide-details
          small
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="form.start_date"
        no-title
        scrollable
        @change="emitFormData"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="startDateMenu = false">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.startDate.save(form.start_date)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-menu
      v-if="
        registrationConfig.additionalFields &&
        registrationConfig.additionalFields.includes('until_date')
      "
      ref="untilDate"
      v-model="untilDateMenu"
      :close-on-content-click="false"
      :return-value.sync="form.until_date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="form.until_date"
          label="Until date"
          readonly
          v-bind="attrs"
          v-on="on"
          clearable
          hide-details
          small
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="form.until_date"
        no-title
        scrollable
        @change="emitFormData"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="untilDateMenu = false">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.untilDate.save(form.until_date)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { UNIT_FORM_CONFIG_BY_TYPE } from '../../utils/constants.js'

export default {
  name: 'RegistrationInput',
  props: {
    unitType: {
      type: String,
      required: true,
    },
    regData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    registrationConfig() {
      return this.unitType
        ? UNIT_FORM_CONFIG_BY_TYPE[this.unitType].registration
        : null
    },
  },
  data() {
    return {
      startDateMenu: false,
      untilDateMenu: false,
      form: {
        type: '',
        amount: 0,
        start_date: null,
        until_date: null,
      },
    }
  },
  created() {
    this.form = {
      ...this.form,
      ...this.regData,
      start_date: this.regData && this.regData.start_date
        ? this.$options.filters.formatDatePicker(this.regData.start_date)
        : null,
      until_date: this.regData && this.regData.until_date
        ? this.$options.filters.formatDatePicker(this.regData.until_date)
        : null,
    }
  },
  methods: {
    emitFormData() {
      this.form.amount = parseFloat(this.form.amount)
      this.$emit('input', this.form)
    },
  },
}
</script>
