<template>
  <v-input disabled>
    <div class="readonly-input">
      <span class="readonly-input__label">{{label}}</span>
      <span class="readonly-input__value">{{value}}</span>
    </div>
  </v-input>
</template>

<script>
export default {
  name: 'ReadonlyField',
  props: {
    value: {
      type: [String, Number, Date],
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.readonly-input {
  display: flex;
  flex-direction: column;

  &__label {
    color: rgba(0, 0, 0, 0.38);
    font-size: 11px;
  }
}
</style>