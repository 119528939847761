import Api from '@/services/Api'

export default {
  getEmploymentHistories(id) {
    return Api().get(`/employment-history/${id}`)
  },
  addEmploymentHistory(employmentHistory) {
    return Api().post('/employment-history', employmentHistory)
  },
  editEmploymentHistory(employmentHistory) {
    return Api().put('/employment-history', employmentHistory)
  },
  deleteEmploymentHistory(id) {
    return Api().delete(`/employment-history/${id}`)
  },
}
