<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.messages') }}
      </v-card-title>
      <v-data-table
          height="750"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="messages"
          class="elevation-1"
          :loading="loading"
          :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.created="{item}">
          {{item.created | formatDateObj('MM/DD/YYYY HH:mm')}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
                small
                class="mr-2"
                @click="viewMessage(item)"
            >
              mdi-eye
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="formDialog = true"
        >
          <v-icon @click="form = {}">mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddMessage" data-vv-scope="message">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            {{!form._id ? 'Send message' : 'View Message'}}
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                    ref="name"
                    prepend-icon="mdi-format-title"
                    v-model="form.name"
                    :disabled="form._id"
                    v-validate="'required'"
                    :error="errors.has('message.name')"
                    :error-messages="errors.first('message.name')"
                    name="name"
                    id="name"
                    :autofocus="true"
                    placeholder="Title"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-textarea
                    ref="content"
                    prepend-icon="mdi-message"
                    name="content"
                    id="content"
                    v-model="form.content"
                    :disabled="form._id"
                    label="Message content"
                    v-validate="'required'"
                    :error="errors.has('message.content')"
                    :error-messages="errors.first('message.content')"
                    :autofocus="true"
                    placeholder="Content"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-checkbox
                    v-model="form.daily_report"
                    label="Daily report for market updates?"
                    :value="form.daily_report"
                    :disabled="form.weekly_report"
                ></v-checkbox>
                <v-checkbox
                    v-model="form.weekly_report"
                    label="Weekly report for market updates?"
                    :value="form.weekly_report"
                    :disabled="form.daily_report"
                ></v-checkbox>
                <v-select :items="roles"
                          name="role"
                          clearable
                          v-validate="'required'"
                          :error="errors.has('add.role')"
                          :error-messages="errors.first('add.role')"
                          v-model="selected.roles"
                          prepend-icon="mdi-face"
                          append-outer-icon="mdi-check-all"
                          label="Role"
                          :disabled="form._id"
                          item-value="name"
                          item-text="name"
                          hide-details="auto"
                          multiple
                          return-object
                          @click:append-outer="selectAll"
                >
                </v-select>
              </v-flex>
              <!-- <v-flex xs12>
                <v-checkbox
                    v-model="form.is_active"
                    :disabled="form._id"
                    label="Is active"
                ></v-checkbox>
              </v-flex> -->
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="formDialog = false">Cancel</v-btn>
            <v-btn v-if="!form._id" color="info" :loading="loading" :disabled="loading" type="submit">Send</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddMessage" data-vv-scope="message">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            {{!form._id ? 'Add message' : 'View Message'}}
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                    ref="name"

                    v-model="form.name"
                    :disabled="form._id ? true : false"
                    v-validate="'required'"
                    :error="errors.has('message.name')"
                    :error-messages="errors.first('message.name')"
                    name="name"
                    id="name"
                    :autofocus="true"
                    placeholder="Name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-textarea
                    ref="content"
                    name="content"
                    id="content"
                    v-model="form.content"
                    :disabled="form._id ? true : false"
                    label="Message content"
                    v-validate="'required'"
                    :error="errors.has('message.content')"
                    :error-messages="errors.first('message.content')"
                    :autofocus="true"
                    placeholder="Content"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-checkbox
                    v-if="form.daily_report"
                    v-model="form.daily_report"
                    label="Daily report for market updates?"
                    :value="form.daily_report"
                    :disabled="form._id ? true : false"
                ></v-checkbox>
                <v-checkbox
                    v-else-if="form.weekly_report"
                    v-model="form.weekly_report"
                    label="Weekly report for market updates?"
                    :value="form.weekly_report"
                    :disabled="form._id ? true : false"
                ></v-checkbox>
                <v-select :items="roles"
                          name="role"
                          v-validate="'required'"
                          :error="errors.has('add.role')"
                          :error-messages="errors.first('add.role')"
                          v-model="selected.roles"
                          prepend-icon="mdi-face"
                          label="Role"
                          :disabled="form._id ? true : false"
                          item-value="name"
                          item-text="name"
                          hide-details="auto"
                          multiple
                          return-object
                >
                </v-select>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                    v-model="form.is_active"
                    :disabled="form._id ? true : false"
                    label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="onCloseViewDialog">Cancel</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import usersService from '@/services/UsersService'
export default {
  name: 'Messages',
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 15
      },
      totalItems: 0,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Content', value: 'content'},
        {text: 'Created', value: 'created'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        name: '',
        content: '',
        is_active: true,
        daily_report: false,
        weekly_report: false
      },
      formDialog: false,
      viewDialog: false,
      roles: [],
      selected: {
        roles: null
      }
    }
  },
  mounted() {
    this.getRoles()
  },
  watch: {
    options: {
      handler () {
        this.$store.dispatch('messages/getMessages', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true,
    },
    form: {
      handler(newValue) {
        if (newValue.daily_report || newValue.weekly_report) {
          this.selected.roles = this.roles.find(role => role.name === 'Dispatcher')
        } else {
          this.selected.roles = []
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('messages', ['messages']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    onAddMessage () {
      this.$validator.validateAll('message').then((result) => {
        if (result) {
          this.form.roles = this.selected.roles
          this.$store.dispatch('messages/addMessage', this.form).then(res => {
            if (res) {
              this.formDialog = false
              this.form = {
                name: '',
                content: '',
                is_active: true,
                daily_report: false,
                weekly_report: false,
              }
            }
          })
        }
      })
    },
    viewMessage (message) {
      this.form = {...message}
      this.viewDialog = true
    },
    onCloseViewDialog(){
      this.form = {}
      this.viewDialog = false
    },
    getRoles (){
      usersService.getRoles({}).then(response => {
        this.$store.dispatch('common/setLoading', false)
        this.roles = response.data.docs
      })
    },
    selectAll () {
      this.selected.roles = [...this.roles]
    }
  }
}
</script>

<style lang="scss">
.boards-wrapper {
  width: 100%;
  margin-top: 20px;
}

.v-list-item.move {
  cursor: move;

  .v-list-item__title.teamLead {
    color: green;
  }
}

// @media only screen and (max-width: 599px) {
//   .boards-wrapper {
//     margin-top: 65px;
//   }
// }
</style>