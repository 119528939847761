<template>
  <div>
    <div class="pl-4 pt-4 pr-6 shop-filter-map">
      <div class="d-flex mb-2 justify-center">
        <table-filters
          ref="table-filters"
          :initial-form="filtersForm"
          :initial-values="{ ...options }"
          :shopButtonAvailable="shopButtonAvailable"
          :hideClearButton="false"
          hide-clear-button
          @filters-applied="handleFiltersApplied"
          @filters-shop-reset="resetFilters"
          :isShop="true" />
      </div>
      <div class="d-flex mb-2 justify-center">
        <table-filters
          ref="table-filters-radio"
          :initial-form="filtersFormRadio"
          :initial-values="{...options}"
          @filters-applied="handleFiltersAppliedRadio"
          @filters-shop-reset="resetFilters"
          :isDividerVisible="true"
          :hideClearButton="true"
          :isShop="true" />
      </div>
      <v-alert v-if="postalCodeError" type="error" dismissible>
        Postal Code must have at least 3 letters.
      </v-alert>
    </div>
    <div class="shops-wrapper">
     <div class="shops-container mr-4 pl-4" style="width: 406px;">
        <template v-if="shopsLoaded">
          <ShopCard v-for="shop in shops" :key="shop.id" :shop="shop" />
        </template>
        <v-row v-if="shops.length == 0 && shopsLoaded" class="pt-10 d-flex justify-center align-center" style="max-width: 390px;">
          <v-col align="center">
            <v-icon size="120">mdi-map-search-outline</v-icon>
            <v-card-text class="pb-0 font-weight-bold grey--text text-h4">No shops match.</v-card-text>
            <v-card-text class="font-weight-bold grey--text text-h5">Try adjusting your search or zooming out / in on the
              map.</v-card-text>
          </v-col>
        </v-row>
        <v-col v-if="!shopsLoaded">
          <v-skeleton-loader type="card"></v-skeleton-loader>
          <v-skeleton-loader type="card"></v-skeleton-loader>
          <v-skeleton-loader type="card"></v-skeleton-loader>
          <v-skeleton-loader type="card"></v-skeleton-loader>
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </div>
      <div class="map-wrapper pt-4" >
        <MapBoxShop ref="mapBox" @map-view-changed="mapViewCoordsChanged"/>
        <v-fab-transition>
        <v-btn v-if="permissions.ADD_SHOP" class="fab-btn font-weight-medium text-body-1 mr-15" color="primary" absolute right bottom rounded large fixed
          @click="shopDialogForm = true">
          <v-icon class="mr-1">mdi-plus</v-icon>Add new shop
        </v-btn>
        </v-fab-transition>
        <v-card v-if="blacklistedShops.length && options.status != null"
          class="shop-blacklisted-box"
          min-width="240px"
          max-width="280px"
          max-height="416px"
          min-height="374px">
            <div v-for="shop in blacklistedShops" :key="shop.id">
              <div class="shop-blacklisted-box-content pt-4" @click="redirectToShop(shop.id)" @mouseenter="handleBlacklistedBoxShopHovered(shop)" @mouseleave="handleBlacklistedBoxShopNotHovered(shop)">
                <v-card-text class="pb-0 pt-0 font-weight-bold text-h6 shop-blacklisted-box-name text-truncate">{{ shop.name }}</v-card-text>
                <v-card-text v-if="shop.franchise" class="pt-0 pb-0 font-weight-medium text-body-2 shop-blaclisted-box-franchise">{{shop.franchise.name}}</v-card-text>
              </div>
            </div>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="shopDialogForm" max-width="560px" @click:outside="shopDialogForm = false">
      <v-card>
        <AddShopForm v-if="shopDialogForm" @shop-added="shopAdded" @close-dialog="shopDialogForm = false"/>
      </v-card>
    </v-dialog>
    <v-dialog v-model="shopAddedDialog" max-width="420px">
      <v-card>
        <div align="center" class="pl-8 pt-8 pr-8 pb-8">
          <v-card-text class="pt-0 font-weight-bold text-h6">Add New Shop</v-card-text>
          <v-divider></v-divider>
          <v-card-text class="pt-3 pb-7 text-body-2 font-weight-medium">You have successfully added a new shop. Do you
            want to look at the shop page now?</v-card-text>
          <v-btn class="mr-4 primary--text white--text" outlined @click="handleNotNowClick">Not Now</v-btn>
          <v-btn color="primary" @click="redirectToCreatedShop">Yes</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import debounce from 'lodash/debounce'
import MsShopService from '../../services/MsShopService'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import MapBoxShop from '@/components/Shop/MapBoxShop.vue'
import ShopCard from '@/components/Shop/ShopCard.vue'
import AddShopForm from '@/components/Shop/AddShopForm.vue'
import TableFilters from '../TableFilters.vue'

import EventBus from '@/eventbus/EventBus.js'

export default {
  name: 'Shop',
  components: {
    AddShopForm,
    TableFilters,
    MapBoxShop,
    ShopCard
  },
  data: () => ({
    filtersForm: {
      // name: {
      //   label: 'Name',
      //   type: 'text',
      // },
      franchise: {
        label: 'Franchise',
        type: 'select',
        options: []
      },
      postalCode: {
        label: 'Postal Code',
        type: 'text',
      },
      city: {
        type: 'autocomplete',
        label: 'City',
        apiUrl: '/loads/autocomplete/cities',
        dataKey: 'cities'
      },
      radius: {
        label: 'Radius 80mi',
        type: 'text',
        inputType: 'number'
      },
      type: {
        type: 'select',
        label: 'Type',
        options: [
          {
            text: 'Truck',
            value: 'TRUCK',
          },
          {
            text: 'Trailer',
            value: 'TRAILER',
          },
          {
            text: 'Towing',
            value: 'TOWING'
          },
          {
            text: 'Secured Parking',
            value: 'SECURED_PARKING',
          },
          {
            text: 'Mobile Shop',
            value: 'MOBILE_SHOP'
          },
          {
            text: 'Detailing',
            value: 'DETAILING'
          },
          // Novi tipovi
          {
            text: 'Frame Alignment & Suspension',
            value: 'FRAME_ALIGNMENT_SUSPENSION',
          },
          {
            text: 'Glass Repair',
            value: 'GLASS_REPAIR',
          },
          {
            text: 'Trailer Refrigeration Repair',
            value: 'TRAILER_REFRIGERATION_REPAIR',
          },
          {
            text: 'Welding',
            value: 'WELDING',
          },
          {
            text: 'Lock Out Service',
            value: 'LOCK_OUT_SERVICE',
          },
          {
            text: 'Mobile Fueling',
            value: 'MOBILE_FUELING',
          }
        ],
        multiple: true
      },
    },
    filtersFormRadio: {
      filter: {
        type: 'radio-group',
        options: [
          { label: 'All', value: 'ALL' },
          { label: 'Gold', value: 'GOLD' },
          { label: 'Platinum', value: 'PLATINUM' },
          { label: 'Silver', value: 'SILVER' }
        ],
      },
      status: {
        type: 'radio-group',
        options: [
          { label: 'All', value: 'ALL' },
          { label: 'Blacklisted', value: 'BLACKLISTED' }
        ],
      },
    },
    options: {
      status: null,
      filter: 'ALL',
      name: '',
      franchise: '',
      postalCode: '',
      city: null,
      radius: null,
      type: []
    },
    franchises: [],
    shopButtonAvailable: true,
    shopAddedDialog: false,
    shopDialogForm: false,
    shops: [],
    postalCodeError: false,
    newlyCreatedShopId: '',
    shopsLoaded: false,
    filtersInputs: {
      name: '',
      franchise: '',
      postalCode: '',
      city: null,
      type: []
    },
    filtersRadio: {
      status: null,
      filter: 'ALL',
    }
  }),
  async mounted() {
    this.getFilteredShops(this.formatFilterParams(this.options))
    this.getFranchises()
  },
  computed: {
    areFiltersEmpty() {
      return (
        this.options.status == null &&
        ( this.options.filter == 'ALL' || this.options.filter == null ) &&
        ( this.options.name == '' || this.options.name == null ) &&
        ( this.options.franchise == '' || this.options.franchise == null ) &&
        ( this.options.postalCode == '' || this.options.postalCode == null ) &&
        this.options.city == null &&
        ( this.options.radius == null || this.options.radius == undefined || this.options.radius == '' ) &&
        this.options.type.length == 0
      )
    },
    computedGeoJsonData() {
      return {
        type: 'FeatureCollection',
        features: this.shops.map(obj => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [obj.longitude, obj.latitude]
          },
          properties: {
            id: obj.id,
            label: obj.label,
            averageRating: obj.averageRating,
            name: obj.name,
            type: obj.type,
            address: obj.address.text,
            franchise: obj.franchise ? obj.franchise.name : '',
            franchiseId: obj.franchise ? obj.franchise.id : null,
            status: obj.details[0]?.status || null
          }
        }))
      }
    },
    ...mapGetters('auth', ['permissions']),
    // shopPinData() {
    //   return this.shops.map(shop => ({
    //     id: shop.id,
    //     averageRating: shop.averageRating,
    //     label: shop.label,
    //     name: shop.name,
    //     franchise: shop.franchise,
    //     address: shop.address,
    //     latitude: Number(shop.latitude),
    //     longitude: Number(shop.longitude),
    //     status: shop.details[0] && shop.details[0].status
    //   }))
    // },
    blacklistedShops(){
      return this.shops.filter((shop) => shop.details && shop.details[0] && shop.details[0].status === 'BLACKLISTED')
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    handleFiltersApplied: debounce(async function (filters) {
      this.filtersInputs = {
        name: filters.name,
        franchise: filters.franchise,
        postalCode: filters.postalCode,
        city: filters.city,
        radius: filters.radius,
        type: filters.type
      }
      const filterCombination = {...this.filtersInputs, ...this.filtersRadio}
      const data = this.formatFilterParams(filterCombination)

      await this.getFilteredShops(data)
      if(this.areFiltersEmpty){
        this.$refs.mapBox.resetMap()
      }
      else{
        this.$refs.mapBox.updateSource(this.computedGeoJsonData)
      }
    }, 500),
    handleFiltersAppliedRadio: debounce(async function (filters) {
      this.filtersRadio = {
        filter: filters.filter,
        status: filters.status
      }
      const filterCombination = {...this.filtersInputs, ...this.filtersRadio}
      const data = this.formatFilterParams(filterCombination)

      await this.getFilteredShops(data)
      if(this.areFiltersEmpty){
        this.$refs.mapBox.resetMap()
      }
      else{
        this.$refs.mapBox.updateSource(this.computedGeoJsonData)
      }
    }, 500),
    async resetFilters(values) {
      this.filtersInputs = {
        name: '',
        franchise: '',
        postalCode: '',
        city: null,
        radius: null,
        type: []
      }
      this.filtersRadio = {
        status: null,
        filter: 'ALL',
      }
      // Reset options to init state
      this.options = {
        status: null,
        filter: 'ALL',
        name: '',
        franchise: '',
        postalCode: '',
        city: null,
        radius: null,
        type: []
      }

      const data = {
        name: values.name,
        type: values.type,
        postalCode: values.postalCode,
        status: values.status === 'ALL' ? null : values.status,
        label: values.filter === 'BLACKLISTED' ? null : (values.filter === 'ALL' ? null : values.filter),
        franchiseId: values.franchise,
        locationLat: values.city ? values.city.lat : null,
        locationLng: values.city ? values.city.lng : null,
      }
      await this.getFilteredShops(data)
      this.$refs.mapBox.resetMap()
    },
    async getFilteredShops(data) {
      try {
        this.shopsLoaded = false
        this.$store.dispatch('common/setLoading', true)
        const result = await MsShopService.getShops(data)
        this.shops = [...result.data]
        this.$store.dispatch('common/setLoading', false)
        if (this.shops.length == 0) this.setMessage('No shops match the applied filters.')
        this.shopsLoaded = true
      } catch {
        this.setMessage('Error submitting filters ')
      }
    },
    async getFranchises() {
      try {
        this.franchises = await MsShopService.getFranchises()
        this.filtersForm.franchise.options = this.franchises.data.franchises.map(franchise => ({
          text: franchise.name,
          value: franchise.id
        }))
      } catch (error) {
        console.error('Error fetching franchises:', error)
      }
    },
    mapViewCoordsChanged: debounce(async function(mapData) {

      // reset filter component
      this.$refs['table-filters'].handleRemoveValue('city')
      this.$refs['table-filters'].handleRemoveValue('radius')
      this.$refs['table-filters'].handleRemoveValue('postalCode')

      const formatedFIlterParams = this.formatFilterParams(this.options)
      const data = {
        ...formatedFIlterParams,
        east: mapData.east,
        north: mapData.north,
        south: mapData.south,
        west: mapData.west,
        postalCode: null,
        locationLat: null,
        locationLng: null,
        radius: null
      }
      await this.getFilteredShops(data)
      if(this.areFiltersEmpty){
        this.$refs.mapBox.resetMap(false)
      }
      else{
        this.$refs.mapBox.updateSource(this.computedGeoJsonData, false)
      }
    }, 600),
    formatFilterParams(filters) {
      if (filters.postalCode && filters.postalCode.length < 3) {
        this.postalCodeError = true
        return
      }
      this.postalCodeError = false

      // Set options to keep state of applayed filters
      this.options = {
        status: filters.status === 'ALL' ? null : filters.status,
        filter: filters.filter === 'BLACKLISTED' ? null : (filters.filter === 'ALL' ? null : filters.filter),
        name: filters.name,
        franchise: filters.franchise,
        postalCode: filters.postalCode,
        city: filters.city,
        radius: filters.radius,
        type: filters.type
      }

      return {
        name: filters.name,
        type: filters.type,
        postalCode: filters.postalCode,
        status: filters.status === 'ALL' ? null : filters.status,
        label: filters.filter === 'BLACKLISTED' ? null : (filters.filter === 'ALL' ? null : filters.filter),
        franchiseId: filters.franchise,
        locationLat: filters.city ? filters.city.lat : null,
        locationLng: filters.city ? filters.city.lng : null,
        radius: filters.radius
      }
    },
    redirectToShop(shopId){
      this.$router.push({ name: 'ShopDetails', params: { shopId: shopId } })
    },
    handleBlacklistedBoxShopHovered(shop){
      EventBus.$emit('blacklistedBoxShopHovered', shop)
    },
    handleBlacklistedBoxShopNotHovered(shop){
      EventBus.$emit('blacklistedBoxShopNotHovered', shop)
    },
    shopAdded(shopId) {
      this.newlyCreatedShopId = shopId
      this.shopDialogForm = false
      this.shopAddedDialog = true
    },
    redirectToCreatedShop() {
      if (this.newlyCreatedShopId) this.$router.push({ name: 'ShopDetails', params: { shopId: this.newlyCreatedShopId } })
      this.newlyCreatedShopId = ''
    },
    async handleNotNowClick() {
      this.shopAddedDialog = false
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/variables/variables.scss';

.shop-filter-map {
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  z-index: 3;
}

.fab-btn {
  margin-bottom: -8px;
  z-index: 2;
}

.shop-here-map-disabled{
  pointer-events: none;
}

.shops-wrapper {
  display: flex;
  margin-top: 80px;
  height: calc(100vh - 80px - 72px);

  .shops-container {
    max-height: 100%;
    min-width: 390px;
    overflow-y: scroll;
    direction: rtl;
  }

  .map-wrapper {
    position: relative !important;
    flex: 1;

    .shop-here-map {
      height: 100%;
    }
    .shop-blacklisted-box {
      overflow-y: scroll;
      opacity: 0.5;
      background-color: #070B13;
      position: absolute;
      top: 20px;
      right: 20px;
      &:hover {
        opacity: 1;
      }
      .shop-blacklisted-box-content{
        &:hover{
          cursor: pointer;
          opacity: 0.7 !important;
        }
      }
      .shop-blacklisted-box-name{
        color: $blacklisted-color !important;
      }
      .shop-blaclisted-box-franchise{
        color: #B4C5E4 !important;
      }
    }

  }
}
</style>
