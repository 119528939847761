import loadType from '../../services/LoadTypeService'

const state = {
  loadTypes: []
}

const getters = {
  loadTypes (state) {
    return state.loadTypes
  }
}

const actions = {
  getLoadTypes ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return loadType.getLoadTypes(payload).then(res => {
      commit('setLoadTypes', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllLoadTypes ({dispatch}) {
    return loadType.getAllLoadTypes().then(res => {
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addLoadType ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return loadType.addLoadType(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addLoadType', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editLoadType ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return loadType.editLoadType(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editLoadType', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteLoadType ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return loadType.deleteLoadType(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteLoadType', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setLoadTypes (state, loadTypes) {
    state.loadTypes = loadTypes
  },
  addLoadType (state, loadType) {
    const tmpLoads = state.loadTypes.slice()
    tmpLoads.push(loadType)
    state.loadTypes = tmpLoads
  },
  editLoadType (state, loadType) {
    let tmpLoads = state.loadTypes.slice()
    let index = tmpLoads.findIndex(el => el._id === loadType._id)
    tmpLoads.splice(index, 1, loadType)
    state.loadTypes = tmpLoads
  },
  deleteLoadType (state, id) {
    const tmpLoads = state.loadTypes.slice()
    tmpLoads.splice(tmpLoads.findIndex(el => el._id === id), 1)
    state.loadTypes = tmpLoads
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
