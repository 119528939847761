<template>
  <div class="InactiveDrivers">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :options="options"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      @update:options="handleOptionsChange"
      item-key="_id"
      disable-sort
      dense
      class="elevation-1"
    >
      <template v-slot:item.date_from="{ item }">
        {{ item.date_from | formatDate }}
      </template>

      <template v-slot:item.date_to="{ item }">
        {{ item.date_to | formatDate }}
      </template>

      <template v-slot:item.driver_name="{ item }">
        {{ getName(item) }}
      </template>

      <template v-slot:item.truck="{ item }">
        {{ getTruck(item) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer />
          <v-text-field
            v-model="search"
            @input="handleSearch"
            dense
            hide-details
            small
            solo
            clearable
            style="max-width: 160px"
            label="Search"
          ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import service from '../../services/TrailersService'

export default {
  data() {
    return {
      search: null,
      loading: false,
      items: [],
      totalItems: 0,

      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },

      options: {
        page: 1,
        itemsPerPage: 30,
      },

      headers: [
        {
          text: 'From',
          value: 'date_from',
        },
        {
          text: 'To',
          value: 'date_to',
        },
        {
          text: 'Driver Name',
          value: 'driver_name',
        },
        {
          text: 'Truck',
          value: 'truck',
        },
        {
          text: 'Trailer',
          value: 'subject',
        },
        {
          text: 'Company',
          value: 'company',
        },
      ],
    }
  },

  methods: {
    handleSearch: debounce(function (v) {
      this.getItems(v)
    }, 200),

    handleOptionsChange() {
      this.getItems()
    },

    getName({ name }) {
      const arr = name.split(' - ')
      if (!Array.isArray(arr)) return null
      return arr[0]
    },

    getTruck({ name }) {
      const arr = name.split(' - ')
      if (!Array.isArray(arr)) return null
      return arr[1]
    },

    async getItems(search) {
      this.loading = true
      try {
        const { data } = await service.getInactiveDriverHistory({ search })
        this.totalItems = data.totalItems
        this.items = data.docs
      } catch (error) {
        console.error('getItems()', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.InactiveDrivers {
  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
      position: relative;
    }
  }
}
</style>
