import Api from '@/services/Api'

export default {
  getDotInspections (pagination) {
    return Api().get('/dot-inspections', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1
    }})
  },
  addDotInspection (dotInspection) {
    return Api().post('/dot-inspections', dotInspection)
  },
  editDotInspection (dotInspection) {
    return Api().put('/dot-inspections', dotInspection)
  },
  deleteDotInspection (id) {
    return Api().delete(`/dot-inspections/${id}`)
  },
  getAllDotInspections () {
    return Api().get('/dot-inspections/all')
  }
}
