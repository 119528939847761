<template>
  <v-footer fixed elevation="6">
    <v-btn
      v-if="trucksLink"
      color="primary"
      class="ml-4 elevation-2"
      style="position: sticky; left: 0"
      dark
      small
      :to="{ name: 'trucks', params: { filter: 'all' } }"
    >
      DRIVER / TRUCK
    </v-btn>
    <v-btn
      v-if="$route.name !== 'AvailableView'"
      color="success"
      class="ml-2 elevation-2"
      style="position: sticky; left: 0"
      dark
      small
      :to="{ name: 'AvailableView' }"
    >
      Available trucks
    </v-btn>
    <v-btn
      color="primary"
      class="ml-4 elevation-2"
      style="position: sticky; left: 0"
      dark
      small
      @click="openAlert"
    >
      Add alert
    </v-btn>
    <AlertDialog ref="alertDialog"/>
    <v-btn
      v-if="$route.name !== 'Alerts'"
      color="primary"
      class="ml-4 elevation-2"
      style="position: sticky; left: 0"
      dark
      small
      :to="{ name: 'Alerts' }"
    >
      All alerts
    </v-btn>

  </v-footer>
</template>

<script>
import {mapGetters} from 'vuex'
import AlertDialog from './AlertDialog.vue'

export default {
  data() {
    return {
    }
  },
  components: {
    AlertDialog
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    trucksLink() {
      return this.permissions['TRUCK_VIEW'] && this.$route.name !== 'trucks'
    },
  },

  methods: {
    openAlert() {
      this.$refs.alertDialog.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-footer {
}
</style>
