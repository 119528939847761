<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="340"
    min-width="340"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <td
        @click="on.click"
        @mouseenter="onMouseEnter($event)"
        @mouseleave="showComments = false"
        @mousedown="showComments = false"
        @contextmenu.prevent="onClick"
        :draggable="permissions.ELD_MOVE_CALL"
        @dragstart="startDrag($event, call)"
        class="call bold"
        :class="{drag: permissions.ELD_MOVE_CALL && !noMove}"
        :title="call.callType.type"
        :id="call._id"
        :style="{
          background: call.callType.color,
          'webkit-user-select': 'none',
          'moz-user-select': 'none'
        }"
      >
        {{call.truck}}
        <span v-if="showCommentsNumber && call.comments.length" class="comments-number">{{call.comments.length}}</span>
        <div class="comments-holder" v-if="showComments && call.comments.length" :style="positionStyle">
          <v-card>
            <Comments :comments="call.comments" :callId="call._id" :showActions="false"/>
          </v-card>
        </div>
      </td>
    </template>
    <v-card>
      <Comments v-if="call.comments.length" @edit="onEditComment" :comments="call.comments" :callId="call._id" :showActions="true"/>
      <form v-if="!disableCommentsInput" @submit.prevent="onSubmitComment(call._id)">
        <mention-input
          @onEnter="handleEnter($event, call._id)"
          ref="comtext"
          :autofocus="true"
          name="comment"
          placeholder="Comment"
          label=""
          :rules="rules"
          counter
          v-model="commentText"/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editCommentId !== null"
            text
            small
            @click="cancelEdit"
          >
            Cancel edit
          </v-btn>
          <v-btn
            v-else
            text
            small
            @click="closeMenu"
          >
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="success"
            small
            :loading="loading"
            :disabled="!permissions.ELD_ADD_COMMENT || commentText === '' || commentText.length > 500"
          >
            {{editCommentId === null ? 'Save' : 'Edit'}}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import callsService from '../../services/CallsService'
import Comments from './../Common/Comments'
import MentionInput from '../Common/MentionInput'
export default {
  name: 'Call',
  props: ['call', 'position', 'shiftId', 'disableCommentsInput', 'noMove', 'noHoverComments', 'showCommentsNumber'],
  components: {
    Comments,
    MentionInput
  },
  data() {
    return {
      menu: false,
      menuDate: false,
      commentText: '',
      rules: [v => v.length <= 500 || 'Max 500 characters'],
      showComments: false,
      positionHorizontal: 'left',
      positionVertical: 'top',
      editCommentId: null
    }
  },
  computed: {
    ...mapGetters('auth', ['isSuperAdmin', 'user', 'permissions']),
    ...mapGetters('common', ['loading', 'userColor']),
    positionStyle () {
      let style = {}
      style[this.positionHorizontal] = '100%'
      style[this.positionVertical] = '0px'
      return style
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    onSubmitComment (callId) {
      if (!this.commentText.length) return

      let comment = {
        text: this.commentText.trim(),
        user_email: this.user.email,
        user_name: this.user.name
      }
      this.$store.dispatch('common/setLoading', true)
      if (this.editCommentId !== null) {
        comment['_id'] = this.editCommentId
        callsService.editComment(callId, comment, this.shiftId).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.editCommentId = null
            this.commentText = ''
            this.setMessage('Comment successfully edited')
          }
        })
      } else {
        callsService.addComment(callId, comment, this.shiftId).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.closeMenu()
            this.setMessage('Comment successfully added')
          }
        })
      }
    },
    closeMenu () {
      this.commentText = ''
      this.editCommentId = null
      this.menu = false
    },
    onClick () {
      this.$emit('click')
    },
    startDrag (evt, call) {
      this.showComments = false
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('dragdata', JSON.stringify({call: call}))
    },
    handleEnter (e, callId) {
      if (e.ctrlKey && this.permissions.ELD_ADD_COMMENT) {
        this.onSubmitComment(callId)
      }
    },
    onMouseEnter (e) {
      if (e.view.innerWidth <= e.clientX + 400) {
        this.positionHorizontal = 'right'
      } else {
        this.positionHorizontal = 'left'
      }

      if (e.view.innerHeight <= e.clientY + 210) {
        this.positionVertical = 'bottom'
      } else {
        this.positionVertical = 'top'
      }
      
      this.showComments = !this.noHoverComments
    },
    onEditComment (comment) {
      this.editCommentId = comment._id
      this.commentText = comment.text
      this.$refs.comtext.$refs.minput.$refs.input.focus()
    },
    cancelEdit () {
      this.editCommentId = null
      this.commentText = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.comments-holder {
  position: absolute;
  z-index: 5;
  width: 340px;
  font-weight: initial;
  text-align: initial;
  cursor: initial;
}
</style>