<template>
  <v-card v-if="shopData" max-width="300px" class="here-map-pin-data"
    :class="{ 'shop-card-blacklisted': isBlacklisted, 'shop-card-regular': !isBlacklisted }"
    :style="{ left: calculatePinPositionX, top: calculatePinPositionY }">
    <div class="pl-4 pt-4 pr-4 pb-4">
      <v-row align="center" class="pl-3 pt-2 pr-3 pb-2">
        <v-rating empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full"
          background-color="grey" :color="isBlacklisted ? '#8A1829' : '#1B2C4B'" length="5" size="18"
          :value=shopData.averageRating half-increments dense readonly>
        </v-rating>
        <p v-if="shopData && shopData.averageRating > 0" class="pl-3 pt-1 mb-0 text-body-2 font-weight-medium">{{
          shopData.averageRating }}</p>
        <v-spacer></v-spacer>
        <v-chip class="font-weight-bold pl-4 pr-4 pt-1 pb-1" :class="isBlacklisted ? 'shop-blacklisted-chip' : shopCategory(shopData.label)"
          small>{{
            isBlacklisted ?
            'BLACKLISTED' : (!shopData.label ? 'BASIC' : shopData.label) }}</v-chip>
      </v-row>
      <v-divider class="mt-4 mb-2"></v-divider>
      <v-row class="mt-0">
        <!-- when text goes over row add ... (truncate example) -->
        <v-card-text class="pl-4 pt-0 pr-3 pb-0 font-weight-bold text-h6 text-truncate"
          :class="{ 'shop-blacklisted-color': isBlacklisted, 'primary--text': !isBlacklisted }">{{ shopData.name
          }}</v-card-text>
        <v-card-text v-if="shopData.franchise" class="pl-4 pr-3 pt-0 font-weight-medium"> {{ shopData.franchise.name
        }}</v-card-text>
        <v-card-text class="pl-4 pr-3 pt-0 pb-2 font-weight-medium text-truncate"> {{ shopData.address.text
        }}</v-card-text>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ShopMapPopUpData',
  data: () => ({
  }),
  props: {
    shopData: {
      type: Object
    }
  },
  computed: {
    calculatePinPositionX() {
      // console.log('position x ShopMapPopUpData: ', this.shopData.pinPopUpXCoord)
      return this.shopData.pinPopUpXCoord + 'px'
    },
    calculatePinPositionY() {
      // console.log('position y ShopMapPopUpData: ', this.shopData.pinPopUpYCoord)
      return this.shopData.pinPopUpYCoord + 'px'
    },
    shopCategory() {
      return (label) => {
        switch (label) {
        case 'GOLD':
          return 'shop-gold-chip'
        case 'PLATINUM':
          return 'shop-platinum-chip'
        case 'SILVER':
          return 'shop-silver-chip'
        default:
          return 'shop-basic-chip'
        }
      }
    },
    isBlacklisted() {
      return this.shopData.status === 'BLACKLISTED' ? true : false
    }
  }
}
</script>
    
<style lang="scss">
@import '@/assets/variables/variables.scss';

.here-map-pin-data {
  position: absolute;
  z-index: 1000;
}

.shop-gold-chip {
  background-color: $gold !important;
  color: white !important;
}

.shop-platinum-chip {
  background-color: $platinum !important;
  color: white !important;
}

.shop-silver-chip {
  background-color: $silver !important;
  color: white !important;
}
.shop-basic-chip {
  background-color: $basic !important;
  color: white !important;
}

.shop-blacklisted-color {
  color: $blacklisted-color !important;
}

.shop-blacklisted-chip {
  background-color: $blacklisted-color !important;
  color: white !important;
}

.shop-card-blacklisted {
  border: 1px solid $blacklisted-color !important;
  opacity: 0.9;
}

.shop-card-regular {
  border: 1px solid #3f51b5 !important;
}
</style>