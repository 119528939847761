import Api from '@/services/Api'

export default {
  getClaimTypes () {
    return Api().get('/claims/types')
  },
  addClaim (data) {
    return Api().post('/claims', data)
  },
  editClaim (data) {
    return Api().put('/claims', data)
  },
  addComment (claimId, comment) {
    return Api().post('/claims/add-comment', {claimId, comment})
  },
  editComment (claimId, comment) {
    return Api().post('/claims/edit-comment', {claimId, comment})
  },
  deleteComment (claimId, commentId) {
    return Api().post('/claims/delete-comment', {claimId, commentId})
  },
  getClaims (filters) {
    return Api().get('/claims', {params: filters})
  },
  deleteClaim (id) {
    return Api().delete(`/claims/${id}`)
  }
}