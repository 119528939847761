<template>
  <div class="RecruitmentView">
    <div style="position: relative">
      <v-tabs v-model="tab" v-if="isAdmin" @change="handleTabChanged">
        <v-tab>Employees</v-tab>
        <v-tab>Recruiters</v-tab>
      </v-tabs>

      <v-autocomplete
          v-model="selectedRecruiter"
          v-if="tab === 1"
          :loading="loading"
          :items="items"
          :search-input.sync="search"
          item-text="name"
          item-value="_id"
          cache-items
          class="mx-4"
          flat
          hide-no-data
          hide-details
          label="Search Recruiter"
          dense
          filled
          clearable
      ></v-autocomplete>
    </div>

    <employees-table
      v-if="isRecruiter || (isAdmin && tab === 0)"
      :is-admin="isAdmin"
      :is-recruiter="isRecruiter"
      :all-recruiters="allRecruiters"
      :recruiter-id="user._id"
      :onlyView="onlyView"
    />

    <template v-else-if="isAdmin && tab === 1 && selectedRecruiter !== null">
      <employees-table
        :key="`recruiter-table-${recruiters._id}-${recruiters.employees && recruiters.employees.length}`"
        :recruiters="recruiters"
        :all-recruiters="allRecruiters"
        :recruiter-id="recruiters._id"
        :recruiter="recruiters"
        class="mb-2"
        is-admin
        admin-only-table
        @employee-moved="handleEmployeeMoved"
        :onlyView="onlyView"
        :default-statuses="['employed', 'unemployed', 'blocked']"
      />
    </template>
    <div v-if="!disableLoadMore && selectedRecruiter" style="padding: 20px; display: flex; justify-content: center">
      <v-btn @click="handleLoadMoreEmployees" :disabled="loading">Load more employees</v-btn>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import EmployeesTable from './EmployeesTable.vue'
import service from '../../services/EmployeesService'

const ADMINS = ['SUPERADMIN', 'RECRUITER_ADMIN', 'RECRUITER_VIEW']

const PER_PAGE = 50

export default {
  name: 'RecruitmentView',

  components: { EmployeesTable },

  data() {
    return {
      recruiters: [],
      allRecruiters: [],
      selectedRecruiter: null,
      items: [],
      page: 1,
      loading: false,
      tab: null,
      search: null,
      disableLoadMore: false,
      defaultStatuses: ['employed', 'unemployed']
    }
  },
  watch: {
    search (val) {
      if (val && val !== this.selectedRecruiter && val.length > 2) {
        this.getRecruitersByName(val)
      }
    },
    selectedRecruiter(val) {
      if (val !== null) this.getRecruiters()
      this.disableLoadMore = false
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    isRecruiter() {
      if (!this.user) return false
      if (!Array.isArray(this.user.roles)) return false
      return this.user.roles.some((r) => 'RECRUITING' === r.role_name)
    },

    isAdmin() {
      if (!this.user) return false
      if (!Array.isArray(this.user.roles)) return false
      return this.user.roles.some((r) => ADMINS.includes(r.role_name))
    },

    onlyView() {
      if (!this.user) return false
      if (!Array.isArray(this.user.roles)) return false
      return this.user.roles.some((r) => r.role_name === 'RECRUITER_VIEW')
    },
  },

  async created() {
    if (this.isAdmin) await this.getAllRecruiters()
    if (this.isRecruiter)
      this.allRecruiters = [{ name: this.user.name, id: this.user._id }]
  },

  methods: {
    handleTabChanged(v) {
      //if (v === 1) this.getRecruiters()
      if (v === 0) {
        this.recruiters = []
        this.page = 1
        this.selectedRecruiter = null
      }
    },

    async handleEmployeeMoved() {
      try {
        this.recruiters = []
      } catch (error) {
        console.error('RecruitmentView handleEmployeeMoved()', error)
      }
    },

    async handleLoadMoreEmployees() {
      this.page++
      try {
        this.loading = true
        const { data } = await service.groupedByRecruiters({
          page: this.page,
          itemsPerPage: PER_PAGE,
          statuses: this.tab === 1 ? [...this.defaultStatuses, 'blocked', 'retired'] : this.defaultStatuses,
          recruiterId: this.selectedRecruiter,
        })
        console.log(data)
        if (data) {
          this.recruiters.employees = [...this.recruiters.employees, ...data.employees]
          this.loading = false
          console.log('qweqwe', data.length)
          if (data.employees.length < PER_PAGE) this.disableLoadMore = true
        }
      } catch (error) {
        console.error('RecruitmentView getRecruiters()', error)
        this.loading = false
        return []
      }
    },

    async getRecruiters() {
      try {
        this.loading = true
        const { data } = await service.groupedByRecruiters({
          page: this.page,
          itemsPerPage: PER_PAGE,
          statuses: this.tab === 1 ? [...this.defaultStatuses, 'blocked', 'retired'] : this.defaultStatuses,
          recruiterId: this.selectedRecruiter,
        })
        if (data) {
          this.recruiters = data
          this.loading = false
          if (data.employees.length < PER_PAGE) this.disableLoadMore = true
        }
      } catch (error) {
        console.error('RecruitmentView getRecruiters()', error)
        this.loading = false
        return []
      }
    },
    async getRecruitersByName() {
      try {
        this.loading = true
        const { data } = await service.getRecruiters({
          name: this.search,
          autocomplete: true,
        })
        if (Array.isArray(data.docs)) {
          this.items = data.docs
          this.loading = false
        }
      } catch (error) {
        console.error('RecruitmentView getRecruitersByName()', error)
        this.loading = false
        return []
      }
    },

    async getAllRecruiters() {
      try {
        const { data } = await service.getRecruiters({})
        if (Array.isArray(data.docs))
          this.allRecruiters = data.docs.map((r) => ({ name: r.name, id: r._id }))
      } catch (error) {
        console.error('RecruitmentView getRecruiters()', error)
      }
    },
  },
}
</script>
