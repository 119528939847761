<template>
    <div class="roles-wrapper">
      <v-card>
        <v-card-title>
          {{ $t('other.roles') }}
        </v-card-title>
        <v-data-table
          height="650"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="roles"
          class="elevation-1"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': [30, 50]
          }"
        >
          <template v-slot:item.actions="{item}">
            <td class="justify-center layout px-0">
              <v-icon
                small
                class="mr-2"
                @click="editRole(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                :disabled="disableDelete(item.role_name)"
                @click="deleteRole(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
        <v-fab-transition>
          <v-btn
            color="pink"
            dark
            absolute
            top
            right
            fab
            :disabled="loading"
            @click="openForm"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card>
      <v-dialog v-model="formDialog" width="840px" persistent>
          <v-card>
            <form @submit.prevent="onAddRole" data-vv-scope="role">
              <v-card-title
                class="lighten-4 py-4 title"
              >
                {{form._id ? 'Edit' : 'Add'}} role
              </v-card-title>
              <v-container grid-list-sm class="pa-4">
                <v-layout row wrap>
                  <v-flex xs12 align-center justify-space-between>
                      <v-text-field
                        :disabled="form._id !== null"
                        ref="name"
                        v-model="form.name"
                        v-validate="'required'"
                        :error="errors.has('role.name')"
                        :error-messages="errors.first('role.name')"
                        name="name"
                        id="name"
                        :autofocus="true"
                        placeholder="Name"
                      ></v-text-field>
                  </v-flex>
                  <v-flex xs12 align-center justify-space-between>
                      <v-text-field
                        ref="path"
                        v-model="form.path"
                        name="path"
                        id="path"
                        placeholder="Login path"
                      ></v-text-field>
                  </v-flex>
                  <v-flex xs12 align-center justify-space-between>
                      <v-text-field
                        :readonly="true"
                        ref="id"
                        v-model="form.role_id"
                        name="id"
                        id="id"
                        placeholder="Role id"
                      ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <permissions-drag v-if="formDialog" :permissions="form.permissions" @change="onChangePermissions"/>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
                <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
              </v-card-actions>
            </form>
          </v-card>
      </v-dialog>
    </div>
  </template>
  
<script>
import {mapGetters, mapActions} from 'vuex'
import userService from '../../services/UsersService'
import PermissionsDrag from './PermissionsDrag.vue'
  
  
export default {
  name: 'Roles',
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['role_id'],
        sortDesc: [false],
        itemsPerPage: 30
      },
      totalItems: 0,
      headers: [
        {text: 'Role id', value: 'role_id'},
        {text: 'Name', value: 'name'},
        {text: 'Path', value: 'path'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        name: '',
        path: '',
        role_id: '',
        permissions: []
      },
      formDialog: false,
      roles: []
    }
  },
  components: {
    PermissionsDrag
  },
  computed: {
    ...mapGetters('common', ['loading']),
    getMaxRoleId() {
      return Math.max( ...this.roles.map( role => role.role_id ) ) + 1
    }
  },
  watch: {
    options: {
      handler () {
        this.getRoles()
      },
      deep: true,
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    getRoles() {
      this.$store.dispatch('common/setLoading', true)
      userService.getRoles(this.options).then(res => {
        this.$store.dispatch('common/setLoading', false)
        this.roles = res.data.docs
        this.totalItems = res.data.total
      })
    },
    onAddRole () {
      this.$validator.validateAll('role').then((result) => {
        if (result) {
          this.$store.dispatch('common/setLoading', true)
          if (this.form._id) {
            userService.editRole(this.form).then(res => {
              if (res.status === 200) {
                this.$store.dispatch('common/setLoading', false)
                let roleIndex = this.roles.findIndex(role => role._id === res.data._id)
                if (roleIndex !== -1) {
                  this.$set(this.roles, roleIndex, res.data)
                }
                this.closeForm()
                this.setMessage(`${res.data.name} role successfully edited`)
              }
            })
          } else {
            userService.addRole(this.form).then(res => {
              if (res.status === 200) {
                this.getRoles()
                this.closeForm()
                this.setMessage(`${res.data.name} role successfully added`)
              }
            }).catch(err => {
              if (err.data.code === 11000 && err.data.keyValue.role_name) {
                this.errors.add({scope: 'role', field: 'name', msg: this.$t('ROLE_UNIQUE_ERROR')})
              }
            })
          }
        }
      })
    },
    editRole (role) {
      this.form = {...role}
      this.form.permissions = role.permissions ? Object.keys(role.permissions) : []
      this.formDialog = true
    },
    deleteRole (role) {
      let confVal = confirm(`Are you sure you want to delete role: ${role.name}?`)
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        userService.deleteRole(role._id).then(res => {
          if (res.status === 200) {
            this.$store.dispatch('common/setLoading', false)
            if (res.data.status !== 'ERROR') {
              this.getRoles()
              this.setMessage(`${role.name} role successfully deleted`)
            } else {
              this.setMessage(this.$t(res.data.message))
            }
          }
        })
      }
    },
    openForm() {
      this.form.role_id = this.getMaxRoleId
      this.formDialog = true
    },
    closeForm () {
      this.formDialog = false
      this.form = {
        _id: null,
        name: '',
        path: '',
        role_id: '',
        permissions: []
      }
    },
    disableDelete(roleName) {
      return ['SUPERADMIN', 'ADMIN', 'DISPATCHER'].indexOf(roleName) !== -1
    },
    onChangePermissions(permissions) {
      this.form.permissions = permissions
    }
  },
  mounted() {
    this.getRoles()
  }
}
</script>
  
  <style lang="scss">
    .roles-wrapper {
      width: 100%;
      margin-top: 20px;
    }
  </style>