<template>
  <v-lazy
    min-height="262"
    transition="scale-transition"  
  >
    <div>
      <v-card min-height="262" min-width="390" max-width="390" @mouseenter="handleShopCardHover(shop.id)" @mouseleave="handleShopCardNotHovered" class="shop-card pl-0 pb-0 mb-2" :class="{ 'shop-card-blacklisted': isBlacklisted, 'shop-card-regular': !isBlacklisted }">
        <div class="pl-6 pt-6 pr-6 pb-6">
          <v-row align="center" class="pl-3 pt-2 pr-3 pb-2">
            <v-rating empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full"
              background-color="grey" :color="isBlacklisted ? '#8A1829' : '#1B2C4B'" length="5" size="26"
              :value=shop.averageRating half-increments dense readonly>
            </v-rating>
            <p v-if="shop && shop.averageRating > 0 && !isBlacklisted" class="pl-2 mb-0 font-weight-bold body-4 dark-blue">{{
              shop.averageRating }}
            </p>
            <v-spacer></v-spacer>
            <v-chip class="font-weight-bold pl-8 pt-4 pb-4 pr-8 ml-4"
              :class="isBlacklisted ? 'shop-blacklisted-chip' : shopCategory(shop.label)">{{
                isBlacklisted ?
                'BLACKLISTED' : (shop.label === '' || shop.label === null ? 'BASIC' : shop.label) }}</v-chip>
          </v-row>
          <v-divider class="mt-4 mb-4"></v-divider>
          <v-row class="mt-0 shop-card-data" @click="redirectToShopDetails(shop)">
            <v-card-text v-if="shop && shop.name" class="pl-3 pt-0 pr-3 pb-0 font-weight-bold text-truncate text-h5"
              :class="{ 'shop-blacklisted-color': isBlacklisted, 'primary--text': !isBlacklisted, 'pb-8': !shop.franchise }">{{
                shop.name
              }}</v-card-text>
  
            <v-card-text v-if="shop && shop.franchise"
              class="pl-4 pr-3 pt-0 pb-2 font-weight-medium text-body-1 text-truncate">
              {{ shop.franchise.name }}
            </v-card-text>
  
            <v-card-text v-if="shop && shop.address && shop.address.text"
              class="pl-4 pr-4 pt-0 pb-0 font-weight-medium text-truncate">{{ shop.address.text }}</v-card-text>
            <div v-for="(type, index) in shop.type" :key="index" class="pl-0 pt-0 pr-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-if="type === 'TRUCK'" class="ml-4" small v-on="on">mdi-truck</v-icon>
                  <v-icon v-if="type === 'TRAILER'" class="ml-4" small v-on="on">mdi-truck-trailer</v-icon>
                  <v-icon v-if="type === 'TOWING'" class="ml-4" small v-on="on">mdi-tow-truck</v-icon>
                  <v-icon v-if="type === 'MOBILE_SHOP'" class="ml-4" small v-on="on">mdi-account-wrench</v-icon>
                  <v-icon v-if="type === 'SECURED_PARKING'" class="ml-4" small v-on="on">mdi-parking</v-icon>
                  <v-icon v-if="type === 'DETAILING'" class="ml-4" small v-on="on">mdi-car-wash</v-icon>
                </template>
                <span>
                  {{ getTooltipText(type) }}
                </span>
              </v-tooltip>
          </div>
          </v-row>
          <v-divider class="mt-4"></v-divider>
          <v-row class="pl-3 pt-4 pr-3  mt-0">
            <v-btn v-if="permissions.ADD_SHOP_REVIEW" :class="{ 'shop-blacklisted-color': isBlacklisted, 'primary': !isBlacklisted }" small
              @click="openReviewDialog">Write
              Review</v-btn>
            <!-- <v-btn class="ml-2 font-weight-medium" color="grey darken-3" text small
              @click="redirectToShopDetails(shop)">Read
              all</v-btn> -->
            <v-spacer></v-spacer>
            <v-btn icon @click="openContactDialog"><v-icon>mdi-phone</v-icon></v-btn>
          </v-row>
        </div>
      </v-card>
      <!-- Write Review Dialog -->
      <v-dialog v-model="reviewDialog" max-width="480px" @click:outside="handleCloseReviewDialog">
        <v-card>
          <div class="pl-6 pt-6 pr-6 pb-6">
            <v-row>
              <v-card-title class="font-weight-bold">Write Review</v-card-title>
              <v-spacer></v-spacer>
              <v-btn class="mt-3 mr-3" icon @click="handleCloseReviewDialog"><v-icon>mdi-close</v-icon></v-btn>
            </v-row>
            <v-card-text class="pl-2 font-weight-bold text-body-1 dark-blue">Shop & Franchise</v-card-text>
            <v-card-title class="pl-2 pt-0 pb-0 font-weight-bold primary--text">{{ shop.name }}</v-card-title>
            <v-card-text v-if="shop.franchise" class="pl-3 font-weight-bold dark-blue">{{
              shop.franchise.name
            }}</v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pl-2 font-weight-bold text-body-1 dark-blue">Rate Shop *</v-card-text>
            <form @submit.prevent="submitReviewForm" data-vv-scope="addReviewShopCard">
              <v-rating v-model="reviewForm.rating" v-validate.lazy="{ required: true }" data-vv-name="rating"
                :error="errors.has('addReviewShopCard.rating')" empty-icon="mdi-star-outline" full-icon="mdi-star"
                half-icon="mdi-star-half-full" class="ml-1" :background-color="checkRatingError ? 'error' : 'grey'" color="#1B2C4B"
                length="5" size="26" half-increments hide-details dense>
              </v-rating>
              <div v-if="reviewForm.rating == null && reviewFormSubmited" class="pt-2 red--text">{{
                errors.first('addReviewShopCard.rating') }}
              </div>
              <v-divider class="mt-4"></v-divider>
              <v-card-text class="pl-2 pb-0 font-weight-bold text-body-1 dark-blue">Review Visibility</v-card-text>
              <v-switch
                v-model="reviewForm.isPublic"
                class="pl-4"
                color="primary"
                :label="reviewForm.isPublic ? 'Public' : 'Private'"
                hide-details>
              </v-switch>
              <v-card-text class="pb-0 font-weight-medium dark-blue"><v-icon>{{reviewForm.isPublic ? 'mdi-earth' : 'mdi-eye-off'}} </v-icon> {{  reviewForm.isPublic ? 'If you choose to post this review, it will be publicly visible to all users' : 'Your review is private to your company, ensuring privacy and internal communication' }}</v-card-text>
              <v-divider class="mt-4"></v-divider>
              <v-card-text class="pl-2 font-weight-bold text-body-1 dark-blue">Write Review *</v-card-text>
              <v-textarea v-validate="{ required: true }" data-vv-name="description"
                :error="errors.has('addReviewShopCard.description')" v-model="reviewForm.text" label="Write Review"
                rows="6" row-height="15" outlined hide-details></v-textarea>
              <v-btn :disabled="!permissions.ADD_SHOP_REVIEW" class="mt-4" block color="primary" type="submit">Save</v-btn>
            </form>
          </div>
        </v-card>
      </v-dialog>
      <!-- Contact Phone Dialog -->
      <v-dialog v-model="contactDialog" min-width="300px" max-width="400px" @click:outside="handleCloseContactDialog">
        <v-card>
          <div class="pl-8 pt-8 pr-8 pb-8">
            <v-card-title class="pt-0 justify-center font-weight-bold text-h6">Contact</v-card-title>
            <v-divider class="mb-5"></v-divider>
            <div class="pl-4 pt-4 pr-4 pb-4">
              <v-row v-for="(  contact, index  ) in   shop.contacts  " :key="index" class="pb-2 flex-nowrap">
                <v-icon color="black">mdi-phone</v-icon>
                <v-card-text class="pb-0 pt-0 font-weight-bold primary--text">{{ contact.name }} {{ contact.phoneNumber
                }}</v-card-text>
              </v-row>
              <v-row class="pb-2 flex-nowrap">
                <v-icon color="black">mdi-email</v-icon>
                <v-card-text class="pt-0 pb-0 font-weight-bold primary--text"><a :href="'mailto:' + shop.email"
                    class="text-decoration-none">Send email</a></v-card-text>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <!-- Successfully posted review dialog -->
      <v-dialog v-model="reviewPosted" max-width="420px" @click:outside="handleCloseReviewPostedDialog">
        <v-card>
          <div align="center" class="pl-8 pt-8 pr-8 pb-8">
            <v-card-text class="pt-0 font-weight-bold text-h6">Your Review</v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pt-3 pb-7 text-body-2 font-weight-medium">Successfully posted</v-card-text>
            <v-btn color="primary" block @click="handleCloseReviewPostedDialog">Ok</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </v-lazy>
</template>

<script>

import MsShopService from '../../services/MsShopService'
import EventBus from '@/eventbus/EventBus.js'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ShopCard',
  components: {
  },
  data: () => ({
    reviewForm: {
      rating: null,
      text: '',
      isPublic: true
    },
    reviewDialog: false,
    contactDialog: false,
    reviewPosted: false,
    reviewFormSubmited: false,
    lazyActive: false
  }),
  props: {
    shop: {
      type: Object
    }
  },
  watch: {
    reviewDialog(reviewDialog) {
      if (reviewDialog) {
        this.errors.clear()

      }
    }
  },
  mounted() {
    this.errors.clear()
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    shopCategory() {
      return (label) => {
        switch (label) {
        case 'GOLD':
          return 'shop-gold-chip'
        case 'PLATINUM':
          return 'shop-platinum-chip'
        case 'SILVER':
          return 'shop-silver-chip'
        case null:
        case '':
          return 'shop-basic-chip'
        default:
          return ''
        }
      }
    },
    checkRatingError() {
      return this.reviewForm.rating == null && this.reviewFormSubmited ? true : false
    },
    isBlacklisted() {
      return this.shop.details && this.shop.details[0] && this.shop.details[0].status === 'BLACKLISTED'
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    async submitReviewForm() {
      this.reviewFormSubmited = true

      try {
        const isValid = await this.$validator.validateAll('addReviewShopCard')
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('submit_form')
        if (isValid && token) {
          await MsShopService.writeReview({
            text: this.reviewForm.text,
            rating: this.reviewForm.rating,
            isPublic: this.reviewForm.isPublic,
            shopId: this.shop.id,
            recaptchaToken: token,
            recaptchaV2: false
          })
          console.log('Review submitted successfully')
          this.reviewForm = {
            text: '',
            rating: null
          }
          this.reviewDialog = false
          this.reviewPosted = true
          this.reviewFormSubmited = false
        } else {
          console.warn('Form is not valid.')
        }

      } catch (error) {
        if (error.response && error.response.data.message === 'LOW_RECAPTCHA_SCORE') {
          console.log('LOW_RECAPTCHA_SCORE', error)
          this.setMessage('Form validation failed, low recaptcha score.')
        }
        console.error('Error submitting review:', error)
      }
    },

    handleShopCardHover(id) {
      EventBus.$emit('shopCardHovered', id)
    },
    handleShopCardNotHovered() {
      EventBus.$emit('shopCardNotHovered')
    },
    handleCloseReviewDialog() {
      this.reviewDialog = false
      this.reviewForm.rating = null
      this.reviewForm.text = ''
    },
    redirectToShopDetails(shop) {
      this.$router.push({ name: 'ShopDetails', params: { shopId: shop.id } })
    },
    openReviewDialog() {
      this.reviewForm.isPublic = true
      this.reviewDialog = true
      this.reviewFormSubmited = false
    },
    handleCloseContactDialog() {
      this.contactDialog = false
    },
    openContactDialog() {
      this.contactDialog = true
    },
    handleCloseReviewPostedDialog() {
      this.reviewPosted = false
    },
    getTooltipText(type) {
      switch (type) {
      case 'TRUCK':
        return 'Truck'
      case 'TRAILER':
        return 'Truck Trailer'
      case 'TOWING':
        return 'Tow Truck'
      case 'MOBILE_SHOP':
        return 'Mobile Shop'
      case 'SECURED_PARKING':
        return 'Secured Parking'
      case 'DETAILING':
        return 'Detailing'
        // New types
      case 'FRAME_ALIGNMENT_SUSPENSION':
        return 'Frame Alignment & Suspension'
      case 'GLASS_REPAIR':
        return 'Glass Repair'
      case 'TRAILER_REFRIGERATION_REPAIR':
        return 'Trailer Refrigeration Repair'
      case 'WELDING':
        return 'Welding'
      case 'LOCK_OUT_SERVICE':
        return 'Lock Out Service'
      case 'MOBILE_FUELING':
        return 'Mobile Fueling'
      default:
        return ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables/variables.scss';

.shop-card {
  direction: ltr;
}

.shop-gold-chip {
  background-color: $gold !important;
  color: white !important;
}

.shop-platinum-chip {
  background-color: $platinum !important;
  color: white !important;
}

.shop-silver-chip {
  background-color: $silver !important;
  color: white !important;
}

.shop-blacklisted-chip {
  background-color: $blacklisted-color !important;
  color: white !important;
}

.shop-basic-chip{
  background-color: $basic !important;
  color: white !important;
}

.shop-blacklisted-color {
  color: $blacklisted-color !important;
}

.shop-card-blacklisted-btn {
  color: $blacklisted-color !important;

}

.shop-card-blacklisted {
  border: 1px solid $blacklisted-color;
  opacity: 0.7;
}

.shop-card-regular {
  border: 1px solid primary !important;
}

.dark-blue {
  color: $dark-blue !important;
}

.shop-card-data {
  cursor: pointer;
}
</style>
