import log from '../../services/LogsService'

const state = {
  logs: []
}

const getters = {
  logs (state) {
    return state.logs
  }
}

const actions = {
  getLogs ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return log.getLogs(payload).then(res => {
      commit('setLogs', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setLogs (state, logs) {
    state.logs = logs
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
