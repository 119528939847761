<template>
  <v-tooltip :right="right" class="common-note" :disabled="disableTooltip">
    <template v-slot:activator="{ on, attrs }">

      <div class="note-trigger center" @click="showNoteDialog">
        {{ text }}
        <span class="note-holder" :class="{disabled: disableTooltip}" @contextmenu.prevent="emitRightClick">
          <v-icon v-if="note && note.length" class="common-note-icon"> mdi-comment-text-outline </v-icon>
        </span>
      </div>

      <v-dialog v-if="canView" v-model="noteDialog" @click:outside="handleCloseDialog" max-width="540px">
        <v-card>
          <v-container>
            <p style="white-space: pre-wrap;">{{ note ? note : 'No note available' }}</p>
          </v-container>
          <v-card-actions>
            <v-btn text color="error" @click="handleCloseDialog">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Edit dialog -->
      <v-dialog v-if="canEdit" v-model="editDialog" @click:outside="handleCloseEditDialog" max-width="540px">
        <v-card>
          <v-form @submit.prevent="onSubmit">
            <v-container>
              <v-textarea
                v-model="noteValue"
                :label="noteLabel"
                solo
                no-resize
                clearable
                clear-icon="mdi-close-circle"
              />
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="error" @click="handleCloseEditDialog">
                Close
              </v-btn>
              <v-btn type="submit" :loading="loading" color="primary">
                Set note
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </template>
    <span class="note-tooltip" style="white-space: pre-wrap; max-width: 360px;">{{ note ? note : 'Right click to add a note.' }}</span>
  </v-tooltip>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'NoteColumn',
  props: {
    right: {
      type: Boolean,
      default: true
    },
    note: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    noteLabel: {
      type: String,
      default: 'Note'
    },
    disable: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noteDialog: false,
      editDialog: false,
      noteValue: this.note
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    disableTooltip() {
      if (this.disable && (!this.note || !this.note.length)) return true
      else return false
    },
  },
  methods: {
    showNoteDialog() {
      this.noteDialog = true
    },
    emitRightClick() {
      if (!this.disable) {
        this.$emit('right-click')
        this.editDialog = true
      }
    },
    handleCloseDialog() {
      this.noteDialog = false
      // this.$emit('close-dialog')
    },
    handleCloseEditDialog() {
      this.editDialog = false
      this.$emit('close-dialog')
    },
    onSubmit() {
      this.editDialog = false
      this.$emit('dialog-submit', this.noteValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.note-trigger {
  width: 100%;
  height: 100%;
  display: flex;
  &.center {
    justify-content: center;
    align-items: center;
  }
  

  .note-holder {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: context-menu;
    left: 0;
    top: 0;

    &.disabled {
      cursor: default;
    }
    

    .common-note-icon {
      position: absolute;
      right: 0px;
      top: 1px;
      font-size: 12px;
    }
  }
}
</style>