import Api from '@/services/Api'

export default {
  getShiftsAndUsers (day) {
    return Api().get('/shifts', {params: {day}})
  },
  getAllShifts () {
    return Api().get('/shifts/all')
  },
  addShift (shift) {
    return Api().post('/shifts', shift)
  },
  editShift (shift) {
    return Api().put('/shifts', shift)
  },
  deleteShift (id) {
    return Api().delete(`/shifts/${id}`)
  },
  addUser (payload) {
    return Api().put('/shifts/add-user', payload)
  },
  removeUser (payload) {
    return Api().put('/shifts/remove-user', payload)
  },
  removeAllUsers (id) {
    return Api().delete(`/shifts/remove-all-users/${id}`)
  }
}
