
<template>
  <v-card width="600" height="700">
    <div class="map-info">{{this.origin.name}} - {{ this.destination.name }} ({{durationInSeconds | secToHours}})</div>
    <div id="mapContainer" style="height:100%;width:100%" ref="hereMap"></div>
  </v-card>
</template>

<script>
export default {
  name: 'HereMap',
  props: {
    origin: Object,
    destination: Object
  },
  data() {
    return {
      platform: null,
      map: null,
      apikey: 'fH0cdveRThTdEqkw86T69TQ2ZylMxa1Pb6eUFyZt2zU',
      durationInSeconds: 0
      // You can get the API KEY from developer.here.com
    }
  },
  async mounted() {
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-core.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-service.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-mapevents.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-ui.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-clustering.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-data.js')
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: this.apikey
    })
    this.platform = platform
    this.initializeHereMap()
    this.calculateRoute(this.origin, this.destination)
  },
  methods: {
    loadScript(src) {
      return new Promise(function(resolve, reject) {
        let script = document.createElement('script')
        script.src = src
        script.onload = () => resolve(script)
        script.onerror = () => reject(new Error(`Script load error for ${src}`))
        document.head.append(script)
      })
    },
    initializeHereMap() { // rendering map

      const mapContainer = this.$refs.hereMap
      const H = window.H
      // Obtain the default map types from the platform object
      let maptypes = this.platform.createDefaultLayers()

      // Instantiate (and display) a map object:
      let map = new H.Map(mapContainer, maptypes.vector.normal.map)

      addEventListener('resize', () => map.getViewPort().resize())

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map))

      // add UI
      H.ui.UI.createDefault(map, maptypes)
      this.map = map
      // End rendering the initial map
    },
    calculateRoute(from, to) {
      const router = this.platform.getRoutingService(null, 8)
      var routingParameters = {
        transportMode: 'truck',
        origin: `${from.lat},${from.lng}`,
        destination: `${to.lat},${to.lng}`,
        return: 'polyline,summary'
      }

      router.calculateRoute(routingParameters, 
        result => {
          if (result.routes.length) {
            this.durationInSeconds = result.routes[0].sections.reduce((total, section) => total + section.summary.duration, 0)
            this.addRouteToMap(result.routes[0])
          }
        },
        error => {
          console.error(error)
        }
      )
    },
    addRouteToMap(route) {
      const H = window.H
      const lineString = new H.geo.LineString()

      route.sections.forEach((section) => {
        // decode LineString from the flexible polyline
        let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline)

        // add the points to the LineString:
        linestring.eachLatLngAlt((lat, lng) => {
          lineString.pushPoint({lat, lng})
        })
      })

      // Create a polyline to display the route:
      const routeLine = new H.map.Polyline(lineString, {
        style: { strokeColor: '#4169E1', lineWidth: 4 },
        lineTailCap: 'arrow-tail',
        lineHeadCap: 'arrow-head'
      })

      // Create a patterned polyline:
      let routeArrows = new H.map.Polyline(lineString, {
        style: {
          lineWidth: 10,
          fillColor: 'white',
          strokeColor: 'rgba(255, 255, 255, 1)',
          lineDash: [0, 2],
          lineTailCap: 'arrow-tail',
          lineHeadCap: 'arrow-head' }
      })

      // Create an SVG string with custom color
      let greenSvgMarkup = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
  <title>map-marker</title>
  <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" fill="green" />
</svg>`
      let redSvgMarkup = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
  <title>map-marker</title>
  <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" fill="red" />
</svg>`

      // Create an icon, an object holding the SVG marker
      const greenMarker = new H.map.Icon(greenSvgMarkup)
      const redMarker = new H.map.Icon(redSvgMarkup)

      // Create a marker for the start point:
      let startMarker = new H.map.Marker({
        lat: this.origin.lat,
        lng: this.origin.lng
      }, {icon: greenMarker})

      // Create a marker for the end point:
      let endMarker = new H.map.Marker({
        lat: this.destination.lat,
        lng: this.destination.lng
      }, {icon: redMarker})

      // Add the route polyline and the two markers to the map:
      this.map.addObjects([routeLine, startMarker, endMarker, routeArrows])

      // Set the map's viewport to make the whole route visible:
      let bbox = routeLine.getBoundingBox()
      this.map.getViewPort().resize() // Make sure the viewport is up to date
      this.map.getViewModel().setLookAtData({
        bounds: bbox
      })
    }
  }
}
</script>

<style lang="scss">
.map-info {
  position: absolute;
  background: #fff;
  top: 2px;
  z-index: 10000000;
  left: 2px;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
</style>