import Api from '@/services/Api'

export default {
  getTrailerTypes(pagination) {
    return Api().get('/trailers/types', {
      params: {
        page: pagination.page,
        perPage: pagination.itemsPerPage,
        sortby: pagination.sortBy,
        order: pagination.descending ? 1 : -1,
      },
    })
  },
  addTrailerType(trailerType) {
    return Api().post('/trailers/types', trailerType)
  },
  editTrailerType(trailerType) {
    return Api().put('/trailers/types', trailerType)
  },
  deleteTrailerType(id) {
    return Api().delete(`/trailers/types/${id}`)
  },
  getAllTrailerTypes() {
    return Api().get('/trailers/types/all')
  },
}
