import Api from '@/services/Api'

export default {
  getOwners(data) {
    return Api().get('/owners', {
      params: {
        page: data.page,
        perPage: data.itemsPerPage,
        sortby: data.sortBy,
        search: data.search,
        order: data.sortDesc ? -1 : 1,
        is_company: data.is_company,
      },
    })
  },
  addOwner(owner) {
    return Api().post('/owners', owner)
  },
  editOwner(owner) {
    return Api().put('/owners', owner)
  },
  deleteOwner(id) {
    return Api().delete(`/owners/${id}`)
  },
  getAllOwners() {
    return Api().get('/owners/all')
  },
  getOwnersAutocomplete(query){
    return Api().get(`/owners/autocomplete/${query}`)
  },
  //Trailers
  getTrailerChanges(id, type) {
    return Api().get(`/trailers/changes/${id}/${type}`)
  },
  getTrailerFieldChanges(id, type) {
    return Api().get(`/trailers/field-changes/${id}/${type}`)
  },
  addNotToHistory(id, note) {
    return Api().post('/trailers/changes/add-note', { id: id, note: note })
  },
  deleteHistory(id) {
    return Api().post('/trailers/changes/delete', { id: id })
  },
  editHistory(data) {
    return Api().put('/trailers/changes/edit', {
      _id: data._id,
      subject: data.subject,
      subject_repower: data.subject_repower,
      company: data.company,
      state: data.state,
      date_from: data.date_from,
      date_to: data.date_to,
      note: data.note,
      driver_id: data.driver_id,
    })
  },
  getInactiveDriverHistory(params) {
    return Api().get('/trailers/inactive-drivers', { params })
  },
  addHistory(data) {
    return Api().post('/trailers/changes/add', {
      id: data.id,
      type: data.type,
      name: data.name,
      subject: data.subject,
      subject_repower: data.subject_repower,
      company: data.company,
      state: data.state,
      date_from: data.date_from,
      date_to: data.date_to,
      date_from_repower: data.date_from_repower,
      date_to_repower: data.date_to_repower,
      note: data.note,
      driver_id: data.driver_id,
    })
  },
  getTrailers(data) {
    return Api().get('/trailers', {
      params: {
        page: data.page,
        perPage: data.itemsPerPage,
        sortby: data.sortBy,
        order: data.order,
        search: data.search,
        filter: data.filter,
        company: data.company,
        companyFrom: data.companyFrom,
        companyTo: data.companyTo,
        dealership: data.dealership,
        driver_name: data.driver_name,
        yard: data.yard,
        owner: data.owner,
      },
    })
  },
  addTrailer(trailer) {
    return Api().post('/trailers', trailer)
  },
  editTrailer(trailer) {
    return Api().put('/trailers', trailer)
  },
  deleteTrailer(id) {
    return Api().delete(`/trailers/${id}`)
  },
  searchTrailers(search) {
    return Api().get(`/trailers/all?search=${search}`)
  },
  getTrailerRepairs(id) {
    return Api().get(`/trailers/repairs/${id}`)
  },
  addTrailerRepair(repair) {
    return Api().post('/trailers/repairs', repair)
  },
  editTrailerRepair(repair) {
    return Api().put('/trailers/repairs', repair)
  },
  deleteTrailerRepair(id) {
    return Api().delete(`/trailers/repairs/${id}`)
  },
  addAvailableTrailer(trailer) {
    return Api().post('/trailers/available-trailers', trailer)
  },

  getAvailableTrailers(params) {
    return Api().get('/trailers/available', { params })
  },
  getAvailableHistory(params) {
    return Api().get('/trailers/available/history', { params })
  },
  getAvailableStats() {
    return Api().get('/trailers/available/stats')
  },
  editAvailableTrailer(id, body) {
    return Api().put(`/trailers/available/${id}`, body)
  },
  toggleType(id) {
    return Api().put(`/trailers/available/toggle-type/${id}`)
  },
  removeAllTypes() {
    return Api().put('/trailers/available/remove-all-types')
  },
  downloadSheetAvailable(params) {
    return Api().get('/trailers/available/sheet', { params })
  },
  downloadSheet(params) {
    return Api().get('/trailers/sheet', { params })
  },

  createNewLog(body) {
    return Api().post('/trailers/log', body)
  },
  editLog(id, body) {
    return Api().put(`/trailers/log/${id}`, body)
  },
  deleteLog(id) {
    return Api().delete(`/trailers/log/${id}`)
  },

  addComment(trailerId, type, body) {
    return Api().post(`/trailers/comments/${trailerId}/${type}`, body)
  },
  editComment(trailerId, type, body) {
    return Api().put(`/trailers/comments/${trailerId}/${type}`, body)
  },
  deleteComment(trailerId, type, commentId) {
    return Api().delete(`/trailers/comments/${trailerId}/${type}/${commentId}`)
  },
}
