import Api from '@/services/Api'

export default {
  getPrebookLoads(data) {
    return Api().get('/prebook-loads', {params: data})
  },
  addPrebookLoad (data) {
    return Api().post('/prebook-loads', data)
  },
  editPrebookLoad (data) {
    return Api().put('/prebook-loads', data)
  },
  changeStatusPrebookLoad (id, status, data) {
    return Api().put(`/prebook-loads/book/${status}/${id}`, data)
  },
  addComment(loadId, body) {
    return Api().post(`/prebook-loads/comments/${loadId}`, body)
  },
  editComment(loadId, body) {
    return Api().put(`/prebook-loads/comments/${loadId}`, body)
  },
  deleteComment(loadId, commentId) {
    return Api().delete(`/prebook-loads/comments/${loadId}/${commentId}`)
  },
  getPrebookCompanies(data) {
    return Api().get('/prebook-loads/companies', {params: data})
  },
  getPrebookCompaniesAll() {
    return Api().get('/prebook-loads/companies/all')
  },
  addPrebookCompany (data) {
    return Api().post('/prebook-loads/companies', data)
  },
  editPrebookCompany (data) {
    return Api().put('/prebook-loads/companies', data)
  },
  deletePrebookCompany (id) {
    return Api().delete(`/prebook-loads/companies/${id}`)
  },
  getStats () {
    return Api().get('/prebook-loads/stats')
  }
}