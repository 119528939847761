<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div @click="on.click" style="cursor: pointer;">
        <v-icon class="mr-2" small color="black lighten-2">
          mdi-history
        </v-icon>
      </div>
    </template>
    <v-card>
      <employment-histories-list v-if="menu" :driver="driver" />
    </v-card>
  </v-menu>
</template>

<script>
import EmploymentHistoriesList from './EmploymentHistoriesList.vue'

export default {
  name: 'EmploymentHistory',

  components: { EmploymentHistoriesList },

  props: ['driver'],

  data() {
    return {
      menu: false,
    }
  },
}
</script>
