<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-alert
              v-else
              :type="messageType"
              transition="scale-transition"
            >
              {{$t(message)}}
            </v-alert>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import User from '../../services/UsersService'
export default {
  data: () => ({
    message: 'Activation is in progress ...',
    messageType: 'info',
    email: '',
    token: ''
  }),
  mounted () {
    if (this.$route.query.email && this.$route.query.token) {
      this.email = this.$route.query.email
      this.token = this.$route.query.token
      this.activateAppUser()
    }
  },
  methods: {
    activateAppUser () {
      this.$store.dispatch('common/setLoading', true)
      const data = {
        email: this.email,
        token: this.token
      }
      User.activateAppUser(data).then(res => {
        this.$store.dispatch('common/setLoading', false)
        this.message = res.data
        this.messageType = 'success'
      }).catch(err => {
        if (err) {
          this.$store.dispatch('common/setLoading', false)
          this.message = this.$t('GENERIC_ERROR_MESSAGE')
          this.messageType = 'warning'
          console.log(err)
        }
      })
    }
  },
  computed: {
    ...mapGetters('common', ['loading'])
  }
}
</script>
