<script>
export default {
  props: ['loads', 'type'],
  functional: true,
  render(h, context) {
    let sum = 0
    for (let load in context.props.loads) {
      if (context.props.loads[load][context.props.type]) {
        sum += parseFloat(context.props.loads[load][context.props.type])
      }
    }
    return h('span', Math.round(sum * 100) / 100)
  }
}
</script>