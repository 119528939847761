<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    color="primary"
    dark
    dense
    fixed
  >
    <v-toolbar-title>
      <!-- APP NAME/HOME BTN -->
      <v-app-bar-nav-icon  @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <span @click.stop="drawer = !drawer" style="cursor: pointer;" class="d-none d-sm-block mr-2">{{appName}}</span>
    </v-toolbar-title>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="pink lighten-2"
    ></v-progress-linear>
    <!-- BOARDS -->
    <template v-if="layout !== 'mobile' && !showDropDown && canViewBoards ">
      <!-- <v-btn v-for="board in boards" :key="board._id" text small :disabled="($route.name === 'dashboard' && !searchActivated && board._id === selectedBoard) || board.route === $route.path" @click="selectBoard(board)">
        {{board.name}}
      </v-btn> -->
      <div class="tabs-holder" style="max-width: 700px;">
        <v-tabs show-arrows background-color="transparent" center-active :value="getSelectedBoardIndex" optional>
          <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
          <v-tab v-for="board in boards" :key="board._id"
            @click="selectBoard(board)">
              {{board.name}}
          </v-tab>
        </v-tabs>
      </div>
      <div class="tabs-holder" style="max-width: 250px;">
        <v-tabs background-color="transparent" center-active optional v-model="messageReportTab">
          <v-tab @click="openMarketReport('daily')">
            Daily
          </v-tab>
          <v-tab @click="openMarketReport('weekly')">
            Weekly
          </v-tab>
        </v-tabs>
      </div>
    </template>
    <template v-else-if="canViewBoards">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            small
            class="mr-3"
            v-bind="attrs"
            v-on="on"
          >
            {{getSelectedBoardName}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="selectBoard(board)" v-for="board in boards" :key="board._id" v-show="($route.name === 'dashboard' && !searchActivated && board._id !== selectedBoard) || board.route !== $route.path">
            <v-list-item-title>{{board.name}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- CLAIMS -->
    <template v-if="canViewClaims">
        <template v-if="layout !== 'mobile'">
          <div class="tabs-holder" style="max-width: 700px;">
            <v-tabs show-arrows background-color="transparent" center-active :value="getSelectedClaimIndex" optional>
              <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
              <v-tab v-for="user in claimsUsersFormated" :key="user._id"
                @click="selectClaimsUser(user)">
                  {{user.formatedName}}
              </v-tab>
            </v-tabs>
          </div>
        </template>
        <template v-else>
          <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="claimsUsers"
              text
              small
              class="mr-3"
              v-bind="attrs"
              v-on="on"
            >
              {{selectedUser ? selectedUser.formatedName : 'Select user'}}
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="selectClaimsUser(user)" v-for="user in claimsUsersFormated" :key="user._id">
              <v-list-item-title>{{user.formatedName}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </template>
    <v-spacer></v-spacer>
    <!-- ELD SHIFTS -->
    <template v-if="canViewEldShifts">
      <template v-if="layout !== 'mobile'">
        <v-btn v-for="shift in shifts" :disabled="($route.name === 'eldDashboard' && shift._id === selectedShift && !eldSearchActivated)" :key="shift._id" text small @click="selectShift(shift)">
          {{shift.name}}
        </v-btn>
      </template>
      <template v-else>
        <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="shifts"
            text
            small
            class="mr-3"
            v-bind="attrs"
            v-on="on"
          >
            {{getSelectedShiftName}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="selectShift(shift)" v-for="shift in shifts" :key="shift._id">
            <v-list-item-title>{{shift.name}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      </template>
      <template v-if="$route.name === 'eldDashboard'">
        <v-col cols="1" v-if="layout !== 'mobile'" class="pr-1 pl-1">
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            v-model="byUserCalls"
            tabindex="1"
            clearable
            height="28"
            dense
            solo
            :light="true"
            :label="$t('other.byUserCalls')"
            single-line
            hide-details
            @input="onSearchCalls"
          ></v-text-field>
        </v-col>
        <v-col cols="1" v-if="layout !== 'mobile'" class="pr-1 pl-1">
          <v-text-field
            v-model="byTruckCalls"
            tabindex="2"
            clearable
            height="28"
            dense
            solo
            :light="true"
            :label="$t('other.byTruckCalls')"
            single-line
            hide-details
            @input="onSearchCalls"
          ></v-text-field>
        </v-col>
      </template>
    </template>

    <!-- DASHBOARD SEARCH -->
    <template v-if="$route.name === 'dashboard'">
      <v-col :cols="layout !== 'mobile' ? 2 : 5" v-if="layout !== 'mobile'" class="pr-1">
        <v-text-field
          :disabled="loading && !searchActivated"
          prepend-inner-icon="mdi-magnify"
          v-model="byName"
          tabindex="1"
          clearable
          dense
          solo
          :light="true"
          height="28"
          :label="$t('other.searchDriversByName')"
          single-line
          hide-details
          @input="onSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="1" v-if="layout !== 'mobile'" class="pr-1 pl-1">
        <v-text-field
          :disabled="loading && !searchActivated"
          v-model="byTruck"
          tabindex="2"
          clearable
          height="28"
          dense
          solo
          :light="true"
          :label="$t('other.searchDriversByTruck')"
          single-line
          hide-details
          @input="onSearch"
        ></v-text-field>
      </v-col>
      <v-col cols="1" v-if="layout !== 'mobile'" class="pr-1 pl-1">
        <v-text-field
          :disabled="loading && !searchActivated"
          v-model="byTrailer"
          tabindex="3"
          clearable
          height="28"
          dense
          solo
          :light="true"
          :label="$t('other.searchDriversByTrailer')"
          single-line
          hide-details
          @input="onSearch"
        ></v-text-field>
      </v-col>
    </template>
    <!-- CLAIMS SEARCH -->
    <template v-if="$route.name === 'claims'">
      <v-col cols="1" v-if="layout !== 'mobile'" class="pr-1 pl-1">
        <v-text-field
          v-model="byTruckClaims"
          tabindex="2"
          clearable
          height="28"
          dense
          solo
          :light="true"
          :label="$t('other.searchDriversByTruck')"
          single-line
          hide-details
          @input="onSearchClaims"
        ></v-text-field>
      </v-col>
      <v-col cols="1" v-if="layout !== 'mobile'" class="pr-1 pl-1">
        <v-text-field
          v-model="byTrailerClaims"
          tabindex="3"
          clearable
          height="28"
          dense
          solo
          :light="true"
          :label="$t('other.searchDriversByTrailer')"
          single-line
          hide-details
          @input="onSearchClaims"
        ></v-text-field>
      </v-col>
    </template>
    <!-- ELD BUTTON -->
    <!-- <v-btn v-if="$route.name !== 'eldDashboard' && ($route.name === 'dashboard' || $route.name === 'claims') && layout !== 'mobile'" text small :to="{name: 'eldDashboard'}">
      ELD
    </v-btn> -->
    <!-- CLAIMS LINKS -->
    <v-menu left open-on-hover offset-y v-if="((user !== null && permissions.SAFETY))">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text small
          v-bind="attrs"
          v-on="on"
        >
          SAFETY
        </v-btn>
      </template>
      <v-list>
        <v-list-item dense :to="{name: 'eldDashboard'}" v-if="permissions.ELD_VIEW">
          <v-list-item-title>ELD</v-list-item-title>
        </v-list-item>
        <template v-if="permissions.CLAIMS">
          <v-divider></v-divider>
          <v-subheader>Claims</v-subheader>
          <v-list-item dense :to="`/claims/${claim.type}`" v-for="claim in claimTypes" :key="claim._id">
            <v-list-item-title>{{claim.name}}</v-list-item-title>
          </v-list-item>
        </template>
        <template v-if="permissions.NEWDRIVERS_VIEW">
          <v-divider></v-divider>
          <v-subheader>Drivers</v-subheader>
          <v-list-item dense to="/new-drivers">
            <v-list-item-title>NEW DRIVERS</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <!-- <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          v-on="on"
        >
          {{$i18n.locale}}
        </v-btn>
      </template>

      <v-list v-show="$i18n.locale !== 'sr'">
        <v-list-tile @click="onChangeLocale('sr')">
          <v-list-tile-title>Srpski</v-list-tile-title>
        </v-list-tile>
      </v-list>
      <v-list v-show="$i18n.locale !== 'en'">
        <v-list-tile @click="onChangeLocale('en')">
          <v-list-tile-title>English</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu> -->
    <v-btn v-if="canViewTrucks" to="/trucks/all" title="Trucks board" icon class="d-none d-sm-flex">
      <v-icon>mdi-truck</v-icon>
    </v-btn>
    <v-btn v-if="canViewTrailes" to="/trailers" title="Trailer board" icon class="d-none d-sm-flex">
      <v-icon>mdi-truck-trailer</v-icon>
    </v-btn>
    <v-btn v-if="companyInfo" :href="companyInfo" target="_blank" title="Information about companies" icon>
      <v-icon>mdi-information-outline</v-icon>
    </v-btn>
    <v-btn v-if="brokerAlerts" color="warning" :href="brokerAlerts" target="_blank" title="Broker Alerts" icon>
      <v-icon>mdi-alert-outline</v-icon>
    </v-btn>
    <v-btn v-if="cloudLinkNew" :href="cloudLinkNew" target="_blank" title="Cloud new" icon>
      <v-icon>mdi-cloud</v-icon>
    </v-btn>
    <v-btn icon to="/inbox">
      <v-icon>mdi-email</v-icon>
    </v-btn>
    <v-btn icon @click="onLogout">
      <v-icon>mdi-export</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {mapGetters} from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'Toolbar',
  data() {
    return {
      layout: 'desktop',
      boards: [],
      shifts: [],
      byName: '',
      byTruck: '',
      byTrailer: '',
      byTruckClaims: '',
      byTrailerClaims: '',
      byUserCalls: '',
      byTruckCalls: '',
      claimTypes: [],
      showDropDown: false,
      messageReportTab: null
    }
  },
  methods: {
    getWindowWidth() {
      let windowWidth = document.documentElement.clientWidth
      if (windowWidth <= 768) {
        this.layout = 'mobile'
      } else if (windowWidth <= 992 ) {
        this.layout = 'tablet'
      } else if (windowWidth <= 1280 ) {
        this.layout = 'laptop'
        this.showDropDown = true
      } else if (windowWidth <= 1627) {
        this.layout = 'laptop'
      } else {
        this.layout = 'desktop'
      }
    },
    onLogout () {
      this.$store.dispatch('auth/logout')
        .then(() => {
          // this.$socket.client.disconnect();
          // window.location.reload()
          this.$store.dispatch('eldDashboard/setShiftId', null)
          this.$router.push('/login')
        })
        .catch((err) => {
          console.log('logout fail', err.response)
        })
    },
    onChangeLocale (locale) {
      this.$i18n.locale = locale
      this.$store.dispatch('common/changeLang', locale)
    },
    selectClaimsUser (user) {
      this.$store.dispatch('claims/selectUser', user)
    },
    selectBoard (board) {
      this.resetSearch()
      this.messageReportTab = null
      this.$store.dispatch('dashboard/activateSearch', false)
      this.$store.dispatch('eldDashboard/activateSearch', false)
      this.$store.dispatch('dashboard/updateSearch', {
        byName: '',
        byTruck: '',
        byTrailer: ''
      })
      this.$store.dispatch('eldDashboard/updateSearch', {
        byName: '',
        byTruck: ''
      })
      // ako ima ruta
      if (board && board.route && board.route !== '') {
        this.$store.dispatch('dashboard/setBoardId', null)
        this.$router.push(board.route)
      } else {
        this.$store.dispatch('dashboard/setBoardId', board._id)
        if (this.$route.name !== 'dashboard') {
          this.$router.push({name: 'dashboard'})
        }
      }
    },
    selectShift(shift) {
      if (!this.permissions.ELD_SHIFT_CHANGE) {
        return
      }
      this.$store.dispatch('dashboard/activateSearch', false)
      this.$store.dispatch('eldDashboard/activateSearch', false)
      this.$store.dispatch('dashboard/updateSearch', {
        byName: '',
        byTruck: '',
        byTrailer: ''
      })
      this.$store.dispatch('eldDashboard/updateSearch', {
        byName: '',
        byTruck: ''
      })
      this.$store.dispatch('eldDashboard/setShiftId', shift._id)
      if (this.$route.name !== 'eldDashboard') {
        this.$router.push({name: 'eldDashboard'})
      }
    },
    onSearch: debounce( function() {
      if ((this.byName === '' || this.byName === null || this.byName.length < 3)
      && (this.byTruck === '' || this.byTruck === null || this.byTruck.length < 3)
      && (this.byTrailer === '' || this.byTrailer === null || this.byTrailer.length < 3)) {
        if (this.searchActivated) {
          this.$store.dispatch('dashboard/updateSearch', {byName: '', byTruck: '', byTrailer: ''})
        }
        this.$store.dispatch('dashboard/activateSearch', false)
        for (let board of this.boardsWithoutSelected) {
          this.$socket.client.emit('leave_room', board._id)
        }
      } else {
        this.$store.dispatch('dashboard/activateSearch', true)
        this.$store.dispatch('dashboard/updateSearch', {byName: this.byName, byTruck: this.byTruck, byTrailer: this.byTrailer})
        for (let board of this.boardsWithoutSelected) {
          this.$socket.client.emit('joinToBoard', board._id)
        }
      }
    }, 500),
    onSearchCalls: debounce( function() {
      if ((this.byUserCalls === '' || this.byUserCalls === null) && (this.byTruckCalls === '' || this.byTruckCalls === null)) {
        this.$store.dispatch('eldDashboard/activateSearch', false)
      } else {
        this.$store.dispatch('eldDashboard/activateSearch', true)
      }
      this.$store.dispatch('eldDashboard/updateSearch', {byName: this.byUserCalls, byTruck: this.byTruckCalls})
    }, 500),
    onSearchClaims: debounce( function() {
      if ((this.byTruckClaims === '' || this.byTruckClaims === null) && (this.byTrailerClaims === '' || this.byTrailerClaims === null)) {
        this.$store.dispatch('claims/activateSearch', false)
      } else {
        this.$store.dispatch('claims/activateSearch', true)
      }
      this.$store.dispatch('claims/updateSearch', {byTruck: this.byTruckClaims, byTrailer: this.byTrailerClaims})
    }, 500),
    resetSearch () {
      this.byName = ''
      this.byTruck = ''
      this.byTrailer = ''
    },
    openMarketReport(type) {
      if (type === 'daily' && this.$route.name !== 'messages-daily-report') {
        this.$router.push('/messages/daily-report')
      } else if (type === 'weekly' && this.$route.name !== 'messages-weekly-report') {
        this.$router.push('/messages/weekly-report')
      }
    }
  },
  computed: {
    ...mapGetters('dashboard', ['selectedBoard', 'searchActivated']),
    ...mapGetters('eldDashboard', ['selectedShift', 'eldSearchActivated']),
    ...mapGetters('common', ['appName', 'loading', 'dark']),
    ...mapGetters('auth', ['user', 'permissions']),
    ...mapGetters('claims', ['selectedUser', 'claimsUsers']),
    drawer: {
      get () {
        return this.$store.getters['common/drawer']
      },
      set (val) {
        this.$store.dispatch('common/setDrawer', val)
      }
    },
    getSelectedBoardName () {
      let board = this.boards.find(board => board._id === this.selectedBoard)
      if (board) {
        return board.name
      } else {
        let boardByRoute = this.boards.find(board => board.route === this.$route.path)
        return boardByRoute ? boardByRoute.name : ''
      }
    },
    getSelectedBoardIndex () {
      if (this.$route.name !== 'dashboard' || this.searchActivated) return undefined

      let boardIndex = this.boards.findIndex(board => board._id === this.selectedBoard)
      if (boardIndex !== -1) {
        return boardIndex
      } else {
        let boardByRoute = this.boards.findIndex(board => board.route === this.$route.path)
        return boardByRoute !== -1 ? boardByRoute : 0
      }
    },
    getSelectedClaimIndex() {
      if(!this.selectedUser) return 0
      return this.claimsUsersFormated.findIndex(user => user._id == this.selectedUser._id)
    },
    getSelectedShiftName () {
      let shift = this.shifts.find(shift => shift._id === this.selectedShift)
      if (shift) {
        return shift.name
      }
      return ''
    },
    boardsWithoutSelected () {
      let selectedBoardIndex = this.boards.findIndex(board => board._id === this.selectedBoard)
      if (selectedBoardIndex !== -1) {
        let filteredBoards = [...this.boards]
        filteredBoards.splice(selectedBoardIndex, 1)
        return filteredBoards
      }
      return []
    },
    claimsUsersFormated () {
      return this.claimsUsers.map(user => {
        let splited = user.name.split(' ')
        user.formatedName = user.name
        if (splited.length === 2) {
          user.formatedName = `${splited[0]}.${splited[1][0]}`
        }
        return user
      })
    },
    cloudLink () {
      if (this.$config.CLOUD_LINK) {
        return this.$config.CLOUD_LINK
      }
      return false
    },
    cloudLinkNew () {
      if (this.$config.CLOUD_LINK_NEW) {
        return this.$config.CLOUD_LINK_NEW
      }
      return false
    },
    brokerAlerts () {
      if (this.$config.BROKER_ALERTS) {
        return this.$config.BROKER_ALERTS
      }
      return false
    },
    companyInfo () {
      if (this.$config.COMPANY_INFO) {
        return this.$config.COMPANY_INFO
      }
      return false
    },
    canViewTrailes () {
      return this.permissions.TRAILER_VIEW
    },
    canViewTrucks () {
      return this.permissions.TRUCK_VIEW
    },
    canViewBoards () {
      return this.user !== null 
      && (this.permissions.DISPATCHER_ALL || this.permissions.DISPATCHER_READ)
      && this.$route.name !== 'claims'
    },
    canViewEldShifts () {
      return this.$route.name !== 'dashboard'
      && this.$route.name !== 'claims'
      && this.$route.name !== 'newDrivers'
      && (this.user !== null && this.permissions.ELD_VIEW)
    },
    canViewClaims() {
      return (this.user !== null && this.permissions.CLAIMS)
      && this.$route.name === 'claims'
      && this.claimsUsers
    }
  },
  mounted() {
    this.getWindowWidth()

    this.$store.dispatch('boards/getAllBoards').then(res => {
      this.boards = res
      if (this.$route.name === 'dashboard') {
        let fondBoard = this.boards.find(board => board._id === this.user.board_id)
        // Ako korisnik nema board_id (board_id: null)
        if (!fondBoard && (!this.user.board_id || this.user.board_id === '')) {
          this.selectBoard(this.boards[0])
        } else if (fondBoard && (this.user.board_id || this.user.board_id !== '')) {
          this.selectBoard(fondBoard)
        } else {
          this.selectBoard(this.boards[0])
        }
      }
    })

    this.$store.dispatch('shifts/getAllShifts').then(res => {
      this.shifts = res
    })

    if (this.permissions.CLAIMS) {
      this.$store.dispatch('user/getUsersByRoleId', 10).then(res => {
        this.$store.dispatch('claims/updateClaimsUsers', res)
        let userIndex = res.findIndex(user => user._id === this.user._id)
        this.$store.dispatch('claims/selectUser', res[userIndex !== -1 ? userIndex : 0])
      })
      this.$store.dispatch('claims/getTypes').then(res => {
        if (res.status === 'OK') {
          this.claimTypes = res.types
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.v-toolbar__title {
  // min-width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .v-toolbar__title {
    width: 100%;
    max-width: none;
  }
}

.v-tabs {
  .v-tab {
    min-width: 70px;
    font-size: 0.775rem;
  }
}
</style>
