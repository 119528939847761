<template>
  <div class="trailers-wrapper">
    <v-row class="pa-1 d-flex align-center">
      <v-btn class="mr-1" color="pink" dark small @click="editItem = 'new'">
        Add
      </v-btn>
      <v-btn
        color="success"
        :loading="loading"
        dark
        small
        @click="handleExportToExcell"
      >
        Export
      </v-btn>
      <v-btn
        color="primary"
        class="ml-1"
        dark
        small
        @click="goToStatistics"
      >
        Stats <v-icon small>mdi-chart-bar</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <table-filters
        :initial-values="{
          ...FILTER_INITIAL,
          search: ($route.query && $route.query.search) || '',
        }"
        :initial-form="filtersForm"
        @filters-changed="handleFiltersChanged"
      />
    </v-row>

    <span class="total-number">
      Trailers total: <strong>{{ totalItems }}</strong>
    </span>

    <div class="Trailers__table-wrapper">
      <div class="table-container-trailers">
        <table class="trailers-table" :class="{ dark: dark }">
          <thead>
            <tr>
              <th>#</th>
              <th>Trl #</th>
              <th v-for="item in headers" :key="item.value">{{ item.text }}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(trailer, index) in trailers"
              :key="trailer.id"
              :class="{ total_damaged: trailer.is_total_damaged }"
              :title="trailer.is_total_damaged ? 'Total damage' : ''"
            >
              <td align="center">{{ index + 1 }}</td>
              <td class="trailer-number">
                <TrailerNumber
                  :trailer="trailer"
                  @rightClick="handleEditItem(trailer)"
                />
              </td>

              <template v-for="(item, indexH) in headers">
                <td
                  v-if="item.value === 'truck.number'"
                  :key="`td-${indexH}`"
                  align="center"
                >
                  <template v-if="trailer.truck">
                    <TruckNumber :truck="trailer.truck" />
                  </template>
                  <template v-else> / </template>
                </td>

                <td
                  v-else-if="item.value === 'company'"
                  :key="`td-${indexH}`"
                  align="center"
                  :class="{ 'history-field': trailer.company }"
                >
                  <field-with-history
                    type="COMPANY_CHANGED"
                    :id="trailer._id"
                    allow-change
                  >
                    {{ trailer.company }}
                  </field-with-history>
                </td>

                <td
                  v-else-if="item.value === 'plate'"
                  :key="`td-${indexH}1`"
                  align="center"
                  class="history-field"
                >
                  <FieldWithHistory type="plate" :id="trailer._id">{{
                    trailer.plate
                  }}</FieldWithHistory>
                </td>

                <td
                  v-else-if="item.value === 'temp_plate_exp_date'"
                  :key="`td-${indexH}2`"
                  align="center"
                  :class="{ 'history-field': trailer.temp_plate_exp_date }"
                >
                  <FieldWithHistory
                    v-if="trailer.temp_plate_exp_date"
                    type="plate_exp_date"
                    :id="trailer._id"
                    field-type="date"
                    >{{
                      trailer.temp_plate_exp_date | formatDate('MM/DD/YYYY')
                    }}</FieldWithHistory
                  >
                </td>

                <td
                  v-else-if="item.value === 'dot_inspection_date'"
                  :key="`td-${indexH}3`"
                  align="center"
                  :class="{ 'history-field': trailer.dot_inspection_date }"
                >
                  <FieldWithHistory
                    v-if="trailer.dot_inspection_date"
                    type="dot_inspection_date"
                    :id="trailer._id"
                    field-type="date"
                    >{{
                      trailer.dot_inspection_date | formatDate('MM/DD/YYYY')
                    }}</FieldWithHistory
                  >
                </td>

                <td
                  v-else-if="item.value === 'repairs'"
                  :key="`td-${indexH}4`"
                  align="center"
                >
                  <trailer-dot-inspection
                    v-if="trailer.number"
                    :trailer="trailer"
                  />
                </td>

                <td
                  v-else-if="item.value === 'annual_dot'"
                  :key="`td-${indexH}5`"
                  align="center"
                >
                  <trailer-repairs v-if="trailer.number" :trailer="trailer" />
                </td>

                <td
                  v-else-if="item.value === 'status'"
                  :key="`td-${indexH}19`"
                  align="center"
                >
                  {{ trailer.status || 'NO STATUS' }}
                </td>

                <td
                  v-else-if="item.value === 'note_new'"
                  :key="`td-${indexH}20`"
                  align="center"
                  style="min-width: 95px"
                >
                  <trailer-comments
                    :comments="trailer.note_new || []"
                    :id="trailer._id"
                    type="note_new"
                    text="Notes"
                  />
                </td>

                <td
                  v-else-if="item.value === 'info'"
                  :key="`td-${indexH}16`"
                  align="center"
                  style="min-width: 95px"
                >
                  <trailer-comments
                    :comments="trailer.info || []"
                    :id="trailer._id"
                    type="info"
                    text="Info's"
                  />
                </td>

                <td
                  v-else-if="item.value === 'gps'"
                  :key="`td-${indexH}6`"
                  align="center"
                  :title="trailer.gps ? 'Working / App' : ''"
                >
                  <template v-if="trailer.gps">
                    <span>{{ trailer.gps_working ? 'Y' : 'N' }}</span> /
                    <span>{{
                      trailer.gps_app
                        ? trailer.gps_app.substring(0, 2).toUpperCase()
                        : '-'
                    }}</span>
                  </template>
                  <v-icon color="red lighten-2" v-else
                    >mdi-close-octagon-outline</v-icon
                  >
                </td>

                <td
                  v-else-if="
                    ['pu_date', 'driver_pu_date', 'hold_date'].includes(
                      item.value
                    )
                  "
                  :key="`td-${indexH}7`"
                  align="center"
                >
                  {{ trailer[item.value] | formatDate('MM/DD/YYYY') }}
                </td>

                <td
                    v-else-if="item.value === 'current_driver_name'"
                    :key="`td-${indexH}`"
                    align="center"
                >
                  {{ currentDriverText(trailer) }}
                </td>

                <td
                  v-else-if="
                    [
                      'has_partner_sticker',
                      'is_damaged',
                      'towing',
                      'loaded',
                      'units_together',
                      'is_active',
                    ].includes(item.value)
                  "
                  :key="`td-${indexH}8`"
                  align="center"
                >
                  <v-icon color="indigo" v-if="trailer[item.value]"
                    >mdi-check</v-icon
                  >
                  <v-icon color="red lighten-2" v-else
                    >mdi-close-octagon-outline</v-icon
                  >
                </td>

                <td v-else :key="`td-${indexH}9`" align="center">
                  {{ getValue(item.value, trailer) }}
                </td>
              </template>

              <td align="center">
                <v-icon
                  :disable="!canEdit"
                  class="mr-2"
                  small
                  :color="canEdit ? 'primary' : ''"
                  @click="handleEditItem(trailer)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  color="red lighten-2"
                  @click="deleteTrailer(trailer)"
                >
                  mdi-delete
                </v-icon>
                <v-icon v-if="permissions.PERMITBOOK_ADD" small class="mr-2" @click="addDocumentForThisTrailer(trailer)">
                  mdi-upload
                </v-icon>
                <v-icon v-if="permissions.PERMITBOOK_VIEW" small @click="showDocumentsForThisTrailer(trailer)">
                  mdi-file-document-multiple
                </v-icon>
              </td>
            </tr>
          </tbody>
        </table>
        <infinite-loading
          ref="infinite"
          :identifier="optionsString"
          @infinite="infiniteHandler"
        >
          <div slot="spinner">
            <v-progress-circular
              indeterminate
              class="trailer-loader"
              color="primary"
            ></v-progress-circular>
          </div>
          <span slot="no-more"></span>
          <div slot="no-results"></div>
        </infinite-loading>
        <div v-if="!trailers.length">
          <p class="subtitle-1 pt-2" style="text-align: center">
            There is no Trailers
          </p>
        </div>
      </div>

      <div class="Trailers__table-order">
        <table-order
          :userId="user && user._id"
          :default-order="defaultHeaders"
          @order-changed="handleTableOrderChanged"
          table-name="TRAILERS"
        />
      </div>
    </div>

    <trailer-form
      :edit-id="editId"
      :edit-item="editItem"
      @close="handleClose"
    />
    <v-dialog v-model="showForm" max-width="500">
      <div>
        <PermitBookForm
          v-if="showForm"
          @permitBookAdded="showForm = false"
          :referrerForDocument="trailerForDocument"
        />
      </div>
    </v-dialog>
    <v-dialog v-model="showDocumentsModal" max-width="90vw" max-height="90vh">
      <PermitBookTable
        :referrer-type="'trailer'"
        :referrer-id="referrerId"
        v-if="showDocumentsModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import trailersService from '@/services/TrailersService'
import TrailerNumber from './TrailerNumber.vue'
import TruckNumber from './TruckNumber.vue'
import FieldWithHistory from './FieldWithHistory.vue'
import TrailerDotInspection from './TrailerDotInspection.vue'
import TrailerRepairs from './TrailerRepairs.vue'
import TableFilters from '../TableFilters.vue'
import TableOrder from '../TableOrder.vue'
import TrailerForm from './TrailerForm.vue'
import PermitBookForm from '../PermitBooks/PermitBookForm.vue'
import PermitBookTable from '../PermitBooks/PermitBookTable.vue'
import { b64toBlob, downloadFile } from '../../utils'
import { commentsMixin } from './CommentsMixin'
import TrailerComments from './TrailerComments.vue'

const FILTER_INITIAL = {
  filter: null,
  search: null,
  owner: null,
  dealership: null,
  yard: null,
  company: null,
  companyFrom: null,
  companyTo: null,
  driver_name: null,
}

const FILTERS_FORM = {
  company: { type: 'select', label: 'Company', options: [] },
  companyFrom: { type: 'date-time', label: 'From', conditionField: 'company' },
  companyTo: { type: 'date-time', label: 'To', conditionField: 'company' },
  filter: {
    type: 'select',
    itemValue: 'value',
    itemText: 'label',
    options: [
      { label: 'All', value: null },
      { label: 'Active', value: 'is_active' },
      { label: 'Damaged', value: 'is_damaged' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
  status: {
    type: 'select',
    label: 'Status',
    itemValue: 'value',
    itemText: 'text',
    options: [
      { text: 'NO STATUS', value: 'NO STATUS' },
      { text: 'STOLEN', value: 'STOLEN' },
      { text: 'REPOSSESSION', value: 'REPOSSESSION' },
      { text: 'REPOWER', value: 'REPOWER' },
      { text: 'NEW', value: 'NEW' },
      { text: 'ON THE ROAD', value: 'ON THE ROAD' },
      { text: 'SOLO', value: 'SOLO' },
      { text: 'BROKERAGE', value: 'BROCKERAGE' },
      { text: 'PARKED', value: 'PARKED' },
      { text: 'MISSING', value: 'MISSING' },
      { text: 'RECOVERY', value: 'RECOVERY' },
      { text: 'CLAIMS', value: 'CLAIMS' },
    ],
  },
  search: { type: 'text', label: 'Trailer/VIN #', min: 1 },
  driver_name: { type: 'text', label: 'Driver', min: 3 },
  owner_new: { type: 'text', label: 'Owner' },
  dealership: { type: 'text', label: 'Dealership' },
  yard: {
    type: 'select',
    label: 'Yard',
    itemValue: '_id',
    itemText: 'name',
    options: [],
  },
}

const DEFAULT_HEADERS = [
  { text: 'Type', value: 'type' },
  { text: 'Truck #', value: 'truck.number' },
  { text: 'Driver name', value: 'driver_name' },
  { text: 'Third party', value: 'third_party.name' },
  { text: 'Company', value: 'company' },
  { text: 'Owner', value: 'owner_new.name' },
  { text: 'Dealership', value: 'dealership.name' },
  { text: 'Yard', value: 'yard.name' },
  { text: 'Current Driver', value: 'current_driver_name' },
  { text: 'Driver phone', value: 'drivers_phone' },
  { text: 'Pu Date', value: 'pu_date ' },
  { text: 'Driver pu date', value: 'driver_pu_date' },
  { text: 'Make', value: 'make.name' },
  { text: 'Year', value: 'production_year' },
  { text: 'Vin #', value: 'vin_number' },
  { text: 'Plate', value: 'plate' },
  { text: 'Temp plate EXP', value: 'temp_plate_exp_date' },
  { text: 'DOT', value: 'dot_inspection_date' },
  { text: 'Annual DOT', value: 'annual_dot', align: 'center' },
  { text: 'Repairs', value: 'repairs', align: 'center' },
  { text: 'GPS', value: 'gps' },
  { text: 'Sajne', value: 'has_partner_sticker' },
  { text: 'Location GPS', value: 'location_gps' },
  { text: 'Is Damaged', value: 'is_damaged' },
  { text: 'Rent/Buy', value: 'option' },
  { text: 'Loaded', value: 'loaded' },
  { text: 'Availability', value: 'availability' },
  { text: 'Status', value: 'status' },
  { text: 'Brokerage', value: 'brockerage' },
  { text: 'Hold', value: 'hold.name' },
  { text: 'Hold Date', value: 'hold_date' },
  { text: 'Info', value: 'info' },
  { text: 'Note', value: 'note_new' },
  { text: 'Units Together', value: 'units_together' },
  { text: 'Towing', value: 'towing' },
  { text: 'Towing Per Day', value: 'towing_per_day' },
  { text: 'Is Active', value: 'is_active' },
]

export default {
  name: 'Trailers',
  components: {
    InfiniteLoading,
    TrailerNumber,
    TruckNumber,
    FieldWithHistory,
    TrailerDotInspection,
    TrailerRepairs,
    TableFilters,
    TableOrder,
    TrailerForm,
    TrailerComments,
    PermitBookForm,
    PermitBookTable,
  },

  mixins: [commentsMixin],

  data() {
    return {
      FILTER_INITIAL,
      filtersForm: { ...FILTERS_FORM },
      options: {
        page: 1,
        sortBy: ['number'],
        order: 1,
        itemsPerPage: 7,
        ...FILTER_INITIAL,
      },
      trailers: [],

      totalItems: 0,
      editItem: null,
      editId: null,

      headers: DEFAULT_HEADERS,
      defaultHeaders: DEFAULT_HEADERS,
      trailerForDocument: null,
      showForm: false,
      showDocumentsModal: false,
      referrerId: null
    }
  },

  beforeRouteEnter(_, __, next) {
    next((vm) => {
      if (vm.permissions.TRAILER_VIEW) return true
      vm.$router.push('/forbidden')
    })
  },

  beforeMount() {
    if (this.$route.query.search) {
      FILTER_INITIAL.search = this.$route.query.search
      this.options.search = this.$route.query.search
    }

    if (this.$route.params.filter) {
      this.options.filter = this.setFilter()
    }
  },

  mounted() {
    this.$socket.client.emit('joinToTrailer')
    this.setCompanies()
  },

  watch: {
    search(newVal) {
      this.getTrucks(newVal)
    },
  },

  computed: {
    ...mapGetters('auth', ['user', 'permissions']),
    ...mapGetters('common', ['loading', 'dark', 'companiesAll']),

    optionsString() {
      return JSON.stringify(this.options)
    },
    canEdit() {
      return ['SUPERADMIN', 'ADMIN', 'TRAILER_DEPARTMENT'].indexOf(this.user.roles[0].role_name) !== -1
    },
    trailersMap() {
      let trailersMp = {}
      for (const [y, trailer] of this.trailers.entries()) {
        trailersMp[trailer._id] = y
      }
      return trailersMp
    },
  },

  sockets: {
    connect() {
      console.log('client connected')
      // Fired when the socket connects.
      this.$socket.client.emit('joinToTrailer')
    },
    disconnect() {
      console.log('client disconnected')
    },
    newTrailer(data) {
      this.trailers.unshift(data)
      this.setMessage(`A new trailer has been added: ${data.number}`)
    },
    editTrailer(data) {
      if (this.trailersMap[data._id] != undefined) {
        let index = this.trailersMap[data._id]
        this.trailers.splice(index, 1, data)
      }
    },
    deleteTrailer(data) {
      if (this.trailersMap[data._id] != undefined) {
        let index = this.trailersMap[data._id]
        this.trailers.splice(index, 1)
      }
    },
  },

  created() {
    this.getYards()
  },

  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),

    goToStatistics() {
      this.$router.push('trailers/statistics')
    },

    async getYards() {
      try {
        const data = await this.$store.dispatch('yards/getAllYards', 'TRAILER')
        if (Array.isArray(data)) this.filtersForm.yard.options = data
      } catch (error) {
        console.error('getYards()', error)
      }
    },

    setFilter() {
      if (this.$route.params.filter === 'null') return null
      return this.$route.params.filter
    },

    setCompanies() {
      this.$store.dispatch('common/getAllCompanies').then((companies) => {
        if (companies) {
          this.filtersForm.company.options = companies
        }
      })
    },

    handleFiltersChanged: debounce(function (filters) {
      this.checkUrl(this.options.filter, filters.filter)
      this.options = {
        ...this.options,
        ...filters,
        page: 1,
      }
      this.trailers = []
    }, 200),

    checkUrl(oldF, newF) {
      if (oldF !== newF) this.$router.push(`/trailers/${newF}`)
    },

    deleteTrailer(trailer) {
      let confVal = confirm(
        `Are you sure you want to delete trailer: ${trailer.number}?`
      )
      if (confVal) {
        this.$store
          .dispatch('trailers/deleteTrailer', trailer._id)
          .then(() => {
            this.setMessage(`${trailer.number} successfully deleted`)
          })
          .catch((err) => {
            console.log('err on delete', err)
          })
      }
    },
    addDocumentForThisTrailer(trailer) {
      this.showForm = true
      const trailerObj = {
        type: 'trailer',
        text: trailer.number,
        value: {
          number: trailer.number,
          id: trailer._id,
        },
      }
      this.trailerForDocument = trailerObj
    },
    handleTableOrderChanged(order) {
      const prepareObj = {}
      order.forEach((c) => {
        prepareObj[c.columnName] = {
          order: c.order,
          isShown: c.isShown,
        }
      })

      this.headers = DEFAULT_HEADERS.filter(
        (c) => prepareObj[c.value].isShown
      ).sort((a, b) => prepareObj[a.value].order - prepareObj[b.value].order)
    },

    infiniteHandler($state) {
      trailersService
        .getTrailers(this.options)
        .then((res) => {
          if (res.data.docs && res.data.docs.length) {
            this.trailers.push(...res.data.docs)
            this.totalItems = res.data.total
            if (this.trailers.length === res.data.total) {
              $state.complete()
            } else {
              this.options.page++
              $state.loaded()
            }
          } else {
            $state.complete()
          }
        })
        .catch((err) => {
          console.log('err get trailers', err)
        })
    },

    setFormDate(date) {
      return date ? this.$options.filters.formatDatePicker(date) : null
    },

    handleClose() {
      this.editId = null
      this.editItem = null
    },

    handleEditItem(item) {
      if(!this.canEdit) return
      this.editId = item._id
      this.editItem = Object.assign(
        {},
        {
          ...item,
          hold_date: this.setFormDate(item.hold_date),
        }
      )
    },

    async handleExportToExcell() {
      try {
        this.loading = true
        const { data } = await trailersService.downloadSheet(this.filters)
        const blob = b64toBlob(
          data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        )
        downloadFile(blob, 'trailers')
      } catch (error) {
        console.error('handleExportToExcell()', error)
      } finally {
        this.loading = false
      }
    },

    getValue(value, trailer) {
      if (typeof value !== 'string') return '/'
      const [first, second] = value.split('.')
      if (second) return (trailer[first] && trailer[first][second]) || '/'
      return trailer[first] || '/'
    },
    showDocumentsForThisTrailer(trailer) {
      this.referrerId = trailer._id
      this.showDocumentsModal = true
    },
    currentDriverText(item) {
      const driverStateMap = {
        temporary: 'T',
        recovery: 'REC',
        repower: 'R'
      }

      const driverState = driverStateMap[item.driver_state_current_driver] || ''
      const driverName = item.current_driver_name || ''
      const truckId = item.current_driver_truck?.truck_id || ''
      const division = item.current_driver_company || ''
      const startDate = item.start_date_current_driver 
        ? new Date(item.start_date_current_driver).toLocaleDateString('en-US', { 
          month: '2-digit', 
          day: '2-digit', 
          year: 'numeric' 
        }) 
        : ''

      return `${driverState} - ${driverName} ${truckId} ${division} ${startDate}`
    }
  },

  beforeDestroy() {
    this.$socket.client.emit('leave_trailers')
  },
}
</script>

<style lang="scss">
.Trailers {
  &__table-wrapper {
    position: relative;
  }

  &__table-order {
    position: absolute;
    right: -6px;
    top: -20px;
    z-index: 10;
  }

  td {
    position: relative;
  }
}

.is-company {
  span {
    position: absolute;
    left: 16px;
    bottom: 0px;
    font-size: 11px;
    color: #ff5252 !important;
  }
}

.trailers-wrapper {
  .v-input--selection-controls {
    margin-top: 6px;
  }

  .total-number {
    font-size: 10px;
  }

  .table-container-trailers {
    overflow: scroll;
    width: 100%;
    max-height: calc(100vh - 145px);
    position: relative;
    // margin-top: 20px;

    &.loading {
      opacity: 0.7;
    }

    .trailers-table {
      border-collapse: collapse;
      min-width: 400px;
      width: 100%;
      border-collapse: separate; /* Don't collapse */
      border-spacing: 0;

      &.dark {
        th {
          background-color: #43a047;
        }

        tbody {
          tr {
            &:hover {
              color: #000;

              td {
                &.trailer-number {
                  .note-icon {
                    color: #43a047;
                  }
                }
              }
            }

            td {
              &.trailer-number {
                background: #121212;
              }
            }
          }
        }
      }

      th {
        padding: 5px 5px;
        border-left: 1px solid #a9a9a9;
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        left: 0px;
        background-color: #1e88e5ff;
        color: #ffffff;
        text-align: center;
        top: 0;
        z-index: 3;
        font-size: 13px;

        &:nth-child(2) {
          z-index: 4;
          border-right: 2px solid yellow;
        }

        &:nth-child(4) {
          min-width: 60px;
        }
      }

      tbody {
        tr {
          &.total_damaged {
            background-color: rgb(228, 174, 167) !important;

            td {
              &.trailer-number {
                background-color: rgb(228, 174, 167) !important;
              }
            }

            &:hover {
              background-color: rgb(228, 174, 167) !important;

              td {
                &.trailer-number {
                  background-color: rgb(228, 174, 167) !important;
                }
              }
            }
          }

          &:hover {
            background-color: lightyellow !important;

            td {
              &.trailer-number {
                background-color: lightyellow !important;
              }
            }
          }

          td {
            padding: 7px 5px;
            font-size: 12px;
            border-left: 1px solid #a9a9a9;
            border-bottom: 1px solid #a9a9a9;
            white-space: nowrap;
            position: relative;

            &.trailer-number {
              cursor: pointer;
              font-weight: bold;
              min-width: 100px;
              position: sticky;
              position: -webkit-sticky; /* for Safari */
              left: 0px;
              z-index: 2;
              background: #fff;
              border-right: 2px solid yellow;
              padding: 0px;
              &:hover {
                color: blue;
              }

              div {
                padding: 7px 5px;
              }

              .note-icon {
                position: absolute;
                right: 2px;
                top: 2px;
                font-size: 20px;
              }
            }

            &.history-field {
              cursor: pointer;
              &:hover {
                color: blue;
              }
            }

            &:nth-child(4) {
              cursor: pointer;
              font-weight: 600;
              padding: 0px;

              div {
                padding: 7px 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
