<template>
  <div class="newdrivers">
    <div class="d-flex align-center justify-space-between">
      <div class="left-side d-flex align-center">
        <div class="title mr-2">NEW DRIVERS</div>
        <v-switch
          v-if="permissions.NEWDRIVERS_DELETED"
          v-model="showDeleted"
          label="Show deleted drivers"
          color="primary"
          hide-details
          @change="filterDeleted"
        ></v-switch>
    </div>
      <v-spacer />
      <v-fab-transition>
        <v-btn
          :disabled="!canEdit"
          class="claims-add-btn"
          color="pink"
          :dark="canEdit"
          bottom
          left
          fab
          small
          @click="formDialogModal = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
    <div>
      <div class="table-container-claims">
        <table class="main-table-claims" :class="{dark: dark}">
          <thead>
            <tr>
              <th>#</th>
              <th>Safety person</th>
              <th>Driver name</th>
              <th>Recruiter</th>
              <th>Date in office</th>
              <th>Company</th>
              <th>Truck</th>
              <th>Trailer</th>
              <th class="small" title="Drug test">Drug test</th>
              <th class="small" title="Acc orientation">Acc orientation</th>
              <th class="small" title="Orientation eld">Orientation eld</th>
              <th>Phone number</th>
              <th>Downpayment amount</th>
              <th>Way of downpayment</th>
              <th>SSN</th>
              <th>FEIN</th>
              <th>Company name</th>
              <th>Issue with driver</th>
              <th>Comments</th>
              <th>Active day</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!newDrivers.length">
              <td align="center" :colspan="16">No drivers for selected filters</td>
            </tr>
            <template v-else>
              <tr v-for="(driver, index) in newDrivers" :key="driver._id">
                <td>{{index + 1}}</td>
                <td>{{driver.safety_person ? driver.safety_person.name : '/'}}</td>
                <td>{{driver.driver_name}}</td>
                <td>{{driver.recruiter}}</td>
                <td>{{driver.date_in_office | formatDate}}</td>
                <td>{{driver.company}}</td>
                <td>{{driver.truck}}</td>
                <td>{{driver.trailer}}</td>
                <td>{{driver.drug_test}}</td>
                <td>{{driver.acc_orientation}}</td>
                <td>{{driver.orientation_eld}}</td>
                <td>{{driver.phone_number}}</td>
                <td>{{driver.downpayment_amount}}</td>
                <td>{{driver.way_of_downpayment}}</td>
                <td>{{driver.ssn}}</td>
                <td>{{driver.fein}}</td>
                <td>{{driver.company_name}}</td>
                <td>
                  <span v-if="driver.issue_with_driver !== ''"
                    class="d-inline-block text-wrap"
                    style="max-width: 230px;"
                  >
                    {{driver.issue_with_driver}}
                  </span>
                  <span v-else>/</span>
                </td>
                <td>
                  <span v-if="driver.comments !== ''"
                    class="d-inline-block text-wrap"
                    style="max-width: 230px;"
                  >
                    {{driver.comments}}
                  </span>
                  <span v-else>/</span>
                </td>
                <td>{{driver.active_day | formatDate}}</td>
                <td align="center">
                  <v-icon
                    :disabled="!canEdit"
                    small
                    :class="{'mr-2': driver.is_active}"
                    @click="editDriver(driver)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    v-if="driver.is_active"
                    :disabled="!canEdit"
                    small
                    @click="deleteDriver(driver)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <v-pagination
        v-model="page"
        :length="pages"
      ></v-pagination>
    </div>
    <v-dialog v-model="formDialogModal" width="800px">
      <v-card>
        <form @submit.prevent="onSubmitNewDriver" data-vv-scope="newDriver">
          <v-card-title class="lighten-4 py-4 title">
            {{form._id ? 'Edit' : 'Add'}} New driver
          </v-card-title>
          <template>
            <v-container grid-list-sm class="pa-4">
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-autocomplete :items="users"
                    v-model="form.safety_person"
                    :value="form.safety_person"
                    label="Safety person"
                    item-value="_id"
                    item-text="name"
                    hide-details="auto"
                    return-object>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    :maxlength="150"
                    v-model="form.driver_name"
                    label="Driver name"
                    name="driverName"
                    id="driverName"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    :maxlength="150"
                    v-model="form.recruiter"
                    label="Recruiter"
                    name="recruiter"
                    id="recruiter"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-menu
                    v-model="dates['date_in_office'].show"
                    :close-on-content-click="false"
                    :return-value.sync="form.date_in_office"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dates['date_in_office'].value"
                        label="Date in office"
                        name="dateInOffice"
                        id="dateInOffice"
                        readonly
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.date_in_office"
                      no-title
                      color="green lighten-1"
                      @input="closeDatePicker('date_in_office')"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    :maxlength="30"
                    v-model="form.company"
                    label="Company"
                    name="comapny"
                    id="company"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    :maxlength="8"
                    v-model="form.truck"
                    label="Truck"
                    name="truck"
                    id="truck"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-text-field
                    :maxlength="8"
                    v-model="form.trailer"
                    label="Trailer"
                    name="trailer"
                    id="trailer"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                >
                  <v-select
                    :items="['YES', 'NO']"
                    v-model="form.drug_test"
                    label="Drug test"
                    hide-details="auto">
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                >
                  <v-select
                    :items="['YES', 'NO']"
                    v-model="form.acc_orientation"
                    label="Acc orientation"
                    hide-details="auto">
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                >
                  <v-text-field
                    v-model="form.orientation_eld"
                    label="Orientation eld"
                    name="orientationEld"
                    id="orientationEld"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                >
                  <v-text-field
                    v-model="form.phone_number"
                    label="Phone number"
                    name="phoneNumber"
                    id="phoneNumber"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="form.downpayment_amount"
                    label="Downpayment amount"
                    name="downpaymentAmount"
                    id="downpaymentAmount"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="form.way_of_downpayment"
                    label="Way of downpayment"
                    name="wayOfDownpayment"
                    id="wayOfDownpayment"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-menu
                    v-model="dates['active_day'].show"
                    :close-on-content-click="false"
                    :return-value.sync="form.active_day"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dates['active_day'].value"
                        label="Active day"
                        name="activeDay"
                        id="activeDay"
                        readonly
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.active_day"
                      no-title
                      color="green lighten-1"
                      @input="closeDatePicker('active_day')"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="form.ssn"
                    label="SSN"
                    name="ssn"
                    id="ssn"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="form.fein"
                    label="FEIN"
                    name="fein"
                    id="fein"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="form.company_name"
                    label="Company name"
                    name="company_name"
                    id="company_name"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    rows="3"
                    name="issue"
                    label="Issue with driver"
                    :maxlength="250"
                    v-model="form.issue_with_driver"
                    id="issue"
                    hint="maximum 250 characters"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    rows="5"
                    name="comments"
                    label="Comments"
                    :maxlength="450"
                    v-model="form.comments"
                    id="comments"
                    hint="maximum 450 characters"
                  ></v-textarea>
                </v-col>
                <v-col cols="4" v-if="showDeleted">
                  <v-checkbox v-model="form.is_active" label="Return from deleted" />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="formDialogModal = false">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import newDriverService from '../../services/NewDriversService'

export default {
  data() {
    return {
      page: 1,
      pages: 1,
      newDrivers: [],
      showDeleted: false,
      form: {
        _id: null,
        driver_name: '',
        recruiter: '',
        date_in_office: new Date().toISOString().substr(0, 10),
        company: '',
        truck: '',
        trailer: '',
        drug_test: 'YES',
        acc_orientation: 'YES',
        orientation_eld: '',
        phone_number: '',
        downpayment_amount: '',
        way_of_downpayment: '',
        issue_with_driver: '',
        comments: '',
        active_day: new Date().toISOString().substr(0, 10),
        ssn: '',
        fein: '',
        company_name: '',
        is_active: true
      },
      formDialogModal: false,
      dates: {
        date_in_office: {
          value: this.formatDate(new Date().toISOString().substr(0, 10)),
          show: false
        },
        active_day: {
          value: this.formatDate(new Date().toISOString().substr(0, 10)),
          show: false
        }
      },
      optionsUser: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 10000000
      },
      users: []
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.permissions.SAFETY || vm.permissions.NEWDRIVERS_VIEW) {
        return true
      } else {
        vm.$router.push('/forbidden')
      }
    })
  },
  watch: {
    formDialogModal (newVal) {
      if (newVal) {
        this.$store.dispatch('user/getUsersByRoleId', 5).then(res => {
          this.users = res
        })
      }
    },
    page () {
      this.getNewDrivers()
    }
  },
  computed: {
    ...mapGetters('common', ['appName', 'loading', 'dark']),
    ...mapGetters('auth', ['user', 'permissions']),
    canEdit () {
      if (!this.user) {
        return false
      }
      if (this.permissions.SAFETY || this.permissions.NEWDRIVERS_EDIT) {
        return true
      }
      return false
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    closeDatePicker(field) {
      if (this.dates[field]) {
        this.dates[field].show = false
        this.dates[field].value = this.formatDate(this.form[field])
      }
    },
    resetFields () {
      this.form = {
        _id: null,
        driver_name: '',
        recruiter: '',
        date_in_office: new Date().toISOString().substr(0, 10),
        company: '',
        truck: '',
        trailer: '',
        safety_person: '',
        drug_test: 'YES',
        acc_orientation: 'YES',
        orientation_eld: '',
        phone_number: '',
        downpayment_amount: '',
        way_of_downpayment: '',
        issue_with_driver: '',
        comments: '',
        active_day: new Date().toISOString().substr(0, 10),
        ssn: '',
        fein: '',
        company_name: '',
        is_active: true
      }
    },
    getFilters () {
      let filters = {
        page: this.page,
        sortby: 'createdAt',
        // search: this.search,
        order: -1
      }
      if (this.showDeleted) {
        filters['is_active'] = false
      }
      return filters
    },
    getNewDrivers () {
      this.$store.dispatch('common/setLoading', true)
      newDriverService.getNewDrivers(this.getFilters()).then(res => {
        this.$store.dispatch('common/setLoading', false)
        this.pages = res.data.pages
        // this.page = res.data.page
        this.newDrivers = res.data.docs
      }).catch(err => {
        this.$store.dispatch('common/setLoading', false)
        console.log('error on get claim', err)
      })
    },
    filterDeleted () {
      this.getNewDrivers()
    },
    onSubmitNewDriver () {
      if (this.form._id) {
        newDriverService.editNewDriver(this.form).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.formDialogModal = false
            this.resetFields()
            this.getNewDrivers()
          }
        }).catch(err => {
          this.$store.dispatch('common/setLoading', false)
          console.log('error on add claim', err)
        })
      } else {
        newDriverService.addNewDriver(this.form).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.formDialogModal = false
            this.resetFields()
            this.getNewDrivers()
          }
        }).catch(err => {
          this.$store.dispatch('common/setLoading', false)
          console.log('error on add claim', err)
        })
      }
    },
    editDriver (driver) {
      this.resetFields()
      this.form._id = driver._id
      this.form.driver_name = driver.driver_name
      this.form.recruiter = driver.recruiter
      this.form.date_in_office = new Date(driver.date_in_office).toISOString().substr(0, 10)
      this.dates['date_in_office'].value = this.formatDate(new Date(driver.date_in_office).toISOString().substr(0, 10))
      this.form.company = driver.company
      this.form.truck = driver.truck
      this.form.trailer = driver.trailer
      this.form.drug_test = driver.drug_test
      this.form.acc_orientation = driver.acc_orientation
      this.form.orientation_eld = driver.orientation_eld
      this.form.phone_number = driver.phone_number
      this.form.downpayment_amount = driver.downpayment_amount
      this.form.way_of_downpayment = driver.way_of_downpayment
      this.form.issue_with_driver = driver.issue_with_driver
      this.form.comments = driver.comments
      this.form.active_day = new Date(driver.active_day).toISOString().substr(0, 10)
      this.form.ssn = driver.ssn
      this.form.fein = driver.fein
      this.form.company_name = driver.company_name
      this.dates['active_day'].value = this.formatDate(new Date(driver.active_day).toISOString().substr(0, 10))
      this.form.safety_person = driver.safety_person
      this.form.is_active = driver.is_active
      this.formDialogModal = true
    },
    deleteDriver (driver) {
      let confVal = confirm(`Are you sure you want to delete driver: ${driver.driver_name}?`)
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        newDriverService.deleteNewDriver(driver._id).then(() => {
          this.$store.dispatch('common/setLoading', false)
          this.getNewDrivers()
        }).catch(err => {
          this.$store.dispatch('common/setLoading', false)
          console.log('error on delete claim', err)
        })
      }
    }
  },
  mounted () {
    this.getNewDrivers()
  }
}
</script>

<style lang="scss" scoped>
.newdrivers {
  .left-side {
    .v-input--selection-controls {
      margin-top: 0px !important;
    }
  }
}
.small {
  white-space: nowrap;
  max-width: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>