<template>
  <div class="history-wraper" :class="{small: small, 'no-data': !changes.length}">
    <!-- <div class="note-wrapper pa-2">
      <p class="caption" v-html="truckNote"></p>
    </div>
    <v-divider /> -->
    <v-list v-if="!loadingHi && changes.length" class="truck-history">
      <template v-for="(item, index) of changes">
        <v-list-item :key="`history-${item._id}`">
          <v-list-item-content>
            <div class="history-actions">
              <v-icon :disable="!canEdit" small :color="canEdit ? 'primary' : ''" @click="editHistory(item)">mdi-pencil</v-icon>
              <v-icon :disable="!canEdit" small :color="canEdit ? 'error' : ''" @click="deleteHistory(item)">mdi-delete</v-icon>
            </div>
            <v-list-item-subtitle class="history-text" v-html="historyTextFormated(item)">
            </v-list-item-subtitle>
            <!-- <v-textarea v-if="noteId && noteId === item._id"
              v-model="note"
              @keydown.enter="handleEnter($event)"
              ref="comtext"
              :autofocus="true"
              name="note"
              label="Note"
              :hide-details="'auto'"
              rows="3"
              counter
              solo
              clearable
              clear-icon="mdi-close-circle"
              :append-icon="note && note.length ? 'mdi-checkbox-marked-circle' : ''"
              placeholder="Press CTRL + Enter to save..."
              :no-resize="true"
              @click:clear="onCleareNote"
              @click:append="addNote"
            /> -->
            <div class="bottom-history">
              <span title="The user who made changes" class="user-email">{{item.user}}</span>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="`divider-${index}`"
          v-if="index < changes.length - 1"
        ></v-divider>
      </template>
    </v-list>
    <p class="no-history" v-else-if="!loadingHi && !changes.length">No history for truck {{truckNumber}}</p>
    <v-progress-circular
      v-else
      indeterminate
      class="trailer-history-loader"
      color="primary"
    ></v-progress-circular>
    <v-btn
      :disabled="!canAddHistory"
      v-if="type === 'truck'"
      @click="showHistoryDialog = true"
      elevation="2"
      small
      :block="changes.length !== 0"
      color="success"
    >Add history</v-btn>
    <v-dialog v-model="showHistoryDialog" persistent width="400">
      <v-card>
        <form @submit.prevent="onAddTruckHistory" data-vv-scope="history">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            Add truck history - {{truckNumber}}
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex md6 xs6 v-if="!form._id">
                <v-switch v-model="form.is_third_party" hide-details="auto" @change="onChangeThirdParty">
                  <template v-slot:label>
                    <span class="text">Third party</span>
                  </template>
                </v-switch>
              </v-flex>
              <v-flex md6 xs6 v-if="!form._id">
                <v-switch :disabled="form.is_third_party" v-model="forCurrentDriver" hide-details="auto" @change="populateDriver">
                  <template v-slot:label>
                    <span class="text">Current driver</span>
                  </template>
                </v-switch>
              </v-flex>
              <template v-if="!form._id">
                <v-flex md12 xs12 v-if="!form.is_third_party" class="mb-3">
                  <v-autocomplete
                    :disabled="forCurrentDriver"
                    clearable
                    v-model="form.current_driver"
                    :items="driverListFormated"
                    return-object
                    item-text="name"
                    item-id="id"
                    item-disabled="disabled"
                    label="Driver"
                    :search-input.sync="searchDrivers"
                    hide-details="auto"
                    hide-no-data
                    :loading="loading"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    append-outer-icon="mdi-plus"
                    :filter="clientFilterDrivers"
                    name="driver"
                    @click:append-outer="showDriverForm"
                    @change="onSelectDriver"
                    @click:clear="form.current_driver = null"
                  >
                    <template v-slot:item="{item}">
                      <span>
                        {{item.name}}
                      </span>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex v-else md12 xs12 class="mb-3">
                  <v-autocomplete
                    clearable
                    :items="owners"
                    v-model="form.owner"
                    :value="form.owner"
                    label="Owner"
                    item-value="_id"
                    item-text="name"
                    hide-details="auto"
                    return-object
                  >
                    <template v-slot:item="{item}">
                      <span :class="{'is-company': item.is_company}">
                        {{item.name}}
                        <span v-if="item.is_company">Third party</span>
                      </span>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </template>
              <v-flex xs6>
                <v-menu
                    ref="from"
                    v-model="menus.from"
                    :close-on-content-click="false"
                    :return-value.sync="form.date_from"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="form.date_from"
                        label="From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        dense
                        hide-details="auto"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="form.date_from"
                      no-title
                      scrollable
                      @change="$refs.from.save(form.date_from)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menus.from = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.from.save(form.date_from)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-menu
                    ref="to"
                    v-model="menus.to"
                    :close-on-content-click="false"
                    :return-value.sync="form.date_to"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="form.date_to"
                        label="To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        hide-details="auto"
                        clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="form.date_to"
                      no-title
                      scrollable
                      @change="$refs.to.save(form.date_to)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menus.to = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.to.save(form.date_to)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6 class="mb-2 mt-2">
                <v-menu
                    ref="fromSecond"
                    v-model="menus.fromSecond"
                    :close-on-content-click="false"
                    :return-value.sync="form.date_from_second"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="form.date_from_second"
                        label="From second"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        dense
                        hide-details="auto"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="form.date_from_second"
                      no-title
                      scrollable
                      @change="$refs.fromSecond.save(form.date_from_second)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menus.fromSecond = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.fromSecond.save(form.date_from_second)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="form.phoneNumber"
                  name="phone"
                  label="Phone number"
                  hide-details="auto"
                  dense
                  clearable
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="form.note"
                  name="note"
                  label="Note"
                  :hide-details="'auto'"
                  rows="10"
                  counter
                  clearable
                  :no-resize="true"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeHistoryDialog">Cancel</v-btn>
            <v-btn v-if="form._id" color="info" :loading="loading" :disabled="(loading || !canEdit)" type="submit">Edit</v-btn>
            <v-btn v-else color="info" :loading="loading" :disabled="(loading || isSaveDisabled)" type="submit">Add</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="formDialogModal" width="800px">
      <v-card>
        <drivers-form @onClose="formDialogModal = false" :driver_id="null"></drivers-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {mapGetters, mapActions} from 'vuex'
import callsService from '@/services/CallsService'
import TrucksService from '../../services/TruckService'
import debounce from 'lodash/debounce'
import DriversForm from '../Drivers/Form'

export default {
  name: 'TruckHistory',
  props: ['id', 'type', 'small', 'truckNumber', 'truckNote', 'currentDriver'],
  components: {
    DriversForm
  },
  computed: {
    ...mapGetters('auth', ['user', 'permissions']),
    ...mapGetters('common', ['loading', 'dark']),
    canEdit() {
      return ['SUPERADMIN', 'ADMIN', 'TRUCK_DEPARTMENT'].indexOf(this.user.roles[0].role_name) !== -1
    },
    driverListFormated() {
      return this.drivers.map(item => {
        item.disabled = false
        // item.text = `${item.truck_id} - ${item.name}`
        return item
      })
    },
    canAddHistory() {
      return ['SUPERADMIN', 'ADMIN', 'TRUCK_DEPARTMENT'].indexOf(this.user.roles[0].role_name) !== -1
    },
    isSaveDisabled() {
      if (!this.form.note.length && !this.form.date_from ) return true
      else if (this.form.is_third_party && !this.form.owner) return true

      return false
    }
  },
  data() {
    return {
      loadingHi: true,
      changes: [],
      noteId: null,
      note: '',
      showHistoryDialog: false,
      owners: [],
      drivers: [],
      menu: false,
      menu2: false,
      menus: {
        from: false,
        to: false,
        fromSecond: false
      },
      form: {
        _id: null,
        truck: null,
        owner: null,
        current_driver: null,
        second_driver: null,
        is_third_party: false,
        date_from: null,
        date_from_second: null,
        date_to: null,
        phoneNumber: '',
        note: ''
      },
      searchDrivers: '',
      formDialogModal: false,
      forCurrentDriver: false
    }
  },
  mounted() {
    this.getHistory()
  },
  watch: {
    searchDrivers(val) {
      if (val && !this.forCurrentDriver) this.debounceGetDrivers(val, 'drivers')
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    debounceGetDrivers: debounce(function(val, field) {
      if (val && val.length) {
        callsService
          .getMergedDrivers(val, true)
          .then((res) => {
            if (res.data.status === 'OK') {
              this[field] = res.data.drivers
            }
          })
          .catch((err) => {
            console.log('drivers error search', err)
            this.drivers = []
          })
      } else {
        this.drivers = []
      }
    }, 400),
    getHistory () {
      this.loadingHi = true
      TrucksService.getTruckChanges(this.id, this.type).then(res => {
        this.loadingHi = false
        if (res.data.status === 'OK') {
          this.changes = res.data.changes
        }
      })
    },
    // addEditNote(item) {
    //   if (!this.canEdit) return

    //   if (this.noteId !== item._id) {
    //     this.noteId = item._id
    //     this.note = item.note ? item.note : ''
    //   } else {
    //     this.noteId = null
    //     item.note = ''
    //   }
    // },
    deleteHistory(item) {
      if (!this.canEdit) return
      let confVal = confirm('Are you sure you want to delete history')
      if (confVal) {
        TrucksService.deleteHistory(item._id).then((res) => {
          if (res.data.status === 'OK') {
            let index = this.changes.findIndex(change => change._id === item._id)
            this.changes.splice(index, 1)
            this.setMessage('History successfully deleted')
          }
        }).catch((err) => {
          console.log('err on add note', err)
        })
      }
    },
    // addNote() {
    //   if (this.note.length <= 600) {
    //     TrucksService.addNotToHistory(this.noteId, this.note).then((res) => {
    //       if (res.data.status === 'OK') {
    //         let index = this.changes.findIndex(change => change._id === res.data.doc._id)
    //         this.changes.splice(index, 1, res.data.doc)
    //         this.setMessage('Note successfully added / updated')
    //         this.onCleareNote()
    //       }
    //     }).catch((err) => {
    //       console.log('err on add note', err)
    //     })
    //   } else {
    //     this.setMessage('The note cannot be longer than 250 characters!')
    //   }
    // },
    // handleEnter (e) {
    //   if (e.ctrlKey) {
    //     this.addNote()
    //   }
    // },
    // onCleareNote() {
    //   this.noteId = null
    //   this.note = ''
    // },
    clientFilterDrivers (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    clearForm() {
      this.form = {
        _id: null,
        truck: null,
        date_from: null,
        date_from_second: null,
        date_to: null,
        phoneNumber: '',
        note: ''
      }
    },
    closeHistoryDialog() {
      this.showHistoryDialog = false
      this.clearForm()
    },
    formatMergedDriverName() {
      if (this.form.owner && this.form.is_third_party) {
        return this.form.owner.name
      }

      return this.form.current_driver ? this.form.current_driver.name : ''
    },
    editHistory(item) {
      this.form._id = item._id
      this.form.is_third_party = item.is_third_party
      this.form.phoneNumber = item.phone_number
      this.form.note = item.note
      this.form.date_from = item.date_from ? moment(item.date_from).format('YYYY-MM-DD') : null
      this.form.date_from_second = item.date_from_second ? moment(item.date_from_second).format('YYYY-MM-DD') : null
      this.form.date_to = item.date_to ? moment(item.date_to).format('YYYY-MM-DD') : null
      this.showHistoryDialog = true
    },
    onAddTruckHistory() {
      let data = {
        _id: this.form._id,
        id: this.id,
        type: this.type,
        is_third_party: this.form.is_third_party,
        name: this.truckNumber,
        subject: this.formatMergedDriverName(),
        date_from: this.form.date_from,
        date_from_second: this.form.date_from_second,
        date_to: this.form.date_to,
        phone_number: this.form.phoneNumber,
        note: this.form.note
      }

      if (data._id) {
        this.$store.dispatch('trucks/editHistory', data).then(res => {
          if (res.data.status === 'OK') {
            this.setMessage('Trailer history successfully edited')
            this.closeHistoryDialog()
            this.getHistory()
          }
        })
      } else {
        this.$store.dispatch('trucks/addHistory', data).then(res => {
          if (res.data.status === 'OK') {
            this.setMessage('Trailer history successfully added')
            this.closeHistoryDialog()
            this.getHistory()
          }
        })
      }
    },
    getOwners() {
      this.$store.dispatch('trailers/getAllOwners').then(res => {
        this.owners = res
      })
    },
    onChangeThirdParty() {
      if (this.form.is_third_party) {
        this.getOwners()
      }
      this.form.owner = null
      this.form.current_driver = null
      this.forCurrentDriver = false
    },
    onSelectDriver(driver) {
      if (driver && driver.phone_number) this.form.phoneNumber = driver.phone_number
    },
    showDriverForm() {
      this.formDialogModal = true
    },
    populateDriver() {
      if (this.forCurrentDriver && this.currentDriver) {
        this.drivers.push(this.currentDriver)
        this.form.current_driver = this.currentDriver
        this.form.phoneNumber = this.currentDriver.phone_number ? this.currentDriver.phone_number : ''
      }
    },
    historyTextFormated(item) {
      const textPrefix = item.is_third_party ? 'uzeo' : 'vozio'
      const phoneNumber = item.phone_number ? `, PN: ${item.phone_number}` : ''
      const note = item.note ? `${item.subject? ', ' : ''}${item.note}` : ''
      const secondDate = item.date_from_second ? ` - <strong>${this.$options.filters.formatDateUSA(item.date_from_second, 'MM/DD/YYYY')}</strong>` : ''
      const toDate = item.date_to ? `do <strong>${this.$options.filters.formatDateUSA(item.date_to, 'MM/DD/YYYY')}</strong>` : ''
      const dates = item.date_from ? `od <strong>${this.$options.filters.formatDateUSA(item.date_from, 'MM/DD/YYYY')}</strong>${secondDate} ${toDate}` : ''

      const formatedSubjectWithPrefix = item.subject ? `${textPrefix} <strong>${this.$options.filters.driverName(item.subject)}</strong>` : ''

      return `${formatedSubjectWithPrefix} ${dates}${phoneNumber}${note}`
    }
  }
}
</script>

<style lang="scss" scoped>
.history-wraper {
  min-height: 200px;
  &.no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &.small {
    .truck-history {
      .history-text {
        font-size: 14px;
      }
    }
  }

  .truck-history {
    max-height: 400px;
    min-height: 371px;
    overflow-y: scroll;
    .v-list-item {
      position: relative;

      .history-actions {
        position: absolute;
        right: 6px;
        top: 0px;
      }

      .history-text {
        white-space: pre-wrap;
        padding-right: 21px;

        .date-highlight {
          font-size: 12px;
          color: #2196f3;
          font-weight: 600;
        }
      }

      .bottom-history {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .note {
          text-align: left;
          font-size: 13px;
          font-style: italic;
          padding-left: 2px;
        }
        .user-email {
          text-align: right;
          font-size: 11px;
          color: #2196f3;
        }
      }
      
    }
  }

  .no-history {
    text-align: center;
  }

  .trailer-history-loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
}

.is-company {
  span {
    position: absolute;
    left: 16px;
    bottom: 0px;
    font-size: 11px;
    color: #ff5252 !important;
  }
}

.note-wrapper {
  p {
    white-space: break-spaces;
  }
}
</style>