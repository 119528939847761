<template>
  <div class="LeaseCompanies">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :options="options"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      @update:options="handleOptionsChange"
      class="elevation-1"
      multi-sort
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lease Companies</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <table-filters :filterable-fields="tableFilters" table-key="lease-companies" @change="onFiltersChange"></table-filters>
          <v-spacer></v-spacer>

          <v-btn
            small
            color="success"
            class="mr-2"
            dark
            :loading="loading"
            @click="exportData"
          >
            Export <v-icon small>mdi-file-excel-outline</v-icon>
          </v-btn>

          <v-btn small color="primary" dark @click="handleNewItem">
            New Item
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.toll_violation="{ item }">
        <strong>{{ item.toll_violation ? 'Yes' : 'No' }}</strong>
      </template>

      <template v-slot:item.maintenance="{ item }">
        <strong>{{ item.maintenance ? 'Yes' : 'No' }}</strong>
      </template>

      <template v-slot:item.name="{ item }">
        <td class="d-flex align-center">
          <router-link
            class="mr-2 flex-grow-1 text-center"
            :to="`/lease/units?company=${item.name}`"
          >
            <strong> {{ item.name }}</strong></router-link
          >
          <v-icon @click="handleShowChart(item)" small>mdi-chart-bar</v-icon>
        </td>
      </template>

      <template v-slot:item.regruting="{ item }">
        <strong>{{ item.regruting ? 'Yes' : 'No' }}</strong>
      </template>

      <template v-slot:item.insurance="{ item }">
        <strong>{{ item.insurance ? 'Yes' : 'No' }}</strong>
      </template>

      <template v-slot:item.note="{ item }">
        {{ item.note && item.note.text }}
      </template>

      <template v-slot:item.trucks="{ item }">
        <units-info type="truck" :units="item.units">{{
          item.trucks_unit_count
        }}</units-info>
      </template>

      <template v-slot:item.trailers="{ item }">
        <units-info type="trailer" :units="item.units">{{
          item.trailers_unit_count
        }}</units-info>
      </template>

      <template v-slot:item.debt> TODO: </template>

      <!-- <template v-slot:item.billing_period> TODO: </template> -->

      <template v-slot:item.loan="{ item }">
        {{ getLoanValue(item.units) | money }}
      </template>

      <template v-slot:item.sales_manager="{ item }">
        <field-history
          :companyId="item._id"
          item-value="name"
          type="SALES_MANAGER_CHANGE"
          >{{ item.sales_manager && item.sales_manager.name }}</field-history
        >
      </template>

      <template v-slot:item.team_lead="{ item }">
        <field-history
          :companyId="item._id"
          item-value="name"
          type="TEAM_LEAD_CHANGE"
          >{{ item.team_lead && item.team_lead.name }}</field-history
        >
      </template>

      <template v-slot:item.acc_person="{ item }">
        <field-history
          :companyId="item._id"
          item-value="name"
          type="ACC_PERSON_CHANGE"
        >
          <user-info v-if="item.acc_person" :user="item.acc_person" />
        </field-history>
      </template>

      <template v-slot:item.comments="{ item }">
        <lease-comments
          :comments="item.comments"
          :id="item._id"
          type="company"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <!-- Stats -->
    <v-dialog v-model="statsDialog" max-width="500px">
      <v-card>
        <lease-stats
          v-if="statsCompany"
          :company-id="statsCompany._id"
          :company-name="statsCompany.name"
        />
      </v-card>
    </v-dialog>

    <!-- Delete item -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add/Edit item -->
    <v-dialog v-model="dialog" max-width="1080px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="4"
                style="border-right: 2px solid rgba(0, 0, 0, 0.1)"
              >
                <span class="caption">Company information</span>
                <v-text-field
                  v-model="form.name"
                  label="Name*"
                  hide-details
                  small
                />
                <v-text-field
                  v-model="form.address"
                  label="Address*"
                  hide-details
                  small
                />
                <v-text-field
                  v-model="form.city"
                  label="City*"
                  hide-details
                  small
                />
                <v-text-field
                  v-model="form.state"
                  label="State*"
                  hide-details
                  small
                />
                <v-text-field
                  v-model="form.zip"
                  label="Zip*"
                  hide-details
                  small
                />
                <v-text-field
                  v-model="form.mc"
                  label="MC*"
                  hide-details
                  small
                />
                <v-text-field
                  v-model="form.dot"
                  label="DOT*"
                  hide-details
                  small
                />
                <v-text-field
                  v-model="form.fein"
                  label="FEIN*"
                  hide-details
                  small
                />
              </v-col>

              <v-col
                cols="8"
                style="border-bottom: 2px solid rgba(0, 0, 0, 0.1)"
              >
                <span class="caption">Contact</span>
                <contacts-form
                  @change="handleContactsChanged"
                  :initial-contacts="form.contacts"
                />
              </v-col>

              <v-col
                cols="4"
                style="border-right: 2px solid rgba(0, 0, 0, 0.1)"
              >
                <div
                  class="d-flex align-center justify-space-between"
                  style="height: 48px"
                >
                  <v-checkbox
                    class="flex-grow-0 mr-2"
                    v-model="form.toll_violation"
                    @change="handleTollViolationChange"
                    label="Toll Violation"
                    hide-details
                    small
                  />

                  <v-text-field
                    class="flex-grow-0"
                    :value="`$${form.toll_violation_fee || 0}`"
                    label="Fee"
                    style="max-width: 100px"
                    disabled
                    hide-details
                    small
                  />
                </div>

                <div
                  class="d-flex align-center justify-space-between"
                  style="height: 48px"
                >
                  <v-checkbox
                    class="flex-grow-0 mr-2"
                    v-model="form.maintenance"
                    label="Maintenance"
                    hide-details
                    small
                  />

                  <v-text-field
                    v-if="form.maintenance"
                    class="flex-grow-0"
                    v-model="form.maintenance_percent"
                    label="Percent"
                    style="max-width: 100px"
                    hide-details
                    type="number"
                    small
                  />
                </div>

                <div
                  class="d-flex align-center justify-space-between"
                  style="height: 48px"
                >
                  <v-checkbox
                    class="flex-grow-0 mr-2"
                    v-model="form.regruting"
                    label="Regruting"
                    hide-details
                    small
                  />

                  <v-text-field
                    v-if="form.regruting"
                    class="flex-grow-0"
                    v-model="form.regruting_cost"
                    label="Cost"
                    style="max-width: 100px"
                    hide-details
                    type="number"
                    small
                  />
                </div>

                <div
                  class="d-flex align-center justify-space-between"
                  style="height: 48px"
                >
                  <v-checkbox
                    class="flex-grow-0 mr-2"
                    v-model="form.insurance"
                    label="Insurance"
                    hide-details
                    small
                  />

                  <v-text-field
                    v-if="form.insurance"
                    class="flex-grow-0"
                    v-model="form.insurance_percent"
                    label="Percent"
                    style="max-width: 100px"
                    hide-details
                    type="number"
                    small
                  />
                </div>

                <div
                  class="d-flex align-center justify-space-between"
                  style="height: 48px"
                >
                  <v-checkbox
                    class="flex-grow-0 mr-2"
                    v-model="form.fuel"
                    label="Fuel"
                    hide-details
                    small
                  />

                  <v-text-field
                    v-if="form.fuel"
                    class="flex-grow-0"
                    v-model="form.fuel_cost"
                    label="Cost"
                    style="max-width: 100px"
                    hide-details
                    type="number"
                    small
                  />
                </div>
              </v-col>

              <v-col cols="8">
                <span class="caption">People</span>
                <v-row class="mb-10">
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="form.sales_manager"
                      :items="salesManagers"
                      :loading="loading"
                      :search-input.sync="searchSalesManagers"
                      hide-no-data
                      hide-selected
                      item-text="name"
                      label="Sales Manager"
                      placeholder="Sales Manager"
                      hide-details
                      return-object
                      small
                    />
                  </v-col>

                  <v-col cols="4">
                    <v-autocomplete
                      v-model="form.acc_person"
                      :items="accPersons"
                      :loading="loading"
                      :search-input.sync="searchAccPersons"
                      hide-no-data
                      hide-selected
                      item-text="name"
                      label="Acc Person"
                      placeholder="Acc Person"
                      hide-details
                      return-object
                      small
                    />
                  </v-col>

                  <v-col cols="4">
                    <v-autocomplete
                      v-model="form.team_lead"
                      :items="teamLeads"
                      :loading="loading"
                      :search-input.sync="searchTeamLead"
                      hide-no-data
                      hide-selected
                      item-text="name"
                      label="Team Lead"
                      placeholder="Team Lead"
                      hide-details
                      return-object
                      small
                    />
                  </v-col>
                </v-row>

                <span class="caption">Files</span>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-if="typeof form.ach_file_path === 'string'"
                      v-model="form.ach_file_path"
                      @click:prepend="handleOpenFile(form.ach_file_path)"
                      clearable
                      readonly
                      label="ACH"
                      prepend-icon="mdi-file"
                      class="LeaseCompanies__file-text"
                    />
                    <v-file-input
                      v-else
                      v-model="form.ach_file_path"
                      label="ACH"
                    />
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-if="typeof form.void_check_file_path === 'string'"
                      v-model="form.void_check_file_path"
                      @click:prepend="handleOpenFile(form.void_check_file_path)"
                      clearable
                      readonly
                      prepend-icon="mdi-file"
                      label="Void Check"
                      class="LeaseCompanies__file-text"
                    />
                    <v-file-input
                      v-else
                      v-model="form.void_check_file_path"
                      label="Void Check"
                    />
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      v-if="typeof form.cdl_file_path === 'string'"
                      v-model="form.cdl_file_path"
                      @click:prepend="handleOpenFile(form.cdl_file_path)"
                      clearable
                      readonly
                      prepend-icon="mdi-file"
                      label="CDL"
                      class="LeaseCompanies__file-text"
                    />
                    <v-file-input
                      v-else
                      v-model="form.cdl_file_path"
                      label="CDL"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="form.note" label="Note" hide-details />
                <div v-if="form.noteInfo">
                  <span class="LeaseCompanies__note-person"
                    >{{ form.noteInfo.user_name }} ({{
                      form.noteInfo.user_email
                    }})</span
                  >
                  <span class="LeaseCompanies__note-time">{{
                    formatTime(form.noteInfo.entered)
                  }}</span>
                </div>
              </v-col>
              <v-col cols="12">
                <comments
                  :comments="form.comments"
                  :loading="loading"
                  cancel-text="Clear"
                  :show-actions="true"
                  :with-form="true"
                  @delete="handleDeleteComment"
                  @save="saveComment"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :disabled="submitDisabled"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { b64toBlob, downloadFile } from '../../utils'
import { commentsMixin } from './CommentsMixin'
import service from '../../services/LeaseCompaniesService'
import usersService from '../../services/UsersService'
import FieldHistory from './FieldHistory.vue'
import UserInfo from './UserInfo.vue'
import UnitsInfo from './UnitsInfo.vue'
import ContactsForm from './ContactsForm.vue'
import LeaseComments from './LeaseComments.vue'
import TableFilters from '../Common/TableFilters.vue'
import Comments from '../Common/Comments.vue'
import LeaseStats from './LeaseStats.vue'

import {
  FOOTER_PROPS
} from '../../utils/constants.js'

const INITIAL_FORM = {
  name: null,
  address: null,
  city: null,
  state: null,
  zip: null,
  mc: null,
  dot: null,
  fein: null,
  is_active: true,

  contacts: [],

  toll_violation: false,
  toll_violation_fee: 0,

  maintenance: false,
  maintenance_percent: null,

  regruting: false,
  regruting_cost: null,

  insurance: false,
  insurance_percent: null,

  fuel: false,
  fuel_cost: null,

  sales_manager: null,
  acc_person: null,
  team_lead: null,

  comments: [],

  note: null,
}

const REQUIRED = [
  'name',
  'address',
  'city',
  'state',
  'zip',
  'mc',
  'dot',
  'fein',
]

const HEADERS = [
  {
    text: 'FIRMA',
    value: 'name',
    sortable: true,
  },
  { text: 'Trucks', value: 'trucks', sortable: false },
  { text: 'Trailers', value: 'trailers', sortable: false },
  {
    text: 'Sales Manager',
    value: 'sales_manager',
    sortable: true,
  },
  { text: 'Acc Person', value: 'acc_person', sortable: true },
  { text: 'Team Lead', value: 'team_lead', sortable: true },
  // { text: 'Billing Period', value: 'billing_period', sortable: true },
  { text: 'Loan', value: 'loan', sortable: false },
  { text: 'DEBT', value: 'debt', sortable: false },
  { text: 'Note', value: 'note', sortable: true },
  { text: 'Comments', value: 'comments', sortable: false },
  { text: 'Actions', value: 'actions', sortable: false, width: 60 },
]

const TABLE_FILTERS = [
  {
    name: 'name',
    label: 'Name',
    type: 'text'
  },
  {
    name: 'sales_manager.name',
    label: 'Sales manager',
    type: 'text'
  },
  {
    name: 'acc_person.name',
    label: 'Acc person',
    type: 'text'
  },
  {
    name: 'team_lead.name',
    label: 'Team lead',
    type: 'text'
  },
  {
    name: 'trucks_unit_count',
    label: 'Trucks',
    type: 'number'
  },
  {
    name: 'trailers_unit_count',
    label: 'Trailers',
    type: 'number'
  }
]

export default {
  components: {
    FieldHistory,
    UserInfo,
    UnitsInfo,
    ContactsForm,
    LeaseComments,
    TableFilters,
    Comments,
    LeaseStats,
  },
  mixins: [commentsMixin],
  data: () => ({
    commentsService: 'companies',
    dialog: false,
    dialogDelete: false,

    footerProps: FOOTER_PROPS,
    headers: HEADERS.map((h) => ({ ...h, align: 'center' })),

    items: [],
    totalItems: 0,
    loading: false,

    editId: null,
    form: { ...INITIAL_FORM },
    options: { page: 1, itemsPerPage: 10, sortBy: [], sortDesc: [] },
    filters: null,

    salesManagers: [],
    salesManagerTerm: '',

    accPersons: [],
    accPersonTerm: '',

    teamLeads: [],
    teamLeadTerm: '',

    nameFilter: '',

    statsCompany: null,
    statsDialog: false,
    tableFilters: [...TABLE_FILTERS],
  }),

  computed: {
    ...mapGetters('auth', ['user']),

    formTitle() {
      return !this.editId ? 'New Item' : 'Edit Item'
    },

    submitDisabled() {
      for (const field of REQUIRED) if (!this.form[field]) return true
      return false
    },

    searchTeamLead: {
      get() {
        return this.teamLeadTerm
      },
      set(val) {
        if (val !== this.teamLeadTerm) {
          this.teamLeadTerm = val
          this.searchPersons(val, 'teamLeads', 'SUPERADMIN')
        }
      },
    },

    searchSalesManagers: {
      get() {
        return this.salesManagerTerm
      },
      set(val) {
        if (val !== this.salesManagerTerm) {
          this.salesManagerTerm = val
          this.searchPersons(val, 'salesManagers', 'SUPERADMIN')
        }
      },
    },

    searchAccPersons: {
      get() {
        return this.accPersonTerm
      },
      set(val) {
        if (val !== this.accPersonTerm) {
          this.accPersonTerm = val
          this.searchPersons(val, 'accPersons', 'SUPERADMIN')
        }
      },
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    statsDialog(val) {
      val || this.closeStatsDialog()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  methods: {
    closeStatsDialog() {
      this.statsCompany = null
      this.statsDialog = false
    },

    handleShowChart({ _id, name }) {
      this.statsCompany = { _id, name }
      this.statsDialog = true
    },

    searchPersons: debounce(async function (val, prop, role) {
      if (val)
        try {
          this.loading = true
          const { data } = await usersService.getByRole(val, role)
          if (Array.isArray(data.docs)) this[prop] = data.docs
        } catch (error) {
          console.error('getUsersByRole()', error)
          this[prop] = []
        } finally {
          this.loading = false
        }
    }, 200),

    onFiltersChange(filters) {
      this.filters = filters
      this.fetchItems()
    },

    async fetchItems() {
      this.loading = true
      try {
        const { data } = await service.getItems(this.options, this.filters)
        this.totalItems = data.total
        this.items = data.docs
      } catch (error) {
        console.error('fetchItems()', error)
      } finally {
        this.loading = false
      }
    },
    async exportData() {
      this.loading = true
      await service.export(this.options).then((file) => {
        this.loading = false
        const blob = b64toBlob(
          file.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        )
        downloadFile(blob, 'lease_companies_export')
      })
    },

    handleContactsChanged(v) {
      this.form.contacts = [...v]
    },

    handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      this.options.sortBy = o.sortBy
      this.options.sortDesc = o.sortDesc
      this.fetchItems()
    },

    handleNewItem() {
      this.dialog = true
    },

    handleTollViolationChange(v) {
      this.form.toll_violation_fee = v ? 50 : 0
    },

    editItem(item) {
      const { _id, note, ...rest } = item
      const form = rest

      if (note) {
        const { text, ...noteInfo } = note
        Object.assign(form, {
          note: text,
          noteInfo,
        })
      }

      this.editId = _id
      this.form = Object.assign({}, form)
      this.dialog = true
    },

    formatTime(v) {
      return moment(v).format('MM/DD/YYYY, h:mm:ss a')
    },

    deleteItem(item) {
      const { _id, note, ...rest } = item
      const form = rest

      if (note) {
        const { text, ...noteInfo } = note
        Object.assign(form, {
          note: text,
          noteInfo,
        })
      }

      this.editId = _id
      this.form = Object.assign({}, form)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      try {
        await service.deleteItem(this.editId)
        await this.fetchItems()
        this.closeDelete()
      } catch (error) {
        console.error('deleteItemConfirm()', error)
      } finally {
        this.loading = false
      }
    },

    resetForm() {
      this.form = Object.assign({}, INITIAL_FORM)
      this.editId = null
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.form = Object.assign({}, INITIAL_FORM)
        this.editId = null
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.form = Object.assign({}, INITIAL_FORM)
        this.editId = null
      })
    },

    getLoanValue(units) {
      let loan = 0
      if (units && units.length) {
        for (const u of units) if (u.type === 'LOAN') loan += u.total_amount
      }
      return loan
    },

    handleOpenFile(file) {
      if (file) window.open(file, '_newtab')
    },

    prepareForm() {
      const formData = new FormData()

      for (const key in this.form) {
        if (this.form[key]) {
          switch (key) {
          case 'ach_file_path':
          case 'void_check_file_path':
          case 'cdl_file_path':
            if (typeof this.form[key] == 'string')
              formData.append(key, this.form[key])
            else formData.append(key, this.form[key], this.form[key].name)
            break

          case 'contacts':
          case 'comments':
          case 'sales_manager':
          case 'team_lead':
          case 'acc_person':
            formData.append(key, JSON.stringify(this.form[key]))
            break

          default:
            formData.append(key, this.form[key])
            break
          }
        }
      }

      return formData
    },

    async save() {
      this.loading = true
      try {
        const form = this.prepareForm()
        if (this.editId) await service.editItem(this.editId, form)
        else await service.addItem(form)
        await this.fetchItems()
        this.close()
      } catch (error) {
        console.error('save()', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.LeaseCompanies {
  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      position: relative;
      padding: 0 6px !important;
    }
  }

  &__note-person {
    color: rgb(33, 150, 243);
    font-size: 11px;
    margin-right: 5px;
  }

  &__note-time {
    font-size: 11px;
  }

  &__file-text {
    .v-input__icon--prepend {
      cursor: pointer;
    }
  }
}
</style>
