<template>
  <div class="EmploymentHistoriesList">
    <v-list v-if="showHistory" class="EmploymentHistoriesList__list">
      <template v-for="(item, index) of employmentHistories">
        <v-list-item :key="`field-history-${item._id}`">
          <v-list-item-content class="EmploymentHistoriesList__item-content">
            <div class="EmploymentHistoriesList__times">
              <v-list-item-subtitle>
                <strong>Hired:</strong>
                {{ formatDate(item.hired_date) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>Terminated:</strong>
                {{
                  item.terminated_date
                    ? formatDate(item.terminated_date)
                    : 'No date'
                }}
              </v-list-item-subtitle>
            </div>

            <div
              class="EmploymentHistoriesList__actions"
              v-if="showActions(item)"
            >
              <v-icon
                small
                color="primary"
                @click="handleEditEmploymentHistory(item)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                color="error"
                @click="handleDeleteEmploymentHistory(item._id)"
                >mdi-delete</v-icon
              >
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="`divider-${index}`"
          v-if="index < employmentHistories.length - 1"
        ></v-divider>
      </template>
    </v-list>

    <p class="EmploymentHistoriesList__no-history" v-if="noHistory">
      No previous employments
    </p>

    <v-progress-circular
      v-if="loading"
      indeterminate
      class="EmploymentHistoriesList__loader"
      color="primary"
    ></v-progress-circular>

    <v-btn
      @click="handleAddNewInspection"
      class="mt-1"
      block
      elevation="2"
      small
      color="success"
      >Add Employment History</v-btn
    >

    <v-dialog v-model="showDialog" width="400px" persistent>
      <v-card>
        <form @submit.prevent="handleSubmitForm" data-vv-scope="history">
          <v-card-title class="lighten-4 py-4 title">
            {{ this.form._id ? 'Edit' : 'Add' }} Employment History
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-menu
                  ref="hiredDatePicker"
                  v-model="hiredDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="form.hired"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.hired"
                      label="Hired date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.hired" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="hiredDatePicker = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.hiredDatePicker.save(form.hired)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12>
                <v-menu
                  ref="terminatedDatePicker"
                  v-model="terminatedDatePicker"
                  :close-on-content-click="false"
                  :return-value.sync="form.terminated"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.terminated"
                      label="Terminated date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.terminated" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="terminatedDatePicker = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.terminatedDatePicker.save(form.terminated)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="handleCloseDialog"
              >Cancel</v-btn
            >
            <v-btn
              color="info"
              :loading="loading"
              :disabled="loading"
              type="submit"
              >{{ this.form._id ? 'Edit' : 'Add' }}</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

import service from '../../services/EmploymentHistory'

export default {
  name: 'EmploymentHistoriesList',

  props: ['driver'],

  data() {
    return {
      loading: false,
      showDialog: false,
      employmentHistories: [],

      hiredDatePicker: false,
      terminatedDatePicker: false,

      form: {
        _id: null,
        driver_name: this.driver.name,
        company: this.driver.company,
        hired: this.formatDate(),
        terminated: null,
        driver_id: this.driver._id,
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    showHistory() {
      if (this.loading) return false
      return (
        Array.isArray(this.employmentHistories) &&
        this.employmentHistories.length > 0
      )
    },

    noHistory() {
      if (this.loading) return false
      if (!this.employmentHistories) return true
      return (
        Array.isArray(this.employmentHistories) &&
        this.employmentHistories.length === 0
      )
    },
  },

  mounted() {
    this.loading = true
    service
      .getEmploymentHistories(this.driver._id)
      .then((res) => {
        if (res) this.employmentHistories = res.data.data
      })
      .catch(console.warn)
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    handleDeleteEmploymentHistory(id) {
      service
        .deleteEmploymentHistory(id)
        .then(() => {
          this.employmentHistories = this.employmentHistories.filter(
            (i) => i._id !== id
          )
        })
        .catch(console.warn)
        .finally(() => (this.loading = false))
    },

    handleEditEmploymentHistory(item) {
      const { hired_date, terminated_date, ...rest } = item
      this.showDialog = true
      this.form = {
        ...rest,
        hired: this.formatDate(hired_date),
        terminated: terminated_date ? this.formatDate(terminated_date) : null,
      }
    },

    handleAddNewInspection() {
      this.showDialog = true
    },

    handleCloseDialog() {
      this.showDialog = false
      this.resetForm()
    },

    openHiredDatePicker() {
      this.hiredDatePicker = true
    },

    closeHiredDatePicker(v) {
      if (typeof v === 'string') this.form.hired = v
      this.hiredDatePicker = false
    },

    openTerminatedDatePicker() {
      this.terminatedDatePicker = true
    },

    closeTerminatedDatePicker(v) {
      if (typeof v === 'string') this.form.terminated = v
      this.terminatedDatePicker = false
    },

    resetForm() {
      this.form = {
        _id: null,
        driver_name: this.driver.name,
        company: this.driver.company,
        hired: this.formatDate(),
        terminated: null,
        driver_id: this.driver._id,
      }
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    handleSubmitForm() {
      this.loading = true
      if (this.form._id) {
        const { hired, terminated, ...rest } = this.form
        service
          .editEmploymentHistory({
            ...rest,
            hired: new Date(hired),
            terminated: terminated ? new Date(terminated) : null,
          })
          .then((res) => {
            if (res) {
              const { data } = res.data
              const temp = [...this.employmentHistories]
              const i = temp.findIndex((h) => h._id === data._id)
              temp[i] = data
              this.employmentHistories = temp
              this.showDialog = false
              this.resetForm()
            }
          })
          .catch(console.warn)
          .finally(() => (this.loading = false))
      } else {
        // eslint-disable-next-line no-unused-vars
        const { _id, hired, terminated, ...rest } = this.form
        service
          .addEmploymentHistory({
            ...rest,
            hired: new Date(hired),
            terminated: terminated ? new Date(terminated) : null,
          })
          .then((res) => {
            if (res) {
              const { data } = res.data
              this.employmentHistories.push(data)
              this.showDialog = false
              this.resetForm()
            }
          })
          .catch(console.warn)
          .finally(() => (this.loading = false))
      }
    },

    showActions(item) {
      const roles = this.user.roles.map((r) => r.role_name)
      if (roles.includes('SUPERADMIN')) return true
      return item.userId === this.user._id
    },
  },
}
</script>

<style lang="scss">
.EmploymentHistoriesList {
  min-width: 400px;
  min-height: 200px;

  &__list {
    max-height: 200px;
    overflow-y: scroll;
  }

  &__item-content {
    display: flex;
    align-items: flex-start;
  }

  &__times {
    flex: 1;
  }

  &__actions {
    flex: 0;
    min-width: max-content;
  }

  &__no-history {
    padding-top: 20px;
    text-align: center;
  }

  &__loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
