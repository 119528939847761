<template>
  <div>
    <v-dialog v-model="showForm" max-width="500">
      <div>
        <PermitBookForm
          v-if="showForm"
          :permitBookToEdit="bookToEdit"
          @permitBookAdded="handlePermitBookAdded"
        />
      </div>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="formattedPermitBooks"
      :options="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="tableLoading"
      disable-sort
      :no-data-text="'No files for selected referrer'"
      @update:options="handleOptionsUpdate"
      class="elevation-1"
    >
      <template v-slot:item.status="{ item }">
        <td
          :class="item.status.color"
          class="text-center font-weight-bold customTd"
        >
          {{ item.status.message }}
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon :disabled="!permissions.PERMITBOOK_EDIT" small class="mr-2" @click="editPermitBook(item.id)">
          mdi-pencil
        </v-icon>
        <v-dialog v-model="item.showDeleteDialog" max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-icon :disabled="!permissions.PERMITBOOK_DELETE" v-bind="attrs" v-on="on" small> mdi-delete </v-icon>
          </template>
          <v-card>
            <v-card-title class="headline">Are you sure?</v-card-title>
            <v-card-text
              >This will delete the permit book permanently.</v-card-text
            >
            <v-card-actions>
              <v-btn text @click="item.showDeleteDialog = false">Cancel</v-btn>
              <v-btn :disabled="!permissions.PERMITBOOK_DELETE" color="red darken-1" text @click="deletePermitBook(item)"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.preview="{ item }">
        <v-dialog
          v-model="item.showPreviewDialog"
          max-width="90vw"
          max-height="90vh"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-5"
              v-bind="attrs"
              v-on="on"
              @click="previewFile(item.id)"
            >
              mdi-eye
            </v-icon>
          </template>
          <v-card>
            <v-card-title class="headline">Preview</v-card-title>
            <iframe
              v-if="fileUrl"
              :src="fileUrl"
              style="width: 100%; height: calc(90vh - 120px)"
              frameborder="0"
            ></iframe>
            <v-card-actions>
              <v-btn text @click="item.showPreviewDialog = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.generate="{ item }">
        <v-dialog
          v-model="item.showQrCodeDialog"
          max-width="50vw"
          max-height="50vh"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-9"
              v-bind="attrs"
              v-on="on"
              @click="generateQRCode(item.id)"
            >
              mdi-qrcode
            </v-icon>
          </template>
          <v-card>
            <v-card-title class="headline">QR Code</v-card-title>
            <div class="d-flex align-center justify-center" style="width: 100%">
              <img v-if="qrUrl" :src="qrUrl" />
            </div>
            <v-card-actions>
              <v-btn text @click="item.showQrCodeDialog = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import PermitBookService from '../../services/PermitBookService'
import PermitBookForm from './PermitBookForm.vue'
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

export default {
  name: 'PermitBookTable',
  components: {
    PermitBookForm,
  },
  props: ['referrerId', 'referrerType', 'documents', 'userId', 'date_expired_filter'],
  data() {
    return {
      permitBooks: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Date expiring', value: 'date_expired' },
        { text: 'Status', value: 'status' },
        { text: 'Type', value: 'type' },
        { text: 'Subtype', value: 'subtype' },
        { text: 'Referrer\'s data', value: 'referrer_data' },
        { text: 'Actions', value: 'actions' },
        { text: 'Preview file', value: 'preview' },
        { text: 'Generate QR code', value: 'generate' },
      ],
      footerProps: {
        'items-per-page-options': [20, 50, 100],
      },
      options: {
        page: 1,
        itemsPerPage: 20,
      },
      showForm: false,
      tab: 0,
      bookToEdit: undefined,
      totalItems: 0,
      tableLoading: true,
      filters: {
        type: '',
        driver: '',
        truck: '',
        trailer: '',
        company: '',
      },
      fileUrl: null,
      qrUrl: null,
      attrs: {},
      on: {},
    }
  },
  created() {
    if (this.documents && Array.isArray(this.documents)) {
      this.permitBooks = [...this.documents]
      this.totalItems = this.documents.length
      this.tableLoading = false
    } else {
      this.getPermitBooks()
    }
  },
  mounted() {
    const tabParam = this.$route.params.tab
    const tabValues = {
      all: 0,
      driver: 1,
      truck: 2,
      trailer: 3,
      company: 4,
    }
    this.tab = tabValues[tabParam] || 0

    this.filters.type = tabParam
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    formattedPermitBooks() {
      return this.permitBooks.map((book) => ({
        id: book._id,
        name: book.name,
        date_expired: this.formatDate(book.date_expired),
        type: this.formatTypeAndSubtype(book.type),
        subtype: this.formatTypeAndSubtype(book.subtype),
        file_url: book.file_url,
        qr_code_url: book.qr_code_url,
        referrer_data: this.getReferrerData(book),
        status: this.generatePermitBookStatus(book.date_expired),
        showDeleteDialog: false,
        showPreviewDialog: false,
        showQrCodeDialog: false,
      }))
    },
  },
  methods: {
    async getPermitBooks() {
      this.tableLoading = true
      if (this.$props.userId) {
        const response = await PermitBookService.getPermitBooksByDriver(
          this.$props.userId,
          this.$props.date_expired_filter
        )
        this.permitBooks = response.data
        this.totalItems = response.data.total
      } else if (this.$props.referrerId && this.$props.referrerType) {
        const documentsResponse = await PermitBookService.getPermitBookByReferrer(this.$props.referrerType, this.$props.referrerId)
        if (documentsResponse.status === 200) {
          this.permitBooks = documentsResponse.data
          this.totalItems = documentsResponse.data.length
        }
      }
      this.tableLoading = false
    },
    async previewFile(id) {
      this.fileUrl = null
      const response = await PermitBookService.getPermitBookById(id, 'file')
      const preSignedUrl = response.data
      this.fileUrl = preSignedUrl
    },
    async generateQRCode(id) {
      this.qrUrl = null
      const response = await PermitBookService.getPermitBookById(id, 'qr')
      const resQrUrl = response.data
      this.qrUrl = resQrUrl
    },
    handleOptionsUpdate(newOptions) {
      if (
        this.options.page !== newOptions.page ||
        this.options.itemsPerPage !== newOptions.itemsPerPage
      ) {
        this.options.page = newOptions.page
        this.options.itemsPerPage = newOptions.itemsPerPage
        this.getPermitBooks()
      }
    },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }
      return 'No date of expiration'
    },
    handleFiltersApplied: debounce(function () {
      this.getPermitBooks()
    }, 500),
    getReferrerData(book) {
      if (book.trailer) return book.trailer.number
      if (book.truck) return book.truck.number
      if (book.company) return book.company.name
      if (book.driver) return book.driver.name
      return 'No referrer'
    },
    generatePermitBookStatus(date) {
      const currentDate = new Date()
      const expirationDate = new Date(date)
      const differenceInDays = Math.floor(
        (expirationDate - currentDate) / (1000 * 60 * 60 * 24)
      )

      if (differenceInDays < 0) {
        return { color: 'red', message: 'Expired' }
      } else if (differenceInDays <= 30) {
        return { color: 'yellow', message: 'Less than 30' }
      } else {
        return { color: 'green', message: 'Valid' }
      }
    },
    async editPermitBook(id) {
      if (!this.permissions.PERMITBOOK_EDIT) return
      this.bookToEdit = await PermitBookService.getSinglePermitBook(id)
      this.showForm = true
    },
    async deletePermitBook(book) {
      if (!this.permissions.PERMITBOOK_DELETE) return
      await PermitBookService.deletePermitBook(book.id)
      this.getPermitBooks()
      book.showDeleteDialog = false
      this.$emit('permitBookDeleted')
    },
    handlePermitBookAdded() {
      this.showForm = false
      this.options.page = 1
      this.getPermitBooks()
      this.$emit('permitBookAdded')
    },
    formatTypeAndSubtype(string) {
      const stringWithWhitespace = string.replace(/_/g, ' ')
      return (
        stringWithWhitespace.charAt(0).toUpperCase() +
        stringWithWhitespace.slice(1)
      )
    },
    handleTabChanged(newValue) {
      let basePath = '/permit-books'
      this.$router.push(basePath + '/drivers')
      this.options.page = 1
      this.filters.driver = ''
      this.filters.trailer = ''
      this.filters.company = ''
      this.filters.truck = ''
      if (newValue == 0) {
        this.filters.type = ''
        this.$router.push(basePath + '')
      } else if (newValue == 1) {
        this.filters.type = 'driver'
        this.$router.push(basePath + '/driver')
      } else if (newValue == 2) {
        this.filters.type = 'truck'
        this.$router.push(basePath + '/truck')
      } else if (newValue == 3) {
        this.filters.type = 'trailer'
        this.$router.push(basePath + '/trailer')
      } else if (newValue == 4) {
        this.filters.type = 'company'
        this.$router.push(basePath + '/company')
      }
      this.getPermitBooks()
    },
  },
}
</script>
<style scoped>
.actionsBar {
  height: 48px;
}
.customTextField {
  width: 350px;
}
.customTd {
  width: 200px;
}
</style>
