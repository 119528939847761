<template>
  <div class="LeasePayments">
    <span class="caption">Payments</span>
    <v-row>
      <v-col cols="4" />
      <v-col cols="4" />

      <v-col cols="4">
        <v-text-field
          v-model="totalAmount"
          label="Total amount"
          type="number"
          @input="handleChange"
          hide-details
          small
        />
      </v-col>
    </v-row>

    <v-row v-for="(payment, index) in payments" :key="index">
      <v-col cols="4">
        <v-text-field
          :value="index + 1"
          label="Payment #"
          disabled
          hide-details
          small
        />
      </v-col>

      <v-col cols="4">
        <v-menu
          ref="paymentDateMenu"
          v-model="payment.paymentDateMenu"
          :close-on-content-click="false"
          :return-value.sync="payment.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="payment.date"
              label="Date"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              hide-details
              small
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="payment.date"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="payment.paymentDateMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.paymentDateMenu[index].save(payment.date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="4"
        ><v-text-field
          v-model="payment.amount"
          label="Amount"
          type="number"
          hide-details
          small
      /></v-col>
    </v-row>

    <div class="d-flex justify-center mt-5">
      <v-btn text @click="handleAddNewPayment" :disabled="addMoreDisabled" color="primary">
        Add new payment
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

const INITIAL_ITEM = {
  date: null,
  amount: null,
  paymentDateMenu: false
}

export default {
  name: 'LeasePayments',

  emits: ['change'],

  props: {
    initialPayments: {
      type: Array,
      required: false,
    },
    initialTotalAmount: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      payments: [],
      paymentDateMenu: false,
      totalAmount: null
    }
  },

  created() {
    const hasItems = Array.isArray(this.initialPayments) && this.initialPayments.length > 0
    this.payments = 
      hasItems 
        ? this.initialPayments.map(p => ({ ...p, date: this.formatDate(p.date) })) 
        : [{ ...INITIAL_ITEM }]
    this.totalAmount = this.initialTotalAmount || 0
  },

  computed: {
    addMoreDisabled() {
      const lastPayment = this.payments[this.payments.length - 1]
      if (!lastPayment) return false
      const { date, amount } = lastPayment
      return !date || !amount
    },
  },

  methods: {
    handleAddNewPayment() {
      this.payments.push({ ...INITIAL_ITEM })
      this.handleChange()
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    handleRemovePayments(index) {
      const newPayments = [...this.payments]
      newPayments.splice(index, 1)
      this.payments = newPayments
      this.handleChange()
    },

    handleChange() {
      this.$emit('change', { payments: this.payments, totalAmount: this.totalAmount })
    }
  }
}
</script>

<style lang="scss">
.LeasePayments {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
