<template>
  <v-card :loading="loadingRequest">
    <v-form ref="form" @submit.prevent="sendFormRequest" class="pa-6">
      <h2>
        {{ permitBookToEdit ? 'Edit permit book' : 'Insert permit book' }}
      </h2>
      <v-btn
        v-if="permitBookToEdit && !showFileInput"
        @click="showFileInput = true"
        depressed
        color="primary"
        class="my-3"
        >Change file</v-btn
      >
      <v-btn
        v-if="permitBookToEdit && showFileInput"
        @click="hideFileInput"
        depressed
        color="primary"
        class="my-3"
        >Cancel file change</v-btn
      >
      <v-file-input
        v-if="showFileInput"
        :rules="fileInputRules"
        accept="image/*,.pdf"
        show-size
        counter
        label="Insert document"
        v-model="document"
        @change="handleFileInput"
      ></v-file-input>
      <v-text-field
        :rules="requiredRule"
        :disabled="shouldFileNameBeDisabled()"
        label="Document name"
        v-model="name"
        clearable
      ></v-text-field>
      <v-menu
        v-if="
          documentSubType != 'lease_agreement_between_company_and_driver' &&
          documentSubType != 'others'
        "
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dateExpiring"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :rules="requiredRule"
            v-model="dateExpiring"
            label="Choose expiring date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dateExpiring" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(dateExpiring)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-select
        :rules="requiredRule"
        :items="typeItems"
        label="Select document type"
        v-model="documentType"
        :disabled="referrerForDocument !== undefined"
        @input="handleTypeChange"
      ></v-select>
      <v-select
        :rules="requiredRule"
        :disabled="!documentType"
        :items="subtypes[documentType]"
        label="Select document subtype"
        v-model="documentSubType"
      ></v-select>
      <v-autocomplete
        :rules="requiredRule"
        :disabled="!documentSubType || referrerForDocument !== undefined"
        :items="referrerItems"
        label="Choose the document referrer"
        v-model="documentReferrer"
        @update:search-input="handleReferrerSearch"
        @input="handleReferrerInput"
      ></v-autocomplete>
      <v-btn type="submit" class="primary mt-3" width="100%"
        >Save permit book</v-btn
      >
    </v-form>
  </v-card>
</template>
<script>
import TruckService from '../../services/TruckService'
import TrailersService from '../../services/TrailersService'
import PermitBookService from '../../services/PermitBookService'
import CompaniesService from '../../services/CompaniesService'
import AppUsersService from '../../services/AppUsersService'
import debounce from 'lodash/debounce'

const emailRegex = /\(([^)]+@[^)]+)\)/

export default {
  name: 'PermitBookForm',
  data() {
    return {
      name: '',
      document: null,
      dateExpiring: '',
      documentType: '',
      documentSubType: '',
      documentReferrer: null,
      referrerItems: [],
      requiredRule: [(v) => (v != '' && v != null) || 'Value is required'],
      fileInputRules: [
        (v) =>
          (v && v.size < 10000000) ||
          'File is required and size should be less than 10 MB!',
        (v) => !!v || 'File is required',
      ],

      typeItems: [
        {
          text: 'Truck',
          value: 'truck',
        },
        {
          text: 'Trailer',
          value: 'trailer',
        },
        {
          text: 'Company',
          value: 'company',
        },
        {
          text: 'Driver',
          value: 'driver',
        },
      ],
      subtypes: {
        truck: [
          { text: 'Registration', value: 'registration' },
          { text: 'Annual DOT inspection', value: 'annual_dot_inspection' },
          {
            text: 'Lease agreement for registration',
            value: 'lease_agreement_for_registration',
          },
          { text: 'Others', value: 'others' },
        ],
        trailer: [
          { text: 'Registration', value: 'registration' },
          { text: 'Annual DOT inspection', value: 'annual_dot_inspection' },
          { text: 'Others', value: 'others' },
        ],
        company: [
          { text: 'ELD documents', value: 'eld_documents' },
          { text: 'IFTA license', value: 'ifta_license' },
          { text: 'MC Certificate', value: 'mc_certificate' },
          {
            text: 'Certificate of insurance',
            value: 'certificate_of_insurance',
          },
          {
            text: 'State of Connecticut permit',
            value: 'state_of_connecticut_permit',
          },
          { text: 'Kentucky ABC Permit', value: 'kentucky_abc_permit' },
          { text: 'Others', value: 'others' },
        ],
        driver: [
          { text: 'CDL copy', value: 'cdl_copy' },
          { text: 'Medical card', value: 'medical_card' },
          {
            text: 'Lease agreement between company and driver',
            value: 'lease_agreement_between_company_and_driver',
          },
          { text: 'Others', value: 'others' },
        ],
      },
      referrers: {
        truck: null,
        trailer: null,
        company: null,
        driver: null,
      },
      menu: false,
      loadingRequest: false,
      showFileInput: false,
    }
  },
  props: {
    permitBookToEdit: {
      type: Object,
      required: false,
    },
    referrerForDocument: {
      type: Object,
      required: false,
    },
  },
  methods: {
    async sendFormRequest() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        const formData = new FormData()
        if (this.dateExpiring) {
          const dateObj = new Date(this.dateExpiring)
          const formattedDate = dateObj.toISOString()
          formData.append('date_expired', formattedDate)
        }
        const formatteDName = this.removeExtensionFromDocumentName(this.name)
        formData.append('name', formatteDName)
        formData.append('type', this.documentType)
        formData.append('subtype', this.documentSubType)
        formData.append('trailer', JSON.stringify(this.referrers.trailer))
        formData.append('driver', JSON.stringify(this.referrers.driver))
        formData.append('truck', JSON.stringify(this.referrers.truck))
        formData.append('company', JSON.stringify(this.referrers.company))
        formData.append('file', this.document)
        try {
          this.loadingRequest = true
          if (!this.$props.permitBookToEdit) {
            await PermitBookService.addPermitBook(formData)
          } else {
            await PermitBookService.editPermitBook(
              this.$props.permitBookToEdit.data._id,
              formData
            )
          }
          this.loadingRequest = false
          this.$emit('permitBookAdded')
          this.$refs.form.reset()
          this.$refs.form.resetValidation()
          this.referrerItems = []
          this.referrers.truck = null
          this.referrers.trailer = null
          this.referrers.company = null
          this.referrers.driver = null
        } catch (err) {
          console.log(err)
        }
      }
    },
    handleFileInput(newFile) {
      this.name = newFile?.name || ''
    },
    handleReferrerSearch: debounce(async function (newQuery) {
      if (newQuery && newQuery.length >= 2) {
        if (this.documentType == 'truck') {
          const trucksResponse = await TruckService.searchTrucks(newQuery)
          const mappedResponse = trucksResponse.data.docs.map((item) => ({
            text: item.number,
            value: { id: item._id, number: item.number },
          }))
          this.referrerItems = mappedResponse
        } else if (this.documentType == 'trailer') {
          const trailerResponse = await TrailersService.searchTrailers(newQuery)
          const mappedResponse = trailerResponse.data.map((item) => ({
            text: item.number,
            value: { id: item.id, number: item.number },
          }))
          this.referrerItems = mappedResponse
        } else if (this.documentType == 'driver') {
          const foundEmail = newQuery.match(emailRegex)
          const driverResponse = await AppUsersService.getAutocomplete(foundEmail ? foundEmail[1] : newQuery)
          const mappedResponse = driverResponse.data.map((item) => ({
            text: `${item.name} (${item.email})`,
            value: { id: item._id, name: `${item.name} (${item.email})`},
          }))
          this.referrerItems = mappedResponse
        } else if (this.documentType == 'company') {
          const companyResponse = await CompaniesService.getCompaniesAutocomplete(
            newQuery
          )
          const mappedResponse = companyResponse.data.map((item) => ({
            text: item.name,
            value: { id: item._id, name: item.name },
          }))
          this.referrerItems = mappedResponse
        }
      }
    }, 500),
    handleReferrerInput(newValue) {
      this.referrers[this.documentType] = newValue
    },
    removeExtensionFromDocumentName(documentName) {
      const lastDotIndex = documentName.lastIndexOf('.')
      if (lastDotIndex != -1) {
        return documentName.substring(0, lastDotIndex)
      } else {
        return documentName
      }
    },
    shouldFileNameBeDisabled() {
      if (!this.document && this.showFileInput) {
        if (!this.$props.permitBookToEdit || !this.name) {
          return true
        }
      }
      return false
    },
    hideFileInput() {
      this.showFileInput = false
      this.document = null
      this.name = this.$props.permitBookToEdit.data.name
    },
    handleTypeChange() {
      this.referrerItems = []
      this.referrers.company = null
      this.referrers.trailer = null
      this.referrers.truck = null
      this.referrers.driver = null
    },
  },
  mounted() {
    if (this.$props.referrerForDocument) {
      const { type, ...referrerObj } = this.$props.referrerForDocument
      this.documentType = type
      this.referrerItems.push(referrerObj)
      this.documentReferrer = referrerObj
      this.referrers[type] = referrerObj.value
    }
    if (this.$props.permitBookToEdit) {
      this.showFileInput = false
      const referrerObj = {
        text: '',
        value: {},
      }
      this.name = this.$props.permitBookToEdit.data.name
      this.dateExpiring = this.$options.filters.formatDatePicker(
        this.$props.permitBookToEdit.data.date_expired
      )
      this.documentType = this.$props.permitBookToEdit.data.type
      this.documentSubType = this.$props.permitBookToEdit.data.subtype
      if (this.documentType == 'driver') {
        referrerObj.text = this.$props.permitBookToEdit.data.driver.name
        referrerObj.value.id = this.$props.permitBookToEdit.data.driver.id
        referrerObj.value.name = this.$props.permitBookToEdit.data.driver.name
        this.referrerItems.push(referrerObj)
        this.documentReferrer = referrerObj
      } else if (this.documentType == 'truck') {
        referrerObj.text = this.$props.permitBookToEdit.data.truck.number
        referrerObj.value.id = this.$props.permitBookToEdit.data.truck.id
        referrerObj.value.number =
          this.$props.permitBookToEdit.data.truck.number
        this.referrerItems.push(referrerObj)
        this.documentReferrer = referrerObj
      } else if (this.documentType == 'trailer') {
        referrerObj.text = this.$props.permitBookToEdit.data.trailer.number
        referrerObj.value.id = this.$props.permitBookToEdit.data.trailer.id
        referrerObj.value.number =
          this.$props.permitBookToEdit.data.trailer.number
        this.referrerItems.push(referrerObj)
        this.documentReferrer = referrerObj
      } else if (this.documentType == 'company') {
        referrerObj.text = this.$props.permitBookToEdit.data.company.name
        referrerObj.value.id = this.$props.permitBookToEdit.data.company.id
        referrerObj.value.name = this.$props.permitBookToEdit.data.company.name
        this.referrerItems.push(referrerObj)
        this.documentReferrer = referrerObj
      }
      this.referrers[this.documentType] = referrerObj.value
    } else {
      this.showFileInput = true
    }
  },
}
</script>
