<template>
  <v-row class="pr-6 shop-review">
    <v-card-title class="pl-6">{{ review.userName.toUpperCase() }}</v-card-title>
    <p class="pt-6 mb-0 font-weight-medium text-body-2 grey--text">{{ formatDate(review.createdDate) }}</p>
    <v-spacer></v-spacer>
    <v-btn v-if="currentUser.id === review.userId || permissions.EDIT_SHOP_REVIEW" icon
      @click="openEditReviewDialog()" class="pt-5"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
    <v-card-text class="pl-4 pt-0 font-weight-medium text-body-1">{{ review.text }}</v-card-text>
    <v-rating background-color="grey" empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full"
      color="#1B2C4B" length="5" size="24" class="ml-4" :value=review.rating half-increments dense readonly>
    </v-rating>
    <p class="pl-2 pt-1">{{ review.rating }}</p>
    <v-dialog max-width="480px" v-model="editReviewActive" @click:outside="handleCloseEditReview">
      <v-card>
        <div class="pl-6 pt-7 pr-6 pb-7">
          <v-row>
            <v-card-title class="font-weight-bold text-h6">Edit Review</v-card-title>
            <v-spacer></v-spacer>
            <v-btn class="mt-3 mr-3" @click="handleCloseEditReview" icon><v-icon>mdi-close</v-icon></v-btn>
          </v-row>
          <form @submit.prevent="editReview(review)" data-vv-scope="editReviewShop">
            <v-card-text class="pl-2 pb-0 font-weight-bold text-body-1 dark-blue">Rate
              Shop*</v-card-text>
            <v-rating v-model="reviewForm.rating" v-validate="{ required: true }" data-vv-name="rating"
              :error="errors.has('editReviewShop.rating')" :error-messages="errors.first('editReviewShop.rating')"
              empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full"
              :background-color="checkRatingError ? 'error' : 'grey'" color="#1B2C4B" class="mt-4 mb-4 ml-1" length="5"
              size="28" half-increments dense></v-rating>
            <v-divider class="mt-4"></v-divider>
            <v-card-text class="pl-2 font-weight-bold text-body-1 dark-blue">Edit
              Review*</v-card-text>
            <v-textarea v-model="reviewForm.newReviewDescription" v-validate="{ required: true }"
              data-vv-name="description" :error="errors.has('editReviewShop.description')"
              :error-messages="errors.first('editReviewShop.description')" label="Your Review" rows="6" row-height="15"
              outlined hide-details></v-textarea>
            <v-btn class="mt-4" color="primary" type="submit" block>Edit Review</v-btn>
          </form>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import MsShopService from '../../services/MsShopService'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ShopReview',
  data: () => ({
    reviewForm: {
      newReviewDescription: '',
      rating: null,
    },
    editReviewActive: false,
    reviewFormSubmited: false,
    currentUser: {}
  }),
  props: {
    review: Object
  },
  computed: {
    ...mapGetters('auth', ['msUser', 'permissions']),
    checkRatingError() {
      return this.reviewForm.rating == null && this.reviewFormSubmited ? true : false
    }
  },
  mounted() {
    this.currentUser = { ...this.msUser }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    async editReview(review) {
      this.editReviewActive = true
      const isFormValid = await this.$validator.validateAll('editReviewShop')
      try {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('submit_form')
        if (isFormValid && token) {
          review.rating = this.reviewForm.rating
          review.text = this.reviewForm.newReviewDescription
          await MsShopService.updateReview({
            text: review.text,
            rating: review.rating,
            recaptchaToken: token,
            recaptchaV2: false
          }, review.id)

          console.log('Review submitted successfully')

          this.reviewForm = {
            newReviewDescription: '',
            rating: null
          }
          this.editReviewActive = false
          this.reviewFormSubmited = false
          this.$emit('review-updated')
        } else {
          console.warn('Form validation failed. Review not submitted.')
        }
      } catch (error) {
        if (error.response && error.response.data.message === 'LOW_RECAPTCHA_SCORE') {
          console.log('LOW_RECAPTCHA_SCORE')
          this.setMessage('Form validation failed, low recaptcha score.', error)
        }
        console.error('Error submitting review:', error)
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }
      return date.toLocaleString('en-US', options)
    },
    openEditReviewDialog() {
      this.reviewForm.newReviewDescription = this.review.text
      this.reviewForm.rating = this.review.rating
      this.editReviewActive = true
    },
    handleCloseEditReview() {
      this.editReviewActive = false
    },
  }
}
</script>
  
<style lang="scss">
@import '@/assets/variables/variables.scss';


.shop-review {
  position: relative;

  .dark-blue {
    color: $dark-blue !important;
  }
}
</style>