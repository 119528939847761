<script>
export default {
  props: ['loads'],
  functional: true,
  render(h, context) {
    let sumPrice = 0
    let sumMiles = 0
    for (let load in context.props.loads) {
      if (context.props.loads[load].price) {
        sumPrice += parseFloat(context.props.loads[load].price)
      }

      if (context.props.loads[load].miles) {
        sumMiles += parseFloat(context.props.loads[load].miles)
      }
    }
    if (sumMiles && sumPrice) {
      let average = sumPrice / sumMiles
      return h('span', Math.round(average * 100) / 100)
    }
    return h('span', 0)
  }
}
</script>