import Api from '@/services/Api'

export default {
  getTruckAnnualDotsByTruckId(id) {
    return Api().get(`trucks/annual-dots-by-truck/${id}`)
  },
  addTruckAnnualDot(truckAnnualDot) {
    return Api().post('trucks/annual-dots', truckAnnualDot)
  },
  editTruckAnnualDot(truckAnnualDot) {
    return Api().put('trucks/annual-dots', truckAnnualDot)
  },
  deleteTruckAnnualDot(id) {
    return Api().delete(`trucks/annual-dots/${id}`)
  },
}
