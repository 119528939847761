import callType from '../../services/CallTypeService'

const state = {
  callTypes: []
}

const getters = {
  callTypes (state) {
    return state.callTypes
  }
}

const actions = {
  getCallTypes ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return callType.getCallTypes(payload).then(res => {
      commit('setCallTypes', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllCallTypes ({dispatch}) {
    return callType.getAllCallTypes().then(res => {
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addCallType ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return callType.addCallType(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addCallType', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editCallType ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return callType.editCallType(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editCallType', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteCallType ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return callType.deleteCallType(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteCallType', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setCallTypes (state, callTypes) {
    state.callTypes = callTypes
  },
  addCallType (state, callType) {
    const tmpCalls = state.callTypes.slice()
    tmpCalls.push(callType)
    state.callTypes = tmpCalls
  },
  editCallType (state, callType) {
    let tmpCalls = state.callTypes.slice()
    let index = tmpCalls.findIndex(el => el._id === callType._id)
    tmpCalls.splice(index, 1, callType)
    state.callTypes = tmpCalls
  },
  deleteCallType (state, id) {
    const tmpCalls = state.callTypes.slice()
    tmpCalls.splice(tmpCalls.findIndex(el => el._id === id), 1)
    state.callTypes = tmpCalls
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
