import Api from '@/services/Api'

export default {
  getDriverDocuments(options){
    return Api().get('/driver-documents', {
      params:{
        page: options.page,
        perPage: options.itemsPerPage,
        truck_number: options.truck_number || '',
        name: options.name || '',
        document_id: options.document_id || ''
      }
    })
  },
  getFileUrl (id) {
    return Api().get(`/driver-documents/presigned/${id}`)
  },
}