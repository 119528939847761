import Api from '@/services/Api'

export default {
  getEldTrucks (pagination) {
    return Api().get('/eld-trucks', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1,
      search: pagination.search
    }})
  },
  addEldTruck (eldTruck) {
    return Api().post('/eld-trucks', eldTruck)
  },
  editEldTruck (eldTruck) {
    return Api().put('/eld-trucks', eldTruck)
  },
  deleteEldTruck (id) {
    return Api().delete(`/eld-trucks/${id}`)
  },
  getAllEldTrucks () {
    return Api().get('/eld-trucks/all')
  },
  getTrucksByCompanyId(companyId) {
    return Api().get(`/eld-trucks/get-trucks-by-company/${companyId}`)
  },
}
