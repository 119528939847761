<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="300"
    min-width="300"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        {{ user.name }}
      </div>
    </template>

    <v-card style="padding: 10px" v-if="user">
      <div class="UserInfo__row">
        <div class="UserInfo__col">Dir:</div>
        <div class="UserInfo__col text-right">TODO</div>
      </div>

      <div class="UserInfo__row">
        <div class="UserInfo__col">Ext:</div>
        <div class="UserInfo__col text-right">{{ user.ext }}</div>
      </div>

      <div class="UserInfo__row">
        <div class="UserInfo__col">Mail:</div>
        <div class="UserInfo__col">{{ user.email }}</div>
      </div>

      <div class="UserInfo__row">
        <div class="UserInfo__col">Company:</div>
        <div class="UserInfo__col text-center">{{ counts.companies }}</div>
      </div>

      <div class="UserInfo__row">
        <div class="UserInfo__col">Truck:</div>
        <div
          class="UserInfo__col text-center"
          style="background-color: #d8ead3"
        >
          {{ counts.trucks }}
        </div>
      </div>

      <div class="UserInfo__row">
        <div class="UserInfo__col">Trailer:</div>
        <div
          class="UserInfo__col text-center"
          style="background-color: #fff2cc"
        >
          {{ counts.trailers }}
        </div>
      </div>

      <div class="UserInfo__row" />

      <div class="UserInfo__row">
        <div class="UserInfo__col">Ukupno:</div>
        <div class="UserInfo__col text-center">
          {{ counts.trucks + counts.trailers }}
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import service from '../../services/LeaseCompaniesService'

export default {
  name: 'UserInfo',

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menu: false,
      counts: {
        trucks: 0,
        trailers: 0,
        companies: 0,
      },
      loading: false,
    }
  },

  watch: {
    menu(v) {
      if (v) this.fetchData()
    },
  },

  methods: {
    async fetchData() {
      this.loading = true
      try {
        const { data } = await service.getUserInfo(this.user._id)
        this.counts = { ...data }
      } catch (error) {
        console.error('fetchData()', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.UserInfo {
  &__row {
    width: 100%;
    display: flex;
    min-height: 28px;
    &:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }
  }

  &__col {
    width: 50%;
    padding: 2px 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(:last-child) {
      border-right: 1px solid #dddddd;
    }
  }
}
</style>
