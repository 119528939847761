import Vue from 'vue'
import Vuex from 'vuex'
// import createLogger from 'vuex/dist/logger'

import auth from './modules/auth'
import user from './modules/user'
import common from './modules/common'
import roles from './modules/roles'
import boards from './modules/boards'
import drivers from './modules/drivers'
import logs from './modules/logs'
import loadTypes from './modules/loadTypes'
import callTypes from './modules/callTypes'
import dashboard from './modules/dashboard'
import contacts from './modules/contacts'
import messages from './modules/messages'
import shifts from './modules/shifts'
import eldDashboard from './modules/eldDashboard'
import eldCompanies from './modules/eldCompanies'
import eldTrucks from './modules/eldTrucks'
import eldDrivers from './modules/eldDrivers'
import dotInspections from './modules/dotInspections'
import claims from './modules/claims'
import trailers from './modules/trailers'
import trailerMarks from './modules/trailerMarks'
import trailerTypes from './modules/trailerTypes'
import dealerships from './modules/dealerships'
import yards from './modules/yards'
import trucks from './modules/trucks'
import banks from './modules/banks'
import postTrucks from './modules/postTrucks'


Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    common,
    auth,
    user,
    roles,
    boards,
    loadTypes,
    callTypes,
    drivers,
    logs,
    dashboard,
    contacts,
    messages,
    shifts,
    eldDashboard,
    eldCompanies,
    eldTrucks,
    eldDrivers,
    dotInspections,
    claims,
    trailers,
    trailerMarks,
    trailerTypes,
    dealerships,
    yards,
    trucks,
    banks,
    postTrucks
  },
  strict: debug,
  plugins: [
    // createLogger({
    //   collapsed: true
    // })
  ],
})
