<template>
  <div class="table-container-contacts" :class="{loading: loading, empty: !contacts.length && !loading}">
    <v-card-title>
      <v-text-field
          id="step-3"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('other.search')"
          single-line
          hide-details
          height="30"
          dense
      ></v-text-field>
    </v-card-title>
    <table v-if="contacts.length" class="main-table" :class="{dark: dark}">
      <thead >
        <tr>
          <th>Name</th>
          <th>Position</th>
          <!-- <th>Assistant</th> -->
          <th>Company</th>
          <th>{{$t('contact_fields.first_ext_label')}}</th>
          <th>{{$t('contact_fields.second_ext_label')}}</th>
          <th>{{$t('contact_fields.third_ext_label')}}</th>
          <th>{{$t('contact_fields.fourth_ext_label')}}</th>
          <th>Phone</th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contact in contacts" :class="{selected: contact.name === selectedRow}" :key="contact._id" :id="contact.name.toLowerCase().split(' ').join('_')">
          <td class="bold" :class="{blink: contact.name === selectedRow}">{{contact.name}}</td>
          <td class="bold center">{{contact.position.toUpperCase()}}</td>
          <!-- <td class="bold center assistant" @click="findAssistant(contact.assistant)">{{contact.assistant ? contact.assistant.name : '/'}}</td> -->
          <td class="company">{{contact.company ? contact.company : '/'}}</td>
          <td class="bold center">{{contact.first_ext_field && contact.first_ext_field !== '' ? contact.first_ext_field : '/'}}</td>
          <td class="bold center">{{contact.second_ext_field && contact.second_ext_field !== '' ? contact.second_ext_field : '/'}}</td>
          <td class="bold center">{{contact.dispatcher_ext_third && contact.dispatcher_ext_third !== '' ? contact.dispatcher_ext_third : '/'}}</td>
          <td class="bold center">{{contact.dispatcher_ext_fourth && contact.dispatcher_ext_fourth !== '' ? contact.dispatcher_ext_fourth : '/'}}</td>
          <td class="bold center link"><a v-if="contact.phone_number !== '' && contact.phone_number !== '/'" :href="`tel:${contact.phone_number}`">{{contact.phone_number}}</a><template v-else>/</template></td>
          <td class="center link"><a :href="`mailto:${contact.email}`">{{contact.email}}</a></td>
        </tr>
      </tbody>
    </table>
    <p v-else-if="!loading" class="empty-contacts subtitle-1">There are currently no contact information</p>
  </div>
</template>

<script>
import contactsService from '../../services/ContactService'
import {mapGetters} from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'ContactsView',
  data() {
    return {
      contacts: [],
      selectedRow: '',
      search: '',
      options: {
        page: 1,
        sortBy: 'name',
        sortDesc: true,
        itemsPerPage: 15,
      }
    }
  },
  computed: {
    ...mapGetters('common', ['loading', 'dark']),
  },
  methods: {
    findAssistant(assistant) {
      if (assistant && assistant.name) {
        this.$router.push(`/contacts-board?name=${assistant.name}`)
        this.selectedRow = assistant.name
        setTimeout(() => {
          let el = document.getElementById(this.selectedRow.toLowerCase().split(' ').join('_'))
          if (el) {
            let topOffset = el.offsetTop
            document.getElementsByClassName('table-container-contacts')[0].scrollTop = topOffset - 45
          }
        }, 500)

      }
    },
    searchContacts: debounce( function(search) {
      this.options.search = search
      contactsService.getContacts(this.options).then(res => {
        this.contacts = res.data.docs
      })
    }, 300),
  },
  mounted() {
    this.$store.dispatch('common/setLoading', true)
    contactsService.getAllContacts().then(res => {
      this.contacts = res.data
      this.$store.dispatch('common/setLoading', false)

      if (this.$route.query.name) {
        this.selectedRow = this.$route.query.name
        setTimeout(() => {
          let el = document.getElementById(this.selectedRow.toLowerCase().split(' ').join('_'))
          if (el) {
            let topOffset = el.offsetTop
            document.getElementsByClassName('table-container-contacts')[0].scrollTop = topOffset - 45
          }
        }, 500)
      }
    })
  },
  watch: {
    search: function (newSearch) {
      this.searchContacts(newSearch)
    }
  }
}
</script>

<style lang="scss">
  .table-container-contacts {
    overflow: scroll;
    width: 100%;
    max-height: calc(100vh - 90px);
    position: relative;
    margin-top: 10px;

    &.loading {
      opacity: 0.7;
    }

    &.empty {
      overflow: auto;
    }

    .empty-contacts {
      text-align: center;
    }

    .dashboard-loader {
      position: fixed;
      z-index: 100;
      left: 50%;
      bottom: 50%;
      transform: translate(50%, 50%);
    }

    .main-table {
      border-collapse: collapse;
      min-width: 400px;
      width: 100%;
      border-collapse: separate; /* Don't collapse */
      border-spacing: 0;

      &.dark {
        th {
          background-color: #43a047;
        }
      }

      th {
        padding: 10px 5px;
        border-left: 1px solid #A9A9A9;
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        background-color: #1E88E5FF;
        color: #ffffff;
        text-align: center;
        top: 0;
        z-index: 3;
        font-size: 13px;
      }

      tbody {
        tr {
          background-color: #fff;
          &:hover {
            background-color: lightyellow;
          }

          &.selected {
            background-color: lightblue;
          }

          td {
            padding: 7px 5px;
            font-size: 13px;
            border-left: 1px solid #A9A9A9;
            border-bottom: 1px solid #A9A9A9;
            white-space: nowrap;
            position: relative;
            color: #000;

            &.bold {
              font-weight: 600;
            }

            &.center {
              text-align: center;
            }

            &.link {
              font-size: 13px;
              a {
                text-decoration: none;
              }
            }

            &.company {
              color: #3f51b5;
              text-shadow: 0px 0px 10px rgb(63 81 181 / 70%);
              font-weight: 600;
            }

            &.assistant {
              cursor: pointer;

              &:hover {
                color: #755062;
                text-decoration: underline;
                text-shadow: 0px 0px 10px rgb(63 81 181 / 70%);
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }


  @keyframes blink {  
    0% { color: lightsalmon; }
    100% { color: black; }
  }
  @-webkit-keyframes blink {
    0% { color: lightsalmon; }
    100% { color: black; }
  }
  .blink {
    -webkit-animation: blink 2s linear infinite;
    -moz-animation: blink 2s linear infinite;
    animation: blink 2s linear infinite;
  } 
</style>