<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div @click="on.click">
        {{truck.truck_id}}
      </div>
    </template>
    <v-card>
      <div style="min-width:400px;min-height:200px;">
        <TrailerHistory v-if="menu" :small="true" type="driver" :id="truck.id" />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import TrailerHistory from './TrailerHistory.vue'

export default {
  props: {
    truck: {
      type: Object,
      required: true,
    }
  },
  components: {
    TrailerHistory
  },
  data() {
    return {
      menu: false
    }
  }
}
</script>