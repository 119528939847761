import Api from '@/services/Api'

export default {
  addLoad (load) {
    return Api().post('/loads', {
      name: load.name,
      origin: load.origin ? load.origin : '',
      price: load.price !== undefined ? load.price : 0,
      miles: load.miles !== undefined ? load.miles : 0,
      day_key: load.day_key,
      day: load.fullDate,
      driver_id: load.driver_id,
      loadType: load.loadType,
      comments: load.comments,
      board_id: load.board_id,
      absenceType: load.absenceType,
      dateFrom: load.dateFrom,
      dateTo: load.dateTo,
      is_dedicated: load.is_dedicated
    })
  },
  editLoad (load) {
    return Api().put('/loads', {
      _id: load._id,
      name: load.name,
      origin: load.origin ? load.origin : '',
      price: load.price !== undefined ? load.price : 0,
      miles: load.miles !== undefined ? load.miles : 0,
      day_key: load.day_key,
      day: load.fullDate,
      driver_id: load.driver_id,
      user_id: load.user_id,
      loadType: load.loadType,
      comments: load.comments,
      board_id: load.board_id,
      is_dedicated: load.is_dedicated
    })
  },
  getLoad (id) {
    return Api().get(`/loads/${id}`)
  },
  addComment (id, comment, boardId) {
    return Api().post('/loads/add-comment', {
      loadId: id,
      comment: comment,
      board_id: boardId
    })
  },
  editComment (id, comment, boardId) {
    return Api().post('/loads/edit-comment', {
      loadId: id,
      comment: comment,
      board_id: boardId
    })
  },
  deleteComment (loadId, commentId, boardId) {
    return Api().post('/loads/delete-comment', {
      loadId: loadId,
      commentId: commentId,
      board_id: boardId
    })
  },
  deleteLoad (id, board_id) {
    return Api().delete(`/loads/${id}/${board_id}`)
  },
  moveLoad (oldLoad, newLoad, board_id) {
    return Api().put('/loads/move', {
      oldLoad: oldLoad,
      newLoad: newLoad,
      board_id: board_id
    })
  },
  getCitiesList (search) {
    return Api().get(`/loads/autocomplete/cities?search=${search}`)
  },
  getCity (search) {
    return Api().get(`/loads/find/citiy?search=${search}`)
  }
}