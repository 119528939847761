import dotInspection from '../../services/DotInspectionsService'

const state = {
  dotInspections: []
}

const getters = {
  dotInspections (state) {
    return state.dotInspections
  }
}

const actions = {
  getDotInspections ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return dotInspection.getDotInspections(payload).then(res => {
      commit('setDotInspections', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllDotInspections({dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return dotInspection.getAllDotInspections().then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addDotInspection ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return dotInspection.addDotInspection(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addDotInspection', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editDotInspection ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return dotInspection.editDotInspection(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editDotInspection', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteDotInspection ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return dotInspection.deleteDotInspection(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteDotInspection', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setDotInspections (state, dotInspections) {
    state.dotInspections = dotInspections
  },
  addDotInspection (state, dotInspection) {
    const tmpDotInspections = state.dotInspections.slice()
    tmpDotInspections.push(dotInspection)
    state.dotInspections = tmpDotInspections
  },
  editDotInspection (state, dotInspection) {
    let tmpDotInspections = state.dotInspections.slice()
    let index = tmpDotInspections.findIndex(el => el._id === dotInspection._id)
    tmpDotInspections.splice(index, 1, dotInspection)
    state.dotInspections = tmpDotInspections
  },
  deleteDotInspection (state, id) {
    const tmpDotInspections = state.dotInspections.slice()
    tmpDotInspections.splice(tmpDotInspections.findIndex(el => el._id === id), 1)
    state.dotInspections = tmpDotInspections
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
