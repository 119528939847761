import companiesService from '../../services/LeaseCompaniesService'
import unitsService from '../../services/LeaseUnitsService'
import { mapActions } from 'vuex'

export const commentsMixin = {
  data() {
    return {
      form: {comments: []},
      service: null
    }
  },
  created() {
    this.service = this.commentsService === 'companies' ? companiesService : unitsService
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    async handleDeleteComment(commentId) {
      try {
        if (!this.editId) {
          this.form.comments.splice(commentId, 1)
          return
        }
        const { data } = await this.service.deleteComment(this.companyId || this.editId, commentId)
        this.form.comments = [...data.comments]
        this.setMessage('Comment successfully delited')
      } catch (error) {
        console.error('handleDeleteComment()', error)
      } finally {
        this.$store.dispatch('common/setLoading', false)
      }
    },
    async saveComment(comment) {
      this.loading = true
      try {
        if (!this.editId) {
          this.form.comments.push(comment)
          return
        }
        if (comment._id) {
          const { data } = await this.service.editComment(this.editId, comment)
          this.form.comments = [...data.comments]
          this.setMessage('Comment successfully edited')
        } else {
          const { data } = await this.service.addComment(this.editId, comment)
          this.form.comments = [...data.comments]
          this.setMessage('Comment successfully added')
        }
      } catch (error) {
        console.error('addComment()', error)
      } finally {
        this.loading = false
      }
    }
  }
}