<template>
  <v-menu
    v-model="menu"
    open-on-hover
    :disabled="!load.comments.length"
    :close-on-content-click="false"
    max-width="340"
    min-width="340"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        v-on="on"
        label
        class="mr-1 mb-1"
        :title="load.loadType.type"
        :color="load.loadType.type !== 'DEFAULT' ? load.loadType.color : '#cecece'"
        small
        text-color="white"
      >
        {{load.name}} - {{load.day | formatDateObj('DD.MMM')}}
      </v-chip>
    </template>
    <Comments v-if="load.comments.length" :comments="load.comments" :loadId="load._id" :showActions="false"/>
  </v-menu>
</template>

<script>
import Comments from '../Common/Comments'

export default {
  name: 'LoadChip',
  props: ['load'],
  components: {
    Comments
  },
  data() {
    return {
      menu: false,
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>