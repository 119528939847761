<template>
  <div>
    <div class="d-flex justify-space-start flex-wrap mt-3 flexWithGap">
          <v-text-field
            hide-details
            solo
            dense
            class="customTextField mr-1"
            label="Search by doc id"
            prepend-inner-icon="mdi-magnify"
            @input="handleFiltersApplied"
            v-model="filters.document_id"
          ></v-text-field>
          <v-text-field
            hide-details
            solo
            dense
            class="customTextField mr-1"
            label="Search by driver name"
            prepend-inner-icon="mdi-magnify"
            @input="handleFiltersApplied"
            v-model="filters.name"
          ></v-text-field>
          <v-text-field
            hide-details
            solo
            dense
            class="customTextField"
            label="Search by truck number"
            prepend-inner-icon="mdi-magnify"
            @input="handleFiltersApplied"
            v-model="filters.truck_number"
          ></v-text-field>
      </div>
    <v-data-table
      :headers="headers"
      :items="driverDocuments"
      :options="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="tableLoading"
      disable-sort
      :no-data-text="'There is no documents'"
      @update:options="handleOptionsUpdate"
      class="elevation-1"
    >
      <template v-slot:item.preview="{ item }">
        <v-dialog
          v-model="item.showPreviewDialog"
          max-width="90vw"
          max-height="90vh"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="ml-5"
              v-bind="attrs"
              v-on="on"
              @click="previewFile(item._id)"
            >
              mdi-eye
            </v-icon>
          </template>
          <v-card>
            <v-card-title class="headline">Preview</v-card-title>
            <iframe
              v-if="fileUrl"
              :src="fileUrl"
              style="width: 100%; height: calc(90vh - 120px)"
              frameborder="0"
            ></iframe>
            <v-card-actions>
              <v-btn text @click="item.showPreviewDialog = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DriverDocumentsService from '../../services/DriverDocumentsService'
import debounce from 'lodash/debounce'
// import { mapGetters } from 'vuex'


export default {
  name: 'DriverDocumentsTable',
  data() {
    return {
      driverDocuments: [],
      headers: [
        { text: '#ID', value: 'document_id' },
        { text: 'Driver name', value: 'name' },
        { text: 'Truck', value: 'truck_number' },
        // { text: 'Actions', value: 'actions' },
        { text: 'Preview file', value: 'preview' },
      ],
      footerProps: {
        'items-per-page-options': [20, 50, 100],
      },
      options: {
        page: 1,
        itemsPerPage: 20,
      },
      totalItems: 0,
      tableLoading: true,
      filters: {
        driver: '',
        truck_number: '',
        document_id: ''
      },
      fileUrl: null
    }
  },
  async mounted() {
    await this.getDriverDocuments()
  },
  methods: {
    async getDriverDocuments() {
      this.tableLoading = true
      const requestData = { ...this.options, ...this.filters }
      const response = await DriverDocumentsService.getDriverDocuments(requestData)
      this.driverDocuments = response.data.docs
      this.totalItems = response.data.total
      this.tableLoading = false
    },
    async handleOptionsUpdate(newOptions) {
      if (
        this.options.page !== newOptions.page ||
        this.options.itemsPerPage !== newOptions.itemsPerPage
      ) {
        this.options.page = newOptions.page
        this.options.itemsPerPage = newOptions.itemsPerPage
        await this.getDriverDocuments()
      }
    },
    async previewFile(id) {
      this.fileUrl = null
      const response = await DriverDocumentsService.getFileUrl(id)
      const preSignedUrl = response.data
      this.fileUrl = preSignedUrl
    },
    handleFiltersApplied: debounce(async function () {
      await this.getDriverDocuments()
    }, 500),
  }
}
</script>