<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-alert
              :value="message !== ''"
              type="warning"
              transition="scale-transition"
            >
              {{$t(message)}}
            </v-alert>
            <v-card class="elevation-12">
              <v-form @submit.prevent="onSubmit">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>{{$t('SET_PASSWORD')}}</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <v-text-field
                    tabindex="1"
                    :disabled="this.email === '' || this.token === ''"
                    ref="password"
                    :loading="loading"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    v-validate="'required|min:6'"
                    v-model="password"
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    :error="errors.has('password')"
                    :error-messages="errors.first('password')"
                    @click:append="showPassword = !showPassword"></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-text-field
                    tabindex="2"
                    :loading="loading"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showRePassword ? 'text' : 'password'"
                    v-model="passwordRe"
                    data-vv-as="password"
                    v-validate="'required|confirmed:password'"
                    id="passwordRe"
                    prepend-icon="mdi-lock"
                    name="passwordRe"
                    label="Repeat password"
                    placeholder="Repeat password"
                    :error="errors.has('passwordRe')"
                    :error-messages="errors.first('passwordRe')"
                    @click:append="showRePassword = !showRePassword"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit" :loading="loading" :disabled="this.email === '' || this.token === '' || loading">{{$t('other.save')}}</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import User from '../../services/UsersService'
export default {
  data: () => ({
    password: '',
    passwordRe: '',
    message: '',
    email: '',
    token: '',
    showPassword: false,
    showRePassword: false
  }),
  mounted () {
    if (this.$route.query.email && this.$route.query.token) {
      this.email = this.$route.query.email
      this.token = this.$route.query.token
    }
  },
  methods: {
    onSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.dispatch('common/setLoading', true)
          const data = {
            email: this.email,
            token: this.token,
            password: this.password
          }
          User.activateUser(data).then(res => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data.error) {
              this.message = this.$t(res.data.message)
            } else if (res.data._id) {
              this.$router.push({name: 'login'})
            }
          }).catch(err => {
            if (err) {
              console.log(err)
            }
          })
        }
      })
    }
  },
  computed: {
    ...mapGetters('common', ['loading'])
  }
}
</script>
