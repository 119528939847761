<template>
    <v-menu offset-y min-width="140" absolute>
      <template v-slot:activator="{ on, attrs }">
        <div class="division-column" v-bind="attrs" @contextmenu.prevent="open($event, on)">
          {{ text }}
        </div>
      </template>
      <v-list v-if="!disabled">
        <v-list dense>
          <v-list-item-group :value="null">
            <v-list-item @click="onChange">
              <v-list-item-content>
                <v-list-item-title>{{isOn ? 'Remove from list' : 'On the list'}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon small> {{isOn ? 'mdi-close' : 'mdi-check' }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-list>
    </v-menu>
  </template>
  
<script>
export default {
  name: 'DivisionColumn',
  props: {
    text: {
      required: false,
      type: String
    },
    isOn: {
      required: true,
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    onChange() {
      this.$emit('change', !this.isOn)
    },
    open(e, activator){
      const yardTitles = document.getElementsByClassName('AvailableTable__title')
      yardTitles[0].click()
      activator.click(e)
    }
  }
}
</script>
  
  <style lang="scss">
  .division-column {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  </style>