<template>
  <div class="comments-wrapper">
    <v-list class="comments">
      <template v-for="(item, index) of comments">
        <v-list-item  :key="item._id || item.id">
          <v-list-item-content :title="item.user_email">
            <div class="comment-actions" v-if="showActions && user && user.email === item.user_email">
              <v-btn v-if="item._id || item.id"
                icon
                color="primary"
                small
                @click="onEditComment(item)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                color="pink"
                small
                @click="onDeleteComment(item._id || item.id || index)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-list-item-subtitle class="comment-text">
              <span class="text--primary">{{item.user_name}}:</span>
              <br/>
              <span style="white-space: pre-wrap;">{{item.text}}</span>
            </v-list-item-subtitle>
            <div class="comment-type" v-if="item.commentType && item.commentType !== ''">
              <v-chip small label text-color="white" :class="[item.commentType]">{{item.commentType}}</v-chip>
              <div class="comment-date-from-to" v-if="item.commentDateRange && item.commentDateRange.length > 1">
                <span :class="[item.commentType]">{{item.commentDateRange[0] | formatDateUSA('MM/DD/YYYY')}}</span> — <span :class="[item.commentType]">{{item.commentDateRange[1] | formatDateUSA('MM/DD/YYYY')}}</span>
              </div>
              <div class="comment-date-from-to" v-else>
                <span :class="[item.commentType]">{{item.dateFrom | formatDateUSA('MM/DD/YYYY')}}</span> — <span :class="[item.commentType]">{{item.dateTo | formatDateUSA('MM/DD/YYYY')}}</span>
              </div>
            </div>
            <v-list-item-subtitle class="comment-date">
              <span>{{item.created || new Date() | formatDateUSA('MM/DD/YYYY HH:mm')}}</span>
              <template v-if="item.modified && item.modified !== item.created"> / <span>{{item.modified | formatDateUSA('MM/DD/YYYY HH:mm')}}</span> </template></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          class="comment-divider"
          :key="`divider-${index}`"
          v-if="index < comments.length - 1"
        ></v-divider>
      </template>
    </v-list>
    <form v-if="withForm" @submit.prevent="onAddComment">
        <mention-input
          @onEnter="handleEnter"
          ref="comtext"
          :autofocus="autofocus"
          name="comment"
          placeholder="Comment"
          label=""
          :rules="rules"
          counter
          v-model="comment"/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editCommentId !== null"
            text
            small
            @click="cancelEdit"
          >
            Cancel edit
          </v-btn>
          <v-btn
            v-else
            text
            small
            @click="onCancel"
          >
            {{cancelText}}
          </v-btn>
          <v-btn
            type="submit"
            color="success"
            small
            :loading="loading"
            :disabled="comment === '' || comment.length > 500"
          >
            {{editCommentId === null ? 'Save' : 'Edit'}}
          </v-btn>
        </v-card-actions>
      </form>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import loadsService from '../../services/LoadsService'
import callsService from '../../services/CallsService'
import MentionInput from './MentionInput'
export default {
  name: 'Comments',
  props: {
    comments: {
      type: Array,
      default: () => []
    },
    loadId: {
      type: String,
      default: ''
    },
    callId: {
      type: String,
      default: ''
    },
    showActions: {
      type: Boolean,
      default: false
    },
    withForm: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    }
  },
  components: {
    MentionInput
  },
  data: () => ({
    comment: '',
    editCommentId: null,
    rules: [v => v.length <= 500 || 'Max 500 characters']
  }),
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('dashboard', ['selectedBoard']),
    ...mapGetters('eldDashboard', ['selectedShift'])
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    getCommentData() {
      const comment = {
        text: this.comment,
        user_email: this.user.email,
        user_name: this.user.name,
      }

      if (this.editCommentId) comment['_id'] = this.editCommentId

      return comment
    },
    onDeleteComment (commentId) {
      let confVal = confirm('Are you sure you want to delete comment?')
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        if (this.loadId) {
          loadsService.deleteComment(this.loadId, commentId, this.selectedBoard).then(res => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data.status === 'OK') {
              this.setMessage('Comment successfully deleted')
            }
          })
        } else if (this.callId) {
          callsService.deleteComment(this.callId, commentId, this.selectedShift).then(res => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data.status === 'OK') {
              this.setMessage('Comment successfully deleted')
            }
          })
        } else {
          this.$emit('delete', commentId)
        }
      }
    },
    onEditComment (comment) {
      if (this.withForm) {
        this.editCommentId = comment._id
        this.comment = comment.text
        this.$refs.comtext.$refs.minput.$refs.input.focus()
        return
      }
      this.$emit('edit', comment)
    },
    onAddComment () {
      this.$emit('save', this.getCommentData())
      this.cancelEdit()
    },
    onCancel () {
      this.cancelEdit()
      this.$emit('cancel')
    },
    handleEnter(e) {
      if (e.ctrlKey) {
        this.onAddComment(this.comment)
      }
    },
    cancelEdit() {
      this.editCommentId = null
      this.comment = ''
    }
  }
}
</script>

<style lang="scss">
.comments {
  max-height: 200px;
  overflow-y: scroll;

  .comment-divider {
    border-color: rgba(0, 0, 0, 0.12) !important;
  }

  .v-list-item {
    position: relative;

    .comment-actions {
      position: absolute;
      right: 2px;
      top: 0px;
    }
    .comment-text {
      white-space: normal;
    }
    .comment-date {
      text-align: right;
      font-size: 11px;

      span {
        color: #2196f3;
      }
    }

    .comment-type {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .comment-date-from-to {
        font-size: 11px;
        text-align: right;
        flex: 1;
        color: rgba(0, 0, 0, 0.6);
        span {
          &.VACATION {
            color: rgb(76, 175, 8);
          }

          &.HOMETIME {
            color: #2196f3;
          }

          &.OFF {
            color: #ff5252;
          }
        }
      }

      .v-chip {
        &.VACATION {
          background-color: rgb(76, 175, 8);
        }

        &.HOMETIME {
          background-color: #2196f3;
        }

        &.OFF {
          background-color: #ff5252;
        }
      }
    }
  }
}
</style>