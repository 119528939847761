import Api from '@/services/Api'

export default {
  getOilChangeHistoriesByTruckId(number, id) {
    return Api().get(`/trucks/oil-change-history-by-truck/${number}/${id}`)
  },
  addOilChangeHistory(oilChangeHistory) {
    return Api().post('/trucks/oil-change-history', oilChangeHistory)
  },
  editOilChangeHistory(oilChangeHistory) {
    return Api().put('/trucks/oil-change-history', oilChangeHistory)
  },
  deleteOilChangeHistory(id) {
    return Api().delete(`/trucks/oil-change-history/${id}`)
  },
}
