<template>
  <div  class="flex">
    <v-layout justify-space-between>
        <div>
          <v-btn
            class="mr-2"
            icon
            small
            @click="getData"
          >
            <v-icon>mdi-cached</v-icon>
          </v-btn>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                :disabled="selectedBoard === null || dispatchers.length === 0 || searchActivated"
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="downloadReport()"
              >
                <v-icon>mdi-file-pdf-box</v-icon>
              </v-btn>
            </template>
            <span>Download the PDF for the current state of the {{getSelectedBoardName}}</span>
          </v-tooltip>
          <v-btn
            :disabled="selectedBoard === null || dispatchers.length === 0"
            depressed
            small
            @click="goToday"
          >
            Today
          </v-btn>
        </div>
        <div v-if="layout !== 'mobile'">
          <p class="date-from-to subtitle-2">
            <v-menu
              v-model="startDatePicker"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <span class="font-weight-bold date-picker-text" v-bind="attrs" v-on="on">{{startOfWeek | formatDateObj('DD.MMM')}}</span>
              </template>
              <v-date-picker
                v-model="startDatePickerValue"
                no-title
                color="green lighten-1"
                @input="onInputStartDate"
              >
              </v-date-picker>
            </v-menu>
            - {{endOfWeek | formatDateObj('DD.MMM')}}</p>
        </div>
        <div class="align-right">
          <v-btn
            :disabled="selectedBoard === null || dispatchers.length === 0 || loading"
            depressed
            color="primary"
            class="mr-2"
            small
            @click="goPrev"
          >
            <v-icon left> mdi-arrow-left </v-icon>
            {{layout !== 'mobile' ? 'Prev' : ''}}
          </v-btn>
          <v-btn
            :disabled="selectedBoard === null || dispatchers.length === 0 || loading"
            depressed
            color="primary"
            small
            @click="goNext"
          >
            {{layout !== 'mobile' ? 'Next' : ''}}
            <v-icon right> mdi-arrow-right </v-icon>
          </v-btn>
        </div>
    </v-layout>
    <v-layout v-if="layout === 'mobile'">
      <p class="date-from-to mobile subtitle-2">{{startOfWeek | formatDateObj('DD.MMM')}} - {{endOfWeek | formatDateObj('DD.MMM')}}</p>
    </v-layout>
    <v-layout>
      <v-progress-circular
        v-if="loading"
        indeterminate
        class="dashboard-loader"
        color="primary"
      ></v-progress-circular>
      <div id="capture" class="table-container" v-if="dispatchers.length" :class="{loading: loading, 'report-visible': reportTableVisible}">
        <table id="stay-table" class="main-table" :class="{dark: dark}">
          <thead>
            <tr>
              <th v-for="(th, index) in tableConfig[$config.APP_KEY]" :class="[th.thClass]" :style="{minWidth: `${th.width}px`, left: layout !== 'mobile' ? `${th.left}px` : `${th.mLeft}px`}" :key="`thh-${index}`" :title="th.title">{{th.name}}</th>
              <th class="separator" :style="{left: `${separatorLeft}px`}"></th>
              <th v-for="(day, index) in generateDates" :key="`th-${index}`" class="days" :class="[{today: todayDate === day.formatedDate}]">
                <span>{{day.displayDate}}</span>
                {{day.dayName}}
              </th>
            </tr>
          </thead>
          <draggable v-for="dispatcher in dispatchers" :key="dispatcher.id" :id="dispatcher.id" tag="tbody" v-model="dispatcher.drivers" :group="{ name: `drivers-${dispatcher.id}`, pull: true, put: true }" handle=".drag-icon" @end="onMoveRow" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : ''}">
            <tr
              v-for="driver in dispatcher.drivers"
              :key="driver._id"
              :id="driver._id"
              :data-name="driver.name"
              :class="{inactive: !driver.is_active}"
            >
              <template v-for="(td, index) in tableConfig[$config.APP_KEY]">
                <td v-if="td.field === 'name'" :key="`td-${index}`" class="sticky-col" :title="!driver.is_active ? 'Inactive driver' : ''" @contextmenu.prevent="editDriver(driver._id)" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`}">
                  <v-icon v-if="permissions.DISPATCHER_ALL" class="drag-icon" right> mdi-drag </v-icon>
                  <span class="driver-name" :class="{noCut: driver.no_cut}">
                    <template v-if="driver.formatedName">
                      {{driver.formatedName}}
                    </template>
                    <template v-else-if="driver.name.indexOf('/') === -1">
                      {{ driver.name }}
                    </template>
                    <template v-else>
                      {{driver.name.split('/')[0]}} <br/>
                      {{driver.name.split('/')[1]}}
                    </template>
                  </span>
                  <v-tooltip v-if="driver.note && driver.note !== ''" right max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="note-icon" v-bind="attrs" v-on="on" right> mdi-comment-text-outline </v-icon>
                    </template>
                    <span style="white-space: pre-wrap;">{{ driver.note }}</span>
                  </v-tooltip>
                </td>
                <td v-else-if="td.field === 'dispatcher_ext'" :key="`td-${index}`"
                :class="[td.tdClass, {bold: dispatcher.isTeamLead}]"
                :style="{
                  backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '',
                  width: `${td.width}px`,
                  left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`,
                  textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}
                ">
                  {{dispatcher.name}} {{driver[td.field] && driver[td.field] !== '' ? `- ${driver[td.field]}` : '' }}
                  <template v-if="driver.substitute_user"><br/> {{driver.substitute_user.name}}</template>
                </td>
                <td v-else-if="td.field === 'phone'" :key="`td-${index}`" :class="[td.tdClass]" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  <template v-if="driver.phone.indexOf('/') === -1">{{driver.phone}}</template>
                  <template v-else>{{driver.phone.split('/')[0].trim()}}<br/>{{driver.phone.split('/')[1].trim()}}</template>
                  <template v-if="(driver.owner_phone && driver.owner_phone.length)"><br/><strong title="Owner phone number">{{driver.owner_phone}}</strong></template>
                </td>
                <td v-else-if="td.field === 'fc'" :key="`td-${index}`" :class="[td.tdClass]" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">{{ driver.fc  ? 'ON' : 'OFF' }}</td>
                <td v-else-if="td.field === 'fleetManager'" :key="`td-${index}`" :class="[td.tdClass]" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  <v-tooltip v-if="driver.fleetManager && driver.fleetManager.name" left max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="" v-bind="attrs" v-on="on" right @click="$router.push(`/contacts-board?name=${driver.fleetManager.name}`)">
                        {{ driver.fleetManager && driver.fleetManager.name !== '' ? driver.fleetManager.name.split(" ").map((n)=>n[0]).join(".") : '-' }}
                      </span>
                    </template>
                    <span style="white-space: pre-wrap;">{{driver.fleetManager.name}}</span>
                  </v-tooltip>
                </td>
                <td v-else-if="td.field === 'payroll'" :key="`td-${index}`" :class="[td.tdClass]" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  <v-tooltip v-if="driver.payroll && driver.payroll.name" left max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="" v-bind="attrs" v-on="on" right @click="$router.push(`/contacts-board?name=${driver.payroll.name}`)">
                        {{ driver.payroll && driver.payroll.name !== '' ? driver.payroll.name.split(" ").map((n)=>n[0]).join(".") : '-' }}
                      </span>
                    </template>
                    <span style="white-space: pre-wrap;">{{driver.payroll.name}}</span>
                  </v-tooltip>
                </td>
                <td v-else-if="td.field === 'has_app_acc'"
                    :key="`td-${index}`"
                    :class="[td.tdClass]"
                    :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  {{ driver.has_app_acc ? '✓' : '' }}
                </td>
                <td v-else-if="td.field === 'trailer_manager'" :key="`td-${index}`" :class="[td.tdClass]" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  <v-tooltip v-if="driver.trailer_manager && driver.trailer_manager.name" left max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="" v-bind="attrs" v-on="on" right @click="$router.push(`/contacts-board?name=${driver.trailer_manager.name}`)">
                        {{ driver.trailer_manager && driver.trailer_manager.name !== '' ? driver.trailer_manager.name.split(" ").map((n)=>n[0]).join(".") : '-' }}
                      </span>
                    </template>
                    <span style="white-space: pre-wrap;">{{driver.trailer_manager.name}}</span>
                  </v-tooltip>
                </td>
                <td v-else-if="td.field === 'price'" :key="`td-${index}`" :class="[td.tdClass]" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  $<Sum :loads="driver.loads" type="price"/>
                </td>
                <td v-else-if="td.field === 'miles'" :key="`td-${index}`" :class="[td.tdClass]" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  <Sum :loads="driver.loads" type="miles"/> mi
                </td>
                <td v-else-if="td.field === 'sum'" :key="`td-${index}`" :class="[td.tdClass]" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  <Average :loads="driver.loads"/>
                </td>
                <td v-else-if="td.field === 'board'" :key="`td-${index}`" :class="[td.tdClass]" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  {{driver.board.name}}
                </td>
                <td v-else-if="td.field === 'trailer'" :class="td.tdClass" :key="`td-${index}`" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  <router-link :to="`/trailers?search=${driver[td.field]}`" style="text-decoration: none;">{{driver[td.field]}}</router-link>
                </td>
                <td v-else-if="td.field === 'truck'" :class="td.tdClass" :key="`td-${index}`" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">
                  <router-link :to="`/trucks/all?search=${driver[td.field]}`" style="text-decoration: none;">{{driver[td.field]}}</router-link>
                </td>
                <td v-else :class="td.tdClass" :key="`td-${index}`" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : '', width: `${td.width}px`, left: layout !== 'mobile' ? `${td.left}px` : `${td.mLeft}px`, textAlign: td.align && td.align, whiteSpace: td.whiteSpace && td.whiteSpace}">{{driver[td.field]}}</td>
              </template>
              <td class="separator" :style="{left: `${separatorLeft}px`}"></td>
              <template v-for="day in generateDates">
                <Load v-if="driver.loads[day.formatedDate]"
                  @click="openEditDialog(driver.loads[day.formatedDate]._id, driver.board._id)"
                  :key="`${startOfWeek}-${driver.loads[day.formatedDate].day_key}-${driver.loads[day.formatedDate]._id}`"
                  :day="day"
                  :boardId="driver.board._id"
                  :load="driver.loads[day.formatedDate]">
                </Load>
                <td v-else
                  :key="`td-${startOfWeek}-${day.formatedDate}}`"
                  class="load-cell empty"
                  @drop='onDrop($event, day, driver._id)'
                  @dragover.prevent
                  @dragenter.prevent
                  @contextmenu.prevent="openDialog(driver._id, day.formatedDate, day.fullDate, driver.board._id)"
                  :data-date="day.formatedDate"
                  :style="{cursor: 'pointer'}">
                </td>
              </template>
            </tr>
          </draggable>
        </table>
        <!-- Tabela za izvestaje -->
          <table id="report-table" v-if="reportTableVisible" class="report-table">
            <thead>
            <tr>
              <th>Name</th>
              <th style="max-width: 50px">Truck#</th>
              <th>Tr#</th>
              <th v-if="$config.DASHBOARD_PRICE_MILES">Price</th>
              <th v-if="$config.DASHBOARD_PRICE_MILES">Miles</th>
              <th v-if="$config.DASHBOARD_PRICE_MILES">mi/$</th>
              <th class="separator"></th>
              <th v-for="(day, index) in generateDates" :key="`th-${index}`" class="days" :class="[{today: todayDate === day.formatedDate}]">
                <span>{{day.displayDate}}</span>
                {{day.dayName}}
              </th>
            </tr>
            </thead>
            <draggable v-for="dispatcher in dispatchers" :key="dispatcher.id" :id="dispatcher.id" tag="tbody" v-model="dispatcher.drivers" :group="{ name: `drivers-${dispatcher.id}`, pull: true, put: true }" handle=".drag-icon" @end="onMoveRow" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : ''}">
              <tr
                  v-for="driver in dispatcher.drivers"
                  :key="driver._id"
                  :id="driver._id"
                  :data-name="driver.name"
                  :class="{inactive: !driver.is_active}"
              >
                <td :title="!driver.is_active ? 'Inactive driver' : ''" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : ''}">
                <span class="driver-name">
                  <template v-if="driver.name.indexOf('/') === -1">
                    {{ driver.name }}
                  </template>
                  <template v-else>
                    {{driver.name.split('/')[0]}} <br/>
                    {{driver.name.split('/')[1]}}
                  </template>
                </span>
                </td>
                <td style="text-align: center;" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : ''}">{{ driver.truck }}</td>
                <td style="text-align: center;" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : ''}">{{ driver.trailer }}</td>
                <td v-if="$config.DASHBOARD_PRICE_MILES" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : ''}">$<Sum :loads="driver.loads" type="price"/></td>
                <td v-if="$config.DASHBOARD_PRICE_MILES" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : ''}"><Sum :loads="driver.loads" type="miles"/> mi</td>
                <td v-if="$config.DASHBOARD_PRICE_MILES" :style="{backgroundColor: user && userColor && user._id === dispatcher.id ? userColor : ''}"><Average :loads="driver.loads"/></td>
                <td class="separator"></td>
                <template v-for="day in generateDates">
                  <Load v-if="driver.loads[day.formatedDate]"
                        :key="`${startOfWeek}-${driver.loads[day.formatedDate].day_key}-${driver.loads[day.formatedDate]._id}`"
                        :day="day"
                        :boardId="driver.board._id"
                        :load="driver.loads[day.formatedDate]">
                  </Load>
                  <td v-else
                      :key="`td-${startOfWeek}-${day.formatedDate}}`"
                      class="load-cell empty"
                      :data-date="day.formatedDate"
                      :style="{cursor: 'pointer'}">
                  </td>
                </template>
              </tr>
            </draggable>
          </table>
      </div>
      <p v-else-if="!loading" class="date-from-to subtitle-1">There is no data for the selected filters</p>
    </v-layout>
    <v-layout class="bottom-content">
      <p class="rows-number subtitle-1" v-if="driversCount !== 0">Total number of drivers: <strong>{{driversCount}}</strong></p>
      <div v-if="dispatchers.length" class="legenda">
        <v-chip
          v-for="load in loadTypes"
          :key="load._id"
          :color="load.color"
          :class="{active: load.type === selectedLoadType, default: load.type === 'DEFAULT'}"
          @click="setLoadTypeFilter(load.type)"
          small
          text-color="white"
        >
          {{load.type}}
        </v-chip>
      </div>
    </v-layout>
    <v-dialog v-model="loadDialog" max-width="500px" @click:outside="onCloseDialogForm">
      <v-card>
        <v-card-title>
          <span class="headline">{{dialogForm._id !== null ? 'Edit load' : 'Add Load'}}</span>
        </v-card-title>
        <form @submit.prevent="onSubmit">
          <v-card-text>
            <v-container>
              <v-row>
                <!-- <v-col cols="12">
                  <v-text-field
                    :disabled="!permissions.DISPATCHER_ALL"
                    v-model="dialogForm.name"
                    label="Delivery City*"
                    required
                    maxlength="40"
                    counter="40"
                    :hide-details="dialogForm.name.length <= 39"
                    :autofocus="true"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" v-if="$config.LOAD_ORIGIN && (!dialogForm.absenceType || dialogForm.absenceType === '')">
                  <v-combobox
                    :disabled="!permissions.DISPATCHER_ALL"
                    v-model="dialogForm.origin"
                    :items="citiesList"
                    label="Origin*"
                    :search-input.sync="searchCitiesOrigin"
                    :hide-details="!dialogForm.origin || dialogForm.origin.length <= 30"
                    hide-no-data
                    maxlength="40"
                    counter="40"
                    :loading="loading"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    :autofocus="!dialogForm._id && $config.LOAD_ORIGIN ? true : false"
                    clearable
                  ></v-combobox>
                </v-col>
                <v-col cols="12">
                  <v-combobox v-if="!dialogForm.absenceType || dialogForm.absenceType === ''"
                    :disabled="!permissions.DISPATCHER_ALL"
                    v-model="dialogForm.name"
                    :items="citiesList"
                    return-object
                    item-text="name"
                    label="Delivery City*"
                    :search-input.sync="searchCities"
                    :hide-details="!dialogForm.name || dialogForm.name.length <= 30"
                    hide-no-data
                    :loading="loading"
                    maxlength="40"
                    counter="40"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    :autofocus="!dialogForm._id && !$config.LOAD_ORIGIN ? true : false"
                    clearable
                  ></v-combobox>
                  <v-text-field v-else
                    :readonly="true"
                    v-model="dialogForm.name"
                    label="Delivery City*"
                    :hide-details="true"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="$config.DASHBOARD_PRICE_MILES">
                  <v-text-field
                    :disabled="!permissions.DISPATCHER_ALL"
                    v-model="dialogForm.price"
                    label="Price"
                    prefix="$"
                    hide-details="auto"
                    :rules="[rules.number]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="$config.DASHBOARD_PRICE_MILES">
                  <v-text-field
                    :disabled="!permissions.DISPATCHER_ALL"
                    v-model="dialogForm.miles"
                    label="Miles"
                    suffix="mi"
                    hide-details="auto"
                    :rules="[rules.number]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :disabled="!permissions.DISPATCHER_ALL"
                    v-model="dialogForm.loadType"
                    :items="loadTypes"
                    item-text="type"
                    return-object
                    label="Load type*"
                    :hide-details="true"
                    :loading="loading"
                  >
                    <template v-slot:selection="data">
                      <span class="load-type-color" :style="{color: data.item.type !== 'DEFAULT' ? data.item.color : '#000'}">{{data.item.type}}</span>
                    </template>
                    <template v-slot:item="data">
                      <span class="load-type-color" :style="{color: data.item.type !== 'DEFAULT' ? data.item.color : '#000'}">{{data.item.type}}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    :disabled="!permissions.DISPATCHER_ALL"
                    v-model="dialogForm.is_dedicated"
                    hide-details="auto"
                    dense
                    label="Dedicated load"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="5">
                      <v-select
                        clearable
                        :disabled="dialogForm._id !== null"
                        :items="commentTypes"
                        v-model="dialogForm.absenceType"
                        label="Type of absence"
                        height="24"
                        :hint="`Load name will be change`"
                        persistent-hint
                        class="menu-comment-type"
                        single-line
                        @change="onSelectAbsenceType"
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-menu
                        ref="menu2"
                        :disabled="!dialogForm.absenceType || dialogForm.absenceType === ''"
                        v-model="menuDate2"
                        :close-on-content-click="false"
                        :return-value.sync="dialogForm.dateTo"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="formatDate2"
                            :disabled="!dialogForm.absenceType || dialogForm.absenceType === '' || dialogForm._id !== null"
                            label="Date from - to"
                            readonly
                            height="24"
                            class="comment-text-field"
                            hint="Repeat this load for selected dates"
                            persistent-hint
                            single-line
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dialogForm.dateTo"
                          no-title
                          color="green lighten-1"
                          :allowed-dates="allowedDates"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="cancelDate2"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(dialogForm.dateTo)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-card>
                    <v-col cols="12">
                      <mention-input
                        @onEnter="handleEnter($event)"
                        ref="comtext"
                        outlined
                        name="comment"
                        label="Comment"
                        counter
                        hide-details="auto"
                        v-model="dialogForm.commentText"
                      />
                    </v-col>
                    <!-- <v-container>
                      <v-row>
                        <v-col cols="5">
                          <v-select
                            :items="commentTypes"
                            v-model="dialogForm.commentType"
                            label="Type of absence"
                            height="24"
                            :hide-details="true"
                            class="menu-comment-type"
                            single-line
                          ></v-select>
                        </v-col>
                        <v-col cols="7">
                          <v-menu
                            ref="menu"
                            :disabled="dialogForm.commentType === ''"
                            v-model="menuDate"
                            :close-on-content-click="false"
                            :return-value.sync="dialogForm.commentDateRange"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formatDate"
                                :disabled="dialogForm.commentType === ''"
                                label="Date from - to"
                                readonly
                                height="24"
                                class="comment-text-field"
                                :hide-details="true"
                                single-line
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="dialogForm.commentDateRange"
                              no-title
                              range
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="cancelDate"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(dialogForm.commentDateRange)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container> -->
                    <v-col cols="12">
                      <v-btn :loading="loading" :disabled="dialogForm.commentText === '' || dialogForm.commentText.length > 500 || !permissions.COMMENTS" @click="addComment" color="success" small>
                        {{editCommentId === null ? 'Add comment' : 'Edit comment'}}
                      </v-btn>
                      <v-btn @click="cancelCommentEdit" class="ml-3" small v-if="editCommentId">
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="dialogForm.comments.length">
                      <Comments @edit="onEditComment" :comments="dialogForm.comments" :loadId="dialogForm._id" :showActions="true" :key="`comments-${dialogForm.comments.length}`"/>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled="!permissions.DISPATCHER_ALL || !isAllowed"
              :loading="loading"
              v-if="dialogForm._id"
              color="error"
              @click="onDeleteLoad(dialogForm._id)"
            >
              Delete
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onCloseDialogForm"
            >
              Close
            </v-btn>
            <v-btn
              type="submit"
              color="info"
              :loading="loading"
              :disabled="dialogForm.commentText.length > 500 || dialogForm.name === '' || !isAllowed || !this.permissions.DISPATCHER_ALL"
            >
              Save
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="formDialogModal" width="800px">
      <v-card>
        <drivers-form @onClose="formDialogModal = false" :driver_id="driver_id"></drivers-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment/src/moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import draggable from 'vuedraggable'
import loadsService from '../../services/LoadsService'
import driversService from '../../services/DriverService'
import DriversForm from '../Drivers/Form'
import Load from './Load'
import Comments from '../Common/Comments'
import MentionInput from '../Common/MentionInput'
import debounce from 'lodash/debounce'
import Sum from './Sum.vue'
import Average from './AveragePerMile.vue'
import { loadSelectedGenerator, removeSelectedUserFromLoad } from '../../utils'

export default {
  components: {
    draggable,
    DriversForm,
    Load,
    Comments,
    MentionInput,
    Sum,
    Average
  },
  data: () => ({
    layout: 'desktop',
    hover: false,
    selectedLoadType: '',
    startDate: new Date(),
    todayDate: moment(new Date()).format('DD-MM-YYYY'),
    dialogForm: {
      _id: null,
      name: '',
      origin: '',
      miles: '',
      price: '',
      driver_id: null,
      day_key: '',
      fullDate: null,
      loadType: null,
      absenceType: '',
      dateFrom: '',
      dateTo: '',
      is_dedicated: false,
      commentText: '',
      commentType: '',
      commentDateRange: [],
      comments: [],
      board_id: null
    },
    driver_id: null,
    loadDialog: false,
    loadTypes: [],
    dispatchers: [],
    driversMap: {},
    menu: false,
    menuDate: false,
    menuDate2: false,
    commentTypes: ['HOMETIME', 'VACATION', 'OFF'],
    isConnected: false,
    editCommentId: null,
    searchCities: '',
    searchCitiesOrigin: '',
    citiesList: [],
    startDatePicker: false,
    startDatePickerValue: new Date().toISOString().substr(0, 10),
    rules: {
      number: value => {
        if (!value || value.length === 0) return true
        return !isNaN(value) && !isNaN(parseFloat(value)) || 'Value not number'
      }
    },
    reportTableVisible: false,
    tableConfig: {
      'superegoholding': [
        {
          name: 'Co',
          title: 'Company',
          field: 'company',
          width: 30,
          left: 0,
          mLeft: 0,
          tdClass: 'light-col',
          thClass: 'small'
        },
        {
          name: 'Name',
          title: '',
          field: 'name',
          width: 165,
          left: 30,
          mLeft: 0,
          tdClass: 'sticky-col',
          thClass: ''
        },
        {
          name: 'Type',
          title: '',
          field: 'type_trailer',
          width: 55,
          left: 195,
          mLeft: 0,
          tdClass: 'sticky',
          thClass: '',
          align: 'center'
        },
        {
          name: 'Floyd Ext',
          title: '',
          field: 'dispatcher_ext',
          width: 90,
          left: 250,
          mLeft: 0,
          tdClass: 'sticky',
          thClass: 'small',
          align: 'center',
          whiteSpace: 'normal'
        },
        {
          name: 'TryTime Ext',
          title: '',
          field: 'dispatcher_ext_second',
          width: 75,
          left: 340,
          mLeft: 0,
          tdClass: 'sticky',
          thClass: 'small',
          align: 'center',
          whiteSpace: 'normal'
        },
        {
          name: 'Rocket Ext',
          title: '',
          field: 'dispatcher_ext_third',
          width: 75,
          left: 415,
          mLeft: 0,
          tdClass: 'sticky',
          thClass: 'small',
          align: 'center',
          whiteSpace: 'normal'
        },
        {
          name: 'Jordan Ext',
          title: '',
          field: 'dispatcher_ext_fourth',
          width: 75,
          left: 490,
          mLeft: 0,
          tdClass: 'sticky',
          thClass: 'small',
          align: 'center',
          whiteSpace: 'normal'
        },
        {
          name: 'Truck#',
          title: '',
          field: 'truck',
          width: 60,
          left: 565,
          mLeft: 0,
          tdClass: 'sticky',
          thClass: '',
          align: 'center',
          whiteSpace: 'nowrap'
        },
        {
          name: 'Tr#',
          title: '',
          field: 'trailer',
          width: 80,
          left: 625,
          mLeft: 0,
          tdClass: 'sticky',
          thClass: '',
          align: 'center',
          whiteSpace: 'nowrap'
        },
        {
          name: 'Phone',
          title: '',
          field: 'phone',
          width: 100,
          left: 705,
          mLeft: 0,
          tdClass: 'sticky',
          thClass: '',
          align: 'center',
          whiteSpace: 'break-spaces'
        },
        {
          name: 'Fc',
          title: 'Fuel card',
          field: 'fc',
          width: 31,
          left: 805,
          mLeft: 0,
          tdClass: ['sticky', 'small'],
          thClass: 'small',
          align: 'center',
          whiteSpace: 'normal'
        },
        {
          name: 'Fm',
          title: 'Fleet manager',
          field: 'fleetManager',
          width: 40,
          left: 836,
          mLeft: 0,
          tdClass: ['sticky', 'small'],
          thClass: 'small',
          align: 'center',
          whiteSpace: 'normal'
        },
        {
          name: 'Pr',
          title: 'Payrolle',
          field: 'payroll',
          width: 30,
          left: 876,
          mLeft: 0,
          tdClass: ['sticky', 'small'],
          thClass: 'small',
          align: 'center',
          whiteSpace: 'normal'
        },
        {
          name: 'APP',
          title: 'has_app_acc',
          field: 'has_app_acc',
          width: 30,
          left: 906,
          mLeft: 0,
          tdClass: ['sticky', 'small'],
          thClass: 'small',
          align: 'center',
          whiteSpace: 'normal'
        },

        // {
        //   name: 'Tm',
        //   title: 'Trailer Manager',
        //   field: 'trailer_manager',
        //   width: 30,
        //   left: 896,
        //   mLeft: 0,
        //   tdClass: ['sticky', 'small'],
        //   thClass: 'small',
        //   align: 'center',
        //   whiteSpace: 'normal'
        // }
      ],
      // 'summalogistics': [
      //   {
      //     name: 'Co',
      //     title: 'Company',
      //     field: 'company',
      //     width: 30,
      //     left: 0,
      //     mLeft: 0,
      //     tdClass: 'light-col',
      //     thClass: 'small'
      //   },
      //   {
      //     name: 'Name',
      //     title: '',
      //     field: 'name',
      //     width: 165,
      //     left: 30,
      //     mLeft: 0,
      //     tdClass: 'sticky-col',
      //     thClass: ''
      //   },
      //   {
      //     name: 'Type',
      //     title: '',
      //     field: 'type_trailer',
      //     width: 45,
      //     left: 195,
      //     mLeft: 0,
      //     tdClass: 'sticky',
      //     thClass: '',
      //     align: 'center'
      //   },
      //   {
      //     name: 'Dis - ext',
      //     title: '',
      //     field: 'dispatcher_ext',
      //     width: 100,
      //     left: 240,
      //     mLeft: 0,
      //     tdClass: 'sticky',
      //     thClass: '',
      //     align: 'center',
      //     whiteSpace: 'normal'
      //   },
      //   {
      //     name: 'Truck#',
      //     title: '',
      //     field: 'truck',
      //     width: 140,
      //     left: 340,
      //     mLeft: 0,
      //     tdClass: 'sticky',
      //     thClass: '',
      //     align: 'center',
      //     whiteSpace: 'nowrap'
      //   },
      //   {
      //     name: 'Tr#',
      //     title: '',
      //     field: 'trailer',
      //     width: 80,
      //     left: 400,
      //     mLeft: 0,
      //     tdClass: 'sticky',
      //     thClass: '',
      //     align: 'center',
      //     whiteSpace: 'nowrap'
      //   },
      //   {
      //     name: 'Phone',
      //     title: '',
      //     field: 'phone',
      //     width: 100,
      //     left: 480,
      //     mLeft: 0,
      //     tdClass: 'sticky',
      //     thClass: '',
      //     align: 'center',
      //     whiteSpace: 'break-spaces'
      //   },
      //   {
      //     name: 'Fc',
      //     title: 'Fuel card',
      //     field: 'fc',
      //     width: 31,
      //     left: 580,
      //     mLeft: 0,
      //     tdClass: ['sticky', 'small'],
      //     thClass: 'small',
      //     align: 'center',
      //     whiteSpace: 'normal'
      //   },
      //   {
      //     name: 'Fm',
      //     title: 'Fleet manager',
      //     field: 'fleetManager',
      //     width: 30,
      //     left: 611,
      //     mLeft: 0,
      //     tdClass: ['sticky', 'small'],
      //     thClass: 'small',
      //     align: 'center',
      //     whiteSpace: 'normal'
      //   },
      //   {
      //     name: 'Price',
      //     title: '',
      //     field: 'price',
      //     width: 60,
      //     left: 641,
      //     mLeft: 0,
      //     tdClass: ['sticky', 'small'],
      //     thClass: 'small',
      //     align: 'center',
      //     whiteSpace: 'normal'
      //   },
      //   {
      //     name: 'Miles',
      //     title: '',
      //     field: 'miles',
      //     width: 60,
      //     left: 701,
      //     mLeft: 0,
      //     tdClass: ['sticky', 'small'],
      //     thClass: 'small',
      //     align: 'center',
      //     whiteSpace: 'normal'
      //   },
      //   {
      //     name: '$/mi',
      //     title: '',
      //     field: 'sum',
      //     width: 60,
      //     left: 761,
      //     mLeft: 0,
      //     tdClass: ['sticky', 'small'],
      //     thClass: 'small',
      //     align: 'center',
      //     whiteSpace: 'normal'
      //   }
      // ]
    }
  }),
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    handleFmName(fm){
      if (fm && fm.name){
        this.show
      }
    },
    downloadReport() {
      this.$store.dispatch('common/setLoading', true)
      window.scrollTo(0, 0)
      document.getElementById('stay-table').style.display = 'none'
      this.reportTableVisible = true
      setTimeout(() => {
        html2canvas(document.getElementById('capture'),{
        }).then((canvas) => {
          let img = canvas.toDataURL('image/jpeg', 1)
          let doc = new jsPDF('l', 'mm', 'a4')
          let width = doc.internal.pageSize.getWidth()
          let height = doc.internal.pageSize.getHeight()
          doc.addImage(img, 'JPEG', 0, 0, width, height)
          doc.save(`${this.getSelectedBoardName}_${moment(new Date()).format('DD_MM_YYYY_HH:MM')}`)
          //Ako Hoce i sliku posle samo ovo ukljuciti kao opciju
          // var a = document.createElement('a');
          // a.href = canvas.toDataURL('image/jpeg');
          // a.download = 'somefile.jpg';
          // a.click();
          this.$store.dispatch('common/setLoading', false)
          this.reportTableVisible = false
          // window.location.reload()
          if(!this.reportTableVisible) {
            document.getElementById('stay-table').style.display = 'table'
          }
        })
      }, 500)
    },
    getWindowWidth() {
      let windowWidth = document.documentElement.clientWidth
      if (windowWidth <= 992) {
        this.layout = 'mobile'
      } else if (windowWidth <= 1627) {
        this.layout = 'laptop'
      } else {
        this.layout = 'desktop'
      }
    },
    goPrev () {
      // if (this.layout !== 'mobile') {
      //   this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() - 7))
      // } else {
      //   this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() - 3))
      // }
      this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() - 7))
      // set datepicker date to start of week
      this.startDatePickerValue = moment(this.startDate).startOf('isoWeek').add(1, 'd').toDate().toISOString().substr(0, 10)
      this.getData()
    },
    goNext () {
      // if (this.layout !== 'mobile') {
      //   this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() + 7))
      // } else {
      //   this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() + 3))
      // }
      this.startDate = new Date(this.startDate.setDate(this.startDate.getDate() + 7))
      // set datepicker date to start of week
      this.startDatePickerValue = moment(this.startDate).startOf('isoWeek').add(1, 'd').toDate().toISOString().substr(0, 10)
      this.getData()
    },
    goToday () {
      this.startDate = new Date()
      this.startDatePickerValue = new Date().toISOString().substr(0, 10)
      this.getData()
    },
    keyDownHandler (e) {
      if (this.selectedBoard !== null && e.key === 'ArrowLeft') {
        this.goPrev()
      } else if (this.selectedBoard !== null && e.key === 'ArrowRight') {
        this.goNext()
      }
    },
    onMoveRow (evt) {
      let confVal = confirm('Are you sure you want to save your changes?')
      if (confVal) {
        driversService.updateDispatchersDriver(evt.to.id, evt.item.id, this.selectedBoard).then(res => {
          if(res.data.status === 'OK') {
            this.setMessage(`Driver '${evt.item.dataset.name}' successfully moved`)
          }
        })
      }
    },
    openDialog (driver_id, day_key, fullDate, board_id) {
      if (this.permissions.DISPATCHER_ALL) {
        this.dialogForm.board_id = board_id
        this.dialogForm.driver_id = driver_id
        this.dialogForm.day_key = day_key
        this.dialogForm.dateFrom = moment.utc(fullDate).format('YYYY-MM-DD')
        this.dialogForm.dateTo = moment.utc(fullDate).format('YYYY-MM-DD')
        this.dialogForm.fullDate = fullDate
        this.loadDialog = true
      } else {
        console.log('no permission')
        return
      }
    },
    openEditDialog (loadId, board_id) {
      loadsService.getLoad(loadId).then(res => {
        this.dialogForm.board_id = board_id
        this.dialogForm._id = res.data._id
        this.dialogForm.name = res.data.name
        this.dialogForm.origin = res.data.origin ? res.data.origin : ''
        this.dialogForm.price = res.data.price !== undefined ? res.data.price : ''
        this.dialogForm.miles = res.data.miles !== undefined ? res.data.miles : ''
        this.dialogForm.driver_id = res.data.driver_id
        this.dialogForm.day_key = res.data.day_key
        this.dialogForm.fullDate = res.data.day
        this.dialogForm.loadType = res.data.loadType
        this.dialogForm.absenceType = res.data.absenceType ? res.data.absenceType : ''
        if (res.data.dateTo) {
          this.dialogForm.dateFrom = res.data.day_key
          this.dialogForm.dateTo = moment(res.data.dateTo).format('YYYY-MM-DD')
        }
        this.dialogForm.commentText = ''
        this.dialogForm.commentDateRange = []
        this.dialogForm.commentType = ''
        this.dialogForm.comments = res.data.comments
        this.dialogForm.user_id = res.data.user_id
        this.dialogForm.is_dedicated = res.data.is_dedicated
        this.loadDialog = true

        let searchTerm = res.data.name.split(',')[0]
        loadsService.getCitiesList(searchTerm).then(res => {
          if (res.data.status === 'OK') {
            this.citiesList = res.data.cities
          }
        }).catch(err => {
          console.log('trucks error search', err)
          this.citiesList = []
        })
      })
    },
    onSubmit () {
      this.$store.dispatch('common/setLoading', true)
      if (this.dialogForm._id !== null) {
        loadsService.editLoad(this.dialogForm).then(res => {

          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.setMessage(`Load '${res.data.load.name}' successfully edited`)
            this.loadDialog = false
            this.resetForm()
          }
        })
      } else {
        loadsService.addLoad(this.dialogForm).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.setMessage(`Load '${res.data.load.name}' successfully added`)
            this.resetForm()
            this.loadDialog = false
          }
        })
      }
    },
    addComment () {
      let comment = {
        text: this.dialogForm.commentText.trim(),
        commentDateRange: this.dialogForm.commentDateRange,
        commentType: this.dialogForm.commentType,
        user_email: this.user.email,
        user_name: this.user.name,
        created: new Date()
      }
      if (this.dialogForm._id === null) {
        this.dialogForm.comments.push(comment)
        this.dialogForm.commentText = ''
        this.dialogForm.commentDateRange = []
        this.dialogForm.commentType = ''
      } else {
        this.$store.dispatch('common/setLoading', true)
        if (this.editCommentId !== null) {
          comment['_id'] = this.editCommentId
          loadsService.editComment(this.dialogForm._id, comment, this.dialogForm.board_id).then(res => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data.status === 'OK') {
              this.editCommentId = null
              this.dialogForm.commentText = ''
              this.dialogForm.commentDateRange = []
              this.dialogForm.commentType = ''
              this.setMessage('Comment successfully edited')
            }
          })
        } else {
          loadsService.addComment(this.dialogForm._id, comment, this.dialogForm.board_id).then(res => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data.status === 'OK') {
              // let load = res.data.load
              this.dialogForm.commentText = ''
              this.dialogForm.commentDateRange = []
              this.dialogForm.commentType = ''
              // this.dialogForm.comments.push(load.comments[0])
              this.setMessage('Comment successfully added')
            }
          })
        }
      }
    },
    resetForm () {
      this.dialogForm._id = null
      this.dialogForm.board_id = null
      this.dialogForm.name = ''
      this.dialogForm.origin = ''
      this.dialogForm.miles = ''
      this.dialogForm.price = ''
      this.dialogForm.driver_id = null
      this.dialogForm.day_key = null
      this.dialogForm.fullDate = null
      this.dialogForm.absenceType = ''
      this.dialogForm.dateTo = ''
      this.dialogForm.dateFrom = ''
      this.dialogForm.loadType = this.loadTypes.find(el => el.type === 'DEFAULT')
      this.dialogForm.commentText = ''
      this.dialogForm.commentDateRange = []
      this.dialogForm.commentType = ''
      this.dialogForm.comments = []
      this.dialogForm.is_dedicated = false
    },
    onCloseDialogForm () {
      this.resetForm()
      this.loadDialog = false
    },
    onDeleteLoad (loadId) {
      let confVal = confirm('Are you sure you want to delete?')
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        loadsService.deleteLoad(loadId, this.dialogForm.board_id).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.resetForm()
            this.onCloseDialogForm()
            this.setMessage('Load successfully deleted')
          }
        })
      }
    },
    onChangeBoard () {
      this.getData()
    },
    setLoadTypeFilter (type) {
      if (this.selectedLoadType === type) {
        this.selectedLoadType = ''
      } else {
        this.selectedLoadType = type
      }
      // clear all loads
      for (const dispatcher of this.dispatchers) {
        for (const driver of dispatcher.drivers) {
          driver.loads = {}
        }
      }
      this.getData()
    },
    getData () {
      let filters = {
        board_id: this.selectedBoard,
        search: this.search,
        searchActivated: this.searchActivated,
        loadType: this.selectedLoadType,
        startDate: this.startOfWeek.toDate(),
        endDate: this.endOfWeek.toDate()
      }
      this.$store.dispatch('drivers/filterDashboard', filters).then(res => {
        this.dispatchers = res.dispatchers
        // kreiranje mapa za update driver-a i load-a
        let tmpMap = {}
        this.driversMap = {}
        for (const [i, dispatcher] of this.dispatchers.entries()) {
          for (const [y, driver] of dispatcher.drivers.entries()) {
            tmpMap[driver._id] = {
              userIndex: i,
              driverIndex: y
            }
          }
        }
        this.driversMap = Object.assign({}, this.driversMap, tmpMap)

        // Dodavanje / sakrivanje kolone Board
        let index = this.tableConfig[this.$config.APP_KEY].findIndex(el => el.field === 'board')
        if (this.searchActivated && index === -1) {
          this.tableConfig[this.$config.APP_KEY].push({
            name: 'Board',
            title: '',
            field: 'board',
            width: 90,
            left: 766,
            tdClass: ['sticky', 'small'],
            thClass: 'small',
            align: 'center',
            whiteSpace: 'normal'
          })
        } else if (!this.searchActivated && index !== -1) {
          this.tableConfig[this.$config.APP_KEY].splice(index, 1)
        }
      })
    },
    editDriver (id) {
      this.driver_id = id
      this.formDialogModal = true
    },
    cancelDate () {
      this.menuDate = false
      this.dialogForm.commentDateRange = []
    },
    cancelDate2 () {
      this.menuDate2 = false
      this.dialogForm.dateTo= ''
    },
    onDrop (evt, day, driverId) {
      let dragdata = evt.dataTransfer.getData('dragdata')
      dragdata = JSON.parse(dragdata)

      const loadObj = dragdata.load
      const loadObjNew = {...dragdata.load}
      let confVal = confirm(`Are you sure you want to move load to ${day.dayName} ${day.displayDate}?`)
      if (confVal) {
        loadObjNew.driver_id = driverId
        loadObjNew.day_key = day.formatedDate
        loadObjNew.day = day.fullDate
        this.$store.dispatch('common/setLoading', true)
        loadsService.moveLoad(loadObj, loadObjNew, dragdata.boardId).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.setMessage(`Load successfully moved to ${day.displayDate}`)
          }
        })
      }
    },
    onSelectAbsenceType () {
      this.dialogForm.name = this.dialogForm.absenceType ? this.dialogForm.absenceType : ''
    },
    onEditComment (comment) {
      this.editCommentId = comment._id
      this.dialogForm.commentText = comment.text
      // this.dialogForm.commentType = comment.commentType

      // if (comment.dateFrom && comment.dateTo) {
      //   let dates = []
      //   dates.push(moment(comment.dateFrom).format('YYYY-MM-DD'))
      //   dates.push(moment(comment.dateTo).format('YYYY-MM-DD'))
      //   this.dialogForm.commentDateRange = dates
      // }

      this.$refs.comtext.$refs.minput.$refs.input.focus()
    },
    cancelCommentEdit () {
      this.editCommentId = null
      this.dialogForm.commentText = ''
      this.dialogForm.commentDateRange = []
      this.dialogForm.commentType = ''
    },
    allowedDates (val) {
      let valDate = new Date(val)
      let current = new Date(this.dialogForm.dateFrom)
      let after = new Date()
      after.setMonth(after.getMonth() + 1 )
      if (valDate >= current && valDate <= after) {
        return true
      }
      return false
    },
    handleEnter (e) {
      if (e.ctrlKey) {
        this.addComment()
      }
    },
    debounceSearch: debounce( function(search) {
      search = search.split(',')[0]
      if (search && search.length) {
        this.$store.dispatch('common/setLoading', true)
        loadsService.getCitiesList(search).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.citiesList = res.data.cities
          }
        }).catch(err => {
          console.log('trucks error search', err)
          this.citiesList = []
        })
      } else {
        this.citiesList = []
      }
    }, 400),
    onInputStartDate (date) {
      this.startDatePicker = false
      this.startDate = new Date(date)
      this.getData()
    },
    setExtensionNames () {
      // promena naziva kolona za extenzije u zavisnosti od Board-a
      let boardName = this.getSelectedBoardName
      // let boardName = this.getSelectedBoardName
      let ext1 = this.tableConfig[this.$config.APP_KEY].find(el => el.field === 'dispatcher_ext')
      let ext2 = this.tableConfig[this.$config.APP_KEY].find(el => el.field === 'dispatcher_ext_third')
      let ext3 = this.tableConfig[this.$config.APP_KEY].find(el => el.field === 'dispatcher_ext_fourth')
      if (boardName === 'Kordun') {
        ext1.name = 'Rocket Ext'
        ext2.name = 'Rocket Ext 2'
        ext3.name = 'Jordan Ext 3'
      } else {
        ext1.name = 'Floyd Ext'
        ext2.name = 'Rocket Ext'
        ext3.name = 'Jordan Ext'
      }
      ext1.name = 'Ext'
    }
  },
  computed: {
    ...mapGetters('auth', ['isSuperAdmin', 'user', 'permissions']),
    ...mapGetters('common', ['loading', 'userColor', 'dark']),
    ...mapGetters('drivers', ['formDialog']),
    ...mapGetters('dashboard', ['selectedBoard', 'searchActivated', 'search']),
    ...mapGetters('boards', ['boards']),
    formDialogModal: {
      get() {
        return this.formDialog
      },
      set(value) {
        if (!value) {
          this.driver_id = null
        }
        this.$store.dispatch('drivers/setFormDialog', value)
      }
    },
    boardName () {
      let boardName = ''
      for (const board of this.boards)
      {
        if(board._id === this.selectedBoard){
          boardName = board.name
        }
      }
      return boardName
    },
    formatDate () {
      if (this.dialogForm.commentDateRange.length <= 1) return null

      const [year1, month1, day1] = this.dialogForm.commentDateRange[0].split('-')
      const [year2, month2, day2] = this.dialogForm.commentDateRange[1].split('-')
      return `from ${month1}/${day1}/${year1} to ${month2}/${day2}/${year2}`
    },
    formatDate2 () {
      if (this.dialogForm.dateTo && this.dialogForm.dateTo !== '') {
        const [year1, month1, day1] = this.dialogForm.dateTo.split('-')
        return `to ${month1}/${day1}/${year1}`
      }
      return ''
    },
    startOfWeek () {
      return moment(this.startDate).startOf('isoWeek')
    },
    endOfWeek () {
      if (this.$config.DASHBOARD_ADD_DAYS_TO_WEEK) {
        return moment(this.startDate).endOf('isoWeek').add(this.$config.DASHBOARD_ADD_DAYS_TO_WEEK, 'd')
      }
      return moment(this.startDate).endOf('isoWeek')
    },
    generateDates () {
      let days = []
      let day = this.startOfWeek

      while (day <= this.endOfWeek) {
        let momentDate = moment(day.toDate())
        let date = day.toDate()
        let userTimezoneOffset = new Date().getTimezoneOffset() * 60000
        days.push({
          fullDate: new Date(date.getTime() - userTimezoneOffset + 1000),
          dayName: momentDate.format('dddd'),
          displayDate:  this.$options.filters.formatDateObj(day, 'MM/DD/YYYY'),
          formatedDate: momentDate.format('DD-MM-YYYY'),
        })
        day = day.clone().add(1, 'd')
      }

      return days
    },
    isAllowed () {
      if (this.permissions.DISPATCHER_ALL) {
        return true
      }
      return this.selectedBoard === this.user.board_id
    },
    driversCount () {
      let driversCount = 0
      for (let dispatcher of this.dispatchers) {
        driversCount += dispatcher.drivers.length
      }
      return driversCount
    },
    getSelectedBoardName () {
      let board = this.boards.find(board => board._id === this.selectedBoard)
      if (board) {
        return board.name
      }

      return ''
    },
    separatorLeft () {
      if (this.layout !== 'mobile') {
        let temp = this.tableConfig[this.$config.APP_KEY][this.tableConfig[this.$config.APP_KEY].length - 1]
        return temp.left + temp.width
      } else {
        return 165
      }
    },
    canViewTrailes () {
      return this.permissions.TRAILER_VIEW
    }
  },
  watch: {
    selectedBoard: function(newVal, oldVal) {
      if (oldVal) {
        this.$socket.client.emit('leave_room', oldVal)
      }
      if (newVal !== oldVal && newVal !== null) {
        this.startDate = new Date()
        this.getData()
        this.$socket.client.emit('joinToBoard', newVal)
      }

      if (this.$config.APP_KEY === 'superegoholding') {
        this.setExtensionNames()
      }
    },
    search: {
      immediate: false,
      deep: true,
      handler: 'getData'
    },
    searchCities (val) {
      if (val) {
        this.debounceSearch(val)
      }
    },
    searchCitiesOrigin (val) {
      if (val) {
        this.debounceSearch(val)
      }
    }
  },
  sockets: {
    connect() {
      console.log('client connected')
      // Fired when the socket connects.
      this.isConnected = true
      this.$socket.client.emit('joinToBoard', this.selectedBoard)
    },
    disconnect() {
      console.log('client disconnected')
      this.isConnected = false
    },
    reconnect(){
      console.log('client reconnect')
    },
    newLoad(data) {
      let indexes = this.driversMap[data.driver_id]
      if (indexes) {
        this.$set(this.dispatchers[indexes.userIndex].drivers[indexes.driverIndex].loads, data.day_key, data)
      }
    },
    editLoad(data) {
      let indexes = this.driversMap[data.load.driver_id]
      if (indexes) {
        this.$set(this.dispatchers[indexes.userIndex].drivers[indexes.driverIndex].loads, data.load.day_key, data.load)
        if (this.dispatchers[indexes.userIndex].drivers[indexes.driverIndex].loads[data.load.day_key]._id === this.dialogForm._id) {
          if (data.onlyComment) {
            this.dialogForm.comments = this.dispatchers[indexes.userIndex].drivers[indexes.driverIndex].loads[data.load.day_key].comments
          } else {
            this.dialogForm = {...this.dialogForm, ...this.dispatchers[indexes.userIndex].drivers[indexes.driverIndex].loads[data.load.day_key]}
          }
        }
      }
    },
    moveLoad(data) {
      if (!this.permissions.DISPATCHER_ALL) {
        return
      }
      let indexesNewDriver = this.driversMap[data.newLoad.driver_id]
      let indexesOldDriver = this.driversMap[data.oldLoad.driver_id]
      if (indexesNewDriver && indexesOldDriver) {
        setTimeout(() => {
          this.$delete(this.dispatchers[indexesOldDriver.userIndex].drivers[indexesOldDriver.driverIndex].loads, data.oldLoad.day_key)
        }, 200)
        this.$set(this.dispatchers[indexesNewDriver.userIndex].drivers[indexesNewDriver.driverIndex].loads, data.newLoad.day_key, data.newLoad)
      }
    },
    deleteLoad(data) {
      let indexes = this.driversMap[data.driver_id]
      if (indexes) {
        this.$delete(this.dispatchers[indexes.userIndex].drivers[indexes.driverIndex].loads, data.day_key)
      }
    },
    editDriver(data) {
      let indexes = this.driversMap[data._id]
      if (indexes) {
        // ako se promeni board_id
        if (!data.is_active || (this.dispatchers[indexes.userIndex].drivers[indexes.driverIndex].board._id != data.board._id)) {
          this.dispatchers[indexes.userIndex].drivers.splice(indexes.driverIndex, 1)
        } else if (this.dispatchers[indexes.userIndex].drivers[indexes.driverIndex].user._id != data.user._id) {
          // TODO: prebaciti vozaca klientski gde treba
          this.getData() // ako se promeni user_id
        } else {
          this.dispatchers[indexes.userIndex].drivers.splice(indexes.driverIndex, 1, {...this.dispatchers[indexes.userIndex].drivers[indexes.driverIndex], ...data})
        }
      }
    },
    orderChange() {
      if (!this.searchActivated) {
        this.getData()
      }
    },
    loadSelected(data) {
      if (this.user.email === data.user_email) return
      loadSelectedGenerator(data)
    },
    loadDeselected(data) {
      if (this.user.email === data.user_email) return
      removeSelectedUserFromLoad(data.loadId)
    }
  },
  created() {
    this.getWindowWidth()
  },
  mounted() {
    if (this.selectedBoard) {
      this.getData()
      this.$socket.client.emit('joinToBoard', this.selectedBoard)
    } else if (this.boards.length) {
      if (!this.user.board_id || this.user.board_id === '') {
        this.$store.dispatch('dashboard/setBoardId', this.boards[0]._id)
      } else {
        let board = this.boards.find(board => board._id === this.user.board_id)
        this.$store.dispatch('dashboard/setBoardId', board._id)
      }
    }

    if (this.$config.APP_KEY === 'superegoholding') {
      this.setExtensionNames()
    }

    // iskljuceno
    // window.addEventListener('keydown', this.keyDownHandler)

    if (this.permissions) {
      this.$store.dispatch('loadTypes/getAllLoadTypes').then(res => {
        this.loadTypes = res
        this.dialogForm.loadType = res.find(el => el.type === 'DEFAULT')
      })
    }

    if (this.$route.query && this.$route.query.truck) {
      this.$store.dispatch('dashboard/activateSearch', true)
      this.$store.dispatch('dashboard/updateSearch', {byName: '', byTruck: this.$route.query.truck, byTrailer: ''})
    }

  },
  beforeDestroy() {
    this.$socket.client.emit('leave_room', this.selectedBoard)
  }
}
</script>

<style lang="scss">

  .html2canvas-container {  height: 3000px !important; width: 3000px !important; }

  .align-right {
    display: flex;
    justify-content: flex-end;
  }

  .date-from-to {
    text-align: center;
    width: 100%;

    &.mobile {
      margin-bottom: 0px;
    }

    .date-picker-text {
      color: #3f51b5;
    }
  }

  .bottom-content {
    align-items: center;
    .legenda {
      margin-top: 10px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-right: 12px;
      .v-chip {
        margin-right: 5px;

        &.active {
          opacity: 0.6;
        }

        &.default {
          background-color: #3f51b5 !important;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .rows-number {
      width: 100%;
      margin-bottom: 0px;
    }
  }

  .dashboard-loader {
    position: fixed;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }

  .table-container {

    overflow: scroll;
    // max-width: 100%;
    width: 100%;
    max-height: calc(100vh - 145px);
    position: relative;
    // margin-right: 12px;
    // margin-left: 12px;

    &.loading {
      opacity: 0.7;
    }

    &.report-visible {
      opacity: 1;
    }

    .main-table {
      border-collapse: collapse;
      min-width: 400px;
      width: 100%;
      border-collapse: separate; /* Don't collapse */
      border-spacing: 0;

      &.dark {
        th {
          background-color: #43a047;
        }

        td {
          .drag-icon {
            color: #43a047;
          }

          .note-icon {
            color: #43a047;
          }
        }
      }

      th {
        padding: 10px 5px;
        border-left: 1px solid #A9A9A9;
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        background-color: #1E88E5FF;
        color: #ffffff;
        text-align: center;
        top: 0;
        z-index: 5;
        font-size: 13px;

        &.small {
          font-size: 11px;
        }

        &.board-col-head {
          left: 816px;
          border-left: 1px solid #A9A9A9;
          min-width: 55px;
          z-index: 5;
          font-size: 11px;
        }

        &.separator {
          z-index: 5;
          background-color: #ffee58;
          border-bottom: none;
          border-top: none;
          min-width: 1px;
          padding: 1px;
          box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
          -webkit-box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
        }

        &.today {
          background-color:rgb(100, 181, 246);
        }

        &.days {
          z-index: 3;
          span {
            font-weight: 500;
            font-size: 10px;
            position: absolute;
            top: 0px;
            left: 2px;
            opacity: 0.8;
          }
        }
      }

      tbody {
        &:nth-child(even) {
          background-color: #eee;
          color: #000;
          tr {
            &:hover {
              td:not(.load-cell) {
                background-color: lightyellow !important;

                &.separator {
                  background-color: #ffee58 !important;
                }
              }
            }

            td:not(.load-cell) {
              background-color: #eee;
            }
          }
        }
        &:nth-child(odd) {
          background-color: #fff;
          color: #000;

          tr {
            &:hover {
              td:not(.load-cell) {
                 background-color: lightyellow !important;

                 &.separator {
                  background-color: #ffee58 !important;
                 }
              }
            }

            td:not(.load-cell) {
              background-color: #fff;
            }
          }
        }

        tr {

          &.inactive {
            opacity: 0.6;
            pointer-events: none;
            cursor: not-allowed;

            .sticky-col {
              opacity: 1;
              pointer-events: auto;
            }
          }


          &:hover {
            background-color: lightyellow !important;
          }

          &:last-child {
            td {
              border-bottom: 1px solid rgba(183, 28, 28, 0.8);
            }
          }

          td {
            padding: 7px 5px;
            font-size: 12px;
            border-left: 1px solid #A9A9A9;
            border-bottom: 1px solid #A9A9A9;
            white-space: nowrap;
            position: relative;

            hr {
              width: 100%;
              border-top: 1px solid black;
              border-bottom: none;
            }

            .price-miles {
              width: 100%;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
            }

            &.bold {
              font-weight: 600;
            }

            &.small {
              font-size: 11px;
            }

            &.light-col {
              position: sticky;
              position: -webkit-sticky; /* for Safari */
              z-index: 2;
              color: #3f51b5;
              text-shadow: 0px 0px 10px rgba(63, 81, 181, 0.7);
              font-weight: 600;
              font-size: 11px;
              text-align: center;
            }

            &.sticky-col {
              position: sticky;
              position: -webkit-sticky; /* for Safari */
              padding-left: 20px;
              padding-right: 20px;
              z-index: 2;

              cursor: context-menu;

              .driver-name {
                cursor: pointer;
                white-space: normal;
                &.noCut {
                  background-color: red;
                }

              }
              &:hover {
                .driver-name {
                  color: blue;
                }
              }

              .drag-icon {
                position: absolute;
                left: -10px;
                top: 0;
                cursor: move;
              }

              .note-icon {
                position: absolute;
                right: 2px;
                top: 2px;
                font-size: 20px;
              }
            }

            &.sticky {
              position: sticky;
              position: -webkit-sticky; /* for Safari */
              border-left: 1px solid #A9A9A9;
              z-index: 4;
            }

            &.board-col {
              position: sticky;
              position: -webkit-sticky; /* for Safari */
              left: 816px;
              border-left: 1px solid #A9A9A9;
              width: 55px;
              z-index: 4;
              font-size: 11px;
              text-align: center;
            }

            &.separator {
              position: sticky;
              position: -webkit-sticky; /* for Safari */
              background-color: #ffee58 !important;
              border-bottom: none;
              border-top: none;
              width: 1px;
              padding: 1px;
              z-index: 4;
              box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
              -webkit-box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
            }
          }
        }
      }
    }

    .report-table {
      border-collapse: collapse;
      min-width: 400px;
      width: 100%;
      border-collapse: separate; /* Don't collapse */
      border-spacing: 0;

      th {
        padding: 10px 5px;
        border-left: 1px solid #A9A9A9;
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        background-color: #1E88E5FF;
        color: #ffffff;
        text-align: center;
        top: 0;
        z-index: 3;
        font-size: 13px;

        &:nth-child(1) {
          left: 0px;
          border-left: 1px solid #A9A9A9;
          min-width: 165px;
          z-index: 5;
          font-size: 11px;
          padding: 6px 6px;
        }

        &:nth-child(2) {
          left: 165px;
          border-left: 1px solid #A9A9A9;
          min-width: 60px;
          z-index: 5;
        }

        &:nth-child(3) {
          left: 225px;
          border-left: 1px solid #A9A9A9;
          min-width: 80px;
          z-index: 5;
        }

        &:nth-child(4) {
          left: 305px;
          border-left: 1px solid #A9A9A9;
          min-width: 60px;
          z-index: 5;
        }
        
        &:nth-child(5) {
          left: 365px;
          border-left: 1px solid #A9A9A9;
          min-width: 60px;
          z-index: 5;
        }
        
        &:nth-child(6) {
          left: 425px;
          border-left: 1px solid #A9A9A9;
          min-width: 60px;
          z-index: 5;
        }
        
        &.separator {
          left: 485px;
          z-index: 5;
          background-color: #ffee58;
          border-bottom: none;
          border-top: none;
          min-width: 1px;
          padding: 1px;
          box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
          -webkit-box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
        }

        &.days {
          span {
            font-weight: 500;
            font-size: 10px;
            position: absolute;
            top: 0px;
            left: 2px;
            // transform: translateX(-50%);
            opacity: 0.8;
          }
        }
      }

      tbody {
        &:nth-child(even) {
          background-color: #eee;
          color: #000;
          tr {
            td:not(.load-cell) {
              background-color: #eee;
            }
          }
        }
        &:nth-child(odd) {
          background-color: #fff;
          color: #000;

          tr {
            td:not(.load-cell) {
              background-color: #fff;
            }
          }
        }

        tr {

          &.inactive {
            opacity: 0.6;
            pointer-events: none;
            cursor: not-allowed;

            .sticky-col {
              opacity: 1;
              pointer-events: auto;
            }
          }

          &:last-child {
            td {
              border-bottom: 1px solid rgba(183, 28, 28, 0.8);
            }
          }

          td {
            padding: 7px 5px;
            font-size: 12px;
            border-left: 1px solid #A9A9A9;
            border-bottom: 1px solid #A9A9A9;
            white-space: nowrap;
            position: relative;

            hr {
              width: 100%;
              border-top: 1px solid black;
              border-bottom: none;
            }

            .price-miles {
              width: 100%;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
            }

            &.bold {
              font-weight: 600;
            }

            &:nth-child(1) {
              left: 0px;
              border-left: 1px solid #A9A9A9;
              min-width: 165px;
              z-index: 5;
              font-size: 11px;
              padding: 6px 6px;
            }

            &:nth-child(2) {
              left: 165px;
              border-left: 1px solid #A9A9A9;
              min-width: 60px;
              z-index: 5;
            }

            &:nth-child(3) {
              left: 225px;
              border-left: 1px solid #A9A9A9;
              min-width: 80px;
              z-index: 5;
            }

            &:nth-child(4) {
             position: sticky;
             position: -webkit-sticky; /* for Safari */
             left: 305px;
             border-left: 1px solid #A9A9A9;
             width: 60px;
             z-index: 4;
            }
            
            &:nth-child(5) {
             position: sticky;
             position: -webkit-sticky; /* for Safari */
             left: 365px;
             border-left: 1px solid #A9A9A9;
             width:60px;
             z-index: 4;
            }
            
            &:nth-child(6) {
             position: sticky;
             position: -webkit-sticky; /* for Safari */
             left: 425px;
             border-left: 1px solid #A9A9A9;
             width: 60px;
             z-index: 4;
            }

            &.separator {
              position: sticky;
              position: -webkit-sticky; /* for Safari */
              left: 485px;
              background-color: #ffee58 !important;
              border-bottom: none;
              border-top: none;
              width: 1px;
              padding: 1px;
              z-index: 4;
              box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
              -webkit-box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
            }
          }
        }
      }
    }
  }

  .comment-text-field {
    padding-top: 0px;
  }
  .menu-comment-type {
    padding-top: 0px;
  }

  @media only screen and (max-width: 1440px) {
    .table-container {
      max-height: 450px;
    }
  }

  @media only screen and (max-width: 426px) {
    .table-container {
      margin-right: 0px;
      margin-left: 0px;

      .main-table {
        th {
          &:not(.days), &:not(.separator){
            z-index: 3;
          }
          &:nth-child(2) {
            left: 0;
            z-index: 4;
            box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
            -webkit-box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
          }
          &.separator {
            z-index: 4;
          }
        }

        tbody {
          tr {
            td {
              -moz-user-select: none; /* Firefox */
              -ms-user-select: none; /* Internet Explorer */
              -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
              -webkit-user-select: none; /* Chrome, Safari, and Opera */
              -webkit-touch-callout: none; /* Disable Android and iOS callouts*/

              &:not(.load-cell), &:not(.sticky-col), &:not(.separator){
                position: relative;
                z-index: 1;
              }

              &:nth-child(2) {
                position: sticky;
                box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
                -webkit-box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
                z-index: 3;
              }

              &.separator {
                z-index: 3;
                position: sticky;
              }
            }
          }
        }
      }
    }

  .bottom-content {
    flex-direction: column;
    .legenda {
      justify-content: flex-end;
      flex-direction: column;
      .v-chip {
        margin-right: 0px;
        margin-left: 12px;
        margin-bottom: 5px;
      }
    }

    .rows-number {
      text-align: center;
    }
  }
}
</style>
