<template>
  <form class="LocationForm" @submit.prevent="handleSubmitForm">
        <v-switch
          dense
          color="success"
          hide-details="auto"
          @change="handleOnTheRoadChanged"
          v-model="onTheRoad"
        >
          <template v-slot:label>
            <span class="text">On the road</span>
          </template>
        </v-switch>
        <template v-if="onTheRoad">
          <v-layout row wrap>
            <v-flex xs6>
              <v-text-field
                v-model="lat"
                @input="handleLatChange"
                name="lat"
                id="lat"
                placeholder="Latitude"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="lng"
                @input="handleLngChange"
                name="lng"
                id="lng"
                placeholder="Longitude"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-flex xs12>
            <input class="LocationForm__input" type="text" ref="autocomplete" />
            <div ref="maps" style="height: 200px"></div>
          </v-flex>
        </template>
        <v-card-actions v-if="!isForm">
          <v-spacer></v-spacer>
          <v-btn color="info" type="submit">Save</v-btn>
        </v-card-actions>
  </form>
</template>

<script>
import { Loader } from 'google-maps'

const DEFAULT = { lat: 35.1175, lng: -89.971107 }

export default {
  name: 'LocationForm',

  props: {
    truck: Object,
    isForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['location-changed'],

  data() {
    return {
      lat: this.truck.lat || DEFAULT.lat,
      lng: this.truck.lng || DEFAULT.lng,
      onTheRoad: this.truck.on_the_road,
      map: null,
      marker: null,
    }
  },

  async mounted() {
    if (this.truck.on_the_road) await this.initMap()
  },

  methods: {
    handleSubmitForm() {
      this.$emit('location-changed', {
        lat: this.lat,
        lng: this.lng,
        onTheRoad: this.onTheRoad,
        truck: this.truck,
      })
    },

    async handleLngChange(v) {
      const newValue = Number(v)
      this.lng = newValue
      this.map.setCenter({ lat: this.lat, lng: newValue })
      this.marker.setPosition({ lat: this.lat, lng: newValue })
      if (this.isForm) this.handleSubmitForm()
    },

    async handleLatChange(v) {
      const newValue = Number(v)
      this.lat = newValue
      this.map.setCenter({ lng: this.lng, lat: newValue })
      this.marker.setPosition({ lng: this.lng, lat: newValue })
      if (this.isForm) this.handleSubmitForm()
    },

    async initMap() {
      const { lat, lng } = this
      const position = lat && lng ? { lat, lng } : DEFAULT

      if (!window.google) {
        const loader = new Loader('AIzaSyD-m9aYLn84q8TWkxNp9NLpifgmqq9E0d0', {
          libraries: ['places'],
        })
        const google = await loader.load()
        Object.assign(window, { google })
      }

      this.map = new window.google.maps.Map(this.$refs.maps, {
        center: position,
        zoom: 6,
      })

      this.autocomplete = new window.google.maps.places.Autocomplete(
        this.$refs.autocomplete
      )
      this.autocomplete.setFields(['geometry'])
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace()
        if (!place) return
        if (!place.geometry) return
        if (!place.geometry.location) return
        this.lat = place.geometry.location.lat()
        this.lng = place.geometry.location.lng()
        this.map.setCenter(place.geometry.location)
        this.marker.setPosition(place.geometry.location)
        if (this.isForm) this.handleSubmitForm()
      })

      this.marker = new window.google.maps.Marker({
        position,
        map: this.map,
        draggable: true,
        title: 'Drag me!',
      })

      this.marker.addListener('dragend', this.handleMarkerDragEnd)
    },

    handleMarkerDragEnd(e) {
      const lat = e.latLng.lat()
      const lng = e.latLng.lng()
      this.lat = lat
      this.lng = lng
      if (this.isForm) this.handleSubmitForm()
    },

    async handleOnTheRoadChanged(v) {
      if (v) await this.initMap()
      else {
        this.map = null
        this.marker = null
      }
      if (this.isForm) this.handleSubmitForm()
    },
  },
}
</script>

<style lang="scss">
.LocationForm {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  &__input {
    background-color: transparent;
    border-style: none;
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
    max-height: 32px;
    color: rgba(0, 0, 0, 0.87);
    outline: none;

    border-bottom-color: rgba(0, 0, 0, 0.42);
    border-bottom-style: solid;
    border-bottom-width: thin 0 0 0;
    &:focus {
      border-bottom-color: rgba(0, 0, 0, 0.87);
    }
  }
}
</style>
