<template>
  <div class="companies-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.companies') }}
      </v-card-title>
      <v-data-table
        height="550"
        :disable-sort="true"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalItems"
        :items="companies"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.is_active="{item}">
          {{item.is_active ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              @click="editCompany(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteCompany(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
          color="pink"
          dark
          absolute
          top
          right
          fab
          @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
        <v-card>
          <form @submit.prevent="onAddCompany" data-vv-scope="company">
            <v-card-title
              class="lighten-4 py-4 title"
            >
              {{form._id ? 'Edit' : 'Add'}} company
            </v-card-title>
            <v-container grid-list-sm class="pa-4">
              <v-layout row wrap>
                <v-flex xs12 align-center justify-space-between>
                    <v-text-field
                      ref="name"
                      v-model="form.name"
                      v-validate="'required'"
                      :error="errors.has('company.name')"
                      :error-messages="errors.first('company.name')"
                      name="name"
                      id="name"
                      :autofocus="true"
                      placeholder="Name"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    v-model="form.is_active"
                    label="Is active"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
              <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
            </v-card-actions>
          </form>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import PrebookService from '../../services/PrebookService'

export default {
  name: 'Companies',
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 10
      },
      companies: [],
      totalItems: 0,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Is active', value: 'is_active'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        name: '',
        is_active: true
      },
      formDialog: false
    }
  },
  computed: {
    ...mapGetters('common', ['loading'])
  },
  watch: {
    options: {
      handler () {
        this.getCompanies()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    getCompanies() {
      PrebookService.getPrebookCompanies(this.options).then(res => {
        this.companies = res.data.docs
        this.totalItems = res.data.total
      }).catch(err => {
        console.log('Error on get prebook companies', err)
      })
    },
    onAddCompany() {
      this.$validator.validateAll('company').then((result) => {
        if (result) {
          if (this.form._id) {
            PrebookService.editPrebookCompany(this.form).then(res => {
              if (res) {
                this.getCompanies()
                this.closeForm()
              }
            }).catch(err => {
              if (err.data && err.data.code === 11000) {
                let key = Object.keys(err.data.keyPattern)[0]
                this.errors.add({
                  scope: 'company',
                  field: key,
                  msg: 'This field must be unique',
                })
              }
            })
          } else {
            PrebookService.addPrebookCompany(this.form).then(res => {
              if (res) {
                this.getCompanies()
                this.closeForm()
              }
            }).catch(err => {
              if (err.data && err.data.code === 11000) {
                let key = Object.keys(err.data.keyPattern)[0]
                this.errors.add({
                  scope: 'company',
                  field: key,
                  msg: 'This field must be unique',
                })
              }
            })
          }
        }
      })
    },
    editCompany(company) {
      this.form = {...company}
      this.formDialog = true
    },
    deleteCompany(company) {
      let confVal = confirm(`Are you sure you want to delete company: ${company.name}?`)
      if (confVal) {
        PrebookService.deletePrebookCompany(company._id).then(res => {
          if (res) {
            this.getCompanies()
            this.setMessage(`${company.name} successfully deleted`)
          }
        }).catch(err => {
          console.log('err on delete', err)
        })
      }
    },
    closeForm () {
      this.formDialog = false
      this.form = {
        _id: null,
        name: '',
        is_active: true
      }
    }
  }
}
</script>