<template>
  <v-dialog
    v-model="settingsDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
        dense
      >
        <v-btn
          icon
          dark
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Settings</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list
        three-line
        subheader
      >
        <v-subheader>Seccurity</v-subheader>
        <v-list-item>
          <v-list-item-content>
            <user-devices :user-tokens="userTokens"></user-devices>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>General</v-subheader>
        <v-list-item>
          <v-list-item-action>
            <v-switch
              v-model="dark"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dark theme</v-list-item-title>
            <v-list-item-subtitle>Change the application theme to dark</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-action>
            <v-switch
              v-model="saveDrawer"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Save left menu after change</v-list-item-title>
            <v-list-item-subtitle>A setting that determines whether to remember the left menu display or not</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item>
          <v-list-item-action>
            <v-color-picker
              v-model="userColor"
              class="ma-2"
              mode="hexa"
              show-swatches
              :hide-canvas="true"
              :hide-inputs="true"
              :hide-mode-switch="true"
              :flat="true"
            ></v-color-picker>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>User color</v-list-item-title>
            <v-list-item-subtitle>Color of your driver's rows</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import UserDevices from '../Users/UserDevices.vue'

export default {
  name: 'SettingsDialog',
  data: () => ({
    userTokens: []
  }),
  components: {
    UserDevices
  },
  methods: {
    closeDialog () {
      this.$store.dispatch('common/toggleSettingsDialog', false)
    }
  },
  computed: {
    ...mapGetters('common', ['settingsDialog']),
    ...mapGetters('auth', ['user']),
    dark: {
      get () {
        return this.$store.getters['common/dark']
      },
      set (val) {
        this.$vuetify.theme.dark = val
        this.$store.dispatch('common/saveTheme', val)
      }
    },
    saveDrawer: {
      get () {
        return this.$store.getters['common/saveDrawer']
      },
      set (val) {
        this.$store.dispatch('common/saveDawerSetting', val)
      }
    },
    userColor: {
      get () {
        return this.$store.getters['common/userColor']
      },
      set (val) {
        this.$store.dispatch('common/saveUserColor', val)
      }
    }
  },
  async mounted() {
    try {
      const res = await this.$store.dispatch('user/getUserTokens', this.user._id)
      this.userTokens = res
    } catch (err) {
      console.log('err on get user tokens: ', err)
    }
  }
}
</script>