<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-icon
        v-if="!hidden"
        class="FieldHistory__button"
        small
        @click="on.click"
        >mdi-history</v-icon
      >
      <slot></slot>
    </template>
    <v-card>
      
      <div style="min-width: 400px; min-height: 200px">
        <TrailerFieldHistory
          v-if="menu"
          :small="true"
          :type="type"
          :id="id"
          :fieldType="fieldType"
          :fieldValue="fieldValue"
          :allowChange="allowChange"
        />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import TrailerFieldHistory from './TrailerFieldHistory.vue'

export default {
  props: {
    type: {
      required: true,
    },
    id: {
      required: true,
    },
    fieldType: {
      required: false,
    },
    fieldValue: {
      required: false,
    },
    allowChange: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  name: 'FieldWithHistory',
  components: {
    TrailerFieldHistory,
  },
  data() {
    return {
      menu: false,
    }
  },
}
</script>

<style lang="scss">
.FieldHistory {
  &__button {
    position: absolute !important;
    top: 0;
    right: 0;

    opacity: 0.6;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
