import Api from '@/services/Api'

export default {
  getLoadTypes (pagination) {
    return Api().get('/load-types', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1
    }})
  },
  addLoadType (loadType) {
    return Api().post('/load-types', loadType)
  },
  editLoadType (loadType) {
    return Api().put('/load-types', loadType)
  },
  deleteLoadType (id) {
    return Api().delete(`/load-types/${id}`)
  },
  getAllLoadTypes () {
    return Api().get('/load-types/all')
  }
}
