<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div @click="on.click" style="cursor: pointer;">
        <v-icon small color="black lighten-2">
          mdi-wrench-outline
        </v-icon>
      </div>
    </template>
    <v-card>
        <div class="trailer-repairs">
          <v-list v-if="repairs.length" class="trailer-repairs__list">
            <div style="padding: 0 20px">
              <v-text-field 
                v-model="filter" 
                label="Filter by invoice #" 
                placeholder="Filter by invoice #"
                @input="handleFilterChanged"
              >
              </v-text-field>
            </div>
            <template v-for="(item, index) of filteredRepairs">
              <v-list-item :key="`repair-${item._id}`">
                <v-list-item-content class="trailer-repairs__content">
                  <div class="trailer-repairs__stuff">
                    <v-list-item-subtitle>
                      <strong>Date Issued:</strong>
                      {{ item.date_issued | formatDate }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <strong>Date Fixed:</strong>
                      {{ item.date_fixed | formatDate}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <strong>Invoice number:</strong> {{ item.invoice_number }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <strong>Amount:</strong> {{ item.amount }}$
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <strong>State:</strong> {{ item.state }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <strong>City:</strong> {{ item.city }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <strong>Shop info:</strong> {{ item.shop_info }}
                    </v-list-item-subtitle>
                  </div>
                  <div class="trailer-repairs__actions">
                    <v-icon small color="primary" @click="handleEditRepair(item)">mdi-pencil</v-icon>
                    <v-icon small color="error" @click="handleDeleteRepair(item)">mdi-delete</v-icon>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                :key="`divider-${index}`"
                v-if="index < repairs.length - 1"
              ></v-divider>
            </template>
          </v-list>

          <p class="trailer-repairs__no-history" v-else-if="!loading && !repairs.length">
            No repairs ....
          </p>

          <v-progress-circular
            v-if="loading"
            indeterminate
            class="trailer-repairs__loader"
            color="primary"
          ></v-progress-circular>

          <v-btn
            @click="handleAddNewRepair"
            class="mt-1"
            block
            elevation="2"
            small
            color="success"
            >Add repair</v-btn
          >
        </div>
    </v-card>

    <v-dialog v-model="showDialog" width="400px" persistent>
      <v-card>
        <form @submit.prevent="handleSubmitForm" data-vv-scope="repairs">
          <v-card-title class="lighten-4 py-4 title">
            {{ this.form._id ? 'Edit' : 'Add' }} trailer repair
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="invoice_number"
                  v-model="form.invoice_number"
                  v-validate="'required'"
                  :error="errors.has('repairs.invoice_number')"
                  :error-messages="errors.first('repairs.invoice_number')"
                  name="invoice_number"
                  id="invoice_number"
                  :autofocus="true"
                  placeholder="Invoice Number"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="amount"
                  v-model="form.amount"
                  v-validate="'required'"
                  :error="errors.has('repairs.amount')"
                  :error-messages="errors.first('repairs.amount')"
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="Amount"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="state"
                  v-model="form.state"
                  v-validate="'required'"
                  :error="errors.has('repairs.state')"
                  :error-messages="errors.first('repairs.state')"
                  name="state"
                  id="state"
                  placeholder="State"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="city"
                  v-model="form.city"
                  name="city"
                  id="city"
                  placeholder="City"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-textarea
                  ref="shop_info"
                  v-model="form.shop_info"
                  v-validate="'required'"
                  :error="errors.has('repairs.shop_info')"
                  :error-messages="errors.first('repairs.shop_info')"
                  name="shop_info"
                  id="shop_info"
                  placeholder="Shop Info"
                ></v-textarea>
              </v-flex>

              <v-flex xs12>
                <v-menu
                  ref="datePickerIssued"
                  v-model="datePicker.issued"
                  :close-on-content-click="false"
                  :return-value.sync="form.date_issued"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(form.date_issued)"
                      label="Date issued"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date_issued"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="datePicker.issued = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.datePickerIssued.save(form.date_issued)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12>
                <v-menu
                  ref="datePickerFixed"
                  v-model="datePicker.fixed"
                  :close-on-content-click="false"
                  :return-value.sync="form.date_fixed"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(form.date_fixed)"
                      label="Date fixed"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date_fixed"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="datePicker.fixed = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.datePickerFixed.save(form.date_fixed)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="handleCloseDialog"
              >Cancel</v-btn
            >
            <v-btn
              color="info"
              :loading="loading"
              :disabled="loading"
              type="submit"
              >{{ this.form._id ? 'Edit' : 'Add' }}</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-menu>
</template>

<script>
import trailersService from '../../services/TrailersService'
export default {
  name: 'TrailerRepairs',
  props: ['trailer'],
  data() {
    return {
      filter: '',
      filterDebounce: null,
      filteredRepairs: [],

      loading: false,
      menu: false,
      form: {
        _id: null,
        invoice_number: '',
        amount: '',
        repair_type: '',
        date_issued: null,
        date_fixed: null,
        state: '',
        city: '',
        shop_info: ''
      },
      datePicker: {
        issued: false,
        fixed: false
      },
      showDialog: false,
      repairs: []
    }
  },
  watch: {
    menu (newVal) {
      if (newVal) {
        this.getRepairs()
      }
    }
  },
  methods: {
    handleFilterChanged(v) {
      if (this.filterDebounce) clearTimeout(this.filterDebounce)
      this.filterDebounce = setTimeout(() => {
        if (v) this.filteredRepairs = this.repairs.filter(r => r.invoice_number.includes(v))
        else this.filteredRepairs = this.repairs
      }, 300)
    },
    handleSubmitForm() {
      this.$validator.validateAll('repairs').then((result) => {
        if (result) {
          this.loading = true
          if (this.form._id) {
            const { date_issued, date_fixed, ...rest } = this.form
            trailersService
              .editTrailerRepair({
                trailer_id: this.trailer._id,
                ...rest,
                date_issued: new Date(date_issued),
                date_fixed: new Date(date_fixed)
              })
              .then((res) => {
                if (res) {
                  const { trailerRepair } = res.data
                  const temp = [...this.repairs]
                  const i = temp.findIndex((h) => h._id === trailerRepair._id)
                  temp[i] = trailerRepair
                  this.repairs = temp
                  this.showDialog = false
                  this.resetForm()
                }
              })
              .catch(console.warn)
              .finally(() => (this.loading = false))
          } else {
            // eslint-disable-next-line no-unused-vars
            const { _id, date_issued, date_fixed, ...rest } = this.form
            trailersService
              .addTrailerRepair({
                trailer_id: this.trailer._id,
                ...rest,
                date_issued: new Date(date_issued),
                date_fixed: new Date(date_fixed)
              })
              .then((res) => {
                const { trailerRepair } = res.data
                this.repairs.push(trailerRepair)
                this.showDialog = false
                this.resetForm()
              })
              .catch(console.warn)
              .finally(() => (this.loading = false))
          }
          this.filteredRepairs = this.repairs
        }
      })
    },
    handleCloseDialog() {
      this.showDialog = false
      this.resetForm()
    },
    handleAddNewRepair() {
      this.showDialog = true
    },
    resetForm() {
      this.form = {
        _id: null,
        invoice_number: '',
        amount: '',
        state: '',
        city: '',
        shop_info: '',
        date_issued: null,
        date_fixed: null
      }
    },
    formatDate(date) {
      return this.$options.filters.formatDateObj(date)
    },
    handleDeleteRepair(item) {
      let confVal = confirm(
        `Are you sure you want to delete repair: ${item.invoice_number}?`
      )
      if (confVal) {
        this.loading = true
        trailersService
          .deleteTrailerRepair(item._id)
          .then((res) => {
            if (res) {
              this.repairs = this.repairs.filter(
                (i) => i._id !== item._id
              )
              this.filteredRepairs = this.repairs
            }
          })
          .catch(console.warn)
          .finally(() => (this.loading = false))
      }
    },
    handleEditRepair(repair) {
      this.showDialog = true
      this.form = {...repair}
    },
    getRepairs() {
      this.loading = true
      trailersService
        .getTrailerRepairs(this.trailer._id)
        .then((res) => {
          if (res) this.repairs = res.data.trailerRepair
          this.filteredRepairs = this.repairs
        })
        .catch(console.warn)
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss">
.trailer-repairs {
  min-width: 400px;
  min-height: 200px;
  position: relative;

  &__list {
    max-height: 200px;
    overflow-y: scroll;
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__stuff {
    flex: 1;
  }

  &__actions {
    flex: 0;
    min-width: max-content;
  }

  &__no-history {
    padding-top: 20px;
    text-align: center;
  }

  &__loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
