<template>
  <div class="Cars">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :options="options"
      :items="items"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      dense
      @update:options="handleOptionsChange"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Cars</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="540px" @click:outside="close">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New car
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.vin_number"
                        :rules="[rules.required]"
                        prepend-icon="mdi-identifier"
                        label="Vin*"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.plate_number"
                        prepend-icon="mdi-identifier"
                        label="Plate"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        v-model="form.year"
                        :items="yearOptions"
                        prepend-icon="mdi-calendar"
                        label="Year"
                      />
                    </v-col>

                    <v-col cols="6">
                      <v-select
                        v-model="form.make"
                        :items="makeOptions"
                        item-text="name"
                        label="Make"
                        prepend-icon="mdi-car-electric"
                        return-object
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="form.model"
                        :items="modelOptions"
                        item-text="name"
                        label="Model"
                        prepend-icon="mdi-train-car"
                        return-object
                      />
                    </v-col>

                    <v-col cols="8">
                      <v-text-field
                        v-model="form.note"
                        label="Note"
                        prepend-icon="mdi-calendar-text"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-checkbox v-model="form.is_active" label="Is active?" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="submitDisabled"
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogDelete"
            max-width="500px"
            @click:outside="closeDelete"
          >
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.vin_number="{ item }">
        <td style="text-align: center">
          <strong>
            {{ item.vin_number }}
          </strong>
        </td>
      </template>

      <template v-slot:item.make="{ item }">
        <td style="text-align: center">
          {{ item.make && item.make.name }}
        </td>
      </template>

      <template v-slot:item.model="{ item }">
        <td style="text-align: center">
          {{ item.model && item.model.name }}
        </td>
      </template>

      <template v-slot:item.is_active="{ item }">
        <td style="text-align: center">
          {{ item.is_active ? 'Yes' : 'No' }}
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import service from '../../services/CarService'
import { generateYearsArr } from '../../utils'

const REQUIRED = ['vin_number']

const footerProps = {
  'items-per-page-options': [20, 50, 100],
}

const DEFAULT_ITEM = {
  name: null,
  note: null,
  make: null,
  model: null,
  year: null,
  is_active: true,
}

export default {
  data: () => {
    return {
      footerProps,
      dialog: false,
      dialogDelete: false,
      options: { page: 1, itemsPerPage: 10 },
      rules: { required: (value) => !!value || 'Required.' },

      headers: [
        {
          text: 'Vin',
          sortable: false,
          value: 'vin_number',
          align: 'center',
          width: 100,
        },
        {
          text: 'Plate',
          sortable: false,
          value: 'plate_number',
          align: 'center',
          width: 100,
        },
        {
          text: 'Year',
          sortable: false,
          value: 'year',
          align: 'center',
          width: 60,
        },
        {
          text: 'Make',
          sortable: false,
          value: 'make',
          align: 'center',
          width: 150,
        },
        {
          text: 'Model',
          sortable: false,
          value: 'model',
          align: 'center',
          width: 150,
        },
        {
          text: 'Note',
          sortable: false,
          align: 'center',
          value: 'note',
        },
        {
          text: 'Is active?',
          sortable: false,
          align: 'center',
          value: 'is_active',
          width: 90,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: 60,
        },
      ],
      items: [],
      makeOptions: [],
      modelOptions: [],
      yearOptions: generateYearsArr(30),
      totalItems: 0,
      editId: null,
      form: { ...DEFAULT_ITEM },
    }
  },

  computed: {
    formTitle() {
      return this.editId ? 'Edit car' : 'New car'
    },

    submitDisabled() {
      for (const field of REQUIRED) if (!this.form[field]) return true
      return false
    },
  },

  async created() {
    await this.fetchItems()
    await this.getMakes()
    await this.getModels()
  },

  methods: {
    async getMakes() {
      this.loading = true
      try {
        const { data } = await service.getCarMakes({
          itemsPerPage: 500,
          is_active: 1,
        })
        if (Array.isArray(data.docs)) this.makeOptions = data.docs
      } catch (error) {
        console.error('Cars getMakes()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async getModels() {
      this.loading = true
      try {
        const { data } = await service.getCarModels({
          itemsPerPage: 500,
          is_active: 1,
        })
        if (Array.isArray(data.docs)) this.modelOptions = data.docs
      } catch (error) {
        console.error('Cars getModels()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async fetchItems() {
      this.loading = true
      try {
        const { data } = await service.getCars(this.options)
        this.totalItems = data.total
        this.items = data.docs
      } catch (error) {
        console.error('Cars fetchItems()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    editItem(item) {
      const { _id, ...rest } = item
      this.editId = _id
      this.form = Object.assign({}, rest)
      this.dialog = true
    },

    deleteItem(item) {
      const { _id, ...rest } = item
      this.editId = _id
      this.form = Object.assign({}, rest)
      this.dialogDelete = true
    },

    async handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      await this.fetchItems()
    },

    async deleteItemConfirm() {
      this.loading = true
      try {
        await service.deleteCar(this.editId)
        await this.fetchItems()
      } catch (error) {
        console.error('Cars deleteItemConfirm()', error)
        this.error = true
      } finally {
        this.loading = false
        this.closeDelete()
      }
    },

    resetForm() {
      this.form = Object.assign({}, DEFAULT_ITEM)
      this.editId = null
    },

    close() {
      this.resetForm()
      this.dialog = false
    },

    closeDelete() {
      this.resetForm()
      this.dialogDelete = false
    },

    async save() {
      this.loading = true
      try {
        if (this.editId) await service.editCar(this.editId, this.form)
        else await service.addCar(this.form)
        await this.fetchItems()
        this.close()
      } catch (error) {
        console.error('Cars save()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.Cars {
  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
    }
  }
}
</style>
