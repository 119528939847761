<template>
  <div class="field-history-wraper pa-2" :class="{ small: small }">
    <v-list v-if="!loading && changes.length" class="trailer-history">
      <template v-for="(item, index) of changes">
        <v-list-item :key="`field-history-${item._id}`">
          <v-list-item-content>
            <v-list-item-subtitle class="history-text TrailerFieldHistory__top">
              <div class="TrailerFieldHistory__change">
                from
                <span class="date-highlight">{{
                  getValue(item, 'old_state')
                }}</span>
                to
                <span :class="'date-highlight'"
                  >{{ getValue(item, 'new_state') }}
                </span>
              </div>

              <div v-if="allowChange" class="TrailerFieldHistory__actions">
                <v-icon small color="primary" @click="editItem(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon small color="error" @click="deleteItem(item._id)"
                  >mdi-delete</v-icon
                >
              </div>
            </v-list-item-subtitle>
            <div class="bottom-history">
              <span class="date-of-change">{{
                item.date_of_change | formatDateUSA('MM/DD/YYYY HH:mm')
              }}</span>
              <span title="The user who made changes" class="user-email">{{
                item.user
              }}</span>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="`divider-${index}`"
          v-if="index < changes.length - 1"
        ></v-divider>
      </template>
    </v-list>
    <p class="no-history" v-else-if="!loading && !changes.length">
      No field history ...
    </p>
    <v-progress-circular
      v-else
      indeterminate
      class="trailer-history-loader"
      color="primary"
    ></v-progress-circular>

    <div v-if="allowChange" class="d-flex justify-center">
      <v-dialog v-model="dialog" max-width="360px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            block
            color="success"
            dark
            class="mb-2"
            v-bind="attrs"
            v-on="on"
          >
            Add History
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5"
            >{{ editId ? 'Edit' : 'Add' }} history</v-card-title
          >

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.old_state"
                    label="Old State"
                    prepend-icon="mdi-state-machine"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.new_state"
                    label="New State"
                    prepend-icon="mdi-state-machine"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.date_of_change"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.date_of_change"
                        label="Date of Change"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :value="form.date_of_change"
                      :allowed-dates="disableFutureDates"
                      @input="
                        (v) => {
                          $refs.dateMenu.save(v);
                          dateMenu = false;
                        }
                      "
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
              :disabled="!form.new_state && !form.old_state"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import trailersService from '../../services/TrailersService'

const DEFAULT_ITEM = {
  old_state: null,
  new_state: null,
  date_of_change: null,
}

export default {
  name: 'TrailerFieldHistory',
  props: {
    type: {
      required: true,
    },
    id: {
      required: true,
    },
    fieldType: {
      required: false,
    },
    small: { type: Boolean, default: false },
    allowChange: {
      type: Boolean,
      default: false,
    },
    fieldValue: {
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      changes: [],
      dialog: false,
      form: { ...DEFAULT_ITEM },
      editId: null,
      dateMenu: false,
    }
  },
  mounted() {
    this.getHistory()
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    async save() {
      this.loading = true

      const body = {
        ...this.form,
        id: this.id,
        type: this.type,
      }

      try {
        if (this.editId) await trailersService.editLog(this.form._id, body)
        else await trailersService.createNewLog(body)
        await this.getHistory()
        this.close()
      } catch (error) {
        console.error('delete() ', error)
      } finally {
        this.loading = false
      }
    },

    async deleteItem(id) {
      this.loading = true
      try {
        await trailersService.deleteLog(id)
        this.getHistory()
      } catch (error) {
        console.error('delete() ', error)
      } finally {
        this.loading = false
      }
    },

    getValue(item, state) {
      const itemState = item[state]

      const v = this.fieldValue
        ? (itemState && itemState[this.fieldValue]) || itemState
        : itemState

      if (this.fieldType === 'date')
        return this.$options.filters.formatDate(v, 'MM/DD/YYYY')

      if (this.fieldType === 'boolean') return itemState ? 'YES' : 'NO'

      return v || '/'
    },

    editItem(item) {
      this.editId = item._id
      this.form = Object.assign(
        {},
        {
          ...item,
          date_of_change: item.date_of_change
            ? this.$options.filters.formatDatePicker(item.date_of_change)
            : null,
        }
      )
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.form = Object.assign({}, DEFAULT_ITEM)
        this.editId = null
      })
    },

    disableFutureDates(val) {
      return val <= new Date().toISOString().substr(0, 10)
    },

    async getHistory() {
      this.loading = true
      try {
        const { data } = await trailersService.getTrailerFieldChanges(
          this.id,
          this.type
        )
        this.changes = data.changes
      } catch (error) {
        console.error('getHistory() ', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.TrailerFieldHistory {
  &__top {
    display: flex;
    justify-content: space-between;
  }
}

.field-history-wraper {
  position: relative;
  min-width: 400px;
  min-height: 200px;

  &.small {
    .trailer-history {
      .history-text {
        font-size: 11px;
      }
    }
  }

  .trailer-history {
    max-height: 200px;
    overflow-y: scroll;
    .v-list-item {
      position: relative;

      .history-text {
        white-space: pre-wrap;
        padding-right: 8px;
        margin-bottom: 8px;

        .date-highlight {
          font-weight: 600;
        }
      }

      .bottom-history {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .note {
          text-align: left;
          font-size: 13px;
          font-style: italic;
          padding-left: 2px;
        }
        .user-email {
          text-align: right;
          font-size: 11px;
          color: #2196f3;
        }

        .date-of-change {
          text-align: left;
          font-size: 11px;
          color: #2196f3;
        }
      }
    }
  }

  .no-history {
    text-align: center;
  }

  .trailer-history-loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
