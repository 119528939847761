import Api from '@/services/Api'

export default {
  getStatististcs() {
    return Api().get('/statististics')
  },
  getTrailersStatistics() {
    return Api().get('/statististics/trailers')
  },
  getYearStats() {
    return Api().get('/statististics/truck-year-stats')
  },
  getTrailersYearStats() {
    return Api().get('/statististics/trailer-year-stats')
  },
  getTrailersTypeStats() {
    return Api().get('/statististics/trailer-type-stats')
  },
  getTrailersMakeStats() {
    return Api().get('/statististics/trailer-make-stats')
  },
}
