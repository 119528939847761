<template>
  <ValidationObserver ref="validationObs" tag="form" @submit.prevent="handleSubmitForm">
    <div class="pl-6 pt-6 pr-6 pb-6">
      <v-row>
        <v-card-title class="font-weight-bold text-h6">{{ editShopData ? 'Edit Shop' : 'Add Shop' }}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn class="mt-3 mr-3" @click="handleCloseAddShopDialog" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-row>
      <v-card-text class="pl-0 font-weight-bold text-body-1 dark-blue">Shop *</v-card-text>
      <ValidationProvider rules="required" v-slot="{ errors }" name="Name">
        <v-text-field v-model="form.shopName" :error="errors.length > 0" :error-messages="errors[0]" class="pb-4"
          label="Shop Name" outlined dense clearable hide-details="auto"></v-text-field>
      </ValidationProvider>
      <v-divider></v-divider>
      <v-card-text class="pl-0 font-weight-bold text-body-1 dark-blue">Franchise</v-card-text>
      <v-select v-if="!addFranchiseActive && !franchiseAdded" v-model="form.franchiseId" :items="franchises"
        class="pb-4" label="Franchise" outlined dense hide-details></v-select>
      <v-text-field v-if="addFranchiseActive" v-model="form.franchiseId" class="pb-4" label="Add New Franchise"
        outlined dense clearable hide-details></v-text-field>
      <v-card-text v-if="franchiseAdded" class="pl-2 pt-0 pr-0 pb-4 font-weight-medium text-body-1 dark-blue">{{
        franchiseName
      }}</v-card-text>

      <v-btn v-if="!addFranchiseActive && !franchiseAdded" class="mb-4" color="primary" @click="addFranchise"><v-icon
          class="mr-2">mdi-plus</v-icon>Add New</v-btn>
      <v-btn v-if="franchiseAdded" class="mb-4 primary--text white--text" color="primary"
        @click="selectFranchiseFromList" outlined>Pick From List</v-btn>
      <v-btn v-if="addFranchiseActive" class="mb-4" color="primary" @click="createFranchise">Create New
        Franchise</v-btn>

      <v-btn v-if="addFranchiseActive" class="ml-4 mb-4 primary--text white--text" outlined @click="addFranchise">Pick
        From
        List</v-btn>
      <v-divider></v-divider>
      <v-card-text class="pl-0 font-weight-bold text-body-1 dark-blue">Shop Category</v-card-text>
      <v-radio-group v-model="form.shopCategory" class="pt-0 pb-4 mt-0" row hide-details>
        <v-radio label="None" value=""></v-radio>
        <v-radio label="Platinum" value="PLATINUM"></v-radio>
        <v-radio label="Gold" value="GOLD"></v-radio>
        <v-radio label="Silver" value="SILVER"></v-radio>
      </v-radio-group>
      <v-divider></v-divider>
      <v-card-text class="pl-0 pb-1 font-weight-bold text-body-1 dark-blue">Shop Type
        *</v-card-text>
      <v-container fluid>
        <ValidationProvider rules="required" v-slot="{ errors }" name="type">
          <v-row class="pl-1 pb-2">
            <v-checkbox
              class="mr-4"
              v-model="form.shopType"
              :error="errors.length > 0"
              label="Truck"
              value="TRUCK"
              hide-details
            ></v-checkbox>
            <v-checkbox
              class="mr-4"
              v-model="form.shopType"
              :error="errors.length > 0"
              label="Trailer"
              value="TRAILER"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.shopType"
              :error="errors.length > 0"
              label="Towing"
              value="TOWING"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.shopType"
              :error="errors.length > 0"
              label="Secured Parking"
              value="SECURED_PARKING"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.shopType"
              class="ml-4"
              :error="errors.length > 0"
              label="Mobile Shop"
              value="MOBILE_SHOP"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.shopType"
              class="ml-4"
              :error="errors.length > 0"
              label="Detailing"
              value="DETAILING"
              hide-details
            ></v-checkbox>
            <v-checkbox
                v-model="form.shopType"
                class="ml-4"
                :error="errors.length > 0"
                label="Frame, Alignment & Suspension"
                value="FRAME_ALIGNMENT_SUSPENSION"
                hide-details
            ></v-checkbox>
            <v-checkbox
                v-model="form.shopType"
                class="ml-4"
                :error="errors.length > 0"
                label="Glass Repair"
                value="GLASS_REPAIR"
                hide-details
            ></v-checkbox>
            <v-checkbox
                v-model="form.shopType"
                class="ml-4"
                :error="errors.length > 0"
                label="Trailer Refrigeration Repair"
                value="TRAILER_REFRIGERATION_REPAIR"
                hide-details
            ></v-checkbox>
            <v-checkbox
                v-model="form.shopType"
                class="ml-4"
                :error="errors.length > 0"
                label="Welding"
                value="WELDING"
                hide-details
            ></v-checkbox>
            <v-checkbox
                v-model="form.shopType"
                class="ml-4"
                :error="errors.length > 0"
                label="Lock Out Service"
                value="LOCK_OUT_SERVICE"
                hide-details
            ></v-checkbox>
            <v-checkbox
                v-model="form.shopType"
                class="ml-4"
                :error="errors.length > 0"
                label="Mobile Fueling"
                value="MOBILE_FUELING"
                hide-details
            ></v-checkbox>
          </v-row>

        </ValidationProvider>
      </v-container>
      <v-divider class="mb-4"></v-divider>
        <v-switch
          v-model="form.isNational"
          inset
          class="my-2"
          label="National Shop"
          false-value="false"
          true-value="true"
        />
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="pl-0 font-weight-bold text-body-1 dark-blue">Shop Address *
        <v-btn v-if="editShopData && editShopData.address && !isAddressInputVisible" icon class="ml-4"
          @click="isAddressInputVisible = true"><v-icon color="primary">mdi-square-edit-outline</v-icon></v-btn>
      </v-card-text>
      <here-map-shop v-if="editShopData && editShopData.address && !isAddressInputVisible" :shops="editShopData"
        :shopDialogForm="true" :height="'150px'"></here-map-shop>

      <v-row v-if="isAddressInputVisible" class="ma-0 pb-4">
        <ValidationProvider slim rules="required" v-slot="{ errors }" name="Address">
          <address-input v-model="form.shopAddress" :value="editShopData" :shopData="editShopData" :error="errors.length > 0"
            :errorMessages="errors[0]"
            @address-changed="addressChanged"></address-input>
        </ValidationProvider>
      </v-row>
      <v-card-text v-if="editShopData && editShopData.address && !isAddressInputVisible"
        class="pl-2 pt-4 font-weight-bold text-body-1 dark-blue">{{
          form.shopAddress.text
        }}</v-card-text>
      <v-spacer></v-spacer>

      <v-divider></v-divider>
      <v-card-text class="pl-0 font-weight-bold text-body-1 dark-blue d-flex align-start justify-space-between">
        Shop working hours
        <v-btn v-if="!showWorkingHoursForm" color="success" text small @click="handleWorkingHoursAddOrEdit"><v-icon> mdi-plus </v-icon> {{ workingHoursFilled ? 'Edit working hours': 'Add working hours' }}</v-btn>
      </v-card-text>
      <v-col cols="12" v-if="showWorkingHoursForm">
            <ValidationObserver v-if="showWorkingHoursForm" ref="validationObsWorkingHours" tag="form" @submit.prevent="saveWorkingHours">
              <v-col
                v-for="(workingDay, index) in workingDays"
                :key="index"
                cols="12"
                class="px-0"
              >
                <p>
                  {{
                    workingDay.day.charAt(0).toUpperCase() +
                    workingDay.day.slice(1)
                  }}
                </p>
                <v-col cols="12" class="px-0 py-0 d-flex align-center">
                  <v-col class="px-0" cols="4">
                    <v-select
                      :hide-details="false"
                      v-model="workingDay.specialNote"
                      label="Select working type"
                      :items="workingHoursSpecialNotes"
                      @input="specialNoteSelected($event, index)"
                    >
                    </v-select>
                  </v-col>
                  <v-col class="w-50" v-if="workingDay.specialNote == null">
                    <ValidationProvider :rules="{ required: !workingDay.specialNote }" v-slot="{ errors }" name="Open">
                      <v-text-field
                        :error="errors.length>0"
                        :error-messages="errors[0]"
                        v-model="workingDay.openTime"
                        type="time"
                        class="mx-2"
                        :hide-details="false"
                        v-if="workingDay.specialNote == null"
                        label="Open"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col class="w-50" v-if="workingDay.specialNote == null">
                    <ValidationProvider :rules="{ required: !workingDay.specialNote }" v-slot="{ errors }" name="Close">
                      <v-text-field
                        :error="errors.length>0"
                        :error-messages="errors[0]"
                        v-model="workingDay.closeTime"
                        type="time"
                        :hide-details="false"
                        v-if="workingDay.specialNote == null"
                        label="Close"
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-tooltip bottom 
                          v-if="
                          index == 0 &&
                          ((workingDay.openTime && workingDay.closeTime) ||
                            workingDay.specialNote)
                        ">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="applyToOtherDays"
                        size="x-small"
                        depressed
                        class="ml-2"
                      >
                        <v-icon size="x-large"> mdi-arrow-down </v-icon>
                      </v-btn>
                    </template>
                    <span>Fill all</span>
                  </v-tooltip>
                </v-col>
              </v-col>
              <!-- <p
                v-if="showWorkingHoursError"
                class="font-weight-bold text-error border-md border-error px-3 py-2 rounded text-center"
              >
                Wrong format
              </p> -->
              <v-col
                cols="12"
                class="px-0 d-flex align-center justify-space-between"
              >
                <v-btn
                  class="error--text"
                  @click="handleWorkingHoursAddOrEditCancel"
                >
                  Cancel
                </v-btn>
                <v-btn type="submit" color="primary"
                  >Save working hours</v-btn
                >
              </v-col>
            </ValidationObserver>
          </v-col>
          <v-col
            v-if="workingHoursFilled && !showWorkingHoursForm"
              class="pt-0"
            >
            <p
              v-for="(day, index) in workingDays"
              :key="index"
              class="font-weight-bold text-subtitle-1"
            >
              {{ formatWorkingHours(day) }}
            </p>
        </v-col>
      <v-divider></v-divider>
      <v-card-text class="pl-0 font-weight-bold text-body-1 dark-blue d-flex align-start justify-space-between">
        Position, email & phone number
        <v-btn v-if="!showContactForm" color="success" text small @click="showContactForm = true"><v-icon> mdi-plus </v-icon> Add new contact</v-btn>
      </v-card-text>
      <v-row v-for="(contact, index) in additionalContactList" :key="index" class="pl-2 pb-4 d-flex align-center justify-space-between">
        <div>
          <div class="pt-0 pb-0 font-weight-bold text-body-1 dark-blue">
            {{ contact.name}}
          </div>
          <span class="pt-0 pb-0 text-body-1">
            {{ contact.phoneNumber }} {{ contact.email}}
          </span>
        </div>
        <v-icon class="mb-2 ml-6" @click="removeContact(index)">mdi-delete</v-icon>
      </v-row>
      <ValidationObserver v-if="showContactForm" ref="validationObsContact" tag="form" @submit.prevent="addContact">
        <v-row class="mb-2">
          <v-col class="pr-0" cols="4">
            <ValidationProvider :rules="{ required: true }" name="Position" v-slot="{ errors }">
              <v-select v-model="additionalContactForm.name"
                :error="errors.length > 0" :error-messages="errors[0]"
                :items="positions" label="Position" dense clearable outlined hide-details></v-select>
            </ValidationProvider>

          </v-col>
          <v-col class="pl-1 pr-0 pr-2" cols="7">
            <ValidationProvider :rules="{ required: true, regex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ }" v-slot="{ errors }" name="Email">
              <v-text-field v-model="additionalContactForm.email" :error="errors.length > 0"
                :error-messages="errors[0]" label="Email" dense clearable outlined
                hide-details="auto"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pr-0 pt-0" cols="6">
            <ValidationProvider :rules="{ required: true, regex: /^\(\d{3}\) \d{3}-\d{4}$/ }" v-slot="{ errors }" name="Phone number">
              <v-text-field v-model="additionalContactForm.phoneNumber" label="Phone Number"
                :error="errors.length > 0"
                :error-messages="errors[0]" placeholder="(123) 456-7890"
                @input="formatPhoneNumber" outlined dense hide-details="auto"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <div class="d-flex align-start justify-space-between">
          <v-btn @click="hideContactForm" class="mb-4 mt-4" color="primary" text small>Cancel</v-btn>
          <v-spacer />
          <v-btn class="mb-4 mt-4" color="primary" type="submit" small>Save Contact</v-btn>
        </div>
      </ValidationObserver>
      <v-divider></v-divider>
      <v-card-text class="pl-0 font-weight-bold text-body-1 dark-blue">Web Site &
        Email & Phone Number</v-card-text>
      <v-row class="ml-0 mt-0 mr-0 mb-0 pb-4">
        <v-text-field v-model="form.shopWebsite" :rules="webSiteRules" label="www.example.com" outlined clearable
          dense hide-details></v-text-field>
        <v-text-field v-model="form.shopEmail" :rules="emailRules" class="ml-2" label="example@gmail.com" outlined
          clearable dense hide-details></v-text-field>
      </v-row>
      <ValidationProvider :rules="{ required: true, regex: /^\(\d{3}\) \d{3}-\d{4}$/ }" v-slot="{ errors }" name="Phone number">
        <v-text-field class="pb-4" v-model="form.shopPhoneNumber" label="Shop Phone Number"
        placeholder="(123) 456-7890" :error="errors.length > 0"
        :error-messages="errors[0]" @input="formatShopPhoneNumber" outlined dense hide-details></v-text-field>
      </ValidationProvider>
      <v-btn type="submit" color="primary" block><v-icon v-if="!editShopData" class="mr-2">mdi-plus</v-icon>{{ editShopData ? 'Edit Shop' :
        'Add Shop' }} </v-btn>
      <v-btn v-if="editShopData" class="mt-3 error" color="primary" block @click="showDeleteDialog=true"> Delete shop </v-btn>
    </div>
    <v-dialog v-model="showDeleteDialog" max-width="460px" @click:outside="showDeleteDialog = false">
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to delete this shop?
        </v-card-title>
        <v-card-text class="error--text text-body-1 font-weight-medium">This action will permanently delete the shop.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="showDeleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red"
            text
            @click="deleteShop(editShopData.id)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import MsShopService from '../../services/MsShopService'
import AddressInput from '../Common/AddressInput.vue'
import HereMapShop from './HereMapShop.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { mapActions } from 'vuex'

const INITIAL_FORM = {
  shopName: '',
  franchiseId: null,
  shopCategory: '',
  shopType: [],
  shopAddress: null,
  contacts: [],
  shopWebsite: '',
  shopEmail: '',
  latitude: 0,
  longitude: 0,
  shopPhoneNumber: '',
  isNational: null
}

export default {
  name: 'AddShopForm',
  components: {
    AddressInput,
    HereMapShop,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    editShopData: {
      type: Object
    }
  },
  data: () => ({
    form: { ...INITIAL_FORM },
    franchisesObject: [
    ],
    franchises: [],
    franchiseId: '',
    franchiseName: '',
    additionalContactList: [],
    //TODO: Change these positions if is needed
    positions: ['Director', 'Manager', 'Technican', 'Mechanic'],
    additionalContactForm: {
      name: '',
      email: '',
      phoneNumber: '',
    },
    addFranchiseActive: false,
    franchiseAdded: false,
    isAddressInputVisible: true,
    addressChangedValue: false,
    showContactForm: false,
    showDeleteDialog: false,
    showWorkingHoursForm: false,
    workingHoursSpecialNotes: [
      {
        text: 'Open 24 hours',
        value: 'OPEN_24_HOURS',
      },
      {
        text: 'Closed',
        value: 'CLOSED',
      },
      {
        text: 'Standard',
        value: null,
      },
    ],
    workingDays: [
      { day: 'monday', openTime: null, closeTime: null, specialNote: null },
      { day: 'tuesday', openTime: null, closeTime: null, specialNote: null },
      { day: 'wednesday', openTime: null, closeTime: null, specialNote: null },
      { day: 'thursday', openTime: null, closeTime: null, specialNote: null },
      { day: 'friday', openTime: null, closeTime: null, specialNote: null },
      { day: 'saturday', openTime: null, closeTime: null, specialNote: null },
      { day: 'sunday', openTime: null, closeTime: null, specialNote: null }
    ],
    workingDaysOriginalState: [
      { day: 'monday', openTime: null, closeTime: null, specialNote: null },
      { day: 'tuesday', openTime: null, closeTime: null, specialNote: null },
      { day: 'wednesday', openTime: null, closeTime: null, specialNote: null },
      { day: 'thursday', openTime: null, closeTime: null, specialNote: null },
      { day: 'friday', openTime: null, closeTime: null, specialNote: null },
      { day: 'saturday', openTime: null, closeTime: null, specialNote: null },
      { day: 'sunday', openTime: null, closeTime: null, specialNote: null }
    ]
  }),
  mounted() {
    if (this.editShopData) this.isAddressInputVisible = false
    this.getFranchises()
    this.errors.clear()
    this.mapShopDataToForm(this.editShopData)
  },
  computed: {
    phoneNumberRules() {
      return [
        v => /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || 'Invalid phone number',
      ]
    },
    emailRules() {
      return [
        value => !!value || 'Email is not formatted well',
        value =>
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
          'Invalid email format'
      ]
    },
    webSiteRules() {
      return [
        value => !!value || 'Website is not formatted well',
        value =>
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$/.test(value) ||
          'Invalid website format'
      ]
    },
    workingHoursFilled: function() {
      return this.workingDays.every(function(day) {
        return ( day.openTime !== null && day.closeTime !== null ) || day.specialNote !== null
      })
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    async handleSubmitForm() {
      const isFormValid = await this.$refs.validationObs.validate()
      if (isFormValid) {
        if (this.editShopData) { // EDIT SHOP
          if (this.addressChangedValue) { //address changed on address-input
            try {
              let objShop = {
                name: this.form.shopName,
                franchiseId: this.form.franchiseId,
                type: this.form.shopType,
                label: this.form.shopCategory,
                // status: this.editShopData.status === 'BLACKLISTED' ? 'BLACKLISTED' : 'REGULAR',
                address: {
                  id: this.form.shopAddress.id,
                  city: this.form.shopAddress.address.city,
                  text: this.form.shopAddress.address.label,
                  state: this.form.shopAddress.address.state,
                  street: this.form.shopAddress.address.street,
                  stateCode: this.form.shopAddress.address.stateCode,
                  postalCode: this.form.shopAddress.address.postalCode,
                  houseNumber: this.form.shopAddress.address.houseNumber || '',
                  building: this.form.shopAddress.address.building || ''
                },
                latitude: this.form.shopAddress.position.lat,
                longitude: this.form.shopAddress.position.lng,
                contacts: this.additionalContactList,
                website: this.form.shopWebsite,
                email: this.form.shopEmail,
                phoneNumber: this.form.shopPhoneNumber,
                isActive: true,
                isNational: this.form.isNational
              }
              if(this.workingHoursFilled){
                objShop.workingHours = this.workingDays
              }
              await MsShopService.updateShop(objShop, this.editShopData.id)
            } catch (error) {
              this.setMessage('Something is wrong with editing form. ', error)
            }
          } else { // Address is not changed on address-input
            try {
              let shopObj = {
                name: this.form.shopName,
                franchiseId: this.form.franchiseId,
                type: this.form.shopType,
                label: this.form.shopCategory,
                // status: this.editShopData.status === 'BLACKLISTED' ? 'BLACKLISTED' : 'REGULAR',
                address: {
                  id: this.form.shopAddress.id || null,
                  city: this.form.shopAddress.city,
                  text: this.form.shopAddress.text,
                  state: this.form.shopAddress.state,
                  street: this.form.shopAddress.street,
                  stateCode: this.form.shopAddress.stateCode,
                  postalCode: this.form.shopAddress.postalCode,
                  houseNumber: this.form.shopAddress.houseNumber || '',
                  building: this.form.shopAddress.building || '',
                },
                latitude: this.editShopData.latitude,
                longitude: this.editShopData.longitude,
                contacts: this.additionalContactList,
                website: this.form.shopWebsite,
                email: this.form.shopEmail,
                phoneNumber: this.form.shopPhoneNumber,
                isActive: true,
                isNational: this.form.isNational === 'true' ? true : false
              }
              if(this.workingHoursFilled){
                shopObj.workingHours = this.workingDays
              }
              await MsShopService.updateShop(shopObj, this.editShopData.id)
            } catch (error) {
              this.setMessage('Something is wrong with editing form. ', error)
            }
          }
          this.addressChangedValue = false
        } else { // CREATE NEW SHOP
          try {
            let shopObj = {
              name: this.form.shopName,
              franchiseId: this.form.franchiseId,
              type: this.form.shopType,
              label: this.form.shopCategory,
              // status: 'REGULAR',
              address: {
                id: this.form.shopAddress.id || null,
                city: this.form.shopAddress.address.city,
                text: this.form.shopAddress.address.label,
                state: this.form.shopAddress.address.state,
                street: this.form.shopAddress.address.street,
                stateCode: this.form.shopAddress.address.stateCode,
                postalCode: this.form.shopAddress.address.postalCode,
                houseNumber: this.form.shopAddress.address.houseNumber || '',
                building: this.form.shopAddress.address.building || ''
              },
              latitude: this.form.shopAddress.position.lat,
              longitude: this.form.shopAddress.position.lng,
              contacts: this.additionalContactList,
              website: this.form.shopWebsite,
              email: this.form.shopEmail,
              phoneNumber: this.form.shopPhoneNumber,
              isActive: true
            }
            if(this.workingHoursFilled){
              shopObj.workingHours = this.workingDays
            }
            const result = await MsShopService.createShop(shopObj)
            this.$emit('shop-added', result.data.id)
          } catch (error) {
            this.setMessage('Something is wrong with sumbiting form. ')
            console.log('Error on add new shop', error)
          }
        }
        //Restart form after is done
        this.form = {
          shopName: '',
          franchise: '',
          shopCategory: '',
          shopType: [],
          shopAddress: null,
          contacts: [],
          shopWebsite: '',
          shopEmail: '',
          shopPhoneNumber: '',
          isNational: null
        }
        this.franchiseId = ''
        this.additionalContactList = []
        this.$emit('close-dialog', false)
        this.$emit('shop-added-edited', true)
      } else {
        const firstErrorElement = document.querySelector('.error--text')
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView({
            behavior: 'smooth'
          })
        }
        this.setMessage('Form validation failed.')
      }
    },
    async deleteShop (id) {
      try {
        await MsShopService.deleteShop(id)
        this.$router.push('/shop')
      }
      catch{
        console.log('error deleting the shop')
      }
    },
    mapShopDataToForm(shopData) {
      if (shopData) {
        this.form.shopName = shopData.name ? shopData.name : ''
        this.form.franchiseId = shopData.franchise ? shopData.franchise.id : ''
        this.form.shopCategory = shopData.label ? shopData.label : ''
        this.form.shopType = shopData.type ? shopData.type : []
        this.form.shopAddress = {
          id: shopData.address.id,
          city: shopData.address.city,
          text: shopData.address.text,
          state: shopData.address.state,
          street: shopData.address.street,
          stateCode: shopData.address.stateCode,
          postalCode: shopData.address.postalCode,
          houseNumber: shopData.address.houseNumber,
          building: shopData.address.houseNumber,
        }
        this.form.latitude = shopData.latitude ? shopData.latitude : ''
        this.form.longitude = shopData.longitude ? shopData.longitude : ''
        this.additionalContactList = shopData.contacts ? shopData.contacts : []
        this.form.contacts = [...this.additionalContactList]
        this.form.shopWebsite = shopData.website ? shopData.website : ''
        this.form.shopEmail = shopData.email ? shopData.email : ''
        this.form.shopPhoneNumber = shopData.phoneNumber ? shopData.phoneNumber : ''
        if(shopData.workingHours) {
          this.workingDays = shopData.workingHours
        }
        if(shopData.isNational) {
          this.form.isNational = shopData.isNational.toString()
        }
      }
    },
    async getFranchises() {
      try {
        this.franchisesObject = await MsShopService.getFranchises()
        this.franchises = this.franchisesObject.data.franchises.map(franchise => ({
          text: franchise.name,
          value: franchise.id
        }))
      } catch (error) {
        console.error('Error fetching franchises:', error)
      }
    },
    async createFranchise() {
      try {
        const response = await MsShopService.createFranchise({
          name: this.form.franchiseId
        })
        if (response.status === 201) {
          this.addFranchiseActive = false
          this.getFranchises()

          this.form.franchiseId = response.data.id
          this.franchiseName = response.data.name
          this.franchiseAdded = true
          this.setMessage('Franchise added successfully!')
        }
      } catch (error) {
        this.setMessage('Error submitting new franchise')
      }
    },
    async addContact() {
      const formValidator = await this.$refs.validationObsContact.validate()
      if (formValidator) {
        this.additionalContactList.push({
          name: this.additionalContactForm.name,
          email: this.additionalContactForm.email,
          phoneNumber: this.additionalContactForm.phoneNumber
        })
        this.form.contacts = { ...this.additionalContactList }
        this.additionalContactForm.name = null
        this.additionalContactForm.email = ''
        this.additionalContactForm.phoneNumber = ''
        this.hideContactForm()
      } else {
        const firstErrorElement = document.querySelector('.error--text')
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView({
            behavior: 'smooth'
          })
        }
      }
    },
    addressChanged(value) {
      this.addressChangedValue = value
    },
    formatPhoneNumber() {
      let numericValue = this.additionalContactForm.phoneNumber.replace(/\D/g, '')
      let formattedValue = ''
      for (let i = 0; i < numericValue.length; i++) {
        if (i === 0) {
          formattedValue += `(${numericValue[i]}`
        } else if (i === 2) {
          formattedValue += `${numericValue[i]}) `
        } else if (i === 5) {
          formattedValue += `${numericValue[i]}-`
        } else {
          formattedValue += numericValue[i]
        }
      }
      this.additionalContactForm.phoneNumber = formattedValue
    },
    formatShopPhoneNumber() {
      let numericValue = this.form.shopPhoneNumber.replace(/\D/g, '')

      let formattedValue = ''
      for (let i = 0; i < numericValue.length; i++) {
        if (i === 0) {
          formattedValue += `(${numericValue[i]}`
        } else if (i === 2) {
          formattedValue += `${numericValue[i]}) `
        } else if (i === 5) {
          formattedValue += `${numericValue[i]}-`
        } else {
          formattedValue += numericValue[i]
        }
      }
      this.form.shopPhoneNumber = formattedValue
    },
    removeContact(index) {
      this.additionalContactList.splice(index, 1)
    },
    addFranchise() {
      this.form.franchiseId = ''
      this.addFranchiseActive = !this.addFranchiseActive
    },
    selectFranchiseFromList() {
      this.franchiseAdded = false
      this.addFranchiseActive = false
      this.form.franchiseId = ''
    },
    handleCloseAddShopDialog() {
      this.$emit('close-dialog', false)
    },
    async hideContactForm() {
      this.showContactForm = false
      await this.$refs.validationObsContact.reset()
    },
    applyToOtherDays: function () {
      for (let i = 1; i < this.workingDays.length; i++) {
        this.workingDays[i].openTime = this.workingDays[0].openTime
        this.workingDays[i].closeTime = this.workingDays[0].closeTime
        this.workingDays[i].specialNote = this.workingDays[0].specialNote
      }
    },
    async specialNoteSelected(value, index) {
      if (value) {
        this.workingDays[index].openTime = null
        this.workingDays[index].closeTime = null
        await this.$refs.validationObsWorkingHours.reset()
      }
    },

    formatWorkingHours(day) {
      if (day.specialNote) {
        return `${day.day.charAt(0).toUpperCase() + day.day.slice(1)}: ${day.specialNote.toLowerCase().replace(/_/g, ' ')}`
      } else {
        return `${day.day.charAt(0).toUpperCase() + day.day.slice(1)}: ${day.openTime} - ${day.closeTime}`
      }
    },

    async saveWorkingHours () {
      const isFormValid = await this.$refs.validationObsWorkingHours.validate()
      if(isFormValid){
        this.showWorkingHoursForm = false
      }
    },
    handleWorkingHoursAddOrEdit() {
      this.showWorkingHoursForm = true
      //samo ovako sam uspeo da zadrzim reaktivne vrednosti
      //a ne moze reset forme jer je validation observer
      for(let i = 0; i<this.workingDays.length; i++){
        this.workingDaysOriginalState[i].day = this.workingDays[i].day
        this.workingDaysOriginalState[i].specialNote = this.workingDays[i].specialNote
        this.workingDaysOriginalState[i].openTime = this.workingDays[i].openTime
        this.workingDaysOriginalState[i].closeTime = this.workingDays[i].closeTime

      }
    },
    handleWorkingHoursAddOrEditCancel(){
      this.showWorkingHoursForm = false
      for(let i = 0; i<this.workingDaysOriginalState.length; i++){
        this.workingDays[i].day = this.workingDaysOriginalState[i].day
        this.workingDays[i].specialNote = this.workingDaysOriginalState[i].specialNote
        this.workingDays[i].openTime = this.workingDaysOriginalState[i].openTime
        this.workingDays[i].closeTime = this.workingDaysOriginalState[i].closeTime

      }
    }

  }
}
</script>

<style lang="scss">
@import '@/assets/variables/variables.scss';

.shop-add-form-content {
  background-color: $silver !important;
}

.dark-blue {
  color: $dark-blue !important;
}

.shop-form-remove-set-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.shop-form-set {
  position: relative;
}

.shop-form-map {
  width: 100%;
  height: 200px;
  display: flex;
}

.custom-width {
  width: 20px;
}
</style>
