import MsApi from '@/services/MsApi'

export default {
  getShops(data) {
    return MsApi().msHttp.get('/shop/shop', { params: data })
  },
  getShop(shopId) {
    return MsApi().msHttp.get(`/shop/shop/${shopId}`)
  },
  createShop(data) {
    return MsApi().msHttp.post('/shop/shop', data)
  },
  updateShop(data, shopId) {
    return MsApi().msHttp.put(`/shop/shop/${shopId}`, data)
  },
  deleteShop(shopId) {
    return MsApi().msHttp.delete(`/shop/shop/${shopId}`)
  },
  updateShopStatus(data, shopId) {
    return MsApi().msHttp.put(`/shop/status/${shopId}`, data)
  },
  createFranchise(data) {
    return MsApi().msHttp.post('/shop/franchise', data)
  },
  getFranchises() {
    return MsApi().msHttp.get('/shop/franchise?page=1&limit=100')
  },
  getReview(shopId, onlyCompanyReviews) {
    return MsApi().msHttp.get(`/shop/review/${shopId}?onlyCompanyReviews=${onlyCompanyReviews}`)
  },
  writeReview(data) {
    const { recaptchaV2, recaptchaToken, ...updateReviewData } = data
    return MsApi().msHttp.post(`/shop/review?recaptchaToken=${recaptchaToken}&recaptchaV2=${recaptchaV2}`, updateReviewData)
  },
  updateReview(data, reviewId) {
    const { recaptchaV2, recaptchaToken, ...updateReviewData } = data
    return MsApi().msHttp.put(`/shop/review/${reviewId}?recaptchaToken=${recaptchaToken}&recaptchaV2=${recaptchaV2}`, updateReviewData)
  },
  getGeojsonData() {
    return MsApi().msHttp.get('/shop/shop/map/geojson')
  }
}