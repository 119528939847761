<template>
  <div class="manage-app-users">
    <form @submit.prevent="onSubmitSearch">
      <v-row>
        <v-col cols="10">
          <table-filters
            class="mr-2"
            align="justify-start"
            :initial-values="{...filters}"
            :initial-form="filtersForm"
            @filters-changed="onFiltersChange"
            hide-clear-button
          />
        </v-col>
        <!-- search po user name-u ili email-u, search po naizivu dispatchera, search po statusima -->
        <!-- <v-col cols="2" class="d-flex align-center">
          <v-text-field
            class="pr-2"
            prepend-inner-icon="mdi-magnify"
            v-model="searchItems"
            clearable
            dense
            solo
            :light="true"
            height="28"
            label="Search FM or truck number"
            single-line
            hide-details="auto"
            persistent-hint
            :hint="foundedDrivers ? `Found ${foundedDrivers} drivers` : 'Search FM or truck number'"
            @input="onSearch"
            @click:clear="onClearSearchFm"
          ></v-text-field>
        </v-col>
        <v-col cols="1" class="mt-2">
          <v-btn type="submit" :disabled="!searchItems || (searchItems && searchItems.length <= 2)" color="primary" small :loading="loading">Search</v-btn>
        </v-col>
        <v-col cols="2" class="mt-1">
          <v-switch class="mt-0" v-model="showInactive" @change="getAllData" label="Show inactive" hide-details small></v-switch>
        </v-col> -->
        <v-spacer />
        <v-col cols="2 dispatchers-search" v-if="this.haveManagePermission">
          <v-text-field
            class="pr-2"
            prepend-inner-icon="mdi-magnify"
            v-model="searchDispatcher"
            clearable
            dense
            solo
            :light="true"
            height="28"
            label="search dispatchers"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col cols="10">
        <v-row>
          <v-col cols="4" v-for="(user) in users" :key="user._id">
            <v-card
              min-height="200"
              elevation="2"
              outlined
            >
              <v-card-title class="pa-2">
                <span :class="{'primary--text': user.is_active, 'error--text': !user.is_active}">{{user.name}}</span>
                <v-spacer></v-spacer>
                <template v-if="haveManagePermission">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        color="error"
                        fab
                        small
                        :disabled="!user.is_active"
                        @click="deactivateUser(user)"
                      >
                        <v-icon>mdi-account-cancel-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Block this user</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="!user.is_active">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        color="success"
                        fab
                        small
                        @click="resendVerification(user)"
                      >
                        <v-icon>mdi-email-sync</v-icon>
                      </v-btn>
                    </template>
                    <span>Resend verification email</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        color="primary"
                        fab
                        :disabled="user.status === 'VERIFIED' || user.status === 'IN_RECRUITMENT_PROCESS'"
                        small
                        @click="sendToRecruiters(user)"
                      >
                        <v-icon>mdi-account-arrow-right</v-icon>
                      </v-btn>
                    </template>
                    <span>Send to recruiters</span>
                  </v-tooltip> -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      :color="user.status === 'VERIFIED' ? 'warning' : 'success'"
                      fab
                      small
                      :disabled="!user.dispatcher"
                      @click="verifyUser(user)"
                    >
                      <v-icon>mdi-shield-check</v-icon>
                    </v-btn>
                    </template>
                    <span>{{user.status === 'VERIFIED' ? 'Unverify this user' : 'Verify this user'}}</span>
                  </v-tooltip>
                </template>
                <template v-if="haveRecruiterPermission">
<!--                  <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                    <v-btn-->
<!--                      :disabled="user.status === 'NOT_EMPLOYED' || user.status === 'EMPLOYED_SUCCESSFULLY'"-->
<!--                      class="mr-2"-->
<!--                      v-bind="attrs"-->
<!--                      v-on="on"-->
<!--                      color="primary"-->
<!--                      fab-->
<!--                      small-->
<!--                      @click="hireUser(user)"-->
<!--                    >-->
<!--                    <v-icon>mdi-account-arrow-left</v-icon>-->
<!--                    </v-btn>-->
<!--                    </template>-->
<!--                    <span>Hire this user if process is done</span>-->
<!--                  </v-tooltip>-->
<!--                  <v-tooltip bottom>-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                    <v-btn-->
<!--                      :disabled="user.status === 'NOT_EMPLOYED' || user.status === 'EMPLOYED_SUCCESSFULLY'"-->
<!--                      v-bind="attrs"-->
<!--                      v-on="on"-->
<!--                      color="warning"-->
<!--                      fab-->
<!--                      small-->
<!--                      @click="endRecruitProcess(user)"-->
<!--                    >-->
<!--                      <v-icon>mdi-account-cancel</v-icon>-->
<!--                    </v-btn>-->
<!--                    </template>-->
<!--                    <span>Stop the hiring process</span>-->
<!--                  </v-tooltip>-->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          :disabled="user.status === 'NOT_EMPLOYED' || user.status === 'EMPLOYED_SUCCESSFULLY'"
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          fab
                          small
                          @click="toggleSelfDispatch(user._id)"
                      >
                        <v-icon v-if="user.canSelfDispatch">mdi-checkbox-marked-circle-outline</v-icon>
                        <v-icon v-else-if="!user.canSelfDispatch">mdi-radiobox-blank</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ !user.canSelfDispatch ? 'ALLOW Self Dispatch' : 'FORBID Self Dispatch' }}
                    </span>
                  </v-tooltip>
                </template>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                      color="success"
                      fab
                      small
                      @click="connectDriver(user)"
                    >
                      <v-icon>mdi-account-multiple-plus</v-icon>
                    </v-btn>
                    </template>
                    <span>Connect driver with this user</span>
                  </v-tooltip>
              </v-card-title>
              <v-card-subtitle>
                <div class="user-infobox">
                  <span class="user-infobox__email"><strong>Current status: </strong>{{ statusesMap[user.status] }}</span>
                  <span class="user-infobox__email"><strong>Email: </strong>{{ user.email }}</span>
                  <span class="user-infobox__email"><strong>Phone: </strong>{{ user.phoneNumber }}</span>
                  <span class="user-infobox__email"><strong>Truck number: </strong>{{ user.truckNumber || '/' }}</span>
                  <span v-if="user.driver" class="user-infobox__email"><strong>Driver: </strong>{{ user.driver.name || '/' }}</span>
                </div>
              </v-card-subtitle>
              <draggable
                class="dispatchers"
                @add="onAddDispatcher($event, user)"
                :sort="false"
                :group="{ name: user._id, pull: false, put: true }"
              >
                <v-chip
                  v-if="user.dispatcher"
                  title="Main dispatcher"
                  class="mr-2 mb-1 disp-chip success"
                  :data-id="user.dispatcher.id"
                  :key="`disp$-${user._id}-${user.dispatcher.id}`"
                  label
                  close
                  @click:close="removeDispatcher('dispatcher', user)"
                >
                  <span class="disp-chip-name">{{user.dispatcher.name}}</span>
                  <span class="disp-chip-email">{{user.dispatcher.email}}</span>
                </v-chip>
                <v-chip
                  v-if="user.secondDispatcher"
                  title="Second dispatcher"
                  class="mr-2 mb-1 disp-chip primary"
                  :data-id="user.secondDispatcher.id"
                  :key="`disp$-${user._id}-${user.secondDispatcher.id}`"
                  label
                  close
                  @click:close="removeDispatcher('secondDispatcher', user)"
                >
                  <span class="disp-chip-name">{{user.secondDispatcher.name}}</span>
                  <span class="disp-chip-email">{{user.secondDispatcher.email}}</span>
                </v-chip>
              </draggable>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row class="cards pl-3 pr-3" v-if="fms.length">
          <fm-card v-for="(user, index) in fms" :key="user._id" @setActiveFm="setActiveFm" @removeDriver="removeDriver" @addDriver="onAddDriver" :user="user" :showInactive="showInactive" :isActive="activeFm === index" :search="searchItems"></fm-card>
        </v-row>
        <v-row class="pl-3 pr-3" v-else>
          <v-col cols="12">
            <p class="text-center subtitle-1" v-if="(!searchItems || !searchItems.length) && loading">Loading ...</p>
            <p class="text-center subtitle-1" v-else-if="searchItems && searchItems.length && !loading">Not found, try something else</p>
          </v-col>
        </v-row> -->
      </v-col>
      <v-col cols="2" v-if="this.haveManagePermission">
        <div class="mt-2 dispatchers-box">
          <draggable
            v-model="dispatchers"
            :sort="false"
            :group="{ name: 'dispatcheers', pull: 'clone', put: false }"
          >
            <v-chip
              class="mr-2 mb-1 disp-chip"
              v-for="disp in filteredDispatchers"
              :data-id="disp._id"
              :key="disp._id"
              label
            >
              <span class="disp-chip-name">{{disp.name}}</span>
              <span class="disp-chip-email">{{disp.email}}</span>
            </v-chip>
          </draggable>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-center">
      <v-btn
        :disabled="disableLoadMore"
        :loading="loading"
        class="mt-6"
        color="primary"
        @click="onLoadMore"
      >
        Load more
      </v-btn>
    </div>

    <v-dialog v-if="selectedUser" v-model="driverModal" width="500px" persistent>
      <v-card>
        <v-card-title
          class="lighten-4 py-4 title"
        >
          Connect driver to: {{ selectedUser.name }}
        </v-card-title>
        <v-container grid-list-sm class="pa-4">
          <v-autocomplete :rules="requiredRule" :items="drivers" label="Choose driver" v-model="selectedDriver" @update:search-input="handleDriverSearch" clearable></v-autocomplete>
        </v-container>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeDriverModal">Cancel</v-btn>
            <v-btn color="info" :disabled="!selectedDriver" @click="connectDriverToUser" :loading="loading">Save</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import UsersService from '../../services/UsersService'
import AppUsersService from '../../services/AppUsersService'
import DriverService from '../../services/DriverService'
import draggable from 'vuedraggable'
import TableFilters from '../TableFilters.vue'

const statusesMap = {
  'NOT_VERIFIED': 'Not Verified',
  'VERIFIED': 'Verified',
  'IN_RECRUITMENT_PROCESS': 'In Recruitment Process',
  'EMPLOYED_SUCCESSFULLY': 'Employed Successfully',
  'NOT_EMPLOYED': 'Not Employed'
}

export default {
  name: 'ManageAppUsers',
  components: {
    draggable,
    TableFilters
  },
  data() {
    return {
      dispatchers: [],
      searchDispatcher: '',
      users: [],
      statusesMap,
      options: {
        page: 1,
        limit: 21
      },
      filters: {
        search: null,
        phoneNumber: null,
        status: null
      },
      disableLoadMore: false,
      filtersForm: {
        search: { type: 'text', label: 'Name or email ...', min: 1 },
        phoneNumber: {type: 'text', label: 'Phone number', min: 1 },
        status: {
          type: 'select',
          itemValue: 'value',
          itemText: 'label',
          options: [
            { label: 'All', value: null },
            { label: 'Verified', value: 'VERIFIED' },
            { label: 'Not verified', value: 'NOT_VERIFIED' },
            { label: 'In recruitment process', value: 'IN_RECRUITMENT_PROCESS' },
            { label: 'Employed successfuly', value: 'EMPLOYED_SUCCESSFULLY' },
            { label: 'Not employed', value: 'NOT_EMPLOYED' },
          ],
        },
      },
      requiredRule: [ v => v != '' && v != null || 'Value is required' ],
      driverModal: false,
      drivers: [],
      selectedUser: null,
      selectedDriver: null
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('auth', ['permissions']),
    filteredDispatchers() {
      if (!this.searchDispatcher) return this.dispatchers
      return this.dispatchers.filter(disp =>
        disp.name.toLowerCase().includes(this.searchDispatcher.toLowerCase()) ||
      disp.email.toLowerCase().includes(this.searchDispatcher.toLowerCase())
      )
    },
    haveManagePermission() {
      return this.permissions && this.permissions.MANAGE_APP_USERS
    },
    haveRecruiterPermission() {
      return this.permissions && this.permissions.RECRUIT_APP_USERS
    }
  },
  async mounted() {
    if (this.haveRecruiterPermission && !this.haveManagePermission) {
      delete this.filtersForm.status
      this.filters.status = 'IN_RECRUITMENT_PROCESS'
    }
    this.$store.dispatch('common/setLoading', true)
    const dispatchersResponse = await UsersService.getUsersByRoleId(3)
    if (dispatchersResponse.status === 200) {
      this.dispatchers = dispatchersResponse.data
    }

    await this.getUsers()
    this.$store.dispatch('common/setLoading', false)
  },
  watch: {
    async filters() {
      this.$store.dispatch('common/setLoading', true)
      await this.getUsers()
      this.$store.dispatch('common/setLoading', false)
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    async getUsers(type = 'filter') {
      if (this.haveRecruiterPermission && !this.haveManagePermission) {
        this.filters.status = 'IN_RECRUITMENT_PROCESS'
      }
      const usersResponse = await AppUsersService.getAppUsers({...this.filters, ...this.options})
      if (usersResponse.status === 200) {
        if(type === 'filter') {
          this.disableLoadMore = false
          this.users = usersResponse.data
        } else if (type === 'push') {
          this.disableLoadMore = !usersResponse.data.length
          this.users.push(...usersResponse.data)
        }
      }
    },
    async onAddDispatcher(event, user) {
      let dispId = event.item.dataset.id
      const foundDisp = this.dispatchers.find((disp) => disp._id === dispId)
      if (foundDisp) {
        const formatDisp = {
          id: foundDisp._id,
          phone_number: foundDisp.phone_number || '',
          name: foundDisp.name,
          email: foundDisp.email,
        }

        if (!user.dispatcher) {
          user.dispatcher = formatDisp
        } else if (!user.secondDispatcher) {
          user.secondDispatcher = formatDisp
        }
        const usersResponse = await AppUsersService.updateDispatchers(user)
        if (usersResponse.status === 200) {
          this.setMessage('User dispatchers updated successfully')
        }
      }
    },
    async removeDispatcher(type, user) {
      user[type] = null
      if (type === 'dispatcher' && user.secondDispatcher) {
        user['dispatcher'] = user.secondDispatcher
        user.secondDispatcher = null
      }
      const usersResponse = await AppUsersService.updateDispatchers(user)
      if (usersResponse.status === 200) {
        this.setMessage('User dispatchers updated successfully')
      }
    },
    async deactivateUser(user) {
      let confVal = confirm(
        `Are you sure you want to deactivate user: ${user.name}?`
      )
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        const deactivationResponse = await AppUsersService.deactivateUser(user._id)
        if (deactivationResponse.status === 200) {
          user.is_active = false
        }
        this.$store.dispatch('common/setLoading', false)
        this.setMessage('User successfully deactivated')
      }
    },
    async resendVerification(user) {
      let confVal = confirm(
        `Are you sure you want to send verification email to user: ${user.name}?`
      )
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        const deactivationResponse = await AppUsersService.resendVerificationEmail(user._id)
        if (deactivationResponse.status === 200) {
          this.setMessage('New verification email is successfully sent')
        }
        this.$store.dispatch('common/setLoading', false)
      }
    },
    async verifyUser(user) {
      let confVal = confirm(
        `Are you sure you want to verify user: ${user.name}?`
      )
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        const updateStatusResponse = await AppUsersService.updateStatus(user, user.status === 'NOT_VERIFIED' ? 'VERIFIED' : 'NOT_VERIFIED')
        if (updateStatusResponse.status === 200) {
          user.status = updateStatusResponse.data.user.status
        }
        this.$store.dispatch('common/setLoading', false)
        this.setMessage('User successfully verified')
      }
    },
    async sendToRecruiters(user) {
      let confVal = confirm(
        `Are you sure you want to send this driver to recruiters: ${user.name}?`
      )
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        const updateStatusResponse = await AppUsersService.updateStatus(user, 'IN_RECRUITMENT_PROCESS')
        if (updateStatusResponse.status === 200) {
          user.status = updateStatusResponse.data.user.status
        }
        this.$store.dispatch('common/setLoading', false)
        this.setMessage('User is sent to recruiters page')
      }
    },
    // async hireUser(user) {
    //   let confVal = confirm(
    //     `Are you sure you want to hire this driver: ${user.name}?`
    //   )
    //   if (confVal) {
    //     this.$store.dispatch('common/setLoading', true)
    //     const updateStatusResponse = await AppUsersService.updateStatus(user, 'EMPLOYED_SUCCESSFULLY')
    //     if (updateStatusResponse.status === 200) {
    //       user.status = updateStatusResponse.data.user.status
    //     }
    //     this.$store.dispatch('common/setLoading', false)
    //     this.setMessage('User is sent to admins page')
    //   }
    // },
    // async endRecruitProcess(user) {
    //   let confVal = confirm(
    //     `Are you sure you want to stop the hiring process: ${user.name}?`
    //   )
    //   if (confVal) {
    //     this.$store.dispatch('common/setLoading', true)
    //     const updateStatusResponse = await AppUsersService.updateStatus(user, 'NOT_EMPLOYED')
    //     if (updateStatusResponse.status === 200) {
    //       user.status = updateStatusResponse.data.user.status
    //     }
    //     this.$store.dispatch('common/setLoading', false)
    //     this.setMessage('Hiring process is stopped')
    //   }
    // },
    async toggleSelfDispatch(userId) {
      this.$store.dispatch('common/setLoading', true)
      const toggleResponse = await AppUsersService.toggleSelfDispatch(userId)
      if (toggleResponse.status === 200) {
        let userToUpdate = this.users.find(user => user._id === userId)
        if (userToUpdate) {
          userToUpdate.canSelfDispatch = !userToUpdate.canSelfDispatch
        }
        this.$store.dispatch('common/setLoading', false)
        this.setMessage('Self dispatch toggled')
      }
    },
    onFiltersChange: debounce(function (filters) {
      this.options.page = 1
      this.filters = {...this.filters, ...filters}
    }, 400),
    async onLoadMore() {
      this.$store.dispatch('common/setLoading', true)
      this.options.page += 1
      await this.getUsers('push')
      this.$store.dispatch('common/setLoading', false)
    },
    connectDriver(user) {
      this.driverModal = true
      this.selectedUser = user
    },
    closeDriverModal() {
      this.driverModal = false
      this.selectedUser = null
      this.drivers = []
    },
    handleDriverSearch: debounce(async function (newQuery) {
      if (newQuery && newQuery.length >= 2) {
        const encodedQuery = encodeURIComponent(newQuery)
        const driversResponse = await DriverService.getDriversAutocomplete(encodedQuery)
        const mappedResponse = driversResponse.data.map(item => ({
          text: item.formatedName,
          value: {id: item._id, name: item.formatedName}
        }))
        this.drivers = mappedResponse
      }
    }, 200),
    async connectDriverToUser() {
      this.$store.dispatch('common/setLoading', true)
      const toggleResponse = await AppUsersService.addDriver(this.selectedUser._id, this.selectedDriver)
      if (toggleResponse.status === 200) {
        let userToUpdate = this.users.find(user => user._id === this.selectedUser._id)
        if (userToUpdate) {
          userToUpdate.driver = this.selectedDriver
        }
        this.$store.dispatch('common/setLoading', false)
        this.setMessage('Driver connected successfully')
        this.closeDriverModal()
      }
    }
  }
}
</script>

<style lang="scss">
.manage-app-users {
  .disp-chip {
    .v-chip__content {
      height: 36px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: move;

      .v-chip__close {
        position: absolute;
        right: 7px;
        top: 0px;
      }

      .disp-chip-name {
        font-size: 13px;
        font-weight: bold;
      }
  
      .disp-chip-email {
        font-size: 11px;
        line-height: 14px;
      }
    }
  }

  .dispatchers-box {
    max-height: 850px;
    overflow-y: scroll;
    position: fixed;
  }

  .dispatchers-search {
    position: fixed;
    right: 0;
  }

  .user-infobox {
    display: flex;
    flex-direction: column;
  }

  .dispatchers {
    padding: 10px;
    width: 100%;
    height: 100px;
    overflow-y: scroll;
  }
}
</style>