<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="480"
    min-width="480"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text small>
        <v-badge v-if="commentsList && commentsList.length"
          color="deep-purple accent-4"
          :content="commentsList.length"
          offset-x="-3"
          offset-y="15"
        >
          Comments
        </v-badge>
        <template v-else>Comments</template>
      </v-btn>
    </template>
    <v-card>
      <comments :comments="commentsList" :loading="loading" :show-actions="true" :with-form="true" @delete="handleDeleteComment" @save="saveComment" @cancel="menu = false"/>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'
import Comments from '../Common/Comments.vue'
import companiesService from '../../services/LeaseCompaniesService'
import unitsService from '../../services/LeaseUnitsService'

export default {
  name: 'LeaseComments',
  components: {
    Comments
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      enum: ['company', 'unit'],
      required: true
    },
  },
  data() {
    return {
      menu: false,
      loading: false,
      service: this.type === 'company' ? companiesService : unitsService,
      commentsList: []
    }
  },
  created() {
    this.commentsList = [...this.comments]
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    async handleDeleteComment(commentId) {
      try {
        await this.service.deleteComment(this.id, commentId)
        const index = this.commentsList.findIndex(comment => comment.id === commentId)
        this.commentsList.splice(index, 1)
        this.setMessage('Comment successfully delited')
      } catch (error) {
        console.error('handleDeleteComment()', error)
      } finally {
        this.$store.dispatch('common/setLoading', false)
      }
    },
    async saveComment(comment) {
      this.loading = true
      try {
        if (comment._id) {
          const { data } = await this.service.editComment(this.id, comment)
          this.commentsList = [...data.comments]
          this.setMessage('Comment successfully edited')
        } else {
          const { data } = await this.service.addComment(this.id, comment)
          this.commentsList = [...data.comments]
          this.setMessage('Comment successfully added')
        }
      } catch (error) {
        console.error('addComment()', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.LeaseComments {
  &__list {
    overflow: scroll;
    max-height: 300px;
    & > *:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  &__left,
  &__right {
    flex: 1 !important;
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  &__change {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 6px !important;
  }
  &__time,
  &__user {
    color: rgb(33, 150, 243);
    font-size: 11px;
    margin: 0 !important;
  }
  &__empty {
    padding: 16px;
    text-align: center;
  }
}
</style>
