<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div @click="on.click" style="cursor: pointer;">
        <span v-if="truck.lastOilChange">
          {{ new Date(truck.lastOilChange.date).toLocaleDateString() }} /
          {{ truck.lastOilChange.millage }}
        </span>
        <v-icon v-else class="mr-2" small color="black lighten-2">
          mdi-oil
        </v-icon>
      </div>
    </template>
    <v-card>
      <oil-change-history-list v-if="menu" :truck="truck" />
    </v-card>
  </v-menu>
</template>

<script>
import OilChangeHistoryList from './OilChangeHistoryList.vue'

export default {
  name: 'OilChangeHistory',

  components: { OilChangeHistoryList },

  props: ['truck'],

  data() {
    return {
      menu: false,
    }
  },
}
</script>
