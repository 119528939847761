<template>
  <div class="address-input">
    <div class="error-holder" v-if="isCustomAddress && error">
      <p class="error--text">{{ errorMessages }}</p>
    </div>
    <v-autocomplete v-if="!isCustomAddress" ref="v-address" v-model="selectedValue" v-validate="{ required: true }" data-vv-name="address"
      :error="error" :error-messages="errorMessages" :items="formatedItems" no-filter item-text="title" item-value="id"
      return-object label="Address" :search-input.sync="searchInput" :loading="loading" placeholder="Enter Address"
      prepend-icon="mdi-map-search" dense row outlined hide-details small clearable @change="onAddressChange"
      class="mb-2"></v-autocomplete>
    <v-text-field
      v-if="isCustomAddress && !addressSaved"
      v-model="cordinates"
      placeholder="Enter latitude and longitude"
      hint="Format: latitude,longitude"
      solo
      @input="setPinByCordinates"
    ></v-text-field>
    <div v-if="addressSaved" class="d-flex justify-end mb-2">
      {{ formatdAddress }}
    </div>
    <div class="d-flex justify-end mb-2">
      <v-btn v-if="!isCustomAddress" text small @click="openCustomAddress">Can't find address ?</v-btn>
    </div>
    <here-map-shop v-if="selectedValue || (isCustomAddress && mapPin)" :shops="mapPin" :shopDialogForm="true" height="150px"></here-map-shop>
    <div v-if="isCustomAddress && !addressSaved" class="address-form mt-2">
      <ValidationObserver ref="validationObs" tag="form" @submit.prevent="onAddressSave">
        <v-row>
          <v-col cols="6">
            <ValidationProvider rules="required" v-slot="{ errors }" name="State">
              <v-text-field
                :disabled="!mapPin"
                class="mb-2"
                v-model="addressForm.state"
                :error="errors.length > 0"
                :error-messages="errors[0]"
                placeholder="State"
                hide-details="auto"
                outlined dense />
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider rules="required" v-slot="{ errors }" name="State code">
              <v-text-field
                :disabled="!mapPin"
                class="mb-2"
                v-model="addressForm.stateCode"
                :error="errors.length > 0"
                :error-messages="errors[0]"
                placeholder="State code"
                hide-details="auto"
                outlined dense/>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider rules="required" v-slot="{ errors }" name="City">
              <v-text-field
                :disabled="!mapPin"
                class="mb-2"
                v-model="addressForm.city"
                :error="errors.length > 0"
                :error-messages="errors[0]"
                placeholder="City"
                hide-details="auto"
                outlined dense/>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider rules="required" v-slot="{ errors }" name="Postal code">
              <v-text-field
                :disabled="!mapPin"
                class="mb-2"
                v-model="addressForm.postalCode"
                :error="errors.length > 0"
                :error-messages="errors[0]"
                placeholder="Postal code"
                hide-details="auto"
                outlined dense/>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider rules="required" v-slot="{ errors }" name="Street">
              <v-text-field
                :disabled="!mapPin"
                class="mb-2"
                v-model="addressForm.street"
                :error="errors.length > 0"
                :error-messages="errors[0]"
                placeholder="Street"
                hide-details="auto"
                outlined dense/>
            </ValidationProvider>
          </v-col>
          <v-col cols="6">
            <ValidationProvider rules="required" v-slot="{ errors }" name="Number">
              <v-text-field
                :disabled="!mapPin"
                class="mb-2"
                v-model="addressForm.houseNumber"
                :error="errors.length > 0"
                :error-messages="errors[0]"
                placeholder="House number"
                hide-details="auto"
                outlined dense/>
            </ValidationProvider>
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-2">
          <v-btn @click="onCancelAddressForm" text small color="warning">Cancel</v-btn>
          <v-spacer />
          <v-btn type="submit" text small color="primary">Save Address</v-btn>
        </div>
      </ValidationObserver>
    </div>
    <!-- <pre v-if="selectedAddress">{{ selectedAddress.address }}</pre>
    <pre v-if="selectedAddress">{{ selectedAddress.position }}</pre> -->
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import HereApiServices from '../../services/HereApiServices'
import HereMapShop from '../Shop/HereMapShop.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

const INITIAL_ADDRESS = {
  city: '',
  text: '',
  state: '',
  street: '',
  stateCode: '',
  postalCode: '',
  houseNumber: '',
  building: ''
}
export default {
  name: 'AddressInput',
  props: {
    value: {
      type: [String, Boolean, Number, Object]
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: String,
      default: ''
    },
    shopData: {
      type: Object
    }
  },
  components: {
    HereMapShop,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    loading: false,
    items: [],
    searchInput: null,
    selectedValue: null,
    selectedAddress: null,
    address: { ...INITIAL_ADDRESS },
    coords: null,
    shop: {},
    addressChanged: false,
    isCustomAddress: false,
    cordinates: '',
    addressForm: {
      state: '',
      stateCode: '',
      city: '',
      street: '',
      houseNumber: '',
      postalCode: ''
    },
    addressSaved: false
  }),
  watch: {
    value(val) {
      this.selectedAddress = val
      if (val) {
        this.mapAddressData(val)
        this.shopData ? this.selectedValue = { id: val.id, title: val.text } : this.selectedValue = { id: val.id, title: val.title }
      } else {
        this.selectedValue = null
      }
    },
    selectedAddress(newVal) {
      this.$emit('input', newVal)
    },
    searchInput(val) {
      if (val) {
        this.debounceSearch(val)
      }
    },
  },
  computed: {
    formatedItems() {
      return this.items.map(item => {
        return { id: item.id, title: item.title }
      })
    },
    mapPin() {
      if (!this.coords) return null
      return this.coords
    },
    formatdAddress() {
      if (this.addressForm.state) {
        const { state, stateCode, city, street, houseNumber, postalCode } = this.addressForm
        return `${houseNumber} ${street}, ${city}, ${state}, ${stateCode} ${postalCode}, United States`
      }

      return ''
    },
  },
  methods: {
    async onAddressChange(value) {
      if (value && value.id) {
        const result = await HereApiServices.lookup(value.id)
        if (result.status === 200) {
          this.selectedAddress = result.data
          this.mapAddressData(this.selectedAddress)
          this.addressChanged = true
          this.$emit('address-changed', true)
        }
      } else {
        this.selectedAddress = null
      }
    },
    debounceSearch: debounce(async function (search) {
      this.loading = true
      const result = await HereApiServices.getSuggestions(search)
      this.loading = false
      if (result.status === 200) {
        this.items = [...result.data]
      }
    }, 400),
    mapAddressData(addressData) {
      this.address = {
        id: addressData.id,
        city: addressData.city,
        text: addressData.label,
        state: addressData.state,
        street: addressData.street,
        stateCode: addressData.stateCode,
        postalCode: addressData.postalCode,
        houseNumber: addressData && addressData.houseNumber ? addressData.houseNumber : '',
        building: addressData && addressData.building ? addressData.building : '',
      }
      if (!this.shopData) { //Add Shop Form coords
        this.coords = {
          latitude: addressData.position.lat,
          longitude: addressData.position.lng,
        }
      } else {//Edit Shop Form coords
        this.coords = {
          latitude: this.selectedAddress.position.lat,
          longitude: this.selectedAddress.position.lng,
        }
      }

    },
    setPinByCordinates(value) {
      const tmpCords = value.split(',')
      if (tmpCords.length === 2) {
        this.coords = {
          latitude: parseFloat(tmpCords[0]),
          longitude: parseFloat(tmpCords[1]),
        }
      } else {
        this.coords = null
      }
    },
    openCustomAddress() {
      this.isCustomAddress = true
      this.selectedAddress = null
    },
    async onAddressSave() {
      const isFormValid = await this.$refs.validationObs.validate()
      if (isFormValid) {
        this.addressSaved = true
        const formatAddressObj = {
          address: {
            id: null,
            label: this.formatdAddress,
            building: '',
            ...this.addressForm,
          },
          position: {
            lat: this.coords.latitude,
            lng: this.coords.longitude
          }
        }
        this.$emit('input', formatAddressObj)
        this.$emit('address-changed', true)
      }
    },
    async onCancelAddressForm() {
      await this.$refs.validationObs.reset()
      this.cordinates = ''
      this.setPinByCordinates(this.cordinates)
      this.isCustomAddress = false
    }
  }
}
</script>

<style lang="scss">
.address-input {
  width: 100%;
}
</style>