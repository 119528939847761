import Api from '@/services/Api'

export default {
  getTruckMake(pagination) {
    return Api().get('/trucks/truck-make', {
      params: {
        page: pagination.page,
        perPage: pagination.itemsPerPage,
        sortby: pagination.sortBy,
        order: pagination.descending ? 1 : -1,
      },
    })
  },
  getAllTruckMake() {
    return Api().get('/trucks/truck-make/all')
  },
  getAllTruckModelsByMake(id) {
    return Api().get(`/trucks/truck-model/all/${id}`)
  },
  addTruckMake(truckMake) {
    return Api().post('/trucks/truck-make', truckMake)
  },
  editTruckMake(truckMake) {
    return Api().put('/trucks/truck-make', truckMake)
  },
  deleteTruckMake(id) {
    return Api().delete(`/trucks/truck-make/${id}`)
  },

  // Change Log
  getFieldHistory(truckId, changeType) {
    return Api().get(`/trucks/field-history/${truckId}/${changeType}`)
  },
  deleteFieldHistory(id) {
    return Api().delete(`/trucks/field-history/${id}`)
  },
  editFieldHistory(id, data) {
    return Api().put(`/trucks/field-history/${id}`, data)
  },
  addFieldHistory(data) {
    return Api().post('/trucks/field-history', data)
  },

  trucksByYard(id, isSpecial = false) {
    let url = `/trucks/by-yard/${id}`
    if (isSpecial) url += '?isSpecial=1'
    return Api().get(url)
  },
  trucksByDealership(id, isSpecial = false) {
    let url = `/trucks/by-dealership/${id}`
    if (isSpecial) url += '?isSpecial=1'
    return Api().get(url)
  },
  getAllOwners() {
    return Api().get('/owners/all')
  },
  getOwnersAutocomplete(query){
    return Api().get(`/owners/autocomplete/${query}`)
  },
  getTruck(id) {
    return Api().get(`/trucks/${id}`)
  },
  searchTrucks(query) {
    return Api().get(`/trucks/search/${query}`)
  },

  //Models
  getTruckModel(pagination) {
    return Api().get('/trucks/truck-model', {
      params: {
        page: pagination.page,
        perPage: pagination.itemsPerPage,
        sortby: pagination.sortBy,
        order: pagination.descending ? 1 : -1,
      },
    })
  },
  addTruckModel(truckModel) {
    return Api().post('/trucks/truck-model', truckModel)
  },
  editTruckModel(truckModel) {
    return Api().put('/trucks/truck-model', truckModel)
  },
  deleteTruckModel(id) {
    return Api().delete(`/trucks/truck-model/${id}`)
  },

  // Trucks
  getTrucks(data) {
    return Api().get('/trucks', {
      params: {
        page: data.page,
        perPage: data.itemsPerPage,
        sortby: data.sortby,
        order: data.order,
        search: data.search,
        filter: data.filter,
        company: data.company,
        companyFrom: data.companyFrom,
        companyTo: data.companyTo,
        vinNumber: data.vin_number
      }
    })
  },
  getReleasedTrucks(data) {
    return Api().get('/trucks/released', {
      params: {
        search: data.search,
        filter: data.filter
      }
    })
  },
  addTruck(truck) {
    return Api().post('/trucks', truck)
  },
  editTruck(truck) {
    return Api().put('/trucks', truck)
  },
  releaseTruck(id, released) {
    return Api().put(`/trucks/release/${id}`, {released})
  },
  deleteTruck(id) {
    return Api().delete(`/trucks/${id}`)
  },
  deleteTruckPermanent(id) {
    return Api().delete(`/trucks/permanent/${id}`)
  },
  rollbackTruck(id) {
    return Api().put(`/trucks/rollback/${id}`)
  },
  searchTruck(search) {
    return Api().get(`/trucks/all?search=${search}`)
  },
  getTruckByNumber(number) {
    return Api().get(`/trucks/get-truck-by-number/${number}`)
  },
  exportTrucks(data) {
    return Api().post('/trucks/export-to-excel', data)
  },
  exportAvailableTrucks(data) {
    return Api().post('/trucks/export-available-to-excel', data)
  },
  removeAllTrucksFromList(id, type) {
    return Api().get(`/trucks/remove-all-from-list/${type}/${id}`)
  },

  // Truck alerts

  getAlerts (pagination) {
    let url = '/trucks/all-alerts'
    
    return Api().get(url, {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
    }})
  },
  addAlert (alert) {
    return Api().post('/trucks/truck-alert', alert)
  },
  deleteUserFromAlertMsg (alertId, userId) {
    return Api().put(`/trucks/remove-alert/${alertId}/${userId}`)
  },
  getUserAlerts () {
    return Api().get('/trucks/user-truck-alerts')
  },
  deleteAlert(alertId) {
    return Api().put(`/trucks/delete-alert/${alertId}`)
  },
  updateAlert(alert) {
    return Api().put(`/trucks/update-alert/${alert._id}`, alert)
  },

  // Truck repairs

  getTruckRepairs(id) {
    return Api().get(`/trucks/repairs/${id}`)
  },
  addTruckRepair(repair) {
    return Api().post('/trucks/repairs', repair)
  },
  editTruckRepair(repair) {
    return Api().put('/trucks/repairs', repair)
  },
  deleteTruckRepair(id) {
    return Api().delete(`/trucks/repairs/${id}`)
  },

  // Truck history
  addHistory(data) {
    return Api().post('/trucks/changes/add', data)
  },
  editHistory(data) {
    return Api().post('/trucks/changes/edit', data)
  },
  getTruckChanges(id, type) {
    return Api().get(`/trucks/changes/${id}/${type}`)
  },
  addNotToHistory(id, note) {
    return Api().post('/trucks/changes/add-note', { id: id, note: note })
  },
  deleteHistory(id) {
    return Api().post('/trucks/changes/delete', { id: id })
  }
}
