<template>
  <div class="managefm-wrapper">
    <form @submit.prevent="onSubmitSearch">
      <v-row>
        <v-col cols="2" class="d-flex align-center">
          <v-text-field
            class="pr-2"
            prepend-inner-icon="mdi-magnify"
            v-model="searchItems"
            clearable
            dense
            solo
            :light="true"
            height="28"
            label="Search FM or truck number"
            single-line
            hide-details="auto"
            persistent-hint
            @input="onSearch"
            @click:clear="onClearSearchFm"
          ></v-text-field>
        </v-col>
        <v-col cols="1" class="mt-2">
          <v-btn type="submit" :disabled="!searchItems || (searchItems && searchItems.length <= 2)" color="primary" small :loading="loading">Search</v-btn>
        </v-col>
        <v-col cols="2" class="mt-1">
          <v-switch class="mt-0" v-model="showInactive" @change="getBoardsWithFms" label="Show inactive" hide-details small></v-switch>
        </v-col>
        <v-divider />
        <v-col cols="2">
          <v-text-field
            class="pr-2"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            clearable
            dense
            solo
            :light="true"
            height="28"
            label="search drivers"
            single-line
            hide-details
            @input="onSearch"
            @click:clear="onClearSearch"
          ></v-text-field>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col cols="10" class="fm-container">
        <v-row class="cards pl-3 pr-3" v-for="group in groupedBoards" :key="group.boardName">
          <div class="board-name-wrapper">
            <h2 class="board-name">{{ group.boardName }}</h2>
          </div>
          <fm-card v-for="(user, index) in group.fleetManagers" :key="user._id" @addDriver="onAddDriver" @removeDriver="removeDriver" :showInactive="showInactive" :isActive="activeFm === index" :user="user"></fm-card>
        </v-row>
      </v-col>
      <v-col cols="2">
        <div id="drivers-no-fm-holder">
          <div v-for="boardGroup in groupedDriversWithNoFms" :key="boardGroup.boardName" class="drivers-no-fm mt-2">
            <h2>{{ boardGroup.boardName }}</h2>
            <draggable
              v-model="boardGroup.drivers"
              :sort="false"
              :group="{ name: 'driversNoFm', pull: true, put: false }"
            >
              <v-chip
                small
                class="mr-2 mb-1 my-chip"
                v-for="driver in boardGroup.drivers"
                :data-id="driver._id"
                :key="`noFm-${driver._id}`"
                label
                :color="!driver.is_active ? 'warning' : ''"
                :title="!driver.is_active ? 'Inactive driver' : ''"
              >
                <span><strong>{{driver.truck}}</strong> - {{driver.name}}</span>              
              </v-chip>
            </draggable>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import draggable from 'vuedraggable'
import driverService from '../../services/DriverService'
import userService from '../../services/UsersService'
import FmCard from './FmCard'

export default {
  name: 'MenageFm',
  components: {
    draggable,
    FmCard
  },
  data() {
    return {
      boardsWithFms: [],
      search: null,
      searchItems: null,
      activeFm: null,
      driversNoFm: [],
      showInactive: false,
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),

    groupedDriversWithNoFms() {
      const grouped = {}

      this.driversNoFm.forEach(boardNoFM => {
        if (boardNoFM.board.name) {
          const boardName = boardNoFM.board.name
          if (!grouped[boardName]) {
            grouped[boardName] = []
          }
          grouped[boardName].push(...boardNoFM.drivers)
        }
      })

      return Object.keys(grouped).map(boardName => ({
        boardName,
        drivers: grouped[boardName],
      }))
    },

    groupedBoards() {
      const grouped = {}

      // Prolazimo kroz sve board-ove sa fleet manager-ima i grupisemo po boardName
      this.boardsWithFms.forEach(boardWithFm => {
        if (boardWithFm.board.name) {
          const boardName = boardWithFm.board.name
          if (!grouped[boardName]) {
            grouped[boardName] = []
          }
          grouped[boardName].push(...boardWithFm.fleetManagers)
        }
      })

      return Object.keys(grouped).map(boardName => ({
        boardName,
        fleetManagers: grouped[boardName],
      }))
    }
  },
  methods: {
    onSearch () {
      this.getDriversWithNoFm()
    },
    onClearSearch () {
      this.search = null
      this.getDriversWithNoFm()
    },
    async onAddDriver (data) {
      const {event, fmUser} = data
      let driverId = event.item.dataset.id

      const draggedDriverRes = await driverService.getDriver(driverId)
      const draggedDriver = draggedDriverRes.data

      if (draggedDriver.board._id === fmUser.board_id) {
        let fmUserFormated = {...fmUser}
        delete fmUserFormated.drivers
        delete fmUserFormated.driverCount
        delete fmUserFormated.board_id

        driverService.updateDriverFm(driverId, fmUserFormated).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            const targetBoardWithFm = this.boardsWithFms.find(
              boardWithFm => boardWithFm.board.id === draggedDriver.board._id
            )
            const targetBoardNoFm = this.driversNoFm.find(
              boardNoFm => boardNoFm.board.name === draggedDriver.board.name
            )

            if (targetBoardWithFm) {
              const targetFleetManager = targetBoardWithFm.fleetManagers.find(
                fleetManager => fleetManager._id === fmUser._id
              )

              if (targetFleetManager) {
                targetFleetManager.drivers.push(draggedDriver)
                targetFleetManager.driverCount += 1

                const targetDriverIndex = targetBoardNoFm.drivers.findIndex(driver => driver._id === driverId)
                targetBoardNoFm.drivers.splice(targetDriverIndex, 1)

              } else {
                console.error('Fleet Manager not found for this board')
              }
            } else {
              console.error('Board not found for driver')
            }
          }
        })
      } else {
        alert('You cannot add a driver with a different board than the Fleet Manager.')
      }
    },
    async removeDriver (data) {
      let confVal = confirm('Are you sure you want to delete the fleet manager for this driver?')
      if (!confVal) {
        return
      }
      const {driverId, fmUser} = data

      const driverRes = await driverService.getDriver(driverId)
      const removedDriver = driverRes.data

      this.$store.dispatch('common/setLoading', true)
      driverService.updateDriverFm(driverId, null).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          const targetBoardWithFm = this.boardsWithFms.find(
            boardWithFm => boardWithFm.board.id === removedDriver.board._id
          )

          const targetBoardNoFm = this.driversNoFm.find(
            boardNoFm => boardNoFm.board._id === removedDriver.board._id
          )

          if (targetBoardWithFm) {
            const targetFleetManager = targetBoardWithFm.fleetManagers.find(
              fleetManager => fleetManager._id === fmUser._id
            )

            if (targetFleetManager) {
              const driverIndex = targetFleetManager.drivers.findIndex(
                driver => driver._id === removedDriver._id
              )

              if (driverIndex !== -1) {
                targetFleetManager.drivers.splice(driverIndex, 1)
                targetFleetManager.driverCount -= 1

                targetBoardNoFm.drivers.push(removedDriver)
              } else {
                console.error('Driver not found in Fleet Manager\'s drivers list')
              }
            } else {
              console.error('Fleet Manager not found for this board')
            }
          } else {
            console.error('Board not found for driver')
          }
        }
      })
    },
    onSubmitSearch() {
      this.getBoardsWithFms()
    },
    onClearSearchFm() {
      this.searchItems = null
      this.getBoardsWithFms()
    },
    getDriversWithNoFm() {
      this.$store.dispatch('common/setLoading', true)
      driverService.getDriversHasNoFm({search: this.search}).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          this.driversNoFm = res.data.boardsWithDrivers
        }
      })
    },
    getBoardsWithFms() {
      this.$store.dispatch('common/setLoading', true)
      userService.getFmGroupedByBoard({search: this.searchItems, showInactive: this.showInactive}).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.status === 200) {
          let boardsWithFms = res.data.fm
          this.boardsWithFms = boardsWithFms
        }
      })
    },
  },
  mounted() {
    this.getBoardsWithFms()
    this.getDriversWithNoFm()
  }
}
</script>

<style lang="scss">
.managefm-wrapper {
  width: 100%;
}

.board-name-wrapper {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;

  .board-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid #ccc; /* opcionalna linija ispod naslova */
  }
}

.fm-container {
  max-height: calc(100vh - 110px);
  overflow-y: scroll;
}

.drivers-no-fm {
  max-height: 795px;
  border-bottom: 2px solid black;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 8px;
  .v-chip {
    cursor: move;
  }
}

#drivers-no-fm-holder {
  max-height: calc(100vh - 110px);
  overflow-y: auto
}

.my-chip {
  font-size: 11px !important;
  height: 32px !important;
  line-height: 16px !important;
  .v-chip__content {
    align-items: flex-start;
    display: flex;
    height: 100%;
    max-width: 100%;
    flex-direction: column;
  }
}
</style>