
<template>
  <div class="here-map-wrapper" :style="mapContainerStyle">
    <link rel="stylesheet" href="https://js.api.here.com/v3/3.1/mapsjs-ui.css" />
    <div ref="hereMap" class="here-map"></div>
    <ShopMapPopUpData v-if="pinHovered && !shopDialogForm" :shopData="shopData" />
  </div>
</template>

<script>
import ShopMapPopUpData from '@/components/Shop/ShopMapPopUpData.vue'
import { isEqual } from 'lodash'

import silverPin from '@/assets/icons/silverPin.svg'
import silverPinActive from '@/assets/icons/silverPinActive.svg'
import platinumPin from '@/assets/icons/platinumPin.svg'
import platinumPinActive from '@/assets/icons/platinumPinActive.svg'
import goldPin from '@/assets/icons/goldPin.svg'
import goldPinActive from '@/assets/icons/goldPinActive.svg'
import blacklistedPin from '@/assets/icons/blacklistedPin.svg'
import blacklistedPinActive from '@/assets/icons/blacklistedPinActive.svg'
import defaultPin from '@/assets/icons/defaultPin.svg'
import defaultPinActive from '@/assets/icons/defaultPinActive.svg'

import EventBus from '@/eventbus/EventBus.js'
import { formatGroupedPin } from '../../utils/hereMapUtils'


const INITIAL_SHOP_DATA = {
  averageRating: 0,
  label: '',
  name: '',
  franchise: '',
  address: {},
  pinPopUpXCoord: 0,
  pinPopUpYCoord: 0,
  status: ''
}

export default {
  name: 'HereMapShop',
  components: {
    ShopMapPopUpData
  },
  props: {
    shops: {
      type: [Array, Object],
      default: () => ({})
    },
    height: {
      type: String,
      default: '300px'
    },
    shopDialogForm: {
      type: Boolean,
      default: false
    },
    isShopMainPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      platform: null,
      map: null,
      mapGroup: null,
      apikey: 'fH0cdveRThTdEqkw86T69TQ2ZylMxa1Pb6eUFyZt2zU',
      isProgrammaticChange: false,
      durationInSeconds: 0,
      H: '',
      pinHovered: false,
      shopData: { ...INITIAL_SHOP_DATA },
      pinPositionX: null,
      pinPositionY: null,
      markerCoords: [],
      iconMappingsActive: {
        'GOLD': goldPinActive,
        'PLATINUM': platinumPinActive,
        'BLACKLISTED': blacklistedPinActive,
        'SILVER': silverPinActive,
        '': defaultPinActive
      },
      iconMappings: {
        'GOLD': goldPin,
        'PLATINUM': platinumPin,
        'BLACKLISTED': blacklistedPin,
        'SILVER': silverPin,
        '': defaultPin
      },
      clusteringDataPoints: [],
      clusteringLayer: null,
      clusteringDataProvider: null,
      clusteringMarkers: new Map()
    }
  },
  computed: {
    mapPins() {
      if (this.map) {
        return {
          silver: new this.H.map.Icon(silverPin),
          platinum: new this.H.map.Icon(platinumPin),
          gold: new this.H.map.Icon(goldPin),
          blacklisted: new this.H.map.Icon(blacklistedPin)
        }
      }
      return null
    },
    mapContainerStyle() {
      return {
        height: this.height,
      }
    },
  },
  created() {
    EventBus.$on('shopCardHovered', this.handleShopCardHover)
    EventBus.$on('shopCardNotHovered', this.handleShopCardNotHovered)
    EventBus.$on('blacklistedBoxShopHovered', this.handleBlacklistedBoxShopHovered)
    EventBus.$on('blacklistedBoxShopNotHovered', this.handleBlacklistedBoxShopNotHovered)
  },
  async mounted() {
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-core.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-service.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-mapevents.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-ui.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-clustering.js')
    await this.loadScript('https://js.api.here.com/v3/3.1/mapsjs-data.js')
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: this.apikey
    })
    this.H = window.H
    this.platform = platform
    this.initializeHereMap()
    // this.createClaster()
  },
  watch: {
    shops: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.createClaster()
        }

        // Position map for Details page
        if (!this.isShopMainPage) {
          this.moveMapToPosition({lat: this.shops.latitude, lng: this.shops.longitude}, 12, false)
        }
      },
      deep: true
    },
  },
  methods: {
    loadScript(src) {
      return new Promise(function (resolve, reject) {
        let script = document.createElement('script')
        script.src = src
        script.onload = () => resolve(script)
        script.onerror = () => reject(new Error(`Script load error for ${src}`))
        document.head.append(script)
      })
    },
    initializeHereMap() { // rendering map
      const mapContainer = this.$refs.hereMap
      // Obtain the default map types from the platform object
      let maptypes = this.platform.createDefaultLayers()
      
      // Instantiate (and display) a map object:
      let map = new this.H.Map(mapContainer, maptypes.vector.normal.map,
        {
          zoom: 4, // Initial zoom level of map
          center: { lat: 40.44217331070482, lng: -99.99376638010682 } // Initial center of map
        })

      addEventListener('resize', () => map.getViewPort().resize())

      // add behavior control
      new this.H.mapevents.Behavior(new this.H.mapevents.MapEvents(map))

      // add UI
      if (this.isShopMainPage) {
        const ui = this.H.ui.UI.createDefault(map, maptypes)
        const mapsettingsControl = ui.getControl('mapsettings')
        mapsettingsControl.setVisibility(false)
      }

      this.map = map
      this.map.getViewPort().element.style.cursor = 'pointer'

      // send to backend when map view changed
      map.addEventListener('mapviewchangeend', () => {
        if (!this.isProgrammaticChange && this.isShopMainPage) {
          this.pinHovered = false
          this.$emit('map-view-changed', this.getMapBounds())
          // this.createClaster()
        }
      })
    },
    createClaster() {
      if (this.map) {
        this.removeClasterData()
        const shopsToIterate = Array.isArray(this.shops) ? this.shops : [this.shops]

        this.clusteringDataPoints = shopsToIterate.map( (shop) => {
          return new this.H.clustering.DataPoint(shop.latitude, shop.longitude, 1, {
            ...shop
          })
        })

        // Create a clustering provider with custom options for clusterizing the input
        this.clusteringDataProvider = new this.H.clustering.Provider(this.clusteringDataPoints, {
          clusteringOptions: {
            strategy: 'DYNAMICGRID',
            // Maximum radius of the neighbourhood
            eps: this.map.getZoom() >= 7 ? 64 : 32,
            // minimum weight of points required to form a cluster
            minWeight: 3
          },
          theme: {
            getClusterPresentation: (cluster) => {
              const weight = cluster.getWeight()

              // Create a different svg icon depending from the weight of the cluster
              const groupedPinData = formatGroupedPin(weight)
              const groupedPin = new this.H.map.Icon(groupedPinData.svgIcon, {
                size: { w: groupedPinData.diameter, h: groupedPinData.diameter },
                anchor: { x: groupedPinData.diameter / 2, y: groupedPinData.diameter / 2 }
              })
              // Create a marker for the cluster
              const clusterMarker = new this.H.map.Marker(cluster.getPosition(), {
                icon: groupedPin,
                // Set min/max zoom with values from the cluster, otherwise
                // clusters will be shown at all zoom levels
                min: cluster.getMinZoom(),
                max: cluster.getMaxZoom()
              })

              clusterMarker.addEventListener('tap', (evt) => {
                let pinPosition = evt.target.getGeometry()
                this.moveMapToPosition(pinPosition, this.map.getZoom() + 2, false)
              })

              // Bind cluster data to the marker
              clusterMarker.setData(cluster)

              return clusterMarker
            },
            getNoisePresentation: (noisePoint) => {
              const noisePointData = noisePoint.getData()
              // Create a marker for noise points:
              const icon = noisePointData.status && noisePointData.status === 'BLACKLISTED' ? this.checkIconCategory('blacklisted') : (noisePointData.label ? this.checkIconCategory(noisePointData.label) : this.checkIconCategory(null))
              const marker = new this.H.map.Marker(noisePoint.getPosition(), {
                icon: icon,
                // Use min zoom from a noise point to show it correctly at certain zoom levels
                min: noisePoint.getMinZoom()
              })

              // Add marker events
              if (this.isShopMainPage) {
                marker.addEventListener('tap', () => {
                  this.$router.push({ name: 'ShopDetails', params: { shopId: noisePointData.id } })
                })

                marker.addEventListener('pointerenter', (event) => {
                  marker.setZIndex(1000)
                  this.shopData.pinPopUpXCoord = event.originalEvent.x + 300 > window.innerWidth ? event.currentPointer.viewportX - 300 : event.currentPointer.viewportX
                  this.shopData.pinPopUpYCoord = event.originalEvent.y + 160 > window.innerHeight ? event.currentPointer.viewportY - 160 : event.currentPointer.viewportY
                  this.shopData.averageRating = noisePointData.averageRating
                  this.shopData.label = noisePointData.label
                  this.shopData.name = noisePointData.name
                  this.shopData.franchise = noisePointData.franchise
                  this.shopData.address = noisePointData.address
                  this.shopData.status = noisePointData.status
                  this.pinHovered = true
                })

                marker.addEventListener('pointerleave', () => {
                  this.pinHovered = false
                  marker.setZIndex(1)
                })
              }

              marker.setData(noisePointData)
              this.clusteringMarkers.set(noisePointData.id, marker)
              return marker
            }
          }
        })

        this.clusteringLayer = new this.H.map.layer.ObjectLayer(this.clusteringDataProvider)
        this.map.addLayer(this.clusteringLayer)
      }
    },
    removeClasterData() {
      if (this.clusteringLayer) {
        this.map.removeLayer(this.clusteringLayer)
        this.clusteringDataProvider = null
      }
    },
    // NE KORISTI SE VISE OVA FUNKCIJA
    createPin() {
      if (this.map) {

        // Reset markers group
        if (this.mapGroup) {
          this.mapGroup.removeAll()
        }
        // Create a group to hold all the markers
        this.mapGroup = new this.H.map.Group()
        this.markerCoords = []
        const shopsToIterate = Array.isArray(this.shops) ? this.shops : [this.shops]
        shopsToIterate.forEach((shop) => {
          const icon = shop.status && shop.status === 'BLACKLISTED' ? this.checkIconCategory('blacklisted') : (shop ? this.checkIconCategory(shop.label) : this.checkIconCategory(null))
          // Create markers for all shops or the single shop
          const marker = new this.H.map.Marker(
            { lat: shop.latitude, lng: shop.longitude },
            { icon: icon }
          )
          if (shop.id) {
            marker.setData({
              id: shop.id
            })
          }

          if (this.isShopMainPage) {
            marker.addEventListener('pointerenter', (event) => {
              this.shopData.pinPopUpXCoord = event.originalEvent.x + 300 > window.innerWidth ? event.currentPointer.viewportX - 300 : event.currentPointer.viewportX
              this.shopData.pinPopUpYCoord = event.originalEvent.y + 160 > window.innerHeight ? event.currentPointer.viewportY - 160 : event.currentPointer.viewportY
              this.shopData.averageRating = shop.averageRating
              this.shopData.label = shop.label
              this.shopData.name = shop.name
              this.shopData.franchise = shop.franchise
              this.shopData.address = shop.address
              this.shopData.status = shop.status
              this.pinHovered = true
            })
          }

          marker.addEventListener('pointerleave', () => {
            this.pinHovered = false
          })
          //TODO: Don't redirect when we are at ShopDetails page and when we are in AddShopForm
          marker.addEventListener('tap', () => {
            this.$router.push({ name: 'ShopDetails', params: { shopId: shop.id } })
          })

          this.mapGroup.addObject(marker)
          this.getPinPositionsPx(marker, shop)
        })
        this.map.addObject(this.mapGroup)
        if (!this.isShopMainPage) this.updateBoundingBox() //da bi lepo zumirao mapu kada je Add/Edit Shop Forma
      }
    },
    // Set the bounding box to include all shops
    updateBoundingBox() {
      const boundingBox = this.clusteringDataProvider.getRootGroup().getBoundingBox()
      if (boundingBox) {
        if(!this.isShopMainPage){ //Shop Details and Add ShopForm
          boundingBox.c = boundingBox.c + 0.05
          boundingBox.f = boundingBox.f - 0.05
        }else{ //Shop Main Page
          boundingBox.b = boundingBox.b + 1 //east
          boundingBox.a = boundingBox.a - 1 //west
          boundingBox.c = boundingBox.c + 1 //north
          boundingBox.f = boundingBox.f - 1 //south
        }

        // Set the map's viewport to focus on the bounding box
        this.isProgrammaticChange = true
        this.map.getViewModel().setLookAtData({
          bounds: boundingBox,
        })

        // Fix for excessive zoom out on the map when pins are scattered across the USA.
        // setTimeout(() => {
        //   const dataLook = this.map.getViewModel().getLookAtData()
        //   const zoomAfterUpdatingBounds = dataLook.zoom
        //   console.log(zoomAfterUpdatingBounds)
        //   if (!zoomAfterUpdatingBounds) {
        //     this.map.getViewModel().setLookAtData({
        //       zoom: 4,
        //       bounds: boundingBox,
        //     })
        //   }
        // }, 300)

        // We use this to distinguish programmatic movement of the map from user movement.
        setTimeout(() => {
          this.isProgrammaticChange = false
        }, 1000)
      }
    },
    getMapBounds() {
      const bounds = this.map.getViewModel().getLookAtData().bounds.getBoundingBox()
      if (bounds) {
        const boundingBox = {
          north: bounds.c, // Severna granica
          south: bounds.f, // Južna granica
          east: bounds.b, // Istočna granica
          west: bounds.a // Zapadna granica
        }
        return boundingBox
      }
      return null
    },
    moveMapToPosition (
      position,
      zoomLvl,
      isProgrammatic
    ) {
      if (this.map) {
        this.isProgrammaticChange = isProgrammatic
        this.map.getViewModel().setLookAtData(
          {
            position: position,
            zoom: zoomLvl
          },
          1.8
        )
      }
    },
    handleShopCardHover() {
      // this.handleIconChange(data, true)
    },
    handleShopCardNotHovered() {
      // this.handleIconChange(data, false)
    },
    handleBlacklistedBoxShopHovered() {
      // this.handleIconChange(data, true)
    },
    handleBlacklistedBoxShopNotHovered() {
      // this.handleIconChange(data, false)
    },
    handleIconChange(data, isActive) {
      const markerIdToFind = data.id
      const iconName = data.status === 'BLACKLISTED' ? data.status : data.label
      const mappings = isActive ? this.iconMappingsActive : this.iconMappings

      const foundMarker = this.mapGroup.getObjects().find(marker => marker.getData().id === markerIdToFind)

      if (foundMarker && iconName in mappings) {
        const newIcon = new this.H.map.Icon(mappings[iconName])
        foundMarker.setIcon(newIcon)
      }
    },
    getPinPositionsPx(marker, shop) {
      const screenCoords = this.map.geoToScreen(marker.getGeometry())
      // console.log('screenCoords -> ', screenCoords)
      this.pinPositionX = screenCoords.x
      this.pinPositionY = screenCoords.y

      const data = {
        shopObject: { ...shop },
        pinPositionX: this.pinPositionX,
        pinPositionY: this.pinPositionY
      }
      this.markerCoords.push(data)
    },
    checkIconCategory(category) {
      const pins = this.mapPins
      if (category && pins) {
        return pins[category.toLowerCase()]
      } else {
        return new this.H.map.Icon(defaultPin)
      }
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.dispose()
    }
  }
}
</script>

<style lang="scss">
.here-map-wrapper {
  position: relative;
  width: 100%;
  display: flex;

  .here-map {
    flex: 1;
    height: 100%;
  }
}
</style>