import Api from '@/services/Api'

export default {
  getItems(params, filters) {
    return Api().post('/lease-units', { ...params, filters })
  },
  getStats(companyId) {
    return Api().get(`/lease-units/stats/${companyId}`)
  },
  addItem(body) {
    return Api().post('/lease-units/add', body)
  },
  editItem(id, body) {
    return Api().put(`/lease-units/${id}`, body)
  },
  getItem(id) {
    return Api().get(`/lease-units/${id}`)
  },
  deleteItem(id) {
    return Api().delete(`/lease-units/${id}`)
  },
  searchUnits(query, type) {
    return Api().get(`/lease-units/search/units/${query}/${type}`)
  },
  addComment(unitId, body) {
    return Api().post(`/lease-units/comments/${unitId}`, body)
  },
  editComment(unitId, body) {
    return Api().put(`/lease-units/comments/${unitId}`, body)
  },
  deleteComment(unitId, commentId) {
    return Api().delete(`/lease-units/comments/${unitId}/${commentId}`)
  }
}
