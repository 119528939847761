import Api from '@/services/Api'

export default {
  getMessages (pagination) {
    let url = '/messages'

    if (pagination.type === 'daily' || pagination.type === 'weekly') {
      url += '/market-report?type=' + pagination.type
    }
    
    return Api().get(url, {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1
    }})
  },
  addMessage (message) {
    return Api().post('/messages', message)
  },
  deleteUserFromMsg (messageId, userId) {
    return Api().put(`/messages/${messageId}/${userId}`)
  },
  getUserMessages () {
    return Api().get('/messages/user-messages')
  },
  getInboxMessages (page, perPage) {
    return Api().get(`/messages/by-role?page=${page}&perPage=${perPage}`)
  }
}
