import Api from '@/services/Api'

export default {
  getAppUsers (filters) {
    return Api().get('/app-users', {params: filters})
  },
  getAutocomplete(search) {
    return Api().get(`/app-users/autocomplete/${search}`)
  },
  deactivateUser (userId) {
    return Api().put(`/app-users/deactivate-user/${userId}`)
  },
  updateStatus (user, status) {
    return Api().put(`/app-users/update-user-status/${user._id}/${status}`, {dispatcher: user.dispatcher, secondDispatcher: user.secondDispatcher})
  },
  updateDispatchers (user) {
    return Api().put(`/app-users/update-dispatchers/${user._id}`, {dispatcher: user.dispatcher, secondDispatcher: user.secondDispatcher})
  },
  resendVerificationEmail (userId) {
    return Api().get(`/app-users/resend-verification/${userId}`)
  },
  getConversations () {
    return Api().get('/app-users/get-conversations')
  },
  toggleSelfDispatch(userId) {
    return Api().post(`/app-users/toggle-can-self-dispatch/${userId}`)
  },
  addDriver(userId, driver) {
    return Api().put(`/app-users/add-driver/${userId}`, {driver})
  }
}
