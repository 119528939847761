import Api from '@/services/Api'

export default {
  getCompanies (pagination) {
    return Api().get('/companies', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1
    }})
  },
  addCompany (company) {
    return Api().post('/companies', company)
  },
  editCompany (company) {
    return Api().put('/companies', company)
  },
  deleteCompany (id) {
    return Api().delete(`/companies/${id}`)
  },
  getAllCompanies () {
    return Api().get('/companies/all')
  },
  getCompaniesAutocomplete (query) {
    return Api().get(`/companies/autocomplete/${query}`)
  }
}
