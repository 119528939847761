import Api from '@/services/Api'

export default {
  getEldCompanies (pagination) {
    return Api().get('/eld-companies', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1
    }})
  },
  addEldCompany (eldCompany) {
    return Api().post('/eld-companies', eldCompany)
  },
  editEldCompany (eldCompany) {
    return Api().put('/eld-companies', eldCompany)
  },
  deleteEldCompany (id) {
    return Api().delete(`/eld-companies/${id}`)
  },
  getAllEldCompanies () {
    return Api().get('/eld-companies/all')
  }
}
