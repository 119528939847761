<template>
  <v-menu class="StatusColumn" offset-y min-width="140" absolute>
    <template v-slot:activator="{ on, attrs }">
      <div
        class="StatusColumn__text"
        v-bind="attrs"
        @contextmenu.prevent="open($event, on)" 
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ text }}
            </span>
          </template>
          <span style="display: block">{{ text }}</span>
        </v-tooltip>
      </div>
    </template>
    <v-list v-if="!disabled">
      <div class="StatusColumn__title">
        <slot name="title"></slot>
      </div>
      <v-list dense>
        <v-list-item-group :value="null">
          <v-list-item
            @click="emitEvent(action.event)"
            v-for="action in actions"
            :key="action.event"
          >
            <v-list-item-content>
              <v-list-item-title>{{ action.text }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon small>{{ action.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
        <v-divider></v-divider>
        <v-list-item-group
          v-if="statuses && statuses.length"
          :value="status && status.order - 1"
        >
          <v-subheader>Change status</v-subheader>
          <v-list-item v-for="status in statuses" :key="status.text">
            <v-list-item-content @click="onChangeStatus(status)">
              <v-list-item-title class="status-item">
                <v-badge :color="status.color" dot inline>
                  {{ status.text }}
                </v-badge>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'StatusColumn',
  props: {
    status: {
      required: false,
      type: Object,
    },
    actions: {
      required: false,
      type: Array,
    },
    text: {
      required: true,
      type: String,
    },
    statuses: {
      required: false,
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {}
  },
  methods: {
    emitEvent(event) {
      this.$emit(event)
    },
    onChangeStatus(status) {
      this.$emit('statusChange', status)
    },
    open(e, activator){
      const yardTitles = document.getElementsByClassName('AvailableTable__title')
      yardTitles[0].click()
      activator.click(e)
    }
  },
}
</script>

<style lang="scss">
.StatusColumn {
  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
    height: 32px;
    padding: 0 6px;
    line-height: 34px;
  }
  &__title{
    display: flex;
    margin: 0px 30px;
    justify-content: space-around;
    font-weight: 500;
  }
  .v-list-item__title.status-item > .v-badge {
    margin-top: 0px !important;
  }
}
</style>
