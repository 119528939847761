import Api from '@/services/Api'

export default {
  getDealerships (pagination) {
    return Api().get('/dealerships', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? -1 : 1
    }})
  },
  addDealership (dealership) {
    return Api().post('/dealerships', dealership)
  },
  editDealership (dealership) {
    return Api().put('/dealerships', dealership)
  },
  deleteDealership (id) {
    return Api().delete(`/dealerships/${id}`)
  },
  getAllDealerships (type) {
    return Api().get(`/dealerships/all/${type}`)
  }
}
