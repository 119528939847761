<template>
  <div class="history-wraper" :class="{ small: small }">
    <v-list v-if="!loadingHi && changes.length" class="trailer-history">
      <template v-for="(item, index) of changes">
        <v-list-item :key="`history-${item._id}`">
          <v-list-item-content>
            <div class="history-actions">
              <v-icon :disable="!canEdit" small :color="canEdit ? 'primary' : ''" @click="showEditHistory(item)"
                >mdi-pencil</v-icon
              >
              <v-icon :disable="!canEdit" small :color="canEdit ? 'error' : ''" @click="deleteHistory(item)"
                >mdi-delete</v-icon
              >
            </div>
            <div v-if="item.state !== 'repower'">
              <v-list-item-subtitle class="history-text">
                from
                <span class="date-highlight">{{
                  item.date_from | formatDate('MM/DD/YYYY')
                }}</span>
                to
                <span :class="'date-highlight'">{{
                  item.date_to
                    ? $options.filters.formatDate(item.date_to, 'MM/DD/YYYY')
                    : $options.filters.formatDate(new Date(), 'MM/DD/YYYY')
                }}</span>
                dragged <strong>{{ item.subject }}</strong>
              </v-list-item-subtitle>
              <v-textarea
                v-if="noteId && noteId === item._id"
                v-model="note"
                @keydown.enter="handleEnter($event)"
                ref="comtext"
                :autofocus="true"
                name="note"
                label="Note"
                :hide-details="'auto'"
                rows="3"
                counter
                solo
                clearable
                clear-icon="mdi-close-circle"
                :append-icon="
                  note && note.length ? 'mdi-checkbox-marked-circle' : ''
                "
                placeholder="Press CTRL + Enter to save..."
                :no-resize="true"
                @click:clear="onCleareNote"
                @click:append="addNote"
              />
            </div>
            <div v-if="item.state === 'repower'" class="history-text">
              <span class="date-highlight">{{
                  item.date_from | formatDate('MM/DD/YYYY')
                }}</span>
              -
              <span :class="'date-highlight'">{{
                  item.date_to
                      ? $options.filters.formatDate(item.date_to, 'MM/DD/YYYY')
                      : $options.filters.formatDate(new Date(), 'MM/DD/YYYY')
                }}</span>
              Vozac:
              <span >{{
                  item.subject
                }}</span>
              radi repower za vozaca:
              <span >{{ item.subject_repower }}</span>
            </div>
            <div class="bottom-history">
              <span class="note">{{ item.note ? item.note : '' }}</span>
              <span title="The user who made changes" class="user-email">{{
                item.user
              }}</span>
              <span v-if="item.state" style="font-size: 13px"
                >state:
                <span style="font-weight: bold">{{ item.state }}</span></span
              >
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="`divider-${index}`"
          v-if="index < changes.length - 1"
        ></v-divider>
      </template>
    </v-list>
    <p class="no-history" v-else-if="!loadingHi && !changes.length">
      No trailer history ...
    </p>
    <v-progress-circular
      v-else
      indeterminate
      class="trailer-history-loader"
      color="primary"
    ></v-progress-circular>
    <v-btn
      :disabled="!canAddHistory"
      v-if="type === 'trailer'"
      @click="showHistoryDialog = true"
      class="mt-1"
      block
      elevation="2"
      small
      color="success"
      >Add history</v-btn
    >
    <v-dialog v-model="showHistoryDialog" width="400px" persistent>
      <v-card>
        <form @submit.prevent="onAddTrailerHistory" data-vv-scope="history">
          <v-card-title class="lighten-4 py-4 title">
            {{ editId ? 'Edit trailer history' : ' Add trailer history' }}
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex md12 xs12 v-if="!form.owner">
                <v-autocomplete
                  clearable
                  v-model="form.driver"
                  :items="driverListFormated"
                  return-object
                  item-text="name"
                  item-id="id"
                  item-disabled="disabled"
                  label="Driver"
                  :search-input.sync="searchDrivers"
                  hide-details="auto"
                  hide-no-data
                  :loading="loading"
                  placeholder="Start typing to Search"
                  prepend-icon="mdi-database-search"
                  :filter="clientFilterDrivers"
                  name="driver"
                  @click:clear="form.driver = null"
                  @input="handleDriverSelect"
                >
                  <template v-slot:item="{ item }">
                    <span>
                      {{ item.name }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex md12 xs12 v-if="!form.owner">
                <v-combobox
                  ref="truck"
                  v-model="form.truck"
                  hide-details="auto"
                  :items="trucks"
                  :search-input.sync="searchTrucks"
                  :loading="loading"
                  label="Truck"
                  hide-no-data
                  placeholder="Start typing..."
                  prepend-icon="mdi-truck"
                  clearable
                  name="truck"
                  id="truck"
                  item-text="number"
                  item-id="number"
                  :return-object="false"
                  @click:clear="form.truck = null"
                ></v-combobox>
              </v-flex>
              <v-flex md12 xs12 v-if="!form.truck">
                <v-autocomplete
                  clearable
                  :items="owners"
                  v-model="form.owner"
                  :value="form.owner"
                  label="Owner"
                  prepend-icon="mdi-domain"
                  item-value="_id"
                  item-text="name"
                  hide-details="auto"
                  return-object
                >
                  <template v-slot:item="{ item }">
                    <span :class="{ 'is-company': item.is_company }">
                      {{ item.name }}
                      <span v-if="item.is_company">Third party</span>
                    </span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="form.date_from"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date_from"
                      label="From"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date_from"
                    :allowed-dates="disableFutureDates"
                    no-title
                    scrollable
                    @change="$refs.menu.save(form.date_from)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(form.date_from)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="form.date_to"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date_to"
                      label="To"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date_to"
                    no-title
                    :allowed-dates="disableFutureDates"
                    scrollable
                    @change="$refs.menu2.save(form.date_to)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu2.save(form.date_to)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="form.company"
                  :items="companiesAll"
                  prepend-icon="mdi-division"
                  clearable
                  label="Division"
                  item-text="name"
                  item-value="name"
                  :hide-details="'auto'"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="form.state"
                  name="state"
                  label="State"
                  :items="statusOptions"
                  item-text="label"
                  item-value="value"
                  :hide-details="'auto'"
                  prepend-icon="mdi-list-status"
                  rows="2"
                  counter
                  clearable
                  :no-resize="true"
                />
              </v-flex>
              <template v-if="form.state === 'repower'">
                <v-flex md12 xs12 v-if="!form.owner_repower">
                  <v-autocomplete
                      clearable
                      v-model="form.driver_repower"
                      :items="driverListFormated_repower"
                      return-object
                      item-text="name"
                      item-id="id"
                      item-disabled="disabled"
                      label="Driver Repower"
                      :search-input.sync="searchDrivers_repower"
                      hide-details="auto"
                      hide-no-data
                      :loading="loading"
                      placeholder="Start typing to Search"
                      prepend-icon="mdi-database-search"
                      :filter="clientFilterDrivers"
                      name="driver"
                      @click:clear="form.driver_repower = null"
                      @input="handleDriverRepowerSelect"
                  >
                    <template v-slot:item="{ item }">
                    <span>
                      {{ item.name }}
                    </span>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex md12 xs12 v-if="!form.owner_repower">
                  <v-combobox
                      ref="truck"
                      v-model="form.truck_repower"
                      hide-details="auto"
                      :items="trucks_repower"
                      :search-input.sync="searchTrucks_repower"
                      :loading="loading"
                      label="Truck Repower"
                      hide-no-data
                      placeholder="Start typing..."
                      prepend-icon="mdi-truck"
                      clearable
                      name="truck"
                      id="truck"
                      item-text="number"
                      item-id="number"
                      :return-object="false"
                      @click:clear="form.truck_repower = null"
                  ></v-combobox>
                </v-flex>
                <v-flex md12 xs12 v-if="!form.truck_repower">
                  <v-autocomplete
                      clearable
                      :items="owners"
                      v-model="form.owner_repower"
                      :value="form.owner_repower"
                      label="Owner"
                      prepend-icon="mdi-domain"
                      item-value="_id"
                      item-text="name"
                      hide-details="auto"
                      return-object
                  >
                    <template v-slot:item="{ item }">
                    <span :class="{ 'is-company': item.is_company }">
                      {{ item.name }}
                      <span v-if="item.is_company">Third party</span>
                    </span>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="form.company_repower"
                    :items="companiesAll"
                    prepend-icon="mdi-division"
                    clearable
                    label="Division repower"
                    item-text="name"
                    item-value="name"
                    :hide-details="'auto'"
                  />
                </v-flex>
              </template>
              <v-flex xs12>
                <v-textarea
                  v-model="form.note"
                  name="note"
                  label="Note"
                  :hide-details="'auto'"
                  rows="2"
                  counter
                  clearable
                  :no-resize="true"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="handleCancel"
              >Cancel</v-btn
            >
            <v-btn
              v-if="!editId"
              color="info"
              :loading="loading"
              :disabled="loading"
              type="submit"
              >Add</v-btn
            >
            <v-btn
              v-if="editId"
              color="info"
              :loading="loading"
              :disabled="loading || !canEdit"
              @click="editHistory()"
              >Edit</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import trailersService from '../../services/TrailersService'
import callsService from '@/services/CallsService'
import TrucksService from '../../services/TruckService'
import DriverService from '../../services/DriverService'
import debounce from 'lodash/debounce'
import TrailersService from '../../services/TrailersService'

export default {
  name: 'TrailerHistory',
  props: ['id', 'type', 'small', 'trailerNumber', 'driverName'],
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('common', ['companiesAll']),
    ...mapGetters('common', ['loading', 'dark']),
    canEdit() {
      return ['SUPERADMIN', 'ADMIN', 'TRAILER_DEPARTMENT'].indexOf(this.user.roles[0].role_name) !== -1
    },
    driverListFormated() {
      return this.drivers.map((item) => {
        item.disabled = false
        // item.text = `${item.truck_id} - ${item.name}`
        return item
      })
    },
    driverListFormated_repower() {
      return this.drivers_repower.map((item) => {
        item.disabled = false
        // item.text = `${item.truck_id} - ${item.name}`
        return item
      })
    },
    canAddHistory() {
      return (
        ['SUPERADMIN', 'ADMIN', 'TRAILER_DEPARTMENT'].indexOf(
          this.user.roles[0].role_name
        ) !== -1
      )
    },
  },
  data() {
    return {
      loadingHi: true,
      changes: [],
      noteId: null,
      note: '',
      showHistoryDialog: false,
      trucks: [],
      trucks_repower: [],
      owners: [],
      owners_repower: [],
      drivers: [],
      drivers_repower: [],
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      editId: null,
      form: {
        truck: null,
        truck_repower: null,
        owner: null,
        owner_repower: null,
        driver: null,
        driver_repower: null,
        date_from: null,
        date_from_repower: null,
        company: null,
        company_repower: null,
        date_to: null,
        date_to_repower: null,
        note: '',
        state: null,
        state_repower: null,
      },
      searchDrivers: '',
      searchDrivers_repower: '',
      searchTrucks: '',
      searchTrucks_repower: '',
      statusOptions: [
        { label: 'Temp', value: 'temp' },
        { label: 'Recovery', value: 'recovery' },
        { label: 'Repower', value: 'repower' },
      ],
    }
  },
  mounted() {
    this.getHistory()
    this.getOwners()
  },
  watch: {
    searchDrivers(val) {
      if (val) this.debounceGetDrivers(val)
    },
    searchTrucks(val) {
      if (val) {
        this.debounceSearchTrucks(val)
      }
    },
    searchDrivers_repower(val) {
      if (val) this.debounceGetDrivers_repower(val)
    },
    searchTrucks_repower(val) {
      if (val) {
        this.debounceSearchTrucks_repower(val)
      }
    },
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),

    handleCancel() {
      if (this.editId) {
        this.closeEditHistoryDialog()
      } else {
        this.closeHistoryDialog()
      }
    },

    async handleDriverSelect(selectedDriver) {
      if (selectedDriver) {
        await this.fetchTruckAndDivisionForDriver(selectedDriver.truck_id)
      }
    },
    async fetchTruckAndDivisionForDriver(truckNumber) {
      try {
        const response = await TrucksService.getTruckByNumber(truckNumber)
        this.form.truck = response.data.truck ? response.data.truck.number : null
        this.form.company = response.data.truck ? response.data.truck.division : null
      } catch (error) {
        console.error('Error fetching trucks:', error)
      }
    },
    async handleDriverRepowerSelect(selectedDriverRepower) {
      if (selectedDriverRepower) {
        await this.fetchTruckAndDivisionForRepowerDriver(selectedDriverRepower.truck_id)
      }
    },
    async fetchTruckAndDivisionForRepowerDriver(truckNumber) {
      try {
        const response = await TrucksService.getTruckByNumber(truckNumber)
        this.form.truck_repower = response.data.truck.number
        this.form.company_repower = response.data.truck.division
      } catch (error) {
        console.error('Error fetching repower trucks:', error)
      }
    },
    debounceGetDrivers: debounce(function (val) {
      if (val && val.length) {
        callsService
          .getMergedDrivers(val)
          .then((res) => {
            if (res.data.status === 'OK') {
              this.drivers = res.data.drivers
            }
          })
          .catch((err) => {
            console.log('drivers error search', err)
            this.drivers = []
          })
      } else {
        this.drivers = []
      }
    }, 400),
    debounceGetDrivers_repower: debounce(function (val) {
      if (val && val.length) {
        callsService
          .getMergedDrivers(val)
          .then((res) => {
            if (res.data.status === 'OK') {
              this.drivers_repower = res.data.drivers
            }
          })
          .catch((err) => {
            console.log('drivers error search', err)
            this.drivers = []
          })
      } else {
        this.drivers = []
      }
    }, 400),
    debounceSearchTrucks: debounce(function (search) {
      if (search && search.length) {
        this.$store.dispatch('common/setLoading', true)
        TrucksService.searchTruck(search)
          .then((res) => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data) {
              this.trucks = res.data
            }
          })
          .catch((err) => {
            console.log('trailers error search', err)
            this.trucks = []
          })
      } else {
        this.trucks = []
      }
    }, 400),
    debounceSearchTrucks_repower: debounce(function (search) {
      if (search && search.length) {
        this.$store.dispatch('common/setLoading', true)
        TrucksService.searchTruck(search)
          .then((res) => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data) {
              this.trucks_repower = res.data
            }
          })
          .catch((err) => {
            console.log('trailers error search', err)
            this.trucks_repower = []
          })
      } else {
        this.trucks_repower = []
      }
    }, 400),
    getHistory() {
      this.loadingHi = true
      trailersService.getTrailerChanges(this.id, this.type).then((res) => {
        this.loadingHi = false
        if (res.data.status === 'OK') {
          this.changes = res.data.changes
        }
      })
    },
    addEditNote(item) {
      if (this.noteId !== item._id) {
        this.noteId = item._id
        this.note = item.note ? item.note : ''
      } else {
        this.noteId = null
        item.note = ''
      }
    },
    deleteHistory(item) {
      if (!this.canEdit) return
      let confVal = confirm('Are you sure you want to delete history')
      if (confVal) {
        trailersService
          .deleteHistory(item._id)
          .then((res) => {
            if (res.data.status === 'OK') {
              let index = this.changes.findIndex(
                (change) => change._id === item._id
              )
              this.changes.splice(index, 1)
              this.setMessage('History successfully deleted')
            }
          })
          .catch((err) => {
            console.log('err on add note', err)
          })
      }
    },

    async showEditHistory(item) {
      this.editId = item._id
      const parsedSubject = item.subject ? this.parseSubject(item.subject) : {}
      const parsedSubjectRepower = item.subject_repower ? this.parseSubject(item.subject_repower) : {}


      const driverResponse = await DriverService.getDrivers({
        search: parsedSubject.driver
      })

      const driver = driverResponse.data.docs

      let driverRepower = []
      if (item.state === 'repower' && item.subject_repower) {
        const driverRepowerResponse = await DriverService.getDrivers({
          search: parsedSubjectRepower.driver
        })
        driverRepower = driverRepowerResponse.data.docs
      }

      if (!this.drivers.some(d => d.id === driver[0].id)) {
        this.drivers.push(driver[0])
      }

      if (driverRepower[0] && !this.drivers_repower.some(d => d.id === driverRepower[0].id)) {
        this.drivers_repower.push(driverRepower[0])
      }

      this.form = {
        truck: parsedSubject.truck || null,
        truck_repower: parsedSubjectRepower.truck || null,
        owner: item.owner || null,
        owner_repower: item.owner_repower || null,
        driver: driver[0] || null,
        driver_repower: driverRepower[0] || null,
        date_from: item.date_from || null,
        company: item.company || null,
        company_repower: parsedSubjectRepower.company || null,
        date_to: item.date_to || null,
        note: item.note || '',
        state: item.state || null,
        state_repower: item.state_repower || null,
      }

      // stavio sam zbog formatiranja imena na beku na ruti jer se form.name ne poklapa sa driverFormated.name i onda bude prazno
      this.form.driver.name = this.form.driver.formatedName

      this.showHistoryDialog = true
    },

    parseSubject(subject) {
      const parts = subject.split(' - ')
      const driver = parts[0] ? parts[0].trim() : ''
      const truck = parts[1] ? parts[1].trim() : ''
      const company = parts[2] ? parts[2].trim() : ''
        
      return {
        driver,
        truck,
        company
      }
      
    },

    async editHistory() {
      let data = {
        _id: this.editId,
        subject: this.form.driver ? this.form.driver.name : '',
        subject_repower: this.form.driver_repower ? this.form.driver_repower.name : '',
        date_from: this.form.date_from,
        date_from_repower: this.form.date_from_repower,
        company: this.form.company,
        date_to: this.form.date_to,
        date_to_repower: this.form.date_to_repower,
        note: this.form.note,
        state: this.form.state,
        driver_id:
          this.form.driver && this.form.driver.id ? this.form.driver.id : null,
        driver_id_repower:
          this.form.driver_repower && this.form.driver_repower.id ? this.form.driver_repower.id : null,
      }
      if (this.form.truck) {
        data.subject += ` - ${this.form.truck}`
      }
      if (this.form.company) {
        data.subject += ` - ${this.form.company}`
      }
      if (this.form.truck_repower) {
        data.subject_repower += ` - ${this.form.truck_repower}`
      }
      if (this.form.company_repower) {
        data.subject_repower += ` - ${this.form.company_repower}`
      }
      if (this.form.owner) {
        data.driver_id = null
        data.subject = this.form.owner.name
      }
      if (this.form.owner_repower) {
        data.driver_id_repower = null
        data.subject_repower = this.form.owner_repower.name
      }
      TrailersService.editHistory(data).then((res) => {
        if (res.data.status === 'OK') {
          this.setMessage('Trailer history successfully edited')
          this.closeEditHistoryDialog()
          this.getHistory()
        }
      })
    },

    disableFutureDates(val) {
      return val <= new Date().toISOString().substr(0, 10)
    },

    addNote() {
      if (this.note.length <= 250) {
        trailersService
          .addNotToHistory(this.noteId, this.note)
          .then((res) => {
            if (res.data.status === 'OK') {
              let index = this.changes.findIndex(
                (change) => change._id === res.data.doc._id
              )
              this.changes.splice(index, 1, res.data.doc)
              this.setMessage('Note successfully added / updated')
              this.onCleareNote()
            }
          })
          .catch((err) => {
            console.log('err on add note', err)
          })
      } else {
        this.setMessage('The note cannot be longer than 250 characters!')
      }
    },
    handleEnter(e) {
      if (e.ctrlKey) {
        this.addNote()
      }
    },
    onCleareNote() {
      this.noteId = null
      this.note = ''
    },
    clientFilterDrivers(item, queryText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1
      )
    },
    clearForm() {
      this.form = {
        truck: null,
        date_from: null,
        date_to: null,
        company: null,
        note: '',
        state: null,
      }
    },
    clearEditForm() {
      this.form = {
        truck: null,
        truck_repower: null,
        owner: null,
        owner_repower: null,
        driver: null,
        driver_repower: null,
        date_from: null,
        date_from_repower: null,
        company: null,
        company_repower: null,
        date_to: null,
        date_to_repower: null,
        note: '',
        state: null,
        state_repower: null
      }
      this.drivers = []
      this.drivers_repower = []
    },
    closeHistoryDialog() {
      this.showHistoryDialog = false
      this.clearForm()
    },
    closeEditHistoryDialog() {
      this.showHistoryDialog = false
      this.editId = null
      this.clearEditForm()
    },
    onAddTrailerHistory() {
      let data = {
        id: this.id,
        type: this.type,
        name: this.trailerNumber,
        subject: this.form.driver ? this.form.driver.name : '',
        subject_repower: this.form.driver_repower ? this.form.driver_repower.name : '',
        // subject: `${this.form.truck && this.form.truck.text ? this.form.truck.text :  this.form.truck ? this.form.truck : ''}`,
        date_from: this.form.date_from,
        date_from_repower: this.form.date_from_repower,
        company: this.form.company,
        date_to: this.form.date_to,
        date_to_repower: this.form.date_to_repower,
        note: this.form.note,
        state: this.form.state,
        driver_id:
          this.form.driver && this.form.driver.id ? this.form.driver.id : null,
        driver_id_repower:
          this.form.driver_repower && this.form.driver_repower.id ? this.form.driver_repower.id : null,
      }
      if (this.form.truck) {
        data.subject += ` - ${this.form.truck}`
      }
      if (this.form.company) {
        data.subject += ` - ${this.form.company}`
      }
      if (this.form.truck_repower) {
        data.subject_repower += ` - ${this.form.truck_repower}`
      }
      if (this.form.company_repower) {
        data.subject_repower += ` - ${this.form.company_repower}`
      }
      if (this.form.owner) {
        data.driver_id = null
        data.subject = this.form.owner.name
      }
      if (this.form.owner_repower) {
        data.driver_id_repower = null
        data.subject_repower = this.form.owner_repower.name
      }
      this.$store.dispatch('trailers/addHistory', data).then((res) => {
        if (res.data.status === 'OK') {
          this.setMessage('Trailer history successfully added')
          this.closeHistoryDialog()
          this.getHistory()
        }
      })
    },
    getOwners() {
      this.$store.dispatch('trailers/getAllOwners').then((res) => {
        this.owners = res
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.history-wraper {
  position: relative;
  min-width: 400px;
  min-height: 200px;
  padding: 10px;

  &.small {
    .trailer-history {
      .history-text {
        font-size: 11px;
      }
    }
  }

  .trailer-history {
    max-height: 200px;
    overflow-y: scroll;
    .v-list-item {
      position: relative;

      .history-actions {
        position: absolute;
        right: 2px;
        top: 0px;
      }

      .history-text {
        white-space: pre-wrap;
        padding-right: 8px;

        .date-highlight {
          color: #2196f3;
          font-weight: 600;
        }
      }

      .bottom-history {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .note {
          text-align: left;
          font-size: 13px;
          font-style: italic;
          padding-left: 2px;
        }
        .user-email {
          text-align: right;
          font-size: 11px;
          color: #2196f3;
        }
      }
    }
  }

  .no-history {
    text-align: center;
  }

  .trailer-history-loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
}

.is-company {
  span {
    position: absolute;
    left: 16px;
    bottom: 0px;
    font-size: 11px;
    color: #ff5252 !important;
  }
}
</style>
