<template>
  <v-dialog
    v-model="inactiveModel"
    persistent
    max-width="310"
  >
    <v-card>
      <v-card-title class="headline">
        You've been inactive for a while
      </v-card-title>
      <v-card-text>
        Log in again to use the app
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="redirectToLogin"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'InactiveModal',
  computed: {
    ...mapGetters('auth', ['inactiveModel'])
  },
  methods: {
    redirectToLogin () {
      this.$store.dispatch('auth/showInactiveModal', false)
      this.$store.dispatch('auth/logoutLocal')
      this.$router.push({name: 'login'})
    }
  },
}
</script>