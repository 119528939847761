<template>
  <div class="TrailerMarks">
    <v-card>
      <v-card-title>
        {{ $t('other.trailerMarks') }}
      </v-card-title>
      <v-data-table
        height="550"
        :disable-sort="true"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalItems"
        :items="items"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 15, 25],
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.is_active="{ item }">
          {{ item.is_active ? 'YES' : 'NO' }}
        </template>
        <template v-slot:item.actions="{ item }">
          <td class="justify-center layout px-0">
            <v-icon small class="mr-2" @click="editTrailerMark(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteTrailerMark(item)"> mdi-delete </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
          color="pink"
          dark
          absolute
          top
          right
          fab
          @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddTrailerMark" data-vv-scope="trailerMark">
          <v-card-title class="lighten-4 py-4 title">
            {{ form._id ? 'Edit' : 'Add' }} trailer make
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="name"
                  v-model="form.name"
                  v-validate="'required'"
                  :error="errors.has('trailerMark.name')"
                  :error-messages="errors.first('trailerMark.name')"
                  name="name"
                  id="name"
                  :autofocus="true"
                  placeholder="Name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="note"
                  v-model="form.note"
                  v-validate="'max:300'"
                  :error="errors.has('trailerMark.note')"
                  :error-messages="errors.first('trailerMark.note')"
                  name="note"
                  id="note"
                  placeholder="Note"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="vin_prefix"
                  v-model="form.vin_prefix"
                  v-validate="'max:300'"
                  :error="errors.has('trailerMark.vin_prefix')"
                  :error-messages="errors.first('trailerMark.vin_prefix')"
                  name="vin_prefix"
                  id="vin_prefix"
                  placeholder="VIN prefix"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="form.is_active"
                  label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
            <v-btn
              color="info"
              :loading="loading"
              :disabled="loading"
              type="submit"
              >{{ form._id ? 'Save' : 'Add' }}</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TrailerMarks',
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 10,
      },
      totalItems: 0,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Vin prefix', value: 'vin_prefix' },
        { text: 'Note', value: 'note' },
        { text: 'Is active', value: 'is_active' },
        { text: 'Actions', value: 'actions', align: 'center' },
      ],
      form: {
        _id: null,
        name: '',
        note: '',
        vin_prefix: null,
        is_active: true,
      },
      items: [],
      formDialog: false,
    }
  },
  watch: {
    options: {
      handler() {
        this.$store
          .dispatch('trailerMarks/getTrailerMarks', this.options)
          .then((res) => {
            this.totalItems = res.total
            this.items = res.docs
          })
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('trailerMarks', ['trailerMarks']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),
    onAddTrailerMark() {
      this.$validator.validateAll('trailerMark').then((result) => {
        if (result) {
          if (this.form._id) {
            this.$store
              .dispatch('trailerMarks/editTrailerMark', this.form)
              .then((res) => {
                if (res) {
                  this.closeForm()
                }
              })
          } else {
            // eslint-disable-next-line no-unused-vars
            const { _id, ...rest } = this.form
            this.$store
              .dispatch('trailerMarks/addTrailerMark', rest)
              .then((res) => {
                if (res) {
                  this.closeForm()
                }
              })
          }
        }
      })
    },
    editTrailerMark(trailerMark) {
      this.form = { ...trailerMark }
      this.formDialog = true
    },
    deleteTrailerMark(trailerMark) {
      let confVal = confirm(
        `Are you sure you want to delete trailer mark: ${trailerMark.name}?`
      )
      if (confVal) {
        this.$store
          .dispatch('trailerMarks/deleteTrailerMark', trailerMark._id)
          .then(() => {
            this.setMessage(`${trailerMark.name} successfully deleted`)
          })
          .catch((err) => {
            console.log('err on delete', err)
          })
      }
    },
    closeForm() {
      this.formDialog = false
      this.form = {
        vin_prefix: null,
        _id: null,
        name: '',
        note: '',
        is_active: true,
      }
    },
  },
}
</script>

<style lang="scss">
.TrailerMarks {
  width: 100%;
  margin-top: 20px;

  .v-list-item.move {
    cursor: move;

    .v-list-item__title.teamLead {
      color: green;
    }
  }
}
</style>
