import eldDriver from '../../services/EldDriversService'

const state = {
  eldDrivers: []
}

const getters = {
  eldDrivers (state) {
    return state.eldDrivers
  }
}

const actions = {
  getEldDrivers ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return eldDriver.getEldDrivers(payload).then(res => {
      commit('setEldDrivers', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllEldDrivers ({dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return eldDriver.getAllEldDrivers().then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addEldDriver ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return eldDriver.addEldDriver(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addEldDriver', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editEldDriver ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return eldDriver.editEldDriver(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editEldDriver', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteEldDriver ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return eldDriver.deleteEldDriver(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteEldDriver', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setEldDrivers (state, eldDrivers) {
    state.eldDrivers = eldDrivers
  },
  addEldDriver (state, contact) {
    const tmpEldDrivers = state.eldDrivers.slice()
    tmpEldDrivers.push(contact)
    state.eldDrivers = tmpEldDrivers
  },
  editEldDriver (state, eldDriver) {
    let tmpEldDrivers = state.eldDrivers.slice()
    let index = tmpEldDrivers.findIndex(el => el._id === eldDriver._id)
    tmpEldDrivers.splice(index, 1, eldDriver)
    state.eldDrivers = tmpEldDrivers
  },
  deleteEldDriver (state, id) {
    const tmpEldDrivers = state.eldDrivers.slice()
    tmpEldDrivers.splice(tmpEldDrivers.findIndex(el => el._id === id), 1)
    state.eldDrivers = tmpEldDrivers
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
