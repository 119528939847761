import Api from '@/services/Api'

export default {
  getContacts (pagination) {
    return Api().get('/contacts', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      search: pagination.search,
      order: pagination.descending ? 1 : -1
    }})
  },
  addContact (contact) {
    return Api().post('/contacts', contact)
  },
  editContact (contact) {
    return Api().put('/contacts', contact)
  },
  deleteContact (id) {
    return Api().delete(`/contacts/${id}`)
  },
  getAllContacts () {
    return Api().get('/contacts/all')
  }
}
