import Api from '@/services/Api'

export default {
  getItems(params, filters) {
    return Api().post('/lease-companies', { ...params, filters })
  },
  export(params) {
    return Api().post('/lease-companies/export', { params })
  },
  getAll() {
    return Api().get('/lease-companies/all')
  },
  addItem(body) {
    return Api().post('/lease-companies/add', body)
  },
  editItem(id, body) {
    return Api().put(`/lease-companies/${id}`, body)
  },
  getItem(id) {
    return Api().get(`/lease-companies/${id}`)
  },
  deleteItem(id) {
    return Api().delete(`/lease-companies/${id}`)
  },
  getHistory(id, type) {
    return Api().get(`/lease-companies/field-history/${id}/${type}`)
  },
  addComment(companyId, body) {
    return Api().post(`/lease-companies/comments/${companyId}`, body)
  },
  editComment(companyId, body) {
    return Api().put(`/lease-companies/comments/${companyId}`, body)
  },
  deleteComment(companyId, commentId) {
    return Api().delete(`/lease-companies/comments/${companyId}/${commentId}`)
  },
  getUserInfo(userId) {
    return Api().get(`/lease-companies/user-info/${userId}`)
  }
}
