import message from '../../services/MessagesService'

const state = {
  messages: []
}

const getters = {
  messages (state) {
    return state.messages
  }
}

const actions = {
  getMessages ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return message.getMessages(payload).then(res => {
      commit('setMessages', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addMessage ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return message.addMessage(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addMessage', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  // editBoard ({commit, dispatch}, payload) {
  //     dispatch('common/setLoading', true, {root: true})
  //     return board.editBoard(payload).then(res => {
  //         dispatch('common/setLoading', false, {root: true})
  //         commit('editBoard', res.data)
  //         return Promise.resolve(res.data)
  //     }).catch(err => {
  //         dispatch('common/setLoading', false, {root: true})
  //         return Promise.reject(err)
  //     })
  // },
}

const mutations = {
  setMessages (state, messages) {
    state.messages = messages
  },
  addMessage (state, message) {
    const tmpMessages = state.messages.slice()
    tmpMessages.push(message)
    state.messages = tmpMessages
  }
  // editBoard (state, board) {
  //     let tmpBoards = state.boards.slice()
  //     let index = tmpBoards.findIndex(el => el._id === board._id)
  //     tmpBoards.splice(index, 1, board)
  //     state.boards = tmpBoards
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}