import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.min.css'
Vue.use(Vuetify)

let localSettings = localStorage.getItem('localSettings') ? JSON.parse(localStorage.getItem('localSettings')) : null

let options = {
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: { 
    dark: false,
    themes: {
      light: {
        primary: '#3f51b5',
        gold:'#B99042',
        platinum:'#B4C5E4',
        silver:'#717B8E',
        basic:'#008000',
        blacklisted:'#8A1829'
      },
      dark: {
        primary: '#6abf69'
      }
    }
  }
}
if (localSettings) {
  options.theme.dark = localSettings.dark
}

export default new Vuetify(options)

