import Api from '@/services/Api'

export default {
  getEldDrivers (pagination) {
    return Api().get('/eld-drivers', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1,
      search: pagination.search
    }})
  },
  addEldDriver (eldDriver) {
    return Api().post('/eld-drivers', eldDriver)
  },
  editEldDriver (eldDriver) {
    return Api().put('/eld-drivers', eldDriver)
  },
  deleteEldDriver (id) {
    return Api().delete(`/eld-drivers/${id}`)
  },
  getAllEldDrivers () {
    return Api().get('/eld-drivers/all')
  }
}
