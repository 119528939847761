<template>
  <div class="users-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.fnd_users') }}
        <v-spacer></v-spacer>
        <v-text-field
          id="step-3"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('other.search')"
          single-line
          hide-details
          height="30"
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        height="760"
        :disable-sort="false"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalItems"
        :items="users"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': search ? [totalItems, totalItems] : [10, 15, 25]
        }"
        :item-class="itemRowBackground"
        @update:options="getUsers"
      >
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              id="step-2"
              @click="editUser(item._id)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteUser(item)"
            >
              mdi-delete
            </v-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  color="error"
                  @click="deleteUserPermanent(item)"
                >
                  mdi-delete-forever
                </v-icon>
              </template>
              <span>Delete this user forever</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
      <!-- Add btn -->
      <v-btn
        fab
        dark
        small
        color="indigo"
        @click.stop="dialog = !dialog"
      >
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
    </v-card>
    <!-- Dialog for edit user -->
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <form @submit.prevent="onSaveUser" data-vv-scope="add">
          <v-card-title
            class="lighten-4 py-4 title"
          >
            {{userForm._id !== null ? 'Edit user' : 'Add new user'}}
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  :autofocus="true"
                  name="email"
                  v-model="userForm.email"
                  v-validate="'required|email'"
                  :error="errors.has('add.email')"
                  :error-messages="errors.first('add.email')"
                  prepend-icon="mdi-email"
                  placeholder="Email"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                  <v-text-field
                    v-model="userForm.name"
                    v-validate="'required'"
                    :error="errors.has('add.name')"
                    :error-messages="errors.first('add.name')"
                    prepend-icon="mdi-account-circle"
                    name="name"
                    placeholder="Name"
                  ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="userForm.is_active"
                  label="Is active"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  placeholder="Password"
                  :error="errors.has('add.password')"
                  :error-messages="errors.first('add.password')"
                  prepend-icon="mdi-lock"
                  v-model="userForm.password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  name="password"
                  @click:append="showPass = !showPass"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dialog = false">Cancel</v-btn>
            <v-btn color="info" type="submit">Save</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>    
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import UsersService from '../../services/UsersService'

export default {
  name: 'Users',
  data: () => ({
    options: {
      search: '',
      page: 1,
      itemsPerPage: 15
    },
    totalItems: 0,
    search: '',
    dialog: false,
    showPass: false,
    userForm: {
      _id: null,
      email: '',
      name: '',
      password: '',
      is_active: true,
    },
    headers: [
      { text: 'Name', value: 'name',sortable: false },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    users: [],
    loading: false,
  }),
  methods: {
    async editUser (id) {
      try {
        const { data } = await UsersService.getFndUserById(id)
        this.userForm._id = data._id
        this.userForm.name = data.name
        this.userForm.email = data.email
        this.userForm.is_active = data.is_active

        this.dialog = true
      } catch (error) {
        console.error('Error retrieving user details:', error)
      }
    },
    clearForm () {
      this.userForm = {
        _id: null,
        email: '',
        name: '',
        password: '',
        is_active: true,
      }
    },
    onSaveUser () {
      this.$validator.validateAll('add').then((result) => {
        if (result) {
          const user = this.userForm
          if (user._id === null) {
            UsersService.addFndUser(user).then(() => {
              this.dialog = false
              this.getUsers()
            }).catch(err => {
              if (err.response && err.response.data.code === 11000 && err.response.data.keyValue.email) {
                this.errors.add({scope: 'add', field: 'email', msg: this.$t('EMAIL_UNIQUE_ERROR')})
              }
            })
          } else {
            UsersService.editFndUser(user).then(() => {
              this.dialog = false
              this.getUsers()
            }).catch(err => {
              if (err.response && err.response.data.code === 11000 && err.response.data.keyValue.email) {
                this.errors.add({scope: 'add', field: 'email', msg: this.$t('EMAIL_UNIQUE_ERROR')})
              }
            })
          }
        }
      })
    },

    async deleteUser (user) {
      let confVal = confirm(`Are you sure you want to delete user: ${user.name}?`)
      if (confVal) {
        UsersService.deleteFndUser(user._id).then(() => {
          this.getUsers()
        }).catch((err) => {
          console.log('err on delete', err.response)
        })
      }
    },
    async deleteUserPermanent (user) {
      let confVal = confirm(`Are you sure you want to delete user forever: ${user.name}?`)
      if (confVal) {
        UsersService.deletefndUserPermanent(user._id).then(() => {
          this.getUsers()
        }).catch((err) => {
          console.log('err on permanent delete', err.response)
        })
      }
    },
    getUsers: debounce(function() {
      this.loading = true
      UsersService.getFndUsers({
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        search: this.search
      })
        .then(res => {
          this.users = res.data.docs
          this.totalItems = res.data.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }, 400),
    searchUsers(search) {
      if(search){
        this.options.itemsPerPage = 100 
        this.options.page = 1
      }else{
        this.options.itemsPerPage = 15
      }
    },
    itemRowBackground(item) {
      return !item.is_active ? 'user-background-deleted' : ''
    }
  },
  watch: {
    search: function (newSearch) {
      this.searchUsers(newSearch)
    },
    dialog: function (newVal) {
      if (!newVal) this.clearForm()
      else this.$store.dispatch('roles/getRoles')
    },
  },
}
</script>

<style lang="scss">
  .users-wrapper {
    width: 100%;

    .v-speed-dial--bottom {
      bottom: 2px;
    }
  }
  .user-background-deleted {
    background-color: rgba(239, 154, 154, 0.6);
  }
</style>
