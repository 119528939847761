import Api from '@/services/Api'

export default {
  getTrailerMarks(pagination) {
    return Api().get('/trailers/marks', {
      params: {
        page: pagination.page,
        perPage: pagination.itemsPerPage,
        sortby: pagination.sortBy,
        order: pagination.descending ? 1 : -1,
      },
    })
  },
  addTrailerMark(trailerMark) {
    return Api().post('/trailers/marks', trailerMark)
  },
  editTrailerMark(trailerMark) {
    return Api().put('/trailers/marks', trailerMark)
  },
  deleteTrailerMark(id) {
    return Api().delete(`/trailers/marks/${id}`)
  },
  getAllTrailerMarks() {
    return Api().get('/trailers/marks/all')
  }
}
