import Api from '@/services/Api'

export default {
  getSuggestions (search) {
    return Api().get(`/here-api/suggest?search=${search}`)
  },
  lookup (id) {
    return Api().get(`/here-api/lookup?id=${id}`)
  }
}
