<template>
  <div class="OilChangeHistory">
    <v-list v-if="showOilChanges" class="OilChangeHistory__list">
      <template v-for="(item, index) of oilChangeHistories">
        <v-list-item :key="`oil-change-${item._id}`">
          <v-list-item-content class="OilChangeHistory__content">
            <div class="OilChangeHistory__stuff">
              <v-list-item-subtitle>
                <strong>Date:</strong>
                {{ new Date(item.date) | formatDate('DD/MM/YYYY') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>Millage:</strong> {{ item.millage }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>Oil Type:</strong> {{ item.oilType }}
              </v-list-item-subtitle>
            </div>
            <div class="OilChangeHistory__actions">
              <v-icon small color="primary" @click="handleEditOilChange(item)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                color="error"
                @click="handleDeleteOilChangeHistory(item._id)"
                >mdi-delete</v-icon
              >
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="`divider-${index}`"
          v-if="index < oilChangeHistories.length - 1"
        ></v-divider>
      </template>
    </v-list>

    <p class="OilChangeHistory__no-history" v-if="noHistory">
      No oil change histories...
    </p>

    <v-progress-circular
      v-if="loading"
      indeterminate
      class="OilChangeHistory__loader"
      color="primary"
    ></v-progress-circular>

    <v-btn
      @click="handleAddNewOilChangeHistory"
      class="mt-1"
      block
      elevation="2"
      small
      color="success"
      >Add Trucks Oil Change History</v-btn
    >

    <v-dialog v-model="showDialog" width="400px" persistent>
      <v-card>
        <form @submit.prevent="handleSubmitForm" data-vv-scope="history">
          <v-card-title class="lighten-4 py-4 title">
            {{ this.form._id ? 'Edit' : 'Add' }} Trucks Oil Change History
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :return-value.sync="form.date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date"
                      label="Date inspected"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="datePicker = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.datePicker.save(form.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="oilType"
                  v-model="form.oilType"
                  v-validate="'required'"
                  :error="errors.has('oilChangeHistories.oilType')"
                  :error-messages="errors.first('oilChangeHistories.oilType')"
                  name="oilType"
                  id="oilType"
                  placeholder="Oil Type"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                    ref="millage"
                    v-model="form.millage"
                    v-validate="'required'"
                    :error="errors.has('oilChangeHistories.millage')"
                    :error-messages="errors.first('oilChangeHistories.millage')"
                    name="millage"
                    id="millage"
                    placeholder="Millage"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="handleCloseDialog"
              >Cancel</v-btn
            >
            <v-btn
              color="info"
              :loading="loading"
              :disabled="!canEdit || loading"
              type="submit"
              >{{ this.form._id ? 'Edit' : 'Add' }}</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import service from '../../services/OilChangeHistory'

export default {
  name: 'OilChangeHistory',

  props: ['truck'],

  data() {
    return {
      loading: false,
      showDialog: false,
      oilChangeHistories: [],

      datePicker: false,

      form: {
        _id: null,
        millage: '',
        oilType: '',
        date: this.formatDate(),
        truckId: this.truck._id,
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['permissions']),
    showOilChanges() {
      if (this.loading) return false
      return (
        Array.isArray(this.oilChangeHistories) &&
        this.oilChangeHistories.length > 0
      )
    },
    noHistory() {
      if (this.loading) return false
      if (!this.oilChangeHistories) return true
      return (
        Array.isArray(this.oilChangeHistories) &&
        this.oilChangeHistories.length === 0
      )
    },
    canEdit() {
      return this.permissions.TRUCK_EDIT
    },
  },

  mounted() {
    this.loading = true
    service
      .getOilChangeHistoriesByTruckId(this.truck.number, this.truck._id)
      .then((res) => {
        if (res) this.oilChangeHistories = res.data.oilChangeHistories
      })
      .catch(console.warn)
      .finally(() => (this.loading = false))
  },

  methods: {
    handleDeleteOilChangeHistory(id) {
      if (!this.permissions.TRUCK_EDIT) return
      this.loading = true
      service
        .deleteOilChangeHistory(id)
        .then((res) => {
          if (res) {
            this.oilChangeHistories = this.oilChangeHistories.filter(
              (i) => i._id !== id
            )
          }
        })
        .catch(console.warn)
        .finally(() => (this.loading = false))
    },

    handleEditOilChange({ date, ...rest }) {
      if (!this.permissions.TRUCK_EDIT) return
      this.showDialog = true
      this.form = {
        ...rest,
        date: date ? this.formatDate(date) : null,
      }
    },

    handleAddNewOilChangeHistory() {
      this.showDialog = true
    },

    handleCloseDialog() {
      this.showDialog = false
      this.resetForm()
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    resetForm() {
      this.form = {
        _id: null,
        millage: '',
        oilType: '',
        date: this.formatDate(),
        truckId: this.truck._id,
      }
    },

    handleSubmitForm() {
      if (!this.permissions.TRUCK_EDIT) return
      this.loading = true
      if (this.form._id) {
        service
          .editOilChangeHistory(this.form)
          .then((res) => {
            if (res) {
              const { oilChangeHistory } = res.data
              const temp = [...this.oilChangeHistories]
              const i = temp.findIndex((h) => h._id === oilChangeHistory._id)
              temp[i] = oilChangeHistory
              this.oilChangeHistories = temp
              this.showDialog = false
              this.resetForm()
            }
          })
          .catch(console.warn)
          .finally(() => (this.loading = false))
      } else {
        service
          .addOilChangeHistory(this.form)
          .then((res) => {
            const { oilChangeHistory } = res.data
            this.oilChangeHistories.push(oilChangeHistory)
            this.showDialog = false
            this.resetForm()
          })
          .catch(console.warn)
          .finally(() => (this.loading = false))
      }
    },
  },
}
</script>

<style lang="scss">
.OilChangeHistory {
  min-width: 400px;
  min-height: 200px;

  &__list {
    max-height: 200px;
    overflow-y: scroll;
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__stuff {
    flex: 1;
  }

  &__actions {
    flex: 0;
    min-width: max-content;
  }

  &__no-history {
    padding-top: 20px;
    text-align: center;
  }

  &__loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
