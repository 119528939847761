<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-alert
                :value="message !== ''"
                :type="messageType"
                transition="scale-transition"
            >
              {{message}}
            </v-alert>
            <v-card class="elevation-12">
              <v-form @submit.prevent="verifyOtp">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Check your Email and Enter OTP token</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-text-field :loading="loading" placeholder="OTP token" :autofocus="true" v-model="otp" prepend-icon="mdi-account" name="otp" label="Otp" type="text"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="primary" type="button" @click="resendToken()">Resend OTP token Email</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">Verify</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthService from '@/services/AuthService'
export default {
  data: () => ({
    message: '',
    messageType: 'warning',
    otp: null
  }),
  methods: {
    async verifyOtp () {
      const visitorData = await this.$fpjs.getVisitorData({
        extendedResult: true
      })
      this.$store.dispatch('auth/verifyOtp', {otp: this.otp, email: this.email, visitorData})
        .then((response) => {
          if(response.message === 'AVAILABLE_FOR_LOGIN'){
            this.$router.push({name: 'login', query: {fromOtp: true}})
          } else {
            this.message = 'Wrong Otp token'
            this.messageType = 'warning'
          }
        })
        .catch((err) => {
          if (err && err.data.message === 'WRONG_OTP') {
            this.message = 'Wrong Otp token'
            this.messageType = 'warning'
          }
        })
    },
    async resendToken() {
      const visitorData = await this.$fpjs.getVisitorData({
        extendedResult: true
      })
      AuthService.resendToken({email: this.email, visitorData}).then(res => {
        if (res.data) {
          this.message = 'Token Sent Again'
          this.messageType = 'success'
        }
      }).catch(err => {
        console.log('error in sending token', err)
      })
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    email() {
      return this.$route.params.email
    }
  }
}
</script>
