import board from '../../services/BoardsService'

const state = {
  boards: []
}

const getters = {
  boards (state) {
    return state.boards
  }
}

const actions = {
  getBoards ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return board.getBoards(payload).then(res => {
      commit('setBoards', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllBoards ({commit, dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return board.getAllBoards().then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('setBoards', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addBoard ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return board.addBoard(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addBoard', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editBoard ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return board.editBoard(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editBoard', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteBoard ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return board.deleteBoard(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteBoard', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setBoards (state, boards) {
    state.boards = boards
  },
  addBoard (state, board) {
    const tmpBoards = state.boards.slice()
    tmpBoards.push(board)
    state.boards = tmpBoards
  },
  editBoard (state, board) {
    let tmpBoards = state.boards.slice()
    let index = tmpBoards.findIndex(el => el._id === board._id)
    tmpBoards.splice(index, 1, board)
    state.boards = tmpBoards
  },
  deleteBoard (state, id) {
    const tmpBoards = state.boards.slice()
    tmpBoards.splice(tmpBoards.findIndex(el => el._id === id), 1)
    state.boards = tmpBoards
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
