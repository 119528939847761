<template>
  <div class="drivers-wrapper">
    <v-card>
      <v-card-title>
        {{ options.deleted ? 'Inactive' : '' }} {{ $t('other.drivers') }}
        <v-btn class="ml-2" color="success" dark small :loading="loading" @click="exportInactiveDrivers">
          Export <v-icon small>mdi-file-excel-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-select
          class="mr-3"
          :items="searchtypes"
          v-model="searchBy"
          item-text="name"
          item-value="value"
          label="Drivers with no truck or trailer"
          height="30"
          dense
          hide-details
          clearable
          @change="getDriversSearchBy"
        ></v-select>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('other.search')"
          placeholder="Search by name, truck or trailer ..."
          single-line
          hide-details
          height="30"
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        height="750"
        :disable-sort="true"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalItems"
        :items="drivers"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 15, 25],
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.formatedName ? item.formatedName : item.name }}
        </template>
        <template v-slot:item.is_active="{ item }">
          {{ item.is_active ? 'YES' : 'NO' }}
        </template>
        <template v-slot:item.has_app_acc="{ item }">
          {{ item.has_app_acc ? 'YES' : 'NO' }}
        </template>
        <template v-slot:item.board="{ item }">
          <td>
            {{ item.board && item.board._id ? boardsMap[item.board._id] : '/' }}
          </td>
        </template>
        <template v-slot:item.trailer="{ item }">
          <td>
            <router-link class="table-link" :to="`/trailers?search=${item.trailer}`">{{item.trailer}}</router-link>
          </td>
        </template>
        <template v-slot:item.truck="{ item }">
          <td>
            <router-link class="table-link" :to="`/trucks/all?search=${item.truck}`">{{item.truck}}</router-link>
          </td>
        </template>
        <template v-slot:item.history="{ item }">
          <employment-histories v-if="item" :driver="item" />
        </template>
        <template v-slot:item.actions="{ item }">
          <td class="justify-center layout px-0">
            <v-icon :disabled="!permissions.DRIVERS_EDIT" small class="mr-2" @click="editDriver(item)">
              mdi-pencil
            </v-icon>
            <v-icon :disabled="!permissions.DRIVERS_DELETE" small @click="deleteDriver(item)">
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
          v-if="permissions.DRIVERS_ADD"
          color="pink"
          dark
          absolute
          top
          right
          fab
          @click="formDialogSet(true)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialogModal" width="800px">
      <v-card>
        <Form
          :key="driver_id"
          @onClose="formDialogModal = false"
          :driver_id="driver_id"
        ></Form>
      </v-card>
    </v-dialog>
    <v-overlay :value="deleteWarnModal">
      <div class="d-block pa-2 primary accent-4 white--text">
        You need to migrate trucks to another driver before delete
      </div>
        <v-list width="800px">
          <v-list-item-group
              multiple
          >
            <template v-for="(item, index) in trucksForMigration">
              <v-list-item :key="item.number">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.number"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-divider
                  v-if="index < trucksForMigration.length - 1"
                  :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      <v-btn
          @click="deleteWarnModal = false"
          block
          color="primary"
          elevation="2"
          x-large
      >CLOSE</v-btn>

    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Form from './Form'
import debounce from 'lodash/debounce'
import { b64toBlob, downloadFile } from '../../utils'

import EmploymentHistories from './EmploymentHistories.vue'
import DriverService from '../../services/DriverService'

export default {
  name: 'Drivers',
  components: {
    Form,
    EmploymentHistories,
  },
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 15,
        search: '',
        deleted: 0,
      },
      deleteWarnModal: false,
      trucksForMigration: [],
      search: '',
      driver_id: null,
      totalItems: 0,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'User', value: 'user.name' },
        { text: 'Board', value: 'board' },
        { text: 'Trailer Type', value: 'type_trailer' },
        { text: 'Dispatcher Ext', value: 'dispatcher_ext' },
        { text: 'Dispatcher Ext second', value: 'dispatcher_ext_second' },
        { text: 'Company', value: 'company' },
        { text: 'Truck', value: 'truck' },
        { text: 'Trailer', value: 'trailer' },
        { text: 'Phone', value: 'phone' },
        { text: 'Lb', value: 'lb' },
        { text: 'Safety', value: 'safety' },
        { text: 'Has app', value: 'has_app_acc' },
        { text: 'Is active', value: 'is_active' },
        { text: 'Employment History', value: 'history', align: 'center' },
        { text: 'Actions', value: 'actions', align: 'center' },
      ],
      boards: [],
      searchtypes: [
        {
          name: 'Drivers with no truck',
          value: 'no_truck',
        },
        {
          name: 'Drivers with no trailer',
          value: 'no_trailer',
        },
      ],
      searchBy: null,
      // formDialog: false
    }
  },
  watch: {
    options: {
      handler() {
        this.$store.dispatch('drivers/getDrivers', this.options).then((res) => {
          this.totalItems = res.total
        })
      },
      deep: true,
    },
    search: function(newSearch) {
      this.searchDrivers(newSearch)
    },
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('auth', ['permissions']),
    ...mapGetters('drivers', ['drivers', 'formDialog']),
    formDialogModal: {
      get() {
        return this.formDialog
      },
      set(value) {
        if (!value) {
          this.driver_id = null
        }
        this.$store.dispatch('drivers/setFormDialog', value)
      },
    },
    boardsMap() {
      let boradsMap = {}
      for (let board of this.boards) {
        boradsMap[board._id] = board.name
      }
      return boradsMap
    },
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),
    formDialogSet(param) {
      this.$store.dispatch('drivers/setFormDialog', param)
    },
    editDriver(driver) {
      if (!this.permissions.DRIVERS_EDIT) return
      this.driver_id = driver._id
      this.formDialogModal = true
    },
    deleteDriver(driver) {
      if (!this.permissions.DRIVERS_DELETE) return
      let confVal = confirm(
        `Are you sure you want to delete driver: ${driver.name}?`
      )
      if (confVal) {
        this.$store
          .dispatch('drivers/deleteDriver', driver._id)
          .then(() => {
            this.setMessage(`${driver.name} successfully deleted`)
          })
          .catch((err) => {
            if(err.status === 409) {
              this.deleteWarnModal = true
              this.trucksForMigration = err.data
            }
            console.log('err on delete', err)
          })
      }
    },
    searchDrivers: debounce(function(search) {
      this.options.search = search
      this.$store.dispatch('drivers/getDrivers', this.options).then((res) => {
        this.totalItems = res.total
      })
    }, 300),
    getBoards() {
      this.$store.dispatch('boards/getAllBoards').then((res) => {
        this.boards = res
      })
    },
    getDriversSearchBy() {
      this.$store
        .dispatch('drivers/getDrivers', {
          ...this.options,
          searchBy: this.searchBy,
        })
        .then((res) => {
          this.totalItems = res.total
        })
    },
    exportInactiveDrivers() {
      this.$store.dispatch('common/setLoading', true)
      DriverService.exportDrivers(this.options).then(file => {
        this.$store.dispatch('common/setLoading', false)
        const blob = b64toBlob(file.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8')
        downloadFile(blob, 'inactive_drivers_export')
      })
    }
  },
  mounted() {
    this.getBoards()
    if (this.$route.name === 'driversDeleted') {
      this.options.deleted = 1
    }
  },
}
</script>

<style lang="scss">
.drivers-wrapper {
  width: 100%;
  margin-top: 20px;
}

.table-link {
  text-decoration: none;
  cursor: pointer;
}
// @media only screen and (max-width: 599px) {
//   .drivers-wrapper {
//     margin-top: 65px;
//   }
// }
</style>
