<template>
  <div class='map-wrapper'>
    <div id='mapbox-container'></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

let map = null

export default {
  props: {
    shop: {
      type: Object,
      required: true,
    },
    shops: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      default: '100vh',
    },
  },
  data() {
    return {
      map: null,
    }
  },
  computed: {
    geoJsonData() {
      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [this.shop.longitude, this.shop.latitude],
            },
            properties: {
              id: this.shop.id,
              name: this.shop.name,
              type: this.shop.type,
              address: this.shop.address.text,
              franchise: this.shop.franchise?.name || '',
              franchiseId: this.shop.franchise?.id || null,
              status: this.shop.details[0]?.status || null
            },
          },
        ],
      }
    },
    geoJsonAdditionalShops() {
      const mainShopId = this.shop.id
      const additionalShopsFiltered = this.shops.filter(
        (obj) => obj.id !== mainShopId
      )
      return {
        type: 'FeatureCollection',
        features: additionalShopsFiltered.map((obj) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [obj.longitude, obj.latitude],
          },
          properties: {
            id: obj.id,
            name: obj.name,
            type: obj.type,
            averageRating: obj.averageRating,
            label: obj.label,
            address: obj.address.text,
            franchise: obj.franchise?.name || '',
            franchiseId: obj.franchise?.id || null,
          },
        })),
      }
    },
  },
  watch: {
    shop: {
      handler(newValue) {
        if (map && newValue) {
          this.resizeMap()
        }
      },
      deep: true,
    },
    shops: {
      async handler(newValue) {
        if (map && newValue.length) {
          await this.addAdditionalShopsSource()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initMap()

    window.addEventListener('resize', () => {
      this.resizeMap()
    })
  },
  methods: {
    initMap() {
      mapboxgl.accessToken =
        'pk.eyJ1IjoibmVtYW5qYTk0NyIsImEiOiJjbHVwamxhb2wyM29sMnFsaTEzdW5ka3U1In0.kT_pXqkD-9ADRSK7mpX78Q'

      map = new mapboxgl.Map({
        container: 'mapbox-container', // container ID
        style:'mapbox://styles/mapbox/streets-v12',
        center: [this.shop.longitude, this.shop.latitude],
        minZoom: 12,
      })

      map.on('style.load', async () => {
        map.easeTo(
          {
            padding: { top: 30, right: 30, bottom: 60, left: 30 },
            duration: 500,
          },
          { isProgramatic: true }
        )

        map.addSource('shops', {
          type: 'geojson',
          data: this.geoJsonData,
          cluster: false,
        })

        this.addMapLayers()
      })
    },
    addMapLayers() {

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'shops',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#0C4130',
          'circle-radius': 10,
          'circle-stroke-width': 1.5,
          'circle-stroke-color': '#F7AB41',
        },
      })
    },
    createPopup() {

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'map-popup',
        offset: 12,
      })

      map.on('mouseenter', 'shop-additional', (e) => {
        map.getCanvas().style.cursor = 'pointer'

        let coordinates = e.features[0].geometry.coordinates.slice()
        const properties = e.features[0].properties
        // Ensure that if the pin is over the map boundary, it's shown at the correct place
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        // Set popup HTML content and display it at the appropriate position
        popup
          .setLngLat(coordinates)
          .setHTML(this.generatePopUpHtml(properties))
          .setMaxWidth('300px')
          .addTo(map)
      })

      map.on('mouseleave', 'shop-additional', () => {
        map.getCanvas().style.cursor = ''
        popup.remove()
      })
    },
    generatePopUpHtml(properties) {
      const popUpHtml = `
        <div class='d-flex align-center justify-space-between'>
            ${this.generatePopUpRating(properties.averageRating)}
            ${this.generatePopUpLabel(properties)}
        </div>
        <h3 class='map-popup pt-2 text-h6 font-weight-bold text-primary text-truncate'>${properties.name}<h3>
        <h2 class='map-popup pb-4 text-subtitle-1 font-weight-bold text-truncate'>${properties.franchise}<h2>
        <h2 class='map-popup text-subtitle-2 font-weight-bold'>${properties.address}<h2>
      `
      return popUpHtml
    },
    generatePopUpRating(averageRating){
      if(averageRating==null){
        averageRating = 0
      }
      let starsHtml = '<div class="d-flex align-center">'
      const fullStars = Math.floor(parseFloat(averageRating))
      const hasHalfStar = parseFloat(averageRating) % 1 !== 0

      for (let i = 0; i < fullStars; i++) {
        starsHtml += '<span class="mdi mdi-star text-h6 amber--text"></span>'
      }

      if (hasHalfStar) {
        starsHtml += '<span class="mdi mdi-star-half-full text-h6 amber--text"></span>'
      }

      const emptyStars = 5 - Math.ceil(parseFloat(averageRating))
      for (let i = 0; i < emptyStars; i++) {
        starsHtml += '<span class="mdi mdi-star-outline text-h6 amber--text"></span>'
      }
      starsHtml += `<span class="ml-1 text-subtitle-1 font-weight-bold">${averageRating}</span></div>`
      return starsHtml
    },
    generatePopUpLabel(props){
      if(props.status!=null){
        return `<p class="text-subtitle-2 font-weight-bold px-4 py-1 ${props.status.toLowerCase()} d-inline-block rounded-pill white--text">${props.status}</p>`
      }
      else if(props.label!=null && props.label!=''){
        return `<p class="text-subtitle-2 font-weight-bold px-4 py-1 ${props.label.toLowerCase()} d-inline-block rounded-pill white--text">${props.label}</p>`
      }
      else{
        return '<p class="text-subtitle-2 font-weight-bold px-4 py-1 basic d-inline-block rounded-pill white--text">BASIC</p>'
      }
    },
    clickEventsPin() {
      map.on('click', 'shop-additional', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['shop-additional'],
        })

        if (!features.length) {
          return
        }

        const clickedFeature = features[0]

        this.$router.push({
          name: 'ShopDetails',
          params: {
            shopId: clickedFeature.properties.id,
          },
        })
      })
    },
    async addAdditionalShopsSource() {

      map.addSource('shops-additional', {
        type: 'geojson',
        data: this.geoJsonAdditionalShops,
        cluster: false,
      })

      map.addLayer({
        id: 'shop-additional',
        type: 'circle',
        source: 'shops-additional',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'case',
            ['==', ['get', 'label'], 'GOLD'], '#B99042', 
            ['==', ['get', 'label'], 'PLATINUM'], '#B4C5E4', 
            ['==', ['get', 'label'], 'SILVER'], '#717B8E',
            ['==', ['get', 'status'], 'BLACKLISTED'], '#8A1829',
            '#F7AB41'
          ],
          'circle-radius': 10,
          'circle-stroke-width': 1.5,
          'circle-stroke-color': '#0C4130',
        },
      })

      this.createPopup()
      this.clickEventsPin()
    },
    resizeMap() {
      map.resize()
    },
  },
}
</script>

<style scoped lang='scss'>
.map-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  #mapbox-container {
    flex: 1;
    height: 100%
  }
}

.map-popup {
  padding-right: 8px;
  z-index: 1000
}
</style>
