<template>
  <div
    class="AvailableTrailers"
    :class="{ 'AvailableTrailers__lock-first': !isHistory }"
    :key="isHistory"
  >
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :options="options"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      @update:options="handleOptionsChange"
      height="calc(100vh - 173px)"
      fixed-header
      disable-sort
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn
            v-if="!isHistory"
            color="primary"
            dark
            small
            @click="editItem = 'new'"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
          <v-spacer />

          <table-filters
            v-if="!isHistory"
            class="mr-2"
            :initial-values="{ ...FILTERS_INITIAL }"
            :initial-form="filtersForm"
            @filters-changed="handleFiltersChanged"
          />
          <table-filters
            v-else
            class="mr-2"
            :initial-values="{ ...FILTERS_INITIAL }"
            :initial-form="filtersFormHistory"
            @filters-changed="handleFiltersChanged"
          />

          <table-order
            v-if="!isHistory"
            :userId="user && user._id"
            :default-order="defaultHeaders"
            @order-changed="handleTableOrderChanged"
            table-name="AVAILABLE_TRAILERS"
          />
        </v-toolbar>
      </template>

      <template v-slot:item.changed="{ item }">
        <td style="text-align: center">
          {{ item.changed | formatDate }}
        </td>
      </template>

      <template v-slot:item.temp_plate_exp_date="{ item }">
        <td
          style="text-align: center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="TEMP_PLATE_EXP_DATE"
            field-type="date"
          >
            {{ item.temp_plate_exp_date | formatDate }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.driver_pu_date="{ item }">
        <td
          style="text-align: center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="DRIVER_PU_DATE"
            field-type="date"
          >
            {{ item.driver_pu_date | formatDate }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.pu_date="{ item }">
        <td
          style="text-align: center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="PU_DATE"
            field-type="date"
          >
            {{ item.pu_date | formatDate }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.hold_date="{ item }">
        <td
          style="text-align: center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="HOLD_DATE"
            field-type="date"
          >
            {{ item.hold_date | formatDate }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.company="{ item }">
        <td
          style="text-align: center"
          :class="{
            AvailableTrailers__towing: item.towing,
          }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="COMPANY_CHANGED"
            allow-change
          >
            {{ item.company }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.driver_name="{ item }">
        <td :class="{ AvailableTrailers__towing: item.towing }">
          <span
            class="AvailableTrailers__elipsis"
            :class="{
              'AvailableTrailers__red-letters':
                item.driver_state === 'temporary',
            }"
          >
            <field-with-history
              :hidden="isHistory"
              :id="item._id"
              type="DRIVER_NAME"
            >
              {{ item.driver_name }}
            </field-with-history>
          </span>
        </td>
      </template>

      <template v-slot:item.dealership="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          {{ (item.dealership && item.dealership.name) || '/' }}
        </td>
      </template>

      <template v-slot:item.owner_new="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history :hidden="isHistory" :id="item._id" type="OWNER">
            <span class="AvailableTrailers__elipsis">
              {{ item.owner_new }}
            </span>
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.third_party="{ item }">
        <td
          class="text-center"
          :class="{
            AvailableTrailers__towing: item.towing,
            'AvailableTrailers__red-letters': item.temporary_third_party,
          }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="THIRD_PARY"
            field-value="name"
          >
            <span v-if="item.temporary_third_party">{{
              item.third_party || '/'
            }}</span>
            <span v-else>{{
              (item.third_party && item.third_party.name) || '/'
            }}</span>
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.make="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          {{ (item.make && item.make.name) || '/' }}
        </td>
      </template>

      <template v-slot:item.status="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history :hidden="isHistory" :id="item._id" type="STATUS">
            {{ item.status || 'NO STATUS' }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.brockerage="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="BROCKERAGE"
          >
            {{ item.brockerage }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.gps_app="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="BROCKERAGE"
          >
            {{ item.gps_app }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.towing="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="TOWING"
            field-type="boolean"
          >
            {{ item.towing ? 'YES' : 'NO' }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.availability="{ item }">
        <td
          class="text-center"
          :class="{
            AvailableTrailers__available: item.availability === 'available',
          }"
        >
          {{ item.availability }}
        </td>
      </template>

      <template v-slot:item.units_together="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="UNITS_TOGETHER"
            field-type="boolean"
          >
            {{ item.units_together ? 'YES' : 'NO' }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.is_damaged="{ item }">
        <td
          class="text-center"
          :class="{
            AvailableTrailers__broken: item.is_damaged,
          }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="IS_DAMAGED"
            field-type="boolean"
          >
            {{ item.is_damaged ? 'YES' : 'NO' }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.towing_per_day="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="TOWING_PER_DAY"
          >
            {{
              item.towing && item.towing_per_day
                ? `$${item.towing_per_day}`
                : '/'
            }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.is_total_damaged="{ item }">
        <td v-if="item.is_damaged" class="text-center">
          <span v-if="item.is_damaged">
            {{ item.is_total_damaged ? 'UNDRIVABLE' : 'DRIVABLE' }}
          </span>
          <span v-else>/</span>
        </td>
      </template>

      <template v-slot:item.gps="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="GPS"
            field-type="boolean"
          >
            {{ item.gps ? 'YES' : 'NO' }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.has_partner_sticker="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="HAS_PARTNER_STICKER"
            field-type="boolean"
          >
            {{ item.has_partner_sticker ? 'YES' : 'NO' }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.type="{ item }">
        <td
          class="text-center"
          :class="{
            'AvailableTrailers__active-type': item.active_type,
            'AvailableTrailers__cursor-default': loading,
          }"
          @click="handleToggleType(item._id)"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.type }}
              </span>
            </template>
            <span>Click to toggle.</span>
          </v-tooltip>
        </td>
      </template>

      <template v-slot:item.loaded="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__loaded: item.loaded }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="LOADED"
            field-type="boolean"
          >
            {{ item.loaded ? 'YES' : 'NO' }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.number="{ item }">
        <td
          class="AvailableTrailers__bold-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <span v-if="isHistory">{{ item.number }}</span>
          <trailer-number v-else :trailer="item" hide-note />
        </td>
      </template>

      <template v-slot:item.option="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history :hidden="isHistory" :id="item._id" type="OPTION">
            {{ item.option }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.production_year="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          {{ item.production_year }}
        </td>
      </template>

      <template v-slot:item.vin_number="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          {{ item.vin_number }}
        </td>
      </template>

      <template v-slot:item.plate="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history :hidden="isHistory" :id="item._id" type="PLATE">
            {{ item.plate }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.hold="{ item }">
        <td
          class="text-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="HOLD"
            field-value="name"
          >
            {{ item.hold && item.hold.name }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.truck="{ item }">
        <td
          class="AvailableTrailers__bold-center"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="TRUCK"
            field-value="truck_id"
          >
            {{ (item.truck && item.truck.truck_id) || '/' }}
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.yard="{ item }">
        <td :class="{ AvailableTrailers__towing: item.towing }">
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="YARD"
            field-value="name"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="AvailableTrailers__elipsis"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.yard && item.yard.name }}
                </span>
              </template>
              <span>{{ item.yard && item.yard.name }}</span>
            </v-tooltip>
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.note_new="{ item }">
        <trailer-comments
          :comments="item.note_new || []"
          :id="item._id"
          type="note_new"
          text="Notes"
        />
      </template>

      <template v-slot:item.info="{ item }">
        <trailer-comments
          :comments="item.info || []"
          :id="item._id"
          type="info"
          text="Info's"
        />
      </template>

      <template v-slot:item.drivers_phone="{ item }">
        <td
          class="AvailableTrailers__menu-dots"
          :class="{ AvailableTrailers__towing: item.towing }"
        >
          <field-with-history
            :hidden="isHistory"
            :id="item._id"
            type="DRIVERS_PHONE"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item.drivers_phone }}
                </span>
              </template>
              <span>{{ item.drivers_phone }}</span>
            </v-tooltip>
          </field-with-history>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon v-if="!isHistory" small @click="handleEditItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>

    <trailer-form
      :edit-id="editId"
      :edit-item="editItem"
      @save="fetchItems"
      @close="handleClose"
      is-available-form
    />

    <div class="AvailableTrailers__bottom-left">
      <v-btn
        color="secondary"
        class="mr-1"
        dark
        small
        to="/available-trailers/stats"
        >Stats</v-btn
      >
      <v-btn
        color="pink"
        class="mr-1"
        v-if="!isHistory"
        dark
        small
        :loading="loading"
        @click="removeActiveDialog = true"
        >Remove active types</v-btn
      >
      <v-btn
        color="success"
        class="mr-1"
        :loading="loading"
        v-if="!isHistory"
        dark
        small
        @click="handleExportToExcell"
      >
        Export <v-icon small>mdi-file-excel-outline</v-icon>
      </v-btn>
      <v-btn color="primary" dark text small to="/trailers">All Trailers</v-btn>
    </div>

    <v-dialog
      v-model="removeActiveDialog"
      max-width="500px"
      @keydown.esc="removeActiveDialog = false"
      @click:outside="removeActiveDialog = false"
    >
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to remove all active types?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="removeActiveDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="handleRemoveActiveTypes"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import service from '../../services/TrailersService'
import contractService from '../../services/ContractService'
import throttle from 'lodash/throttle'
import TableFilters from '../TableFilters.vue'
import TrailerNumber from './TrailerNumber.vue'
import FieldWithHistory from './FieldWithHistory.vue'
import TableOrder from '../TableOrder.vue'
import TrailerForm from './TrailerForm.vue'
import { b64toBlob, downloadFile } from '../../utils'
import { commentsMixin } from './CommentsMixin'
import TrailerComments from './TrailerComments.vue'

const HEADERS = [
  { text: 'Trailer #', value: 'number', align: 'center', width: 100 },
  { text: 'Driver', value: 'driver_name', align: 'center', width: 250 },
  { text: 'Third party', value: 'third_party', align: 'center', width: 250 },
  { text: 'Rent/Buy', value: 'option', align: 'center' },
  { text: 'Truck #', value: 'truck', align: 'center', width: 80 },
  { text: 'Phone #', value: 'drivers_phone', align: 'center', width: 200 },
  { text: 'Division', value: 'company', align: 'center', width: 100 },
  {
    text: 'Driver Pickup Date',
    value: 'driver_pu_date',
    align: 'center',
    width: 150,
  },
  { text: 'Owner', value: 'owner_new', align: 'center', width: 120 },
  { text: 'Type', value: 'type', align: 'center', width: 80 },
  { text: 'Make', value: 'make', align: 'center', width: 120 },
  { text: 'Year', value: 'production_year', align: 'center', width: 70 },
  { text: 'Vin #', value: 'vin_number', align: 'center', width: 200 },
  { text: 'Plate', value: 'plate', align: 'center', width: 100 },
  {
    text: 'Plate EXP Date',
    value: 'temp_plate_exp_date',
    align: 'center',
    width: 110,
  },
  { text: 'GPS', value: 'gps', align: 'center', width: 60 },
  { text: 'GPS App', value: 'gps_app', align: 'center', width: 80 },
  { text: 'Sajne', value: 'has_partner_sticker', align: 'center', width: 60 },
  { text: 'Location GPS', value: 'location_gps', width: 100 },
  { text: 'Dealership', value: 'dealership', align: 'center', width: 120 },
  { text: 'Location', value: 'yard', align: 'center', width: 120 },
  {
    text: 'Pickup Date',
    value: 'pu_date',
    align: 'center',
    width: 110,
  },
  { text: 'Loaded', value: 'loaded', align: 'center', width: 60 },
  { text: 'Broken', value: 'is_damaged', align: 'center', width: 60 },
  {
    text: 'Damage Status',
    value: 'is_total_damaged',
    align: 'center',
    width: 120,
  },
  { text: 'Availability', value: 'availability', align: 'center', width: 120 },
  { text: 'Status', value: 'status', align: 'center', width: 120 },
  { text: 'Brockerage', value: 'brockerage', align: 'center', width: 120 },
  { text: 'Hold', value: 'hold', align: 'center', width: 120 },
  { text: 'Hold Date', value: 'hold_date', align: 'center', width: 110 },
  { text: 'Info', value: 'info', align: 'center', width: 200 },
  { text: 'Note', value: 'note_new', align: 'center', width: 200 },
  {
    text: 'Units Together',
    value: 'units_together',
    align: 'center',
    width: 120,
  },
  { text: 'Towing', value: 'towing', align: 'center', width: 80 },
  {
    text: 'Towing Per Day',
    value: 'towing_per_day',
    align: 'center',
    width: 120,
  },
  { text: 'Actions', value: 'actions', align: 'center', width: 60 },
]

const FILTERS_FULL = {
  search: { type: 'text', label: 'Trailer/VIN #', min: 1 },
  driver_name: { type: 'text', label: 'Driver', min: 2 },
  company: { type: 'select', label: 'Company', options: [] },
  companyFrom: {
    type: 'date-time',
    label: 'From',
    conditionField: 'company',
  },
  companyTo: {
    type: 'date-time',
    label: 'To',
    conditionField: 'company',
  },
  filter: {
    type: 'select',
    itemValue: 'value',
    itemText: 'label',
    options: [
      { label: 'All', value: null },
      { label: 'Damaged', value: 'is_damaged' },
      { label: 'Towing', value: 'towing' },
    ],
  },
  driverStatus: {
    type: 'select',
    label: 'Driver Status',
    itemValue: 'value',
    itemText: 'label',
    options: [
      { label: 'Temporary', value: 'temporary' },
      { label: 'Repower', value: 'repower' },
      { label: 'Recovery', value: 'recovery' },
    ],
  },
  status: {
    type: 'select',
    label: 'Status',
    options: [
      'NO STATUS',
      'STOLEN',
      'REPOSSESSION',
      'REPOWER',
      'NEW',
      'ON THE ROAD',
      'SOLO',
      'BROCKERAGE',
      'PARKED',
      'MISSING',
      'RECOVERY',
      'CLAIMS',
    ],
  },
  yard: {
    type: 'select',
    label: 'Yard',
    itemValue: '_id',
    itemText: 'name',
    options: [],
  },
  makes: {
    type: 'select',
    label: 'Makes',
    multiple: true,
    itemValue: 'name',
    itemText: 'name',
    options: [],
  },
  types: {
    type: 'select',
    label: 'Types',
    multiple: true,
    itemValue: 'name',
    itemText: 'name',
    options: [],
  },
}

const FILTERS_INITIAL = {
  makes: null,
  type: null,
  vin: null,
  yard: null,
  company: null,
  companyFrom: null,
  companyTo: null,
  driverStatus: null,
  filter: null,
  driver_name: null,
}

export default {
  components: {
    TableFilters,
    TrailerNumber,
    FieldWithHistory,
    TableOrder,
    TrailerForm,
    TrailerComments,
  },

  mixins: [commentsMixin],

  data() {
    return {
      FILTERS_INITIAL,
      filtersForm: { ...FILTERS_FULL },
      filtersFormHistory: {
        search: { type: 'text', label: 'Trailer/VIN #', min: 1 },
      },

      filters: { ...FILTERS_INITIAL },
      options: {
        page: 1,
        itemsPerPage: 30,
      },

      headers: this.$route.query.isHistory
        ? [
          {
            text: 'Change Date',
            value: 'changed',
            align: 'center',
            width: 110,
          },
          ...HEADERS.filter((h) => !['note_new', 'info'].includes(h.value)),
        ]
        : HEADERS,
      defaultHeaders: HEADERS,
      loading: false,
      items: [],
      totalItems: 0,

      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },

      editId: null,
      editItem: null,
      removeActiveDialog: false,

      isHistory: Boolean(this.$route.query.isHistory),
    }
  },

  async created() {
    this.setCompanies()
    this.getYards()
    this.setupFilters()
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  watch: {
    $route() {
      this.isHistory = Boolean(this.$route.query.isHistory)
      this.items = []
      this.changeHeaders()
      this.fetchItems()
      if (!this.isHistory) this.setupFilters()
    },
  },

  methods: {
    async setupFilters() {
      try {
        const { data } = await contractService.getUnitFilterOptions({
          forAT: true,
        })
        this.filtersForm.makes.options = [...data.trailerMakes]
        this.filtersForm.types.options = [...data.trailerTypes]
      } catch (error) {
        console.error('setupFilters()', error)
      }
    },

    changeHeaders() {
      this.headers = this.$route.query.isHistory
        ? [
          {
            text: 'Change Date',
            value: 'changed',
            align: 'center',
            width: 110,
          },
          ...HEADERS.filter((h) => !['note_new', 'info'].includes(h.value)),
        ]
        : HEADERS
    },

    async handleExportToExcell() {
      try {
        this.loading = true
        const { data } = await service.downloadSheetAvailable(this.filters)
        const blob = b64toBlob(
          data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        )
        downloadFile(blob, 'available-trailers')
      } catch (error) {
        console.error('handleExportToExcell()', error)
      } finally {
        this.loading = false
      }
    },

    handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      this.fetchItems()
    },

    handleFiltersChanged: throttle(function (filters) {
      this.filters = { ...filters }
      this.options.page = 1
      this.items = []
      this.fetchItems()
    }, 200),

    async fetchItems() {
      this.loading = true
      try {
        const params = {
          ...this.options,
          ...this.filters,
        }
        const fn = this.isHistory
          ? service.getAvailableHistory
          : service.getAvailableTrailers
        const { data } = await fn(params)
        this.totalItems = data.total
        this.items = data.docs
      } catch (error) {
        console.error('fetchItems()', error)
      } finally {
        this.loading = false
      }
    },

    handleEditItem(item) {
      this.editId = item._id
      this.editItem = Object.assign(
        {},
        {
          ...item,
          hold_date: item.hold_date
            ? this.$options.filters.formatDatePicker(item.hold_date)
            : null,
        }
      )
    },

    handleClose() {
      this.editItem = null
      this.editId = null
    },

    handleToggleType: throttle(async function (id) {
      if (this.isHistory) return
      this.loading = true
      try {
        await service.toggleType(id)
        await this.fetchItems()
      } catch (error) {
        console.error('handleToggleType', error)
      } finally {
        this.loading = false
      }
    }, 200),

    handleTableOrderChanged(order) {
      const prepareObj = {}
      order.forEach((c) => {
        prepareObj[c.columnName] = {
          order: c.order,
          isShown: c.isShown,
        }
      })

      this.headers = HEADERS.filter((c) => prepareObj[c.value].isShown).sort(
        (a, b) => prepareObj[a.value].order - prepareObj[b.value].order
      )
    },

    async getYards() {
      this.loading = true
      try {
        const data = await this.$store.dispatch('yards/getAllYards', 'TRAILER')
        if (Array.isArray(data)) this.filtersForm.yard.options = data
      } catch (error) {
        console.error('getYards()', error)
      } finally {
        this.loading = false
      }
    },

    setCompanies() {
      this.$store.dispatch('common/getAllCompanies').then((companies) => {
        this.filtersForm.company.options = companies
      })
    },

    async handleRemoveActiveTypes() {
      this.loading = true
      try {
        await service.removeAllTypes()
        await this.fetchItems()
        this.removeActiveDialog = false
      } catch (error) {
        console.error('handleRemoveActiveTypes()', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.AvailableTrailers {
  position: relative;

  &__bottom-left {
    position: absolute;
    bottom: 4px;
    left: 0;
  }

  &__elipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
  }

  &__menu-dots {
    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      text-align: center;
      width: 200px;
    }
  }

  &__bold-center {
    font-weight: 800;
    text-align: center;
  }

  &__bold {
    font-weight: 800;
  }

  tr:hover {
    cursor: pointer;
  }

  &__active-type {
    background-color: #e1e30c;
  }

  &__towing {
    background-color: #fce8b2 !important;
  }

  &__cursor-default {
    cursor: default;
  }

  &__red-letters {
    font-weight: 700;
    color: red;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  .v-data-table--fixed-header
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th {
    border-bottom: 2px solid #cfcfcf !important;
  }

  .v-toolbar__content {
    padding-left: 0;
    padding-right: 0;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
      position: relative;
    }
  }

  &__loaded {
    background-color: #ff00ff;
  }

  &__broken {
    background-color: #4a86e8;
  }

  &__available {
    background-color: #ff0000;
  }

  .v-data-footer__select .v-select {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  &__lock-first {
    th:nth-child(1),
    tr > td:nth-child(1) {
      position: sticky !important;
      position: -webkit-sticky !important;
      left: 0;
      z-index: 4 !important;
      background: white;
      border-right: 2px solid #cfcfcf;
    }
  }

  th:nth-child(1) {
    top: 0 !important;
    z-index: 5 !important;
  }

  tr:hover > td:nth-child(1) {
    background: #eeeeee;
  }
}
</style>
