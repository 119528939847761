<template>
  <div class="loads-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.loadTypes') }}
      </v-card-title>
      <v-data-table
          height="550"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="loadTypes"
          class="elevation-1"
          :loading="loading"
          :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.color="{ item }">
          <v-chip
            :color="item.color"
            dark
          >
            {{ item.color }}
          </v-chip>
        </template>
        <template v-slot:item.is_active="{item}">
          {{item.is_active ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
                small
                class="mr-2"
                @click="editLoadType(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteLoadType(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddLoadType" data-vv-scope="loadType">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            {{form._id ? 'Edit' : 'Add'}} load type
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                    ref="type"
                    v-model="form.type"
                    v-validate="'required'"
                    :error="errors.has('loadType.type')"
                    :error-messages="errors.first('loadType.type')"
                    name="type"
                    id="name"
                    :autofocus="true"
                    placeholder="Name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <!-- <v-text-field
                    ref="color"
                    v-model="form.color"
                    v-validate="'required'"
                    :error="errors.has('loadType.color')"
                    :error-messages="errors.first('loadType.color')"
                    name="color"
                    id="color"
                    :autofocus="true"
                    placeholder="Color"
                ></v-text-field> -->
                <v-color-picker
                  v-model="form.color"
                  class="ma-2"
                  mode="hexa"
                  show-swatches
                  :hide-canvas="true"
                  :hide-inputs="true"
                  :hide-mode-switch="true"
                  :flat="true"
                ></v-color-picker>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                    v-model="form.is_active"
                    label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'LoadTypes',
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['type'],
        sortDesc: [false],
        itemsPerPage: 10
      },
      totalItems: 0,
      headers: [
        {text: 'Type', value: 'type'},
        {text: 'Color', value: 'color'},
        {text: 'Is active', value: 'is_active'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        type: '',
        color: '#0D47A1FF',
        is_active: true
      },
      formDialog: false
    }
  },
  watch: {
    options: {
      handler () {
        this.$store.dispatch('loadTypes/getLoadTypes', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('loadTypes', ['loadTypes']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    onAddLoadType () {
      this.$validator.validateAll('loadType').then((result) => {
        if (result) {
          if (this.form._id) {
            this.$store.dispatch('loadTypes/editLoadType', this.form).then(res => {
              if (res) {
                this.formDialog = false
                this.form = {
                  _id: null,
                  type: '',
                  color: '#0D47A1FF',
                  is_active: true
                }
              }
            })
          } else {
            this.$store.dispatch('loadTypes/addLoadType', this.form).then(res => {
              if (res) {
                this.formDialog = false
                this.form = {
                  _id: null,
                  type: '',
                  color: '#0D47A1FF',
                  is_active: true
                }
              }
            })
          }
        }
      })
    },
    editLoadType (loadType) {
      this.form = {...loadType}
      this.formDialog = true
    },
    deleteLoadType (loadType) {
      let confVal = confirm(`Are you sure you want to delete load tye: ${loadType.type}?`)
      if (confVal) {
        this.$store.dispatch('loadTypes/deleteLoadType', loadType._id).then(() => {
          this.setMessage(`${loadType.type} successfully deleted`)
        }).catch((err) => {
          console.log('err on delete', err)
        })
      }
    },
    closeForm () {
      this.formDialog = false
      this.form = {
        _id: null,
        type: '',
        color: '#0D47A1FF',
        is_active: true
      }
    }
  }
}
</script>

<style lang="scss">
.loads-wrapper {
  width: 100%;
  margin-top: 20px;
}

// @media only screen and (max-width: 599px) {
//   .loads-wrapper {
//     margin-top: 65px;
//   }
// }
</style>