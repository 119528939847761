import trailerType from '../../services/TrailerTypesService'

const state = {
  trailerTypes: [],
}

const getters = {
  trailerTypes(state) {
    return state.trailerTypes
  },
}

const actions = {
  getTrailerTypes({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return trailerType
      .getTrailerTypes(payload)
      .then((res) => {
        commit('setTrailerTypes', res.data.trailerTypes)
        dispatch('common/setLoading', false, { root: true })
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  getAllTrailerTypes({ commit, dispatch }) {
    dispatch('common/setLoading', true, { root: true })
    return trailerType
      .getAllTrailerTypes()
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('setTrailerTypes', res.data.trailerTypes)
        return Promise.resolve(res.data.trailerTypes)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  addTrailerType({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return trailerType
      .addTrailerType(payload)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('addTrailerType', res.data.trailerType)
        return Promise.resolve(res.data.trailerType)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  editTrailerType({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return trailerType
      .editTrailerType(payload)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('editTrailerType', res.data.trailerType)
        return Promise.resolve(res.data.trailerType)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  deleteTrailerType({ commit, dispatch }, id) {
    dispatch('common/setLoading', true, { root: true })
    return trailerType
      .deleteTrailerType(id)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('deleteTrailerType', id)
        return Promise.resolve(res)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
}

const mutations = {
  setTrailerTypes(state, trailerTypes) {
    state.trailerTypes = trailerTypes
  },
  addTrailerType(state, trailerType) {
    const tmpTrailerTypes = state.trailerTypes.slice()
    tmpTrailerTypes.push(trailerType)
    state.trailerTypes = tmpTrailerTypes
  },
  editTrailerType(state, trailerType) {
    let tmpTrailerTypes = state.trailerTypes.slice()
    let index = tmpTrailerTypes.findIndex((el) => el._id === trailerType._id)
    tmpTrailerTypes.splice(index, 1, trailerType)
    state.trailerTypes = tmpTrailerTypes
  },
  deleteTrailerType(state, id) {
    const tmpTrailerTypes = state.trailerTypes.slice()
    tmpTrailerTypes.splice(
      tmpTrailerTypes.findIndex((el) => el._id === id),
      1
    )
    state.trailerTypes = tmpTrailerTypes
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
