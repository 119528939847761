<template>
  <div class="Leasing">
    <div class="Leasing__actions">
      <v-text-field
        v-model="filters.vin_number"
        class="mr-1"
        label="VIN"
        style="width: 160px"
        small
        solo
        hide-details
        clearable
        @input="handleFiltersChanged"
      />
      <v-btn color="primary" dark class="mb-2" @click="openNewUnitDialog">
        New unit
      </v-btn>
    </div>

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :options="options"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      @update:options="handleOptionsChange"
      disable-sort
      dense
    >
      <template v-slot:item.type="{ item }">
        <td
          :style="{
            backgroundColor: getTypeBackground(item.type),
            textTransform: 'capitalize',
          }"
        >
          {{ item.type }}
        </td>
      </template>

      <template v-slot:item.year="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ item.year }}
        </td>
      </template>

      <template v-slot:item.vin_number="{ item }">
        <td
          :class="{ 'Leasing--red': Boolean(item.lease.paid) }"
          @click="handleVinClicked(item)"
        >
          {{ item.vin_number }}
        </td>
      </template>

      <template v-slot:item.lease.paymentPeriod="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ item.lease.paymentPeriod }}
        </td>
      </template>

      <template v-slot:item.make="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ typeof item.make === 'object' ? item.make.name : item.make }}
        </td>
      </template>

      <template v-slot:item.model="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{
            item.type === 'trailer'
              ? item.trailerType
              : item.model && item.model.name
          }}
        </td>
      </template>

      <template v-slot:item.monthlyPayment="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{
            item.lease.monthlyPayment &&
            `$${item.lease.monthlyPayment.toFixed(2)}`
          }}
        </td>
      </template>

      <template v-slot:item.balloonPayment="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{
            item.lease.balloonPayment &&
            `$${item.lease.balloonPayment.toFixed(2)}`
          }}
        </td>
      </template>

      <template v-slot:item.securityDeposit="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{
            item.lease.securityDeposit &&
            `$${item.lease.securityDeposit.toFixed(2)}`
          }}
        </td>
      </template>

      <template v-slot:item.date="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ formatDate(item.lease.date) }}
        </td>
      </template>

      <template v-slot:item.datePaidOn="{ item }">
        <td
          v-if="item.lease.datePaidOn"
          :class="{ 'Leasing--red': Boolean(item.lease.paid) }"
        >
          {{ formatDate(item.lease.datePaidOn) }}
        </td>
      </template>

      <template v-slot:item.remainingPeriod="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ getRemainingPeriod(item) }}
        </td>
      </template>

      <template v-slot:item.remainingBalance="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          ${{ getRemainingBalance(item).toFixed(2) }}
        </td>
      </template>

      <template v-slot:item.dueDate="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ getDueDate(item) }}
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
      @click:outside="closeDelete"
    >
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      max-width="540px"
      @click:outside="handleCloseDialog"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-card-title v-if="editId" class="text-h5"
                >Edit VIN: {{ form.vin_number }}</v-card-title
              >

              <v-col v-if="!editId" cols="12">
                <v-autocomplete
                  :items="unitOptions"
                  :loading="loading"
                  :search-input.sync="search"
                  item-text="vin_number"
                  label="Search for a truck / trailer / car"
                  placeholder="Search by vin or unit number"
                  prepend-icon="mdi-database-search"
                  item-disabled
                  clearable
                  hide-no-data
                  hide-selected
                  return-object
                  @change="handleUnitPicked"
                >
                  <template v-slot:item="{ item }">
                    <v-icon class="mr-1"> {{ getIcon(item.type) }} </v-icon
                    >VIN:&nbsp;
                    <strong>{{ item.vin_number }}</strong>
                    <template v-if="item.number"
                      >&nbsp;Number:&nbsp;
                      <strong>{{ item.number }}</strong></template
                    >
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="form.date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date"
                      label="Date*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="form.date"
                    @input="
                      (v) => {
                        $refs.dateMenu.save(v);
                        dateMenu = false;
                      }
                    "
                    no-title
                    scrollable
                  />
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="form.monthlyPayment"
                  prepend-icon="mdi-clock"
                  type="number"
                  min="0"
                  label="Monthly*"
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="form.paymentPeriod"
                  prepend-icon="mdi-currency-usd"
                  type="number"
                  min="0"
                  label="Payment period*"
                />
              </v-col>

              <v-col v-if="balloon" cols="6">
                <v-text-field
                  v-model="form.balloonPayment"
                  prepend-icon="mdi-currency-usd"
                  type="number"
                  min="0"
                  label="Balloon payment"
                />
              </v-col>

              <v-col v-if="securityDeposit" cols="6">
                <v-text-field
                  v-model="form.securityDeposit"
                  prepend-icon="mdi-currency-usd"
                  type="number"
                  min="0"
                  label="Security deposit"
                />
              </v-col>

              <v-col v-if="withMonthly" cols="6">
                <v-menu
                  ref="firstPaymentDateMenu"
                  v-model="firstPaymentDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="form.firstPaymentDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.firstPaymentDate"
                      label="First payment date*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="form.firstPaymentDate"
                    @input="
                      (v) => {
                        $refs.firstPaymentDateMenu.save(v);
                        firstPaymentDateMenu = false;
                      }
                    "
                    no-title
                    scrollable
                  />
                </v-menu>
              </v-col>

              <v-col>
                <v-checkbox
                  v-model="form.paid"
                  @change="handlePaidChange"
                  label="Is paid in full?"
                />
              </v-col>

              <v-col v-if="form.paid" cols="6">
                <v-menu
                  ref="datePaidOnMenu"
                  v-model="datePaidOnMenu"
                  :close-on-content-click="false"
                  :return-value.sync="form.datePaidOn"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.datePaidOn"
                      label="Date paid on*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="form.datePaidOn"
                    @input="
                      (v) => {
                        $refs.datePaidOnMenu.save(v);
                        datePaidOnMenu = false;
                      }
                    "
                    no-title
                    scrollable
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseDialog">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="submitDisabled"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="monthlyDialog"
      max-width="540px"
      @click:outside="handleCloseMonthlyDialog"
    >
      <v-card v-if="monthlyUnit">
        <v-card-title class="text-h5"
          >Edit payments for: {{ monthlyUnit.vin_number }}</v-card-title
        >

        <v-card-text style="max-height: 300px; overflow-y: scroll">
          <v-container>
            <v-data-table
              :headers="paymentsHeaders"
              :items="monthlyUnit.lease.monthlyPayments"
              :items-per-page="-1"
              hide-default-footer
              dense
            >
              <template v-slot:item.number="{ index }">
                <td style="text-align: center; font-weight: bold">
                  {{ index + 1 }}
                </td>
              </template>

              <template v-slot:item.dueDate="{ item }">
                {{ item.dueDate | formatDate }}
              </template>

              <template v-slot:item.amount="{ item }">
                <v-text-field
                  v-model="item.amount"
                  class="Leasing__center-input"
                  type="number"
                  min="0"
                  hide-details
                  dense
                />
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseMonthlyDialog">
            Cancel
          </v-btn>
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="updateUnit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import debounce from 'lodash/debounce'
import service from '../../services/ContractService'
import { mapGetters } from 'vuex'

const DEFAULT_ITEM = {
  vin_number: null,
  number: null,
  type: null,
  year: null,
  make: null,
  model: null,
  trailerType: null,
  paid: false,
  datePaidOnMenu: false,

  monthlyPayment: null,
  paymentPeriod: null,
  balloonPayment: null,
  securityDeposit: null,
  date: null,
  firstPaymentDate: null,
}

const defaultHeaders = (balloon, securityDeposit) => [
  {
    text: 'Truck/Trailer',
    value: 'type',
  },
  { text: 'Date', value: 'date' },
  { text: 'VIN', value: 'vin_number' },
  { text: 'Make', value: 'make' },
  { text: 'Model', value: 'model' },
  { text: 'Year', value: 'year' },
  { text: 'Monthly Payment', value: 'monthlyPayment' },
  { text: 'Payment Period', value: 'lease.paymentPeriod' },

  ...(balloon ? [{ text: 'Balloon Payment', value: 'balloonPayment' }] : []),
  ...(securityDeposit
    ? [{ text: 'Security Deposit', value: 'securityDeposit' }]
    : []),
  { text: 'Remaining period', value: 'remainingPeriod' },
  { text: 'Remaining balance', value: 'remainingBalance' },
  { text: 'Due Date', value: 'dueDate' },
  { text: 'Paid in full', value: 'datePaidOn' },
  { text: 'Actions', value: 'actions', width: 60, align: 'center' },
]

const PAYMENTS_HEADERS = [
  {
    align: 'center',
    sortable: false,
    text: '#',
    value: 'number',
    width: 40,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Due date',
    value: 'dueDate',
    width: 100,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Amount',
    value: 'amount',
    width: 60,
  },
]

const WITH_MONTHLY_HEADERS = [
  {
    text: 'Truck/Trailer',
    value: 'type',
  },
  { text: 'VIN', value: 'vin_number' },
  { text: 'Make', value: 'make' },
  { text: 'Model', value: 'model' },
  { text: 'Year', value: 'year' },
  { text: 'Paid in full', value: 'datePaidOn' },
  { text: 'Actions', value: 'actions', width: 60, align: 'center' },
]

const REQUIRED = ['vin_number', 'date', 'paymentPeriod', 'monthlyPayment']

export default {
  name: 'Leasing',

  props: {
    company: {
      type: String,
      required: true,
    },
    withMonthly: {
      type: Boolean,
      default: false,
    },
    securityDeposit: {
      type: Boolean,
      default: false,
    },
    balloon: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      paymentsHeaders: PAYMENTS_HEADERS,
      dateMenu: false,
      datePaidOnMenu: false,
      firstPaymentDateMenu: false,
      dialog: false,
      loading: false,
      unitOptions: [],
      dialogDelete: false,
      items: [],
      totalItems: 0,
      queryTerm: '',
      editId: null,
      filters: { vin_number: null },
      options: {
        page: 1,
        itemsPerPage: 30,
      },
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },
      form: { ...DEFAULT_ITEM, company: this.company },
      monthlyDialog: false,
      monthlyUnit: false,
    }
  },

  watch: {
    company(v) {
      this.fetchItems()
      this.form.company = v
    },
  },

  computed: {
    ...mapGetters('auth', ['permissions']),

    headers() {
      return this.withMonthly
        ? WITH_MONTHLY_HEADERS
        : defaultHeaders(this.balloon, this.securityDeposit)
    },

    submitDisabled() {
      if (!this.permissions.ACCOUNTING_EDIT_ALL) return true
      for (const field of REQUIRED) if (!this.form[field]) return true
      return false
    },

    search: {
      get() {
        return this.queryTerm
      },

      set(val) {
        if (val !== this.queryTerm) {
          this.queryTerm = val
          this.searchUnits(val)
        }
      },
    },
  },

  methods: {
    handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      this.fetchItems()
    },

    handleCloseDialog() {
      this.unitOptions = []
      this.dialog = false
      this.queryTerm = ''
    },

    openNewUnitDialog() {
      this.dialog = true
    },

    handleFiltersChanged: debounce(async function () {
      this.items = []
      await this.fetchItems()
    }, 200),

    handleUnitPicked(u) {
      if (!u) return
      this.search = ''
      this.queryTerm = ''
      this.form = {
        ...this.form,
        ...u,
      }
    },

    getRemainingBalance(item) {
      return (
        this.getRemainingPeriod(item) * item.lease.monthlyPayment +
          item.lease.balloonPayment || 0
      )
    },

    handlePaidChange(v) {
      if (!v) this.form.datePaidOn = null
    },

    getDueDate(item) {
      return moment(item.lease.date)
        .add(item.lease.paymentPeriod, 'months')
        .format('MM/DD/YYYY')
    },

    handleCloseMonthlyDialog() {
      this.monthlyDialog = false
      this.monthlyUnit = null
    },

    handleVinClicked(item) {
      if (!this.withMonthly) return
      this.monthlyDialog = true
      this.monthlyUnit = { ...item }
    },

    async updateUnit() {
      this.loading = true

      try {
        await service.updateMonthlyPayments(this.monthlyUnit)
        await this.fetchItems()
        this.handleCloseMonthlyDialog()
      } catch (error) {
        console.error('Contracts updateUnit()', error)
      } finally {
        this.loading = false
      }
    },

    editItem(item) {
      const { _id, lease, datePaidOn, ...rest } = item
      this.editId = _id
      this.form = Object.assign(
        {},
        {
          ...rest,
          ...lease,
          date: this.formatForDatePicker(lease.date),
          datePaidOn: this.formatForDatePicker(datePaidOn),
        }
      )
      this.dialog = true
    },

    deleteItem(item) {
      const { _id, lease, datePaidOn, ...rest } = item
      this.editId = _id
      this.form = Object.assign(
        {},
        {
          ...rest,
          ...lease,
          date: this.formatForDatePicker(lease.date),
          datePaidOn: this.formatForDatePicker(datePaidOn),
        }
      )
      this.dialogDelete = true
    },

    close() {
      this.resetForm()
      this.dialog = false
    },

    resetForm() {
      this.form = Object.assign({}, { ...DEFAULT_ITEM, company: this.company })
      this.editId = null
    },

    async save() {
      this.loading = true

      try {
        await service.saveUnit(this.form, this.withMonthly)
        await this.fetchItems()
        this.close()
      } catch (error) {
        console.error('Contracts save()', error)
      } finally {
        this.loading = false
      }
    },

    formatForDatePicker(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    getRemainingPeriod(item) {
      let remainingPeriod = 0
      for (let i = 0; i < item.lease.paymentPeriod; i++) {
        const date = moment(item.lease.date).add(i, 'months')
        if (date.isAfter(moment())) remainingPeriod++
      }
      return remainingPeriod
    },

    closeDelete() {
      this.resetForm()
      this.dialogDelete = false
    },

    searchUnits: debounce(async function (val) {
      if (val)
        try {
          this.loading = true
          const { data } = await service.searchUnits(val, {
            trucks: 1,
            trailers: 1,
          })
          if (Array.isArray(data.docs)) this.unitOptions = data.docs
        } catch (error) {
          console.error('Leasing searchUnits()', error)
          this.unitOptions = []
        } finally {
          this.loading = false
        }
    }, 200),

    getIcon(type) {
      switch (type) {
      case 'car':
        return 'mdi-car'
      case 'trailer':
        return 'mdi-truck-trailer'
      default:
        return 'mdi-truck'
      }
    },

    formatDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },

    getTypeBackground(type) {
      let color = '#ffffff'
      if (type === 'truck') return '#D8EAD3'
      if (type === 'trailer') return '#FFF2CC'
      return color
    },

    async deleteItemConfirm() {
      this.loading = true
      try {
        await service.deleteLeaseUnit(this.form.vin_number, this.form.type)
        await this.fetchItems()
      } catch (error) {
        console.error('Leasing deleteItemConfirm()', error)
      } finally {
        this.loading = false
        this.closeDelete()
      }
    },

    async fetchItems() {
      this.loading = true
      try {
        const { data } = await service.getLeaseUnits(this.company, {
          ...this.options,
          ...this.filters,
        })
        if (Array.isArray(data.docs)) {
          this.items = data.docs
          this.totalItems = data.total
        }
      } catch (error) {
        console.error('Leasing fetchItems()', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.Leasing {
  position: relative;

  &__center-input input {
    text-align: center;
  }

  &--red {
    background: #ee4b2b;
  }

  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
    padding: 0;
  }

  &__actions {
    display: flex;
    position: absolute;
    top: -40px;
    right: 0;
    height: 36px;
  }

  table {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }

  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
      position: relative;
    }
  }
}
</style>
