export default {
  $vuetify: {
    dataIterator: {
      rowsPerPageText: 'Stavki po stranici:',
      rowsPerPageAll: 'Sve',
      pageText: '{0}-{1} od {2}',
      noResultsText: 'Ništa pronadjeno',
      nextPage: 'Sledeća strana',
      prevPage: 'Prethodna strana'
    },
    dataTable: {
      rowsPerPageText: 'Stavki po stranici:'
    },
    noDataText: 'Nema podataka'
  },
  user: {
    email: 'Email',
    name: 'Ime',
    role: 'Uloga',
    actions: 'Akcije',
    loginError: 'Pogrešan email ili password, pokušajte ponovo!'
  },
  menu: {
    home: 'Početna',
    competitions: 'Top lige',
    spec: 'Speciali',
    quotas: 'Upload kvota',
    icons: 'Upload ikonica',
    bettingPlaces: 'Uplatna mesta',
    cities: 'Gradovi',
    banners: 'Upravljanje banerima',
    news: 'Upravljanje vestima',
    agents: 'Agenti',
    logs: 'Log izmene'
  },
  other: {
    users: 'Korisnici',
    search: 'Pretraži',
    logout: 'Odjava',
    save: 'Sačuvaj',
    config: 'Konfig',
    cities: 'Gradovi',
    driver_documents: 'Driver documents',
  },
  FIELD_REQUIRED: 'Polje {field} je obavezno',
  FIELD_NUMERIC: 'Polje {field} moze sadrzati samo brojeve',
  FIELD_IMAGE: 'Podržani fajlovi su .jpg, .jpeg i .png',
  load_more: 'Učitaj još',
  EMAIL_UNIQUE_ERROR: 'Email adresa zauzeta',
  COPY_TO_CLIPBOARD_SUCCESS: 'Uspešno ste kopirali link',
  SET_PASSWORD: 'Kreiranje lozinke',
  TOKEN_EXPIRED: 'Token za kreiranje lozinke je istekao, zatražite novi link od administratora.',
  USER_NOT_FOUND: 'Korisnik nije pronađen',
  RESET_PASSWORD_EMAIL_SENT: 'Poslat vam je email sa linkom za reset lozinke'
}
