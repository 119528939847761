const { FINANCED, REFINANCED, PAID_WITH_CASH, TRAILER_BACKGROUND, TRUCK_BACKGROUND } = require('./accountingConstants')


const isForCash = (unitsType) => PAID_WITH_CASH.includes(unitsType)
exports.isForCash = isForCash
const isRefinanced = (unitsType) => REFINANCED.includes(unitsType)
exports.isRefinanced = isRefinanced
const isFinanced = (unitsType) => FINANCED.includes(unitsType)
exports.isFinanced = isFinanced

const getFinancingType = (unit) => {
  if (isRefinanced(unit.unitsType)) return 'Refinanced'
  if (isForCash(unit.unitsType)) return 'Paid with cash'
  if (isFinanced(unit.unitsType)) return 'Financed'
  return '/'
}
exports.getFinancingType = getFinancingType

const monthlyPaymentsRemaining = (monthlyPayments) => monthlyPayments.filter((m) => !m.paid).length
exports.monthlyPaymentsRemaining = monthlyPaymentsRemaining

const balloonPerUnit = (balloonPayment, unitsLength) => {
  if (!balloonPayment || !unitsLength) return 0
  return balloonPayment.amount / unitsLength
}
exports.balloonPerUnit = balloonPerUnit

const getTypeBackground = (type) => {
  let color = '#ffffff'
  if (type && type.toLowerCase() === 'truck') return TRUCK_BACKGROUND
  if (type && type.toLowerCase() === 'trailer') return TRAILER_BACKGROUND
  return color
}
exports.getTypeBackground = getTypeBackground

const unitPerMonthPrice = (unit) => {
  if (!unit.monthlyPayment) return 0

  return (
    monthlyPaymentsRemaining(unit.monthlyPayments) *
    unit.monthlyPayment +
    balloonPerUnit(
      unit.balloonPayment && unit.balloonPayment.amount,
      unit.unitsLength
    )
  )
}
exports.unitPerMonthPrice = unitPerMonthPrice

const unitPayoffPrice = (unit, balloonPayment, isForCash = false) => {
  if (unit.paid && unit.paid.date) return 'Paid in full.'

  if (isForCash) {
    if (!unit.amount) return 'Enter a cash price.'
    return unit.amount
  }

  if (!Array.isArray(unit.monthlyPayments) || unit.monthlyPayments.length === 0) return 'No more payments.'
  if (!unit.monthlyPayment) return 'Enter a monthly payment.'

  return (
    monthlyPaymentsRemaining(unit.monthlyPayments) *
    unit.monthlyPayment + balloonPerUnit(balloonPayment, unit.unitsLength)
  )
}
exports.unitPayoffPrice = unitPayoffPrice
