import Api from '@/services/Api'

export default {
  getYards (pagination) {
    return Api().get('/yards', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? -1 : 1
    }})
  },
  addYard (yard) {
    return Api().post('/yards', yard)
  },
  editYard (yard) {
    return Api().put('/yards', yard)
  },
  deleteYard (id) {
    return Api().delete(`/yards/${id}`)
  },
  getAllYards (type) {
    return Api().get(`/yards/all/${type}`)
  }
}
