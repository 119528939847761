<template>
  <div class="registration-fields d-flex" v-if="billingConfig">
    <v-select
      class="mr-1"
      v-model="form.type"
      :items="billingConfig.options"
      label="Billing Period"
      hide-details
      small
      @change="emitFormData"
    />
    <v-text-field
      v-if="billingConfig.additionalFields && billingConfig.additionalFields.includes('amount')"
      prefix="$"
      class="mr-1"
      label="Amount"
      v-model="form.amount"
      type="number"
      @change="emitFormData"
    ></v-text-field>
  </div>
</template>

<script>
import { UNIT_FORM_CONFIG_BY_TYPE } from '../../utils/constants.js'

export default {
  name: 'BillingPeriodInput',
  props: {
    unitType: {
      type: String,
      required: true
    },
    billingData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    billingConfig() {
      return this.unitType ? UNIT_FORM_CONFIG_BY_TYPE[this.unitType].billing_periond : null
    }
  },
  data () {
    return {
      requestedDateMenu: false,
      form: {
        type: '',
        amount: 0
      }
    }
  },
  created() {
    this.form = {...this.form, ...this.billingData}
  },
  methods: {
    emitFormData() {
      this.form.amount = parseFloat(this.form.amount)
      this.$emit('input', this.form)
    }
  }
}
</script>