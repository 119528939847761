<template>
  <div class="AccountingUnits">
    <div class="d-flex align-center justify-space-between mb-2">
      <v-spacer />
      <table-filters
        class="mr-2"
        :initial-values="{ ...FILTERS_INITIAL }"
        :initial-form="filtersForm"
        @filters-changed="handleFiltersChanged"
        hide-clear-button
      />
    </div>

    <div class="AccountingUnits__table">
      <div class="AccountingUnits__table-order">
        <table-order
          :key="$route.query.paidWithCash"
          :userId="user && user._id"
          :default-order="defaultHeaders"
          @order-changed="handleTableOrderChanged"
          :table-name="
            !$route.query.paidWithCash
              ? 'ACCOUNTING_UNITS'
              : 'ACCOUNTING_UNITS_CASH'
          "
        />
      </div>

      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :options="options"
        :footer-props="footerProps"
        :server-items-length="totalItems"
        @update:options="handleOptionsChange"
        disable-sort
        dense
      >
        <template v-slot:item.type="{ item }">
          <td
            :style="{
              backgroundColor: getTypeBackground(item.type),
              textTransform: 'capitalize',
            }"
          >
            {{ item.type }}
          </td>
        </template>
        <template v-slot:item.make="{ item }">
          {{ typeof item.make === 'object' ? item.make.name : item.make }}
        </template>
        <template v-slot:item.model="{ item }">
          {{
            item.type === 'trailer'
              ? item.trailerType
              : item.model && item.model.name
          }}
        </template>
        <template v-slot:item.bank="{ item }">
          <div class="text-overflow">
            {{ item.bank && item.bank.name }}
          </div>
        </template>
        <template v-slot:item.financingType="{ item }">
          {{ getFinancingType(item) }}
        </template>
        
        <template v-slot:item.payoff="{ item }">
          {{
            typeof item.payoff === 'number'
              ? `$${item.payoff.toFixed()}`
              : item.payoff
          }}
        </template>

        <template v-slot:item.location="{ item }">
          <div class="text-overflow">
            {{ item.yard && item.yard.name }}
          </div>
        </template>

        <template v-slot:item.company="{ item }">
          <div class="text-overflow">
            {{ item.company }}
          </div>
        </template>

        <template v-slot:item.contractName="{ item }">
          <router-link
            class="AccountingUnits__id-col text-overflow"
            :to="`/contract-details/${item.contractId}`"
          >
            {{ item.contractName }}
          </router-link>
        </template>

        <template v-slot:item.vin_number="{ item }">
          <td>
            <div style="display: flex; align-items: center">
              {{ item.vin_number }}

              <router-link
                :to="`/accounting/units-history?vin_number=${item.vin_number}`"
              >
                <v-icon class="ml-2" small> mdi-history </v-icon>
              </router-link>
            </div>
          </td>
        </template>

        <template v-slot:item.status="{ item }">
          {{ item.status || (item.aStatus && item.aStatus.text) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="deleteUnit(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>

    <div class="AccountingUnits__bottom-left">
      <v-btn
        color="success"
        :loading="loading"
        class="mr-1"
        dark
        small
        @click="handleExportToExcell"
      >
        Export <v-icon small>mdi-file-excel-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import throttle from 'lodash/throttle'
import TableFilters from '../TableFilters.vue'
import TableOrder from '../TableOrder.vue'
import service from '../../services/ContractService'
import { b64toBlob, downloadFile } from '../../utils'
import * as accountingMethods from '../../utils/accountingMethods'
import {
  FILTERS,
  FILTERS_INITIAL,
  HEADERS,
  COMMON_DATA,
  FINANCED_AND_REFINANCED,
  PAID_WITH_CASH,
} from '../../utils/accountingConstants'

export default {
  name: 'AccountingUnits',

  components: { TableFilters, TableOrder },

  data() {
    return {
      ...COMMON_DATA,

      paidWithCash: Boolean(this.$route.query.paidWithCash),
      headers: this.$route.query.paidWithCash
        ? HEADERS.PAID_WITH_CASH
        : HEADERS.FINANCED_AND_REFINANCED,

      defaultHeaders: this.$route.query.paidWithCash
        ? HEADERS.PAID_WITH_CASH
        : HEADERS.FINANCED_AND_REFINANCED,

      FILTERS_INITIAL: {
        ...FILTERS_INITIAL,
        financing: this.$route.query.paidWithCash
          ? [...PAID_WITH_CASH]
          : [...FINANCED_AND_REFINANCED],
      },
      filters: {
        ...FILTERS_INITIAL,
        financing: this.$route.query.paidWithCash
          ? [...PAID_WITH_CASH]
          : [...FINANCED_AND_REFINANCED],
      },
      filtersForm: {
        ...FILTERS.COMMON_UNIT_FILTERS,
        ...FILTERS.ACCOUNTING_UNIT_FILTERS,
        ...(this.$route.query.paidWithCash
          ? {}
          : { ...FILTERS.FINANCING_FILTERS }),
      },
    }
  },

  async created() {
    await this.setupFilters()
  },

  watch: {
    $route() {
      this.setupTableHeaders()
      if (this.$route.query.paidWithCash) this.setupFiltersForCash()
      else this.setupFiltersForFinanced()
      this.fetchItems()
    },
  },

  computed: {
    ...mapGetters('auth', ['user']),
  },

  methods: {
    ...accountingMethods,

    setupTableHeaders() {
      this.headers = this.$route.query.paidWithCash
        ? HEADERS.PAID_WITH_CASH
        : HEADERS.FINANCED_AND_REFINANCED
      this.defaultHeaders = this.headers
    },

    setupFiltersForFinanced() {
      this.filtersForm = {
        ...this.filtersForm,
        ...FILTERS.FINANCING_FILTERS,
      }

      this.filters = {
        ...this.filters,
        financing: [...FINANCED_AND_REFINANCED],
      }
    },

    setupFiltersForCash() {
      // eslint-disable-next-line no-unused-vars
      const { financing, bank, ...rest } = this.filtersForm
      this.filtersForm = { ...rest }
      this.filters = {
        ...this.filters,
        financing: [...PAID_WITH_CASH],
      }
    },

    handleTableOrderChanged(order) {
      const prepareObj = {}
      order.forEach((c) => {
        prepareObj[c.columnName] = {
          order: c.order,
          isShown: c.isShown,
        }
      })

      const headers = this.$route.query.paidWithCash
        ? HEADERS.PAID_WITH_CASH
        : HEADERS.FINANCED_AND_REFINANCED

      this.headers = headers
        .filter((c) => prepareObj[c.value].isShown)
        .sort((a, b) => prepareObj[a.value].order - prepareObj[b.value].order)
    },

    async setupFilters() {
      try {
        const { data } = await service.getUnitFilterOptions({ forUnits: true })

        this.filtersForm.make.options = [
          { header: 'Truck' },
          ...data.truckMakes,
          { header: 'Trailer' },
          ...data.trailerMakes,
        ]
        this.filtersForm.model.options = [
          { header: 'Truck' },
          ...data.truckModels,
          { header: 'Trailer' },
          ...data.trailerTypes,
        ]
        this.filtersForm.bank.options = [...data.banks]
        this.filtersForm.yard.options = [...data.yards]
      } catch (error) {
        console.error('AccountingUnits setupFilters()', error)
      }
    },

    handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      this.fetchItems()
    },

    handleFiltersChanged: throttle(function (filters) {
      this.filters = { ...this.filters, ...filters }
      this.options.page = 1
      this.items = []
      this.fetchItems()
    }, 200),

    async handleExportToExcell() {
      try {
        this.loading = true

        const { data } = await service.downloadSheet(this.filters)
        const blob = b64toBlob(
          data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        )
        downloadFile(blob, 'units')
      } catch (error) {
        console.error('handleExportToExcell()', error)
      } finally {
        this.loading = false
      }
    },

    async fetchItems() {
      this.loading = true
      try {
        const params = { ...this.options, ...this.filters }

        const { data } = await service.getUnits(params)
        if (Array.isArray(data.docs)) {
          this.items = data.docs
          this.totalItems = data.totalItems
        }
      } catch (error) {
        console.error('AccountingUnits fetchItems()', error)
      } finally {
        this.loading = false
      }
    },

    async deleteUnit(item) {
      let confVal = confirm(
        `Are you sure you want to delete unit: ${item.vin_number}?`
      )
      if (confVal) {
        try {
          await service.deleteUnit(item.contractId, item.vin_number)
          this.fetchItems()
        } catch (error) {
          console.log('deleteUnit()', error)
        }
      }
    },
  },
}
</script>

<style lang="scss">
.AccountingUnits {
  position: relative;

  &__table {
    position: relative;
  }

  &__table-order {
    position: absolute;
    right: -6px;
    top: -20px;
    z-index: 10;
  }

  &__bottom-left {
    position: absolute;
    bottom: 4px;
    left: 0;
  }

  .v-data-footer__select .v-select {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  table {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }

  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
      position: relative;
    }
  }

  &__id-col {
    font-weight: bold;
    text-decoration: underline;
    color: #3f51b5;
  }

  .text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
