import Api from '@/services/Api'

export default {
  getBanks (params) {
    return Api().get('/banks', {params: {
      page: params.page,
      perPage: params.itemsPerPage,
      sortby: params.sortBy,
      isDealer: params.isDealer,
      vin: params.vin,
      order: params.descending ? -1 : 1
    }})
  },
  addBank (bank) {
    return Api().post('/banks', bank)
  },
  editBank (bank) {
    return Api().put('/banks', bank)
  },
  deleteBank (id) {
    return Api().delete(`/banks/${id}`)
  },
  getAllBanks () {
    return Api().get('/banks/all')
  },
  getBank(id) {
    return Api().get(`/banks/${id}`)
  }
}
