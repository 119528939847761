<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title>
        {{ isPostedTrucks ? 'Posted Trucks' : 'Post Trucks' }}
        <v-divider class="mx-4" inset vertical></v-divider>

        <table-filters
            v-if="isPostedTrucks"
            class="mr-2"
            :initial-values="{ ...FILTERS_INITIAL }"
            :initial-form="filtersForm"
            hide-clear-button
            @filters-changed="handleFiltersChanged"
        />
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
          height="900"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="postTrucks"
          class="elevation-1"
          :loading="loading"
          hide-default-footer
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.truckId="{ item }">
          <td>
            <v-btn @click="goToPostLoads(item)" plain color="primary">{{ item.truckId }}</v-btn>
          </td>
        </template>
        <template v-slot:item.avail="{ item }">
          {{ item.avail | formatDate  }}
        </template>
<!--        <template v-slot:item.trip_duration="{ item }">-->
<!--          {{ item.trip_duration | formatDurationFromSeconds  }}-->
<!--        </template>-->
        <template v-slot:item.trip="{ item }">
          {{ item.trip  }} mi
        </template>
        <template v-slot:[`item.dho.trip`]="{ item }">
          <span v-if="item.dho">{{ item.dho.trip  }} mi</span>
          <span v-else>/</span>
        </template>
        <template v-slot:[`item.dhd.trip`]="{ item }">
          <span v-if="item.dhd">{{ item.dhd.trip  }} mi</span>
          <span v-else>/</span>
        </template>
<!--        <template v-slot:item.is_active="{ item }">-->
<!--          {{ item.is_active ? 'YES' : 'NO' }}-->
<!--        </template>-->

        <template v-slot:item.actions="{ item }">
          <td class="justify-center layout px-0">
            <v-icon small class="mr-2" @click="editPostTruck(item)">
              mdi-pencil
            </v-icon>
            <v-icon :disabled="loading" small @click="deletePostTruck(item)"> mdi-delete </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition v-if="!isPostedTrucks">
        <v-btn
            :disable="!canAddTruck"
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddPostTruck" data-vv-scope="postTruck">
          <v-card-title class="lighten-4 py-4 title">
            {{ form._id ? 'Edit' : 'Add' }} post truck
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-menu
                    ref="availMenu"
                    v-model="availMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.avail"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="form.avail"
                        v-validate="{ required: true}"
                        data-vv-name="avail"
                        :error="errors.has('postTruck.avail')"
                        :error-messages="errors.first('postTruck.avail')"
                        label="Avail *"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        hide-details="auto"
                        small
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="form.avail"
                      no-title
                      scrollable
                      @input="$refs.availMenu.save(form.avail)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-autocomplete
                    v-model="form.origin"
                    :items="citiesListOrigin"
                    item-text="name"
                    return-object
                    v-validate="{ required: true}"
                    data-vv-name="origin"
                    :error="errors.has('postTruck.origin')"
                    :error-messages="errors.first('postTruck.origin')"
                    label="Origin *"
                    :search-input.sync="searchCitiesOrigin"
                    hide-details="auto"
                    hide-no-data
                    :loading="loading"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    clearable
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-autocomplete
                    v-model="form.destination"
                    :items="citiesListDestination"
                    item-text="name"
                    return-object
                    v-validate="{ required: false}"
                    data-vv-name="destination"
                    :error="errors.has('postTruck.destination')"
                    :error-messages="errors.first('postTruck.destination')"
                    label="Destination *"
                    :search-input.sync="searchCitiesDestination"
                    hide-details="auto"
                    hide-no-data
                    :loading="loading"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    clearable
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-select
                    v-model="form.trailer_type"
                    :items="trailerTypes"
                    data-vv-name="trailer_type"
                    label="Trailer type *"
                    small
                    hide-details="auto"
                    clearable
                />
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                    ref="note"
                    v-model="form.note"
                    name="note"
                    id="note"
                    :autofocus="true"
                    placeholder="Note"
                ></v-text-field>
              </v-flex>
<!--              <v-flex xs12 align-center justify-space-between>-->
<!--                <v-text-field-->
<!--                    ref="truckId"-->
<!--                    v-model="form.truckId"-->
<!--                    name="truckId"-->
<!--                    id="truckId"-->
<!--                    :error="errors.has('postTruck.truckId')"-->
<!--                    :error-messages="errors.first('postTruck.truckId')"-->
<!--                    v-validate="{ required: true}"-->
<!--                    :autofocus="true"-->
<!--                    placeholder="ID"-->
<!--                ></v-text-field>-->
<!--              </v-flex>-->
<!--              <v-flex xs12 align-center justify-space-between>-->
<!--                <v-switch-->
<!--                    v-model="form.is_active"-->
<!--                    :label="form.is_active ? 'Active' : 'Inactive'"-->
<!--                    color="success"-->
<!--                ></v-switch>-->
<!--              </v-flex>-->
            </v-layout>
          </v-container>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="resetForm"> Close </v-btn>
              <v-btn :disable="!canAddTruck" color="blue darken-1" text type="submit"> Save </v-btn>
        </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {mapActions, mapGetters} from 'vuex'
import TableFilters from '../TableFilters.vue'
// import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import loadsService from '@/services/LoadsService'
const FILTERS_INITIAL = {
  // origin: null,
  // destination: null,
}
export default {
  name: 'BoardList',
  mounted() {
    this.fetchItems()
  },
  data () {
    return {
      FILTERS_INITIAL,
      filtersForm: {
        truckId: {
          type: 'text',
          label: 'ID',
        },
        origin: {
          type: 'autocomplete',
          label: 'Origin',
          apiUrl: '/loads/autocomplete/cities',
          dataKey: 'cities'
        },
        destination: {
          type: 'autocomplete',
          label: 'Destination',
          apiUrl: '/loads/autocomplete/cities',
          dataKey: 'cities'
        },
        dho: {
          label: 'DH-O',
          type: 'text',
          inputType: 'number'
        },
        dhd: {
          label: 'DH-D',
          type: 'text',
          inputType: 'number'
        }
      },
      filters: { ...FILTERS_INITIAL },
      formDialog: false,
      searchCitiesOrigin: '',
      searchCitiesDestination: '',
      options: {
        page: 1,
        sortBy: 'origin',
        sortDesc: true,
        itemsPerPage: 50000,
      },
      availMenu: false,
      citiesListOrigin: [],
      citiesListDestination: [],
      trailerTypes: ['V', 'F', 'R', 'D&H'],
      form: {
        _id: null,
        person_posted: '',
        avail: '',
        origin: null,
        trailer_type: '',
        destination: '',
        note: '',
        // truckId: '',
        // is_active: true,
      },
      totalItems: 0,
      // headers: [
      //   { text: 'Origin', value: 'origin.name' },
      //   { text: 'Avail', value: 'avail' },
      //   { text: 'Destination', value: 'destination.name' },
      //   { text: 'Trip', value: 'trip' },
      //   // { text: 'Trip Duration', value: 'trip_duration' },
      //   { text: 'Note', value: 'note' },
      //   { text: 'Trailer Type', value: 'trailer_type' },
      //   // { text: 'Is active', value: 'is_active' },
      //   { text: 'Actions', value: 'actions', align: 'center' },
      // ],
    }
  },
  watch: {
    searchCitiesOrigin (val) {
      if (val) {
        this.debounceSearch(val, 'citiesListOrigin')
      }
    },
    searchCitiesDestination (val) {
      if (val) {
        this.debounceSearch(val, 'citiesListDestination')
      }
    },
    'options.page'() {
      this.fetchItems()
    }
    // ostatak vašeg koda

  },
  components: {
    TableFilters,
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('postTrucks', ['postTrucks']),
    ...mapGetters('auth', ['user', 'permissions']),
    canEditTruck() {
      if (this.permissions && this.permissions.PREBOOK_POST_TRUCKS_EDIT) return true
      return false
    },
    canAddTruck() {
      if (this.permissions && this.permissions.PREBOOK_POST_TRUCKS_ADD) return true
      return false
    },
    isPostedTrucks() {
      return this.$route.name === 'PostedTrucks'
    },
    headers() {
      let headers = [
        { text: 'ID', value: 'truckId' },
        { text: 'Origin', value: 'origin.name' },
        { text: 'DH-O', value: 'dho.trip' },
        { text: 'Avail', value: 'avail' },
        { text: 'Destination', value: 'destination.name' },
        { text: 'DH-D', value: 'dhd.trip' },
        { text: 'Trip', value: 'trip' },
        // { text: 'Trip Duration', value: 'trip_duration' },
        { text: 'Note', value: 'note' },
        { text: 'Trailer Type', value: 'trailer_type' },
        { text: 'Posted by', value: 'posted_by.email' },
        { text: 'Board', value: 'posted_by.board.name' },
        // { text: 'Is active', value: 'is_active' },
      ]

      if (!this.isPostedTrucks) {
        headers.push({ text: 'Actions', value: 'actions', align: 'center' })
      }

      return headers
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),
    resetForm() {
      this.formDialog = false
      this.form = {
        _id: null,
        person_posted: '',
        avail: '',
        origin: '',
        trailer_type: '',
        destination: '',
        note: '',
        // truckId: '',
        // is_active: true,
      }
    },
    debounceSearch: debounce( function(search, dataArrayName) {
      search = search.split(',')[0]
      if (search && search.length) {
        this.$store.dispatch('common/setLoading', true)
        return loadsService.getCitiesList(search).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this[dataArrayName] = res.data.cities
          }
        }).catch(err => {
          console.log('trucks error search', err)
          this[dataArrayName] = []
        })
      } else {
        this[dataArrayName] = []
      }
    }, 400),
    handleFiltersChanged: debounce(function (filters) {
      this.options.page = 1
      this.options = {...this.options, ...filters}
      this.fetchItems()

    }, 500),
    fetchItems() {
      this.$store
        .dispatch('postTrucks/getPostTrucks', this.options)
        .then((res) => {
          this.totalItems = res.total
        })
    },
    onAddPostTruck() {
      this.$validator.validateAll('postTruck').then((result) => {
        if (result) {
          if (this.form._id) {
            this.$store
              .dispatch('postTrucks/editPostTruck', {
                ...this.form,
              })
              .then((res) => {
                if (res) {
                  this.formDialog = false
                  this.form = {
                    _id: null,
                    person_posted: '',
                    avail: '',
                    origin: '',
                    trailer_type: '',
                    destination: '',
                    note: '',
                    // truckId: '',
                    // is_active: true,
                  }
                }
              })
              .catch((err) => {
                if (err.data && err.data.code === 11000) {
                  let key = Object.keys(err.data.keyPattern)[0]
                  this.errors.add({
                    scope: 'posTruck',
                    field: key,
                    msg: 'This field must be unique',
                  })
                }
              })
          } else {
            if (!this.canAddTruck) return false
            this.$store
              .dispatch('postTrucks/addPostTruck', {
                ...this.form,
              })
              .then((res) => {
                if (res) {
                  this.formDialog = false
                  this.goToPostLoads(this.form)
                  this.form = {
                    _id: null,
                    person_posted: '',
                    avail: '',
                    trailer_type: '',
                    destination: '',
                    note: '',
                    // truckId: '',
                    // is_active: true,
                  }
                }
              })
              .catch((err) => {
                if (err.data && err.data.code === 11000) {
                  let key = Object.keys(err.data.keyPattern)[0]
                  this.errors.add({
                    scope: 'postTruck',
                    field: key,
                    msg: 'This field must be unique',
                  })
                }
              })
              .finally(() => this.fetchItems())
          }
        }
      })
    },
    editPostTruck(postTruck) {
      this.form = {...postTruck}
      this.citiesListOrigin.push(postTruck.origin)
      this.citiesListDestination.push(postTruck.destination)
      this.formDialog = true
    },

    deletePostTruck(postTruck) {
      let confVal = confirm(
        `Are you sure you want to delete post truck: ${postTruck._id}?`
      )
      if (confVal) {
        this.$store
          .dispatch('postTrucks/deletePostTruck', postTruck._id)
          .then(() => {
            this.setMessage(`${postTruck._id} successfully deleted`)
          })
          .catch((err) => {
            console.log('err on delete', err)
          })
      }
    },
    closeForm() {
      this.formDialog = false
      this.form = {
        _id: null,
        person_posted: '',
        avail: '',
        origin: '',
        trailer_type: '',
        destination: '',
        note: '',
        // truckId: '',
        // is_active: true,
      }
    },
    goToPostLoads(truck) {
      const query = {
        dho: 150
      }
      if (truck.origin) query['origin'] = truck.origin.name
      if (truck.destination) {
        query['destination'] = truck.destination.name
        query['dhd'] = 150
      }
      if (truck.avail) query['avail'] = moment(truck.avail).format('YYYY-MM-DD')
      this.$router.push({name: 'PostLoads', query: query})
    }
  }
}
</script>

<style lang="scss">
.boards-wrapper {
  width: 100%;
  margin-top: 20px;
}

.v-list-item.move {
  cursor: move;

  .v-list-item__title.teamLead {
    color: green;
  }
}

</style>
