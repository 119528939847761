import axios from 'axios'
import store from '../store'
import i18n from '../plugins/lang'
import router from '../router'

export default () => {
  let msHttp = axios.create({
    baseURL: `${process.env.VUE_APP_MICROSERVICE_PROXY_URL}`,
    withCredentials: true
  })


  msHttp.interceptors.response.use(
    response => response,
    error => {
      const { status } = error.response
      store.dispatch('common/setLoading', false)
      if (status === 500) {
        store.dispatch('common/setMessage', 'Doslo je do greske na serveru!')
      } else if (status === 401) {
        if (router.app.$route.name === 'Shop') {
          store.dispatch('auth/logoutLocal')
          router.push('/login')
        }
      } else if (status === 403) {
        store.dispatch('common/setMessage', `Akcija zabranjena: ${error.response.request.responseURL}`)
      } else if (status === 404) {
        store.dispatch('common/setMessage', `Not found error: ${error.response.request.responseURL}`)
      } else if (status === 400) {
        store.dispatch('common/setMessage', error.response.data.message ? i18n.t(error.response.data.message) : 'Bad request error!')
        console.log('error 400', error.response.data.message)
      }
      return Promise.reject(error.response)
    }
  )

  return {msHttp}
}
