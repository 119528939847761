<template>
  <form @submit.prevent="onSubmitForm" data-vv-scope="truck">
    <v-card-title class="lighten-4 py-4 title">
      {{ form._id ? 'Edit' : 'Add' }} truck
    </v-card-title>
    <v-container grid-list-sm class="pa-4">
      <v-layout row wrap>
        <v-flex md12 xs12>
          <v-switch :disabled="thirdPartyDisabled" hint="You need to remove division to select" persistent-hint v-model="form.is_third_party" @change="onChangeThirdParty">
            <template v-slot:label>
              <span class="text">Third party</span>
            </template>
          </v-switch>
        </v-flex>
        <v-flex md3 xs6>
          <v-text-field
            ref="number"
            v-model="form.number"
            v-validate="'required|max:6|numeric'"
            :error="errors.has('truck.number')"
            :error-messages="errors.first('truck.number')"
            name="number"
            id="number"
            :autofocus="true"
            placeholder="Truck Number"
            label="Truck Number"
          ></v-text-field>
        </v-flex>
        <v-flex v-if="!form.is_third_party" md9 xs6>
          <v-autocomplete
            clearable
            v-model="form.current_driver"
            :items="drivers"
            return-object
            item-text="name"
            item-id="id"
            item-disabled="disabled"
            label="Driver"
            :search-input.sync="searchDrivers"
            hide-details="auto"
            hide-no-data
            :loading="loading"
            placeholder="Start typing to Search"
            prepend-icon="mdi-database-search"
            :filter="clientFilter"
            name="driver"
            @click:clear="onClearDriver"
          >
            <template v-slot:item="{ item }">
              <span>
                {{ `${item.name}` }}
              </span>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex v-else md9 xs9>
          <v-autocomplete
              clearable
              v-model="form.owner"
              :items="thirdPartyItems"
              return-object
              item-text="name"
              item-id="owner"
              item-disabled="disabled"
              label="Third party"
              hide-details="auto"
              hide-no-data
              placeholder="Start typing to Search"
              prepend-icon="mdi-database-search"
              :filter="clientFilter"
              name="owners"
          >
            <template v-slot:item="{ item }">
              <span>
                {{ `${item.name}` }}
              </span>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex md4 xs6>
          <v-text-field
              ref="info"
              v-model="form.info"
              label="Info"
              name="info"
              id="info"
              hide-details="auto"
              placeholder="Info"
          ></v-text-field>
        </v-flex>
<!--        <v-flex md4 xs6>-->
<!--          <v-text-field-->
<!--              ref="noteNew"-->
<!--              v-model="form.noteNew"-->
<!--              label="Note"-->
<!--              name="noteNew"-->
<!--              id="noteNew"-->
<!--              hide-details="auto"-->
<!--              placeholder="Note"-->
<!--          ></v-text-field>-->
<!--        </v-flex>-->
        <v-flex xs4>
          <v-text-field
            ref="phone"
            v-model="form.phone"
            label="Phone"
            name="phone"
            id="phone"
            hide-details="auto"
            placeholder="Phone"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-text-field v-model="form.aOwner" label="Owner" />
        </v-flex>
        <v-flex md2 xs6>
          <v-select
            clearable
            v-model="form.division"
            :items="companiesAll"
            name="company"
            id="company"
            label="Select division"
          ></v-select>
        </v-flex>
        <v-flex xs3>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="startDateFormated.first"
                label="Start date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="form.start_date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.start_date"
              @change="menu = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs3>
          <v-menu
            ref="menuSecond"
            v-model="menuSecond"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="startDateFormated.second"
                label="Start date second"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="form.start_date_second = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.start_date_second"
              @change="menuSecond = false"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs3>
          <v-autocomplete
            v-model="form.make"
            :items="truckMakes"
            item-text="name"
            return-object
            name="make"
            id="make"
            label="Select make"
            @input="getModels"
            clearable
            @click:clear="form.model = null"
            v-validate="'required'"
            :error="errors.has('truck.make')"
            :error-messages="errors.first('truck.make')"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs3>
          <v-autocomplete
            :disabled="!form.make && !truckModels.length"
            v-model="form.model"
            :items="truckModels"
            item-text="name"
            return-object
            name="model"
            id="model"
            label="Select model"
            v-validate="'required'"
            clearable
            :error="errors.has('truck.model')"
            :error-messages="errors.first('truck.model')"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            ref="color"
            v-model="form.color"
            name="color"
            id="color"
            placeholder="Truck color"
            label="Truck color"
            v-validate="'max:15'"
            :error="errors.has('truck.color')"
            :error-messages="errors.first('truck.color')"
          ></v-text-field>
        </v-flex>
        <v-flex xs2>
          <v-select
            v-model="form.year"
            :items="yearsList"
            name="year"
            id="year"
            label="Year"
          ></v-select>
        </v-flex>
        <v-flex xs4>
          <v-text-field
            ref="vin_number"
            v-model="form.vin_number"
            name="vin_number"
            id="vin_number"
            placeholder="Vin number"
            label="Vin number"
            v-validate="'required|length:17'"
            :error="errors.has('truck.vin_number')"
            :error-messages="errors.first('truck.vin_number')"
          ></v-text-field>
        </v-flex>
        <v-flex md4 xs4>
          <v-text-field
            ref="plate"
            v-model="form.plate_number"
            name="plate"
            id="plate"
            placeholder="Plate"
            label="Plate"
            v-validate="'max:12'"
            :error="errors.has('truck.plate')"
            :error-messages="errors.first('truck.plate')"
          ></v-text-field>
        </v-flex>
        <v-flex xs4>
          <v-select
            clearable
            v-model="form.engine"
            :items="engines"
            name="engine"
            id="engine"
            label="Truck engine"
          ></v-select>
        </v-flex>
        <v-flex xs4>
          <v-select
            v-model="form.transmission"
            :items="transmissionTypes"
            name="transmission"
            id="transmission"
            label="Select transmission"
          ></v-select>
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model="form.mileage"
            name="mileage"
            id="mileage"
            placeholder="Truck mileage"
            label="Truck mileage"
            v-validate="'max:15'"
            :error="errors.has('truck.mileage')"
            :error-messages="errors.first('truck.mileage')"
          ></v-text-field>
        </v-flex>
        <v-flex xs4>
          <v-select
              v-model="form.gps_type"
              :items="gpsTypesItems"
              name="transmission"
              id="transmission"
              label="Select GPS"
              multiple
          ></v-select>
        </v-flex>
        <!-- <v-flex xs12>
          <v-textarea
            rows="2"
            name="note"
            label="Note"
            v-validate="'max:250'"
            v-model="form.note"
            id="note"
            :error="errors.has('trailer.note')"
            :error-messages="errors.first('trailer.note')"
            hint="maximum 250 characters"
          ></v-textarea>
        </v-flex>
        <v-flex xs12>
          <location-form @location-changed="handleLocationChange" :truck="this.form" is-form />
        </v-flex> -->
        <v-flex md2 xs3>
          <v-switch dense hide-details="auto" v-model="form.rent">
            <template v-slot:label>
              <span class="text">{{
                form.rent ? 'Lease' : 'Rented'
              }}</span>
            </template>
          </v-switch>
        </v-flex>
        <v-flex md2 xs2 mr-2>
          <v-switch
            dense
            small
            hide-details="auto"
            color="warning"
            v-model="form.broken"
          >
            <template v-slot:label>
              <span class="text">Broken truck</span>
            </template>
          </v-switch>
        </v-flex>
        <v-flex md3 xs3>
          <v-switch
            dense
            small
            hide-details="auto"
            color="error"
            v-model="form.total_damage"
          >
            <template v-slot:label>
              <span class="text">Total damage</span>
            </template>
          </v-switch>
        </v-flex>
        <!-- <v-flex md2 xs3>
          <v-switch
            dense
            small
            hide-details="auto"
            v-model="form.released"
          >
            <template v-slot:label>
              <span class="text">Released</span>
            </template>
          </v-switch>
        </v-flex> -->
        <!-- <v-flex md2 xs3>
          <v-checkbox
            v-model="form.is_active"
            label="Is active"
          ></v-checkbox>
        </v-flex> -->
      </v-layout>
    </v-container>
    <v-card-actions>
      <v-btn
        v-if="form._id"
        plain
        color="error"
        type="button"
        @click="deleteTruck()"
        :loading="loading"
        :disabled="loading || !canEdit"
      >Delete</v-btn>
      <v-btn
        v-if="form._id && !form.is_active && canDeletePermanent"
        color="success"
        @click="rollbackTruck()"
        :loading="loading"
        :disabled="loading || !canEdit || !canDeletePermanent"
      >Rollback</v-btn>
      <v-btn color="warning" @click="releaseTruck()">{{!form.released ? 'INACTIVE' : 'Return to trucks'}}</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
      <v-btn
        color="info"
        :loading="loading"
        :disabled="loading || !canEdit"
        type="submit"
        >{{ form._id ? 'Save' : 'Add' }}</v-btn
      >
    </v-card-actions>
  </form>
</template>

<script>
import truckService from '../../services/TruckService'
import callsService from '@/services/CallsService'
import debounce from 'lodash/debounce'

import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  name: 'TruckForm',
  props: ['truck', 'activeFilter'],
  data: () => {
    return {
      form: {
        _id: null,
        number: '',
        trailer: null,
        current_driver: null,
        current_trailer: null,
        division: '',
        yard: null,
        owner: null,
        start_date: null,
        start_date_second: null,
        make: '',
        model: '',
        year: new Date().getFullYear(),
        engine: '',
        color: '',
        transmission: 'Automatic',
        gps_type: '',
        mileage: 0,
        oil_change_date: null,
        vin_number: '',
        plate_number: '',
        aOwner: null,
        aOwnerNote: null,
        aStartDateNote: null,
        phone: '',
        info: '',
        note: '',
        noteNew: '',
        total_damage: false,
        broken: false,
        rent: false,
        on_the_road: false,
        lat: null,
        lng: null,
        is_active: true,
        is_third_party: false,
        released: false
      },
      menu: false,
      menuSecond: false,
      engines: ['Cummins', 'A26', 'D13', 'DD13', 'Paccar', 'DD15', 'Maxxforce', 'MP8', 'D16', 'DD16', 'D11', '/'],
      transmissionTypes: ['Automatic', 'Manual', '/'],
      gpsTypesItems: ['GEO', 'N2', 'SA', 'AZ'],
      drivers: [],
      truckMakes: [],
      truckModels: [],
      searchDrivers: '',
      thirdPartyItems: []
    }
  },
  computed: {
    ...mapGetters('common', ['loading', 'companiesAll']),
    ...mapGetters('auth', ['permissions']),
    yearsList() {
      let currentYear = parseInt(new Date().getFullYear() + 1)
      let years = []
      for (let i = currentYear; i >= currentYear - 35; i--) {
        years.push(i + '')
      }
      return years
    },
    startDateFormated() {
      return {
        first: this.form.start_date
          ? this.$options.filters.formatDateObj(this.form.start_date)
          : '',
        second: this.form.start_date_second
          ? this.$options.filters.formatDateObj(this.form.start_date_second)
          : ''
      }
    },
    thirdPartyDisabled() {
      return this.form.division && this.form.division.length ? true : false
    },
    canDeletePermanent() {
      return this.permissions.TRUCK_DELETE
    },
    canEdit() {
      return this.permissions.TRUCK_EDIT
    }
  },
  watch: {
    searchDrivers(val) {
      if (val) this.debounceGetDrivers(val, 'drivers')
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),
    onSubmitForm() {
      this.$validator.validateAll('truck').then((result) => {
        if (result) {
          this.$emit('submit', this.form)
        }
      })
    },
    handleLocationChange({ lat, lng, onTheRoad }) {
      this.form = {
        ...this.form,
        on_the_road: onTheRoad,
        lat,
        lng,
      }
    },
    resetForm() {
      this.form = {
        _id: null,
        number: '',
        trailer: null,
        current_driver: null,
        second_driver: null,
        current_trailer: null,
        division: '',
        yard: null,
        owner: null,
        start_date: '',
        start_date_second: '',
        make: '',
        model: '',
        year: '',
        engine: '',
        color: '',
        transmission: 'Automatic',
        mileage: 0,
        oil_change_date: null,
        vin_number: '',
        plate_number: '',
        note: '',
        aOwner: null,
        aOwnerNote: null,
        aStartDateNote: null,
        phone: '',
        info: '',
        noteNew: '',
        total_damage: false,
        broken: false,
        gps_type: '',
        rent: false,
        on_the_road: false,
        is_active: true,
        is_third_party: false,
        released: false
      }
    },
    closeForm() {
      this.$emit('close')
    },
    getMake() {
      this.$store.dispatch('trucks/getTruckMakeAll').then((data) => {
        if (data && data.length) {
          this.truckMakes = data
        }
      })
    },
    getModels() {
      if (!this.form.make) return
      this.$store
        .dispatch('trucks/getTruckModelsByMake', this.form.make._id)
        .then((data) => {
          if (data && data.length) {
            this.truckModels = data
          }
        })
    },
    getOwners() {
      this.$store.dispatch('trailers/getAllOwners').then((res) => {
        if (res.length) {
          this.thirdPartyItems = res.filter(owner => owner.is_company).map(owner => {
            return {
              id: owner._id,
              name: owner.name
            }
          })
        }
      })
    },
    deleteTruck() {
      let confVal = confirm(
        `Are you sure you want to delete truck: ${this.form.number}?`
      )
      if (!confVal) return

      // Obrisi zauvek ako ima permisu i ako je na Delete filteru
      if (this.canDeletePermanent && this.activeFilter === 'is_active') {
        truckService.deleteTruckPermanent(this.form._id)
          .then(() => {
            this.setMessage(`${this.form.number} successfully deleted`)
            this.closeForm()
          })
          .catch((err) => {
            console.log('err on delete permanent', err)
          })
      } else {
        truckService
          .deleteTruck(this.form._id)
          .then(() => {
            this.setMessage(`${this.form.number} successfully deleted`)
            this.closeForm()
          })
          .catch((err) => {
            console.log('err on safe delete', err)
          })
      }
    },
    rollbackTruck() {
      let confVal = confirm(
        `Are you sure you want to rollback truck: ${this.form.number}?`
      )
      if (!confVal) return

      truckService
        .rollbackTruck(this.form._id)
        .then(() => {
          this.setMessage(`${this.form.number} successfully returned from deleted`)
          this.closeForm()
        })
        .catch((err) => {
          console.log('err on rollback truck', err)
        })
    },
    debounceGetDrivers: debounce(function(val, item) {
      if (val && val.length) {
        callsService
          .getMergedDrivers(val, true)
          .then((res) => {
            if (res.data.status === 'OK') {
              this[item] = res.data.drivers.map((item) => {
                const tmpItem = {
                  id: item.id,
                  name: item.name,
                  phone_number: item.phone_number,
                  trailer: item.trailer
                }
                return tmpItem
              })
            }
          })
          .catch((err) => {
            console.log('drivers error search', err)
            this[item] = []
          })
      } else {
        this[item] = []
      }
    }, 400),
    clientFilter(item, queryText) {
      return (
        item.name && item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1
      )
    },
    onClearDriver() {
      this.form.current_driver = null
      this.form.current_trailer = null
      this.form.phone = ''
    },
    onChangeThirdParty(value) {
      if (value) {
        this.form.current_driver = null
        this.form.second_driver = null
      } else {
        this.form.owner = null
      }
    },
    releaseTruck() {
      if (this.form._id) {
        let confVal = confirm(
          `Are you sure you want to ${this.form.released ? 'return to trucks' : 'release'} this truck: ${this.form.number}?`
        )
        if (!confVal) return
        this.$store.dispatch('common/setLoading', true)
        truckService.releaseTruck(this.form._id, !this.form.released).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.status === 200) {
            this.setMessage(`Truck: ${this.form.number} successfully released`)
            this.closeForm()
          }
        }).catch(err => {
          console.log('err on release', err)
        })
      } else {
        this.form.released = !this.form.released
      }
    }
  },
  mounted() {
    this.getMake()
    this.getOwners()

    if (this.truck) this.form = {...this.truck}

    if (this.form._id) {
      this.getModels()

      if (this.truck.current_driver) {
        this.drivers.push(this.truck.current_driver)
      }
    }
  }
}
</script>