import Api from '@/services/Api'

export default {
  getPermitBooks(options){
    return Api().get('/permit-books', {
      params:{
        page: options.page,
        perPage: options.itemsPerPage,
        type:options.type,
        trailer:options.trailer,
        truck:options.truck,
        driver:options.driver,
        company:options.company
      }
    })
  },
  getAllPermitBooks () {
    return Api().get('/permit-books/all')
  },
  getPermitBookById (id, urlType) {
    return Api().get(`/permit-books/presigned/${id}`, {
      params: { urlType }
    })
  },
  editPermitBook (permitBookId, data) {
    return Api().put(`/permit-books/${permitBookId}`, data)
  },
  addPermitBook (data) {
    return Api().post('/permit-books', data)
  },
  deletePermitBook (permitBookId) {
    return Api().delete(`/permit-books/${permitBookId}`)
  },
  getSinglePermitBook(permitBookId){
    return Api().get(`/permit-books/${permitBookId}`)
  },
  getPermitBooksByDriver (userId, dateExpiredFilter) {
    return Api().get(`/permit-books/get-documents-for-driver/${userId}`,{
      params: {
        dateExpiredFilter: dateExpiredFilter
      }
    })
  },
  getPermitBookDrivers(filters) {
    return Api().get('/permit-books/by-driver', {params: filters})
  },
  getPermitBookByReferrer(type, id) {
    return Api().get(`/permit-books/by-referrer/${type}/${id}`)
  }
}