<template>
  <div class="flex">
    <template v-if="!eldSearchActivated">
      <v-layout justify-space-between>
        <div>
          <v-btn
            class="mr-2"
            icon
            small
            @click="getData"
          >
            <v-icon>mdi-cached</v-icon>
          </v-btn>
          <v-badge
            v-if="dotInspections.length"
            :content="dotInspections.length"
            :value="dotInspections.length"
            bordered
            color="error"
            overlap
          >
            <v-btn
              small
              color="error"
              @click="sheet = !sheet"
            >
              DOT
            </v-btn>
          </v-badge>
        </div>
        <div>
          <p class="date-from-to subtitle-2">{{todayDate | formatDateObj('DD.MMM')}}</p>
        </div>
      </v-layout>
      <v-layout>
        <v-progress-circular
          v-if="loading"
          indeterminate
          class="dashboard-loader"
          color="primary"
        ></v-progress-circular>
      </v-layout>
      <div class="table-container-eld" v-if="data && data.users.length" :class="{loading: loading}">
        <table class="main-table-eld" :class="{dark: dark}">
          <thead>
            <tr>
              <th>User</th>
              <th>Ext</th>
              <th class="separator"></th>
              <th v-for="index in cellsNumber" :key="`th-${index}`" class="call">
                {{index}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in data.users" :key="`main-${user._id}`">
              <td>{{user.name}}</td>
              <td>{{user.ext ? user.ext : '-'}}</td>
              <td class="separator"></td>
              <template v-for="index in cellsNumber">
                <Call v-if="user.calls[index]"
                  @click="openEditDialog(user.calls[index]._id, user.calls[index].shift_id, user.calls[index].user_id)"
                  :key="`call-${user.calls[index]._id}-${user.calls[index].position}`"
                  :position="user.calls[index].position"
                  :call="user.calls[index]"
                  :shiftId="user.calls[index].shift_id"
                >
                </Call>
                <td
                  v-else
                  class="call"
                  :key="`td-${index}`"
                  @drop='onDrop($event, index, user._id, user.name)'
                  @dragover.prevent
                  @dragenter.prevent
                  @contextmenu.prevent="openDialog(user._id, index, user.name)"
                  :data-index="index">
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="locked-trucks" v-if="locked.length">
        <p class="subtitle-2 mb-1">locked trucks:</p>
        <v-chip
          v-for="truck in locked"
          :key="`lock-truck-${truck}`"
          label
          close-icon="mdi-delete"
          :close="permissions.ELD_REMOVE_LOCKED_TRUCK"
          @click:close="removeLockedTrucks(truck)"
          class="mr-2"
          color="warning"
          small
          text-color="white"
        >
          <v-icon small left>
            mdi-lock
          </v-icon>
          {{truck}}
        </v-chip>
      </div>
      <div class="unfinished-calls" v-if="unfinishedCalls.length">
        <p class="subtitle-2 mb-1">unfinished calls:</p>
        <v-chip
          v-for="call in unfinishedCalls"
          :key="call._id"
          label
          class="mr-2 mb-1"
          :class="{default: call.callType && call.callType.type === 'DEFAULT'}"
          :color="call.callType && call.callType.color"
          text-color="white"
          :draggable="permissions.ELD_MOVE_CALL"
          :title="call.callType && call.callType.type"
          @dragstart="startDragUnfinished($event, call)"
        >
          <v-icon small left>
            mdi-drag
          </v-icon>
          {{call.truck}}
        </v-chip>
      </div>
      <template v-if="permissions.ELD_ARCHIVE">
        <v-layout class="mt-6" justify-space-between>
          <div>
            <v-btn
              :disabled="selectedShift === null"
              depressed
              small
              @click="goToday"
              :loading="archiveLoading"
            >
              Today
            </v-btn>
          </div>
          <div v-if="layout !== 'mobile'">
            <p class="date-from-to subtitle-2">Archive from {{startOfArchive | formatDateObj('DD.MMM')}} to {{endOfArchive | formatDateObj('DD.MMM')}}</p>
          </div>
          <div class="align-right">
            <v-btn
              :disabled="selectedShift === null || archiveLoading"
              depressed
              color="primary"
              class="mr-2"
              small
              @click="goPrev"
              :loading="archiveLoading"
            >
              <v-icon left> mdi-arrow-left </v-icon>
              {{layout !== 'mobile' ? 'Prev' : ''}}
            </v-btn>
            <v-btn
              :disabled="selectedShift === null || archiveLoading || startDate.format() === startDateCheck.format()"
              depressed
              color="primary"
              small
              @click="goNext"
              :loading="archiveLoading"
            >
              {{layout !== 'mobile' ? 'Next' : ''}}
              <v-icon right> mdi-arrow-right </v-icon>
            </v-btn>
          </div>
        </v-layout>
        <div v-if="archive && Object.keys(archive).length">
          <div class="table-container-archive" v-for="(day, key, index) in archive" :key="`day-${index}`">
            <p class="day-info-text h6">Day: {{key}}</p>
            <table class="main-table-eld" :class="{dark: dark}">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Ext</th>
                  <th class="separator"></th>
                  <th v-for="index in cellsNumber" :key="`th-${index}`" class="call">
                    {{index}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in day.users" :key="user._id">
                  <td>{{user.name}}</td>
                  <td>{{user.ext ? user.ext : '-'}}</td>
                  <td class="separator"></td>
                  <template v-for="index in cellsNumber">
                    <Call v-if="user.calls[index]"
                      :key="`call-${user.calls[index]._id}-${user.calls[index].position}`"
                      :position="user.calls[index].position"
                      :call="user.calls[index]"
                      :shiftId="user.calls[index].shift_id"
                      :disableCommentsInput="true"
                      :noMove="true"
                      :noHoverComments="true"
                      :showCommentsNumber="true"
                    >
                    </Call>
                    <td
                      v-else
                      class="call"
                      :key="`td-${index}`"
                      :data-index="index">
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <p class="subtitle-1" style="text-align: center;">There is no archive for the selected dates</p>
        </div>
      </template>
      <v-bottom-sheet v-model="sheet">
        <v-sheet
          class="text-center"
          height="350px"
          scrollable
        >
          <v-simple-table :key="`dot-table-${dotInspections.length}`" dense fixed-header height="330px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    Truck
                  </th>
                  <th class="text-center">
                    Name
                  </th>
                  <th class="text-center">
                    Origin
                  </th>
                  <th class="text-center">
                    Destination
                  </th>
                  <th class="text-center">
                    Location
                  </th>
                  <th class="text-center">
                    Date
                  </th>
                  <th class="text-center">
                    Start time
                  </th>
                  <th class="text-center">
                    End time
                  </th>
                  <th class="text-center">
                    Zone
                  </th>
                  <th class="text-center">
                    OOS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="dot in dotInspections"
                  :key="`dot-${dot._id}`"
                >
                  <td class="text-left" style="width: 100px;">
                    <strong>{{ dot.truck.truck_id }}</strong>
                    <v-tooltip v-if="dot.note && dot.note !== ''" right max-width="350">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="note-icon" v-bind="attrs" v-on="on" right> mdi-comment-text-outline </v-icon> 
                      </template>
                      <span style="white-space: pre-wrap;">{{ dot.note }}</span>
                    </v-tooltip>
                  </td>
                  <td class="text-left">{{ dot.truck.name }}</td>
                  <td class="text-left">{{ dot.origin }}</td>
                  <td class="text-left">{{ dot.destination }}</td>
                  <td class="text-left">{{ dot.location }}</td>
                  <td>{{ dot.dot_inspection_date }}</td>
                  <td>{{ dot.start_date }}</td>
                  <td>{{ dot.end_date }}</td>
                  <td>{{ dot.time_zone.name }}</td>
                  <td>{{ dot.out_of_service ? 'YES' : 'NO'}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </template>
    <template v-else>
      <div v-if="searchData && searchData.docs.length" class="search-data">
        <v-hover v-for="call in searchData.docs" :key="`call-search-${call._id}`">
          <template v-slot:default="{ hover }">
            <div @click="openEditDialog(call._id, call.shift_id, call.user_id._id)" class="search-call-item elevation-6 rounded" :style="{backgroundColor: call.callType.color}" :class="{hover: hover}">
              <div class="top">
                <span class="title" :title="call.callType.type">{{call.truck}}</span>
                <span class="name text-subtitle-2">{{call.user_id.name}}</span>
              </div>
              <div class="bottom">
                <span class="date text-caption align-right">{{call.created | formatDateObj('MM/DD/YYYY HH:MM')}}</span>
              </div>
            </div>
          </template>
        </v-hover>
        <div class="loadmore-btn" v-if="searchData.pages > searchData.page">
          <v-btn
            class="mt-6"
            :loading="loading"
            :disabled="loading"
            color="primary"
            @click="loadMoreSearch"
          >
            Load more {{restNumberOfCalls ? `(${restNumberOfCalls})` : ''}}
          </v-btn>
        </div>
      </div>
    </template>

    <v-dialog v-model="callDialog" max-width="550px" @click:outside="onCloseDialogForm">
      <v-card>
        <v-card-title>
          <span class="headline">{{dialogForm._id !== null ? 'Edit call' : 'Start call'}}</span>
        </v-card-title>
        <form @submit.prevent="onSubmit">
          <v-container>
            <v-row>
              <div class="pl-4 pr-4" style="width: 100%;" v-if="checkDotInspections">
                <v-alert dense outlined type="warning" transition="scale-transition">
                  The selected truck is in <strong>DOT inspections</strong>
                  <v-tooltip right max-width="350">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="info-icon" v-bind="attrs" v-on="on" right> mdi-information-outline </v-icon> 
                    </template>
                    <span style="white-space: pre-wrap;">
                      truck: {{checkDotInspections.truck.truck_id}}<br/>
                      driver: {{checkDotInspections.truck.name}}<br/>
                      <template v-if="checkDotInspections.note && checkDotInspections.note !== ''"> note: {{ checkDotInspections.note }} </template>
                      </span>
                  </v-tooltip>
                </v-alert>
              </div>
              <v-col cols="12">
                <v-autocomplete
                  :disabled="dialogForm._id !== null && !permissions.ELD_EDIT_DELETE_CALL"
                  v-model="dialogForm.truck"
                  :items="trucksListFormated"
                  return-object
                  item-text="truck_id"
                  item-disabled="disabled"
                  label="Truck*"
                  :search-input.sync="searchDrivers"
                  hide-details="auto"
                  :hint="dialogForm.truck && dialogForm.truck.is_external ? 'External truck' : ''"
                  persistent-hint
                  hide-no-data
                  :loading="loading"
                  placeholder="Start typing to Search"
                  prepend-icon="mdi-database-search"
                  :filter="clientFilter"
                  @input="onTruckInput"
                  :autofocus="!dialogForm._id ? true : false"
                  clearable
                >
                  <template v-slot:item="{item}">
                    <span :class="{isExternal: item.is_external}">
                      {{`${item.truck_id} - ${item.name}`}}
                      <span v-if="item.is_external">external</span>
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="latestLoads.length">
                <div class="latest-loads">
                  <LoadChip v-for="load in latestLoads" :key="`load-${load._id}`" :load="load" />
                </div>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :disabled="!permissions.ELD_EDIT_TYPE_CALL"
                  v-model="dialogForm.callType"
                  :items="callTypes"
                  item-text="type"
                  return-object
                  label="Call type*"
                  :hide-details="true"
                  :loading="loading"
                >
                  <template v-slot:selection="data">
                    <span disabled="true" :style="{color: data.item.type !== 'DEFAULT' ? data.item.color : '#000'}">{{data.item.type}}</span>
                  </template>
                  <template v-slot:item="data">
                    <span :style="{color: data.item.type !== 'DEFAULT' ? data.item.color : '#000'}">{{data.item.type}}</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                  <v-card>
                    <v-col cols="12">
                      <mention-input
                        @onEnter="handleEnter($event)"
                        ref="comtext"
                        outlined
                        name="comment"
                        label="Comment"
                        counter
                        hide-details="auto"
                        v-model="dialogForm.commentText"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-btn :loading="loading" :disabled="!permissions.ELD_ADD_COMMENT || dialogForm.commentText === '' || dialogForm.commentText.length > 500" @click="addComment" color="success" small>
                        {{editCommentId === null ? 'Add comment' : 'Edit comment'}}
                      </v-btn>
                      <v-btn @click="cancelCommentEdit" class="ml-3" small v-if="editCommentId">
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="dialogForm.comments.length">
                      <Comments @edit="onEditComment" :comments="dialogForm.comments" :callId="dialogForm._id" :showActions="true" :key="`comments-${dialogForm.comments.length}`"/>
                    </v-col>
                  </v-card>
                </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-btn
              v-if="permissions.ELD_EDIT_DELETE_CALL && dialogForm._id"
              :loading="loading"
              color="error"
              @click="onDeleteCall(dialogForm._id)"
            >
              Delete
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="onCloseDialogForm"
            >
              Close
            </v-btn>
            <v-btn
              type="submit"
              color="info"
              :loading="loading"
              :disabled="dialogForm.commentText.length > 500 || !dialogForm.truck || dialogForm.truck === null || dialogForm.truck === ''"
            >
              {{dialogForm._id ? 'Edit' : 'Start'}}
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment-timezone'
import callsService from '../../services/CallsService'
import MentionInput from '../Common/MentionInput'
import Comments from './../Common/Comments'
import Call from './Call'
import LoadChip from './LoadChip'
import debounce from 'lodash/debounce'

export default {
  name: 'EldDashboard',
  components: {
    MentionInput,
    Comments,
    Call,
    LoadChip
  },
  data() {
    return {
      dialogForm: {
        _id: null,
        truck: null,
        callType: null,
        position: null,
        user_id: null,
        user_name: '',
        shift_id: null,
        commentText: '',
        comments: []
      },
      callTypes: [],
      defaultCallType: null,
      trucks: [],
      searchDrivers: '',
      layout: 'desktop',
      todayDate: new Date(),
      callDialog: false,
      editCommentId: null,
      data: null,
      archive: null,
      cellsNumber: 60,
      usersMap: null,
      latestLoads: [],
      archiveLoading: false,
      startDate: moment(new Date()).set({hour:2,minute:-1,second:0,millisecond:0}),
      startDateCheck: moment(new Date()).set({hour:2,minute:0,second:0,millisecond:0}),
      sheet: false,
      searchData: null,
      restNumberOfCalls: 0,
      unfinishedCalls: []
    }
  },
  computed: {
    ...mapGetters('auth', ['isSuperAdmin', 'user', 'permissions']),
    ...mapGetters('common', ['loading', 'userColor', 'dark']),
    ...mapGetters('eldDashboard', ['selectedShift', 'dotInspections', 'locked', 'eldSearchActivated', 'search']),
    trucksListFormated() {
      return this.trucks.map(item => {
        item.disabled = false
        if (this.locked.indexOf(item.truck_id) !== -1) {
          item.disabled = true
        }
        item.text = `${item.truck_id} - ${item.name}`
        return item
      })
    },
    startOfArchive () {
      return this.startDate
    },
    endOfArchive () {
      return moment(this.startDate).subtract(7,'d').set({hour:2,minute:-1,second:0,millisecond:0})
    },
    checkDotInspections () {
      if (this.dialogForm.truck) {
        let index = this.dotInspections.findIndex(dot => dot.truck.truck_id === this.dialogForm.truck.truck_id || dot.truck.truck_id === this.dialogForm.truck)
        if (index !== -1) {
          let today = new Date()
          let before14Days = new Date()
          before14Days.setDate(today.getDate() - 14)
          let created = new Date(this.dotInspections[index].createdAt)
          if (created < before14Days) return false
          return this.dotInspections[index]
        }
        return false
      }
      return false
    }
  },
  watch: {
    selectedShift: function(newVal, oldVal) {
      if (oldVal) {
        this.$socket.client.emit('leave_shift', oldVal)
      }
      if (newVal !== oldVal && newVal !== null && oldVal !== null) {
        this.getData()
        this.getUnfinished()
        this.$socket.client.emit('joinToShift', newVal)

        if (this.permissions.ELD_ARCHIVE) {
          this.getArchive()
        }
      }
    },
    search: {
      immediate: false,
      deep: true,
      handler: 'searchCalls'
    },
    searchDrivers (val) {
      if (val) {
        this.debounceSearch(val)
      }
    }
  },
  sockets: {
    connect() {
      if (this.selectedShift) {
        this.$socket.client.emit('joinToShift', this.selectedShift)
      }
      this.$socket.client.emit('joinToLockedTrucks')
      this.$socket.client.emit('joinDotInspections')
    },
    newCall(call) {
      let index = this.usersMap[call.user_id]
      if (this.data.users[index]) {
        this.$set(this.data.users[index].calls, call.position, call)
      }
    },
    editCall(data) {
      let index = this.usersMap[data.call.user_id]
      if (this.data.users[index]) {
        this.$set(this.data.users[index].calls, data.call.position, data.call)
        if (this.data.users[index].calls[data.call.position]._id === this.dialogForm._id) {
          if (data.onlyComment) {
            this.dialogForm.comments = this.data.users[index].calls[data.call.position].comments
          } else {
            this.dialogForm = {...this.dialogForm, ...this.data.users[index].calls[data.call.position]}
          }
        }
      }
    },
    moveCall(data) {
      this.getUnfinished()
      let indexNewUser = this.usersMap[data.newCall.user_id]
      let indexOldUser = this.usersMap[data.oldCall.user_id]
      if (this.data.users[indexOldUser] && this.data.users[indexNewUser]) {
        setTimeout(() => {
          this.$delete(this.data.users[indexOldUser].calls, data.oldCall.position)
        }, 200)
        this.$set(this.data.users[indexNewUser].calls, data.newCall.position, data.newCall)
      } else if (this.data.users[indexNewUser]) {
        this.$set(this.data.users[indexNewUser].calls, data.newCall.position, data.newCall)
      }
    },
    deleteCall(data) {
      let index = this.usersMap[data.user_id]
      if (this.data.users[index]) {
        this.$delete(this.data.users[index].calls, data.position)
      }
    },
    locked(data) {
      this.$store.dispatch('eldDashboard/setLocked', data)
    },
    newDot(dot) {
      this.$store.dispatch('eldDashboard/addDot', dot)
    },
    editDot(dot) {
      this.$store.dispatch('eldDashboard/editDot', dot)
    },
    deleteDot(dot) {
      this.$store.dispatch('eldDashboard/deliteDot', dot)
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    getWindowWidth() {
      let windowWidth = document.documentElement.clientWidth
      if (windowWidth <= 992) {
        this.layout = 'mobile'
      } else if (windowWidth <= 1627) {
        this.layout = 'laptop'
      } else {
        this.layout = 'desktop'
      }
    },
    getData() {
      this.$store.dispatch('common/setLoading', true)
      callsService.getDashboardData({shift_id: this.selectedShift}).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          this.data = res.data.shift
          this.usersMap = res.data.usersMap
        }
      })
    },
    getUnfinished() {
      callsService.getUnfinished(this.selectedShift).then(res => {
        if (res.data.status === 'OK') {
          this.unfinishedCalls = res.data.calls
        }
      })
    },
    getArchive() {
      this.archiveLoading = true
      callsService.getArchiveData({shift_id: this.selectedShift, from: this.startOfArchive, to: this.endOfArchive}).then(res => {
        this.archiveLoading = false
        if (res.data.status === 'OK') {
          this.archive = res.data.archive
        }
      })
    },
    openDialog(userId, index, userName) {
      if (userId === this.user._id || this.permissions.ELD_ADD_CALL) {
        this.dialogForm.position = index
        this.dialogForm.user_id = userId
        this.dialogForm.user_name = userName
        this.dialogForm.shift_id = this.selectedShift
        this.callDialog = true
      }
    },
    openEditDialog (callId, shift_id, userId) {
      if (userId === this.user._id || this.permissions.ELD_EDIT_DELETE_CALL) {
        callsService.getCall(callId).then(res => {
          this.dialogForm.shift_id = shift_id
          this.dialogForm._id = res.data._id
          this.dialogForm.truck = res.data.truck
          this.dialogForm.callType = res.data.callType

          this.dialogForm.commentText = ''
          this.dialogForm.comments = res.data.comments
          this.dialogForm.user_id = res.data.user_id
          this.dialogForm.user_name = res.data.user_name ? res.data.user_name : ''
          this.callDialog = true

          
          this.$store.dispatch('common/setLoading', true)
          callsService.getMergedDrivers(this.dialogForm.truck).then(res => {
            if (res.data.status === 'OK') {
              this.trucks = res.data.drivers
              if (res.data.drivers[0]) {
                callsService.getLatestLoadsForDrivers(res.data.drivers[0].id).then(res => {
                  this.$store.dispatch('common/setLoading', false)
                  if (res.data.status === 'OK') {
                    this.latestLoads = res.data.loads
                  }
                })
              } else {
                this.$store.dispatch('common/setLoading', false)
              }
            }
          }).catch(err => {
            console.log('trucks error search', err)
            this.trucks = []
          })
        })
      }
    },
    onCloseDialogForm() {
      this.callDialog = false
      this.resetForm()
    },
    onSubmit() {
      this.$store.dispatch('common/setLoading', true)

      if (this.dialogForm._id !== null) {
        callsService.editCall(this.dialogForm).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.setMessage(`Call '${res.data.call.truck}' successfully edited`)
            this.onCloseDialogForm()
          }
        })
      } else {
        callsService.addCall(this.dialogForm).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.setMessage(`Call '${res.data.call.truck}' successfully started`)
            this.onCloseDialogForm()
          } else if (res.data.status === 'ERROR' && res.data.message === 'LOCKED_TRUCK') {
            this.setMessage(`Truck: '${res.data.truck}' is locked!`)
          }
        })
      }
    },
    onDeleteCall(id) {
      let confVal = confirm('Are you sure you want to delete call?')
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        callsService.deleteCall(id).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.onCloseDialogForm()
            this.setMessage('Call successfully deleted')
          }
        })
      }
    },
    resetForm() {
      this.dialogForm = {
        _id: null,
        truck: '',
        callType: this.defaultCallType,
        position: null,
        user_id: null,
        user_name: '',
        shift_id: this.selectedShift,
        commentText: '',
        comments: []
      }
      this.latestLoads = []
    },
    addComment () {
      let comment = {
        text: this.dialogForm.commentText.trim(),
        user_email: this.user.email,
        user_name: this.user.name
      }
      if (this.dialogForm._id === null) {
        this.dialogForm.comments.push(comment)
        this.dialogForm.commentText = ''
      } else {
        this.$store.dispatch('common/setLoading', true)
        if (this.editCommentId !== null) {
          comment['_id'] = this.editCommentId
          callsService.editComment(this.dialogForm._id, comment, this.dialogForm.shift_id).then(res => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data.status === 'OK') {
              this.editCommentId = null
              this.dialogForm.commentText = ''
              this.setMessage('Comment successfully edited')
            }
          })
        } else {
          callsService.addComment(this.dialogForm._id, comment, this.dialogForm.shift_id).then(res => {
            this.$store.dispatch('common/setLoading', false)
            if (res.data.status === 'OK') {
              this.dialogForm.commentText = ''
              this.setMessage('Comment successfully added')
            }
          })
        }
      }
    },
    handleEnter (e) {
      if (e.ctrlKey && this.permissions.ELD_ADD_COMMENT) {
        this.addComment()
      }
    },
    onEditComment (comment) {
      this.editCommentId = comment._id
      this.dialogForm.commentText = comment.text
      this.$refs.comtext.$refs.minput.$refs.input.focus()
    },
    cancelCommentEdit () {
      this.editCommentId = null
      this.dialogForm.commentText = ''
    },
    onDrop (evt, position, userId, userName) {
      let dragdata = evt.dataTransfer.getData('dragdata')
      dragdata = JSON.parse(dragdata)

      const callObj = dragdata.call
      const callObjNew = {...dragdata.call}
      let confVal = confirm('Are you sure you want to move call?')
      if (confVal) {
        callObjNew.shift_id = this.selectedShift
        callObjNew.user_id = userId
        callObjNew.user_name = userName
        callObjNew.position = position
        this.$store.dispatch('common/setLoading', true)
        callsService.moveCall(callObj, callObjNew).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.setMessage('Call successfully moved')
          }
        })
      }
    },
    debounceSearch: debounce( function(search) {
      if (search && search.length) {
        this.$store.dispatch('common/setLoading', true)
        callsService.getMergedDrivers(search).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.trucks = res.data.drivers
          }
        }).catch(err => {
          console.log('trucks error search', err)
          this.trucks = []
        })
      } else {
        this.trucks = []
      }
    }, 400),
    clientFilter (item, queryText) {
      return item.text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    onTruckInput () {
      if (this.dialogForm.truck && this.dialogForm.truck.id) {
        callsService.getLatestLoadsForDrivers(this.dialogForm.truck.id).then(res => {
          if (res.data.status === 'OK') {
            this.latestLoads = res.data.loads
          }
        })
      }
    },
    removeLockedTrucks(truck) {
      let confVal = confirm('Are you sure you want to delete locked truck?')
      if (confVal) {
        this.$store.dispatch('common/setLoading', true)
        callsService.deleteLockedTruck(truck).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.setMessage('Locked truck successfully deleted')
          }
        })
      }
    },
    goPrev () {
      this.startDate = moment(this.startDate).subtract(7, 'd').set({hour:2,minute:-1,second:0,millisecond:0})
      if (this.permissions.ELD_ARCHIVE) {
        this.getArchive()
      }
    },
    goNext () {
      this.startDate = moment(this.startDate).add(7, 'd').set({hour:2,minute:-1,second:0,millisecond:0})
      if (this.permissions.ELD_ARCHIVE) {
        this.getArchive()
      }
    },
    goToday () {
      this.startDate = moment(new Date()).set({hour:2,minute:-1,second:0,millisecond:0})
      if (this.permissions.ELD_ARCHIVE) {
        this.getArchive()
      }
    },
    searchCalls () {
      this.$store.dispatch('common/setLoading', true)
      callsService.searchCalls(this.search).then(res => {
        this.$store.dispatch('common/setLoading', false)
        this.searchData = res.data
        this.restNumberOfCalls = res.data.total - res.data.limit
      })
    },
    loadMoreSearch () {
      this.$store.dispatch('common/setLoading', true)
      callsService.searchCalls({...this.search, page: this.searchData.page + 1}).then(res => {
        this.$store.dispatch('common/setLoading', false)
        this.searchData.page = res.data.page
        this.restNumberOfCalls -= res.data.limit
        this.searchData.docs = [...this.searchData.docs, ...res.data.docs]
      })
    },
    startDragUnfinished (evt, call) {
      this.showComments = false
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('dragdata', JSON.stringify({call: call}))
    }
  },
  created() {
    this.getWindowWidth()
  },
  mounted() {
    if (this.selectedShift) {
      this.getData()
      this.$socket.client.emit('joinToShift', this.selectedShift)
      if (this.permissions.ELD_ARCHIVE) {
        this.getArchive()
      }
    } else if (this.user.shift_id) {
      this.$store.dispatch('eldDashboard/setShiftId', this.user.shift_id)
      this.$socket.client.emit('joinToShift', this.user.shift_id)
      this.getData()
    } else {
      this.$store.dispatch('shifts/getAllShifts').then(res => {
        let userZone = 'Europe/Budapest'
        let date = moment().tz(userZone)
        let tmpHour = date.hour()
        let shiftIndex = 0
        if (tmpHour >= 6 && tmpHour <= 13) {
          // prva smena
          shiftIndex = 0
        }else if (tmpHour >= 14 && tmpHour <= 21) {
          // druga smena
          shiftIndex = 1
        } else if (tmpHour >= 22 || tmpHour <= 5) {
          // treca smena
          shiftIndex = 2
        }

        if (res[shiftIndex]._id) {
          this.$socket.client.emit('joinToShift', res[shiftIndex]._id)
          this.$store.dispatch('eldDashboard/setShiftId', res[shiftIndex]._id)
          this.getData()

          if (this.permissions.ELD_ARCHIVE) {
            this.getUnfinished()
            this.getArchive()
          }
        }
      })
    }

    if (this.permissions) {
      this.$store.dispatch('callTypes/getAllCallTypes').then(res => {
        this.callTypes = res
        this.dialogForm.callType = res.find(el => el.type === 'DEFAULT')
        this.defaultCallType = res.find(el => el.type === 'DEFAULT')
      })

      this.$store.dispatch('eldDashboard/fetchAllDotInspections')
    }

    callsService.getLockedTrucks().then(res => {
      if (res.data.status === 'OK') {
        this.$store.dispatch('eldDashboard/setLocked', res.data.locked)
      }
    })

    // this.$socket.client.emit('joinToLockedTrucks')
    // this.$socket.client.emit('joinDotInspections')
  },
  beforeDestroy() {
    this.$socket.client.emit('leave_shift', this.selectedShift)
    this.$socket.client.emit('leaveLockedTrucks')
    this.$socket.client.emit('leaveDotInspections')
  }
}
</script>

<style lang="scss">
.dashboard-loader {
  position: fixed;
  z-index: 100;
  left: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}

.locked-trucks {
  margin-top: 12px;
  margin-bottom: 12px;
}

.unfinished-calls {

  .v-chip {
    cursor: move;
    &.default {
      background-color: #3f51b5 !important
    }
  }
}

.info-icon {
  color: #ff5252 !important;
}

.isExternal {
  span {
    position: absolute;
    left: 16px;
    bottom: 0px;
    font-size: 11px;
    color: #ff5252 !important;
  }
}

.table-container-eld {
  overflow: scroll;
  // max-width: 100%;
  width: 100%;
  min-height: 500px;
  max-height: calc(100vh - 145px);
  position: relative;
  // margin-right: 12px;
  // margin-left: 12px;

  &.loading {
    opacity: 0.7;
  }
}

.table-container-archive {
  width: 100%;
  position: relative;
  margin-top: 12px;
  overflow: scroll;

  &.loading {
    opacity: 0.7;
  }

  .day-info-text {
    text-align: center;
    font-weight: bold;
  }
}

.main-table-eld {
    border-collapse: collapse;
    min-width: 400px;
    width: 100%;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;

    &.dark {
      th {
        background-color: #43a047;
      }

      tbody tr td:nth-child(-n+2) {
        background-color: #121212;
      }

      tbody tr {
        &:hover {
          color: #000;
        }
      }
    }

    th {
      padding: 5px 5px;
      border-left: 1px solid #A9A9A9;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      background-color: #1E88E5FF;
      color: #ffffff;
      text-align: center;
      top: 0;
      z-index: 3;
      font-size: 13px;

      &:nth-child(1) {
        left: 0px;
        border-left: 1px solid #A9A9A9;
        min-width: 150px;
        z-index: 4;
      }

      &:nth-child(2) {
        left: 150px;
        border-left: 1px solid #A9A9A9;
        min-width: 60px;
        z-index: 4;
      }

      &.separator {
        left: 210px;
        z-index: 4;
        background-color: #ffee58;
        border-bottom: none;
        border-top: none;
        min-width: 3px;
        padding: 0px;
        box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
        -webkit-box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: lightyellow !important;

          td:nth-child(-n+2) {
            background-color: lightyellow !important;
            color: red;
          }
        }

        td:nth-child(-n+2) {
          background-color: #fff;
        }

        td {
          padding: 7px 5px;
          font-size: 12px;
          border-left: 1px solid #A9A9A9;
          border-bottom: 1px solid #A9A9A9;
          white-space: nowrap;
          position: relative;
          min-width: 60px;

          &:nth-child(1) {
            position: sticky;
            position: -webkit-sticky; /* for Safari */
            z-index: 4;
            left: 0;
            border-left: 1px solid #A9A9A9;
            font-weight: 600;
            width: 150px;
          }

          &:nth-child(2) {
            text-align: center;
            position: sticky;
            position: -webkit-sticky; /* for Safari */
            z-index: 4;
            left: 150px;
            border-left: 1px solid #A9A9A9;
            font-weight: 600;
            width: 60px;
          }

          &.call {
            text-align: center;
            cursor: pointer;

            &.bold {
              font-weight: 600;
              font-size: 14px;
            }

            &.drag {
              cursor: move;
            }
          }

          &.separator {
            position: sticky;
            position: -webkit-sticky; /* for Safari */
            z-index: 4;
            left: 210px;
            background-color: #ffee58;
            border-bottom: none;
            border-top: none;
            width: 3px;
            min-width: 3px;
            padding: 0px;
            z-index: 4;
            box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
            -webkit-box-shadow: inset -3px 0 4px -3px rgba(0,0,0,0.30);
          }

          .comments-number {
            position: absolute;
            font-size: 10px;
            top: 0px;
            right: 1px;
            color: #3f51b5;
            text-shadow: 0px 0px 10px rgb(63 81 181 / 70%);
          }
        }
      }
    }
}

.search-data {
  display: flex;
  flex-wrap: wrap;

  .loadmore-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .search-call-item {
    width: 200px;
    padding: 8px;
    margin-right: 8px;
    margin-top: 8px;
    cursor: pointer;

    &.hover {
      box-shadow: 0px 10px 13px -6px rgb(0 0 0 / 20%), 0px 20px 31px 3px rgb(0 0 0 / 14%), 0px 8px 38px 7px rgb(0 0 0 / 12%) !important;
    }

    .top {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 600;
      }
    }
  }
}
</style>