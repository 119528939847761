<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div class="history-holder" @click="on.click" style="cursor: pointer">
        <slot></slot>
      </div>
    </template>
    <v-card>
      <div style="min-width: 400px; min-height: 200px">
        <truck-history v-if="menu" :truck-id="truckId" :type="type" />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import TruckHistory from './TruckTypeHistory.vue'

export default {
  name: 'FieldWithHistory',

  components: { TruckHistory },

  props: {
    truckId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
    }
  },
}
</script>

<style>
.history-holder {
  min-width: 50px;
  min-height: 20px;
}
</style>
