<template>
  <div class="registration-fields d-flex" v-if="bobtailConfig">
    <v-select
      class="mr-1"
      v-model="form.type"
      :items="bobtailConfig.options"
      label="Bobtail Insurance"
      hide-details
      small
      @change="emitFormData"
    />
    <v-text-field
      v-if="bobtailConfig.additionalFields && bobtailConfig.additionalFields.includes('amount')"
      prefix="$"
      class="mr-1"
      label="Amount"
      v-model="form.amount"
      type="number"
      @change="emitFormData"
    ></v-text-field>
    <v-menu
      v-if="bobtailConfig.additionalFields && bobtailConfig.additionalFields.includes('requested_date')"
      ref="requestedDate"
      v-model="requestedDateMenu"
      :close-on-content-click="false"
      :return-value.sync="form.requested_date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="mr-1"
          v-model="form.requested_date"
          label="Start date"
          readonly
          v-bind="attrs"
          v-on="on"
          clearable
          hide-details
          small
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="form.requested_date"
        no-title
        scrollable
        @change="emitFormData"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="requestedDateMenu = false">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.requestedDate.save(form.requested_date)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { UNIT_FORM_CONFIG_BY_TYPE } from '../../utils/constants.js'

export default {
  name: 'BobtailInsuranceInput',
  props: {
    unitType: {
      type: String,
      required: true
    },
    bobtailData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    bobtailConfig() {
      return this.unitType ? UNIT_FORM_CONFIG_BY_TYPE[this.unitType].bobtail_insurance : null
    }
  },
  data () {
    return {
      requestedDateMenu: false,
      form: {
        type: '',
        amount: 0,
        requested_date: null
      }
    }
  },
  created() {
    this.form = {
      ...this.form, 
      ...this.bobtailData,
      requested_date: this.bobtailData && this.bobtailData.requested_date
        ? this.$options.filters.formatDatePicker(this.bobtailData.requested_date)
        : null,
    }
  },
  methods: {
    emitFormData() {
      this.form.amount = parseFloat(this.form.amount)
      this.$emit('input', this.form)
    }
  }
}
</script>