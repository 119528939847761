import moment from 'moment'

export const b64toBlob = function (b64Data, contentType, sliceSize = 512) {
  const byteCharacters = window.atob(b64Data)
  const byteArrays = []
  
  // processing the byteCharacters in smaller slices, rather than all at once
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
  
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
  
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  
  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const generateYearsArr = (howMany) => {
  const thisYear = new Date().getFullYear()
  const yearOptions = []
  for (let i = 0; i < howMany; i++) yearOptions.push(thisYear - i)
  return yearOptions
}

export const downloadFile = function (blob, fileNamePrefix = 'export') {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  const fileName = `${fileNamePrefix}_${moment(new Date()).format('YYYY-MM-DD')}`
  link.download = fileName
  link.click()
  link.remove()
}

export const loadSelectedGenerator = function (data) {
  const loadEl = document.getElementById(data.loadId)
  if (loadEl) {
    // set load border
    loadEl.style.border = `2px solid ${data.color}`
    // remove span if exists
    const oldSpan = loadEl.querySelector('span')
    if (oldSpan) loadEl.removeChild(oldSpan)

    // add new span with user name
    const userSpan = document.createElement('span')
    userSpan.textContent = data.user_name
    userSpan.style.backgroundColor = data.color
    userSpan.classList.add('user-info')
    
    loadEl.appendChild(userSpan)
  }
  return
}

export const removeSelectedUserFromLoad = function (loadId) {
  const loadEl = document.getElementById(loadId)
  if (loadEl) {
    loadEl.style.border = 'none'
    loadEl.style.borderLeft = '1px solid #A9A9A9'
    loadEl.style.borderBottom = '1px solid #A9A9A9'
    const userSpan = loadEl.querySelector('span')
    if (userSpan) loadEl.removeChild(userSpan)
  }
  return
}

export const getFingerprintLocalCache = function () {
  const visitorData = JSON.parse(localStorage.getItem('visitorData'))
  if (visitorData && new Date(visitorData.expDate).valueOf() <= Date.now()) {
    localStorage.removeItem('visitorData')
    return null
  }
  return visitorData
}

export const cacheFingerprintData = function (visitorData) {
  const expDate = new Date()
  expDate.setDate(expDate.getDate() + 30)
  localStorage.setItem('visitorData', JSON.stringify({...visitorData, expDate}))
  return visitorData
}

export const decodeJwtToken = function(token) {
  const base64Url = token.split('.')[1] // Uzmite payload deo
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export const isDateInRange = function(dateToCheck, dateRange) {
  const startDate = moment(dateRange[0], 'YYYY-MM-DD')
  const endDate = moment(dateRange[1], 'YYYY-MM-DD').endOf('day')

  const date = moment(dateToCheck)

  return date.isBetween(startDate, endDate, undefined, '[]')
}
