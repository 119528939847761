import postTruck from '../../services/PostTruckService'

const state = {
  postTrucks: []
}

const getters = {
  postTrucks (state) {
    return state.postTrucks
  }
}

const actions = {
  getPostTrucks ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return postTruck.getPostTrucks(payload).then(res => {
      commit('setPostTrucks', res.data)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addPostTruck ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return postTruck.addPostTruck(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addPostTruck', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editPostTruck ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return postTruck.editPostTruck(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editPostTruck', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deletePostTruck ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return postTruck.deletePostTruck(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deletePostTruck', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setPostTrucks (state, postTrucks) {
    state.postTrucks = postTrucks
  },
  addPostTruck (state, postTruck) {
    const tmpPostTrucks = state.postTrucks.slice()
    tmpPostTrucks.unshift(postTruck)
    state.postTrucks = tmpPostTrucks
  },
  editPostTruck (state, postTruck) {
    let tmpPostTrucks = state.postTrucks.slice()
    let index = tmpPostTrucks.findIndex(el => el._id === postTruck._id)
    tmpPostTrucks.splice(index, 1, postTruck)
    state.postTrucks = tmpPostTrucks
  },
  deletePostTruck (state, id) {
    const tmpPostTrucks = state.postTrucks.slice()
    tmpPostTrucks.splice(tmpPostTrucks.findIndex(el => el._id === id), 1)
    state.postTrucks = tmpPostTrucks
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
