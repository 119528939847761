<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="340"
    min-width="340"
    offset-x
    @input="onOpenMenu"
  >
    <template v-slot:activator="{ on }">
      <td
        @click="on.click"
        @mouseenter="onMouseEnter($event)"
        @mouseleave="showComments = false"
        @mousedown="showComments = false"
        @contextmenu.prevent="onClick"
        :draggable="permissions.DISPATCHER_ALL"
        @dragstart="startDrag($event, load)"
        :data-date="day.formatedDate"
        :title="loadTitle"
        :id="load._id"
        class="load-cell"
        :style="{
          cursor: 'pointer',
          background: load.loadType.color,
          fontWeight: '600',
          fontSize: '13px',
          textAlign: 'center',
          'webkit-user-select': 'none',
          'moz-user-select': 'none'
        }"
      >
        <template v-if="$config.DASHBOARD_PRICE_MILES && load.price && load.miles">
          <span>{{loadName}}</span>
          <hr/>
          <span class="price-miles"><span>${{load.price}}</span> | <span>{{load.miles}} mi</span></span>
        </template>
        <template v-else>
          {{loadName}}
          <template v-if="showDate">
            <br/>
            <span class="load-date">{{load.day | formatDate('MM/DD/YYYY')}}</span>
          </template>
          <v-icon v-if="load.is_dedicated" :title="$t('LOAD_IS_DEDICATED')" class="dedicated-icon" small :color="getLoadDedicatedIconColor">mdi-alpha-d-box</v-icon>
        </template>
        <div class="comments-holder" v-if="showComments && load.comments.length" :style="positionStyle">
          <v-card>
            <Comments :comments="load.comments" :loadId="load._id" :showActions="false"/>
          </v-card>
        </div>
      </td>
    </template>
    <v-card>
      <Comments v-if="load.comments.length" @edit="onEditComment" :comments="load.comments" :loadId="load._id" :showActions="true"/>
      <form @submit.prevent="onSubmitComment(load._id)">
        <!-- <v-select
          :items="commentTypes"
          v-model="commentType"
          label="Type of absence"
          height="24"
          :hide-details="true"
          class="menu-comment-type"
          single-line
        ></v-select>
        <v-menu
          ref="menu"
          :disabled="commentType === ''"
          v-model="menuDate"
          :close-on-content-click="false"
          :return-value.sync="commentDateRange"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formatDate"
               :disabled="commentType === ''"
              label="Date from - to"
              readonly
              height="24"
              class="comment-text-field"
              :hide-details="true"
              single-line
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="commentDateRange"
            no-title
            range
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="cancelDate"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(commentDateRange)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu> -->
        <!-- <v-textarea
          @onEnter="handleEnter($event, load._id)"
          ref="comtext"
          :autofocus="true"
          class="comment-text-field"
          name="comment"
          :label="''"
          placeholder="Comment"
          :rules="rules"
          counter
          hide-details="auto"
          rows="2"
          no-resize
          v-model="commentText"
        ></v-textarea> -->
        <mention-input
          @onEnter="handleEnter($event, load._id)"
          ref="comtext"
          :autofocus="true"
          name="comment"
          placeholder="Comment"
          label=""
          counter
          v-model="commentText"/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="editCommentId !== null"
            text
            small
            @click="cancelEdit"
          >
            Cancel edit
          </v-btn>
          <v-btn
            v-else
            text
            small
            @click="closeMenu"
          >
            Cancel
          </v-btn>
          <v-btn
            type="submit"
            color="success"
            small
            :loading="loading"
            :disabled="commentText === '' || commentText.length > 500 || !permissions.COMMENTS"
          >
            {{editCommentId === null ? 'Save' : 'Edit'}}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import loadsService from '../../services/LoadsService'
import Comments from '../Common/Comments'
import moment from 'moment'
import MentionInput from '../Common/MentionInput'

export default {
  name: 'Load',
  props: ['load', 'day', 'boardId', 'showDate'],
  components: {
    Comments,
    MentionInput
  },
  data() {
    return {
      menu: false,
      menuDate: false,
      commentText: '',
      commentType: '',
      commentDateRange: [],
      commentTypes: ['HOMETIME', 'VACATION', 'OFF'],
      showComments: false,
      positionHorizontal: 'left',
      positionVertical: 'top',
      editCommentId: null
    }
  },
  computed: {
    ...mapGetters('auth', ['isSuperAdmin', 'user', 'permissions']),
    ...mapGetters('common', ['loading', 'userColor']),
    formatDate () {
      if (this.commentDateRange.length <= 1) return null

      const [year1, month1, day1] = this.commentDateRange[0].split('-')
      const [year2, month2, day2] = this.commentDateRange[1].split('-')
      return `from ${month1}/${day1}/${year1} to ${month2}/${day2}/${year2}`
    },
    positionStyle () {
      let style = {}
      style[this.positionHorizontal] = '100%'
      style[this.positionVertical] = '0px'
      return style
    },
    loadTitle () {
      if (this.load.absenceType && this.load.absenceType !== '' && this.load.dateFrom && this.load.dateTo) {
        return `from ${moment.utc(this.load.dateFrom).format('MM/DD/YYYY')} to ${moment.utc(this.load.dateTo).format('MM/DD/YYYY')}`
      }
      return this.load.loadType.type
    },
    loadName () {
      if (this.$config.LOAD_ORIGIN && this.load.origin && this.load.origin !== '') {
        return `${this.load.origin} - ${this.load.name}`
      }

      return this.load.name
    },
    getLoadDedicatedIconColor() {
      return ['DISPATCHED, NOT LOADED ', 'DEFAULT'].includes(this.load.loadType.type) ? 'black' : 'white'
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    emitEvent(event) {
      if (!this.permissions.EMIT_LOAD_SELECTED) return
      this.$socket.client.emit(event, {user_email: this.user.email, user_name: this.user.name, color: this.userColor, loadId: this.load._id, board_id: this.boardId})
    },
    onOpenMenu() {
      if (this.menu) {
        this.emitEvent('load_selected')
      } else {
        this.emitEvent('load_deselected')
      }
    },
    onSubmitComment (loadId) {
      if (!this.commentText.length) return

      let comment = {
        text: this.commentText.trim(),
        commentDateRange: this.commentDateRange,
        commentType: this.commentType,
        user_email: this.user.email,
        user_name: this.user.name,
        created: new Date()
      }
      this.$store.dispatch('common/setLoading', true)
      if (this.editCommentId !== null) {
        comment['_id'] = this.editCommentId
        loadsService.editComment(loadId, comment, this.boardId).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.editCommentId = null
            this.commentText = ''
            this.setMessage('Comment successfully edited')
          }
        })
      } else {
        loadsService.addComment(loadId, comment, this.boardId).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this.closeMenu()
            this.setMessage('Comment successfully added')
          }
        })
      }
    },
    closeMenu () {
      this.emitEvent('load_deselected')
      this.commentText = ''
      this.commentType = ''
      this.commentDateRange = []
      this.editCommentId = null
      this.menu = false
    },
    onClick () {
      this.$emit('click')
    },
    cancelDate () {
      this.menuDate = false
      this.commentDateRange = []
    },
    startDrag (evt, load) {
      if (!this.permissions.DISPATCHER_ALL) {
        return
      }
      this.showComments = false
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('dragdata', JSON.stringify({load: load, boardId: this.boardId}))
    },
    handleEnter (e, loadId) {
      if (e.ctrlKey) {
        this.onSubmitComment(loadId)
      }
    },
    onMouseEnter (e) {
      //e.offsetX
      if (e.view.innerWidth <= e.clientX + 400) {
        this.positionHorizontal = 'right'
      } else {
        this.positionHorizontal = 'left'
      }

      if (e.view.innerHeight <= e.clientY + 210) {
        this.positionVertical = 'bottom'
      } else {
        this.positionVertical = 'top'
      }
      
      this.showComments = true
    },
    onEditComment (comment) {
      this.editCommentId = comment._id
      this.commentText = comment.text
      this.commentType = comment.commentType

      if (comment.dateFrom && comment.dateTo) {
        let dates = []
        dates.push(moment(comment.dateFrom).format('YYYY-MM-DD'))
        dates.push(moment(comment.dateTo).format('YYYY-MM-DD'))
        this.commentDateRange = dates
      }
      this.$refs.comtext.$refs.minput.$refs.input.focus()
    },
    cancelEdit () {
      this.editCommentId = null
      this.commentText = ''
      this.commentDateRange = []
      this.commentType = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.comments-holder {
  position: absolute;
  z-index: 5;
  width: 340px;
  font-weight: initial;
  text-align: initial;
  cursor: initial;
}

.dedicated-icon {
  position: absolute;
  right: 0;
  top: 0;
}
</style>