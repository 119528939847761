import claimsService from '../../services/ClaimsService'

const state = {
  types: [],
  claimsUsers: [],
  selectedUser: null,
  searchActivated: false,
  search: {
    byTruck: '',
    byTrailer: ''
  }
}

const getters = {
  types (state) {
    return state.types
  },
  selectedUser (state) {
    return state.selectedUser
  },
  search: (state) => {
    return state.search
  },
  searchActivated: (state) => {
    return state.searchActivated
  },
  claimsUsers: (state) => {
    return state.claimsUsers
  }
}

const actions = {
  getTypes ({commit, dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return claimsService.getClaimTypes().then(res => {
      commit('setClaimTypes', res.data.types)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  selectUser ({commit}, userId) {
    commit('setUser', userId)
  },
  updateSearch ({commit}, search) {
    commit('setSearch', search)
  },
  activateSearch ({commit}, active) {
    commit('setActiveSearch', active)
  },
  updateClaimsUsers ({commit}, users) {
    commit('setClaimsUsers', users)
  }
}

const mutations = {
  setClaimTypes (state, types) {
    state.types = types
  },
  setUser (state, userId) {
    state.selectedUser = userId
  },
  setSearch (state, search) {
    state.search.byTruck = search.byTruck
    state.search.byTrailer = search.byTrailer
  },
  setActiveSearch (state, active) {
    state.searchActivated = active
  },
  setClaimsUsers (state, users) {
    state.claimsUsers = users
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}