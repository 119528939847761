<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="380"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div class="truck-content" @click="on.click" @contextmenu.prevent="onRightClick">
        {{truck.number}}
        <v-icon v-if="truck.note && truck.note !== ''" class="note-icon" right> mdi-comment-text-outline </v-icon> 
      </div>
    </template>
    <v-card width="380">
      <div>
        <TruckHistory v-if="menu" :small="true" type="truck" :id="truck._id" :truckNumber="truck.number" :truckNote="truck.note" :currentDriver="truck.current_driver"/>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import TruckHistory from './TruckHistory.vue'

export default {
  props: {
    truck: {
      type: Object,
      required: true,
    }
  },
  components: {
    TruckHistory
  },
  data() {
    return {
      menu: false
    }
  },
  methods: {
    onRightClick() {
      this.$emit('right-click')
    }
  }
}
</script>