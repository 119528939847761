import trailerMark from '../../services/TrailerMarksService'

const state = {
  trailerMarks: [],
}

const getters = {
  trailerMarks(state) {
    return state.trailerMarks
  },
}

const actions = {
  getTrailerMarks({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return trailerMark
      .getTrailerMarks(payload)
      .then((res) => {
        commit('setTrailerMarks', res.data.docs)
        dispatch('common/setLoading', false, { root: true })
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  getAllTrailerMarks({ commit, dispatch }) {
    dispatch('common/setLoading', true, { root: true })
    return trailerMark
      .getAllTrailerMarks()
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('setTrailerMarks', res.data.trailerMarks)
        return Promise.resolve(res.data.trailerMarks)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  addTrailerMark({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return trailerMark
      .addTrailerMark(payload)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('addTrailerMark', res.data.trailerMark)
        return Promise.resolve(res.data.trailerMark)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  editTrailerMark({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return trailerMark
      .editTrailerMark(payload)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('editTrailerMark', res.data.trailerMark)
        return Promise.resolve(res.data.trailerMark)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  deleteTrailerMark({ commit, dispatch }, id) {
    dispatch('common/setLoading', true, { root: true })
    return trailerMark
      .deleteTrailerMark(id)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('deleteTrailerMark', id)
        return Promise.resolve(res)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
}

const mutations = {
  setTrailerMarks(state, trailerMarks) {
    state.trailerMarks = trailerMarks
  },
  addTrailerMark(state, trailerMark) {
    const tmpTrailerMarks = state.trailerMarks.slice()
    tmpTrailerMarks.push(trailerMark)
    state.trailerMarks = tmpTrailerMarks
  },
  editTrailerMark(state, trailerMark) {
    let tmpTrailerMarks = state.trailerMarks.slice()
    let index = tmpTrailerMarks.findIndex((el) => el._id === trailerMark._id)
    tmpTrailerMarks.splice(index, 1, trailerMark)
    state.trailerMarks = tmpTrailerMarks
  },
  deleteTrailerMark(state, id) {
    const tmpTrailerMarks = state.trailerMarks.slice()
    tmpTrailerMarks.splice(
      tmpTrailerMarks.findIndex((el) => el._id === id),
      1
    )
    state.trailerMarks = tmpTrailerMarks
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
