import store from '../store'
export default {
  guest (to, from, next) {
    next(!store.getters['auth/isLoggedIn'] ? true : {
      path: '/'
    })
  },
  auth (to, from, next) {
    next(store.getters['auth/isLoggedIn'] ? true : {
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    })
  },
  authAdmin (to, from, next) {
    next(store.getters['auth/isSuperAdmin'] ? true : {
      path: '/forbidden'
    })
  },
  checkRole (to, from, next) {
    if(!store.getters['auth/isLoggedIn']) {
      return next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }

    if (to.meta.allowedPermissions) {
      for (let permission of to.meta.allowedPermissions) {
        const permissions = store.getters['auth/permissions']
        if (permissions[permission]) return next(true)
      }
    }
    if (to.meta.allowedRoles) {
      for (let role of to.meta.allowedRoles) {
        if (store.getters['auth/getRoles'].indexOf(role) !== -1) {
          return next(true)
        }
      }
    }
    return next({path: '/forbidden'})
  }
}
