<template>
  <div class="table-filters-v1 d-flex align-center">
    <v-menu offset-x v-model="filterMenu" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small icon class="mr-2" v-bind="attrs" v-on="on">
          <v-icon small :color="filterMenu ? 'primary' : ''">
            mdi-filter
          </v-icon>
        </v-btn>
      </template>
      <div class="filter-menu-content">
        <v-select
          v-model="field"
          :items="filterableFields"
          label="Field"
          item-text="label"
          return-object
          solo
          dense
          hide-details="auto"
        ></v-select>
        <v-select
          v-if="field && field.type !== 'boolean'"
          v-model="operator"
          class="mt-1"
          :items="field ? operatorsByType[field.type] : ['is', 'is not']"
          label="Operator"
          solo
          dense
        ></v-select>
        <div class="value-fields">
          <v-text-field
            v-if="field === null || field.type === 'text' || field.type === 'number'"
            v-model="value"
            :type="field ? field.type : 'text'"
            label="Enter value"
            dense
          ></v-text-field>
          <v-select
            v-else-if="field && field.type === 'select'"
            class="mt-1"
            v-model="value"
            :items="field.options"
            :label="field.label"
            dense
            solo
            small
            clearable
          />
          <v-switch
            v-else-if="field && field.type === 'boolean'"
            v-model="value"
            label="Value"
            dense
          ></v-switch>
          <v-menu
            v-else-if="field && field.type === 'date'"
            ref="datePicker"
            v-model="datePicker"
            :close-on-content-click="false"
            offset-y
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="value"
                :label="operator !== 'range' ? 'Select date' : 'From'"
                prepend-inner-icon="mdi-calendar"
                dense
                solo
                readonly
                small
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="value"
              no-title
              @input="datePicker = false"
            />
          </v-menu>
          <v-menu
            v-if="field && field.type === 'date' && operator === 'range'"
            ref="datePickerSecond"
            v-model="datePickerSecond"
            :close-on-content-click="false"
            offset-y
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueTo"
                label="To"
                prepend-inner-icon="mdi-calendar"
                dense
                solo
                readonly
                small
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="valueTo"
              no-title
              @input="datePickerSecond = false"
            />
          </v-menu>
        </div>
        <div class="buttons-holder d-flex">
          <v-btn
            small
            text
            color="error"
            @click="closeMenu"
          >Clear</v-btn>
          <v-spacer/>
          <v-btn
            :disabled="field.type !== 'boolean' && (!field || !value)"
            small
            text
            color="primary"
            @click="applayFilter"
          >Apply</v-btn>
        </div>
      </div>
    </v-menu>
    <div class="filters-holder">
      <v-chip-group>
        <v-chip small v-for="(filter, index) in filters" :key="filter.field.name" close @click:close="onRemoveFilter(index)">
          <strong>{{ filter.field.label }}</strong>:
          {{ filter.operator !== 'range' ? filter.operator : ''}} '{{ filter.operator !== 'range' ? filter.value : `${filter.value.from} - ${filter.value.to}`}}'
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
// TODO: filter sa predefinisanom select listom

// TODO: za kasnije
// 1. Opcija za cuvanje filtera pod nekim nazivom
// 2. Primena sacuvanih filtera

export default {
  name: 'TableFilters',
  props: {
    filterableFields: {
      type: Array,
      required: true,
    },
    initFilters: {
      type: Array,
      default: () => []
    },
    tableKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filterMenu: false,
      field: this.filterableFields[0],
      operator: 'is',
      value: null,
      valueTo: null,
      filters: [...this.initFilters],
      operatorsByType: {
        'text': ['is', 'is not'],
        'select': ['is', 'is not'],
        'boolean': ['is', 'is not'],
        'date': ['is', 'less than', 'greater than', 'range'],
        'number': ['is', 'less than', 'greater than']
      },
      datePicker: false,
      datePickerSecond: false
    }
  },
  methods: {
    applayFilter() {
      let value = this.value
      if (this.operator === 'range') {
        value = {
          from: this.value,
          to: this.valueTo
        }
      }
      this.filters.push({
        field: this.field,
        operator: this.operator,
        value: value
      })
      this.$emit('change', this.filters)
      this.closeMenu()
    },
    closeMenu() {
      this.field = this.filterableFields[0]
      this.operator = 'is'
      this.value = null
      this.filterMenu = false
    },
    onRemoveFilter(index) {
      this.filters.splice(index, 1)
      this.$emit('change', this.filters)
    }
  }
}
</script>

<style lang="scss">
.table-filters-v1 {
}

.filter-menu-content {
  background-color: white;
  width: 280px;
  padding: 8px;
}
</style>