<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.eldDrivers') }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('other.search')"
          single-line
          hide-details
          height="30"
          dense
        ></v-text-field>
      </v-card-title>

      <v-data-table
          height="750"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="eldDrivers"
          class="elevation-1"
          :loading="loading"
          :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.is_active="{item}">
          {{item.is_active ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
                small
                class="mr-2"
                @click="editEldDriver(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteEldDriver(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddEldDriver" data-vv-scope="eldDriver">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            {{form._id ? 'Edit' : 'Add'}} eldDriver
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                    ref="truck_number"
                    v-model="form.name"
                    v-validate="'required'"
                    :error="errors.has('eldDriver.name')"
                    :error-messages="errors.first('eldDriver.name')"
                    name="name"
                    id="name"
                    :autofocus="true"
                    placeholder="Driver's Name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    ref="truck_brand"
                    v-model="form.phone_number"
                    v-validate="'required'"
                    :error="errors.has('eldDriver.phone_number')"
                    :error-messages="errors.first('eldDriver.phone_number')"
                    name="phone_number"
                    id="phone_number"
                    :autofocus="true"
                    placeholder="Phone Number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-autocomplete :items="eldCompanies"
                  v-model="selected.eldCompany"
                  label="Eld Company"
                  item-value="_id"
                  item-text="name"
                  return-object
                  hide-details="auto"
                  @change="onCompanySelect()"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6>
                <v-autocomplete :items="eldTrucks"
                  v-model="selected.eldTruck"
                  label="Truck Number"
                  item-value="_id"
                  item-text="truck_number"
                  return-object
                  hide-details="auto"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                    ref="note"
                    v-model="form.note"
                    name="note"
                    id="note"
                    :autofocus="true"
                    placeholder="Note"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                    v-model="form.is_active"
                    label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'EldDrivers',
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        search: '',
        itemsPerPage: 15
      },
      eldCompanies:[],
      eldTrucks:[],
      selected: {
        eldCompany: null,
        eldTruck: null
      },
      totalItems: 0,
      headers: [
        {text: 'Truck', value: 'truck'},
        {text: 'Name', value: 'name'},
        {text: 'Company', value: 'eldCompany.name'},
        {text: 'Phone', value: 'phone_number'},
        {text: 'Payment started', value: 'eldTruck.payment_started'},
        {text: 'Note', value: 'note'},
        {text: 'Is active', value: 'is_active'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        name: '',
        note: '',
        is_active: true
      },
      formDialog: false,
      search: ''
    }
  },
  mounted() {
    this.getCompanies()
  },
  watch: {
    search: function (newSearch) {
      this.searchDrivers(newSearch)
    },
    options: {
      handler () {
        this.$store.dispatch('eldDrivers/getEldDrivers', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('eldDrivers', ['eldDrivers']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    onCompanySelect(){
      this.getTrucksByCompanyId(this.selected.eldCompany._id)
    },
    searchDrivers: debounce( function(search) {
      this.options.search = search
      this.$store.dispatch('eldDrivers/getEldDrivers', this.options).then(res => {
        this.totalItems = res.total
      })
    }, 300),
    getCompanies(){
      this.$store.dispatch('eldCompanies/getAllEldCompanies').then(res => {
        this.eldCompanies = res
      })
    },
    getTrucksByCompanyId(companyId){
      this.$store.dispatch('eldTrucks/getTrucksByCompanyId', companyId).then(res => {
        this.eldTrucks = res
      })
    },
    getTrucks(){
      this.$store.dispatch('eldTrucks/getAllEldTrucks').then(res => {
        this.eldTrucks = res
      })
    },
    onAddEldDriver () {
      this.$validator.validateAll('eldDriver').then((result) => {
        if (result) {
          if(this.selected.eldCompany){
            this.form.eldCompany = this.selected.eldCompany
          }
          if(this.selected.eldTruck){
            this.form.eldTruck = this.selected.eldTruck
            this.form.truck = this.selected.eldTruck.truck_number
          }
          if (this.form._id) {

            if(this.selected.eldCompany){

              this.form.eldCompany = this.selected.eldCompany
            }
            if(this.selected.eldTruck){
              this.form.eldTruck = this.selected.eldTruck
              this.form.truck = this.selected.eldTruck.truck_number

            }
            this.$store.dispatch('eldDrivers/editEldDriver', this.form).then(res => {
              if (res) {
                this.formDialog = false
                this.form = {
                  _id: null,
                  name: '',
                  note: '',
                  is_active: true,
                  truck: ''
                }
              }
            })
          } else {
            if(this.selected.eldCompany){
              this.form.eldCompany = this.selected.eldCompany
            }
            if(this.selected.eldTruck){
              this.form.eldTruck = this.selected.eldTruck
            }
            this.$store.dispatch('eldDrivers/addEldDriver', this.form).then(res => {
              if (res) {
                this.formDialog = false
                this.form = {
                  _id: null,
                  name: '',
                  note: '',
                  is_active: true,
                  truck: ''
                }
              }
            })
          }
        }
      })
    },
    editEldDriver (eldDriver) {
      this.selected.eldTruck = {_id: eldDriver.eldTruck._id, truck_number: eldDriver.eldTruck.truck_number}
      this.selected.eldCompany = {_id: eldDriver.eldCompany._id, truck_number: eldDriver.eldCompany.name}
      this.form = {...eldDriver}
      this.formDialog = true
    },
    deleteEldDriver (eldDriver) {
      let confVal = confirm(`Are you sure you want to delete eldDriver: ${eldDriver.name}?`)
      if (confVal) {
        this.$store.dispatch('eldDrivers/deleteEldDriver', eldDriver._id).then(() => {
          this.setMessage(`${eldDriver.name} successfully deleted`)
        }).catch((err) => {
          console.log('err on delete', err)
        })
      }
    },
    closeForm () {
      this.formDialog = false
      this.form = {
        _id: null,
        name: '',
        note: '',
        is_active: true,
        truck: ''
      }
    }
  }
}
</script>

<style lang="scss">
.boards-wrapper {
  width: 100%;
  margin-top: 20px;
}

.v-list-item.move {
  cursor: move;

  .v-list-item__title.teamLead {
    color: green;
  }
}

// @media only screen and (max-width: 599px) {
//   .boards-wrapper {
//     margin-top: 65px;
//   }
// }
</style>