const state = {
  selectedBoard: null,
  searchActivated: false,
  search: {
    byName: '',
    byTruck: '',
    byTrailer: ''
  }
}

const getters = {
  selectedBoard: (state, getters, rootState) => {
    if (rootState.auth.user === null)
      return null
    return state.selectedBoard
    // return state.selectedBoard !== null ? state.selectedBoard : rootState.auth.user.board_id
  },
  search: (state) => {
    return state.search
  },
  searchActivated: (state) => {
    return state.searchActivated
  }
}

const actions = {
  setBoardId ({commit}, id) {
    commit('setBoardId', id)
  },
  updateSearch ({commit}, search) {
    commit('setSearch', search)
  },
  activateSearch ({commit}, active) {
    commit('setActiveSearch', active)
  }
}

const mutations = {
  setBoardId (state, id) {
    state.selectedBoard = id
  },
  setSearch (state, search) {
    state.search.byName = search.byName
    state.search.byTruck = search.byTruck
    state.search.byTrailer = search.byTrailer
  },
  setActiveSearch( state, active) {
    state.searchActivated = active
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
