import userService from '../../services/UsersService'

const state = {
  roles: []
}

const getters = {
  roles (state) {
    return state.roles
  }
}

const actions = {
  getRoles ({commit, dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return userService.getRoles({}).then(res => {
      commit('setRoles', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve()
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setRoles (state, roles) {
    state.roles = roles
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
