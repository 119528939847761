<template>
  <div class="AvailableTrailerStats">
    <v-data-table
      v-if="unitsByYard"
      :headers="headers"
      :items="unitsByYard"
      class="mr-8 elevation-1"
      hide-default-footer
      disable-sort
      dense
    />

    <v-data-table
      v-if="unitsByStatus"
      :headers="headers"
      :items="unitsByStatus"
      class="mr-8 elevation-1"
      hide-default-footer
      disable-sort
      dense
    />

    <v-data-table
      v-if="unitsByHolder"
      :headers="headers"
      :items="unitsByHolder"
      class="elevation-1"
      hide-default-footer
      disable-sort
      dense
    />
  </div>
</template>

<script>
import service from '../../services/TrailersService'

export default {
  name: 'AvailableTrailerStats',

  data() {
    return {
      unitsByYard: null,
      unitsByStatus: null,
      unitsByHolder: null,

      headers: [
        { text: 'Name', value: '_id' },
        { text: 'Count', value: 'count' },
      ],
    }
  },

  created() {
    this.getData()
  },

  methods: {
    async getData() {
      try {
        const { data } = await service.getAvailableStats()
        this.unitsByYard = data.unitsByYard || []
        this.unitsByStatus = data.unitsByStatus || []
        this.unitsByHolder = data.unitsByHolder || []
      } catch (error) {
        console.error('getData()', error)
      }
    },
  },
}
</script>

<style lang="scss">
.AvailableTrailerStats {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
    }
  }

  & > * {
    overflow-y: scroll;
  }
}
</style>
