import yards from '../../services/YardsServices'

const state = {
  yards: []
}

const getters = {
  yards (state) {
    return state.yards
  }
}

const actions = {
  getYards ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return yards.getYards(payload).then(res => {
      commit('setYards', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllYards ({commit, dispatch}, type) {
    dispatch('common/setLoading', true, {root: true})
    return yards.getAllYards(type).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('setYards', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addYard ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return yards.addYard(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addYard', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editYard ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return yards.editYard(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editYard', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteYard ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return yards.deleteYard(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteYard', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setYards (state, yards) {
    state.yards = yards
  },
  addYard (state, yard) {
    const tmpYards = state.yards.slice()
    tmpYards.push(yard)
    state.yards = tmpYards
  },
  editYard (state, yard) {
    let tmpYards = state.yards.slice()
    let index = tmpYards.findIndex(el => el._id === yard._id)
    tmpYards.splice(index, 1, yard)
    state.yards = tmpYards
  },
  deleteYard (state, id) {
    const tmpYards = state.yards.slice()
    tmpYards.splice(tmpYards.findIndex(el => el._id === id), 1)
    state.yards = tmpYards
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
