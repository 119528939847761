import Api from '@/services/Api'

export default {
  getCars(params) {
    return Api().get('/cars', { params })
  },
  addCar(body) {
    return Api().post('/cars', body)
  },
  editCar(id, body) {
    return Api().put(`/cars/${id}`, body)
  },
  getCar(id) {
    return Api().get(`/cars/${id}`)
  },
  deleteCar(id) {
    return Api().delete(`/cars/${id}`)
  },

  getCarMakes(params) {
    return Api().get('/cars/make', { params })
  },
  addCarMake(body) {
    return Api().post('/cars/make', body)
  },
  editCarMake(id, body) {
    return Api().put(`/cars/make/${id}`, body)
  },
  getCarMake(id) {
    return Api().get(`/cars/make/${id}`)
  },
  deleteCarMake(id) {
    return Api().delete(`/cars/make/${id}`)
  },

  getCarModels(params) {
    return Api().get('/cars/model', { params })
  },
  addCarModel(body) {
    return Api().post('/cars/model', body)
  },
  editCarModel(id, body) {
    return Api().put(`/cars/model/${id}`, body)
  },
  getCarModel(id) {
    return Api().get(`/cars/model/${id}`)
  },
  deleteCarModel(id) {
    return Api().delete(`/cars/model/${id}`)
  },
}
