<template>
  <div class="AvailableTable">
    <v-card>
      <v-card-title class="AvailableTable__title">
        {{ item.name }}
        <v-divider></v-divider>
        <v-btn
          :disabled="!isOnTheListCount || !canEdit"
          class="mr-2"
          color="yellow"
          :loading="loading"
          small
          @click="handleDeleteAllFromList"
        >
          <v-icon small> mdi-delete</v-icon>
          ({{isOnTheListCount}})
        </v-btn>
        <v-btn
          :disabled="!canEdit"
          color="success"
          :loading="loading"
          class="mr-2"
          small
          @click="handleOpenDialog"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
        <v-btn v-if="type === 'yard'"
        :disabled="!canEdit"
        color="success"
        :loading="loading"
        class="mr-2"
        small
        @click="exportData"
      >
        Export <v-icon small>mdi-file-excel-outline</v-icon>
      </v-btn>
      </v-card-title>

      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :hide-default-footer="true"
        :items-per-page="-1"
        dense
      >
        <template v-slot:item.data="{ item }">
          <td class="status-column" :style="createStyles(item.aStatus)" :title="item.aStatus.text">
            <status-column
              :key="`status-column-${item._id}`"
              :disabled="!canEdit"
              :status="item.aStatus"
              :statuses="statuses"
              :actions="actionItems"
              :text="item.data"
              @edit="handleEditClicked(item)"
              @info="handleOpenCommentDialog(item)"
              @statusChange="handleStatusChange($event, item)"
              @transfer="openTransferDialog(item)"
              @delete="handleDelete(item)"
            >
            <template slot="title"><v-icon>mdi-truck</v-icon>{{ item.truckNumber }}</template>
            </status-column>
          </td>
        </template>
        <template v-slot:item.aDivision="{ item }">
          <td
            :style="getDivisonStyle(item)"
          >
            <division-column
              :disabled="!canEdit"
              :text="item.aDivision"
              :isOn="item.aIsOnTheList"
              @change="handleOnListChange($event, item)"
            ></division-column>
          </td>
        </template>

        <template v-slot:item.aOwner="{ item }">
          <td style="position: relative; padding: 0px !important;">
            <note :note="item.aOwnerNote" :text="item.aOwner" :can-view="true" disable/>
          </td>
        </template>

        <template v-slot:item.aInfo="{ item }">
          <td @contextmenu.prevent="handleOpenCommentDialog(item)">
            <v-tooltip bottom v-if="item.aInfo && item.aInfo.length">
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="AvailableTable__info"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.aInfo }}
                </span>
              </template>
              <span style="white-space: pre-wrap; display: block; max-width: 360px">{{
                item.aInfo
              }}</span>
            </v-tooltip>
          </td>
        </template>

        <template v-slot:item.trans="{ item }">
          <td align="center">
            {{ item.trans ? item.trans.charAt(0).toUpperCase() : '' }}
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showCommentDialog"
      @click:outside="handleCloseCommentDialog"
      max-width="540px"
    >
      <v-card>
        <v-card-title>Selected truck: {{ this.form.truck ? this.form.truck.number : '' }}</v-card-title>
        <v-form @submit.prevent="handleSaveTruck">
          <v-container>
            <v-alert v-if="error" type="error" dismissible
              >Something went wrong.</v-alert
            >
            <v-textarea
              v-model="form.aInfo"
              label="Info"
              solo
              no-resize
              clearable
              clear-icon="mdi-close-circle"
            />
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" :loading="loading" color="primary">
              Save note
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Koristi se samo na glavnoj tabeli -->
    <!-- <v-dialog
      v-model="showAOwnerNoteDialog"
      @click:outside="handleCloseAOwnerDialog"
      max-width="540px"
    >
      <v-card>
        <v-form @submit.prevent="handleSaveTruck">
          <v-container>
            <v-alert v-if="error" type="error" dismissible
              >Something went wrong.</v-alert
            >
            <v-textarea
              v-model="form.aOwnerNote"
              label="Owner note"
              solo
              no-resize
              clearable
              clear-icon="mdi-close-circle"
            />
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" :loading="loading" color="primary">
              Save note
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog> -->

    <v-dialog
      v-model="showTransferDialog"
      @click:outside="showTransferDialog = false"
      max-width="540px"
    >
      <v-card>
        <v-card-title>Selected truck: {{ this.form.truck ? this.form.truck.number : '' }}</v-card-title>
        <v-form @submit.prevent="handleSaveTruck">
          <v-container>
            <v-alert v-if="error" type="error" dismissible
              >Something went wrong.</v-alert
            >
            <v-autocomplete
              :items="transferOptions"
              v-model="transfer"
              :value="transfer"
              label="Select yard/dealership"
              item-text="name"
              return-object
              hide-details="auto"
              single-line
              dense
              solo
              clearable
            >
              <template v-slot:item="{ item }">
                <span class="transfer-item">
                  <v-badge
                    :color="item.isSpecial ? 'blue' : 'white'"
                    dot
                    offset-x="-4"
                  >
                    {{ item.name }}
                  </v-badge>
                  <span class="transfer-type">{{ item.type }}</span>
                </span>
              </template>
            </v-autocomplete>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="!transfer"
              type="submit"
              :loading="loading"
              color="primary"
            >
              Transfer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showForm" max-width="540px" persistent>
      <v-card>
        <v-card-title>{{ form.truck ? 'Edit' : 'Add' }} truck</v-card-title>
        <v-card-subtitle
          >Choose a truck to add to "{{ item.name }}"
          {{ type }}.</v-card-subtitle
        >
        <v-form @submit.prevent="handleSaveTruck">
          <v-container>
            <v-alert v-if="showTruckTakenAlert" type="error"
              >This truck is already assigned to one of the Yards or
              Dealerships!</v-alert
            >

            <v-alert v-if="error" type="error" dismissible
              >Something went wrong.</v-alert
            >

            <v-row>
              <v-col cols="12" v-if="isEdit">
                <v-text-field
                  :value="formatData(form.truck)"
                  label="Truck"
                  readonly
                  disabled
                />
              </v-col>
              <v-col cols="12" v-else>
                <v-autocomplete
                  v-model="form.truck"
                  :items="trucks"
                  :loading="loading"
                  :disabled="isEdit"
                  :search-input.sync="search"
                  item-text="number"
                  item-disabled
                  label="Select a truck"
                  placeholder="Search by number"
                  prepend-icon="mdi-database-search"
                  clearable
                  hide-no-data
                  hide-selected
                  return-object
                  @change="handleTruckPicked($event, true)"
                >
                  <template v-slot:item="{ item }">
                    {{ formatData(item) }}
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="form.aNote"
                  label="Aditional info"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-combobox
                  clearable
                  v-model="form.aDivision"
                  :items="companiesAll"
                  label="Select division"
                ></v-combobox>
              </v-col>

              <v-col cols="4">
                <v-text-field v-model="form.aOwner" label="Owner" disabled />
              </v-col>

              <v-col cols="4">
                <v-text-field
                  v-model="form.aMileage"
                  label="Mileage"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="form.aInfo" label="Info"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="form.aStatus"
                  :items="statuses"
                  return-object
                  label="Status"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />

            <v-btn
              @click="handleCloseDialog"
              class="pink"
              :loading="loading"
              dark
              >Close</v-btn
            >
            <v-btn
              type="submit"
              :loading="loading"
              :disabled="submitDisabled"
              color="primary"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import trucksService from '../../services/TruckService'
import StatusColumn from './StatusColumn.vue'
import DivisionColumn from './DivisionColumn.vue'
import Note from '../Common/NoteColumn.vue'
import debounce from 'lodash/debounce'
import {mapGetters, mapActions} from 'vuex'
import { b64toBlob, downloadFile } from '../../utils'


const STATUSES = [
  { color: '#9C27B0', text: 'Total damage', order: 1 },
  { color: '#F44336', text: 'In company - Empty', order: 2 },
  { color: 'white', text: 'In company - Reserved', order: 3 },
  { color: '#607D8B', text: 'Out of company', order: 4 },
  { color: '#3F51B5', text: 'Broken', order: 5 },
  { color: 'white', text: 'Temporary', order: 6 },
  { color: '#FFA726', text: 'Good to go', order: 7 },
  { color: '#00FF00', text: 'Auction', order: 8 },
]

export default {
  name: 'AvailableTable',

  props: {
    item: {
      type: Object,
      required: true,
    },
    rows: {
      type: Array,
    },
    transferOptions: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (v) => ['yard', 'dealership'].includes(v),
    },
    yardId: {
      type: String,
      required: false,
    },
  },

  components: {
    StatusColumn,
    DivisionColumn,
    Note
  },

  data() {
    return {
      headers: [
        {
          align: 'center',
          sortable: false,
          text: 'Truck',
          value: 'data',
          width: 500,
        },
        {
          align: 'center',
          sortable: false,
          text: 'Division',
          value: 'aDivision',
          width: 60,
        },
        {
          align: 'center',
          sortable: false,
          text: 'Owner',
          value: 'aOwner',
          width: 33,
        },
        {
          align: 'center',
          sortable: false,
          text: 'Mileage',
          value: 'aMileage',
          width: 50,
        },
        {
          align: 'center',
          sortable: false,
          text: 'Trans',
          value: 'trans',
          width: 33,
        },
        {
          align: 'center',
          sortable: false,
          text: 'Info',
          value: 'aInfo',
          width: 500,
        },
      ],

      loading: false,
      error: null,
      isEdit: false,
      isChangedStatus: false,
      trucks: [],
      queryTerm: '',

      showForm: false,
      form: {
        truck: null,
        aNote: null,
        aDivision: null,
        aOwner: null,
        aMileage: null,
        aInfo: null,
        aStatus: STATUSES[2],
        aIsOnTheList: false,
        aOwnerNote: null,
      },

      statuses: STATUSES,

      showCommentDialog: false,
      showAOwnerNoteDialog: false,
      showTransferDialog: false,
      transfer: null,

      actionItems: [
        {
          text: 'Edit truck',
          icon: 'mdi-pencil-outline',
          event: 'edit',
        },
        {
          text: 'Info',
          icon: 'mdi-pencil-outline',
          event: 'info',
        },
        {
          text: 'Transfer',
          icon: 'mdi-swap-horizontal',
          event: 'transfer',
        },
        {
          text: 'Delete',
          icon: 'mdi-delete',
          event: 'delete',
        },
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['permissions']),
    ...mapGetters('common', ['companiesAll', 'dark']),
    submitDisabled() {
      return this.loading || this.showTruckTakenAlert
    },

    showTruckTakenAlert() {
      if (!this.form.truck) return false
      const alreadyTaken = Boolean(
        this.form.truck.yard || this.form.truck.dealership
      )
      return !this.isEdit && alreadyTaken
    },

    items() {
      return this.item.rows.map(this.formatDoc)
    },

    search: {
      get() {
        return this.queryTerm
      },

      set(val) {
        if (val !== this.queryTerm) {
          this.queryTerm = val
          this.searchTrucks(val)
        }
      },
    },

    canEdit() {
      return this.permissions['AVAILABLE_TRUCKS_ALL']
    },

    isOnTheListCount() {
      return this.items.filter((item) => item.aIsOnTheList).length
    }
  },

  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
      setAlert: 'common/setAlert'
    }),
    async handleEditClicked(r) {
      await this.pickTruck(r)
      this.showForm = true
      this.isEdit = true
    },

    async handleSaveTruck({ remove = false } = {}) {
      const { truck, ...form } = this.form
      const body = { ...truck, ...form }
      body['availableSave'] = true

      if (remove) {

        body[this.type] = null
        body.aNote = null
        body.aInfo = null
        body.aDivision = null
        // body.aOwner = null
        // body.aOwnerNote = null
        body.aMileage = null
        body.aStatus = null
        body.aModified = null
        body.aIsOnTheList = null
      } else {
        const { _id, name, type } = this.transfer ? this.transfer : this.item
        const newType = type || this.type
        body[newType] = { _id, name }
        const otherType = newType === 'yard' ? 'dealership' : 'yard'
        body[otherType] = null
        // flag za truck alert
        body['addedToAvailable'] = this.isEdit || this.isChangedStatus ? false : true

        if (this.transfer) body.aIsOnTheList = false
      }

      this.loading = true
      this.error = false

      try {
        const res = await trucksService.editTruck(body)
        if (res.status === 200) {
          this.handleCloseDialog()
          this.handleCloseCommentDialog()
          this.handleCloseAOwnerDialog()
        } else {
          this.error = true
        }
      } catch (error) {
        console.error('AvailableTable handleSaveTruck()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async pickTruck(truck) {
      try {
        this.handleTruckPicked(truck)
        const { data } = await trucksService.getTruck(truck._id)
        this.form.truck = data.doc
      } catch (error) {
        console.error('AvailableTable pickTruck()', error)
        this.error = true
      }
    },

    exportData() {
      this.$store
        .dispatch('trucks/exportAvailableToExcel', {
          headers: this.headers,
          yardId: this.yardId
        })
        .then((file) => {
          const blob = b64toBlob(
            file.data,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          )
          downloadFile(blob, 'available_truck_export')
        })
    },

    async handleOpenCommentDialog(truck) {
      if (!this.canEdit) return
      await this.pickTruck(truck)
      this.showCommentDialog = true
      this.isEdit = true
    },

    async handleOpenAOwnerDialog(truck) {
      if (!this.canEdit) return
      await this.pickTruck(truck)
      this.showAOwnerNoteDialog = true
    },

    async openTransferDialog(truck) {
      await this.pickTruck(truck)
      this.showTransferDialog = true
      this.isEdit = true
    },

    async handleStatusChange(status, truck) {
      await this.pickTruck(truck)
      this.form.aStatus = status
      this.isChangedStatus = true
      this.handleSaveTruck()
    },

    async handleDelete(truck) {
      await this.pickTruck(truck)
      let confVal = confirm(
        `Are you sure you want to remove truck (${this.form.truck ? this.form.truck.number : ''}) from '${this.item.name}'?`
      )
      if (confVal) {
        
        this.handleSaveTruck({ remove: true })
      }
    },

    async handleOnListChange(isOn, truck) {
      await this.pickTruck(truck)
      this.form.aIsOnTheList = isOn
      this.handleSaveTruck()
    },

    handleCloseCommentDialog() {
      this.showCommentDialog = false
      this.isEdit = true
    },

    handleCloseAOwnerDialog() {
      this.showAOwnerNoteDialog = false
    },

    async handleOpenDialog() {
      this.handleCloseDialog()
      this.isEdit = false
      this.showForm = true
    },

    async handleDeleteAllFromList() {
      let confVal = confirm(
        'Are you sure you want to remove all trucks from list?'
      )
      if (confVal) {
        this.loading = true
        const response = await trucksService.removeAllTrucksFromList(this.item._id, this.type)
        this.loading = false
        if (response.data.status === 'OK') {
          this.setMessage('All trucks from the list are removed')
          // Will be handled on the socket
          // this.item.rows = this.item.rows.map(item => {
          //   item.aIsOnTheList = false
          //   return item;
          // })
        }
      }
    },

    handleCloseDialog() {
      this.showForm = false
      this.form = {
        truck: null,
        aNote: null,
        aDivision: null,
        aOwner: null,
        aMileage: null,
        aInfo: null,
        aStatus: STATUSES[2],
        aIsOnTheList: false,
        aOwnerNote: null,
      }
      this.transfer = null
      this.showTransferDialog = false
      this.isEdit = false
      this.isChangedStatus = false
    },

    handleTruckPicked(t, setDivison = false) {
      if (!t) return

      this.form.aNote = t.aNote
      this.form.aOwner = t.aOwner
      this.form.aOwnerNote = t.aOwnerNote
      this.form.aMileage = t.aMileage || t.mileage
      this.form.aInfo = t.aInfo
      this.form.aStatus = t.aStatus || STATUSES[2]
      this.form.aIsOnTheList = t.aIsOnTheList || false

      if (setDivison && t.division) {
        this.form.aDivision = t.division
      } else {
        this.form.aDivision = t.aDivision
      }
    },

    formatData(d) {
      if (!d) return ''
      let data = `${d.number} -`

      if (d.make) data += ` ${d.make.name}`
      if (d.model) data += ` / ${d.model.name}`
      if (d.color) data += ` / ${d.color}`
      if (d.year) data += ` / ${d.year}`
      if (d.aNote) data += ` *${d.aNote}*`

      return data
    },

    formatDoc(d) {
      const doc = {
        data: this.formatData(d),
        truckNumber: d.number,
        aDivision: d.aDivision,
        aOwner: d.aOwner,
        aOwnerNote: d.aOwnerNote,
        aMileage: d.aMileage,
        trans: d.transmission,
        aInfo: d.aInfo,
        aNote: d.aNote,
        aStatus: d.aStatus || STATUSES[2],
        aIsOnTheList: d.aIsOnTheList,
        _id: d._id,
      }

      return doc
    },

    createStyles(aStatus) {
      if (!aStatus || aStatus.color === 'white') return {}
      return { backgroundColor: aStatus.color }
    },

    searchTrucks: debounce(async function (val) {
      try {
        if (!val || !val.length) {
          this.trucks = []
          return
        }
        this.loading = true
        const { data } = await trucksService.searchTrucks(val)
        if (Array.isArray(data.docs)) this.trucks = data.docs
      } catch (error) {
        console.error('AvailableTable searchTrucks()', error)
        this.trucks = []
        this.error = true
      } finally {
        this.loading = false
      }
    }, 200),
    getDivisonStyle(item) {
      const style = { backgroundColor: ''}

      if (item.aIsOnTheList) style.backgroundColor = 'yellow'
      if (this.dark && item.aIsOnTheList) style['color'] = '#000'

      return style
    }
  }
}
</script>

<style lang="scss">
.AvailableTable {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-divider {
      border-color: transparent;
    }
  }

  &__info {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
  }

  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;

      &.status-column {
        padding: 0px !important;
      }
    }
  }
}

.transfer-item {
  position: relative;
  .transfer-type {
    position: absolute;
    left: 0;
    font-size: 11px;
    bottom: -12px;
    color: #ccc;
  }
}
</style>
