<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title>
        All alerts
      </v-card-title>
      <v-data-table
          height="750"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="alerts"
          class="elevation-1"
          :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template v-slot:item.created="{item}">
          {{item.created | formatDateObj('MM/DD/YYYY HH:mm')}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="openEditDialog(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteAlert(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <!-- Edit Dialog -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title>
          Edit Alert
        </v-card-title>
        <v-card-text>
          <v-textarea
            label="Content"
            v-model="editedAlert.note"
            rows="5"
            :disabled="editedAlert.isSent"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeEditDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveAlert" :disabled="editedAlert.isSent">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import truckService from '../../services/TruckService'

export default {
  name: 'Alerts',
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 15
      },
      totalItems: 0,
      headers: [
        {text: 'Truck number', value: 'truck.number'},
        {text: 'VIN', value: 'truck.vin_number'},
        {text: 'Truck model', value: 'truck.model.name'},
        {text: 'Truck make', value: 'truck.make.name'},
        {text: 'Content', value: 'note'},
        {text: 'Created', value: 'created'},
        {text: 'Sent', value:'isSent'},
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      alerts: [],
      editDialog: false,
      editedAlert: {
        note: '',
        isSent: false
      },
    }
  },
  watch: {
    options: {
      handler () {
        truckService.getAlerts(this.options).then(res => {
          this.alerts = res.data.docs
          this.totalItems = res.data.total
        })
      },
      deep: true,
    }
  },
  methods: {
    openEditDialog(item) {
      this.editedAlert = { ...item }
      this.editDialog = true
    },
    closeEditDialog() {
      this.editDialog = false
    },
    async saveAlert() {
      await truckService.updateAlert(this.editedAlert).then(() => {
        this.closeEditDialog()
        this.fetchAlerts()
      })
    },
    async deleteAlert(item) {

      let confVal = confirm(
        `Are you sure you want to remove alert ${item._id} for truck: ${item.truck.number}?`
      )
      if (confVal) {
        
        await truckService.deleteAlert(item._id).then(() => {
          this.fetchAlerts()
        })
      }
    },
    fetchAlerts() {
      truckService.getAlerts(this.options).then(res => {
        this.alerts = res.data.docs
        this.totalItems = res.data.total
      })
    }
  }
}
</script>

<style lang="scss">
.boards-wrapper {
  width: 100%;
  margin-top: 20px;
}

.v-list-item.move {
  cursor: move;

  .v-list-item__title.teamLead {
    color: green;
  }
}

// @media only screen and (max-width: 599px) {
//   .boards-wrapper {
//     margin-top: 65px;
//   }
// }
</style>