<template>
  <v-snackbar v-model="show">
    {{message}}
    <v-btn text color="accent" @click.native="show = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: ''
    }
  },
  created: function () {
    this.$store.watch(state => state.common.message, () => {
      const msg = this.$store.state.common.message
      if (msg !== '') {
        this.show = true
        this.message = this.$store.state.common.message
        setTimeout(() => {
          this.$store.dispatch('common/setMessage', '')
        }, 1000)
      }
    })
  }
}
</script>
