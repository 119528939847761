import truck from '../../services/TruckService'

const state = {
  truckMake: [],
  truckModel: [],
  formDialog: false
}

const getters = {
  truckMake (state) {
    return state.truckMake
  },
  truckModel (state) {
    return state.truckModel
  },
  formDialog(state){
    return state.formDialog
  }
}

const actions = {
  // Truck
  addTruck({dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return truck.addTruck(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editTruck ({dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return truck.editTruck(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addHistory({ dispatch }, data) {
    dispatch('common/setLoading', true, { root: true })
    return truck.addHistory(data).then(res => {
      dispatch('common/setLoading', false, { root: true })
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, { root: true })
      return Promise.reject(err)
    })
  },
  editHistory({ dispatch }, data) {
    dispatch('common/setLoading', true, { root: true })
    return truck.editHistory(data).then(res => {
      dispatch('common/setLoading', false, { root: true })
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, { root: true })
      return Promise.reject(err)
    })
  },
  exportToExcel({dispatch}, data) {
    dispatch('common/setLoading', true, { root: true })
    return truck.exportTrucks(data).then(res => {
      dispatch('common/setLoading', false, { root: true })
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, { root: true })
      return Promise.reject(err)
    })
  },
  exportAvailableToExcel({dispatch}, data) {
    dispatch('common/setLoading', true, { root: true })
    return truck.exportAvailableTrucks(data).then(res => {
      dispatch('common/setLoading', false, { root: true })
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, { root: true })
      return Promise.reject(err)
    })
  },
  
  // Make
  getTruckMake({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .getTruckMake(payload)
      .then((res) => {
        commit('setTruckMake', res.data.docs)
        dispatch('common/setLoading', false, { root: true })
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  getTruckMakeAll({ dispatch }) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .getAllTruckMake()
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  getTruckModelsByMake({ dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .getAllTruckModelsByMake(payload)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  addTruckMake({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .addTruckMake(payload)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('addTruckMake', res.data)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  editTruckMake({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .editTruckMake(payload)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('editTruckMake', res.data)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  deleteTruckMake({ commit, dispatch }, id) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .deleteTruckMake(id)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('deleteTruckMake', id)
        return Promise.resolve(res)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  //Models

  getTruckModel({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .getTruckModel(payload)
      .then((res) => {
        commit('setTruckModel', res.data.docs)
        dispatch('common/setLoading', false, { root: true })
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  addTruckModel({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .addTruckModel(payload)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('addTruckModel', res.data)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  editTruckModel({ commit, dispatch }, payload) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .editTruckModel(payload)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('editTruckModel', res.data)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },
  deleteTruckModel({ commit, dispatch }, id) {
    dispatch('common/setLoading', true, { root: true })
    return truck
      .deleteTruckModel(id)
      .then((res) => {
        dispatch('common/setLoading', false, { root: true })
        commit('deleteTruckModel', id)
        return Promise.resolve(res)
      })
      .catch((err) => {
        dispatch('common/setLoading', false, { root: true })
        return Promise.reject(err)
      })
  },

  setFormDialog({commit},payload){
    commit('setFormDialog',payload)
  }
}

const mutations = {
  setTruckMake (state, truckMake) {
    state.truckMake = truckMake
  },
  addTruckMake (state, truckMake) {
    const tmpTruckMake = state.truckMake.slice()
    tmpTruckMake.push(truckMake)
    state.truckMake = tmpTruckMake
  },
  editTruckMake (state, truckMake) {
    let tmpTruckMake = state.truckMake.slice()
    let index = tmpTruckMake.findIndex(el => el._id === truckMake._id)
    tmpTruckMake.splice(index, 1, truckMake)
    state.truckMake = tmpTruckMake
  },
  deleteTruckMake (state, id) {
    const tmpTruckMake = state.truckMake.slice()
    tmpTruckMake.splice(tmpTruckMake.findIndex(el => el._id === id), 1)
    state.truckMake = tmpTruckMake
  },



  // Models

  setTruckModel (state, truckModel) {
    state.truckModel = truckModel
  },
  addTruckModel (state, truckModel) {
    const tmpTruckModel = state.truckModel.slice()
    tmpTruckModel.push(truckModel)
    state.truckModel = tmpTruckModel
  },
  editTruckModel (state, truckModel) {
    let tmpTruckModel = state.truckModel.slice()
    let index = tmpTruckModel.findIndex(el => el._id === truckModel._id)
    tmpTruckModel.splice(index, 1, truckModel)
    state.truckModel = tmpTruckModel
  },
  deleteTruckModel (state, id) {
    const tmpTruckModel = state.truckModel.slice()
    tmpTruckModel.splice(tmpTruckModel.findIndex(el => el._id === id), 1)
    state.truckModel = tmpTruckModel
  },

  setFormDialog(state, payload){
    state.formDialog = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
