<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="480"
    min-width="480"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text small>
        <v-badge
          v-if="commentsList && commentsList.length"
          color="deep-purple accent-4"
          :content="commentsList.length"
          offset-x="-3"
          offset-y="15"
        >
          {{ text }}
        </v-badge>
        <template v-else>{{ text }}</template>
      </v-btn>
    </template>
    <v-card>
      <comments
        :comments="commentsList"
        :loading="loading"
        :show-actions="true"
        :with-form="true"
        @delete="handleDeleteComment"
        @save="saveComment"
        @cancel="menu = false"
      />
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'
import Comments from '../Common/Comments.vue'
import service from '../../services/TrailersService'

export default {
  name: 'TrailerComments',
  components: {
    Comments,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      loading: false,
      commentsList: [],
    }
  },
  created() {
    this.commentsList = [...this.comments]
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),
    async handleDeleteComment(commentId) {
      try {
        await service.deleteComment(this.id, this.type, commentId)
        const index = this.commentsList.findIndex(
          (comment) => comment.id === commentId
        )
        this.commentsList.splice(index, 1)
        this.setMessage('Comment successfully delited')
      } catch (error) {
        console.error('handleDeleteComment()', error)
      } finally {
        this.$store.dispatch('common/setLoading', false)
      }
    },
    async saveComment(comment) {
      this.loading = true
      try {
        if (comment._id) {
          const { data } = await service.editComment(
            this.id,
            this.type,
            comment
          )
          this.commentsList = [...data.comments]
          this.setMessage('Comment successfully edited')
        } else {
          const { data } = await service.addComment(
            this.id,
            this.type,
            comment
          )
          this.commentsList = [...data.comments]
          this.setMessage('Comment successfully added')
        }
      } catch (error) {
        console.error('addComment()', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.TrailerComments {
  &__list {
    overflow: scroll;
    max-height: 300px;
    & > *:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  &__left,
  &__right {
    flex: 1 !important;
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  &__change {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 6px !important;
  }
  &__time,
  &__user {
    color: rgb(33, 150, 243);
    font-size: 11px;
    margin: 0 !important;
  }
  &__empty {
    padding: 16px;
    text-align: center;
  }
}
</style>
