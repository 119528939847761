<template>
  <div class="loads-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.shifts') }}
      </v-card-title>
      <v-row class="pl-3">
        <v-col cols="3">
          <v-select
            v-model="form.day"
            hint="Menage shifts for the selected day"
            :items="days"
            item-text="name"
            item-value="day"
            label="Day of the week"
            persistent-hint
            single-line
            @change="onChangeDay"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="cards pl-3 pr-3" v-if="shifts.length">
        <v-col cols="4" :loading="loading" v-for="(shift, index) in shifts" :key="shift._id">
          <v-card
            min-height="200"
            elevation="2"
            outlined
            :color="activeShift === index ? '#E8F5E9FF' : ''"
          >
            <v-card-title>
              <span :class="{'primary--text': activeShift === index}">{{shift.name}}</span>
              <v-spacer></v-spacer>
                <v-btn
                  class="mr-2"
                  color="warning"
                  fab
                  small
                  :disabled="shift.users.length === 0"
                  @click="cleareUsers(shift)"
                >
                  <v-icon>mdi-account-multiple-remove-outline</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  fab
                  small
                  @click="editShift(shift)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </v-card-title>
            <draggable
              class="users"
              @add="onAddUser($event, shift)"
              @remove="onRemoveUser($event, shift)"
              :sort="false"
              :group="{ name: shift.name, pull: true, put: true }"
            >
              <v-chip
                :style="{cursor: 'move'}"
                class="mr-2 mb-1"
                v-for="(user, index) in shift.users"
                :key="`${shift.name}_${user._id}_${index}`"
                :data-id="user._id"
                label
                close
                @click:close="removeUserFromShift(shift._id, user._id)"
              >{{user.name}}</v-chip>
            </draggable>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pl-3 pr-3" v-else>
        <v-col cols="12">
          <p class="text-center subtitle-1">There is no shifts for selected day</p>
        </v-col>
      </v-row>
      <v-row class="users-eld pl-3 pr-3">
        <v-col cols="3">
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            clearable
            dense
            solo
            :light="true"
            height="28"
            label="search users"
            single-line
            hide-details
            @input="onSearch"
            @click:clear="onClearSearch"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="users-eld pl-3 pr-3">
        <v-col>
          <draggable
            v-model="users"
            :sort="false"
            :group="{ name: 'users', pull: 'clone', put: false }"
          >
            <v-chip
              class="mr-2 mb-1"
              :class="{info: filteredArray.findIndex(el => el._id === user._id) !== -1}"
              v-for="user in users"
              :data-id="user._id"
              :key="user._id"
              label
            >{{user.name}}</v-chip>
          </draggable>
        </v-col>
      </v-row>
      <v-fab-transition>
        <v-btn
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="openDialog"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onSaveShift" data-vv-scope="shift">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            {{form._id ? 'Edit' : 'Add' }} shift
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                    readonly
                    ref="type"
                    v-model="form.name"
                    v-validate="'required'"
                    :error="errors.has('shift.name')"
                    :error-messages="errors.first('shift.name')"
                    name="name"
                    id="name"
                    :autofocus="true"
                    placeholder="Name"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-btn
              v-if="form._id"
              color="error"
              @click="onDeleteShift(form._id)"
            >
              Delete
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import draggable from 'vuedraggable'
import moment from 'moment-timezone'

export default {
  name: 'Shifts',
  components: {
    draggable
  },
  data() {
    return {
      formDialog: false,
      form: {
        _id: null,
        name: '',
        users: [],
        day: 0,
      },
      users: [],
      filteredArray: [],
      search: '',
      days: [
        {day: 1, name: 'Monday'},
        {day: 2, name: 'Tuesday'},
        {day: 3, name: 'Wednesday'},
        {day: 4, name: 'Thursday'},
        {day: 5, name: 'Friday'},
        {day: 6, name: 'Saturday'},
        {day: 0, name: 'Sunday'}
      ],
      activeShift: 0
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('shifts', ['shifts'])
  },
  methods: {
    openDialog () {
      this.formDialog = true
      this.form.name = `Shift ${this.shifts.length + 1}`
    },
    closeForm() {
      this.formDialog = false
      this.form._id = null
      this.form.name = ''
      this.form.users = []
    },
    setDay() {
      let userZone = 'Europe/Budapest'
      let date = moment().tz(userZone)
      let tmpHour = date.hour()
      let day = 0
      if (tmpHour < 6) {
        day = date.day() !== 0 ? date.day() - 1 : 6
      } else {
        day = date.day()
      }

      if (tmpHour >= 6 && tmpHour <= 13) {
        // prva smena
        this.activeShift = 0
      }else if (tmpHour >= 14 && tmpHour <= 21) {
        // druga smena
        this.activeShift = 1
      } else if (tmpHour >= 22 || tmpHour <= 5) {
        // treca smena
        this.activeShift = 2
      }

      this.form.day = day
    },
    onSaveShift() {
      this.$validator.validateAll('shift').then((result) => {
        if (result) {
          if (this.form._id) {
            this.$store.dispatch('shifts/editShift', this.form)
          } else {
            this.$store.dispatch('shifts/addShift', this.form)
          }
          this.closeForm()
        }
      })
    },
    editShift(shift) {
      this.formDialog = true
      this.form._id = shift._id
      this.form.name = shift.name
    },
    onDeleteShift(id) {
      let confVal = confirm('Are you sure you want to delete shift?')
      if (confVal) {
        this.$store.dispatch('shifts/deleteShift', id)
        this.closeForm()
      }
    },
    onSearch () {
      if (this.search && this.search.length >= 3) {
        let searchTmp = this.search.toLowerCase()
        this.filteredArray = this.users.filter(user => {
          return user.name.toLowerCase().includes(searchTmp)
        })
      } else {
        this.filteredArray = []
      }
    },
    onClearSearch () {
      this.search = ''
      this.filteredArray = []
    },
    cleareUsers(shift) {
      let confVal = confirm('Are you sure you want to cleare shift?')
      if (confVal) {
        this.$store.dispatch('shifts/removeAllUsers', shift._id)
      }
    },
    removeUserFromShift(shiftId, userId) {
      this.$store.dispatch('shifts/removeUser', {user_id: userId, shift_id: shiftId})
    },
    onAddUser (event, shift) {
      let userId = event.item.dataset.id
      for (let shift of this.shifts) {
        if (shift.users.findIndex(user => user._id === userId) !== -1) {
          let confVal = confirm(`User already exists in shift: ${shift.name}`)
          if (!confVal) {
            return
          }
        }
      }
      
      this.$store.dispatch('shifts/addUser', {user_id: userId, shift_id: shift._id})
    },
    onRemoveUser (event, shift) {
      this.$store.dispatch('shifts/removeUser', {user_id: event.item.dataset.id, shift_id: shift._id})
    },
    onChangeDay () {
      this.$store.dispatch('shifts/getShifts', this.form.day)
    }
  },
  mounted() {
    this.$store.dispatch('user/getUsersByRoleId', 7).then(res => {
      this.users = res
    })

    this.setDay()
    this.$store.dispatch('shifts/getShifts', this.form.day)
  }
}
</script>

<style lang="scss">
.loads-wrapper {
  width: 100%;
  margin-top: 20px;
}

.users {
  padding: 10px;
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}

.users-eld {
  .v-chip {
    cursor: move;
  }
}

// @media only screen and (max-width: 599px) {
//   .loads-wrapper {
//     margin-top: 65px;
//   }
// }
</style>