import Api from '@/services/Api'

export default {
  getLogs (pagination) {
    return Api().get('/logs', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1,
      searchBy: pagination.searchBy,
      search: pagination.search
    }})
  }
}
