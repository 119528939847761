<template>
  <div class="TruckDotInspectionList">
    <v-list v-if="showDots" class="TruckDotInspectionList__list">
      <template v-for="(item, index) of truckAnnualDots">
        <v-list-item :key="`field-history-${item._id}`">
          <v-list-item-content class="TruckDotInspectionList__content">
            <div class="TruckDotInspectionList__stuff">
              <v-list-item-subtitle>
                <strong>Date inspected:</strong>
                {{ formatDate(item.date_inspected) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>Invoice number:</strong> {{ item.invoice_number }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>Amount:</strong> {{ item.amount }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>State:</strong> {{ item.state }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>City:</strong> {{ item.city }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>Shop info:</strong> {{ item.shop_info }}
              </v-list-item-subtitle>
            </div>
            <div class="TruckDotInspectionList__actions">
              <v-icon small color="primary" @click="handleEditDot(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small color="error" @click="handleDeleteDot(item._id)"
                >mdi-delete</v-icon
              >
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="`divider-${index}`"
          v-if="index < truckAnnualDots.length - 1"
        ></v-divider>
      </template>
    </v-list>

    <p class="TruckDotInspectionList__no-history" v-if="noHistory">
      No annual dot inspections...
    </p>

    <v-progress-circular
      v-if="loading"
      indeterminate
      class="TruckDotInspectionList__loader"
      color="primary"
    ></v-progress-circular>

    <v-btn
      @click="handleAddNewInspection"
      class="mt-1"
      block
      elevation="2"
      small
      color="success"
      >Add Annual DOT inspection</v-btn
    >

    <v-dialog v-model="showDialog" width="400px" persistent>
      <v-card>
        <form @submit.prevent="handleSubmitForm" data-vv-scope="truckAnnualDots">
          <v-card-title class="lighten-4 py-4 title">
            {{ this.form._id ? 'Edit' : 'Add' }} Annual DOT inspection
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="invoice_number"
                  v-model="form.invoice_number"
                  v-validate="'required'"
                  :error="errors.has('truckAnnualDots.invoice_number')"
                  :error-messages="
                    errors.first('truckAnnualDots.invoice_number')
                  "
                  name="invoice_number"
                  id="invoice_number"
                  :autofocus="true"
                  placeholder="Invoice Number"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="amount"
                  v-model="form.amount"
                  v-validate="'required'"
                  :error="errors.has('truckAnnualDots.amount')"
                  :error-messages="errors.first('truckAnnualDots.amount')"
                  type="number"
                  name="amount"
                  id="amount"
                  placeholder="Amount"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="state"
                  v-model="form.state"
                  v-validate="'required'"
                  :error="errors.has('truckAnnualDots.state')"
                  :error-messages="errors.first('truckAnnualDots.state')"
                  name="state"
                  id="state"
                  placeholder="State"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="city"
                  v-model="form.city"
                  v-validate="'required'"
                  :error="errors.has('truckAnnualDots.city')"
                  :error-messages="errors.first('truckAnnualDots.city')"
                  name="city"
                  id="city"
                  placeholder="City"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 align-center justify-space-between>
                <v-textarea
                  ref="shop_info"
                  v-model="form.shop_info"
                  v-validate="'required'"
                  :error="errors.has('truckAnnualDots.shop_info')"
                  :error-messages="errors.first('truckAnnualDots.shop_info')"
                  name="shop_info"
                  id="shop_info"
                  placeholder="Shop Info"
                ></v-textarea>
              </v-flex>

              <v-flex xs12>
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :return-value.sync="form.date_inspected"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date_inspected"
                      label="Date inspected"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date_inspected"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="datePicker = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.datePicker.save(form.date_inspected)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="handleCloseDialog"
              >Cancel</v-btn
            >
            <v-btn
              color="info"
              :loading="loading"
              :disabled="!canEdit || loading"
              type="submit"
              >{{ this.form._id ? 'Edit' : 'Add' }}</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import service from '../../services/TruckAnnualDotsService'

export default {
  name: 'TruckDotInspectionList',

  props: ['truck'],

  data() {
    return {
      loading: false,
      showDialog: false,
      truckAnnualDots: [],

      datePicker: false,

      form: {
        _id: null,
        invoice_number: '',
        amount: '',
        state: '',
        city: '',
        shop_info: '',
        date_inspected: this.formatDate(),
        truck_id: this.truck._id,
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['user', 'permissions']),
    showDots() {
      if (this.loading) return false
      return (
        Array.isArray(this.truckAnnualDots) && this.truckAnnualDots.length > 0
      )
    },
    noHistory() {
      if (this.loading) return false
      if (!this.truckAnnualDots) return true
      return (
        Array.isArray(this.truckAnnualDots) && this.truckAnnualDots.length === 0
      )
    },
    canEdit() {
      return this.permissions.TRUCK_EDIT
    },
  },

  mounted() {
    this.loading = true
    service
      .getTruckAnnualDotsByTruckId(this.truck._id)
      .then((res) => {
        if (res) this.truckAnnualDots = res.data.truckAnnualDots
      })
      .catch(console.warn)
      .finally(() => (this.loading = false))
  },

  methods: {
    handleDeleteDot(id) {
      if (!this.permissions.TRUCK_EDIT) return
      const confVal = confirm('Are you sure you want to delete this?')
      if (confVal) {
        this.loading = true
        service
          .deleteTruckAnnualDot(id)
          .then((res) => {
            if (res) {
              this.truckAnnualDots = this.truckAnnualDots.filter(
                (i) => i._id !== id
              )
            }
          })
          .catch(console.warn)
          .finally(() => (this.loading = false))
      }
    },

    handleEditDot({ date_inspected, ...rest }) {
      if (!this.permissions.TRUCK_EDIT) return
      this.showDialog = true
      this.form = {
        ...rest,
        date_inspected: date_inspected ? this.formatDate(date_inspected) : null,
      }
    },

    handleAddNewInspection() {
      this.showDialog = true
    },

    handleCloseDialog() {
      this.showDialog = false
      this.resetForm()
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    resetForm() {
      this.form = {
        _id: null,
        invoice_number: '',
        amount: '',
        state: '',
        city: '',
        shop_info: '',
        date_inspected: this.formatDate(),
        truck_id: this.truck._id,
      }
    },

    handleSubmitForm() {
      if (!this.permissions.TRUCK_EDIT) return
      this.$validator.validateAll('truckAnnualDots').then((result) => {
        if (result) {
          this.loading = true
          if (this.form._id) {
            const { date_inspected, ...rest } = this.form
            service
              .editTruckAnnualDot({
                ...rest,
                date_inspected: new Date(date_inspected),
              })
              .then((res) => {
                if (res) {
                  const { truckAnnualDot } = res.data
                  const temp = [...this.truckAnnualDots]
                  const i = temp.findIndex((h) => h._id === truckAnnualDot._id)
                  temp[i] = truckAnnualDot
                  this.truckAnnualDots = temp
                  this.showDialog = false
                  this.resetForm()
                }
              })
              .catch(console.warn)
              .finally(() => (this.loading = false))
          } else {
            // eslint-disable-next-line no-unused-vars
            const { _id, date_inspected, ...rest } = this.form
            service
              .addTruckAnnualDot({
                ...rest,
                date_inspected: new Date(date_inspected),
              })
              .then((res) => {
                const { truckAnnualDot } = res.data
                this.truckAnnualDots.push(truckAnnualDot)
                this.showDialog = false
                this.resetForm()
              })
              .catch(console.warn)
              .finally(() => (this.loading = false))
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
.TruckDotInspectionList {
  min-width: 400px;
  min-height: 200px;

  &__list {
    max-height: 200px;
    overflow-y: scroll;
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__stuff {
    flex: 1;
  }

  &__actions {
    flex: 0;
    min-width: max-content;
  }

  &__no-history {
    padding-top: 20px;
    text-align: center;
  }

  &__loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
