import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/lang'
import VeeValidate from 'vee-validate'
import './filters/commonFilters'
import VueSocketIO from 'vue-socket.io-extended'
import $socket from './socket-instance'
import VueTour from 'vue-tour'
import ReadonlyComponent from './components/Common/ReadonlyField'
import { fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v2'
import VueClipboard from 'vue-clipboard2'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.config.productionTip = false
require('vue-tour/dist/vue-tour.css')

const compareTimes = {
  getMessage(field, args) {
    return `The ${field} must be lower than ${args[1]}.`
  },
  validate(value, args) {
    let hoursStart = value.split(':')
    let hoursEnd = args[0].split(':')
    let startTime = new Date()
    let endTime = new Date()
    startTime.setHours(hoursStart[0], hoursStart[1], 0)
    endTime.setHours(hoursEnd[0], hoursEnd[1], 0)
    return startTime < endTime
  }
}
VeeValidate.Validator.extend('compare_times', compareTimes)

Vue.use(VeeValidate)
Vue.use(VueTour)
Vue.use(VueSocketIO, $socket)
Vue.use(fpjsPlugin, {
  loadOptions: {
    apiKey: '5yoxJaWTCIxk3i2VE8zX',
    region: 'eu'
  },
})
Vue.use(VueClipboard)
Vue.component('v-readonly-field', ReadonlyComponent)

// Google recaptcha v3
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHAV3,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  }
})

const configParams = {
  APP_KEY: process.env.VUE_APP_KEY,
  LOAD_ORIGIN: process.env.VUE_APP_LOAD_ORIGIN === 'true',
  BROKER_ALERTS: process.env.VUE_APP_BROKER_ALERTS,
  CLOUD_LINK: process.env.VUE_APP_CLOUD_LINK,
  CLOUD_LINK_NEW: process.env.VUE_APP_CLOUD_LINK_NEW,
  COMPANY_INFO: process.env.VUE_APP_COMPANY_INFO,
  DASHBOARD_ADD_DAYS_TO_WEEK: process.env.VUE_APP_DASHBOARD_ADD_DAYS_TO_WEEK,
  DASHBOARD_PRICE_MILES: process.env.VUE_APP_DASHBOARD_PRICE_MILES === 'true',
  TALKJS_APP_ID: process.env.VUE_APP_TALKJS_APP_ID
}

Vue.prototype.$config = Object.freeze(configParams)

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

