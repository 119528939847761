<template>
  <div class="prebookload-card">
    <div class="prebookload-card__left">
      <div class="prebookload-card__city mb-1">
        <span class="prebookload-card__city-name">
          <v-icon color="#191970">mdi-upload</v-icon>{{ load.origin.name }} <template v-if="load.dho">({{ load.dho.trip }} mi)</template>
        </span>
        <span class="prebookload-card__city-date pl-2">
          {{ load.pickup_date | formatDateUSA('dddd, DD MMM') }} {{ load.pickup_time | formatTimeUSA }}
        </span>
      </div>
      <div class="prebookload-card__city mb-1">
        <span class="prebookload-card__city-name">
          <v-icon color="#191970">mdi-download</v-icon> {{ load.destination.name }} <template v-if="load.dhd">({{ load.dhd.trip }} mi)</template>
        </span>
        <span class="prebookload-card__city-date pl-2">
          {{ load.delivery_date | formatDateUSA('dddd, DD MMM') }} {{ load.delivery_time | formatTimeUSA }}
        </span>
      </div>
      <div class="prebookload-card__tags">
        <v-chip class="mr-1" label>{{ load.trailer_type }}</v-chip>
        <v-chip class="mr-1" label>{{ load.weight }} lbs</v-chip>
        <v-chip class="mr-1" label>{{ load.trip }} mi</v-chip>
      </div>
    </div>
    <div class="prebookload-card__right">
      <div class="prebookload-card__right-rate">
        <span class="prebbokload-rate money"> {{ load.suggested_rate | money }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrebookLaodCard',
  props: ['load'],
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.prebookload-card {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    flex: 1;
  }

  &__right {
    &-rate {
      display: flex;
      flex-direction: column;
      align-items: end;

      .prebbokload-rate {
        &.money {
          color: #228B22;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
  }

  &__city {
    display: flex;
    flex-direction: column;
    align-items: start;

    &-name {
      color: #191970;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>