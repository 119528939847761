var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.registrationConfig)?_c('div',{staticClass:"registration-fields d-flex"},[_c('v-select',{staticClass:"mr-1",attrs:{"items":_vm.registrationConfig.options,"label":"Registration","hide-details":"","small":""},on:{"change":_vm.emitFormData},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(
      _vm.registrationConfig.additionalFields &&
      _vm.registrationConfig.additionalFields.includes('amount')
    )?_c('v-text-field',{staticClass:"mr-1",attrs:{"prefix":"$","label":"Amount","type":"number"},on:{"change":_vm.emitFormData},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}):_vm._e(),(
      _vm.registrationConfig.additionalFields &&
      _vm.registrationConfig.additionalFields.includes('start_date')
    )?_c('v-menu',{ref:"startDate",attrs:{"close-on-content-click":false,"return-value":_vm.form.start_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form, "start_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.form, "start_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"label":"Start date","readonly":"","clearable":"","hide-details":"","small":""},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}},'v-text-field',attrs,false),on))]}}],null,false,42385028),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":_vm.emitFormData},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.startDate.save(_vm.form.start_date)}}},[_vm._v(" OK ")])],1)],1):_vm._e(),(
      _vm.registrationConfig.additionalFields &&
      _vm.registrationConfig.additionalFields.includes('until_date')
    )?_c('v-menu',{ref:"untilDate",attrs:{"close-on-content-click":false,"return-value":_vm.form.until_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form, "until_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.form, "until_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Until date","readonly":"","clearable":"","hide-details":"","small":""},model:{value:(_vm.form.until_date),callback:function ($$v) {_vm.$set(_vm.form, "until_date", $$v)},expression:"form.until_date"}},'v-text-field',attrs,false),on))]}}],null,false,3275609351),model:{value:(_vm.untilDateMenu),callback:function ($$v) {_vm.untilDateMenu=$$v},expression:"untilDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":_vm.emitFormData},model:{value:(_vm.form.until_date),callback:function ($$v) {_vm.$set(_vm.form, "until_date", $$v)},expression:"form.until_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.untilDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.untilDate.save(_vm.form.until_date)}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }