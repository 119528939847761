import Api from '@/services/Api'

export default {
  getUserData () {
    return Api().get('/users/me')
  },
  getPermissions () {
    return Api().get('/roles/permissions')
  },
  login (data) {
    return Api().post('/users/login', data)
  },
  verifyOtp (data) {
    return Api().post('/users/verify-otp', data)
  },
  resendToken (data) {
    return Api().post('/users/resend-otp', data)
  },
  logout () {
    return Api().delete('/users/me/token')
  }
}
