<template>
  <div id="prebook-stats">
    <v-card-title>Prebook Statistics</v-card-title>
    <div
      style="
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
      v-if="loading"
    >
      <v-progress-circular indeterminate />
    </div>
    <div v-else-if="chartsData">
      <v-row>
        <v-col cols="3">
          <v-card>
            <v-card-title>Loads count:</v-card-title>
            <v-card-text>
              <ICountUp
                class="countup-number"
                :delay="delay"
                :endVal="chartsData.loadsCount"
                :options="options"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
            <v-card>
              <v-card-title>Booked loads count:</v-card-title>
              <v-card-text>
                <ICountUp
                  class="countup-number"
                  :delay="delay"
                  :endVal="chartsData.bookedLoadsCount"
                  :options="options"
                />
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
            <v-card>
              <v-card-title>Rate sum:</v-card-title>
              <v-card-text>
                <ICountUp
                  class="countup-number green-text"
                  :delay="delay"
                  :endVal="chartsData.ratesSum[0].totalRate"
                  :options="{...options, ...optionsMoney}"
                />
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card>
            <v-card>
              <v-card-title>Suggested rate sum:</v-card-title>
              <v-card-text>
                <ICountUp
                  class="countup-number red-text"
                  :delay="delay"
                  :endVal="chartsData.ratesSum[0].totalSuggestedRate"
                  :options="{...options, ...optionsMoney}"
                />
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>Booked loads by user:</v-card-title>
            <v-card-text>
              <v-simple-table fixed-header height="500px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Booked loads
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in chartsData.countByUser"
                      :key="item._id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ item._id }}</td>
                      <td>{{ item.count }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card>
            <v-card-title>Added loads per user:</v-card-title>
            <v-card-text>
              <v-simple-table fixed-header height="500px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Count
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in chartsData.countByUserWhoCreate"
                      :key="item._id"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ item._id }}</td>
                      <td>{{ item.count }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card>
            <v-card-text>
              <PillarChart title="Top 30 Origins" :chart-data="chartDataOrigins"></PillarChart>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card>
            <v-card-text>
              <PillarChart title="Top 30 Destination" :chart-data="chartDataDestination"></PillarChart>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card>
            <v-card-text>
              <PillarChart title="Top 15 Companies" :chart-data="chartDataCompanies"></PillarChart>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card>
            <v-card-text>
              <PillarChart title="Count by trailer type" :chart-data="chartDataTrailerType"></PillarChart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import PrebookService from '../../services/PrebookService'
import PillarChart from '../Statististics/PillarChart.vue'
import ICountUp from 'vue-countup-v2'

export default {
  name: 'PrebookStats',
  components: {
    ICountUp,
    PillarChart
  },
  data() {
    return {
      loading: false,
      delay: 600,
      chartsData: null,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      optionsMoney: {
        prefix: '$',
      }
    }
  },
  async mounted() {
    await this.getStats()
  },
  computed: {
    chartDataOrigins() {
      if (!this.chartsData) return null
      return {
        labels: this.chartsData.top30Origins.map(location => location._id),
        datasets: [
          {
            label: 'Booked lodas count',
            data: this.chartsData.top30Origins.map(location => location.count),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',  
            borderWidth: 1
          }
        ]
      }
    },
    chartDataDestination() {
      if (!this.chartsData) return null
      return {
        labels: this.chartsData.top30Destinations.map(location => location._id),
        datasets: [
          {
            label: 'Booked lodas count',
            data: this.chartsData.top30Destinations.map(location => location.count),
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }
        ]
      }
    },
    chartDataCompanies() {
      if (!this.chartsData) return null
      return {
        labels: this.chartsData.top15Companies.map(location => location._id),
        datasets: [
          {
            label: 'Booked lodas count',
            data: this.chartsData.top15Companies.map(location => location.count),
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1
          }
        ]
      }
    },
    chartDataTrailerType() {
      if (!this.chartsData) return null
      return {
        labels: this.chartsData.countByTrailerType.map(location => location._id),
        datasets: [
          {
            label: 'Booked lodas count',
            data: this.chartsData.countByTrailerType.map(location => location.count),
            backgroundColor: 'rgba(75, 202, 162, 0.2)',
            borderColor: 'rgba(75, 202, 162, 1)', 
            borderWidth: 1
          }
        ]
      }
    }
  },
  methods: {
    async getStats() {
      this.loading = true
      try {
        const { data } = await PrebookService.getStats()
        this.chartsData = data
        console.log('charts', data)
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    },
    onReady: function(instance) {
      const that = this
      instance.update(that.endVal + 100)
    }
  }
}
</script>

<style lang="scss">
.countup-number {
  color: #6e90da;   
  font-size: 24px;
  font-weight: bold;

  &.green-text {
    color: #85BB65;  
  }

  &.red-text {
    color: #D32F2F
  }
}
</style>