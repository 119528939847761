<template>
  <div class="LeaseStats">
    <pillar-chart
      v-if="chartData"
      :chart-data="chartData"
      stacked
      title="Stats (%)"
    />
  </div>
</template>

<script>
import service from '../../services/LeaseUnitsService'
import PillarChart from '../Statististics/PillarChart.vue'

export default {
  name: 'LeaseStats',

  components: { PillarChart },

  props: {
    companyId: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      chartData: null,
    }
  },

  created() {
    this.getStats()
  },

  methods: {
    async getStats() {
      try {
        const { data } = await service.getStats(this.companyId)

        const chartData = {
          labels: [this.companyName],
          datasets: [
            {
              label: 'Contract Signed',
              backgroundColor: '#ff6384',
              data: [data.contractsSigned],
            },
            {
              label: 'Insurance Received',
              backgroundColor: '#36a2eb',
              data: [data.insurancesRecived],
            },
          ],
        }

        this.chartData = chartData
      } catch (error) {
        console.error('getStats()', error)
      }
    },
  },
}
</script>

<style lang="scss">
.LeaseStats {
  position: relative;
  height: calc(100vh - 172px);

  & > div {
    position: relative;
    height: 100%;
  }
}
</style>
