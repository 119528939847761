<template>
  <div class="yards-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.yards') }}
      </v-card-title>
      <v-data-table
          height="550"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="yards"
          class="elevation-1"
          :loading="loading"
          :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.isSpecial="{item}">
          {{item.isSpecial ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.is_active="{item}">
          {{item.is_active ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
                small
                class="mr-2"
                @click="editYard(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteYard(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddYard" data-vv-scope="yard">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            {{form._id ? 'Edit' : 'Add'}} yard
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                    ref="name"
                    v-model="form.name"
                    v-validate="'required'"
                    :error="errors.has('yard.name')"
                    :error-messages="errors.first('yard.name')"
                    name="name"
                    id="name"
                    :autofocus="true"
                    placeholder="Name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-select
                  v-model="form.type"
                  :items="['TRUCK', 'TRAILER']"
                  multiple
                  label="Select type"
                  hide-details="auto"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                  <v-text-field
                    v-model="form.order"
                    type="number"
                    prepend-icon="mdi-order-numeric-ascending"
                    name="Order"
                    placeholder="Order"
                  ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                    v-model="form.isSpecial"
                    label="Is special?"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                    v-model="form.is_active"
                    label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Yards',
  data() {
    return {
      options: {
        page: 1,
        sortBy: 'order',
        sortDesc: true,
        itemsPerPage: 10
      },
      totalItems: 0,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Is active', value: 'is_active'},
        {text: 'Type', value: 'type'},
        {text: 'Is special?', value: 'isSpecial'},
        {text: 'Order', value: 'order'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        name: '',
        order: null,
        isSpecial: false,
        is_active: true,
        type: null
      },
      formDialog: false,
      orderDialog: false,
    }
  },
  watch: {
    options: {
      handler () {
        this.$store.dispatch('yards/getYards', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('yards', ['yards']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    onAddYard () {
      this.$validator.validateAll('yard').then((result) => {
        if (result) {
          if (this.form._id) {
            this.$store.dispatch('yards/editYard', this.form).then(res => {
              if (res) {
                this.closeForm()
              }
            }).catch(err => {
              if(err.data && err.data.code === 11000) {
                let key = Object.keys(err.data.keyPattern)[0]
                this.errors.add({scope: 'yard', field: key, msg: 'This field must be unique'})
              }
            })
          } else {
            this.$store.dispatch('yards/addYard', this.form).then(res => {
              if (res) {
                this.closeForm()
              }
            }).catch(err => {
              if(err.data && err.data.code === 11000) {
                let key = Object.keys(err.data.keyPattern)[0]
                this.errors.add({scope: 'yard', field: key, msg: 'This field must be unique'})
              }
            })
          }
        }
      })
    },
    editYard (yard) {
      this.form = {...yard}
      this.formDialog = true
    },
    deleteYard (yard) {
      let confVal = confirm(`Are you sure you want to delete yard: ${yard.name}?`)
      if (confVal) {
        this.$store.dispatch('yards/deleteYard', yard._id).then(() => {
          this.setMessage(`${yard.name} successfully deleted`)
        }).catch((err) => {
          console.log('err on delete', err)
        })
      }
    },
    closeForm () {
      this.formDialog = false
      this.form = {
        _id: null,
        name: '',
        order:  null,
        isSpecial: false,
        is_active: true,
        type: null
      }
    }
  }
}
</script>

<style lang="scss">
.yards-wrapper {
  width: 100%;
  margin-top: 20px;
}

.v-list-item.move {
  cursor: move;

  .v-list-item__title.teamLead {
    color: green;
  }
}

// @media only screen and (max-width: 599px) {
//   .boards-wrapper {
//     margin-top: 65px;
//   }
// }
</style>