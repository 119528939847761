<template>
  <v-navigation-drawer
    :class="{'with-footer': footerOn}"
    :clipped="$vuetify.breakpoint.lgAndUp"
    v-model="drawer"
    fixed
    app
  >
    <v-list dense class="pt-14">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title" v-if="user">
            {{user.name}}
          </v-list-item-title>
          <v-list-item-subtitle v-if="user">
            {{user.email}}
          </v-list-item-subtitle>
          <div class="socket-connection" :class="[{connected: $socket.client.connected}]">
            {{$socket.client.connected ? 'online' : 'offline'}}
          </div>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(item, index) in getLinks">
        <v-list-group
        v-if="item.children"
        v-model="item.model"
        :key="item.text"
        :prepend-icon="item.icon"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </template>

        <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.url"
            link
            exact
          >
            <v-list-item-title v-text="$t(child.lang_id)"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else :key="`nav-${index}`" :to="item.url" exact>
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.lang_id) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block color="primary" @click="openSettings">
          Settings
          <v-icon
            right
            dark
          >
            mdi-cog
          </v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { unionBy } from 'lodash'
import { naviConfig, permissionLinksMap } from './naviConfig.js'

export default {
  name: 'mnavigation',
  props: ['footerOn'],
  data: () => (
    {
      items: naviConfig
    }
  ),
  methods: {
    openSettings () {
      this.$store.dispatch('common/toggleSettingsDialog', true)
    }
  },
  computed: {
    ...mapGetters('auth', [
      'getRoles', 'user', 'permissions'
    ]),
    drawer: {
      get () {
        return this.$store.getters['common/drawer']
      },
      set (val) {
        this.$store.dispatch('common/setDrawer', val)
      }
    },
    getLinks () {
      let links = []
      if (this.getRoles) {
        for (let role of this.getRoles) {
          links = [...this.items[role]]
        }
      }

      // Push links by permissions
      const linksFromPermisions = []
      for (let perm in this.permissions) {
        if (permissionLinksMap[perm]) {
          // check if this link exists in links
          let notFound = true
          for (let link of links) {
            if (link.children && link.children.length) {
              let index = link.children.findIndex(child => child.url === permissionLinksMap[perm].url)
              if (index !== -1) {
                notFound = false
              }
            } else if (link.url === permissionLinksMap[perm].url) {
              notFound = false
            }
          }

          if (notFound) linksFromPermisions.push(permissionLinksMap[perm])
        }
      }
      const unionArray = unionBy(links, linksFromPermisions, 'url')
      return unionArray
    },
    activeRoute() {
      return this.$route.path
    }
  }
}
</script>

<style scoped lang="scss">

.v-navigation-drawer {
  &.with-footer {
    max-height: calc(100% - 40px) !important;
  }
}
.socket-connection {
  color: red;
  font-size: 13px;
  font-weight: 600;
  &.connected {
    color: green;
  }
}
</style>

