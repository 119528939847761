import Api from '@/services/Api'

export default {
  getMessages(data) {
    return Api().get(`/chat/get-messages-by-group/${data.groupId}/${data.offset}/${data.limit}`)
  },
  sendMessages(data) {
    return Api().post('/chat/add-message', data)
  },
  getUserGroups() {
    return Api().get('/chat/get-user-groups')
  },
  updateMessage(data) {
    return Api().put('/chat/update-message', data)
  },
  deleteMessage(message) {
    return Api().delete(`/chat/delete-message?messageId=${message._id}`)
  }
}
