import shift from '../../services/ShiftsService'

const state = {
  shifts: []
}

const getters = {
  shifts (state) {
    return state.shifts
  }
}

const actions = {
  getShifts ({commit, dispatch}, day) {
    dispatch('common/setLoading', true, {root: true})
    return shift.getShiftsAndUsers(day).then(res => {
      commit('setShifts', res.data)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllShifts ({dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return shift.getAllShifts().then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addShift ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return shift.addShift(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addShift', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editShift ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return shift.editShift(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editShift', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteShift ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return shift.deleteShift(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteShift', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addUser({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return shift.addUser(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      payload.users = res.data.users
      commit('updateUsers', payload)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  removeUser({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return shift.removeUser(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('removeUser', payload)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  removeAllUsers({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return shift.removeAllUsers(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      if (res.data.status === 'OK')
        commit('removeAllUsers', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setShifts (state, shifts) {
    state.shifts = shifts
  },
  addShift (state, shift) {
    const tmpShifts = state.shifts.slice()
    tmpShifts.push(shift)
    state.shifts = tmpShifts
  },
  editShift (state, shift) {
    let tmpShifts = state.shifts.slice()
    let index = tmpShifts.findIndex(el => el._id === shift._id)
    tmpShifts.splice(index, 1, shift)
    state.shifts = tmpShifts
  },
  deleteShift (state, id) {
    const tmpShifts = state.shifts.slice()
    tmpShifts.splice(tmpShifts.findIndex(el => el._id === id), 1)
    state.shifts = tmpShifts
  },
  updateUsers (state, data) {
    let index = state.shifts.findIndex(el => el._id === data.shift_id)
    state.shifts[index].users = data.users
  },
  addUser (state, data) {
    let index = state.shifts.findIndex(el => el._id === data.shift_id)
    state.shifts[index].users.push(data.user_id)
  },
  removeUser (state, data) {
    let index = state.shifts.findIndex(el => el._id === data.shift_id)
    let userIndex = state.shifts[index].users.findIndex(user => user._id === data.user_id)
    let tmpUsers = state.shifts[index].users.slice()
    tmpUsers.splice(userIndex, 1)
    state.shifts[index].users = tmpUsers
  },
  removeAllUsers (state, id) {
    let index = state.shifts.findIndex(el => el._id === id)
    state.shifts[index].users = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
