import Api from '@/services/Api'

export default {
  getPostTrucks (params) {
    return Api().get('/post-trucks', {params: {
      page: params.page,
      perPage: params.itemsPerPage,
      sortby: params.sortBy,
      origin: params.origin,
      destination: params.destination,
      truckId: params.truckId,
      dho: params.dho,
      dhd: params.dhd,
      order: params.descending ? -1 : 1
    }})
  },
  addPostTruck (postTruck) {
    return Api().post('/post-trucks', postTruck)
  },
  editPostTruck (postTruck) {
    return Api().put('/post-trucks', postTruck)
  },
  deletePostTruck (id) {
    return Api().delete(`/post-trucks/${id}`)
  },
  getAllPostTrucks () {
    return Api().get('/post-trucks/all')
  },
  getPostTruck(id) {
    return Api().get(`/post-trucks/${id}`)
  }
}
