<template>
  <div class="chat-wrapper">
    <v-scale-transition v-if="selectedGroup" origin="bottom right 0">
      <div class="chat-holder" v-if="showChat">
        <v-card
          elevation="2"
          shaped
          width="800"
          height="600"
        >
          <div class="chat-header subtitle-1">
            <span class="group-holder" @click="showGroups = !showGroups">
              <v-icon v-if="showGroups">mdi-arrow-right-thin</v-icon>
              <v-icon v-else >mdi-arrow-left-thin</v-icon> 
              {{selectedGroup.name}}
            </span>

            <span class="icon-right" @click="closeChat">
              <v-icon>mdi-close</v-icon> 
            </span>
          </div>
          <v-divider/>
          <v-scroll-x-transition hide-on-leave>
            <div class="chat-groups" v-if="showGroups">
              <v-list flat>
                <v-list-item-group
                  v-model="selectedGroupIndex"
                  color="primary"
                >
                  <v-list-item
                    v-for="group in chatGroups"
                    :key="group._id"
                    @click="onSelectGroup(group)"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{group.name}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-scroll-x-transition>
          <v-scroll-x-reverse-transition hide-on-leave>
            <div class="chat-messages" v-if="!showGroups">
              <div class="messages-holder" ref="msgholder">
                <div id="loading-holder">
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    :size="30"
                    :width="3"
                    class="mention-loader"
                    color="primary"
                  ></v-progress-circular>
                  <span v-else-if="noMoreMessages" class="back-to-bottom" @click="scrollMessagesToBottom('smooth')" title="Back to bottom"> <v-icon color="#4e8cff">mdi-arrow-down-circle</v-icon></span>
                </div>
                <single-message v-for="message in messages" 
                  :key="message._id" 
                  :content="message.content" 
                  :created="message.createdAt" 
                  :userEmail="message.senderEmail" 
                  :userId="message.userId" 
                  :messageId="message._id"
                  :selectedGroup="selectedGroup.name"
                  @message-updated="onMessageUpdated"
                  @deleteMessage="onDeleteMessage(message)"/>
              </div>
              <div class="input-holder">
                <mention-input
                  @onEnter="handleEnter($event)"
                  @onAppendClick="sendMessage"
                  ref="messageinput"
                  name="message"
                  label="Message"
                  hide-details="auto"
                  v-model="message"
                  append-icon="mdi-send"
                  autofocus
                  :no-resize="true"
                  :loading="loading"
                />
              </div>
            </div>
          </v-scroll-x-reverse-transition>
        </v-card>
      </div>
    </v-scale-transition>
    <div class="chat-btn" v-if="selectedGroup">
      <v-fab-transition>
        <v-btn
          @click="toggleChat"
          color="primary"
          fab
          bottom
          right
          absolute
          small
          class="v-btn--chat"
        >
          <v-badge
            :content="newMessagesCount"
            :value="newMessagesCount"
            color="red"
            overlap
          >
            <v-icon>mdi-note</v-icon>
          </v-badge>
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
import MentionInput from '../Common/MentionInput'
import {mapGetters} from 'vuex'
import ChatService from '../../services/ChatService'
import SingleMessage from './SingleMessage.vue'

export default {
  name: 'Chat',
  components: {
    MentionInput,
    SingleMessage
  },
  data() {
    return {
      newMessagesMap: {},
      showChat: false,
      showGroups: false,
      selectedGroupIndex: 0,
      selectedGroup: null,
      message: '',
      messages: [],
      loading: false,
      limit: 30,
      offset: 0,
      noMoreMessages: false,
      observer: null
    }
  },
  computed: {
    ...mapGetters('auth', ['chatGroups']),
    newMessagesCount() {
      let sum = 0
      for (let key in this.newMessagesMap) {
        sum += this.newMessagesMap[key]
      }
      return sum
    }
  },
  methods: {
    onMessageUpdated(updatedMessage) {
      const index = this.messages.findIndex(message => message._id === updatedMessage.messageId)
      if (index !== -1) {
        this.$set(this.messages, index, { ...this.messages[index], content: updatedMessage.content })
      }
    },
    onDeleteMessage(message) {
      ChatService.deleteMessage(message).then(() => {
        this.messages = this.messages.filter(m => m._id !== message._id)
      }).catch(error => {
        console.log('Error deleting message:', error)
      })
    },
    toggleChat() {
      this.showChat = !this.showChat
      if (this.showChat) {
        this.offset = 0
        this.scrollMessagesToBottom()
        this.resetNewMessages()
        this.getMessages()
        this.initIntersectionObserver()
      } else {
        this.offset = 0
        this.messages = []
        if (this.observer) {
          this.observer.disconnect()
        }
      }
    },
    closeChat() {
      this.showChat = false
      this.offset = 0
      this.messages = []
      if (this.observer) {
        this.observer.disconnect()
      }
    },
    onSelectGroup(group) {
      this.selectedGroup = group
      this.showGroups = false
      this.offset = 0
      this.messages = []
      this.getMessages()
      this.initIntersectionObserver()
    },
    handleEnter(e) {
      if (e.ctrlKey) {
        this.callSendMessage()
      }
    },
    sendMessage() {
      this.callSendMessage()
    },
    callSendMessage() {
      if (this.message.length > 500) {
        return
      }
      this.loading = true
      ChatService.sendMessages({content: this.message, groupId: this.selectedGroup._id}).then(response => {
        if (response.data) {
          this.loading = false
          this.message = ''
        }
      }).catch(error => {
        this.loading = false
        console.log('Erro on send message:', error)
      })
    },
    getMessages() {
      this.loading = true
      ChatService.getMessages({groupId: this.selectedGroup._id, offset: this.offset, limit: this.limit}).then(response => {
        this.loading = false
        if (response.data && response.data.length) {
          if (!this.offset) {
            this.messages = response.data.reverse()
            this.scrollMessagesToBottom()
            if (response.data.length < 30) {
              this.noMoreMessages = true
              if (this.observer) {
                this.observer.disconnect()
              }
            }
          } else {
            this.messages.unshift(...response.data.reverse())
          }
        } else {
          this.noMoreMessages = true
          if (this.observer) {
            this.observer.disconnect()
          }
        }
      }).catch(error => {
        this.loading = false
        console.log('Erro on get messages:', error)
      })
    },
    scrollMessagesToBottom( type = 'instant') {
      setTimeout(() => {
        let scrollingElement = this.$refs.msgholder
        scrollingElement.scrollTo({ top: scrollingElement.scrollHeight, behavior: type })
      }, 100)
    },
    initIntersectionObserver() {
      if (this.observer) {
        this.observer.disconnect()
      }
      setTimeout(() => {
        const options = {
          root: this.$refs.msgholder,
          rootMargin: '110px',
          threshold: 0
        }
        const loadingHolder = document.getElementById('loading-holder')
        this.observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
            this.offset += this.limit
            this.getMessages()
          }
        }, options)
        this.observer.observe(loadingHolder)
      }, 200)
    },
    resetNewMessages() {
      for (let group of this.chatGroups) {
        this.$set(this.newMessagesMap, group._id, 0)
        localStorage.setItem('newMessages', JSON.stringify(this.newMessagesMap))
      }
    }
  },
  sockets: {
    newMessage (message) {
      if (this.showChat) {
        if (message.groupId === this.selectedGroup._id) {
          this.messages.push(message)
          let element = this.$refs.msgholder
          // ako je scroll na kraju kada stigne poruka uradi scroll to bottom
          if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            this.scrollMessagesToBottom('smooth')
          }
        }
      } else if (this.newMessagesMap[message.groupId] !== undefined) {
        this.$set(this.newMessagesMap, message.groupId, this.newMessagesMap[message.groupId] + 1)
        localStorage.setItem('newMessages', JSON.stringify(this.newMessagesMap))
      }
    },
    messageDeleted({ messageId }) {
      this.messages = this.messages.filter(message => message._id !== messageId)
    },
    messageUpdated({ messageId, content }) {
      const messageIndex = this.messages.findIndex(message => message._id === messageId)
      if (messageIndex !== -1) {
        this.$set(this.messages, messageIndex, { ...this.messages[messageIndex], content })
      }
    }
  },
  mounted() {
    this.$store.dispatch('auth/getChatGroups').then(groups => {
      this.selectedGroupIndex = 0
      this.selectedGroup = groups.length ? groups[0] : null

      for (let group of groups) {
        this.$set(this.newMessagesMap, group._id, 0)
        this.$socket.client.emit('joinToChatGroup', group._id)
      }

      if (localStorage.getItem('newMessages')) {
        this.newMessagesMap = JSON.parse(localStorage.getItem('newMessages'))
      }
    })
  }
}
</script>

<style lang="scss">
.chat-holder {
  position: absolute;
  right: 13px;
  bottom: 75px;

  .chat-header {
    text-align: left;
    padding-left: 16px;
    height: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon-right {
      margin-left: auto;
      margin-right: 12px;
    }
  }

  .chat-messages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 50px;

    .messages-holder {
      padding-top: 10px;
      max-height: 500px;
      overflow-y: scroll;

      #loading-holder {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        .back-to-bottom {
          font-size: 12px;
          cursor: pointer;
          color: #4e8cff;
        }
      }
    }
  }
}

.v-btn--chat {
  bottom: 30px !important;
  right: 12px !important;
}
</style>