<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.boards') }}
      </v-card-title>
      <v-data-table
        height="550"
        :disable-sort="true"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalItems"
        :items="boards"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.is_active="{item}">
          {{item.is_active ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              @click="editBoard(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-show="!item.route"
              small
              class="mr-2"
              @click="orderDispatchers(item)"
            >
              mdi-order-numeric-ascending
            </v-icon>
            <v-icon
              small
              @click="deleteBoard(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
          color="pink"
          dark
          absolute
          top
          right
          fab
          @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
        <v-card>
          <form @submit.prevent="onAddBoard" data-vv-scope="board">
            <v-card-title
              class="lighten-4 py-4 title"
            >
              {{form._id ? 'Edit' : 'Add'}} board
            </v-card-title>
            <v-container grid-list-sm class="pa-4">
              <v-layout row wrap>
                <v-flex xs12 align-center justify-space-between>
                    <v-text-field
                      ref="name"
                      v-model="form.name"
                      v-validate="'required'"
                      :error="errors.has('board.name')"
                      :error-messages="errors.first('board.name')"
                      name="name"
                      id="name"
                      :autofocus="true"
                      placeholder="Name"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12 align-center justify-space-between>
                    <v-text-field
                      ref="order"
                      v-model="form.order"
                      v-validate="'required'"
                      :error="errors.has('board.order')"
                      :error-messages="errors.first('board.order')"
                      name="order"
                      id="order"
                      placeholder="Order"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    v-model="form.is_active"
                    label="Is active"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
              <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
            </v-card-actions>
          </form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="orderDialog" width="800px" persistent>
        <v-card>
          <v-card-title
            class="lighten-4 py-4 title"
          >
            Order dispatchers
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-list dense>
              <v-list-item-group
                color="primary"
              >
                <draggable v-model="users">
                  <v-list-item  v-for="(user, i) in users" :id="user._id" :key="user._id" class="move">
                    <v-list-item-icon>
                      {{i + 1}}
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title :title="user.is_team_lead ? 'Team lead' : ''" :class="{teamLead: user.is_team_lead}">{{user.name}} - {{user.email}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </draggable>
              </v-list-item-group>
            </v-list>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="onCloseOrderDialog">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" @click="saveOrder">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import userService from '../../services/UsersService'
import draggable from 'vuedraggable'

export default {
  name: 'Boards',
  components: {
    draggable
  },
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 10
      },
      totalItems: 0,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Order', value: 'order'},
        {text: 'Is active', value: 'is_active'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        name: '',
        order: 0,
        is_active: true
      },
      formDialog: false,
      orderDialog: false,
      users: []
    }
  },
  watch: {
    options: {
      handler () {
        this.$store.dispatch('boards/getBoards', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('boards', ['boards']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    onAddBoard () {
      this.$validator.validateAll('board').then((result) => {
        if (result) {
          if (this.form._id) {
            this.$store.dispatch('boards/editBoard', this.form).then(res => {
              if (res) {
                this.closeForm()
              }
            })
          } else {
            this.$store.dispatch('boards/addBoard', this.form).then(res => {
              if (res) {
                this.closeForm()
              }
            })
          }
        }
      })
    },
    editBoard (board) {
      this.form = {...board}
      this.formDialog = true
    },
    deleteBoard (board) {
      let confVal = confirm(`Are you sure you want to delete board: ${board.name}?`)
      if (confVal) {
        this.$store.dispatch('boards/deleteBoard', board._id).then(() => {
          this.setMessage(`${board.name} successfully deleted`)
        }).catch((err) => {
          console.log('err on delete', err)
        })
      }
    },
    orderDispatchers (board) {
      this.orderDialog = true
      this.$store.dispatch('common/setLoading', true)
      userService.getDispetchersByBoardId(board._id).then(response => {
        this.$store.dispatch('common/setLoading', false)
        this.users = response.data
      })
    },
    onCloseOrderDialog () {
      this.orderDialog = false
      this.users = []
    },
    saveOrder () {
      let index = 1
      for (let user of this.users) {
        user.order = index
        index++
      }
      this.$store.dispatch('common/setLoading', true)
      userService.updateUsers(this.users).then(res => {
        this.$store.dispatch('common/setLoading', false)
        if (res.data.status === 'OK') {
          this.orderDialog = false
          this.users = []
          this.setMessage('Order successfully saved')
        }
      })
      console.log('save order', this.users)
    },
    closeForm () {
      this.formDialog = false
      this.form = {
        _id: null,
        name: '',
        order: 0,
        is_active: true
      }
    }
  }
}
</script>

<style lang="scss">
  .boards-wrapper {
    width: 100%;
    margin-top: 20px;
  }

  .v-list-item.move {
    cursor: move;

    .v-list-item__title.teamLead {
      color: green;
    }
  }

  // @media only screen and (max-width: 599px) {
  //   .boards-wrapper {
  //     margin-top: 65px;
  //   }
  // }
</style>