import Api from '@/services/Api'

export default {
  getTrailerAnnualDotsByTrailerId(id) {
    return Api().get(`/trailers/annual-dots-by-trailer/${id}`)
  },
  addTrailerAnnualDot(trailerAnnualDot) {
    return Api().post('/trailers/annual-dots', trailerAnnualDot)
  },
  editTrailerAnnualDot(trailerAnnualDot) {
    return Api().put('/trailers/annual-dots', trailerAnnualDot)
  },
  deleteTrailerAnnualDot(id) {
    return Api().delete(`/trailers/annual-dots/${id}`)
  },
}
