<template>
  <div class="trailer-owners">
    <v-card>
      <v-card-title>
        {{ type === 'owner' ? $t('other.owners') : $t('other.thirdParty') }}
        <v-spacer></v-spacer>
        <v-text-field
          id="step-3"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('other.search')"
          single-line
          hide-details
          height="30"
          dense
          clearable
        ></v-text-field>
      </v-card-title>
      <v-data-table
        height="750"
        :disable-sort="true"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalItems"
        :items="owners"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 15, 25],
        }"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}
          <v-tooltip v-if="item.note && item.note !== ''" right max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="note-icon" v-bind="attrs" v-on="on" right>
                mdi-comment-text-outline
              </v-icon>
            </template>
            <span style="white-space: pre-wrap">{{ item.note }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.is_active="{ item }">
          {{ item.is_active ? 'YES' : 'NO' }}
        </template>
        <template v-slot:item.temporary="{ item }">
          {{ item.temporary ? 'YES' : 'NO' }}
        </template>
        <!-- <template v-slot:item.is_company="{item}">
          {{item.is_company ? 'YES' : 'NO'}}
        </template> -->
        <!-- <template v-slot:item.drivers="{item}">
          {{driversByCompanyMap[item._id] ? driversByCompanyMap[item._id] : '/'}}
        </template> -->
        <template v-slot:item.actions="{ item }">
          <td class="justify-center layout px-0">
            <v-icon small class="mr-2" @click="editOwner(item)">
              mdi-pencil
            </v-icon>
            <v-icon small class="mr-2" @click="deleteOwner(item)">
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
          color="pink"
          dark
          absolute
          top
          right
          fab
          @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddOwner" data-vv-scope="owner">
          <v-card-title class="lighten-4 py-4 title">
            {{ form._id ? 'Edit' : 'Add' }}
            {{ type === 'owner' ? $t('other.owners') : $t('other.thirdParty') }}
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  ref="name"
                  v-model="form.name"
                  v-validate="'required'"
                  :error="errors.has('owner.name')"
                  :error-messages="errors.first('owner.name')"
                  name="name"
                  id="name"
                  :autofocus="true"
                  placeholder="Name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  rows="3"
                  name="note"
                  label="Note"
                  v-validate="'max:250'"
                  v-model="form.note"
                  id="note"
                  :error="errors.has('owner.note')"
                  :error-messages="errors.first('owner.note')"
                  hint="maximum 250 characters"
                ></v-textarea>
              </v-flex>
              <v-flex xs12 v-if="form.is_company">
                <v-text-field
                  v-model="form.dot"
                  label="Dot #"
                  name="dot"
                  id="dot"
                  :error="errors.has('owner.dot')"
                  :error-messages="errors.first('owner.dot')"
                />
              </v-flex>
              <v-flex xs12 v-if="form.is_company">
                <v-text-field
                  v-model="form.phone"
                  label="Phone"
                  v-validate="'length:10'"
                  type="number"
                  name="phone"
                  id="phone"
                  :error="errors.has('owner.phone')"
                  :error-messages="errors.first('owner.phone')"
                />
              </v-flex>
              <v-flex xs12 v-if="!form.is_company">
                <v-text-field
                  v-model="form.cdl"
                  label="CDL #"
                  name="cdl"
                  id="cdl"
                  :error="errors.has('owner.cdl')"
                  :error-messages="errors.first('owner.cdl')"
                />
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="form.is_active"
                  label="Is active"
                ></v-checkbox>
              </v-flex>

              <v-flex xs12 v-if="form.is_company">
                <v-checkbox
                  v-model="form.temporary"
                  label="Temporary"
                ></v-checkbox>
              </v-flex>
              <!-- <v-flex xs12>
                <v-checkbox
                  v-model="form.is_company"
                  label="Is company"
                ></v-checkbox>
              </v-flex> -->
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
            <v-btn
              color="info"
              :loading="loading"
              :disabled="loading"
              type="submit"
              >{{ form._id ? 'Save' : 'Add' }}</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TrailersService from '../../services/TrailersService'
import debounce from 'lodash/debounce'

const THIRD_PARTY_HEADERS = [
  { text: 'Name', value: 'name' },
  { text: 'Trailers', value: 'trailers' },
  { text: 'Trucks', value: 'trucks' },
  { text: 'Temporary', value: 'temporary' },
  { text: 'DOT #', value: 'dot' },
  { text: 'Phone', value: 'phone' },
  { text: 'Trucks', value: 'trucks' },
  { text: 'Is active', value: 'is_active' },
  { text: 'Actions', value: 'actions', align: 'center' },
]

const OWNERS_HEADERS = [
  { text: 'Name', value: 'name' },
  { text: 'Trailers', value: 'trailers' },
  { text: 'Trucks', value: 'trucks' },
  { text: 'Temporary', value: 'temporary' },
  { text: 'CDL #', value: 'cdl' },
  { text: 'Phone', value: 'phone' },
  { text: 'Trucks', value: 'trucks' },
  { text: 'Is active', value: 'is_active' },
  { text: 'Actions', value: 'actions', align: 'center' },
]

export default {
  data() {
    return {
      options: {
        page: 1,
        sortBy: 'name',
        sortDesc: false,
        itemsPerPage: 15,
        is_company: true,
      },
      search: '',
      totalItems: 0,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Trailers', value: 'trailers' },
        { text: 'Trucks', value: 'trucks' },
        { text: 'Temporary', value: 'temporary' },
        { text: 'CDL #', value: 'cdl' },
        { text: 'DOT #', value: 'dot' },
        { text: 'Phone', value: 'phone' },
        { text: 'Trucks', value: 'trucks' },
        { text: 'Is active', value: 'is_active' },
        { text: 'Actions', value: 'actions', align: 'center' },
      ],
      owners: [],
      formDialog: false,
      type: 'third',
      form: {
        _id: null,
        name: '',
        note: '',
        is_active: false,
        is_company: true,
        dot: null,
        cdl: null,
        temporary: false,
        phone: null,
      },
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
  },
  watch: {
    options: {
      handler() {
        this.getOwners()
      },
      deep: true,
    },
    '$route.params.type': {
      handler() {
        if (this.$route.params.type) {
          this.type = this.$route.params.type
          if (this.type === 'owner') {
            this.form.is_company = false
            this.options.is_company = false
            this.headers = OWNERS_HEADERS
          } else {
            this.form.is_company = true
            this.options.is_company = true
            this.headers = THIRD_PARTY_HEADERS
          }
        }
        this.getOwners()
      },
      deep: true,
      immediate: true,
    },
    search: function (newSearch) {
      this.searchOwners(newSearch)
    },
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),
    searchOwners: debounce(function (search) {
      this.options.search = search
      TrailersService.getOwners(this.options).then((res) => {
        this.totalItems = res.data.owners.total
        this.owners = res.data.owners.docs
      })
    }, 300),
    getOwners() {
      TrailersService.getOwners(this.options).then((res) => {
        this.totalItems = res.data.owners.total
        this.owners = res.data.owners.docs
      })
    },
    onAddOwner() {
      this.$validator.validateAll('owner').then((result) => {
        if (result) {
          if (this.form._id) {
            TrailersService.editOwner(this.form)
              .then((res) => {
                if (res) {
                  this.setMessage(`${this.form.name} successfully edited`)
                  this.closeForm()
                  this.getOwners()
                }
              })
              .catch((err) => {
                if (err.data && err.data.code === 11000) {
                  let key = Object.keys(err.data.keyPattern)[0]
                  this.errors.add({
                    scope: 'owner',
                    field: key,
                    msg: 'This field must be unique',
                  })
                }
              })
          } else {
            TrailersService.addOwner(this.form)
              .then((res) => {
                if (res) {
                  this.setMessage(`${this.form.name} successfully added`)
                  this.closeForm()
                  this.getOwners()
                }
              })
              .catch((err) => {
                if (err.data && err.data.code === 11000) {
                  let key = Object.keys(err.data.keyPattern)[0]
                  this.errors.add({
                    scope: 'owner',
                    field: key,
                    msg: 'This field must be unique',
                  })
                }
              })
          }
        }
      })
    },
    editOwner(owner) {
      this.form = { ...owner }
      this.formDialog = true
    },
    closeForm() {
      this.formDialog = false
      this.form = {
        _id: null,
        name: '',
        note: '',
        is_active: false,
        dot: null,
        cdl: null,
        temporary: false,
        phone: null,
        is_company: this.type === 'owner' ? false : true,
      }
    },
    deleteOwner(owner) {
      let confVal = confirm(
        `Are you sure you want to delete owner: ${owner.name}?`
      )
      if (confVal) {
        TrailersService.deleteOwner(owner._id)
          .then((res) => {
            if (res.data._id) {
              this.setMessage(`${owner.name} successfully deleted`)
              this.getOwners()
            }
          })
          .catch((err) => {
            console.log('err on delete', err)
          })
      }
    },
  },
  mounted() {
    if (this.$route.params.type) {
      this.type = this.$route.params.type
      if (this.type === 'owner') {
        this.form.is_company = false
        this.options.is_company = false
      }
    }
  },
}
</script>

<style>
.trailer-owners {
  width: 100%;
  margin-top: 20px;
}
</style>
