import Api from '@/services/Api'

export default {
  getDashboardData (data) {
    return Api().get('/calls/dashboard', {params: data})
  },
  getArchiveData (data) {
    return Api().post('/calls/archive', data)
  },
  getCall (id) {
    return Api().get(`/calls?id=${id}`)
  },
  addCall (call) {
    return Api().post('/calls', {
      truck: call.truck.truck_id,
      driver_name: call.truck.name,
      callType: call.callType,
      position: call.position,
      shift_id: call.shift_id,
      user_id: call.user_id,
      user_name: call.user_name,
      comments: call.comments
    })
  },
  editCall (call) {
    let data = {
      _id: call._id,
      callType: call.callType,
      shift_id: call.shift_id
    }
    if (typeof call.truck === 'object') {
      data['truck'] = call.truck.truck_id
      data['driver_name'] = call.truck.name
    }
    return Api().put('/calls', data)
  },
  addComment (id, comment, shiftId) {
    return Api().post('/calls/add-comment', {
      callId: id,
      comment: comment,
      shift_id: shiftId
    })
  },
  editComment (id, comment, shiftId) {
    return Api().post('/calls/edit-comment', {
      callId: id,
      comment: comment,
      shift_id: shiftId
    })
  },
  deleteComment (callId, commentId, shiftId) {
    return Api().post('/calls/delete-comment', {
      callId: callId,
      commentId: commentId,
      shift_id: shiftId
    })
  },
  moveCall (oldCall, newCall) {
    return Api().put('/calls/move', {
      oldCall,
      newCall
    })
  },
  deleteCall (id) {
    return Api().delete(`/calls/${id}`)
  },
  getLockedTrucks () {
    return Api().get('/calls/locked-trucks')
  },
  deleteLockedTruck (truck) {
    return Api().delete(`/calls/locked-trucks-delete/${truck}`)
  },
  getMergedDrivers (search, withInactive = false) {
    let url = `/drivers/merged?search=${search}`
    if (withInactive) {
      url += `&withInactive=${withInactive}`
    }
    return Api().get(url)
  },
  getLatestLoadsForDrivers (id) {
    return Api().get(`/drivers/get-latest-loads/${id}`)
  },
  searchCalls (data) {
    let payload = {
      user_name: data.byName,
      truck: data.byTruck
    }
    if (data.page) {
      payload['page'] = data.page
    }
    return Api().post('/calls/search', payload)
  },
  getUnfinished (shiftId) {
    return Api().get(`/calls/unfinished?shift_id=${shiftId}`)
  }
}