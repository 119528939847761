<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div @click="on.click" style="cursor: pointer;">
        <span v-if="truck.lastAnnualDot">
          {{ new Date(truck.lastAnnualDot.date_inspected).toLocaleDateString() }}
        </span>
        <v-icon v-else class="mr-2" small color="black lighten-2">
          mdi-clock-outline
        </v-icon>
      </div>
    </template>
    <v-card>
      <truck-dot-inspection-list v-if="menu" :truck="truck" />
    </v-card>
  </v-menu>
</template>

<script>
import TruckDotInspectionList from './TruckDotInspectionList.vue'

export default {
  name: 'TruckDotInspection',

  components: { TruckDotInspectionList },

  props: ['truck'],

  data() {
    return {
      menu: false,
    }
  },
}
</script>
