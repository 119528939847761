import Api from '@/services/Api'

export default {
  addNewDriver (data) {
    return Api().post('/new-drivers', data)
  },
  editNewDriver (data) {
    return Api().put('/new-drivers', data)
  },
  getNewDrivers (filters) {
    return Api().get('/new-drivers', {params: filters})
  },
  deleteNewDriver (id) {
    return Api().delete(`/new-drivers/${id}`)
  }
}