import { render, staticRenderFns } from "./ShopReview.vue?vue&type=template&id=57872f07&"
import script from "./ShopReview.vue?vue&type=script&lang=js&"
export * from "./ShopReview.vue?vue&type=script&lang=js&"
import style0 from "./ShopReview.vue?vue&type=style&index=0&id=57872f07&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports