<template>
  <div class="post-loads">
    <link rel="stylesheet" href="https://js.api.here.com/v3/3.1/mapsjs-ui.css" />
    <v-data-table
      class="post-loads-table"
      :loading="tableLoader"
      :headers="headers"
      :items="loads"
      :items-per-page="-1"
      :sort-by="[]"
      :sort-desc="[]"
      :no-data-text= "noDataText"
      :item-class="itemRowBackground"
      hide-default-footer
      fixed-header
      multi-sort
      dense
    >
      <template v-slot:top>
        <div class="d-flex flex-column">
          <div class="d-flex align-center mb-2">
            <v-toolbar-title>Post Loads</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <table-filters
              class="mr-2"
              :initial-values="{ ...FILTERS_INITIAL_RADIO }"
              :initial-form="filtersFormRadio"
              hide-clear-button
              @filters-changed="handleFiltersChanged"
            />
            <v-btn small to="/pre-book/posted-trucks">
              Search Trucks
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!canAddLoad" color="primary" dark @click="dialog = true">
              + New load
            </v-btn>
          </div>
          <div class="d-flex align-center mb-2">
            <table-filters
              class="mr-2"
              :initial-values="{ ...options }"
              :initial-form="filtersForm"
              hide-clear-button
              :key="JSON.stringify(options)"
              @filters-changed="handleFiltersChanged"
            />
            <v-spacer></v-spacer>
            <p v-if="loads.length" class="text-caption mt-2">Number of loads: {{ loads.length }}</p>
          </div>
        </div>
      </template>
      <template v-slot:item.loadIdExternal="{ item }">
        {{item.loadIdExternal ? item.loadIdExternal : '/'}}
      </template>
      <template v-slot:item.load_id="{ item }">
        {{ item.load_id ? item.load_id : '/' }}
        <v-tooltip v-if="item.note && item.note.text !== ''" right max-width="350">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="note-icon" v-bind="attrs" v-on="on" right> mdi-comment-text-outline </v-icon>
          </template>
          <span style="white-space: pre-wrap;">{{ item.note.text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.origin="{ item }">
        <td style="cursor: pointer;" @click="openCitiesTimeline(item)">
          <v-badge
            v-if="item.additional_origins"
            color="primary"
            :value="item.additional_origins.length"
            :content="item.additional_origins.length"
            offset-y="10px"
            offset-x="-5px"
            bordered
          >
          {{ item.origin.name }}
          </v-badge>
          <template v-else>{{ item.origin.name }}</template>
        </td>
      </template>
      <template v-slot:item.destination="{ item }">
        <td style="cursor: pointer;" @click="openCitiesTimeline(item)">
          <v-tooltip right max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-if="item.loads_near && item.loads_near.length"
                v-bind="attrs"
                v-on="on"
                class="ma-2"
                color="green"
                text-color="white"
                x-small
              >
              +{{ item.loads_near.length }}
              </v-chip>
            </template>
            <span style="white-space: pre-wrap;">{{$t('PREBOOK_NEAR_LOADS_IN_RADIUS')}}</span>
          </v-tooltip>
          <v-badge
            v-if="item.additional_destinations"
            color="primary"
            :value="item.additional_destinations && item.additional_destinations.length"
            :content="item.additional_destinations && item.additional_destinations.length"
            offset-y="10px"
            offset-x="-5px"
            bordered
          >
            {{ item.destination.name }} {{  }}
          </v-badge>
          <template v-else>{{ item.destination.name }}</template>
        </td>
      </template>
      <template v-slot:item.pickup_date="{ item }">
        {{ item.pickup_date | formatDate }} {{ item.pickup_time }} {{ item.pickup_time_to ? `- ${item.pickup_time_to}` : '' }}
      </template>
      <template v-slot:item.delivery_date="{ item }">
        {{ item.delivery_date | formatDate }} {{ item.delivery_time }} {{ item.delivery_time_to ? `- ${item.delivery_time_to}` : '' }}
      </template>
      <template v-slot:item.dedicated="{ item }">
        <v-icon v-if="item.dedicated">mdi-alpha-d-box</v-icon>
        <span v-else>/</span>
      </template>
      <template v-slot:[`item.dho.trip`]="{ item }">
        <template v-if="item.dho">
          <span>
            {{ item.dho.trip  }} mi
          </span>
          <v-tooltip right max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="td-info" v-bind="attrs" v-on="on" right> mdi-information </v-icon>
            </template>
            <span style="white-space: pre-wrap;">{{$t('PREBOOK_LOADS_DHO_INFO')}}</span>
          </v-tooltip>
        </template>
        <span v-else>/</span>
      </template>
      <template v-slot:[`item.dhd.trip`]="{ item }">
        <template v-if="item.dhd">
          <span>
            {{ item.dhd.trip  }} mi
          </span>
          <v-tooltip right max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="td-info" v-bind="attrs" v-on="on" right> mdi-information </v-icon>
            </template>
            <span style="white-space: pre-wrap;">{{$t('PREBOOK_LOADS_DHO_INFO')}}</span>
          </v-tooltip>
        </template>
        <span v-else>/</span>
      </template>
      <template v-slot:item.trip="{ item }">
        {{ item.trip  }} mi
      </template>
      <template v-slot:item.weight="{ item }">
        {{ item.weight ? `${item.weight} lbs` : '/' }}
      </template>
      <template v-slot:item.rate="{ item }">
        <template v-if="item.rate">{{ item.rate | money}}</template>
        <template v-else>/</template>
      </template>

      <template v-slot:item.booked_user="{ item }">
        <template v-if="item.booked_user">{{ item.booked_user.email }} ({{ item.booked_contact }})</template>
        <template v-else>/</template>
      </template>
      <template v-slot:item.suggested_rate="{ item }">
        <template v-if="item.suggested_rate">{{ item.suggested_rate | money}}</template>
        <template v-else>/</template>
      </template>
      <template v-slot:item.comments="{ item }">
        <comments-dropdown :comments="item.comments || []" :id="item._id" type="postload" />
      </template>
      <!-- ACTION BTNS -->
      <template v-slot:item.actions="{ item }">
        <v-btn v-if="canEditLoad(item)" icon small class="mr-1" color="primary" @click="editItem(item)"><v-icon small> mdi-pencil </v-icon></v-btn>
        <v-btn v-if="canDeleteLoad(item)" icon small class="mr-1" color="error" @click="deletelLoad(item)"><v-icon small> mdi-delete </v-icon></v-btn>
        <v-btn v-if="(item && item.selfDispatch && item.selfDispatch.length > 0) && item.status === 'PENDING'" icon small class="mr-1" color="deep-purple" @click="showDriversInterestedInLoad(item)"><v-icon small> mdi-format-list-numbered </v-icon></v-btn>
        <v-btn icon small class="mr-1" color="deep-purple" @click="viewRoute(item)"><v-icon small> mdi-map </v-icon></v-btn>
        <v-btn v-if="(user && user._id !== item.user.id) && item.status === 'NOT_BOOKED' || item.status === 'PENDING'" width="75" small color="success" :disabled="isPendingDisabled(user && user._id, item && item.selfDispatch || null, item && item.driversWaitingForTheLoad && item.driversWaitingForTheLoad[0])" @click="openBookModal(item)">BOOK</v-btn>
        <v-btn v-if="(user && user._id === item.user.id) && item.status === 'NOT_BOOKED'" width="75" small color="error" @click="deletelLoad(item)">DELETE</v-btn>
        <v-btn v-if="(user && item.booked_user && user._id === item.booked_user.id) && item.status === 'BOOKED'" width="75" small color="error" @click="cancelLoad(item)">CANCEL</v-btn>
        <v-chip color="error" small v-if="item.status === 'CANCELED'">NOT ACTIVE</v-chip>
      </template>
    </v-data-table>

    <v-dialog
        v-model="dialog"
        max-width="840px"
        @click:outside="handleCloseDialog"
      >
        <v-card>
          <v-form @submit.prevent="saveLoad" data-vv-scope="prebook">
            <v-card-title>
              <span class="text-h5">{{form._id ? `Edit load: ${form.load_id}` : 'Post Load'}}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" style="
                      border-right: 2px solid rgba(0, 0, 0, 0.1);
                      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                    ">
                      <span class="caption">Main information</span>
                      <v-row>
                        <v-col cols="2">
                          <v-text-field
                            v-model="form.load_id"
                            v-validate="{ required: true}"
                            data-vv-name="load_id"
                            :error="errors.has('prebook.load_id')"
                            :error-messages="errors.first('prebook.load_id')"
                            name="load_id"
                            label="Load id *"
                            hide-details="auto"
                            small
                          />
                        </v-col>
                        <v-col cols="5">
                          <v-autocomplete
                            v-model="form.origin"
                            :items="citiesListOrigin"
                            item-text="name"
                            return-object
                            v-validate="{ required: true}"
                            data-vv-name="origin"
                            :error="errors.has('prebook.origin')"
                            :error-messages="errors.first('prebook.origin')"
                            label="Origin *"
                            :search-input.sync="searchCitiesOrigin"
                            hide-details="auto"
                            hide-no-data
                            :loading="loading"
                            placeholder="Start typing to Search"
                            prepend-icon="mdi-database-search"
                            clearable
                          >
                          <template v-slot:append-outer>
                            <v-badge
                              color="primary"
                              :value="form.additional_origins.length"
                              :content="form.additional_origins.length"
                              overlap
                              bordered
                            >
                              <v-icon
                                @click="openAdditionalCities('additional_origins')"
                                v-text="!form.additional_origins.length ? 'mdi-plus' : 'mdi-circle-edit-outline'"
                              ></v-icon>
                            </v-badge>
                          </template>
                        </v-autocomplete>
                        </v-col>
                        <v-col cols="5">
                          <v-autocomplete
                            v-model="form.destination"
                            :items="citiesListDestination"
                            item-text="name"
                            return-object
                            v-validate="{ required: true}"
                            data-vv-name="destination"
                            :error="errors.has('prebook.destination')"
                            :error-messages="errors.first('prebook.destination')"
                            label="Destination *"
                            :search-input.sync="searchCitiesDestination"
                            hide-details="auto"
                            hide-no-data
                            :loading="loading"
                            placeholder="Start typing to Search"
                            prepend-icon="mdi-database-search"
                            clearable
                          >
                          <template v-slot:append-outer>
                            <v-badge
                              color="primary"
                              :value="form.additional_destinations.length"
                              :content="form.additional_destinations.length"
                              overlap
                              bordered
                            >
                              <v-icon
                                @click="openAdditionalCities('additional_destinations')"
                                v-text="!form.additional_destinations.length ? 'mdi-plus' : 'mdi-circle-edit-outline'"
                              ></v-icon>
                            </v-badge>
                          </template>
                        </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            ref="pickUpDateMenu"
                            v-model="pickUpDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.pickup_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="form.pickup_date"
                                v-validate="{ required: true}"
                                data-vv-name="pickup_date"
                                :error="errors.has('prebook.pickup_date')"
                                :error-messages="errors.first('prebook.pickup_date')"
                                label="Pickup Date *"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                hide-details="auto"
                                small
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="form.pickup_date"
                              no-title
                              scrollable
                              @input="$refs.pickUpDateMenu.save(form.pickup_date)"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            ref="pickupTimeMenu"
                            v-model="pickupTimeMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.pickup_time"
                            transition="scale-transition"
                            offset-y
                            min-width="310"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="form.pickup_time"
                                v-validate="{ required: true}"
                                data-vv-name="pickup_time"
                                :error="errors.has('prebook.pickup_time')"
                                :error-messages="errors.first('prebook.pickup_time')"
                                label="P. Time *"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details="auto"
                                small
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="pickupTimeMenu"
                              width="310"
                              format="24hr"
                              v-model="form.pickup_time"
                              full-width
                              @click:minute="$refs.pickupTimeMenu.save(form.pickup_time)"
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            ref="pickupTimeToMenu"
                            v-model="pickupTimeToMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.pickup_time_to"
                            transition="scale-transition"
                            offset-y
                            min-width="310"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="form.pickup_time_to"
                                label="To"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details="auto"
                                small
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="pickupTimeToMenu"
                              width="310"
                              format="24hr"
                              v-model="form.pickup_time_to"
                              full-width
                              @click:minute="$refs.pickupTimeToMenu.save(form.pickup_time_to)"
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            ref="deliveryDateMenu"
                            v-model="deliveryDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.delivery_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="form.delivery_date"
                                v-validate="{ required: true}"
                                data-vv-name="delivery_date"
                                :error="errors.has('prebook.delivery_date')"
                                :error-messages="errors.first('prebook.delivery_date')"
                                label="Delivery Date *"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                hide-details="auto"
                                small
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="form.delivery_date"
                              no-title
                              scrollable
                              @input="$refs.deliveryDateMenu.save(form.delivery_date)"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            ref="deliveryTimeMenu"
                            v-model="deliveryTimeMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.delivery_time"
                            transition="scale-transition"
                            offset-y
                            min-width="310"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="form.delivery_time"
                                v-validate="{ required: true}"
                                data-vv-name="delivery_time"
                                :error="errors.has('prebook.delivery_time')"
                                :error-messages="errors.first('prebook.delivery_time')"
                                label="D. Time *"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details="auto"
                                small
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="deliveryTimeMenu"
                              width="310"
                              format="24hr"
                              v-model="form.delivery_time"
                              full-width
                              @click:minute="$refs.deliveryTimeMenu.save(form.delivery_time)"
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="1">
                          <v-menu
                            ref="deliveryTimeToMenu"
                            v-model="deliveryTimeToMenu"
                            :close-on-content-click="false"
                            :return-value.sync="form.delivery_time_to"
                            transition="scale-transition"
                            offset-y
                            min-width="310"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="form.delivery_time_to"
                                label="To"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details="auto"
                                small
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="deliveryTimeToMenu"
                              width="310"
                              format="24hr"
                              v-model="form.delivery_time_to"
                              full-width
                              @click:minute="$refs.deliveryTimeToMenu.save(form.delivery_time_to)"
                            ></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            v-model="form.company"
                            :items="prebookCompanies"
                            item-text="name"
                            item-value="name"
                            v-validate="{ required: true}"
                            data-vv-name="company"
                            :error="errors.has('prebook.company')"
                            :error-messages="errors.first('prebook.company')"
                            label="Company *"
                            small
                            hide-details="auto"
                            clearable
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-if="typeof form.doc === 'string' && form.doc.length"
                            v-model="form.doc"
                            clearable
                            readonly
                            @click:prepend="handleOpenPDF(form.doc)"
                            prepend-icon="mdi-file"
                            label="File"
                          />
                          <v-file-input
                            v-else
                            v-model="form.file"
                            label="File"
                            accept="application/pdf"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" style="
                      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                    ">
                      <span class="caption">Broker contact</span>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.contact.name"
                            v-validate="{ required: true}"
                            data-vv-name="contact_name"
                            :error="errors.has('prebook.contact_name')"
                            :error-messages="errors.first('prebook.contact_name')"
                            name="contact_name"
                            label="Name *"
                            hide-details="auto"
                            small
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.contact.email"
                            v-validate="{ required: true, email: true}"
                            data-vv-name="contact_email"
                            :error="errors.has('prebook.contact_email')"
                            :error-messages="errors.first('prebook.contact_email')"
                            name="contact_email"
                            label="Email *"
                            hide-details="auto"
                            small
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="form.contact.phone"
                            v-validate="{ required: false, numeric: true}"
                            data-vv-name="contact_phone"
                            :error="errors.has('prebook.contact_phone')"
                            :error-messages="errors.first('prebook.contact_phone')"
                            name="contact_phone"
                            label="Phone"
                            hide-details="auto"
                            small
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" style="
                      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                    ">
                      <span class="caption">Load info</span>
                      <v-row>
                        <v-col cols="3">
                          <v-select
                            v-model="form.trailer_type"
                            :items="trailerTypes"
                            v-validate="{ required: true}"
                            data-vv-name="trailer_type"
                            :error="errors.has('prebook.trailer_type')"
                            :error-messages="errors.first('prebook.trailer_type')"
                            label="Trailer type *"
                            small
                            hide-details="auto"
                            clearable
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model="form.weight"
                            v-validate="{ required: true, numeric: true}"
                            data-vv-name="weight"
                            :error="errors.has('prebook.weight')"
                            :error-messages="errors.first('prebook.weight')"
                            name="weight"
                            label="Weight *"
                            hide-details="auto"
                            small
                            prepend-inner-icon="mdi-weight"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model="form.rate"
                            v-validate="'required|decimal:2'"
                            data-vv-name="rate"
                            :error="errors.has('prebook.rate')"
                            :error-messages="errors.first('prebook.rate')"
                            name="rate"
                            label="Rate *"
                            hide-details="auto"
                            small
                            prepend-inner-icon="mdi-currency-usd"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            type="number"
                            v-model="form.suggested_rate"
                            v-validate="'required|decimal:2'"
                            data-vv-name="suggested_rate"
                            :error="errors.has('prebook.suggested_rate')"
                            :error-messages="errors.first('prebook.suggested_rate')"
                            name="suggested_rate"
                            label="Suggested Rate"
                            hide-details="auto"
                            small
                            prepend-inner-icon="mdi-currency-usd"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-row>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="form.dedicated"
                          label="Dedicated"
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>
                    <v-col cols="12">
                      <v-text-field v-model="form.note.text" label="Note" hide-details />
                      <div v-if="form.note && form.note.user_email">
                        <span class="LeaseCompanies__note-person"
                          >{{ form.note.user_name }} ({{
                            form.note.user_email
                          }})</span
                        >
                        <span class="LeaseCompanies__note-time">{{
                          form.note.entered | formatDateUSA('MM/DD/YYYY hh:mm')
                        }}</span>
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="form._id">
                      <comments
                        :comments="form.comments"
                        :loading="loading"
                        cancel-text="Clear"
                        :autofocus="false"
                        :show-actions="true"
                        :with-form="true"
                        @delete="handleDeleteComment"
                        @save="saveComment"
                      />
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="handleCloseDialog"> Cancel </v-btn>
              <v-btn :disabled="!canAddLoad" color="blue darken-1" text type="submit" :loading="loading"> Save </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
    </v-dialog>

    <!-- PDF viewer dialog -->
    <v-dialog
      v-model="pdfDialog"
      max-width="90vw"
      @click:outside="handleClosePDFDialog"
    >
      <vue-pdf
        v-if="pdfUrl"
        :source="pdfUrl"
        style="height: 90vh; width: 90vw"
      />
    </v-dialog>

    <!-- Map route dialog -->
    <v-dialog
      v-model="mapDialog"
      width="600"
      @click:outside="closeMap"
    >
      <HereMap v-if="selectedLoad" :origin="selectedLoad.origin" :destination="selectedLoad.destination"/>
    </v-dialog>

    <!-- Book dialog -->
    <v-dialog
        v-model="bookDialog"
        max-width="600px"
        @click:outside="handleCloseBookDialog"
      >
        <v-card v-if="this.selectedLoad">
          <v-form @submit.prevent="bookLoad" data-vv-scope="book">
            <v-card-title>
              <span class="text-h5">Book load: {{this.selectedLoad.load_id}}</span>
            </v-card-title>
            <v-card-text>
                <v-container class="pa-0">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.booked_contact"
                        v-validate="{ required: true, numeric: true}"
                        data-vv-name="booked_contact"
                        :error="errors.has('book.booked_contact')"
                        :error-messages="errors.first('book.booked_contact')"
                        name="booked_contact"
                        label="Phone number *"
                        hide-details="auto"
                        small
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="form.booked_company"
                        :items="companiesAll"
                        label="Company"
                        small
                        hide-details
                        clearable
                      />
                    </v-col>
                    <v-col cols="12" v-if="selectedLoad">
                      <comments
                        :comments="form.comments"
                        :loading="loading"
                        cancel-text="Clear"
                        :autofocus="false"
                        :show-actions="true"
                        :with-form="true"
                        @delete="handleDeleteComment"
                        @save="saveComment"
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <template v-if="this.selectedLoad.additional_origins.length">
                  <p class="caption">Origins:</p>
                  <vue-horizontal-timeline :clickable="false" :title-substr="50" min-height="30px" min-width="200px" :items="formatAdditionalCitiesName(this.selectedLoad.additional_origins)" title-attr="name" />
                </template>
                <template v-if="this.selectedLoad.additional_destinations.length">
                  <p class="caption mt-2">Destinations:</p>
                  <vue-horizontal-timeline :clickable="false" :title-substr="50" min-height="30px" min-width="200px" :items="formatAdditionalCitiesName(this.selectedLoad.additional_destinations)" title-attr="name" />
                </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="handleCloseBookDialog"> Cancel </v-btn>
              <v-btn color="blue darken-1" text type="submit" :loading="loading"> Book now </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
    </v-dialog>

    <!-- Additional cities dialog -->
    <v-dialog
        v-model="additionalCitiesDialog"
        max-width="600px"
        @click:outside="handleCloseAdditionalDialog"
      >
        <v-card v-if="form[this.additionalCitiesType]">
            <v-card-title>
              <span class="text-h5">{{this.selectedLoad ? 'Edit' : 'Add'}} additional {{ additionalCitiesType && additionalCitiesType.split('_')[1] }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="mb-0 pb-0" v-for="(city, index) in form[this.additionalCitiesType]" :key="city.name">
                      <v-text-field
                        readonly
                        :value="`${city.name}  ${$options.filters.formatDateUtc(city.date, 'MM/DD/YYYY')} (${city.time_from} - ${city.time_to})`"
                        :label="`City ${index + 1}`"
                        hide-details
                        small
                      >
                      <template v-slot:append-outer>
                        <v-icon
                          @click="removeCity(index)"
                          v-text="'mdi-delete'"
                        ></v-icon>
                        </template>
                    </v-text-field>
                    </v-col>
                    <div class="d-flex">
                      <v-col cols="4">
                        <v-autocomplete
                            :disabled="form[this.additionalCitiesType] && form[this.additionalCitiesType].length >= 10"
                            v-model="additionalCityForm.city"
                            :items="citiesListAdditional"
                            item-text="name"
                            return-object
                            label="Add city"
                            :search-input.sync="searchCitiesAdditional"
                            hide-details="auto"
                            hide-no-data
                            :loading="loading"
                            placeholder="Start typing to Search"
                            prepend-icon="mdi-database-search"
                            clearable
                          >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-menu
                            ref="additionalCityDateMenu"
                            v-model="additionalCityDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="additionalCityForm.date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="additionalCityForm.date"
                                label="Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                hide-details="auto"
                                small
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="additionalCityForm.date"
                              no-title
                              scrollable
                              @input="$refs.additionalCityDateMenu.save(additionalCityForm.date)"
                            >
                            </v-date-picker>
                          </v-menu>
                      </v-col>
                      <v-col cols="2">
                          <v-menu
                            ref="additionalTimeMenuRef"
                            v-model="additionalTimeMenu"
                            :close-on-content-click="false"
                            :return-value.sync="additionalCityForm.timeFrom"
                            transition="scale-transition"
                            offset-y
                            min-width="310"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="additionalCityForm.timeFrom"
                                label="From"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details="auto"
                                small
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="additionalTimeMenu"
                              width="310"
                              format="24hr"
                              v-model="additionalCityForm.timeFrom"
                              full-width
                              @click:minute="$refs.additionalTimeMenuRef.save(additionalCityForm.timeFrom)"
                            ></v-time-picker>
                          </v-menu>
                      </v-col>
                      <v-col cols="2">
                          <v-menu
                            ref="additionalTimeToMenuRef"
                            v-model="additionalTimeToMenu"
                            :close-on-content-click="false"
                            :return-value.sync="additionalCityForm.timeTo"
                            transition="scale-transition"
                            offset-y
                            min-width="310"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="additionalCityForm.timeTo"
                                label="To"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details="auto"
                                small
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              v-if="additionalTimeToMenu"
                              width="310"
                              format="24hr"
                              v-model="additionalCityForm.timeTo"
                              full-width
                              @click:minute="$refs.additionalTimeToMenuRef.save(additionalCityForm.timeTo)"
                            ></v-time-picker>
                          </v-menu>
                      </v-col>
                      <v-col cols="1" class="d-flex">
                        <v-icon
                          v-text="'mdi-plus'"
                          @click="pushCity"
                        ></v-icon>
                      </v-col>
                    </div>
                  </v-row>
                </v-container>
            </v-card-text>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="handleCloseAdditionalDialog"> Close </v-btn>
            </v-card-actions> -->
        </v-card>
    </v-dialog>

    <!-- Drivers waiting for load dialog -->
    <v-dialog
        v-model="driversWaitingForLoadDialog"
        width="600"
        @click:outside="closeDriversWaitingDialog()"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Drivers waiting for the load</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <ul>
                <li v-for="driver in driversList" :key="driver._id">{{ driver.name }} {{ driver.email }}</li>
              </ul>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Cities timeline -->
    <v-dialog
        v-model="additionalCitiesShow"
        max-width="800px"
        @click:outside="closeCitiesTimeline"
      >
        <v-card v-if="this.selectedLoad">
            <v-card-title>
              <span class="text-h5">Additional info</span>
            </v-card-title>
            <v-card-text>Nearby loads located within a 50-mile radius of your selected load</v-card-text>
              <v-container fluid v-if="this.selectedLoad.loads_near">
                <v-row dense>
                  <v-col xs="12" sm="6" md="4" lg="6" v-for="load in this.selectedLoad.loads_near" :key="load.load_id" >
                    <v-card>
                      <v-card-title>Load id: {{load.load_id}}</v-card-title>
                      <v-card-text>
                        Pickup date:{{ load.pickup_date | formatDate }}
                        <v-spacer></v-spacer>
                        Origin name: {{ load.origin.name }}
                        <v-spacer></v-spacer>
                        Destination name:  {{ load.destination.name }}
                        <v-spacer></v-spacer>
                        Delivery date: {{ load.delivery_date | formatDate }}
                        <v-spacer></v-spacer>
                        Trip: {{ load.trip }} mi
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            <v-card-text>
              <p class="caption">Origins:</p>
              <vue-horizontal-timeline v-if="this.selectedLoad.additional_origins.length" :title-substr="50" :clickable="false" min-height="30px" min-width="200px" :items="formatAdditionalCitiesName(this.selectedLoad.additional_origins)" title-attr="name" />
              <p class="caption mt-2">Destinations:</p>
              <vue-horizontal-timeline v-if="this.selectedLoad.additional_destinations.length" :title-substr="50" :clickable="false" min-height="30px" min-width="200px" :items="formatAdditionalCitiesName(this.selectedLoad.additional_destinations)" title-attr="name" />
            </v-card-text>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="handleCloseAdditionalDialog"> Close </v-btn>
            </v-card-actions> -->
        </v-card>
    </v-dialog>

    <!-- Confirmation dialog -->
    <v-dialog
      v-model="confirmDialog.active"
      max-width="500px"
      @click:outside="confirmDialogClose"
    >
      <v-card v-if="selectedLoad">
        <v-card-title class="text-h5"
          >{{ confirmDialog.text }}</v-card-title
        >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="confirmDialog.action === 'cancelLoadApplay'">
                <comments
                  :comments="form.comments"
                  :loading="loading"
                  cancel-text="Clear"
                  :autofocus="false"
                  :show-actions="true"
                  :with-form="true"
                  @delete="handleDeleteComment"
                  @save="saveComment"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="confirmDialogClose">Cancel</v-btn>
          <v-btn color="error" text @click="confirmDialogAction"
            >YES</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { serialize } from 'object-to-formdata'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import loadsService from '../../services/LoadsService'
import TableFilters from '../TableFilters.vue'
import HereMap from '../Common/HereMap.vue'
import VuePDF from 'pdf-viewer-vue/dist/vue2-pdf-viewer'
import Comments from '../Common/Comments.vue'
import CommentsDropdown from './CommentsDropdown'
import { commentsMixin } from './CommentsMixin'
import PrebookService from '../../services/PrebookService'
import { VueHorizontalTimeline } from 'vue-horizontal-timeline'
import { isDateInRange } from '../../utils/index'

const FILTERS_INITIAL_RADIO = {
  filter: 'notbooked'
}

const INITIAL_FORM = {
  _id: null,
  load_id: '',
  pickup_date: null,
  pickup_time: '12:00',
  pickup_time_to: '',
  delivery_date: null,
  dedicated: false,
  delivery_time: '12:00',
  delivery_time_to: '',
  origin: null,
  additional_origins: [],
  destination: null,
  additional_destinations: [],
  company: '',
  contact: {
    name: '',
    phone: '',
    email: ''
  },
  trailer_type: 'V',
  weight: null,
  rate: null,
  suggested_rate: null,
  file: null,
  note: {
    text: ''
  },
  booked_contact: '',
  booked_company: '',
  comments: []
}

export default {
  components: {
    TableFilters,
    Comments,
    CommentsDropdown,
    HereMap,
    'vue-pdf': VuePDF,
    VueHorizontalTimeline
  },
  mixins: [commentsMixin],
  data() {
    return {
      trailerTypes: ['V', 'F', 'R', 'D&H'],
      form: { ...INITIAL_FORM },
      editId: null,
      loads: [],
      options: {
        loadId: null,
        filter: 'notbooked',
        origin: null,
        destination: null,
        trailerType: null,
        pickup: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).add(1, 'days').format('YYYY-MM-DD')],
        dho: 300,
        dhd: 300,
        weight: null
      },
      totalItems: 0,
      headers: [
        {
          text: 'Load ID',
          value: 'load_id',
          width: 120,
          sortable: false,
        },
        {
          text: 'ID',
          value: 'loadIdExternal',
          width: 120,
          sortable: false,
        },
        {
          text: 'Pickup',
          value: 'pickup_date',
          width: 200,
          sortable: false,
        },
        {
          text: 'DH-O',
          value: 'dho.trip',
          sortable: true,
          width: 120,
        },
        {
          text: 'Origin',
          value: 'origin',
          sortable: false,
          width: 150,
        },
        {
          text: 'Trip',
          value: 'trip',
          sortable: true,
          width: 100,
        },
        {
          text: 'Destination',
          value: 'destination',
          sortable: false,
          width: 185,
        },
        {
          text: 'Delivery',
          value: 'delivery_date',
          sortable: false,
          width: 200,
        },
        {
          text: 'Dedicated',
          value: 'dedicated',
          sortable: false,
          width: 200,
        },
        {
          text: 'DH-D',
          value: 'dhd.trip',
          sortable: true,
          width: 120,
        },
        {
          text: 'Trailer type',
          value: 'trailer_type',
          sortable: true,
          width: 50,
        },
        {
          text: 'Weight',
          value: 'weight',
          sortable: true,
          width: 100,
        },
        {
          text: 'Rate',
          value: 'rate',
          sortable: true,
          width: 100,
        },
        {
          text: 'Company',
          value: 'company',
          width: 200,
        },
        {
          text: 'Contact person',
          value: 'contact.name',
          sortable: false,
          width: 150,
        },
        {
          text: 'Email',
          value: 'contact.email',
          sortable: false,
          width: 150,
        },
        {
          text: 'Phone',
          value: 'contact.phone',
          sortable: false,
          width: 150,
        },
        {
          text: 'Posted by',
          value: 'user.email',
          sortable: false,
          width: 150,
        },
        {
          text: 'Booked by',
          value: 'booked_user',
          sortable: false,
          width: 150,
        },
        {
          text: 'Booked company',
          value: 'booked_company',
          sortable: false,
          width: 150,
        },
        {
          text: 'Suggested rate',
          value: 'suggested_rate',
          sortable: true,
          width: 100,
        },
        { text: 'Comments', value: 'comments', width: 120, sortable: false, },
        {
          text: 'Actions',
          value: 'actions',
          width: 210,
          align: 'right',
          sortable: false
        },
      ],
      FILTERS_INITIAL_RADIO: {
        ...FILTERS_INITIAL_RADIO
      },
      filtersForm: {
        loadId: {
          label: 'Load ID',
          type: 'text',
        },
        loadIdExternal: {
          label: 'ID',
          type: 'text',
        },
        trailerType: {
          label: 'Trailer type',
          type: 'select',
          options: [{text: 'V', value: 'V'}, {text: 'F', value: 'F'}, {text: 'R', value: 'R'}, {text: 'D&H', value: 'D&H'}]
        },
        origin: {
          type: 'autocomplete',
          label: 'Origin',
          apiUrl: '/loads/autocomplete/cities',
          dataKey: 'cities'
        },
        destination: {
          type: 'autocomplete',
          label: 'Destination',
          apiUrl: '/loads/autocomplete/cities',
          dataKey: 'cities'
        },
        pickup: {
          label: 'Avail',
          type: 'date-time',
          range: true,
          maxRange: 3,
          clearable: true
        },
        dho: {
          label: 'DH-O radius',
          type: 'text',
          inputType: 'number'
        },
        dhd: {
          label: 'DH-D radius',
          type: 'text',
          inputType: 'number'
        },
        weight: {
          label: 'Weight',
          type: 'text',
          inputType: 'number'
        }
      },
      filtersFormRadio: {
        filter: {
          type: 'radio-group',
          options: [
            { label: 'Load list', value: 'notbooked' },
            { label: 'Booked by me', value: 'booked' },
            { label: 'Posted by me', value: 'posted' }
          ],
        }
      },
      citiesListOrigin: [],
      citiesListDestination: [],
      citiesListAdditional: [],
      dialog: false,
      mapDialog: false,
      driversWaitingForLoadDialog: false,
      driversList: [],
      pickUpDateMenu: false,
      pickupTimeMenu: false,
      pickupTimeToMenu: false,
      deliveryDateMenu: false,
      deliveryTimeMenu: false,
      deliveryTimeToMenu: false,
      searchCitiesOrigin: '',
      searchCitiesDestination: '',
      searchCitiesAdditional: '',
      pdfDialog: false,
      pdfUrl: null,
      selectedLoad: null,
      bookDialog: false,
      confirmDialog: {
        active: false,
        text: '',
        action: null
      },
      tableLoader: false,
      prebookCompanies: [],
      additionalCitiesDialog: false,
      additionalCitiesType: '',
      additionalCitiesShow: false,
      additionalTimeMenu: false,
      additionalTimeToMenu: false,
      additionalCityForm: {
        city: null,
        date: moment(new Date()).format('YYYY-MM-DD'),
        timeFrom: '',
        timeTo: ''
      },
      additionalCityDateMenu: false
    }
  },
  sockets: {
    connect() {
      this.$socket.client.emit('join_prebook_loads')
    },
    edit_prebook_load(load) {
      if (load.status === 'PENDING') {
        const index = this.loadsMap[load._id]

        if (index !== undefined) {
          this.loads.splice(index, 1, load)
        }
      } else if (load.status !== 'NOT_BOOKED') {
        // remove load from view
        if (this.loadsMap[load._id] !== undefined) {
          this.loads.splice(this.loadsMap[load._id], 1)
        }
      } else if (load.status === 'NOT_BOOKED' && this.options.filter === 'notbooked' && isDateInRange(load.pickup_date, this.options.pickup)) {
        // znaci da je vracena iz CANCEL statusa, dodaj na tabelu
        this.loads.push(load)
      }
    },
    new_prebook_load(load) {
      if (isDateInRange(load.pickup_date, this.options.pickup) && this.options.filter === 'notbooked') {
        this.loads.push(load)
      }
    }
  },
  watch: {
    searchCitiesOrigin (val) {
      if (val) {
        this.debounceSearch(val, 'citiesListOrigin')
      }
    },
    searchCitiesDestination (val) {
      if (val) {
        this.debounceSearch(val, 'citiesListDestination')
      }
    },
    searchCitiesAdditional (val) {
      if (val) {
        this.debounceSearch(val, 'citiesListAdditional')
      }
    }
  },
  async mounted() {
    await this.setCompanies()
    const prebookCompaniesResponse = await PrebookService.getPrebookCompaniesAll()
    if (prebookCompaniesResponse.status === 200) {
      this.prebookCompanies = prebookCompaniesResponse.data
    }
    // TODO: izmestiti ovo u funkciju koja ce da radi handle ovih query parametara
    if (this.$route.query.origin) {
      const response = await loadsService.getCity(this.$route.query.origin)
      if (response.data.status === 'OK') {
        this.options.origin = response.data.cities[0]
      }
    }

    if (this.$route.query.destination) {
      const response = await loadsService.getCity(this.$route.query.destination)
      if (response.data.status === 'OK') {
        this.options.destination = response.data.cities[0]
      }
    }

    if (this.$route.query.avail) {
      this.options.pickup = [this.$route.query.avail, moment(this.$route.query.avail).add(1, 'days').format('YYYY-MM-DD')]
    }

    if (this.$route.query.dho) {
      this.options.dho = parseInt(this.$route.query.dho)
    }

    if (this.$route.query.dhd) {
      this.options.dhd = parseInt(this.$route.query.dhd)
    }

    await this.getLoads()
  },
  computed: {
    ...mapGetters('common', ['loading', 'companiesAll']),
    ...mapGetters('auth', ['user', 'permissions']),
    canAddLoad() {
      if (this.permissions && this.permissions.PREBOOK_LOAD_ADD || this.permissions.PREBOOK_LOAD_ADMIN) return true
      return false
    },
    noDataText(){
      return this.options.pickup
        ? 'No loads found for the selected Avail date'
        : 'Insert Avail date to see loads'
    },
    loadsMap() {
      // Mapa trenutnih load-a iz tabele
      const loadsMap = {}
      for (const [y, load] of this.loads.entries()) {
        loadsMap[load._id] = y
      }
      return loadsMap
    },
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),
    async getLoads() {
      this.tableLoader = true
      const response = await PrebookService.getPrebookLoads(this.options)
      this.tableLoader = false
      if (response.status === 200) {
        this.loads = response.data
      }
    },
    debounceSearch: debounce( function(search, dataArrayName) {
      search = search.split(',')[0]
      if (search && search.length) {
        this.$store.dispatch('common/setLoading', true)
        return loadsService.getCitiesList(search).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data.status === 'OK') {
            this[dataArrayName] = res.data.cities
          }
        }).catch(err => {
          console.log('cities error search', err)
          this[dataArrayName] = []
        })
      } else {
        this[dataArrayName] = []
      }
    }, 400),
    handleFiltersChanged:  debounce(function (filters) {
      if (Object.keys(this.$route.query).length !== 0) {
        this.$router.replace({ path: this.$route.path, query: {} })
      }
      this.loads = []
      this.options = {...this.options, ...filters}

      this.getLoads(this.options)
    }, 500),
    saveLoad() {
      this.$validator.validateAll('prebook').then( async (result) => {
        if (result) {
          try {
            this.$store.dispatch('common/setLoading', true)
            const formData = serialize(this.form)
            formData.append('additional_origins', JSON.stringify(this.form.additional_origins))
            formData.append('additional_destinations', JSON.stringify(this.form.additional_destinations))
            let response = null
            if (this.form._id) {
              response = await PrebookService.editPrebookLoad(formData)
            } else {
              response = await PrebookService.addPrebookLoad(formData)
            }
            this.$store.dispatch('common/setLoading', false)
            if (response && response.status === 200) {
              this.getLoads()
              this.setMessage(`Load successfully saved: ${this.form.load_id}`)
              this.handleCloseDialog()
            }
          } catch (err) {
            if (err.data && err.data.code === 11000) {
              let key = Object.keys(err.data.keyPattern)[0]
              this.errors.add({
                scope: 'prebook',
                field: key,
                msg: 'This field must be unique',
              })
            }
          }
        }
      })
    },
    bookLoad() {
      this.$validator.validateAll('book').then( async (result) => {
        if (result) {
          try {
            this.$store.dispatch('common/setLoading', true)
            const response = await PrebookService.changeStatusPrebookLoad(this.selectedLoad._id, 'BOOKED', this.form)
            this.$store.dispatch('common/setLoading', false)
            if (response && response.status === 200) {
              this.setMessage('Load successfully booked')
              // Vec se radi ovo na socketu
              // if (this.loadsMap[this.selectedLoad._id]) {
              //   this.loads.splice(this.loadsMap[this.selectedLoad._id], 1)
              // }
              this.handleCloseBookDialog()
            }
          } catch (err) {
            console.log('Error on book prebook load', err)
          }
        }
      })
    },
    async deleteLoadApplay() {
      try {
        this.$store.dispatch('common/setLoading', true)
        const response = await PrebookService.changeStatusPrebookLoad(this.selectedLoad._id, 'CANCELED')
        this.$store.dispatch('common/setLoading', false)
        if (response && response.status === 200) {
          this.setMessage(`Load successfully deleted: ${this.selectedLoad.load_id}`)
          // Trebalo bi vec da se radi na socketu
          // if (this.loadsMap[this.selectedLoad._id]) {
          //   this.loads.splice(this.loadsMap[this.selectedLoad._id], 1)
          // }
          this.confirmDialogClose()
        }
      } catch (err) {
        console.log('Error on book prebook load', err)
      }
    },
    async cancelLoadApplay() {
      try {
        this.$store.dispatch('common/setLoading', true)
        const response = await PrebookService.changeStatusPrebookLoad(this.selectedLoad._id, 'NOT_BOOKED')
        this.$store.dispatch('common/setLoading', false)
        if (response && response.status === 200) {
          this.setMessage(`Load successfully canceled: ${this.selectedLoad.load_id}`)
          // Vec se radi na socketu
          // const index = this.loads.findIndex(load => load._id === this.selectedLoad._id)
          // this.loads.splice(index, 1)
          this.confirmDialogClose()
        }
      } catch (err) {
        console.log('Error on book prebook load', err)
      }
    },
    canEditLoad(load) {
      if (this.user._id === load.user.id || load.status === 'PENDING' || this.permissions.PREBOOK_LOAD_EDIT || this.permissions.PREBOOK_LOAD_ADMIN) return true
      if (load.status !== 'NOT_BOOKED' || !this.user) return false
    },
    canDeleteLoad() {
      if (this.permissions.PREBOOK_LOAD_DELETE || this.permissions.PREBOOK_LOAD_ADMIN) return true
    },
    editItem(load) {
      this.form = {...load}
      this.selectedLoad = load
      this.citiesListOrigin.push(load.origin)
      this.citiesListDestination.push(load.destination)
      this.dialog = true
    },
    resetForm() {
      this.form = {...INITIAL_FORM}
      this.form.contact.name = ''
      this.form.contact.email = ''
      this.form.contact.phone = ''
      this.form.note.text = ''
      this.form.additional_destinations = []
      this.form.additional_origins = []
    },
    async setCompanies() {
      await this.$store.dispatch('common/getAllCompanies')
    },
    handleCloseDialog() {
      this.resetForm()
      this.dialog = false
    },
    handleOpenPDF(fileUrl) {
      setTimeout(() => (this.pdfUrl = fileUrl), 500)
      this.pdfDialog = true
    },
    handleClosePDFDialog() {
      this.pdfUrl = null
      this.pdfDialog = false
    },
    viewRoute(item) {
      this.selectedLoad = item
      this.mapDialog = true
    },
    showDriversInterestedInLoad(item) {
      this.driversWaitingForLoadDialog = true
      this.driversList = item.driversWaitingForTheLoad
    },
    closeMap() {
      this.mapDialog = false
      this.selectedLoad = null
    },
    closeDriversWaitingDialog() {
      this.driversWaitingForLoadDialog = false
      this.driversList = []
    },
    openBookModal(item) {
      this.selectedLoad = item
      this.bookDialog = true
    },
    handleCloseBookDialog() {
      this.bookDialog = false
      this.form.booked_contact = ''
      this.form.booked_company = ''
      this.form.comments = []
      this.selectedLoad = null
    },
    deletelLoad(item) {
      this.selectedLoad = item
      this.confirmDialog.active = true
      this.confirmDialog.text = `Are you sure you want to delete load: ${this.selectedLoad.load_id}?`
      this.confirmDialog.action = 'deleteLoadApplay'
    },
    cancelLoad(item) {
      this.selectedLoad = item
      this.confirmDialog.active = true
      this.confirmDialog.text = `Are you sure you want to cancel load: ${this.selectedLoad.load_id}?`
      this.confirmDialog.action = 'cancelLoadApplay'
      this.form.comments = []
    },
    async confirmDialogAction() {
      if (this[this.confirmDialog.action]) {
        await this[this.confirmDialog.action]()
      }
    },
    confirmDialogClose() {
      this.selectedLoad = null
      this.confirmDialog = {
        active: false,
        text: '',
        action: null
      }
    },
    openAdditionalCities(type) {
      this.additionalCitiesType = type
      this.additionalCitiesDialog = true
    },
    handleCloseAdditionalDialog() {
      this.additionalCitiesType = ''
      this.additionalCitiesDialog = true
      this.citiesListAdditional = []
    },
    pushCity() {
      if (this.form[this.additionalCitiesType].length <= 10 && this.additionalCityForm.city) {
        this.form[this.additionalCitiesType].push({
          ...this.additionalCityForm.city,
          date: this.additionalCityForm.date,
          time_from: this.additionalCityForm.timeFrom,
          time_to: this.additionalCityForm.timeTo
        })
        this.additionalCityForm.city = null
        this.additionalCityForm.date = moment(new Date()).format('YYYY-MM-DD')
        this.additionalCityForm.timeFrom = ''
        this.additionalCityForm.timeTo = ''
        this.citiesListAdditional = []
      }
    },
    removeCity(index) {
      this.form[this.additionalCitiesType].splice(index, 1)
    },
    openCitiesTimeline(item) {
      this.selectedLoad = item
      this.additionalCitiesShow = true
    },
    closeCitiesTimeline() {
      this.selectedLoad = null
      this.additionalCitiesShow = false
    },
    formatAdditionalCitiesName(cities) {
      return cities.map(city => {
        return {
          ...city,
          name: `${city.name} - ${this.$options.filters.formatDateUtc(city.date, 'MM/DD/YYYY')} (${city.time_from || ''} - ${city.time_to || ''})`,
        }
      })
    },
    isPendingDisabled(userId, selfDispatch, firstDriverForTheLoad) {
      // Bookiranje je dozvoljeno samo dispatcheru ciji je vozac na redu, odnosno prvi u poolu vozaca koji su zatrazili Load
      if (!selfDispatch) return false
      if (selfDispatch.length === 0) return false
      return !selfDispatch.find(item => item.dispatcher.id === userId && item.driverId === firstDriverForTheLoad._id)
    },
    itemRowBackground(item) {
      return item.status === 'PENDING' ? 'self-dispatch' : ''
    }
  },
  beforeDestroy() {
    this.$socket.client.emit('leave_prebook_loads')
  },
}
</script>

<style lang="scss">
.post-loads-table {
  .v-data-table__wrapper {
    overflow: scroll;
    width: 100%;
    max-height: calc(100vh - 160px);
    position: relative;

    .self-dispatch {
      color: #1e88e5;
    }

    tr {
      &:hover {
        background-color: lightyellow !important;

        td {
          background-color: lightyellow !important;
        }
      }
    }

    th, td {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      left: 0px;

      &:nth-child(1){
        left: 0px;
        z-index: 4;
      }

      &:nth-child(3){
        left: 120px;
        z-index: 4;
      }

      &:nth-child(4) {
        left: 240px;
        z-index: 4;
      }

      &:nth-child(6) {
        left: 330px;
        z-index: 4;
        border-right: 2px solid yellow;
      }
    }

    td {

      &:nth-child(1),  &:nth-child(3), &:nth-child(4), &:nth-child(6){
        z-index: 3;
      }
    }

    td {
      background-color: #fff;

      .td-info {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 20px;
      }

      &:nth-child(1) {
        .note-icon {
          cursor: pointer;
          position: absolute;
          right: 0px;
          top: 0px;
          font-size: 20px;
        }
      }
    }
  }

  .v-input--radio-group {
    margin-top: 0px !important;
  }
}

.vue-horizontal-timeline .timeline {
  padding: 0px !important;

  .time {
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 11px !important;
      margin-bottom: 0px !important;
      padding-left: 4px;
      line-height: normal !important;
    }
  }


  ol {
    padding: 80px 0 80px 0 !important;

    li .time {
      padding: 2px !important;
    }
  }
}
</style>
