import Api from '@/services/Api'

export default {
  getBoards (pagination) {
    return Api().get('/boards', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1
    }})
  },
  addBoard (board) {
    return Api().post('/boards', board)
  },
  editBoard (board) {
    return Api().put('/boards', board)
  },
  deleteBoard (id) {
    return Api().delete(`/boards/${id}`)
  },
  getAllBoards () {
    return Api().get('/boards/all')
  }
}
