import dealership from '../../services/DealershipsService'

const state = {
  dealerships: []
}

const getters = {
  dealerships (state) {
    return state.dealerships
  }
}

const actions = {
  getDealerships ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return dealership.getDealerships(payload).then(res => {
      commit('setDealerships', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllDealerships ({commit, dispatch}, type) {
    dispatch('common/setLoading', true, {root: true})
    return dealership.getAllDealerships(type).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('setDealerships', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addDealership ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return dealership.addDealership(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addDealership', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editDealership ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return dealership.editDealership(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editDealership', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteDealership ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return dealership.deleteDealership(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteDealership', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setDealerships (state, dealerships) {
    state.dealerships = dealerships
  },
  addDealership (state, dealership) {
    const tmpDealerships = state.dealerships.slice()
    tmpDealerships.push(dealership)
    state.dealerships = tmpDealerships
  },
  editDealership (state, dealership) {
    let tmpDealerships = state.dealerships.slice()
    let index = tmpDealerships.findIndex(el => el._id === dealership._id)
    tmpDealerships.splice(index, 1, dealership)
    state.dealerships = tmpDealerships
  },
  deleteDealership (state, id) {
    const tmpDealerships = state.dealerships.slice()
    tmpDealerships.splice(tmpDealerships.findIndex(el => el._id === id), 1)
    state.dealerships = tmpDealerships
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
