<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title>
        {{ $t('other.dotInspections') }}
      </v-card-title>
      <v-data-table
          height="700"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="dotInspections"
          class="elevation-1"
          :loading="loading"
          :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.is_active="{item}">
          {{item.is_active ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.out_of_service="{item}">
          {{item.out_of_service ? 'YES' : 'NO'}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
                small
                class="mr-2"
                @click="editDotInspection(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteDotInspection(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddDotInspection" data-vv-scope="dotInspection">
          <v-card-title
              class="lighten-4 py-4 title"
          >
            {{form._id ? 'Edit' : 'Add'}} DOT Inspection
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12>
                <v-autocomplete
                    v-model="selected.truck"
                    :items="trucksListFormated"
                    return-object
                    item-text="truck_id"
                    item-disabled="disabled"
                    label="Truck*"
                    :search-input.sync="search"
                    hide-details="auto"
                    :hint="selected.truck_id"
                    persistent-hint
                    hide-no-data
                    :loading="loading"
                    placeholder="Start typing to Search"
                    prepend-icon="mdi-database-search"
                    :filter="clientFilter"
                    :autofocus="!form._id ? true : false"
                    v-validate="'required'"
                    name="truck"
                    :error="errors.has('dotInspection.truck')"
                    :error-messages="errors.first('dotInspection.truck')"
                >
                  <template v-slot:item="{item}">
                    <span :class="{isExternal: item.is_external}">
                      {{`${item.truck_id} - ${item.name}`}}
                      <span v-if="item.is_external">external</span>
                    </span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                cols="12"
                lg="6"
              >
                <v-flex xs12>
                  <v-select
                      v-model="form.time_zone"
                      :items="time_zones"
                      item-text="name"
                      item-value="value"
                      label="Select Timezone"
                      persistent-hint
                      return-object
                      single-line
                      name="time_zone"
                      data-vv-as="Time zone"
                      v-validate="'required'"
                      :error="errors.has('dotInspection.time_zone')"
                      :error-messages="errors.first('dotInspection.time_zone')"
                  ></v-select>
                </v-flex>
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.dot_inspection_date"
                      label="Inspection date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-if="form.time_zone"
                    v-model="date"
                    no-title
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>

                <v-menu
                    ref="menu"
                    v-model="datePickerStart"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="form.start_date"
                        label="Start time"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-validate="`compare_times:${form.end_date},End time`"
                        name="start_date"
                        data-vv-as="Start time"
                        :error="errors.has('dotInspection.start_date')"
                        :error-messages="errors.first('dotInspection.start_date')"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-model="form.start_date"
                      full-width
                      @click:minute="$refs.menu.save(form.start_date)"
                  ></v-time-picker>
                </v-menu>

                <v-menu
                    ref="menu"
                    v-model="datePickerEnd"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="form.end_date"
                        label="End time"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-model="form.end_date"
                      full-width
                      @click:minute="$refs.menu.save(form.end_date)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  rows="3"
                  name="note"
                  label="Note"
                  v-validate="'max:250'"
                  v-model="form.note"
                  id="note"
                  :error="errors.has('dotInspection.note')"
                  :error-messages="errors.first('dotInspection.note')"
                  hint="maximum 250 characters"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                    rows="3"
                    name="origin"
                    label="Origin"
                    v-validate="'max:250'"
                    v-model="form.origin"
                    id="origin"
                    :error="errors.has('dotInspection.origin')"
                    :error-messages="errors.first('dotInspection.origin')"
                    hint="maximum 250 characters"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                    rows="3"
                    name="destination"
                    label="Destination"
                    v-validate="'max:250'"
                    v-model="form.destination"
                    id="destination"
                    :error="errors.has('dotInspection.destination')"
                    :error-messages="errors.first('dotInspection.destination')"
                    hint="maximum 250 characters"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                    rows="3"
                    name="location"
                    label="Location"
                    v-validate="'max:250'"
                    v-model="form.location"
                    id="location"
                    :error="errors.has('dotInspection.location')"
                    :error-messages="errors.first('dotInspection.location')"
                    hint="maximum 250 characters"
                ></v-textarea>
              </v-flex>
              <v-switch
                  hide-details="auto"
                  v-model="form.out_of_service"
              >
                <template v-slot:label>
                  <span class="text">Out of service {{form.out_of_service ? 'YES' : 'NO'}}</span>
                </template>
              </v-switch>
              <v-flex xs12>
                <v-checkbox
                    v-model="form.is_active"
                    label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
            <v-btn color="info" :loading="loading" :disabled="loading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import callsService from '../../services/CallsService'
import moment from 'moment'
export default {
  name: 'DotInspections',
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['dot_inspection_date'],
        sortDesc: [false],
        itemsPerPage: 15
      },
      time_zones:[
        {name: 'Pacific', value: 'US/Pacific'},
        {name: 'Mountain', value: 'US/Mountain'},
        {name: 'Central', value: 'US/Central'},
        {name: 'Eastern', value: 'US/Eastern'}
      ],
      trucks: [],
      selected: {
        truck: null
      },
      search: '',
      totalItems: 0,
      headers: [
        {text: 'Truck number', value: 'truck.truck_id'},
        {text: 'Driver', value: 'truck.name'},
        {text: 'Timezone', value: 'time_zone.name'},
        {text: 'Dot inspection date', value: 'dot_inspection_date'},
        {text: 'Start time', value: 'start_date'},
        {text: 'End time', value: 'end_date'},
        {text: 'Origin', value: 'origin'},
        {text: 'Destination', value: 'destination'},
        {text: 'Location', value: 'location'},
        {text: 'OOS', value: 'out_of_service'},
        {text: 'Note', value: 'note'},
        {text: 'Is active', value: 'is_active'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        _id: null,
        dot_inspection_date: this.formatDate(new Date().toISOString().substr(0, 10)),
        start_date: this.currentTime(),
        end_date: this.currentTime(),
        timezone: '',
        is_active: true,
        note: '',
        origin: '',
        destination: '',
        location: '',
        out_of_service: false
      },
      formDialog: false,
      datePicker: false,
      datePickerStart: false,
      datePickerEnd: false,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  mounted() {
  },
  watch: {
    options: {
      handler () {
        this.$store.dispatch('dotInspections/getDotInspections', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true,
    },
    search(newVal) {
      this.getTrucks(newVal)
    },
    date () {
      this.form.dot_inspection_date = this.formatDate(this.date)
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('dotInspections', ['dotInspections']),
    trucksListFormated() {
      return this.trucks.map(item => {
        item.disabled = false
        item.text = `${item.truck_id} - ${item.name}`
        return item
      })
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    clientFilter (item, queryText) {
      return item.text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    getTrucks(val){
      callsService.getMergedDrivers(val).then(res => {
        if (res.data.status === 'OK') {
          this.trucks = res.data.drivers
        }
      }).catch(err => {
        console.log('trucks error search', err)
        this.trucks = []
      })
    },
    onAddDotInspection () {
      this.$validator.validateAll('dotInspection').then((result) => {
        if (result) {
          if (this.form._id) {
            if(this.selected.truck){
              this.form.truck = this.selected.truck
            }
            this.$store.dispatch('dotInspections/editDotInspection', this.form).then(res => {
              if (res) {
                this.closeForm()
              }
            })
          } else {
            if(this.selected.truck){
              this.form.truck = this.selected.truck
            }
            this.$store.dispatch('dotInspections/addDotInspection', this.form).then(res => {
              if (res) {
                this.closeForm()
              }
            })
          }
        }
      })
    },
    editDotInspection (dotInspection) {
      this.selected.truck = dotInspection.truck
      // this.form.timezone
      callsService.getMergedDrivers(dotInspection.truck).then(res => {
        if (res.data.status === 'OK') {
          this.trucks = res.data.drivers
        }
      }).catch(err => {
        console.log('trucks error search', err)
        this.trucks = []
      })
      this.form = {...dotInspection}
      this.formDialog = true
    },
    deleteDotInspection (dotInspection) {
      let confVal = confirm(`Are you sure you want to delete dotInspection: ${dotInspection._id}?`)
      if (confVal) {
        this.$store.dispatch('dotInspections/deleteDotInspection', dotInspection._id).then(() => {
          this.setMessage(`${dotInspection._id} successfully deleted`)
        }).catch((err) => {
          console.log('err on delete', err)
        })
      }
    },
    closeForm () {
      this.formDialog = false
      this.form = {
        _id: null,
        dot_inspection_date: this.formatDate(new Date().toISOString().substr(0, 10)),
        start_date: this.currentTime(),
        end_date: this.currentTime(),
        timezone: '',
        is_active: true,
        note: '',
        origin: '',
        destination: '',
        location: '',
        out_of_service: false
      }
      this.selected.truck = null
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    currentTime(){
      return moment.utc(new Date(), 'HH:mm').format('HH:mm')
    }
  }
}
</script>

<style lang="scss">
.boards-wrapper {
  width: 100%;
  margin-top: 20px;
}

.isExternal {
  span {
    position: absolute;
    left: 16px;
    bottom: 0px;
    font-size: 11px;
    color: #ff5252 !important;
  }
}

// @media only screen and (max-width: 599px) {
//   .boards-wrapper {
//     margin-top: 65px;
//   }
// }
</style>