import axios from 'axios'
import store from '../store'
import router from '../router'
import i18n from '../plugins/lang'

export default() => {
  let http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true
  })

  // catch 401 requests
  http.interceptors.response.use(
    response => response,
    error => {
      const {status} = error.response
      store.dispatch('common/setLoading', false)
      if (status === 500) {
        store.dispatch('common/setMessage', 'Doslo je do greske na serveru!')
      } else if (status === 401) {
        // ako je vec setovan modal samo izadji
        if (store.getters['auth/inactiveModel']) return Promise.reject(error.response)

        if (error.response.data.userInactive) {
          store.dispatch('auth/showInactiveModal', true)
          return Promise.reject(error.response)
        } else {
          store.dispatch('auth/logoutLocal')
          if (router.app.$route.name !== 'login') {
            router.push('/login')
          }
        }
      } else if (status === 403) {
        if (error.response.data.selfDispatch) {
          store.dispatch('common/setMessage', 'Load for another dispatcher (most likely a driver booked a load in the meantime)')
        } else {
          store.dispatch('common/setMessage', `Akcija zabranjena: ${error.response.request.responseURL}`)
        }
      } else if (status === 404) {
        store.dispatch('common/setMessage', `Not found error: ${error.response.request.responseURL}`)
      } else if (status === 400) {
        store.dispatch('common/setMessage', error.response.data.message ? i18n.t(error.response.data.message) : 'Bad request error!')
        console.log('error 400', error.response.data.message)
      }
      return Promise.reject(error.response)
    }
  )

  return http
}
