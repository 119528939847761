<template>
  <div class="PerMile">
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="perMile"
          label="Per Mile"
          type="number"
          @input="handlePerMileChange"
          hide-details
          small
          prefix="$"
        />
      </v-col>
    </v-row>

    <v-row v-for="(payment, index) in payments" :key="index">
      <v-col cols="3">
        <v-menu
          ref="paymentDateMenu"
          v-model="payment.paymentDateMenu"
          :close-on-content-click="false"
          :return-value.sync="payment.date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="payment.date"
              label="Date"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              hide-details
              row
              small
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="payment.date"
            no-title
            scrollable
            row
            dense
            hide-details
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="payment.paymentDateMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.paymentDateMenu[index].save(payment.date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="3"
        ><v-text-field
          v-model="payment.miles"
          @input="(v) => handleMilesChanged(v, index)"
          label="Miles"
          type="number"
          hide-details
          row
          small
          dense
      /></v-col>

      <v-col cols="3"
        ><v-text-field
          v-model="payment.payment"
          label="Payment"
          disabled
          row
          hide-details
          small
          dense
      /></v-col>

      <v-col cols="3">
        <v-text-field
          v-model="payment.comment"
          label="Comment"
          row
          hide-details
          dense
          small
        />
      </v-col>
    </v-row>

    <div class="d-flex justify-center mt-5">
      <v-btn
        text
        @click="handleAddNewPayment"
        :disabled="addMoreDisabled"
        color="primary"
      >
        Add new payment
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

const INITIAL_ITEM = {
  date: null,
  miles: null,
  payment: null,
  comment: null,
  paymentDateMenu: false,
}

export default {
  name: 'PerMile',

  emits: ['change'],

  props: {
    initialPayments: {
      type: Array,
      required: false,
    },
    initialPerMile: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      payments: [],
      paymentDateMenu: false,
      perMile: null,
    }
  },

  created() {
    const hasItems =
      Array.isArray(this.initialPayments) && this.initialPayments.length > 0
    this.payments = hasItems
      ? this.initialPayments.map((p) => ({
        ...p,
        date: this.formatDate(p.date),
      }))
      : [{ ...INITIAL_ITEM }]
    this.perMile = this.initialPerMile || 0
  },

  computed: {
    addMoreDisabled() {
      const lastPayment = this.payments[this.payments.length - 1]
      if (!lastPayment) return false
      const { date, miles } = lastPayment
      return !date || !miles
    },
  },

  methods: {
    handleAddNewPayment() {
      this.payments.push({ ...INITIAL_ITEM })
      this.handleChange()
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    handlePerMileChange(v) {
      const perMile = Number(v)
      this.payments = this.payments.map((p, i) => {
        if (!perMile)
          return {
            ...p,
            payment: 0,
          }

        const itemBefore = this.payments[i - 1]
        let payment = 0
        if (itemBefore && itemBefore.miles && p.miles) {
          const milesDifference = Number(p.miles) - Number(itemBefore.miles)
          payment = milesDifference * perMile
        }

        return {
          ...p,
          payment,
        }
      })
      this.handleChange()
    },

    handleMilesChanged(v, i) {
      const perMile = Number(this.perMile)
      if (!perMile) return this.handleChange()
      const newPayments = [...this.payments]
      const itemBefore = newPayments[i - 1]
      let payment = 0
      if (itemBefore && itemBefore.miles && v) {
        const milesDifference = Number(v) - Number(itemBefore.miles)
        payment = milesDifference * perMile
      }
      newPayments[i].payment = payment
      this.payments = newPayments
      this.handleChange()
    },

    handleRemovePayments(index) {
      const newPayments = [...this.payments]
      newPayments.splice(index, 1)
      this.payments = newPayments
      this.handleChange()
    },

    handleChange() {
      this.$emit('change', { payments: this.payments, perMile: this.perMile })
    },
  },
}
</script>

<style lang="scss">
.PerMile {
  max-height: 300px;
  overflow-y: scroll;
}
</style>