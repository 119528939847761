import contact from '../../services/ContactService'

const state = {
  contacts: []
}

const getters = {
  contacts (state) {
    return state.contacts
  }
}

const actions = {
  getContacts ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return contact.getContacts(payload).then(res => {
      commit('setContacts', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllContact ({dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return contact.getAllContacts().then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addContact ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return contact.addContact(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addContact', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editContact ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return contact.editContact(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editContact', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteContact ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return contact.deleteContact(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteContact', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setContacts (state, contacts) {
    state.contacts = contacts
  },
  addContact (state, contact) {
    const tmpContacts = state.contacts.slice()
    tmpContacts.push(contact)
    state.contacts = tmpContacts
  },
  editContact (state, contact) {
    let tmpContacts = state.contacts.slice()
    let index = tmpContacts.findIndex(el => el._id === contact._id)
    tmpContacts.splice(index, 1, contact)
    state.contacts = tmpContacts
  },
  deleteContact (state, id) {
    const tmpContacts = state.contacts.slice()
    tmpContacts.splice(tmpContacts.findIndex(el => el._id === id), 1)
    state.contacts = tmpContacts
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
