<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-responsive>
            <v-container fill-height>
              <v-layout align-center>
                <v-flex>
                  <h3 class="display-3">404 Page not found</h3>
                  <v-divider class="my-3"></v-divider>
                  <v-btn to="/" large color="info">Back to Home</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-responsive>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
  })
}
</script>
