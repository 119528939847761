<template>
  <div class="EmployeesTable">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <p class="mb-0 mr-2">
            {{ adminOnlyTable ? recruiter.name : 'All' }}
          </p>

          <v-dialog v-model="showForm" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="onlyView"
                v-if="!adminOnlyTable"
                class="mr-1"
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                >Add new employee</v-btn
              >
            </template>
            <form
              @submit.prevent="handleSubmitEmployee"
              data-vv-scope="employee"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5"
                    >{{ editId ? 'Edit' : 'Add' }} new employee</span
                  >
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-alert
                      v-if="serverError"
                      type="error"
                      @input="handleErrorDissmised"
                      dismissible
                      >{{ serverError }}</v-alert
                    >
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-text-field v-model="form.cdl" label="CDL" />
                      </v-col>
                      <v-col cols="8" sm="4" v-if="!isRecruiter">
                        <v-select
                          v-model="form.recruiter"
                          :items="allRecruiters"
                          item-text="name"
                          label="Recruiter"
                          clearable
                          return-object
                        />
                      </v-col>
                      <v-col cols="4" sm="4">
                        <v-readonly-field v-if="isRecruiter" label="Number of days" :value="form.until"/>
                        <v-text-field v-else
                          v-model="form.until"
                          label="Number of days"
                          type="number"
                          v-validate="'numeric'"
                          data-vv-name="days"
                          name="days"
                          :error="errors.has('employee.days')"
                          :error-messages="errors.first('employee.days')"
                          :disabled="isRecruiter"
                        />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field v-model="form.name" label="Name*" />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="form.email"
                          label="Email"
                          type="email"
                        />
                      </v-col>

                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          type="number"
                          v-model="form.phone"
                          label="Phone*"
                          :disabled="editId && !isAdmin"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="form.phone2"
                          label="Phone 2"
                          clearable
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field v-model="form.country" label="Country*" />
                      </v-col>

                      <v-col cols="12" sm="6" md="3">
                        <v-text-field type="number" v-model="form.ssn" label="SSN*" />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="form.note"
                          label="Note"
                          clearable
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="form.status"
                          :items="STATUSES"
                          item-text="label"
                          item-value="value"
                          label="Status*"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="handleClose"> Close </v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="submitDisabled"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </form>
          </v-dialog>

          <v-btn
            v-if="adminOnlyTable"
            :disabled="loading || !selected.length"
            class="primary mr-1"
            small
            @click="handleOpenMoveDialog(false)"
          >
            Move
          </v-btn>

          <v-btn
            v-if="adminOnlyTable"
            :disabled="loading"
            class="primary mr-1"
            small
            @click="handleOpenMoveDialog(true)"
          >
            Move all
          </v-btn>

          <v-btn
            v-if="items.length"
            color="green"
            :disabled="loading || onlyView"
            dark
            small
            @click="handleDownloadSheet"
          >
            Export <v-icon small>mdi-file-excel-outline</v-icon>
          </v-btn>
        </div>

        <div class="d-flex align-center">
          <v-checkbox
            v-model="filters.statuses"
            class="mr-2"
            label="Employed"
            value="employed"
            small
            @change="handleFiltersChanged"
          />
          <v-checkbox
            v-model="filters.statuses"
            class="mr-2"
            label="Unemployed"
            value="unemployed"
            small
            @change="handleFiltersChanged"
          />
          <v-checkbox
            v-model="filters.statuses"
            class="mr-2"
            label="Blocked"
            value="blocked"
            small
            @change="handleFiltersChanged"
          />
          <v-checkbox
            v-model="filters.statuses"
            class="mr-2"
            label="Retired"
            value="retired"
            small
            @change="handleFiltersChanged"
          />
          <v-checkbox
            v-model="filters.statuses"
            class="mr-2"
            label="Third company"
            value="third company"
            small
            @change="handleFiltersChanged"
          />
          <v-checkbox
            v-model="filters.statuses"
            class="mr-2"
            label="Owner operator"
            value="owner operator"
            small
            @change="handleFiltersChanged"
          />
          <v-checkbox
            v-model="filters.statuses"
            class="mr-2"
            label="Hold"
            value="hold"
            small
            @change="handleFiltersChanged"
          />
          <v-checkbox
            v-model="filters.statuses"
            class="mr-2"
            label="In contact"
            value="in contact"
            small
            @change="handleFiltersChanged"
          />
          <v-text-field
            v-model="filters.search"
            label="Search employees"
            small
            solo
            hide-details
            @input="handleFiltersChanged"
          />
        </div>
      </v-card-title>

      <v-data-table
        v-model="selected"
        :loading="loading"
        :headers="headers"
        :items="items"
        :options="options"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :show-select="adminOnlyTable"
        :hide-default-footer="adminOnlyTable"
        disable-sort
        item-key="_id"
        dense
        @update:options="handleOptionsUpdate"
      >
        <template v-slot:item.recruiter="{ item }">
          <td v-if="item.recruiter" style="text-align: center">
            {{ item.recruiter.name }}
          </td>
        </template>

        <template v-slot:item.note="{ item }">
          <field-history :employeeId="item._id" type="NOTE_CHANGE">
            {{ item.note }}
          </field-history>
        </template>

        <template v-slot:item.history="{ item }">
          <td
            v-if="item.recruiter"
            style="text-align: center"
          >
            <recruiter-history
              :employee-id="item._id"
              :recruiter="item.recruiter"
            />
          </td>
        </template>

        <template v-slot:item.status="{ item }">
          <td class="EmployeesTable__status" :style="statusStyles(item.status)">
            <strong>{{ statusesMap[item.status] }}</strong>
          </td>
        </template>

        <template v-slot:item.actions="{ item }">
          <td style="text-align: center">
            <v-icon :disabled="onlyView" small class="mr-2" @click="handleEditEmployee(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              :disabled="onlyView"
              v-if="isAdmin"
              small
              @click="handleDeleteEmployee(item._id)"
            >
              mdi-delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-expansion-panels v-if="isRecruiter" class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-header
          >List of blocked employees</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-data-table
            :loading="blockedLoading"
            :headers="blockedHeaders"
            :items="blockedItems"
            :options="blockedOptions"
            :server-items-length="blockedTotalItems"
            :footer-props="blockedFooterProps"
            disable-sort
            dense
            @update:options="handleBlockedOptionsUpdate"
          >
            <template v-slot:item.recruiter="{ item }">
              <td v-if="item.recruiter" style="text-align: center">
                {{ item.recruiter.name }}
              </td>
            </template>

            <template v-slot:item.note="{ item }">
              <field-history :employeeId="item._id" type="NOTE_CHANGE">
                {{ item.note }}
              </field-history>
            </template>

            <template v-slot:item.history="{ item }">
              <td
                v-if="item.recruiter"
                style="text-align: center"
              >
                <recruiter-history
                  :employee-id="item._id"
                  :recruiter="item.recruiter"
                />
              </td>
            </template>

            <template v-slot:item.status="{ item }">
              <td
                class="EmployeesTable__status"
                :style="statusStyles(item.status)"
              >
                <strong>{{ statusesMap[item.status] }}</strong>
              </td>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog
      v-model="moveDialog"
      max-width="500px"
      @click:outside="handleCloseMoveDialog"
    >
      <v-card>
        <v-card-title class="text-h5 text-center"
          >Move employees to selected recruiter</v-card-title
        >

        <v-card-text>
          <v-select
            v-model="newRecruiter"
            :items="allRecruiters"
            item-text="name"
            return-object
            label="Select a recruiter"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseMoveDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="handleMove"
            :disabled="!newRecruiter"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from '../../services/EmployeesService'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { b64toBlob, downloadFile } from '../../utils'
import RecruiterHistory from './RecruiterHistory.vue'
import FieldHistory from './FieldHistory.vue'

const recruiterCol = {
  align: 'center',
  text: 'Recruiter',
  value: 'recruiter',
}
const PER_PAGE = 100
const statusStyles = (status) => {
  switch (status) {
  case 'employed':
    return 'background-color: green; color: white;'
  case 'unemployed':
    return 'background-color: yellow; color: black;'
  case 'blocked':
    return 'background-color: red; color: white;'
  case 'retired':
    return 'background-color: blue; color: white;'
  case 'third company':
    return 'background-color: #C2B280; color: white;'
  case 'owner operator':
    return 'background-color: #FFAC1C; color: white;'
  case 'hold':
    return 'background-color: gray; color: white;'
  case 'in contact':
    return 'background-color: #DE3163; color: white;'
  default:
    return 'background-color: white; color: black;'
  }
}

const STATUSES = [
  { label: 'Employed', value: 'employed' },
  { label: 'Unemployed', value: 'unemployed' },
  { label: 'Blocked', value: 'blocked' },
  { label: 'EX DRIVERS', value: 'retired' },
  { label: 'Third company', value: 'third company' },
  { label: 'Owner operator', value: 'owner operator' },
  { label: 'Hold', value: 'hold' },
  { label: 'In contact', value: 'in contact' },
]

const REQUIRED = ['name', 'phone', 'country', 'status']

export default {
  name: 'EmployeesTable',

  components: { RecruiterHistory, FieldHistory },

  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isRecruiter: {
      type: Boolean,
      default: false,
    },
    adminOnlyTable: {
      type: Boolean,
      default: false,
    },
    recruiters: {
      default: () => [],
    },
    allRecruiters: {
      type: Array,
      default: () => [],
    },
    recruiter: {
      default: () => {},
    },
    onlyView: {
      type: Boolean,
      default: false,
    },
    defaultStatuses: {
      type: Array,
      default: () => ['employed', 'unemployed']
    },
    recruiterId: String,
  },

  emits: ['employee-moved'],

  data() {
    return {
      serverError: null,
      filters: {
        statuses: this.defaultStatuses,
        search: null,
      },
      footerProps: {
        itemsPerPageOptions: [20, 50, 100]
      },
      options: {
        page: 1,
        itemsPerPage: 100
      },
      totalItems: 0,
      items: [],
      headers: [
        {
          align: 'center',
          text: 'CDL #',
          value: 'cdl',
        },
        {
          align: 'center',
          text: 'Employee name',
          value: 'name',
        },
        ...(!this.adminOnlyTable && this.isAdmin ? [recruiterCol] : []),
        {
          align: 'center',
          text: 'Recruiting',
          value: 'history',
        },
        {
          align: 'center',
          text: 'Status',
          value: 'status',
          width: 112,
        },
        {
          align: 'center',
          text: 'Email',
          value: 'email',
        },
        {
          align: 'center',
          text: 'Phone',
          value: 'phone',
        },
        {
          align: 'center',
          text: 'Phone 2',
          value: 'phone2',
        },
        {
          align: 'center',
          text: 'Country',
          value: 'country',
        },
        {
          align: 'center',
          text: 'SSN',
          value: 'ssn',
        },
        {
          align: 'center',
          text: 'Note',
          value: 'note',
          width: '20%',
        },
        {
          align: 'center',
          text: 'Actions',
          value: 'actions',
          width: 56,
        },
      ],
      recruitingHistory: null,
      loading: false,
      STATUSES,
      showForm: false,
      form: this.setupForm(),
      editId: false,

      selected: [],
      moveDialog: false,
      moveAll: false,
      newRecruiter: null,
      oldRecruiter: null,
      initDone: false,

      blockedOptions: {
        page: 1,
        itemsPerPage: 10,
        statuses: ['blocked'],
      },
      blockedTotalItems: 0,
      blockedItems: [],
      blockedLoading: false,
      blockedFooterProps: {
        'items-per-page-options': [10, 20, 50],
      },
      blockedHeaders: [
        {
          align: 'center',
          text: 'CDL #',
          value: 'cdl',
        },
        {
          align: 'center',
          text: 'Employee name',
          value: 'name',
        },
        ...[recruiterCol],
        {
          align: 'center',
          text: 'Recruiting',
          value: 'history',
        },
        {
          align: 'center',
          text: 'Status',
          value: 'status',
          width: 112,
        },
        {
          align: 'center',
          text: 'Email',
          value: 'email',
        },
        {
          align: 'center',
          text: 'Phone',
          value: 'phone',
        },
        {
          align: 'center',
          text: 'Phone 2',
          value: 'phone2',
        },
        {
          align: 'center',
          text: 'Country',
          value: 'country',
        },
        {
          align: 'center',
          text: 'SSN',
          value: 'ssn',
        },
        {
          align: 'center',
          text: 'Note',
          value: 'note',
          width: '20%',
        },
      ],
    }
  },

  computed: {
    submitDisabled() {
      if (this.loading) return true
      return Object.keys(this.form).some((field) => {
        const isRequired = REQUIRED.find((f) => f === field)
        return isRequired && !this.form[field]
      })
    },

    statusesMap() {
      const map = {}
      for (let status of STATUSES) {
        map[status.value] = status.label
      }
      return map
    },
  },

  mounted() {
    this.initDone = true
  },
  
  async created() {
    if (!this.adminOnlyTable) {
      this.loading = true
      await this.fetchEmployees()
      this.loading = false
    } else {
      if (Array.isArray(this.recruiter.employees) && this.recruiter.employees.length > 0) {
        this.items = [...this.recruiter.employees]
        this.totalItems = this.recruiter.employees.length
      }
    }
  },

  watch: {
    'recruiter.employees'(v) {
      if (this.initDone && v === null) { 
        this.fetchEmployees()
      }
    }
  },

  methods: {
    statusStyles,

    handleCloseMoveDialog() {
      this.moveDialog = false
      this.moveAll = false
      this.newRecruiter = null
    },

    handleOpenMoveDialog(moveAll = false) {
      this.moveDialog = true
      this.moveAll = moveAll
    },

    async handleMove() {
      this.loading = true
      const params = {
        moveAll: this.moveAll,
        oldRecruiterId: this.recruiterId,
        newRecruiterId: this.newRecruiter.id,
        newRecruiterName: this.newRecruiter.name,
        employeesToMove: this.selected,
      }
      try {
        await service.moveEmployees(params)
        this.$emit('employee-moved', params)
        this.handleCloseMoveDialog()
      } catch (error) {
        console.error('EmployeesTable handleMove()', error)
      } finally {
        this.loading = false
      }
    },

    async handleOptionsUpdate(o) {
      if (this.initDone) {
        this.options.page = o.page
        this.options.itemsPerPage = o.itemsPerPage
        this.loading = true
        await this.fetchEmployees()
        this.loading = false
      }
    },

    async handleBlockedOptionsUpdate(o) {
      if (this.initDone) {
        this.blockedOptions.page = o.page
        this.blockedOptions.itemsPerPage = o.itemsPerPage
        this.blockedLoading = true
        await this.fetchBlockedEmployees()
        this.blockedLoading = false
      }
    },

    async fetchBlockedEmployees() {
      try {
        const { data } = await service.getEmployees(this.blockedOptions)
        if (Array.isArray(data.docs)) this.blockedItems = data.docs
        this.blockedTotalItems = data.total
      } catch (error) {
        console.error('EmployeesTable fetchBlockedEmployees()', error)
      }
    },

    async handleDownloadSheet() {
      let id
      if (this.isAdmin && this.adminOnlyTable) id = this.recruiterId
      if (this.isRecruiter) id = this.recruiterId

      try {
        this.loading = true
        const { data } = await service.downloadSheet({
          id,
          statuses: this.filters.statuses,
          search: this.filters.search,
        })
        const blob = b64toBlob(
          data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        )
        downloadFile(blob, 'employees_export')
      } catch (error) {
        console.error('EmployeesTable handleDownloadSheet()', error)
      } finally {
        this.loading = false
      }
    },

    handleFiltersChanged: debounce(async function () {
      this.options.page = 1
      this.items = []
      this.loading = true
      await this.fetchEmployees()
      this.loading = false
    }, 200),

    calculateDays(recruiter) {
      if (!recruiter || !recruiter.until) return 30
      const time = new Date(recruiter.until).getTime() - Date.now()
      return Math.round(time / (1000 * 3600 * 24))
    },

    handleSubmitError(msg) {
      if (msg.includes('duplicate key'))
        this.serverError = 'An employee with this PHONE NUMBER already exists.'
      else this.serverError = 'Something went wrong.'
    },

    handleErrorDissmised(v) {
      this.serverError = v
    },

    handleEditEmployee(item) {
      this.showForm = true
      this.editId = item._id
      this.form = {
        ...item,
        recruiter: item.recruiter || null,
        until: this.calculateDays(item.recruiter),
      }
    },

    async handleDeleteEmployee(id) {
      const yes = confirm('Are you sure you want to delete?')
      if (yes) {
        try {
          this.loading = true
          await service.deleteEmployee(id)
          await this.fetchEmployees()
        } catch (error) {
          console.error('EmployeesTable fetchEmployees()', error)
        } finally {
          this.loading = false
        }
      }
    },

    findRecruiter(id) {
      return this.allRecruiters.find((r) => r.id === id)
    },

    async fetchEmployees() {
      try {
        if (this.isRecruiter || this.adminOnlyTable) {
          const params = this.filters
          if (!this.adminOnlyTable) Object.assign(params, this.options)

          this.loading = true
          const { data } = await service.groupedByRecruiters({
            page: params.page,
            itemsPerPage: PER_PAGE,
            statuses: params.statuses,
            recruiterId: this.recruiterId,
            search: params.search
          })
          if (data) this.items = data.employees
          this.totalItems = data.total
          this.loading = false
        } else {
          this.loading = true
          const { data } = await service.getEmployees({ ...this.options, ...this.filters })
          if (Array.isArray(data.docs)) this.items = data.docs
          this.totalItems = data.total
          this.loading = false
        }
      } catch (error) {
        this.loading = false
        console.error('EmployeesTable fetchEmployees()', error)
      }
    },

    async handleSubmitEmployee() {
      this.$validator.validateAll('employee').then(async (result) => {
        if (result) {
          try {
            this.serverError = null
            this.loading = true
            if (this.editId) await service.editEmployee(this.editId, this.form)
            else await service.addEmployee(this.form)
            await this.fetchEmployees()
            this.showForm = false
            this.form = this.setupForm()
            this.editId = false
          } catch (error) {
            if (error.data && error.data.error)
              this.handleSubmitError(error.data.error)
            console.error('RecruitmentView handleSubmitEmployee()', error)
            return []
          } finally {
            this.loading = false
          }
        }

        return
      })
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    setupForm() {
      return {
        cdl: null,
        recruiter: this.isRecruiter
          ? this.findRecruiter(this.recruiterId)
          : null,
        name: null,
        email: null,
        phone: null,
        phone2: null,
        country: null,
        ssn: null,
        status: null,
        note: null,
        until: 30,
      }
    },

    handleClose() {
      this.showForm = false
      this.form = this.setupForm()
      this.editId = false
    },
  },
}
</script>

<style lang="scss">
.EmployeesTable {
  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  &__status {
    text-transform: capitalize;
    text-align: center;
  }

  td {
    position: relative;
  }

  &__title {
    button {
      display: block;
    }
  }
}

.readonly-input {
  display: flex;
  flex-direction: column;

  &__label {
    color: rgba(0, 0, 0, 0.38);
    font-size: 11px;
  }
}
</style>
