<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title v-if="routeName === 'messages-daily-report'">
        {{ $t('other.messages_daily_report') }}
      </v-card-title>
      <v-card-title v-else>
        {{ $t('other.messages_weekly_report') }}
      </v-card-title>
      <v-data-table
          height="750"
          :disable-sort="true"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
          :items="messages"
          class="elevation-1"
          :loading="loading"
          :footer-props="{
          'items-per-page-options': [10, 15, 25]
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.created="{item}">
          {{item.created | formatDateObj('MM/DD/YYYY HH:mm')}}
        </template>
        <template v-slot:item.actions="{item}">
          <td class="justify-center layout px-0">
            <v-icon
                small
                class="mr-2"
                @click="viewMessage(item)"
            >
              mdi-eye
            </v-icon>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <div>
      <v-dialog
          v-if="viewDialog"
          persistent
          max-width="460"
          :value="true"
      >
        <v-card>
          <v-card-title class="headline">
            {{form.name}}
          </v-card-title>
          <v-card-text style="white-space: pre-wrap;" v-html="form.content"></v-card-text>
          <v-card-actions>
            <span class="caption">{{form.created | formatDateObj('MM/DD/YYYY HH:mm')}}</span>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="onCloseViewDialog">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import usersService from '@/services/UsersService'
export default {
  name: 'Messages',
  data() {
    return {
      options: {
        page: 1,
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 15
      },
      totalItems: 0,
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Content', value: 'content'},
        {text: 'Created', value: 'created'},
        {text: 'Actions', value: 'actions', align: 'center'}
      ],
      form: {
        name: '',
        content: '',
        is_active: true,
        daily_report: false,
        weekly_report: false
      },
      viewDialog: false,
      roles: [],
      selected: {
        roles: null
      },
      routeName: null,
    }
  },
  mounted() {
    this.getRoles()
    this.routeName = this.$route.name
  },
  watch: {
    options: {
      handler () {
        if (this.routeName === 'messages-daily-report') this.options.type = 'daily'; else this.options.type = 'weekly'
        this.$store.dispatch('messages/getMessages', this.options).then(res => {
          this.totalItems = res.total
        })
      },
      deep: true,
    },
    form: {
      handler(newValue) {
        if (newValue.daily_report || newValue.weekly_report) {
          this.selected.roles = this.roles.find(role => role.name === 'Dispatcher')
        } else {
          this.selected.roles = []
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('messages', ['messages']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage'
    }),
    viewMessage (message) {
      this.form = {...message}
      this.viewDialog = true
    },
    onCloseViewDialog(){
      this.form = {}
      this.viewDialog = false
    },
    getRoles (){
      usersService.getRoles({}).then(response => {
        this.$store.dispatch('common/setLoading', false)
        this.roles = response.data.docs
      })
    },
    selectAll () {
      this.selected.roles = [...this.roles]
    }
  }
}
</script>

<style lang="scss">
.boards-wrapper {
  width: 100%;
  margin-top: 20px;
}

.v-list-item.move {
  cursor: move;

  .v-list-item__title.teamLead {
    color: green;
  }
}
</style>