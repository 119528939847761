<template>
  <div>
    <v-dialog
      v-if="alerts[0] && !hiddenAlerts.includes(alerts[0]._id)"
      :key="alerts[0]._id"
      persistent
      max-width="460"
      :value="true"
    >
      <v-card>
        <v-card-title class="headline">
          Alert for truck with number: {{alerts[0].truck.number}}
        </v-card-title>
        <v-card-text style="white-space: pre-wrap;" v-html="alerts[0].note"></v-card-text>
        <v-card-actions>
          <span class="caption">{{alerts[0].created | formatDateObj('MM/DD/YYYY HH:mm')}}</span>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeAlert(alerts[0]._id)">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import truckService from '../../services/TruckService'

export default {
  computed: {
    ...mapGetters('common', ['alerts', 'hiddenAlerts']),
    ...mapGetters('auth', ['user'])
  },
  methods: {
    closeAlert (id) {
      truckService.deleteUserFromAlertMsg(id, this.user._id).then(() => {
        this.$store.dispatch('common/removeAlert', id)

        this.$socket.client.emit('hideAlert', { alertId: id, userId: this.user._id })
      })
    }
  },
  sockets: {
    connect() {
      this.$socket.client.emit('joinToAlerts')
    },
    alert (alert) {
      let index = alert.userIds.indexOf(this.user._id)
      if (index !== -1) {
        this.$store.dispatch('common/addAlert', alert)
      }
    },
    hideAlert (data) {
      if (data.userId === this.user._id){
        this.$store.dispatch('common/hideAlert', data.alertId)
      }
    }
  },
  mounted() {
    truckService.getUserAlerts().then(response => {
      for(let i = 0; i < response.data.length; i++){
        this.$store.dispatch('common/addAlert', response.data[i])
      }
    })

    this.$socket.client.emit('joinToAlerts')
  },
  beforeDestroy() {
    this.$socket.client.emit('leave_alerts')
  }
}
</script>